<template>
  <section class="charts">
    <!-- 스타일과 클래스, 그래프 옵션은 자식컴포넌트에 바인딩 가능하다 -->
    <vue-highcharts :options="pieOptions" ref="pieChart"></vue-highcharts>
  </section>
</template>

<script>
import VueHighcharts from "./HighChart";

export default {
  components: {
    VueHighcharts,
  },
  data() {
    return {
      pieOptions: {
        credits: {
          enabled: false,
        },
        exporting: {
          enabled: false,
        },
        chart: {
          type: "pie",
          options3d: {
            enabled: true,
            alpha: 45,
          },
        },
        title: {
          text: "파이 그래프 샘플",
        },
        subtitle: {
          text: "pie",
        },
        plotOptions: {
          pie: {
            innerSize: 100,
            depth: 45,
          },
        },
        series: [
          {
            name: "처리건수",
            data: [],
          },
        ],
      },
      asyncData: [], //데이터 연동
      //   asyncData: [
      //     ["월요일", 18],
      //     ["화요일", 13],
      //     ["수요일", 11],
      //     ["목요일", 16],
      //     ["금요일", 28],
      //   ],
    };
  },
  created() {},
  mounted() {
    this.reflow();
  },
  methods: {
    //그래프를 100% 사이즈로 그리기 위하여 컴포넌트가 활성화 된 직후 다시 그리기를 해준다
    reflow() {
      let pieChart = this.$refs.pieChart;
      setTimeout(() => {
        pieChart.chart.reflow();

        //샘플데이터 업데이트
        this.setData();
      }, 500);
    },
    setData() {
      let pieChart = this.$refs.pieChart;
      pieChart.delegateMethod("showLoading", "Loading...");

      //샘플데이터 가공
      let set_data = [];
      for (let i = 0; i < 5; i++) {
        let inner_set_dat = [];
        let date = ["월요일", "화요일", "수요일", "목요일", "금요일"];
        inner_set_dat.push(date[i]);
        inner_set_dat.push(Math.floor(Math.random() * 20));

        set_data.push(inner_set_dat);
      }

      setTimeout(() => {
        pieChart.chart.series[0].update(
          {
            data: set_data,
          },
          true //true / false to redraw
        );
        pieChart.hideLoading();
      }, 1000);

      //다시 호출
      setTimeout(() => {
        this.setData();
      }, 5000);
    },
  },
};
</script>
