<template>
  <v-list-item
    :key="item.C_NODE_NO"
    link
    color="white"
    class="transparent"
    @click="mixin_set_active_tab(item)"
  >
    <v-list-item-content>
      <v-list-item-title>{{ item.NODE_TITLE }}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
  export default {
    name: "MenuList",
    props: {
      item: {
        type: Object,
        default: () => ({}),
      },
      user_menu: {
        type: Array,
        default: () => [],
      },
    },
    methods: {
    },
  };
</script>

<style></style>
