<template>
  <div class="modal-wrap">
    <h1 class="tit-h1 d-flex">
      큐 등록
      <div class="ml-auto align-self-center">
        <v-icon x-small class="svg-close20" @click="[$emit('hide'),init()]"></v-icon>
      </div>
    </h1>
    <div class="modal-box">
      <div>
        <!-- 큐등록 다이얼로그 시작 -->
        <div class="box-ct">
          <div class="table-form">
            <table>
              <colgroup>
                <col width="127px" />
                <col width="310px" />
                <col width="127px" />
                <col width="310px" />
                <col width="127px" />
                <col width="" />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">
                    <v-badge dot color="#EF9191">고객사명</v-badge>
                  </th>
                  <td>
                    <v-text-field class="form-inp full" name="name" value="고객사명" id="id" outlined hide-details=""
                      v-model="Qdata.COMPANY_NEWNM" readonly>
                    </v-text-field>
                  </td>
                  <th scope="row">
                    <v-badge dot color="#EF9191">전화번호</v-badge>
                  </th>
                  <td>
                    <v-text-field class="form-inp full" name="name" value="전화번호" id="id" outlined hide-details=""
                      v-model="Qdata.PHONE_NEWNUM" readonly>
                    </v-text-field>
                  </td>
                  <th scope="row">
                    <v-badge dot color="#EF9191">큐번호</v-badge>
                  </th>
                  <td>
                    <v-text-field class="form-inp full" name="name" value="큐번호" id="id" outlined hide-details=""
                      v-model="QUEUE_REGQNUM">
                    </v-text-field>
                  </td>
                </tr>
                <tr>
                  <td colspan="6">
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!--// 큐등록 다이얼로그 -->
      </div>
      <div class="text-right mt-3">
        <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSave')" outlined class="btn-point" v-on:click="showAlertReg"> 저장 </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
  import api from '../../store/apiUtil.js';
  import { mixin } from "@/mixin/mixin.js";

  export default {
    name: "MENU_M810706P01P01", //name은 'MENU_' + 파일명 조합
    mixins: [mixin],
    props: {
      Qdata: {
        type: Object,
      },
    },
    computed: {},
    components: {},
    data() {
      return {
        QUEUE_REGQNUM: '',
      };
    },
    methods: {

      btnRegistQ() {
        if (this.QUEUE_REGQNUM == "" || this.QUEUE_REGQNUM == undefined) {
          this.showAlert("큐번호를 입력해주세요.");
          return;
        }

        api.post("api/setting/system/queue/regist", //api url입력
            {
              ASP_NEWCUST_KEY: this.Qdata.ASP_NEWCUST_KEY,
              COMPANY_NM: this.Qdata.COMPANY_NEWNM,
              PHONE_NUM: this.Qdata.PHONE_NEWNUM,
              QUEUE_NUM: this.QUEUE_REGQNUM,
              REGR_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
              ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
              ASP_CUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
            }, {
              head: {
                "SERVICE": "setting.system.queue",
                "METHOD": "regist",
                "TYPE": "BIZ_SERVICE",
              }
            })
          .then((response) => {
            this.$emit("Qsave", "selectList")
            this.success()
          })
          .catch((err) => {
            this.fail()
          })

      },

      init() {
        this.QUEUE_REGQNUM = '';
      },

      showAlert(msg) {
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: true,
          msg: msg,
          iconClass: "svg-error-lg",
          type: "default",
        });
      },

      showAlertReg() {
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: true,
          msg: '큐를 저장하시겠습니까?',
          iconClass: 'svg-chk-lg',
          type: 'confirm',
          callYes: this.callYes,
          callNo: this.callNo,
        })
      },

      callYes() {
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: false,
        });
        this.btnRegistQ();
      },

      callNo() {
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: false,
        });
      },

      success(){
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: true,
          msg: '정상처리 되었습니다.',
          iconClass: 'svg-done-lg',
          type: 'default'
        })
      },

      fail(){
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: true,
          msg: '시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.',
          iconClass: 'svg-error-lg',
          type: 'default'
        })
      },
    },
    computed: {},
    watch: {}
  };
</script>

<style></style>