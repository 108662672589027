<template>
  <section class="charts">
    <!-- 스타일과 클래스, 그래프 옵션은 자식컴포넌트에 바인딩 가능하다 -->
    <vue-highcharts
      :highcharts="Highcharts"
      :options="options"
      ref="lineChart"
    ></vue-highcharts>
  </section>
</template>

<script>
import VueHighcharts from "./HighChart";
import Highcharts from "highcharts";
import Exporting from "highcharts/modules/exporting";
Exporting(Highcharts);

//그래프 레이아웃 폼은 여기서 정의한다
const basicData = {
  chart: {
    type: "spline",
  },
  title: {
    text: "라인 그래프 샘플",
  },
  subtitle: {
    text: "spline",
  },
  xAxis: {
    categories: ["월요일", "화요일", "수요일", "목요일", "금요일"],
  },
  yAxis: {
    title: {
      text: "처리건수",
    },
    labels: {
      formatter: function () {
        return this.value + "건";
      },
    },
  },
  tooltip: {
    crosshairs: true,
    shared: true,
  },
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  plotOptions: {
    spline: {
      marker: {
        radius: 4,
        lineColor: "#666666",
        lineWidth: 1,
      },
    },
    series: {
      animation: {
        //라인 그려지는 속도
        duration: 2000,
      },
    },
  },
  series: [],
};

export default {
  components: {
    VueHighcharts,
  },
  data() {
    return {
      options: basicData,
      Highcharts: Highcharts,
      asyncData: [
        {
          name: "InBound",
          marker: {
            symbol: "circle", //'circle', 'square', 'diamond', 'triangle''triangle-down'
          },
          data: [
            // 7, 6, 9, 4, 8,
            // {
            //   y: 16.5,
            //   marker: {
            //     symbol: "circle",
            //     fillColor: "orange",
            //     lineColor: "red",
            //     lineWidth: 2,
            //     radius: 8,
            //   },
            // },
          ],
        },
        {
          name: "OutBound",
          marker: {
            symbol: "circle", //'circle', 'square', 'diamond', 'triangle''triangle-down'
          },
          data: [
            // 1, 2, 3, 8, 12,
            // {
            //   y: 18,
            //   marker: {
            //     symbol: "circle",
            //     fillColor: "skyblue",
            //     lineColor: "blue",
            //     lineWidth: 2,
            //     radius: 8,
            //   },
            // },
          ],
        },
      ],
    };
  },
  created() {},
  mounted() {
    this.reflow();
  },
  methods: {
    //그래프를 100% 사이즈로 그리기 위하여 컴포넌트가 활성화 된 직후 다시 그리기를 해준다
    reflow() {
      let lineChart = this.$refs.lineChart;
      setTimeout(() => {
        lineChart.chart.reflow();

        //샘플데이터 업데이트
        this.setData();
      }, 500);
    },
    setData() {
      //그래프 데이터 생성
      let lineChart = this.$refs.lineChart;
      lineChart.removeSeries();

      //샘플 데이터 가공
      let sample_data1 = [];
      let sample_data2 = [];
      for (let i = 0; i < 5; i++) {
        sample_data1.push(Math.floor(Math.random() * 20));
        sample_data2.push(Math.floor(Math.random() * 20));
      }

      this.asyncData[0].data = sample_data1;
      this.asyncData[1].data = sample_data2;

      setTimeout(() => {
        this.asyncData.forEach((element) => lineChart.addSeries(element));
        // lineChart.addSeries(this.asyncData);
        lineChart.hideLoading();
      }, 1000);

      //그래프 다시 그리기
      setTimeout(() => {
        this.setData();
      }, 5000);
    },
  },
};
</script>
