<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">게시판</h1>
    <div class="box-wrap">
      <div class="box-ct d-flex">
        <div>
          <div class="form-inp sm">
            <v-select
              :items="selBoxBiz"
              item-text="CD_NM"
              item-value="CD"
              outlined
              hide-details
              label="회사구분"
              return-object
              v-on:change="clickSelBoxBizItem"
              v-model="selBoxBizV"
              :readonly="RDOnly"
            >
              <template v-slot:label>
                회사구분
                <v-icon color="#EF9191">mdi-circle-small</v-icon>
              </template>
            </v-select>
          </div>
          <div class="form-inp sm ml-2" v-if="false">
            <v-select
              :items="selBoxBizId"
              item-text="BRD_NAME"
              item-value="BRD_ID"
              outlined
              hide-details
              label="게시판명"
              return-object
              v-on:change=""
              v-model="selBoxBizIdV"
            >
              <template v-slot:label>
                게시판명
                <v-icon color="#EF9191">mdi-circle-small</v-icon>
              </template>
            </v-select>
          </div>
          <v-text-field
            class="form-inp lg ml-2"
            outlined
            hide-details
            label="제목"
            v-model="txtFldTitleV"
          >
          </v-text-field>
          <v-text-field
            class="form-inp sm ml-2"
            outlined
            hide-details
            label="등록자"
            v-model="txtFldRegV"
          >
          </v-text-field>
          <v-text-field
            class="form-inp sm ml-2"
            outlined
            hide-details
            label="수정자"
            v-model="txtFldModV"
          >
          </v-text-field>
        </div>
        <div class="d-flex align-center ml-auto">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')" class="btn-etc2" outlined @click="clickGetList"> 조회 </v-btn>
        </div>
      </div>
    </div>
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">
        게시물리스트
        <div class="ml-auto align-self-center">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnNew')" class="btn-default" outlined @click="clickNewWrite">
            신규
          </v-btn>
        </div>
      </h2>

      <div class="box-ct">
        <v-data-table
          class="grid-default case03"
          height="549px"
          dense
          fixed-header
          :headers="gridListDataHeaders"
          :items="gridListDataText"
          :items-per-page="30"
          item-key="id"
          :page.sync="page"
          hide-default-footer
          @page-count="pageCount = $event"
          @dblclick:row="dbClickListRow"
          @click:row="rowClick"
          single-select
          v-model="gridListItemSelected"
        >
          <template v-slot:item.BRD_TIT="{ item }">
            <td>
              <span v-html="item.NBSP"></span>
              <v-icon color="#EF9191" v-if="item.LVL_NO > 0" size="18px">
                mdi-subdirectory-arrow-right
              </v-icon>
              {{ item.BRD_TIT }}
              <v-icon
                color="#BA6EFF"
                v-if="item.BRD_TIT_NEW == 'Y'"
                size="18px"
              >
                mdi-alpha-n-box
              </v-icon>
            </td>
          </template>
        </v-data-table>
        <div class="grid-paging text-center pt-2">
          <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="totalVisible"
            next-icon="svg-paging-next"
            prev-icon="svg-paging-prev"
          ></v-pagination>
        </div>
      </div>
    </div>

    <!-- 새글 쓰기 모달창 -->
    <div class="ml-auto">
      <v-dialog
        v-if="DialogM6101P01"
        persistent
        v-model="DialogM6101P01"
        content-class="square-modal"
        max-width="1400px"
      >
        <dialog-M6101P01
          headerTitle="새글 등록"
          @hide="hideDialog('M6101P01')"
          @Qsave="clickGetList(), hideDialog('M6101P01')"
          :param="M6101P01Param"
        >
        </dialog-M6101P01>
        <template v-slot:body> </template>
      </v-dialog>

      <v-dialog
        v-if="DialogM6101P02"
        persistent
        v-model="DialogM6101P02"
        content-class="square-modal"
        max-width="1400px"
      >
        <dialog-M6101P02
          headerTitle="상세내용보기"
          @hide="hideDialog('M6101P02')"
          @Qsave="clickGetList(), hideDialog('M6101P02')"
          :param="M6101P02Param"
        >
        </dialog-M6101P02>
        <template v-slot:body> </template>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mixin } from "@/mixin/mixin";
import api from "@/store/apiUtil";
import DialogM6101P01 from "./M6101P01.vue";
import DialogM6101P02 from "./M6101P02.vue";

export default {
  name: "MENU_M6101", //name은 'MENU_' + 파일명 조합
  components: {
    DialogM6101P01,
    DialogM6101P02,
  },
  mixins: [mixin],
  data() {
    return {
      brdId: "",
      inquiryBool: false,

      DialogM6101P01: false,
      DialogM6101P02: false,
      M6101P01Param: {},
      M6101P02Param: {},

      // 조회 셀렉트박스 아이템 리스트
      selBoxBiz: [],
      selBoxBizId: [],

      // 조회 v-model
      selBoxBizV: {},
      selBoxBizIdV: "",
      txtFldTitleV: "",
      txtFldRegV: "",
      txtFldModV: "",

      //리스트
      page: 1,
      pageCount: 0,
      itemsPerPage: 20,
      totalVisible: 10,

      gridListItemSelected: [],
      gridListDataSelect: [],
      gridListDataText: [],
      gridListDataHeaders: [
        {
          text: "No",
          align: "center",
          value: "index",
          width: "70px",
          sortable: true,
        },
        {
          text: "회사명",
          value: "ASP_NEWCUST_KEY",
          align: "center",
          sortable: true,
        }, //COMPANY_NM
        {
          text: "유형",
          value: "BRD_QUICK_TY_NM",
          align: "center",
          width: "80px",
          sortable: true,
        },
        {
          text: "제목",
          value: "BRD_TIT",
          align: "center",
          width: "500px",
          sortable: true,
        },
        {
          text: "파일수",
          value: "FILE_CNT",
          align: "center",
          sortable: true,
        },
        {
          text: "등록자",
          value: "FST_USER_NM",
          align: "center",
          sortable: true,
        },
        {
          text: "등록일시",
          value: "FST_BRD_DT",
          align: "center",
          sortable: true,
        }, //ASP_CUST_KEY
        {
          text: "수정자",
          value: "LAST_USER_NM",
          align: "center",
          sortable: true,
        },
        {
          text: "수정일시",
          value: "LAST_BRD_DT",
          align: "center",
          sortable: true,
        },
        {
          text: "조회수",
          value: "SELECT_NO",
          align: "center",
          sortable: true,
        },
      ],

      alertMsg: {
        selBoxBizChk: "회사구분 은(는) 필수입력입니다.",
        selBoxIdChk: "게시판명 은(는) 필수입력입니다.",
        noCntData: "조회된 데이터가 없습니다.",
        newWriteChk: "조회 후 작성 가능합니다.",
        responseError: "시스템에 오류가 발생하였습니다.",
      },

      RDOnly:false,
    };
  },

  methods: {
    //회사구분 셀렉트박스 선택
    clickSelBoxBizItem() {
      this.inquiryBool = false;
    },

    //게시판 그리드 데이터 조회
    clickGetList() {
      this.inquiryBool = true;

      if (this.selBoxBizV == "" || this.selBoxBizV == null) {
        this.common_alert(this.alertMsg.selBoxBizChk, "error");
        return;
      }

      this.getGridList();
    },

    // 그리드 데이터 호출
    async getGridList() {
      let requestData = {
        headers: {},
        sendData: {},
      };

      requestData.headers["URL"] = "/api/board/list";
      requestData.headers["SERVICE"] = "board";
      requestData.headers["METHOD"] = "list";
      requestData.headers["ASYNC"] = false;
      requestData.headers["TYPE"] = "BIZ_SERVICE";

      //requestData.sendData["BRD_ID"]          = this.$store.getters["userStore/GE_USER_ROLE"].userId;
      requestData.sendData["BRD_ID"] = this.brdId;
      requestData.sendData["ASP_NEWCUST_KEY"] = this.selBoxBizV.CD;
      requestData.sendData["BRD_TIT"] = this.txtFldTitleV;
      requestData.sendData["FST_USER_NM"] = this.txtFldRegV;
      requestData.sendData["LAST_USER_NM"] = this.txtFldModV;
      requestData.sendData["BULTN_FR_DT"] = "";
      requestData.sendData["BULTN_TO_DT"] = "";
      requestData.sendData["NOTICE_STATUS"] = "";

      let responseGridList = await this.common_postCall(requestData);
      let resHeader = responseGridList.HEADER;
      let resData = responseGridList.DATA;

      // 데이터 조회 오류 시 alert
      if (resHeader.ERROR_FLAG == true) {
        this.common_alert(this.alertMsg.responseError, "error");
        return false;
      }

      // 조회 데이터가 없을시 alert
      // if(resHeader.TOT_COUNT == 0) {
      //   this.common_alert(this.alertMsg.noCntData, 'error')
      //   this.gridListDataText = [];
      //   return false;
      // }

      if (resData != 0) {
        let idx = 1;
        for (let i in resData) {
          let id = resData[i].BRD_NO;
          resData[i]["id"] = id;
          resData[i]["index"] = idx++;
          resData[i]["FST_BRD_DT"] = this.parseDateTime(
            resData[i]["FST_BRD_DT"]
          );
          resData[i]["LAST_BRD_DT"] = this.parseDateTime(
            resData[i]["LAST_BRD_DT"]
          );
        }
      }
      this.gridListDataText = resData;
    },

    clickNewWrite() {
      //if(this.selBoxBizV == '' || this.selBoxBizIdV == '' ) {
      if (this.selBoxBizV == "") {
        this.common_alert(this.alertMsg.newWriteChk, "error");
        return;
      }

      if (!this.inquiryBool) {
        this.common_alert(this.alertMsg.newWriteChk, "error");
        return;
      }

      this.M6101P01Param = {
        flag: "new",
        userId: this.$store.getters["userStore/GE_USER_ID"],
        bizCd: this.selBoxBizV.CD,
        bizNm: this.selBoxBizV.CD_NM,
        item: {
          BRD_ID: this.brdId,
        },
        BRD_ID: "2",
      };

      this.showDialog("M6101P01");
      //this.M6101P01Param = {};
    },

    //userTable row 클릭이벤트
    dbClickListRow(e, { item }) {
      this.M6101P02Param = {
        userId: this.$store.getters["userStore/GE_USER_ID"],
        bizCd: this.selBoxBizV.CD,
        bizNm: this.selBoxBizV.CD_NM,
        listData: this.gridListDataText,
        item: item,
      };

      this.showDialog("M6101P02");
      //this.M6101P02Param = {};
    },

    getBrdId() {
      let menuList = {};
      menuList = this.$store.getters["userStore/GE_USER_MENU"];
      // console.log("here!!@@@", menuList);

      // 메뉴정보 배열 구조 파싱
      // for(let i in menuList) {
      //   if(menuList[i].C_NODE_NO === "M6101") {
      //     let tempUrl = menuList[i].NODE_URL;
      //     let idIndex = tempUrl.indexOf("BRD_ID=");
      //     return tempUrl.substring(idIndex+7);
      //   }
      // }

      for (let i in menuList) {
        //게시판 대메뉴
        if (menuList[i].C_NODE_NO === "M61") {
          for (let a in menuList[i].child) {
            //게시판 중메뉴(페이지)
            if (menuList[i].child[a].C_NODE_NO === "M6101") {
              let tempUrl = menuList[i].child[a].NODE_URL_MENUID;
              let idIndex = tempUrl.indexOf("BRD_ID=");

              return tempUrl.substring(idIndex + 7);
            }
          }
        }
      }
    },

    parseDateTime(data) {
      let year = [data.substr(0, 4), data.substr(4, 2), data.substr(6, 2)].join(
        "-"
      );
      let hour = [
        data.substr(8, 2),
        data.substr(10, 2),
        data.substr(12, 2),
      ].join(":");

      return year + " " + hour;
    },

    showDialog(menu) {
      console.log("showDialog");
      this[`Dialog${menu}`] = true;
    },
    hideDialog(menu) {
      console.log("hideDialog");
      this[`Dialog${menu}`] = false;
      this.getGridList();
    },

    rowClick: function (item, row) {
      row.select(true);
    },
  },

  mounted() {
    this.selBoxBiz = this.$store.getters["userStore/GE_USER_COMPANY"];
    this.brdId = this.getBrdId();

    if (this.$store.getters["userStore/GE_USER_ROLE"].CHK_CUST.vue == "M6101") {
      if (this.$store.getters["userStore/GE_USER_ROLE"].CHK_CUST.CHK == 1) {
        let CUST = {
          CD: this.$store.getters["userStore/GE_USER_ROLE"].CHK_CUST.CHK_CD,
          CD_NM: this.$store.getters["userStore/GE_USER_ROLE"].CHK_CUST.CHK_NM,
        };
        this.selBoxBizV = CUST;
        this.clickGetList();

        let initCHK = { CHK: 0, vue: "", CHK_CD: "", CHK_NM: "" };
        this.$store.getters["userStore/GE_USER_ROLE"].CHK_CUST = initCHK;
      }
    }

    let chk = this.mixin_check_AspCustKey_number();
    if(chk == 'Y'){
      this.selBoxBizV.CD = this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD;
      
      api.post("/api/palette/common/getCompanyNM/inqire", {
        ASP_NEWCUST_KEY: this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD,
        ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
        ASP_CUST_KEY: this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD,
        USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
      },
      { 
        head: {
          SERVICE: "palette.common.getCompanyNM",
          METHOD: "inqire",
          TYPE: "BIZ_SERVICE",
        } 
      })
      .then((ret) => {
        this.selBoxBizV.CD_NM = ret.data.DATA[0].COMPANY_NM;
      });

      this.RDOnly = true;
    } else {
      this.RDOnly = false;
    }
  },

  computed: {
    getCUST() {
      return this.$store.getters["userStore/GE_USER_ROLE"].CHK_CUST;
    },
  },

  watch: {
    getCUST(val, oldVal) {
      if (
        this.$store.getters["userStore/GE_USER_ROLE"].CHK_CUST.vue == "M6101"
      ) {
        if (this.$store.getters["userStore/GE_USER_ROLE"].CHK_CUST.CHK == 1) {
          let CUST = {
            CD: this.$store.getters["userStore/GE_USER_ROLE"].CHK_CUST.CHK_CD,
            CD_NM:
              this.$store.getters["userStore/GE_USER_ROLE"].CHK_CUST.CHK_NM,
          };
          this.selBoxBizV = CUST;
          this.clickGetList();

          let initCHK = { CHK: 0, vue: "", CHK_CD: "", CHK_NM: "" };
          this.$store.getters["userStore/GE_USER_ROLE"].CHK_CUST = initCHK;
        }
      }
    },
  },
};
</script>

<style></style>
