<template>
  <v-container fluid>
    <v-btn color="primary" dark @click="testProgressBar()">
      Open Dialog(2초 후 닫힘)
    </v-btn>
  </v-container>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
export default {
  name: "MENU_Progress_bar",
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      progress_bar: "commonStore/GE_COMMON_PROGRESS_BAR",
    }),
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    testProgressBar() {
      this.openProgressBar();

      setTimeout(() => {
        this.closeProgressBar();
      }, 5000);
    },
    openProgressBar() {
      this.$store.dispatch("commonStore/AC_COMMON_PROGRESS_BAR", true);
    },
    closeProgressBar() {
      this.$store.dispatch("commonStore/AC_COMMON_PROGRESS_BAR", false);
    },
  },
  activated() {},
  deactivated() {},
  destroyed() {},
};
</script>

<style></style>
