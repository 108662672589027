<template>
  <div class="modal-wrap">
    <h1 class="tit-h1 d-flex">
      {{ headerTitle }}
      <div class="ml-auto align-self-center">
        <v-icon 
          x-small 
          class="svg-close20" 
          :disabled="this.CTI_PHONE_TRAN === true"
          @click="$emit('hide')"></v-icon>
      </div>
    </h1>
    <div class="modal-box">
      <div class="d-flex gray-box">
        <div>
          <div class="form-inp pt-0" style="width: 150px">
            <v-select
              :items="ASP_CUST_KEY"
              item-value="CD"
              item-text="CD_NM"
              outlined
              hide-details
              label="회사구분"
              placeholder="선택하세요"
              v-model="ASP_NEWCUST_KEY"
              readonly
            >
              <template v-slot:label>
                회사구분
                <v-icon color="#EF9191">mdi-circle-small</v-icon>
              </template>
            </v-select>
          </div>
          <div class="form-inp ml-2 pt-0" style="width: 122px">
             <v-text-field style="width: 162px" name="name" placeholder="내선번호" outlined hide-details v-model="INLNE_NO">
              </v-text-field>
          </div>
          <v-text-field class="form-inp ml-2 pt-0" style="width: 162px" name="name" placeholder="상담사명" outlined hide-details v-model="USER_NM">
          </v-text-field>
        </div>
        <div class="ml-auto align-self-center">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnPopupUser')" dark small class="btn-main-search pa-0" min-width="32px" height="32px" plain v-on:click="btnGetIpIxtnNo">
            <v-icon small class="svg-main-searh white"></v-icon>
          </v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnInitUser')" dark small class="btn-main-refresh pa-0 ml-2" min-width="32px" height="32px" plain v-on:click="btnInit">
            <v-icon small class="svg-main-refresh"></v-icon>
          </v-btn>
        </div>
      </div>
      <div class="mt-3">
        <div class="text-right my-2">
          <span class="badge-sucess">호전환가능</span>
          <span class="badge-disable">호전환불가능</span>
        </div>
        <v-data-table dense :headers="gridDataHeaders" :items="gridDataText" item-key="index"
          :items-per-page="30" :page.sync="page" hide-default-footer @page-count="pageCount = $event"
          class="grid-default">
          <template v-slot:item="row">
            <tr>
              <td class="text-center">{{ row.item.index }}</td>
              <td class="text-center">{{ row.item.ASP_NEWCUST_KEY }}</td>
              <td class="text-center">{{ row.item.CSLT_NM }}</td>
              <td class="text-center">{{ row.item.INLNE_NO }}</td>
              <td class="text-center">{{ row.item.INLNE_STATE }}</td>
              <td class="text-center">
                <v-btn v-if="mixin_set_btn($options.name, 'btnCallCheck')" 
                  outlined small class="btn-default" 
                  :disabled="row.item.disalb === true"
                  @click="btnCallCheck(row.item)">선택</v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
        <div class="grid-paging text-center pt-2">
          <v-pagination v-model="page" :length="pageCount" :total-visible="totalVisible" next-icon="svg-paging-next"
            prev-icon="svg-paging-prev"></v-pagination>
        </div>
        <div class="text-right"><span class="grid-total-count">전체 <strong > {{ gridDataText.length }}건</strong></span></div>
      </div>

      <!-- 2자 통화 -->
      <div class="mt-3 d-flex">
        <div>
          <v-text-field
              class="form-inp sm pt-0"
              name="name"
              label="선택된 내선번호 "
              outlined
              hide-details
              v-model="TRAN_INLNE_NO"
            >
          </v-text-field>
        </div>
        <div class="ml-2 align-self-center mr-0">
          <v-btn 
            v-if="this.mixin_set_btn(this.$options.name, 'btnCall2')" 
            outlined  
            class="btn-default" 
            :disabled="this.CTI_PHONE_TRAN === true"
            @click="[btnCtiPhoneTran(),$emit('ctiPhoneTran')]">2자 통화 걸기</v-btn>
          <v-btn 
            v-if="this.mixin_set_btn(this.$options.name, 'btnCallCancel2')" 
            outlined  
            class="btn-point ml-2" 
            :disabled="this.CTI_PHONE_TRAN === false"
            @click="$emit('ctiPhoneTrno')">취소</v-btn>
          <v-btn 
            v-if="this.mixin_set_btn(this.$options.name, 'btnCallMove1')" 
            outlined  
            class="btn-default ml-2" 
            :disabled="this.CTI_PHONE_TRAN === false"
            @click="$emit('ctiPhoneTrok')">전환</v-btn>
        </div>
        <div class="maincalltrans-modal--time ">
          통화시간 {{ lapseTime }}
        </div>
      </div>
      <!-- 3자 통화 -->
      <div class="mt-3 d-flex">
        <div>
          <v-text-field
              class="form-inp sm pt-0"
              name="name"
              label="선택된 내선번호 "
              outlined
              hide-details
              v-model="CONF_INLNE_NO"
            >
          </v-text-field>
        </div>
        <div class="ml-2 align-self-center mr-0">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnCall3')" outlined class="btn-default "
            :disabled="this.CTI_PHONE_CONF === true"
            @click="[btnCtiPhoneConf(), $emit('ctiPhoneConf')]">3자 통화 걸기</v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnCallCancel3')" outlined  class="btn-point ml-2" 
            :disabled="this.CTI_PHONE_CONF === false"
            @click="$emit('ctiPhoneCfno')">취소</v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnCallMove2')" outlined class="btn-default ml-2" 
            :disabled="this.CTI_PHONE_CONF === false"
            @click="$emit('ctiPhoneCfok')">전환</v-btn>
        </div>
        <div class="maincalltrans-modal--time ">
          통화시간 {{ lapseTime }}
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import api from '../../store/apiUtil.js';
import { mixin } from "@/mixin/mixin.js";
import alertStore from "@/store/modules/alertStore"; //알림
import { eventBus } from "@/store/eventBus.js"; //Event Bus


  export default {
    name: "MENU_M110101P01", //name은 'MENU_' + 파일명 조합
    mixins: [mixin],
    props: {
      headerTitle: {
        type: String,
        default: "제목",
      },
      CTI_TRANSFER_INFO: {
        type: Object,
        value:{},
      },
    },
    data: () => ({
      dropItems: [{
        title: "option1"
      }],
      gridDataHeaders: [
        { text: "No",       value: "index",           align: "center", width: "50px", sortable: false},
        { text: "회사명",   value: "ASP_NEWCUST_KEY", align: "center", sortable: true},
        { text: "상담사",   value: "CSLT_NM",         align: "center", sortable: true},
        { text: "내선번호", value: "INLNE_NO",        align: "center", sortable: true},
        { text: "상태",     value: "INLNE_STATE",     align: "center", sortable: true},
        { text: "",         value: "index05",         align: "center", width: "70px", sortable: false}
      ],
      
      gridDataText: [],
      page: 1,
      pageCount: 0,
      itemsPerPage: 5,
      totalVisible: 10,
      lapseTime: '00:00:00',

      ASP_CUST_KEY : [],                // 회사구분
      ASP_NEWCUST_KEY : "",             // 선택 회사코드 
      
      INLNE_NO : "",                    // 내선번호  
      USER_NM : "",                     // 고객사명  

      TRAN_INLNE_NO : "",               // 2자 내선번호
      CONF_INLNE_NO : "",               // 3자 내선번호 

      CTI_PHONE_TRAN : false,           // 호전환 시도
      CTI_PHONE_CONF : false,           // 3자 시도
    }),
    created() {
      
      /**
       * 호전환 시도 / 취소
       */
      eventBus.$on("busCtiPhoneTran", (e) => {
        console.log("e : " + e);
        this.CTI_PHONE_TRAN = e;
      });

      /**
       * 3자 시도 / 취소
       */
      eventBus.$on("busCtiPhoneConf", (e) => {
        console.log("e : " + e);
        this.CTI_PHONE_CONF = e;
      });

      this.ASP_CUST_KEY    = this.$store.getters['userStore/GE_USER_ROLE'].company;
      this.ASP_NEWCUST_KEY = this.CTI_TRANSFER_INFO.aspCustKey;
    },
    methods: {

      init() {
        this.ASP_NEWCUST_KEY = "";
        this.btnInit();
      },
      
      showAlertWarn(e){
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: true,
          msg: e,
          iconClass: 'svg-error-lg',
          type: 'default'
        })
      },

      /**
       * 상담원 내선번호 조회 
       */
      getIpIxtnNo(){
        
        if (this.ASP_NEWCUST_KEY == "" || this.ASP_NEWCUST_KEY == undefined) {
          this.showAlertWarn("회사를 먼저 선택해주세요.");
          return;
        }
        api.post("api/phone/setting/ip-lxtn-no-managee/list",   //api url입력
        {
          ASP_NEWCUST_KEY: this.ASP_NEWCUST_KEY,
          IP_ADDR: this.IP_NEWADDR,
          CSLT_NM: this.USER_NM,
          INLNE_NO: this.INLNE_NO,
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_CUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
        },
        {head: {
          "SERVICE" : "phone/setting",
          "METHOD" : "/ip-lxtn-no-managee/list",
          "TYPE" : "BIZ_SERVICE",
          "ROW_CNT" : "200",
          "PAGES_CNT" : "1",
        }})
        .then((response) => {
          
          this.gridDataText = response.data.DATA;
          
          let i = 0;
          let gridDataLen = this.gridDataText.length;
          let transferLen = this.CTI_TRANSFER_INFO.transferLst.length;
          
          for(; i < gridDataLen; i++){

            this.gridDataText[i]["index"] = i + 1;
            this.gridDataText[i]["disalb"] = true;

            let inlneNo =  this.gridDataText[i]["INLNE_NO"];
            
            if (transferLen > 0) {
              
              let x = 0;

              for (; x < transferLen; x++) {

                let ext   = this.CTI_TRANSFER_INFO.transferLst[x].ext;
                let state = this.CTI_TRANSFER_INFO.transferLst[x].state;

                if (ext == inlneNo) {
                  
                  this.gridDataText[i]["INLNE_STATE"] = state;

                  if (state == "대기") {
                    this.gridDataText[i]["disalb"] = false;
                  }

                  break;
                }
              }
            }
          }
          
          this.page = 1;
        })
        .catch((err) => {
          alert(err);
        })
      },

      /**
       * 호전환 시도 
       */
      btnCtiPhoneTran() {
         
        let inlneNo    = "";
        let inlneNoLen = 0;

        inlneNo = this.TRAN_INLNE_NO;
        inlneNoLen = inlneNo.length;

        if (inlneNoLen == 4 || inlneNoLen > 10) {
          this.CTI_TRANSFER_INFO.inLineNo = inlneNo;
        } else {
          this.showAlertWarn("2자통화 내선/외부 를 입력하세요");
          return;
        }
      },

      /**
       * 3자 시도 
       */
      btnCtiPhoneConf() {
         
        let inlneNo    = "";
        let inlneNoLen = 0;

        inlneNo = this.CONF_INLNE_NO;
        inlneNoLen = inlneNo.length;

        if (inlneNoLen == 4 ) {
          this.CTI_TRANSFER_INFO.inLineNo = inlneNo;
        } else {
          this.showAlertWarn("3자통화 내선 번호를 입력하세요");
          return;
        }
      },

      /**
       * 검색조건 초기화 
       */
      btnInit() {
        
        this.INLNE_NO = "";                     // 내선번호  
        this.USER_NM = "";                      // 고객사명  

        this.TRAN_INLNE_NO = "";                // 2자 내선번호
        this.CONF_INLNE_NO = "";                // 3자 내선번호 

        this.gridDataText = [];
      },

      /**
       * 호전환 내선번호 선택 
       */
      btnCallCheck(e) {

        let inlneNo    = "";                    // 내선번호
        let inlneState = "";                    // 회선상태

        inlneNo = e.INLNE_NO;
        inlneState = e.INLNE_STATE;

        this.TRAN_INLNE_NO = inlneNo;           // 2자 내선번호
        this.CONF_INLNE_NO = inlneNo;           // 3자 내선번호 
      },
      
      /**
       * 상담원 내선번호 조회 
       */
      btnGetIpIxtnNo() {
        this.getIpIxtnNo();
      },      
    },
    mounted() {
    },
    computed: {},
    watch: {
      
      CTI_TRANSFER_INFO() {
        
        this.init();

        this.CTI_PHONE_TRAN = false;           // 호전환 시도
        this.CTI_PHONE_CONF = false;           // 3자 시도

        this.ASP_CUST_KEY    = this.$store.getters['userStore/GE_USER_ROLE'].company;
        this.ASP_NEWCUST_KEY = this.CTI_TRANSFER_INFO.aspCustKey;
      }
    }


  };
</script>

<style></style>