<template>
  <v-container fluid>
    <div class="d-flex">
    <div class="flex-grow-1">
      <h3>파일 업로드 팝업 호출 시 전달해줄 값</h3>
      <div class="list-box mt-3 pa-3 white">
        fileInfo라는 object에 해당 변수들을 선언하여 사용<br><br>
        회사 구분 : ASP_CUST_KEY<br>
        최대 파일 개수 : multi<br>
        최대 파일 크기 : maxSize<br>
        허용 파일 형식 : accessFileTY<br>
        업로드 uri : upload<br>
        다운로드 uri : download<br>
        해당 파일 경로 : dir<br>
        해당 파일 temp경로 : tempdir<br>
        파일 지정 : target<br>
        파일명 바인딩 : file<br>
        파일 업로드 결과값 : result
      </div>
    </div>
    <div class="flex-grow-0">&nbsp;&nbsp;&nbsp;</div>
    <div class="flex-grow-1">
      <h3>파일 업로드</h3>
      <div class="list-box mt-3 pa-3 white">
        <div class="form-inp sm ml-2">
        <v-text-field
          class="form-inp sm ml-2"
          name="name"
          label="파일 업로드"
          outlined
          hide-details
         v-model="FILE_NM"
          v-on:click="[setFileInfo(),initFile(),showDialog('FileUploadPop')]"
          readonly
        >
        </v-text-field>
        <v-btn
            outlined
            small
            class="btn-etc2 ml-2"
            v-on:click="[setFileInfo(),initFile(),showDialog('FileUploadPop')]"
          >
            파일 업로드
          </v-btn>
        </div>
      </div>
    </div>
    <div class="flex-grow-0">&nbsp;&nbsp;&nbsp;</div>
    <div class="flex-grow-1">
      <h3>파일 업로드 이 후 결과</h3>
      <div class="list-box mt-3 pa-3 white">
        {{fileInfo.result}}
      </div>
    </div>
    </div>

    <!-- 파일 업로드 모달창 -->
    <div class="">
      <v-dialog
        max-width="500"
        max-height="1200"
        persistent
        v-model="DialogFileUploadPop"
        content-class="square-modal"
      >
        <dialog-FileUploadPop
          headerTitle="파일 업로드"
          @hide="hideDialog('FileUploadPop')"
          :fileInfo="fileInfo"
          @input="fileInfo = $event"
          @selectFile="[hideDialog('FileUploadPop'), setFile()]"
        >
        </dialog-FileUploadPop>
        <template v-slot:body> </template>
      </v-dialog>
    </div>
    <!-- 파일 업로드 모달창 -->
  </v-container>
</template>

<script>
import api from "@/store/apiUtil";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { mapGetters } from "vuex";
import DialogFileUploadPop from "./FileUploadPop.vue";

export default {
  name: "FileUpload",
  components: {
    vueDropzone: vue2Dropzone,
    DialogFileUploadPop,
  },
  props: {},
  data() {
    return {
      dropItemRemove: true,

      DialogFileUploadPop: false,
      fileInfo: {},
      FILE_NUM: '1',
      FILE_SIZE: '5',
      FILE_NEWTY:['.jpg', '.png', '.gif', '.pdf'],

      FILE_TYPE: [
        {
            text:'.jpg',
            selected:true,
        },
        {
            text:'.png',
            selected:true,
        },
        {
            text:'.gif',
            selected:true,
        },
        {
            text:'.pdf',
            selected:true,
            isLast: true
        },
      ],

      ASP_CUST_KEY: this.$store.getters["userStore/GE_USER_ROLE"].company,
      ASP_NEWCUST_KEY: this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD,

      UPLOAD_URI:'/api/file/chat/images/uploads',
      DOWNLOAD_URI:'/api/file/chat/images/download',
      DIR:'/PALETTE2/hkcloud/project/deploy/production/repository/web/chat/images',
      TEMP_DIR:'/PALETTE2/hkcloud/project/deploy/production/repository/web/chat/images/temp',
      TARGET_TY:'FILE',
      
      FILE_NM:'',
    };
  },
  computed: {
  },
  watch: {},
  created() {
  },
  mounted() {},
  methods: {
    removeDropitem(index) {
      this.dropItemM.splice(index, 1);
    },

    setFileInfo() {
      var file = { 
        ASP_CUST_KEY: this.ASP_NEWCUST_KEY, 
        multi: this.FILE_NUM, 
        maxSize: this.FILE_SIZE, 
        accessFileTY: this.FILE_NEWTY, 
        upload: this.UPLOAD_URI,
        download: this.DOWNLOAD_URI,
        dir: this.DIR,
        tempdir: this.TEMP_DIR,
        target: this.TARGET_TY,
        file:'' ,
        result: {}};
      this.fileInfo = file;
    },

    initFile(){
      this.FILE_NM = '';
      this.fileInfo.file = '';
      this.fileInfo.result = {};
    },

    setFile() {
      this.FILE_NM = this.fileInfo.file;
    },

    showDialog(menu) {
      if(menu == 'M810706P01'){
        if (this.ASP_NEWCUST_KEY == "" || this.ASP_NEWCUST_KEY == undefined) {
          this.showAlert("선택된 고객사가 없습니다.");
          return;
        }
    }
      this[`Dialog${menu}`] = true;
    },
    hideDialog(menu) {
      this[`Dialog${menu}`] = false;
    },

  },
  activated() {},
  deactivated() {},
  destroyed() {},
};
</script>

<style scoped></style>
