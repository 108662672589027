<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">아웃바운드 등록</h1>
    <div class="box-wrap">
      <div class="tit-wrap">
        <div class="f-l">
          <h2 class="tit-h2 d-flex">아웃바운드 정보</h2>
        </div>
        <div class="f-r">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnInitObd')" outlined class="btn-point" id="btnInit" @click="initData">초기화</v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSave')" outlined class="btn-point ml-2" id="btnSave" @click="processRtn">등록</v-btn>
        </div>
      </div>
      <v-form ref="form" v-model="isFormValid" lazy-validation>
      <div class="table-form">
        <table>
          <caption class="hide">
            아웃바운드 상세정보 테이블
          </caption>
          <colgroup>
              <col width="127px">
              <col width="291px">
              <col width="127px">
              <col width="291px">
              <col width="127px">
              <col width="291px">
              <col width="127px">
              <col width="">
          </colgroup>
          <tbody>
            <tr>
              <th scope="row"><v-badge dot color="#EF9191">회사구분</v-badge></th>
              <td>
                <v-select
                  v-model.trim="formData.ASP_NEWCUST_KEY"
                  class="form-inp full"
                  :items="geUserCompany"
                  item-text="CD_NM"
                  item-value="CD"
                  outlined
                  hide-details="auto"
                  placeholder="선택"
                  required
                  :rules="[formRules.ASP_NEWCUST_KEY.required]"
                >
              </v-select>
              </td>
              <th scope="row"><v-badge dot color="#EF9191">구분</v-badge></th>
              <td>
                <v-select
                  v-model.trim="formData.CAM_TY"
                  class="form-inp full"
                  :items="mixin_common_code_get(this.commonCodeItems, 'RE010')"
                  outlined
                  hide-details="auto"
                  placeholder="선택"
                  required
                  :rules="[formRules.CAM_TY.required]"
                >
                </v-select>
              </td>
              <th scope="row"><v-badge dot color="#EF9191">상세구분</v-badge></th>
              <td>
                  <v-select
                  v-model.trim="formData.BUSI_TY"
                  class="form-inp full"
                  :items="mixin_common_code_get(this.commonCodeItems, 'OU002')"
                  outlined
                  hide-details="auto"
                  placeholder="선택"
                  required
                  :rules="[formRules.BUSI_TY.required]"
                >
                </v-select>
              </td>
              <th scope="row">등록자</th>
              <td>
                {{ geUserName }}
              </td>
            </tr>
            <tr>
              <th scope="row">시작일시</th>
              <td>
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  content-class="calendar-modal"
                  color="#FFF"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model.trim="autoBeginDateFormat"
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      readonly
                      hide-details="auto"
                      class="form-inp full icon-calendar"
                      append-icon="svg-calendar"
                      maxlength="19"
                      :rules="[formRules.CAM_BEGIN_DT.required]"
                    ></v-text-field>
                  </template>
                  <div class="d-flex align-center">
                    <v-date-picker
                      v-model="formData.CAM_BEGIN_DATE"
                      dark
                      color="#F7AFAE"
                      no-title
                      show-current="false"
                      locale="ko-KR"
                      :first-day-of-week="1"
                      :day-format="mixin_getDate"
                      :max="formData.CAM_EOT_DATE"
                    ></v-date-picker>
                    <v-time-picker
                      v-model="formData.CAM_BEGIN_TIME"
                      color="#F7AFAE"
                      format="24hr"
                      @click:minute="$refs.menu1.save()"
                    ></v-time-picker>
                  </div>
                </v-menu>
              </td>
              <th scope="row">종료일시</th>
              <td>
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  content-class="calendar-modal"
                  color="#FFF"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model.trim="autoEotDateFormat"
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      readonly
                      hide-details="auto"
                      class="form-inp full icon-calendar"
                      append-icon="svg-calendar"
                      maxlength="19"
                      :rules="[formRules.CAM_EOT_DT.required]"
                    ></v-text-field>
                  </template>
                  <div class="d-flex align-center">
                    <v-date-picker
                        v-model="formData.CAM_EOT_DATE"
                        dark
                        color="#F7AFAE"
                        no-title
                        show-current="false"
                        locale="ko-KR"
                        :first-day-of-week="1"
                        :day-format="mixin_getDate"
                        :min="formData.CAM_BEGIN_DATE"
                      ></v-date-picker>
                      <v-time-picker
                        v-model="formData.CAM_EOT_TIME"
                        color="#F7AFAE"
                        format="24hr"
                        @click:minute="$refs.menu2.save()"
                      ></v-time-picker>
                    </div>
                </v-menu>
              </td>
              <th scope="row"><v-badge dot color="#EF9191">아웃바운드명</v-badge></th>
              <td colspan="3">
                <v-text-field
                  v-model.trim="formData.CAM_NM"
                  class="form-inp full"
                  maxlength="100"
                  hide-details="auto"
                  outlined
                  required
                  :rules="[formRules.CAM_NM.required]"
                >
                </v-text-field>
              </td>
            </tr>
            <tr>
              <th scope="row">비고</th>
              <td colspan="8">
                <v-textarea
                  v-model.trim="formData.REM"
                  class="textarea-type0"
                  no-resize
                  outlined
                  :rules="textareaRules"
                ></v-textarea>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      </v-form>
    </div>

    <!-- 아웃바운드 리스트 -->
    <div class="box-wrap">
      <div class="tit-wrap">
        <div class="f-l d-flex">
          <h2 class="tit-h2  inlineB">
            아웃바운드 고객 리스트
          </h2>
          <v-menu
            v-model="menu3"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
            content-class="calendar-modal"
            dark
            >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="formData.OBND_SRCH_DATE"
                v-bind="attrs"
                v-on="on"
                outlined
                hide-details="auto"
                class="form-inp sm icon-calendar ml-4"
                append-icon="svg-calendar"
                maxlength="10"
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="formData.OBND_SRCH_DATE"
              color="#f7afae"
              no-title
              @input="menu3 = false"
              locale="ko-KR"
              :first-day-of-week="1"
              :day-format="mixin_getDate"
            ></v-date-picker>
          </v-menu>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnNext')" outlined disabled class="btn-default inlineB ml-2" id="btnNext">
            다음
            <v-icon small>mdi-chevron-right</v-icon>
          </v-btn>
          <v-checkbox
          class="d-inline-flex ml-2"
          :on-icon="'svg-chk-on'"
          :off-icon="'svg-chk-off'"
          label="연속조회"></v-checkbox>
          <div class="total-number inlineB ml-2 d-flex align-center">
            <span>고객추출건수</span>
            <div class="number inlineB">{{ gridObndCustItems.length | inputNumberFormat }}</div>
          </div>
        </div>

        <div class="f-r">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnExcelUpload')" outlined class="btn-point" id="btnExcelUpload" @click="excelUpload">
            엑셀업로드
          </v-btn>
          <v-file-input
            ref="excelRef"
            v-model="fileValue"
            v-show="false"
            @change="excelUploadRun"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            counter
            label="엑셀파일을 선택하세요"
            :show-size="1000"
          />
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnExcelSample')" outlined class="btn-point ml-2" id="btnExcelSample" @click="excelDownload('divGridObndCusList', '아웃바운드 공제단말 고객 리스트')">
            엑셀양식다운로드
          </v-btn>
        </div>
      </div>
      <div v-show="false" class="box-ct mt-3">
        <v-data-table
          ref="gridObndCustListRef"
          id="divGridObndCusList"
          v-model="gridObndCustList"
          height="310px"
          :headers="gridObndCustExcelHeaders"
          :items="gridObndCustItems"
          item-key="index"
          dense
          hide-default-footer
          class="grid-default"
        >
        </v-data-table>
      </div>
      <div class="box-ct mt-3">
        <v-data-table
          v-model="gridObndCustList"
          fixed-header
          hide-default-footer
          dense
          height="380px"
          :headers="gridObndCustHeaders"
          :items="gridObndCustItems"
          :items-per-page="30"
          class="grid-default"
        >
          <template v-slot:[`item.CUST_NO`]="{ item }">
            <td class="text-center">
              {{ maskingFormat(item.CUST_NO, 'JUMIN', '*') }}
            </td>
          </template>
          <template v-slot:[`item.MOBIL_NO`]="{ item }">
            <td class="text-center">
              {{ maskingFormat(item.MOBIL_NO, 'TEL', '*') }}
            </td>
          </template>
          <template v-slot:[`item.CO_TEL_NO`]="{ item }">
            <td class="text-center">
              {{ maskingFormat(item.CO_TEL_NO, 'TEL', '*') }}
            </td>
          </template>
          <template v-slot:[`item.HOUSE_TEL_NO`]="{ item }">
            <td class="text-center">
              {{ maskingFormat(item.HOUSE_TEL_NO, 'TEL', '*') }}
            </td>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { mixin } from "@/mixin/mixin.js"
import XLSX from "xlsx"

export default {
  name: "MENU_M110401", //name은 'MENU_' + 파일명 조합
  mixins: [mixin],
  data() {
    return {
      menu1: false,
      menu2: false,
      menu3: false,
      fileValue: null,
      commonCodeItems: [],
      gridObndCustList: [],
      gridObndCustItems: [],
      gridObndCustHeaders: [
        { text: 'No', value: 'ROW_NUMBER', align: 'center', width: '50px', sortable: true },
        { text: '고객주민번호', value: 'CUST_NO', align: 'center', width: '100px', sortable: true },
        { text: '고객명', value: 'CUST_NM', align: 'center', width: '100px', sortable: true },
        { text: '휴대전화', value: 'MOBIL_NO', align: 'center', width: '100px', sortable: true },
        { text: '직장전화', value: 'CO_TEL_NO', align: 'center', width: '100px', sortable: true },
        { text: '자택전화', value: 'HOUSE_TEL_NO', align: 'center', width: '100px', sortable: true },
        { text: '증권번호', value: 'SECU_NO', align: 'center', width: '100px', sortable: true },
        { text: '직업상세명', value: 'JOB_DTL_NM', align: 'center', width: '100px', sortable: true },
        { text: '판매개시일자', value: 'SALE_START_DATE', align: 'center', width: '100px', sortable: true },
        { text: '갱신일자', value: 'UPDT_DATE', align: 'center', width: '100px', sortable: true, show: true }
      ],
      gridObndCustExcelHeaders: [
        { text: '순번', value: 'ROW_NO', align: 'center', width: '50px', sortable: true },
        { text: '고객주민번호', value: 'CUST_NO', align: 'center', width: '100px', sortable: true },
        { text: '고객명', value: 'CUST_NM', align: 'center', width: '100px', sortable: true },
        { text: '유입구분', value: 'IFLW_TY', align: 'center', width: '100px', sortable: true },
        { text: '휴대전화', value: 'MOBIL_NO', align: 'center', width: '100px', sortable: true },
        { text: '직장전화', value: 'CO_TEL_NO', align: 'center', width: '100px', sortable: true },
        { text: '자택전화', value: 'HOUSE_TEL_NO', align: 'center', width: '100px', sortable: true },
        { text: '증권번호', value: 'SECU_NO', align: 'center', width: '100px', sortable: true },
        { text: '직업상세명', value: 'JOB_DTL_NM', align: 'center', width: '100px', sortable: true },
        { text: '판매개시일자', value: 'SALE_START_DATE', align: 'center', width: '100px', sortable: true },
        { text: '갱신일자', value: 'UPDT_DATE', align: 'center', width: '100px', sortable: true },
        { text: '비고', value: 'REM', align: 'center', width: '100px', sortable: true }
      ],
      mainVo: function() {
        this.ASP_NEWCUST_KEY=''
        this.CAM_TY=''
        this.BUSI_TY=''
        this.CAM_NM=''
        this.CAM_BEGIN_DATE=(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
        this.CAM_BEGIN_TIME='08:00:00'
        this.CAM_EOT_DATE=(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
        this.CAM_EOT_TIME='19:00:00'
        this.REM=''
        this.OBND_SRCH_DATE=(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      },
      formData: {},
      isFormValid: true,
      formRulesSubfix: ' 은(는) 필수입력입니다.',
      formRules: {
        ASP_NEWCUST_KEY: {
          required: v => !!(v  && (v+'').trim()!=='') || '회사구분' + this.formRulesSubfix
        },
        CAM_TY: {
          required: v => !!(v  && (v+'').trim()!=='') || '캠페인구분' + this.formRulesSubfix
        },
        BUSI_TY: {
          required: v => !!(v  && (v+'').trim()!=='') || '상세구분' + this.formRulesSubfix
        },
        CAM_NM: {
          required: v => !!(v  && (v+'').trim()!=='') || '아웃바운드명' + this.formRulesSubfix
        },
        CAM_BEGIN_DT: {
          required: v => !!(v  && (v+'').trim()!=='') || '시작시간' + this.formRulesSubfix
        },
        CAM_EOT_DT: {
          required: v => !!(v  && (v+'').trim()!=='') || '종료시간' + this.formRulesSubfix
        },
      },
      textareaRules: [(v) => (v || '').length <= 200 || 'Max 200 characters']
    }
  },
  created() {
    this.formData = new this.mainVo()
  },
  mounted() {
    this.initData()
  },
  methods: {
    async initData() {
      this.gridObndCustList = []
      this.gridObndCustItems = []
      this.fileValue = null
      this.$refs.form.resetValidation()
      this.formData = new this.mainVo()
      let pArr = ['RE010','OU002']
      this.commonCodeItems = await this.mixin_common_code_get_all(pArr)
    },
    isDup(obj, trgtKey) {
      return new Set(obj.map(v => v[trgtKey])).size !== obj.length
    },
    processRtn() {
      if(!this.$refs.form.validate()) return false
      if(this.gridObndCustItems.length < 1) {
        this.common_confirm(`등록 할 고객이 없습니다.<br/>그래도 아웃바운드를 등록 하시겠습니까 ?`, this.processRtnRun)
      }else {
        this.common_confirm(`아웃바운드를 등록 하시겠습니까 ?`, ()=>{
          const gridId = this.$refs.gridObndCustListRef.$attrs.id
          let gridData = JSON.parse(JSON.stringify(this.gridObndCustItems))
          let errMsg = ''
          gridData.forEach((v,i) => {
            if(this.mixin_isEmpty(v.CUST_NO)) {
              errMsg = `고객 주민번호는 필수값입니다.`
              return false
            }
            if(this.mixin_isEmpty(v.CUST_NM)) {
              errMsg = `고객 명은 필수값입니다.`
              return false
            }
            v.CUST_NO = this.mixin_isEmpty(v.CUST_NO) ? '' : v.CUST_NO.replace(/[^0-9]/gi,'')
            v.MOBIL_NO = this.mixin_isEmpty(v.MOBIL_NO) ? '' : v.MOBIL_NO.replace(/[^0-9]/gi,'')
            v.CO_TEL_NO = this.mixin_isEmpty(v.CO_TEL_NO) ? '' : v.CO_TEL_NO.replace(/[^0-9]/gi,'')
            v.HOUSE_TEL_NO = this.mixin_isEmpty(v.HOUSE_TEL_NO) ? '' : v.HOUSE_TEL_NO.replace(/[^0-9]/gi,'')
            v[gridId+'_ROWNUM'] = i+1
            v['DATA_FLAG'] = 'I'
          })
          if(!this.mixin_isEmpty(errMsg)) {
            this.common_alert(errMsg, 'error')
            return false
          }
          if(this.isDup(gridData, 'CUST_NO')) {
            this.common_alert(`고객리스트에 중복된 값이 있습니다.<br/>다시 확인하고 등록해 주세요.`,'error')
            return false
          }
          this.processRtnRun(gridData)
        })
      }
    },
    async processRtnRun(gridData) {
      let reqData = {
        headers: {},
        sendData: {}
      }
      reqData.headers['SERVICE'] = 'phone.outbound.regist'
      reqData.headers['METHOD'] = 'process'
      reqData.headers['TYPE'] = 'BIZ_SERVICE'
      reqData.headers['URL'] = '/api/phone/outbound/regist/process'
      reqData.headers['ASYNC'] = true
      let params = JSON.parse(JSON.stringify(this.formData))
      for(let d in params) {
        if(d.indexOf('_DATE')>-1 || d.indexOf('_TIME')>-1) {
          params[d] = params[d].replace(/[^0-9]/g, '')
        }else if(d === 'REM') {
          params[d] = this.restoreXSS_Dec(params[d])
        }
      }
      params['IN_DATE'] = params.OBND_SRCH_DATE
      params['REG_MAN'] = this.geUserRole.userId
      params['CHNG_MAN'] = this.geUserRole.userId
      params['ASP_USER_ID'] = this.geUserRole.userId
      reqData.sendData = params
      if(gridData) {
        reqData.headers['DATA_OBJECT'] = 'OBND_CUST_LIST'
        reqData.sendData['OBND_CUST_LIST'] = gridData
      }
      let res = await this.common_postCall(reqData)
      if(!this.mixin_isEmpty(res) && !res.HEADER.ERROR_FLAG) {
        this.common_alert(`정상적으로 등록되었습니다.`, 'done')
        this.initData()
      }else {
        this.common_alert(`시스템에 오류가 발생하였습니다.`, 'error')
      }
    },
    formatDate(date){
      if (!date) return ''
      const [year, month, day] = date.split('-')
      return `${year}/${month}/${day}`
    },
    allowedStep: (m) => m % 10 === 0,
    showDialog(type) {
      this[`dialog${type}`] = true
    },
    hideDialog(type) {
      this[`dialog${type}`] = false
    },
    excelUpload() {
      this.$refs.excelRef.$refs.input.click()
    },
    excelUploadRun(file) {
      const fileName = file.name
      const reader = new FileReader()
      reader.onload = (e) => {
        var buffer = reader.result
        var data = new Uint8Array(buffer)
        var arr = new Array()
        for (var i = 0; i != data.length; ++i)
          arr[i] = String.fromCharCode(data[i])
        var bstr = arr.join("")
        var workbook = XLSX.read(bstr, {
          type: "binary",
          cellDates: true,
        })
        var first_sheet_name = workbook.SheetNames[0]
        var worksheet = workbook.Sheets[first_sheet_name]
        var getData = XLSX.utils.sheet_to_json(worksheet, { header:2, raw:false, blankRows:false, defval:'' })
        if(getData.length > 0) {
          getData.map(v => {
            let map = {}
            this.gridObndCustExcelHeaders.forEach((j,i) => {
              if(j.text === Object.keys(v)[i]){
                map[j.value] = v[Object.keys(v)[i]]
              }
            })
            map['ROW_NUMBER'] = this.gridObndCustItems.length + 1
            this.gridObndCustItems.push(map)
          })
        }
      }
      reader.readAsArrayBuffer(file)
    },
    maskingFormat(v, type, maskStr){
      if(this.mixin_isEmpty(v)) return ''
      let resStr = ''
      switch(type){
        case 'JUMIN':
          let rrnStr = v.match(/(?:[0-9]{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[1,2][0-9]|3[0,1]))-[1-4]{1}[0-9]{6}\b/gi)
          if(!this.mixin_isEmpty(rrnStr)){
            resStr = v.toString().replace(rrnStr,rrnStr.toString().replace(/(-?)([1-4]{1})([0-9]{6})\b/gi, '$1$2'+maskStr.repeat(6)))
          }else{
            rrnStr = v.match(/\d{13}/gi)
            if(!this.mixin_isEmpty(rrnStr)){
              resStr = v.toString().replace(rrnStr,rrnStr.toString().replace(/([0-9]{6})$/gi, maskStr.repeat(6)))
            }else{
              resStr = v
            }
          }
          break

        case 'TEL':
          let phoneStr = ''
          if(v.toString().split('-').length != 3){
            phoneStr = v.length < 11 ? v.match(/\d{10}/gi) : v.match(/\d{11}/gi)
            if(v.length < 11){
              resStr = v.toString().replace(phoneStr, phoneStr.toString().replace(/(\d{3})(\d{3})(\d{4})/gi,'$1'+maskStr.repeat(3)+'$3'))
            }else{
              resStr = v.toString().replace(phoneStr, phoneStr.toString().replace(/(\d{3})(\d{4})(\d{4})/gi,'$1'+maskStr.repeat(4)+'$3'))
            }
          }else{
            phoneStr = v.match(/\d{2,3}-\d{3,4}-\d{4}/gi)
            if(/-[0-9]{3}-/.test(phoneStr)){
              resStr = v.toString().replace(phoneStr, phoneStr.toString().replace(/-[0-9]{3}-/g, '-'+maskStr.repeat(3)+'-'))
            }else if(/-[0-9]{4}-/.test(phoneStr)){
              resStr = v.toString().replace(phoneStr, phoneStr.toString().replace(/-[0-9]{4}-/g, '-'+maskStr.repeat(4)+'-'))
            }
          }
          break
      }
      return resStr
    },
    excelDownload(table_id, file_name) {
      this.table_id = table_id
      this.file_name = file_name
      this.mixin_common_exportExcel()
    }
  },
  filters: {
    inputNumberFormat(v){
      return String(v).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
  },
  computed: {
    ...mapGetters({
      geUserName: 'userStore/GE_USER_NAME',
      geUserRole: 'userStore/GE_USER_ROLE',
      geUserCompany: 'userStore/GE_USER_COMPANY'
    }),
    autoBeginDateFormat(){
      let camBeginDate = this.mixin_isEmpty(this.formData.CAM_BEGIN_DATE) ? '' : this.formatDate(this.formData.CAM_BEGIN_DATE)
      let camBeginTime = this.mixin_isEmpty(this.formData.CAM_BEGIN_TIME) ? '' : (this.formData.CAM_BEGIN_TIME.length==5 ? this.formData.CAM_BEGIN_TIME+':00' : this.formData.CAM_BEGIN_TIME)
      return camBeginDate + ' ' + camBeginTime
    },
    autoEotDateFormat(){
      let camEotDate = this.mixin_isEmpty(this.formData.CAM_EOT_DATE) ? '' : this.formatDate(this.formData.CAM_EOT_DATE)
      let camEotTime = this.mixin_isEmpty(this.formData.CAM_EOT_TIME) ? '' : (this.formData.CAM_EOT_TIME.length==5 ? this.formData.CAM_EOT_TIME+':00' : this.formData.CAM_EOT_TIME)
      return camEotDate + ' ' + camEotTime
    },
  },
}
</script>

<style></style>
