<template>
  <section class="charts">
    <!-- 스타일과 클래스, 그래프 옵션은 자식컴포넌트에 바인딩 가능하다 -->
    <vue-highcharts :options="bar_options" ref="barChart"></vue-highcharts>
    <vue-highcharts
      class="mt-3"
      :options="colume_options"
      ref="columnChart"
    ></vue-highcharts>
  </section>
</template>

<script>
import VueHighcharts from "./HighChart";

const basicData = {
  chart: {
    type: "bar",
  },
  title: {
    text: "막대 그래프 샘플",
  },
  subtitle: {
    text: "bar",
  },
  xAxis: {
    categories: ["월요일", "화요일", "수요일", "목요일", "금요일"],
    title: {
      text: null,
    },
  },
  yAxis: {
    min: 0,
    title: {
      text: "총처리건수",
      align: "high",
    },
    labels: {
      overflow: "justify",
    },
  },
  tooltip: {
    valueSuffix: " 건",
  },
  plotOptions: {
    bar: {
      dataLabels: {
        enabled: true,
      },
    },
  },
  legend: {
    layout: "vertical",
    align: "right",
    verticalAlign: "top",
    x: -40,
    y: 80,
    floating: true,
    borderWidth: 1,
    backgroundColor: "#FFFFFF",
    shadow: true,
  },
  credits: {
    enabled: false,
  },
  series: [],
};

//basicData 데이터와 동일하지만 chart.type 만 다르다
const basicData1 = {
  chart: {
    type: "column",
  },
  title: {
    text: "막대 그래프 샘플",
  },
  subtitle: {
    text: "column",
  },
  xAxis: {
    categories: ["월요일", "화요일", "수요일", "목요일", "금요일"],
    title: {
      text: null,
    },
  },
  yAxis: {
    min: 0,
    title: {
      text: "총처리건수",
      align: "high",
    },
    labels: {
      overflow: "justify",
    },
  },
  tooltip: {
    valueSuffix: " 건",
  },
  plotOptions: {
    bar: {
      dataLabels: {
        enabled: true,
      },
    },
  },
  legend: {
    layout: "vertical",
    align: "right",
    verticalAlign: "top",
    x: -40,
    y: 80,
    floating: true,
    borderWidth: 1,
    backgroundColor: "#FFFFFF",
    shadow: true,
  },
  credits: {
    enabled: false,
  },
  series: [],
};
export default {
  components: {
    VueHighcharts,
  },
  data() {
    return {
      bar_options: basicData,
      colume_options: basicData1,
    };
  },
  created() {},
  mounted() {
    this.reflow();
  },
  methods: {
    //그래프를 100% 사이즈로 그리기 위하여 컴포넌트가 활성화 된 직후 다시 그리기를 해준다
    reflow() {
      let barChart = this.$refs.barChart;
      setTimeout(() => {
        barChart.chart.reflow();

        //샘플데이터 업데이트
        this.setData();
      }, 500);

      let columnChart = this.$refs.columnChart;
      setTimeout(() => {
        columnChart.chart.reflow();
      }, 500);
    },
    setData() {
      let barChart = this.$refs.barChart;
      let columnChart = this.$refs.columnChart;

      barChart.removeSeries();
      columnChart.removeSeries();

      barChart.delegateMethod("showLoading", "Loading...");
      columnChart.delegateMethod("showLoading", "Loading...");

      //샘플데이터 가공
      // {
      //   name: "InBound",
      //   data: [22, 20, 35, 42, 45],
      // },
      // {
      //   name: "OutBound",
      //   data: [43, 32, 22, 20, 22],
      // },
      let set_data = [];
      for (let i = 0; i < 2; i++) {
        let inner_set_dat = {};
        let array_data = [];
        let date = ["InBound", "OutBound"];
        inner_set_dat["name"] = date[i];
        for (let i = 0; i < 5; i++) {
          array_data.push(Math.floor(Math.random() * 40));
        }
        inner_set_dat["data"] = array_data;
        set_data.push(inner_set_dat);
      }

      setTimeout(() => {
        set_data.forEach((element) => barChart.addSeries(element));
        set_data.forEach((element) => columnChart.addSeries(element));
        barChart.hideLoading();
        columnChart.hideLoading();
      }, 1000);

      //다시 호출
      setTimeout(() => {
        this.setData();
      }, 5000);
    },
  },
};
</script>
