<template>
  <v-container fluid>
    <div class="d-flex flex-column">
      <div>
        <h3>엑셀 업로드</h3>
      </div>
      <div>
        <v-file-input
          @change="uploadExcel"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          counter
          label="엑셀파일을 선택하세요"
          :show-size="1000"
        />
      </div>
      <div>
        <v-data-table
          :headers="upload_headers"
          :items="upload_bodys"
          class="elevation-1"
          dense
          dark
        >
        </v-data-table>
      </div>
      <div class="grey lighten-3 pa-3 mt-3">
        {{ upload_bodys }}
      </div>
      <div class="mt-6">
        <h3>엑셀 다운로드</h3>
        <div class="ml-auto mt-3">
          <v-btn
            outlined
            class="btn-point white--text cyan"
            @click="
              downloadExcel('excelDownloadSampleTable', '엑셀다운로드 샘플')
            "
          >
            <v-icon small>mdi-arrow-collapse-down</v-icon>
            엑셀다운로드</v-btn
          >
        </div>
      </div>
      <div class="mt-3">
        <v-data-table
          dense
          :headers="download_headers"
          :items="download_bodys"
          id="excelDownloadSampleTable"
          dark
        >
        </v-data-table>
      </div>

      <div class="mt-6">
        <h3>엑셀 양식 다운로드(다운로드/업로드 세트)</h3>
        <div class="ml-auto mt-3">
          <v-btn
            outlined
            class="btn-point white--text cyan"
            @click="downloadExcelTemplate('엑셀 양식 다운로드', 'excel_sample')"
          >
            <v-icon small>mdi-arrow-collapse-down</v-icon>
            엑셀 양식 다운로드</v-btn
          >
          <v-file-input
            @change="uploadExcelTemplate"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            counter
            label="엑셀파일을 선택하세요"
            :show-size="1000"
          />
        </div>
      </div>
      <div class="mt-3">
        <v-data-table
          dense
          :headers="upload_excel_template_header"
          :items="upload_excel_template_body"
          id="excelDownloadTempletTable"
          dark
          hide-default-footer
        >
        </v-data-table>
        <div class="grey lighten-3 pa-3 mt-3">
          {{ upload_excel_template_body }}
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mixin } from "@/mixin/mixin.js";
import XLSX from "xlsx";
export default {
  name: "MENU_Excel",
  mixins: [mixin],
  props: {},
  data() {
    return {
      upload_headers: [], //업로드 테이블 헤더
      upload_bodys: [], //업로드 테이블 바디
      download_headers: [
        { text: "No", value: "index", align: "center", sortable: false },
        {
          text: "아웃바운드명",
          value: "index01",
          align: "center",
          sortable: false,
        },
        { text: "총건수", value: "index02", align: "center", sortable: false },
        {
          text: "배분건수",
          value: "index03",
          align: "center",
          sortable: false,
        },
        {
          text: "미배분건수",
          value: "index04",
          align: "center",
          sortable: false,
        },
        {
          text: "실행시작일자",
          value: "index05",
          align: "center",
          sortable: false,
        },
        {
          text: "실행종료일자",
          value: "index06",
          align: "center",
          sortable: false,
        },
        { text: "비고", value: "index07", align: "center", sortable: false },
        {
          text: "처리완료건수",
          value: "index08",
          align: "center",
          sortable: false,
        },
        {
          text: "처리미완료건수",
          value: "index09",
          align: "center",
          sortable: false,
        },
        {
          text: "처리불가건수",
          value: "index10",
          align: "center",
          sortable: false,
        },
        {
          text: "처리중건수",
          value: "index11",
          align: "center",
          sortable: false,
        },
        {
          text: "최종시도실패",
          value: "index12",
          align: "center",
          sortable: false,
        },
        {
          text: "기타(메시지별)",
          value: "index13",
          align: "center",
          sortable: false,
        },
      ],
      download_bodys: [
        {
          index: 1,
          index01: "한국클라우드1",
          index02: "1",
          index03: "11",
          index04: "11",
          index05: "2021/09/09",
          index06: "2021/09/09",
          index07: "test",
          index08: "0",
          index09: "0",
          index10: "0",
          index11: "0",
          index12: "0",
          index13: "0",
        },
        {
          index: 2,
          index01: "한국클라우드2",
          index02: "1",
          index03: "11",
          index04: "11",
          index05: "2021/09/09",
          index06: "2021/09/09",
          index07: "test",
          index08: "0",
          index09: "0",
          index10: "0",
          index11: "0",
          index12: "0",
          index13: "0",
        },
        {
          index: 3,
          index01: "한국클라우드3",
          index02: "1",
          index03: "11",
          index04: "11",
          index05: "2021/09/09",
          index06: "2021/09/09",
          index07: "test",
          index08: "0",
          index09: "0",
          index10: "0",
          index11: "0",
          index12: "0",
          index13: "0",
        },
      ],
      excel_template_header: {
        //다운로드 엑셀 템플릿 헤더 셋팅
        // DB에 사용되는 데이터 ID(json 데이터의 key값)
        // 화면에 사용되는 데이터 TEXT
        id: "아이디",
        name: "이름",
        phone: "전화번호",
        addr: "주소",
        etc: "기타",
      }, //엑셀 템플릿 관리 헤더
      excel_template_body: [
        //다운로드 엑셀 템플릿 바디 셋팅(예제 데이터 넣을때 데이터를 넣고. 없으면 빈 배열을 넣으면 된다)
        ["A1", "B1", "C1", "D1", "E1"],
        ["A2", "B2", "C2", "D2", "E2"],
        ["A3", "B3", "C3", "D3", "E3"],
        ["A4", "B4", "C4", "D4", "E4"],
        ["A5", "B5", "C5", "D5", "E5"],
      ], //엑셀 템플릿 관리 바디
      upload_excel_template_header: [], //엑셀 템플릿으로 업로드된 데이터 헤더
      upload_excel_template_body: [], //엑셀 템플릿으로 업로드된 데이터 바디
      upload_excel_template_body_view: [], //엑셀 템플릿으로 업로드된 데이터 바디
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    //엑셀 업로드
    //엑셀의 첫번째 줄이 json데이터의 key값이 되고 두번째줄이 value값이 된다
    //  No	/ 아웃바운드명	/ 총건수    <-- key
    //  1	  / 한국클라우드	/ 12        <-- value
    //  2	  / KPP         	/ 21        <-- value
    uploadExcel(file) {
      (this.upload_headers = []),
        (this.upload_bodys = []),
        // get File object from input tag
        console.log(file);
      // const file = files[0];
      const fileName = file.name;
      const reader = new FileReader();

      reader.onload = (e) => {
        var buffer = reader.result;
        var data = new Uint8Array(buffer);
        var arr = new Array();
        for (var i = 0; i != data.length; ++i)
          arr[i] = String.fromCharCode(data[i]);
        var bstr = arr.join("");
        var workbook = XLSX.read(bstr, {
          type: "binary",
          cellDates: true,
          // dateNF: "YYYY-MM-DD HH:mm:ss",
        });
        var first_sheet_name = workbook.SheetNames[0];
        var worksheet = workbook.Sheets[first_sheet_name];

        var getData = XLSX.utils.sheet_to_json(worksheet, { raw: true });
        this.upload_bodys = getData;

        if (this.upload_bodys.length > 0) {
          var keys = Object.keys(this.upload_bodys[0]); //키를 가져옵니다. 이때, keys 는 반복가능한 객체가 됩니다.
          for (var a = 0; a < keys.length; a++) {
            var jsonData = {
              text: keys[a],
              value: keys[a],
            };
            this.upload_headers.push(jsonData);
          }
        }
      };
      reader.readAsArrayBuffer(file);
    },
    //엑셀 다운로드
    //v-data-table에 id를 설정한후 id와 다운받을 엑셀명과 같이 전달한다
    downloadExcel(table_id, file_name) {
      //mixin에 셋팅된 method 호출
      this.table_id = table_id;
      this.file_name = file_name;
      this.common_exportExcel();
    },
    downloadExcelTemplate(file_name, sheet_name) {
      //mixin에 셋팅된 method 호출
      this.file_name = file_name;

      //엑셀 템플릿 다운로드
      this.mixin_common_exportExcel_template(
        file_name,
        sheet_name,
        this.excel_template_header,
        this.excel_template_body
      );
    },
    uploadExcelTemplate(file) {
      (this.upload_headers = []),
        (this.upload_bodys = []),
        // get File object from input tag
        console.log(file);
      // const file = files[0];
      const fileName = file.name;
      const reader = new FileReader();

      reader.onload = (e) => {
        var buffer = reader.result;
        var data = new Uint8Array(buffer);
        var arr = new Array();
        for (var i = 0; i != data.length; ++i)
          arr[i] = String.fromCharCode(data[i]);
        var bstr = arr.join("");
        var workbook = XLSX.read(bstr, {
          type: "binary",
          cellDates: true,
          // dateNF: "YYYY-MM-DD HH:mm:ss",
        });
        var first_sheet_name = workbook.SheetNames[0];
        var worksheet = workbook.Sheets[first_sheet_name];

        //header 셋팅
        var keys = Object.keys(this.excel_template_header); //키를 가져옵니다. 이때, keys 는 반복가능한 객체가 됩니다.
        for (var a = 0; a < keys.length; a++) {
          var jsonData = {
            text: this.excel_template_header[keys[a]],
            value: keys[a],
          };
          this.upload_excel_template_header.push(jsonData);
        }

        //body 셋팅
        var getData = XLSX.utils.sheet_to_json(worksheet, { raw: true });
        console.log(
          "this.upload_excel_template_header=",
          this.upload_excel_template_header
        );
        // var set_keys = Object.keys(this.upload_excel_template_header[0]);
        let set_keys = [];
        let set_ids = [];
        for (let i = 0; i < this.upload_excel_template_header.length; i++) {
          set_keys.push(this.upload_excel_template_header[i].text);
          set_ids.push(this.upload_excel_template_header[i].value);
        }

        console.log("set_keys=", set_keys);
        console.log("set_ids=", set_ids);
        console.log("getData=", getData);
        for (let i = 0; i < getData.length; i++) {
          var set_jsonData = {};
          for (var b = 0; b < set_keys.length; b++) {
            console.log("i=", i);
            console.log("set_ids[b]=", set_ids[b]);
            console.log("getData[i][set_keys[b]=", getData[i][set_keys[b]]);
            set_jsonData[set_ids[b]] = getData[i][set_keys[b]];
          }
          console.log("set_jsonData=", set_jsonData);
          console.log("##############################");
          this.upload_excel_template_body.push(set_jsonData);
        }

        console.log(
          "upload_excel_template_body=",
          this.upload_excel_template_body
        );
        // this.upload_excel_template_body = getData;
      };
      reader.readAsArrayBuffer(file);
    },
  },
  activated() {},
  deactivated() {},
  destroyed() {},
};
</script>

<style scoped>
.preview-excel-upload-code {
  padding: 10px 15px;
}
</style>
