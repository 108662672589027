<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">채팅 상담이력관리</h1>
    <div class="box-wrap">
      <div class="box-ct d-flex">
        <div class>
          <div class="form-inp sm">
            <v-select v-model="selected_channel" :items="asp_new_cust_Key" item-value="CD" item-text="CD_NM" outlined
              hide-details label="회사구분" v-on:change="chnASP"
              :readonly="RDOnly">
              <template v-slot:label>
                회사구분
                <v-icon color="#EF9191">mdi-circle-small</v-icon>
              </template>
            </v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select v-model="srhDttm" :items="
              mixin_common_code_get(this.common_code, 'TALK031', '전체')
            " outlined hide-details label="조회구분" filled dense background-color="white"></v-select>
          </div>
          <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
            offset-y min-width="auto" content-class="calendar-modal" color="#FFF">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="dateRangeText" v-bind="attrs" v-on="on" readonly outlined hide-details
                class="form-inp icon-calendar ml-2" append-icon="svg-calendar"></v-text-field>
            </template>
            <div class="datepicker-range">
              <v-date-picker v-model="dates[0]" no-title dark color="#F7AFAE" name="startDate" @change="startDate"
                :first-day-of-week="1" locale="ko-KR" :day-format="mixin_getDate"></v-date-picker>
              <v-date-picker v-model="dates[1]" dark color="#F7AFAE" no-title show-current="false" :min="dates[0]"
                name="endDate" @change="endDate" :first-day-of-week="1" locale="ko-KR" :day-format="mixin_getDate">
              </v-date-picker>
            </div>
            <div class="text-center pt-3 pb-3">
              <v-btn outlined class="btn-default" @click="menu1 = false">확인</v-btn>
            </div>
          </v-menu>
          <div class="form-inp sm ml-2">
            <v-select :items="srhCallTypCd" item-text="title" outlined hide-details label="채널" placeholder="선택하세요">
            </v-select>
          </div>
          <v-text-field class="form-inp sm ml-2" name="userId" label="사용자 ID" v-model="userId" outlined hide-details>
          </v-text-field>
          <v-text-field class="form-inp sm ml-2" name="userNm" label="사용자 이름" v-model="userNm" outlined hide-details>
          </v-text-field>
          <v-text-field class="form-inp sm ml-2" name="userNick" label="닉네임" v-model="userNick" outlined hide-details>
          </v-text-field>
          <!-- 조회 버튼 -->
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnPopupUser')" dark small
            class="btn-main-search pa-0 ml-2" min-width="32px" height="32px" plain
            @click="btnUser(), showDialog('MP02')">
            <v-icon small class="svg-main-searh white"></v-icon>
          </v-btn>
          <!-- 새로고침 버튼 -->
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnInitUser')" dark small
            class="btn-main-refresh pa-0 ml-2" min-width="32px" height="32px" @click="clear0" plain>
            <v-icon small class="svg-main-refresh"></v-icon>
          </v-btn>

          <div class="mt-2">
            <div class="form-inp sm">
              <v-select v-model="select_inqry_code_1" :items="inqry_code_1" item-text="CD_NM" item-value="CD"
                background-color="white" @change="changeInqryKey('HKCTALKMNG', select_inqry_code_1, '1')"
                no-data-text="전체" outlined hide-details label="문의유형1" placeholder="선택하세요"></v-select>
            </div>
            <div class="form-inp sm ml-2">
              <v-select v-model="select_inqry_code_2" :items="inqry_code_2" item-text="CD_NM" item-value="CD"
                background-color="white" @change="changeInqryKey('HKCTALKMNG', select_inqry_code_2, '2')"
                no-data-text="전체" outlined hide-details label="문의유형2" placeholder="선택하세요"></v-select>
            </div>
            <div class="form-inp sm ml-2">
              <v-select v-model="talkStatCd" :items="
                mixin_common_code_get(this.common_code, 'TALK034', '전체')
              " outlined hide-details label="상담상태" filled dense background-color="white"></v-select>
            </div>
            <div class="form-inp sm ml-2">
              <v-select :items="srhAspSenderKey" item-text="title" outlined hide-details label="카카오채널"
                placeholder="선택하세요"></v-select>
            </div>
            <v-text-field class="form-inp sm ml-2" v-model="talkContactId" name="talkContactId" label="상담ID"
              id="talkContactId" outlined hide-details></v-text-field>
            <div class="form-inp sm ml-2">
              <v-select v-model="cnsltypcd" :items="srhCnslTypCd" item-text="CD_NM" item-value="CD" outlined
                hide-details label="상담유형1" @change="changeCouselTyeKey(asp_cust_key, cnsltypcd, '1')"
                placeholder="선택하세요"></v-select>
            </div>
            <div class="form-inp sm ml-2">
              <v-select v-model="cnsltypcd2" :items="srhCnslTypCd2" item-text="CD_NM" item-value="CD" outlined
                hide-details label="상담유형2" @change="changeCouselTyeKey(asp_cust_key, cnsltypcd2, '2')"
                placeholder="선택하세요"></v-select>
            </div>
            <div class="form-inp sm ml-2">
              <v-select v-model="cnsltypcd3" :items="srhCnslTypCd3" item-text="CD_NM" item-value="CD" outlined
                hide-details label="상담유형3" @change="changeCouselTyeKey(asp_cust_key, cnsltypcd3, '3')"
                placeholder="선택하세요"></v-select>
            </div>
            <div class="form-inp sm ml-2">
              <v-select v-model="cnsltypcd4" :items="srhCnslTypCd4" item-text="CD_NM" item-value="CD" outlined
                hide-details label="상담유형4" @change="changeCouselTyeKey(asp_cust_key, cnsltypcd4, '4')"
                placeholder="선택하세요"></v-select>
            </div>
            <v-text-field class="form-inp ml-2" v-model="talkQst" name="talkQst" label="상담제목" id="talkContactId"
              outlined hide-details></v-text-field>
          </div>
          <div class="mt-2"></div>
        </div>
        <div class="ml-auto align-self-center">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')" outlined class="btn-etc2"
            v-on:click="search">조회</v-btn>
        </div>
      </div>
    </div>
    <!-- 상담이력 목록 -->
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">상담이력 목록</h2>
      <div class="box-ct">
        <v-data-table dense @click:row="detail" height="360px" :headers="gridDataHeaders" :items="gridDataText"
          :items-per-page="30" item-key="index" :page.sync="page" hide-default-header hide-default-footer fixed-header
          class="grid-default" @page-count="pageCount = $event" single-select :item-class="isActiveRow">
          <template v-slot:item.TALK_DIST_DT="{ item }">
            <div>{{ yyyyMMddHHMISS(item.TALK_DIST_DT) }}</div>
          </template>
          <template v-slot:item.TALK_START_DT="{ item }">
            <div>{{ yyyyMMddHHMISS(item.TALK_START_DT) }}</div>
          </template>
          <template v-slot:item.TALK_POST_DT="{ item }">
            <div>{{ yyyyMMddHHMISS(item.TALK_POST_DT) }}</div>
          </template>
          <template v-slot:item.TALK_END_DT="{ item }">
            <div>{{ yyyyMMddHHMISS(item.TALK_END_DT) }}</div>
          </template>
          <template v-slot:header>
            <thead class="v-data-table-header">
              <tr>
                <th scope="row" rowspan="2" class="text-center" style="min-width: 10px"></th>
                <th scope="row" rowspan="2" class="text-center" style="min-width: 30px">
                  NO
                </th>
                <th scope="row" rowspan="2" class="text-center" style="min-width: 100px">
                  메신저명
                </th>
                <th scope="row" rowspan="2" class="text-center" style="min-width: 100px">
                  회원/비회원
                </th>
                <th scope="row" rowspan="2" class="text-center" style="min-width: 100px">
                  고객ID
                </th>
                <th scope="row" rowspan="2" class="text-center" style="min-width: 100px">
                  고객명
                </th>
                <th scope="row" rowspan="2" class="text-center" style="min-width: 100px">
                  상담상태
                </th>
                <th scope="row" colspan="2" class="text-center" style="min-width: 200px">
                  문의유형
                </th>
                <th scope="row" colspan="4" class="text-center" style="min-width: 400px">
                  상담유형
                </th>
                <th scope="row" rowspan="2" class="text-center" style="min-width: 100px">
                  상담제목
                </th>
                <th scope="row" rowspan="2" class="text-center" style="min-width: 100px">
                  최초상담사
                </th>
                <th scope="row" rowspan="2" class="text-center" style="min-width: 100px">
                  최종상담사
                </th>
                <th scope="row" rowspan="2" class="text-center" style="min-width: 140px">
                  상담요청일시
                </th>
                <th scope="row" rowspan="2" class="text-center" style="min-width: 140px">
                  상담시작일시
                </th>
                <th scope="row" rowspan="2" class="text-center" style="min-width: 140px">
                  상담종료일시
                </th>
                <th scope="row" rowspan="2" class="text-center" style="min-width: 140px">
                  상담완료일시
                </th>
                <th scope="row" rowspan="2" class="text-center" style="min-width: 100px">
                  내용
                </th>
              </tr>
              <tr>
                <th scope="row" class="text-center" style="min-width: 100px">
                  1단계
                </th>
                <th scope="row" class="text-center" style="min-width: 100px">
                  2단계
                </th>
                <th scope="row" class="text-center" style="min-width: 100px">
                  1단계
                </th>
                <th scope="row" class="text-center" style="min-width: 100px">
                  2단계
                </th>
                <th scope="row" class="text-center" style="min-width: 100px">
                  3단계
                </th>
                <th scope="row" class="text-center" style="min-width: 100px">
                  4단계
                </th>
              </tr>
            </thead>
          </template>
          <template v-slot:item.CONTENT_VIEW="{ item }">
            <!--
              버튼권한을 줄 수 없음
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnHear')" outlined small class="btn-point ml-2">녹취</v-btn>
            -->
            <v-btn outlined small class="btn-point ml-2" @click="showDialog('Chat')">카카오톡</v-btn>
          </template>
        </v-data-table>
        <!-- 채팅내역 모달 -->
        <v-dialog
          max-width="655"
          v-model="dialogChat"
          content-class="square-modal modal-white min-auto">
          <dialog-Chat headerTitle="채팅내역" @hide="hideDialog('Chat')"></dialog-Chat>
          <template v-slot:body></template>
        </v-dialog>

        <div class="grid-paging text-center pt-2">
          <v-pagination v-model="page" :length="pageCount" :total-visible="totalVisible" next-icon="svg-paging-next"
            prev-icon="svg-paging-prev"></v-pagination>
        </div>
      </div>
    </div>
    <!--// 상담이력목록 -->
    <!-- 상담이력상세 -->
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">
        상담이력상세
        <div class="ml-auto">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSave')" outlined class="btn-point ml-2"
            v-on:click="modify">저장</v-btn>
        </div>
      </h2>
      <div class="box-ct">
        <div class="table-form">
          <table>
            <caption class="hide">
              상담이력상세
            </caption>
            <colgroup>
              <col width="127px" />
              <col width="226px" />
              <col width="127px" />
              <col width="226px" />
              <col width="127px" />
              <col width="226px" />
              <col width="127px" />
              <col width="226px" />
              <col width="127px" />
              <col width="" />
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">인입카카오톡채널</th>
                <td colspan="2">
                  <v-text-field v-model="contactDetail.ASP_SENDER_KEY_NM" class="form-inp full" :disabled="true"
                    outlined hide-details id="detailAspSenderKeyNm"></v-text-field>
                </td>
                <th scope="row">상담결과</th>
                <td colspan="2">
                  <v-text-field v-model="contactDetail.EXEC_RST_NM" class="form-inp full" :disabled="true" outlined
                    hide-details id="detailExecRstNm"></v-text-field>
                </td>
                <th scope="row">문의서비스</th>
                <td>
                  <v-text-field v-model="contactDetail.TALK_INQRY_NM_1" class="form-inp full" :disabled="true" outlined
                    hide-details id="detailTalkInqryNm1"></v-text-field>
                </td>
                <th scope="row">문의유형</th>
                <td>
                  <v-text-field v-model="contactDetail.TALK_INQRY_NM_2" class="form-inp full" :disabled="true" outlined
                    hide-details id="detailTalkInqryNm2"></v-text-field>
                </td>
              </tr>
              <tr>
                <th scope="row">상담유형</th>
                <td>
                  <v-text-field v-model="contactDetail.CNSL_TYP_NM" class="form-inp full" :disabled="true" outlined
                    hide-details id="detailCnslTypNm"></v-text-field>
                </td>
                <td>
                  <v-text-field v-model="contactDetail.CNSL_TYP_NM_2" class="form-inp full" :disabled="true" outlined
                    hide-details id="detailCnslTypNm2"></v-text-field>
                </td>
                <td>
                  <v-text-field v-model="contactDetail.CNSL_TYP_NM_3" class="form-inp full" :disabled="true" outlined
                    hide-details id="detailCnslTypNm3"></v-text-field>
                </td>
                <td colspan="2">
                  <v-text-field v-model="contactDetail.CNSL_TYP_NM_4" class="form-inp full" :disabled="true" outlined
                    hide-details id="detailCnslTypNm4"></v-text-field>
                </td>
                <th scope="row">최초사용자</th>
                <td>
                  <v-text-field v-model="contactDetail.USER_ID_NM" class="form-inp full" :disabled="true" outlined
                    hide-details id="detailUserIdNm"></v-text-field>
                </td>
                <th scope="row">최종사용자</th>
                <td>
                  <v-text-field v-model="contactDetail.TRANS_USER_ID_NM" class="form-inp full" :disabled="true" outlined
                    hide-details id="detailTramsUserIdNm"></v-text-field>
                </td>
              </tr>
              <tr>
                <th scope="row">상담상태</th>
                <td colspan="2">
                  <v-text-field v-model="contactDetail.TALK_STAT_NM" class="form-inp full" :disabled="true" outlined
                    hide-details id="detailTalkStatNm"></v-text-field>
                </td>
                <th scope="row">상담접수일시</th>
                <td colspan="2">
                  <v-text-field :value="yyyyMMddHHMISS(contactDetail.TALK_DIST_DT)" class="form-inp full"
                    :disabled="true" outlined hide-details id="detailTalkDistDt"></v-text-field>
                </td>
                <th scope="row">상담제목</th>
                <td>
                  <v-text-field v-model="contactDetail.TALK_QST" class="form-inp full" id="detailtalkQst"
                    :disabled="false" outlined hide-details></v-text-field>
                </td>
                <th scope="row">상담ID</th>
                <td>
                  <v-text-field v-model="contactDetail.TALK_CONTACT_ID" class="form-inp full" :disabled="true" outlined
                    hide-details id="detailtalkContactId"></v-text-field>
                </td>
              </tr>

              <tr>
                <th scope="row">상담시작일시</th>
                <td colspan="2">
                  <v-text-field :value="yyyyMMddHHMISS(contactDetail.TALK_START_DT)" class="form-inp full"
                    :disabled="true" outlined hide-details id="detailtalkStartDt"></v-text-field>
                </td>
                <th scope="row">상담종료일시</th>
                <td colspan="2">
                  <v-text-field :value="yyyyMMddHHMISS(contactDetail.TALK_POST_DT)" class="form-inp full"
                    :disabled="true" outlined hide-details id="detailtalkPostDt"></v-text-field>
                </td>
                <th scope="row" rowspan="3">상담내용</th>
                <td rowspan="3" colspan="4">
                  <v-textarea class="textarea-type0" height="110px" label :value="contactDetail.TALK_ANS"
                    id="detailTalkAns" no-resize outlined></v-textarea>
                </td>
              </tr>
              <tr>
                <th scope="row">고객대기시간</th>
                <td colspan="2">
                  <v-text-field v-model="contactDetail.READY_TIME" class="form-inp full" :disabled="true" outlined
                    hide-details id="detailReadyTime"></v-text-field>
                </td>
                <th scope="row">상담소요시간</th>
                <td colspan="2">
                  <v-text-field v-model="contactDetail.CHAT_TIME" class="form-inp full" :disabled="true" outlined
                    hide-details id="detailChatTime"></v-text-field>
                </td>
              </tr>
              <tr>
                <th scope="row">후처리시간</th>
                <td colspan="2">
                  <v-text-field v-model="contactDetail.AFTER_TIME" class="form-inp full" :disabled="true" outlined
                    hide-details id="detailAfterTime"></v-text-field>
                </td>
                <th scope="row">총소요시간</th>
                <td colspan="2">
                  <v-text-field v-model="contactDetail.TOT_TIME" class="form-inp full" :disabled="true" outlined
                    hide-details id="detailTotTime"></v-text-field>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!--// 상담이력상세 -->
    <div calss>
      <v-dialog max-width="1400" max-height="1400" v-model="dialogMP02" content-class="square-modal">
        <dialog-MP02 headerTitle="사용자정보" @hide="hideDialog('MP02')" :userInfo="userInfo" @input="userInfo = $event"
          @selectUser="[hideDialog('MP02'), setUser()]"></dialog-MP02>
        <template v-slot:body></template>
      </v-dialog>
    </div>
  </div>
</template>

<script>
  import {
    mixin
  } from "@/mixin/mixin.js";
  import axios from "axios";
  import api from "../../store/apiUtil.js";
  import {
    mapGetters
  } from "vuex";
  import dialogMP02 from "./MP02.vue";
  import dialogChat from "./ChatHistoryModal.vue";
  export default {
    name: "MENU_M110204", //name은 'MENU_' + 파일명 조합
    mixins: [mixin],
    components: {
      dialogMP02,
      dialogChat
    },
    data() {
      return {
        menu1: false,
        dialogMP02: false,
        dialogChat: false,
        dates: [
          new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
          new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        ],
        talkQst: "",
        talkContactId: "",
        srhDttm: "",
        cnsltypcd: "",
        cnsltypcd2: "",
        cnsltypcd3: "",
        cnsltypcd4: "",
        talkStatCd: "",
        selected_channel: "",
        userInfo: {},
        asp_new_cust_Key: this.$store.getters["userStore/GE_USER_ROLE"].company,
        srhCallTypCd: [{
          title: "카카오톡"
        }],
        common_code: [],
        srhInqryTypCd: [], //문의유형1
        srhInqryTypCd2: [],
        srhAspSenderKey: [{
          value: "HKCTALKMNG",
          title: "한국클라우드"
        }], //카카오채널
        srhCnslTypCd: [], //상담유형
        srhCnslTypCd2: [], //상담유형2
        srhCnslTypCd3: [], //상담유형3
        srhCnslTypCd4: [], //상담유형4
        contactDetail: [], //상세이력

        userId: "",
        userNm: "",
        userNick: "",
        /** 문의유형 3depth 변수 */
        inqry_code_1: [],
        select_inqry_code_1: "",
        inqry_code_2: [],
        select_inqry_code_2: "",
        inqry_code_3: [],
        select_inqry_code_3: "",

        gridDataHeaders: [{
            align: "center",
            width: "20px",
            sortable: true,
          },
          {
            text: "NO",
            value: "ROW_NUMBER",
            align: "center",
            width: "30px",
            sortable: false,
          },
          {
            text: "메신저명",
            value: "ASP_SENDER_KEY_NM",
            align: "center",
            sortable: true,
            width: "100px",
          },
          {
            text: "회원/비회원",
            value: "CUSTOMER_MEMBER",
            align: "center",
            sortable: true,
            width: "100px",
          },
          {
            text: "고객ID",
            value: "CUSTOMER_ID",
            align: "center",
            sortable: true,
            width: "100px",
            cellClass: "ellipse",
          },
          {
            text: "고객명",
            value: "CUSTOMER_NM",
            align: "center",
            sortable: true,
            width: "100px",
          },
          {
            text: "상담상태",
            value: "TALK_STAT_NM",
            align: "center",
            sortable: true,
            width: "100px",
          },
          {
            text: "1단계",
            value: "TALK_INQRY_NM_1",
            align: "center",
            sortable: true,
            width: "100px",
            cellClass: "ellipse",
          },
          {
            text: "2단계",
            value: "TALK_INQRY_NM_2",
            align: "center",
            sortable: true,
            width: "100px",
            cellClass: "ellipse",
          },
          {
            text: "상담유형 1단계",
            value: "CNSL_TYP_NM",
            align: "center",
            sortable: true,
            width: "100px",
            cellClass: "ellipse",
          },
          {
            text: "상담유형 2단계",
            value: "CNSL_TYP_NM_2",
            align: "center",
            sortable: true,
            width: "100px",
            cellClass: "ellipse",
          },
          {
            text: "상담유형 3단계",
            value: "CNSL_TYP_NM_3",
            align: "center",
            sortable: true,
            width: "100px",
            cellClass: "ellipse",
          },
          {
            text: "상담유형 4단계",
            value: "CNSL_TYP_NM_4",
            align: "center",
            sortable: true,
            width: "100px",
            cellClass: "ellipse",
          },
          {
            text: "상담제목",
            value: "TALK_QST",
            align: "left",
            sortable: true,
            width: "150px",
            cellClass: "ellipse",
          },
          {
            text: "최초상담사",
            value: "USER_ID_NM",
            align: "center",
            sortable: true,
            width: "100px",
            cellClass: "ellipse",
          },
          {
            text: "최종상담사",
            value: "TRANS_USER_ID_NM",
            align: "center",
            sortable: true,
            width: "100px",
            cellClass: "ellipse",
          },
          {
            text: "상담요청일시",
            value: "TALK_DIST_DT",
            align: "center",
            sortable: true,
            width: "140px",
          },
          {
            text: "상담시작일시",
            value: "TALK_START_DT",
            align: "center",
            sortable: true,
            width: "140px",
          },
          {
            text: "상담종료일시",
            value: "TALK_POST_DT",
            align: "center",
            sortable: true,
            width: "140px",
          },
          {
            text: "상담완료일시",
            value: "TALK_END_DT",
            align: "center",
            sortable: true,
            width: "140px",
          },
          {
            text: "내용",
            value: "CONTENT_VIEW",
            align: "left",
            sortable: true,
            width: "150px",
            cellClass: "ellipse",

          },
          {
            text: "상담ID",
            value: "TALK_CONTACT_ID",
            align: " d-none",
            sortable: true,
            width: "100px",
          },
          {
            text: "카카오고객키",
            value: "TALK_USER_KEY",
            align: " d-none",
            sortable: true,
            width: "100px",
          },
          {
            text: "CALLID",
            value: "CALL_ID",
            align: " d-none",
            sortable: true,
            width: "100px",
          },
          {
            text: "이메일",
            value: "EMAIL",
            align: " d-none",
            sortable: true,
            width: "100px",
          },
          {
            text: "상담ID",
            value: "TALK_CONTACT_ID",
            align: " d-none",
            sortable: true,
            width: "100px",
          },
          {
            text: "상담결과",
            value: "EXEC_RST_NM",
            align: " d-none",
            sortable: true,
            width: "100px",
          },
          {
            text: "문의서비스",
            value: "CHANNEL_NM",
            align: " d-none",
            sortable: true,
            width: "100px",
          },
          {
            text: "메모",
            value: "CUSTOMER_MEMO",
            align: " d-none",
            sortable: true,
            width: "100px",
          },
          {
            text: "플러스친구KEY",
            value: "ASP_SENDER_KEY",
            align: " d-none",
            sortable: true,
            width: "100px",
          },
          {
            text: "봇서비스명",
            value: "BOT_SERVICE_NM",
            align: " d-none",
            sortable: true,
            width: "100px",
          },
          {
            text: "UID",
            value: "TID",
            align: " d-none",
            sortable: true,
            width: "100px",
          },
          {
            text: "고객대기시간",
            value: "READY_TIME",
            align: " d-none",
            sortable: true,
            width: "100px",
          },
          {
            text: "상담소요시간",
            value: "CHAT_TIME",
            align: " d-none",
            sortable: true,
            width: "100px",
          },
          {
            text: "후처리시간",
            value: "AFTER_TIME",
            align: " d-none",
            sortable: true,
            width: "100px",
          },
          {
            text: "총소요시간",
            value: "TOT_TIME",
            align: " d-none",
            sortable: true,
            width: "100px",
          },
        ],
        gridDataText: [],
        page: 1,
        pageCount: 0,
        itemsPerPage: 20,
        totalVisible: 10,
        pagination: {
          page: 1,
          rowsPerPage: 200,
          sortBy: "",
          descending: ""
        },
        textareaRules: [(v) => v.length <= 200 || "Max 200 characters"],
        textareaValue: "200자 제한!",
        height: "50px",
        
        RDOnly:false,
      };
    },

    async mounted() {
      //공통코드설정
      let codeName = ["TALK031", "TALK034"];
      this.common_code = await this.mixin_common_code_get_all(codeName);

      //문의유형
      this.initInqry();

      //상담유형
      this.srhCnslTypCd.push({
        CD_NM: "전체",
        CD: ""
      });
      this.srhCnslTypCd2.push({
        CD_NM: "전체",
        CD: ""
      });
      this.srhCnslTypCd3.push({
        CD_NM: "전체",
        CD: ""
      });
      this.srhCnslTypCd4.push({
        CD_NM: "전체",
        CD: ""
      });

      let chk = this.mixin_check_AspCustKey_number();
      if(chk == 'Y'){
        this.selected_channel = this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD;
        this.RDOnly = true;
      } else {
        this.RDOnly = false;
      }
    },

    methods: {
      async chnASP() {
        this.srhCnslTypCd = await this.mixin_counsel_type_code_get(
          this.selected_channel,
          "*",
          "전체"
        );
      },
      startDate(e) {
        this.dates[0] = e;
      },
      endDate(e) {
        this.dates[1] = e;
      },
      clear0() {
        console.log("run clear0");
        this.userId = "";
        this.userNm = "";
        this.userNick = "";
      },
      btnUser() {
        var user = {
          ASP_CUST_KEY: this.selected_channel,
          USER_ID: this.userId,
          USER_NM: this.userNm,
          USER_NICK: this.userNick,
        };
        this.userInfo = user;
      },
      setUser() {
        this.userId = this.userInfo.USER_ID;
        this.userNm = this.userInfo.USER_NM;
        this.userNick = this.userInfo.USER_NICK;
        // this.userId1 = this.userInfo.USER_ID;
        // this.userNm1 = this.userInfo.USER_NM;
        // this.userNick1 = this.userInfo.USER_NICK;
      },
      showDialog(type) {
        //팝업 파라미터 설정
        this.popupParams;
        this[`dialog${type}`] = true;
      },
      hideDialog(type) {
        this[`dialog${type}`] = false;
      },
      submitDialog(type) {
        this[`dialog${type}`] = false;
      },
      //상세내역
      detail(event, {
        item
      }) {
        this.contactDetail = item;
      },
      //년월로 변환
      yyyyMMdd: function (value) {
        // 들어오는 value 값이 공백이면 그냥 공백으로 돌려줌
        if (value == "") return "";

        // 현재 Date 혹은 DateTime 데이터를 javaScript date 타입화
        var js_date = new Date(value);

        // 연도, 월, 일 추출
        var year = js_date.getFullYear();
        var month = js_date.getMonth() + 1;
        var day = js_date.getDate();

        // 월, 일의 경우 한자리 수 값이 있기 때문에 공백에 0 처리
        if (month < 10) {
          month = "0" + month;
        }

        if (day < 10) {
          day = "0" + day;
        }

        // 최종 포맷 (ex - '2021-10-08')
        return year + "" + month + "" + day;
      },

      yyyyMMddHHMISS(yyyyMMddHHMISS) {
        //접수시간 포맷
        var newTime = "";
        if (!yyyyMMddHHMISS) return "";

        if (yyyyMMddHHMISS.length == 14) {
          newTime =
            yyyyMMddHHMISS.substring(0, 4) +
            "/" +
            yyyyMMddHHMISS.substring(4, 6) +
            "/" +
            yyyyMMddHHMISS.substring(6, 8) +
            " " +
            yyyyMMddHHMISS.substring(8, 10) +
            ":" +
            yyyyMMddHHMISS.substring(10, 12) +
            ":" +
            yyyyMMddHHMISS.substring(12, 14);
          return newTime;
        } else {
          return yyyyMMddHHMISS;
        }
      },

      async initInqry() {
        //문의유형 대분류 조회
        this.inqry_code_1 = await this.mixin_inqry_code_get("HKCTALKMNG", "*");
        this.inqry_code_1.unshift({
          CD_NM: "전체",
          CD: ""
        });
        this.inqry_code_2.unshift({
          CD_NM: "전체",
          CD: ""
        });
      },

      //문의유형 중/소분류 조회
      async changeInqryKey(asp_cust_key, spst_inqry_typ_cd, data_type) {
        //data_type: 1  -> 대분류 변경시 중분류 조회
        //data_type: 2  -> 중분류 변경시 소분류 조회

        if (data_type === "1") {
          //대분류 변경시 중소분류 선택값 초기화
          this.select_inqry_code_2 = "";
          this.select_inqry_code_3 = "";
          this.inqry_code_2 = await this.mixin_inqry_code_get(
            asp_cust_key,
            spst_inqry_typ_cd,
            ""
          );
          this.inqry_code_2.unshift({
            CD_NM: "전체",
            CD: ""
          });
        } else if (data_type === "2") {
          //중분류 변경시 소분류 선택값 초기화
          this.select_inqry_code_3 = "";
          this.inqry_code_3 = await this.mixin_inqry_code_get(
            asp_cust_key,
            spst_inqry_typ_cd,
            ""
          );
        }
      },

      warningAlert(text) {
        let alertText = "항목을 선택해주세요.";

        if (text != null && text != "") {
          alertText = text;
        }
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: true,
          msg: alertText,
          iconClass: "svg-error-lg",
          type: "default",
        });
      },

      //상세내용 수정
      modify() {
        if (this.contactDetail.length == 0) {
          this.warningAlert();
          return;
        }

        let head = "";
        let dataa = "";
        this.URLData = "/api/chat/history/manage/modify";
        this.HEADER_SERVICE = "chat.history.manage";
        this.HEADER_METHOD = "modify";
        this.HEADER_TYPE = "BIZ_SERVICE";

        //api 호출 부분
        api
          .post(
            this.URLData, {
              TALK_CONTACT_ID: this.contactDetail.TALK_CONTACT_ID,
              ASP_SENDER_KEY_NM: this.contactDetail.ASP_SENDER_KEY_NM,
              EXEC_RST_NM: this.contactDetail.EXEC_RST_NM,
              TALK_INQRY_NM_1: this.contactDetail.TALK_INQRY_NM_1,
              TALK_INQRY_NM_2: this.contactDetail.TALK_INQRY_NM_2,
              CNSL_TYP_NM: this.contactDetail.CNSL_TYP_NM,
              CNSL_TYP_NM_2: this.contactDetail.CNSL_TYP_NM_2,
              CNSL_TYP_NM_3: this.contactDetail.CNSL_TYP_NM_3,
              CNSL_TYP_NM_4: this.contactDetail.CNSL_TYP_NM_4,
              USER_ID_NM: this.contactDetail.USER_ID_NM,
              TRANS_USER_ID_NM: this.contactDetail.TRANS_USER_ID_NM,
              TALK_STAT_NM: this.contactDetail.TALK_STAT_NM,
              TALK_DIST_DT: this.contactDetail.TALK_DIST_DT,
              TALK_QST: detailtalkQst.value,
              TALK_START_DT: this.contactDetail.TALK_START_DT,
              TALK_POST_DT: this.contactDetail.TALK_POST_DT,
              TALK_ANS: detailTalkAns.value,
              READY_TIME: this.contactDetail.READY_TIME,
              CHAT_TIME: this.contactDetail.CHAT_TIME,
              AFTER_TIME: this.contactDetail.AFTER_TIME,
              TOT_TIME: this.contactDetail.TOT_TIME,
              PROC_ID: "test01",
              AMDR_ID: "test01",
              ASP_NEWCUST_KEY: "HKCTALKMNG",
            }, {
              head: {
                SERVICE: this.HEADER_SERVICE,
                METHOD: this.HEADER_METHOD,
                TYPE: this.HEADER_TYPE,
                ROW_CNT: "20",
                PAGES_CNT: "1",
              },
            }
          ) //api에 로그를 남기기위한 값입력 (필수) (SERVICE, METHOD, TYPE)
          .then((response) => {
            //let jsonObj = JSON.parse(JSON.stringify(response.data.DATA));
            //this.gridDataText = jsonObj;
            //console.log(JSON.stringify(response.data));
            //head = JSON.stringify(response.data.HEADER);
            //dataa = JSON.stringify(response.data.DATA);
            this.contactDetail = [];

            this.common_alert("정상적으로 수정되었습니다.");
            this.search();
          }) //api 호출 성공 이후 수행
          .catch((err) => {
            alert(err);
          }); //api호출 에러 작업 수행

        this.HEADERResult = head;
        this.DATAResult = dataa;
        //결과값 보여주기
      },
      //상담유형ChangeEvent
      async changeCouselTyeKey(asp_cust_key, spst_coundel_typ_cd, data_type) {
        if (data_type === "1") {
          this.srhCnslTypCd2 = [{
            CD_NM: "전체",
            CD: ""
          }];
          this.srhCnslTypCd3 = [{
            CD_NM: "전체",
            CD: ""
          }];
          this.srhCnslTypCd4 = [{
            CD_NM: "전체",
            CD: ""
          }];
          this.cnsltypcd2 = "";
          this.cnsltypcd3 = "";
          this.cnsltypcd4 = "";
          this.srhCnslTypCd2 = await this.mixin_counsel_type_code_get(
            this.selected_channel,
            spst_coundel_typ_cd,
            "전체"
          );
        } else if (data_type === "2") {
          this.srhCnslTypCd3 = [{
            CD_NM: "전체",
            CD: ""
          }];
          this.srhCnslTypCd4 = [{
            CD_NM: "전체",
            CD: ""
          }];
          this.cnsltypcd3 = "";
          this.cnsltypcd4 = "";
          this.srhCnslTypCd3 = await this.mixin_counsel_type_code_get(
            this.selected_channel,
            spst_coundel_typ_cd,
            "전체"
          );
        } else if (data_type === "3") {
          this.srhCnslTypCd4 = [{
            CD_NM: "전체",
            CD: ""
          }];
          this.cnsltypcd4 = "";
          this.srhCnslTypCd4 = await this.mixin_counsel_type_code_get(
            this.selected_channel,
            spst_coundel_typ_cd,
            "전체"
          );
        }
      },

      //조회
      async search() {
        this.URLData = "/api/chat/history/manage/list";
        this.HEADER_SERVICE = "chat.history.manage";
        this.HEADER_METHOD = "list";
        this.HEADER_TYPE = "BIZ_SERVICE";

        let head = "";
        let dataa = "";

        await api
          .post(
            this.URLData, {
              ASP_NEWCUST_KEY: this.selected_channel,
              ASP_CUST_KEY: this.selected_channel,
              SEARCH_FROM: this.yyyyMMdd(this.dates[0]),
              SEARCH_TO: this.yyyyMMdd(this.dates[1]),
              SRH_CALL_TYP_CD: "",
              USER_ID: this.userId,
              USER_NM: this.userNm,
              USER_NICK: this.userNick,
              SRH_INQRY_TYP_CD: this.select_inqry_code_1,
              SRH_INQRY_TYP_CD2: this.select_inqry_code_1,
              TALK_STAT_CD: this.talkStatCd,
              SRH_ASP_SENDER_KEY: "",
              TALK_CONTACT_ID: this.talkContactId,
              SRH_CNSL_TYP_CD: this.cnsltypcd,
              SRH_CNSL_TYP_CD_2: this.cnsltypcd2,
              SRH_CNSL_TYP_CD_3: this.cnsltypcd3,
              SRH_CNSL_TYP_CD_4: this.cnsltypcd4,
              TALK_QST: this.talkQst,
              SRH_DTTM: this.srhDttm,
              USER: "",
            }, //api에서 사용될 data 입력
            {
              head: {
                SERVICE: this.HEADER_SERVICE,
                METHOD: this.HEADER_METHOD,
                TYPE: this.HEADER_TYPE,
                ROW_CNT: this.pagination.rowsPerPage,
                PAGES_CNT: this.pagination.page,
              },
            }
          )
          .then((response) => {
            let jsonObj = JSON.parse(JSON.stringify(response.data.DATA));
            this.gridDataText = jsonObj;

            //console.log(JSON.stringify(response.data));
            head = JSON.stringify(response.data.HEADER);
            dataa = JSON.stringify(response.data.DATA);
          }) //api 호출 성공 이후 수행
          .catch((err) => {
            alert(err);
          }); //api호출 에러 작업 수행

        this.HEADERResult = head;
        this.DATAResult = dataa;
        //결과값 보여주기
      },
      isActiveRow(item) {
        const activeClass =
          item.ROW_NUMBER === this.contactDetail.ROW_NUMBER ? "active" : "";
        return activeClass;
      },
    },
    computed: {
      dateRangeText() {
        var newStartDate = this.dates[0];
        var newEndDate = this.dates[1];
        return newStartDate + " ~ " + newEndDate;
      },
      ...mapGetters({
        aspCustList: "userStore/GE_USER_COMPANY",
      }),
    },
  };
</script>

<style></style>
