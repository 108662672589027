<template>
  <main>
    <div class="layout-subcontainer">
      <div class="box-wrap d-flex">
        <div class="col-6 pa-0">
          <h1 class="tit-h1 d-flex">
            사업지 관리
            <!-- 조회 버튼 -->
            <div class="ml-auto align-self-center">
              <v-btn class="btn-etc2" outlined small @click="getBussList">조회</v-btn>
            </div>
          </h1>
          <!-- 조회 -->
          <div class="box-ct mt-2">
            <div class="table-form d-flex">
              <table>
                <caption class="hide">
                  조회 항목
                </caption>
                <colgroup>
                  <col width="100px" />
                  <col width="180px" />
                  <col width="100px" />
                  <col width="280px" />
                  <col width="100px" />
                  <col width="130px" />
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="row">
                      건설사명
                    </th>
                    <td>
                      <div class="form-inp full">
                        <v-autocomplete
                          v-model="CTCP_CODE"
                          :items="dropCtcpCd"
                          item-text="CD_NM"
                          item-value="CD"
                          outlined
                          hide-details
                          hide-no-data
                          placeholder="선택하세요"
                          @change="changeBussCode(CTCP_CODE)"
                        >
                        </v-autocomplete>
                      </div>
                    </td>
                    <th scope="row">
                      사업지명
                    </th>
                    <td>
                      <div class="form-inp full">
                        <v-autocomplete
                          v-model="BUSS_CD"
                          :items="dropBussCd"
                          item-text="CD_NM"
                          item-value="CD"
                          outlined
                          hide-details
                          hide-no-data
                          placeholder="선택하세요"
                          :disabled="dropBussCd.length === 0 ? true : false"
                        >
                        </v-autocomplete>
                      </div>
                    </td>
                    <th scope="row">
                      구분
                    </th>
                    <td>
                      <div class="form-inp full">
                        <v-select
                          v-model="S_PART_TYPE"
                          :items="sPartTypeCds"
                          item-text="CD_NM"
                          item-value="CD"
                          outlined
                          hide-details
                          placeholder="선택하세요"
                        >
                        </v-select>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- 사업지 목록 -->
          <div class="box-ct mt-3">
            <h1 class="tit-h1 d-flex">
              사업지 목록
            </h1>
            <div class="mt-2">
              <v-data-table
                dense
                v-model="selectedBussGridDataList"
                :headers="bussGridDataHeaders"
                :items="bussGridDataText"
                :items-per-page="itemsPerPage"
                item-key="BUSS_CODE"
                :item-class="isActiveBussRow"
                :page.sync="page"
                hide-default-footer
                @page-count="pageCount = $event"
                fixed-header
                class="grid-default"
                height="400px"
                no-data-text="검색된 결과가 없습니다."
                @click:row="bussGridRowClick"
              >
              </v-data-table>
              <div class="grid-paging text-center pt-2">
                <span class="grid-total-count">총 <strong>{{ bussGridDataText.length }}</strong>건</span>
                <v-pagination
                  v-model="page"
                  :length="pageCount"
                  :total-visible="totalVisible"
                  next-icon="svg-paging-next"
                  prev-icon="svg-paging-prev"
                ></v-pagination>
              </div>
            </div>
          </div>
          <!-- 챗봇 목록-->
          <div class="box-ct mt-3" style="min-height: 230px;">
            <h1 class="tit-h1 d-flex">
              챗봇 목록
              <!-- 신규 버튼, 삭제 버튼 -->
              <div class="ml-auto align-self-center">
                <v-btn outlined small class="btn-default" @click="chatbotNewAdd" :disabled="chatbotNewAddBtn">신규</v-btn>
                <v-btn outlined small class="btn-etc ml-2" @click="chatbotDelInfo" :disabled="chatbotDelBtn">삭제</v-btn>
              </div>
            </h1>
            <div class="mt-2">
              <span class="noti-font"><strong>※</strong> 챗봇 정보 등록 시 카카오 비즈니스 사이트에 시나리오의 블록 및 스킬 정보가 미리 등록되어 있어야 합니다.</span>
              <v-data-table
                dense
                v-model="selectedChatbotGridDataList"
                :headers="chatbotGridDataHeaders"
                :items="chatbotGridDataText"
                item-key="BOT_ID"
                :item-class="isActiveChatbotRow"
                hide-default-footer
                fixed-header
                class="grid-default"
                height="150px"
                no-data-text="검색된 결과가 없습니다."
                @click:row="chatbotGridRowClick"
                @dblclick:row="chatbotGridRowDblclick"
              >
              </v-data-table>
              <div class="grid-paging text-center pt-2">
                <span class="grid-total-count">총 <strong>{{ chatbotGridDataText.length }}</strong>건</span>
              </div>
            </div>
            <!-- 챗봇 팝업 -->
            <div>
              <v-dialog
                max-width="1190"
                min-height="500"
                persistent
                v-if="dialogBUP0110"
                v-model="dialogBUP0110"
                content-class="square-modal min-auto"
              >
                <dialog-BUP0110
                  headerTitle="챗봇 관리"
                  :param="BUP0110Param"
                  @hide="hideDialog('BUP0110')"
                  @select="getChatbotList"
                >
                </dialog-BUP0110>
              </v-dialog>
            </div>
          </div>
        </div>
        <!-- 상세 -->
        <div class="ml-6 flex-grow-1">
          <h1 class="tit-h1 d-flex">
            사업지 상세
            <!-- 신규 버튼, 삭제 버튼, 저장 버튼 -->
            <div class="ml-auto align-self-center">
              <v-btn outlined small class="btn-default" @click="newAdd">{{btnName}}</v-btn>
              <v-btn outlined small class="btn-etc ml-2" @click="delInfo" :disabled="delBtn">삭제</v-btn>
              <v-btn outlined small class="btn-default ml-2" @click="newBtnSave" :disabled="saveBtn">저장</v-btn>
            </div>
          </h1>
          <div class="box-ct mt-2">
            <v-form ref="form" lazy-validation>
              <div class="table-form">
                <table>
                  <caption class="hide">
                    항목
                  </caption>
                  <colgroup>
                    <col width="127px" />
                    <col width="" />
                  </colgroup>

                  <tbody>
                    <tr>
                      <th scope="row">
                        <v-badge dot color="#EF9191" v-if="dataFlag === 'I'">
                          건설사
                        </v-badge>
                        <label v-else>
                          건설사
                        </label>
                      </th>
                      <td>
                        <div class="form-inp full">
                          <v-autocomplete
                            v-model="CTCP_CD"
                            :items="detailDropCtcpCd"
                            item-text="CD_NM"
                            item-value="CD"
                            outlined
                            hide-details
                            placeholder="선택하세요"
                            :disabled="ctcpCd"
                          >
                          </v-autocomplete>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <!-- <v-badge dot color="#EF9191"> -->
                          구분
                        <!-- </v-badge> -->
                      </th>
                      <td>
                        <div class="form-inp full">
                          <v-select
                            v-model="PART_TYPE_OBJ"
                            :items="partTypeCds"
                            item-text="CD_NM"
                            item-value="CD"
                            outlined
                            hide-details
                            placeholder="선택하세요"
                            :disabled="statCd"
                          >
                          </v-select>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <v-badge dot color="#EF9191">
                          사업지명
                        </v-badge>
                      </th>
                      <td>
                        <v-text-field
                          class="form-inp full"
                          v-model="BUSS_NAME"
                          name="BUSS_NAME"
                          outlined
                          hide-details
                          :disabled="bussName"
                        >
                        </v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        대표전화번호
                      </th>
                      <td>
                        <v-text-field
                          class="form-inp full"
                          v-model="RETV_PHONE"
                          name="RETV_PHONE"
                          outlined
                          hide-details
                          :disabled="retvPhone"
                        >
                        </v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <v-badge dot color="#EF9191">
                          상태
                        </v-badge>
                      </th>
                      <td>
                        <div class="form-inp full">
                          <v-select
                            v-model="STAT_CD"
                            :items="dropStatCd"
                            item-text="CD_NM"
                            item-value="CD"
                            outlined
                            hide-details
                            placeholder="선택하세요"
                            :disabled="statCd"
                          >
                          </v-select>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        담당자
                      </th>
                      <td>
                        <v-text-field
                          class="form-inp full"
                          v-model="TPTB_NAME"
                          name="TPTB_NAME"
                          outlined
                          hide-details
                          :disabled="tptbName"
                        >
                        </v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        담당자연락처
                      </th>
                      <td>
                        <v-text-field
                          class="form-inp full"
                          v-model="TPTB_PHONE"
                          name="TPTB_PHONE"
                          outlined
                          hide-details
                          :disabled="tptbPhone"
                        >
                        </v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        정렬순번
                      </th>
                      <td>
                        <div class="form-inp full">
                          <v-text-field
                            v-model="ORDER_SEQ"
                            name="ORDER_SEQ"
                            type="number"
                            min="1"
                            max="9999"
                            outlined
                            hide-details
                            :disabled="orderSeq"
                          >
                          </v-text-field>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>  
            </v-form>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  import DialogBUP0110 from "./BUP0110.vue";

  export default {
    name: "MENU_BUM0100", //name은 'MENU_' + 파일명 조합
    components: {
      DialogBUP0110, // 모달 팝업
    },
    data() {
      return {
        HEADERResult: '',
        DATAResult  : '',
        
        HEADER_TYPE     : 'BIZ_SERVICE',
        HEADER_GRID_ID  : '',
        HEADER_ROW_CNT  : 0,
        HEADER_PAGES_CNT: 0,
        headers         : {},
        
        URLData : '',
        sendData: {},
        
        // 공통코드
        groupCdList  : ['HLW_CTCP_CD', 'HLW_STAT_CD', 'HLW_PART_TYPE_CD'],
        allCodeList  : [],
        USER_AUTH_CHK: '', // 사용자 권한 체크

        // 조회
        CTCP_CODE: '',
        BUSS_CD  : '',
        S_PART_TYPE : '',

        // select box
        dropCtcpCd      : [], // 건설사명
        dropBussCd      : [], // 사업지명
        sPartTypeCds    : [], // 입주/분양 조건 배열
        partTypeCds     : [], // 입주/분양 배열
        detailDropCtcpCd: [], // 상세건설사명
        dropStatCd      : [], // 상태

        // 사업지 목록
        bussGridDataHeaders: [
          { text: 'No', value: 'index', align: 'center', width: '50px', sortable: false, },
          { text: "구분", value: 'PART_TYPE_NAME', align: 'center', width: '100px', sortable: false, },
          { text: "건설사명", value: 'CTCP_NAME', align: 'center', width: '150px', sortable: false, },
          { text: "사업지명", value: 'BUSS_NAME', align: 'center', width: '300px', sortable: false, },
          { text: "운영상태", value: 'STAT_NM', align: 'center', width: '80px', sortable: false, },
          { text: "담당자", value: 'TPTB_NAME', align: 'center', width: '110px', sortable: false, },
          { text: "담당자전화번호", value: 'TPTB_PHONE', align: 'center', width: '110px', sortable: false, },
          { text: "건설사코드", value: 'CTCP_CD', align: ' d-none', width: '0px', sortable: false, },
          { text: "사업지코드", value: 'BUSS_CODE', align: ' d-none', width: '0px', sortable: false, },
          { text: "대표전화번호", value: 'RETV_PHONE', align: ' d-none', width: '0px', sortable: false, },
          { text: "상태코드", value: 'STAT_CD', align: ' d-none', width: '0px', sortable: false, },
        ],
        bussGridDataText        : [], // 사업지 목록 데이터
        selectedBussGridDataList: [],

        // 사업지 상세
        CTCP_CD   : '', // 건설사코드
        CTCP_NAME : '', // 건설사명
        BUSS_CODE : '', // 사업지코드
        BUSS_NAME : '', // 사업지명
        PART_TYPE_OBJ : '', // 입주:MI / 분양:PO 구분
        PART_NM   : '', // 구분명
        RETV_PHONE: '', // 대표전화번호
        STAT_CD   : '', // 상태
        TPTB_NAME : '', // 담당자
        TPTB_PHONE: '', // 담당자전화번호
        ORDER_SEQ : 0,  // 정렬순서
        
        // 사업지 상세 비활성화
        ctcpCd   : true,
        bussCode : true,
        bussName : true,
        retvPhone: true,
        statCd   : true,
        tptbName : true,
        tptbPhone: true,
        orderSeq : true,

        // 버튼 비활성화
        delBtn          : true,
        saveBtn         : true,
        chatbotNewAddBtn: true,
        chatbotDelBtn   : true,

        // 챗봇 목록
        chatbotGridDataHeaders: [
          { text: 'No', align: 'center', value: 'index', width: '50px', sortable: false, },
          { text: "건설사명", value: 'CTCP_NAME', align: 'center', width: '130px', sortable: false, },
          { text: "사업지명", value: 'BUSS_NAME', align: 'center', width: '270px', sortable: false, },
          { text: "봇ID", value: 'BOT_ID', align: 'center', width: '200px', sortable: false, },
          { text: "카카오API키", value: 'BLOCK_API_KEY', align: 'center', width: '250px', sortable: false, },
          { text: "건설사코드", value: 'CTCP_CD', align: ' d-none', width: '0px', sortable: false, },
          { text: "사업지코드", value: 'BUSS_CODE', align: ' d-none', width: '0px', sortable: false, },
        ],
        chatbotGridDataText        : [], // 챗봇 목록 데이터
        selectedChatbotGridDataList: [],
        
        // 챗봇 팝업
        dialogBUP0110: false,
        BUP0110Param : {}, // 챗봇 팝업 전달 값

        // 기타
        USER_ID : '', // 사용자아이디
        dataFlag: '', // 처리구분(I: 등록, U: 수정, D: 삭제)
        btnName : '신규', // 버튼명
        BOT_ID  : '', // 챗봇아이디
        clickChk: '', // 챗봇 목록 클릭 여부 체크(chk: 클릭)

        // 메시지
        bum0100Msg: {
          saveChk              : '사업지를 등록하시겠습니까?',
          editChk              : '사업지를 수정하시겠습니까?',
          bussDeleteChk        : '사업지 삭제 시 챗봇 정보도 삭제됩니다.<br>삭제하시겠습니까?',
          chatbotDeleteChk     : '챗봇을 삭제하시겠습니까?',
          saveSuccess          : '저장되었습니다.',
          editSuccess          : '수정되었습니다.',
          deleteSuccess        : '삭제되었습니다.',
          ctcrCdEmptyChk       : '건설사를 선택하세요.',
          bussNameEmptyChk     : '사업지명을 입력하세요.',
          statCdEmptyChk       : '상태를 선택하세요.',
          partTypeCdEmptyChk   : '구분을 선택해주세요.',
          selectChatbotEmptyChk: '선택된 챗봇 정보가 없습니다.',
          errorMsg             : '관리자에게 문의해주세요.',
          dataDuplChkMsg       : '사업지명이 중복됩니다.',
        },

        // 페이징
        page        : 1,
        pageCount   : 1,
        itemsPerPage: 30,
        totalVisible: 10,
      };
    },

    methods: {

      // 사업지관리 조회
      async getBussList() {
        this.listClean();
        
        let requestData = {
          headers: this.initHeaders,
          sendData: {},
        };

        requestData.headers["URL"] = "/api/buss/buss-manage/list";
        requestData.headers["SERVICE"] = "buss.buss-manage";
        requestData.headers["METHOD"] = "list";
        requestData.headers["TWB_SQL_ID"] = "selectBussList";
        requestData.headers["ASYNC"] = false;
        requestData.headers["GRID_ID"] = "bussGridDataHeaders";
        
        requestData.sendData["CTCP_CD"] = this.CTCP_CODE;
        requestData.sendData["BUSS_CODE"] = this.BUSS_CD;
        requestData.sendData["PART_TYPE"] = this.S_PART_TYPE;

        let response = await this.common_postCall(requestData);

        this.result(response);
      },

      // 사업지관리 조회 결과
      bussGetGridDataCallBack(response) {
        let data = response.DATA;
        this.bussGridDataText = data;

        // 그리드 데이터 가공
        let idx = 1;
        let dataText = this.bussGridDataText;

        for(let i in dataText) {
          this.bussGridDataText[i]["index"] = idx++;
          this.bussGridDataText[i]["TPTB_PHONE"] = this.mixin_setPhoneNo(this.bussGridDataText[i]["TPTB_PHONE"]);
        }
      },

      // 사업지관리 목록 클릭 - 사업지 상세 조회
      bussGridRowClick(item) {
        this.dataFlag = 'U';
        this.isActive('U');
        this.btnName = '신규';

        this.BUSS_CODE = item.BUSS_CODE;
        this.CTCP_CD = item.CTCP_CD;
        this.CTCP_NAME = item.CTCP_NAME;
        this.BUSS_NAME = item.BUSS_NAME;

        this.PART_TYPE_OBJ = item.PART_TYPE;
        this.RETV_PHONE = this.mixin_setPhoneNo(item.RETV_PHONE);
        this.STAT_CD = item.STAT_CD;
        this.TPTB_NAME = item.TPTB_NAME;
        this.TPTB_PHONE = this.mixin_setPhoneNo(item.TPTB_PHONE);
        this.ORDER_SEQ = item.ORDER_SEQ;

        // 관련 챗봇 목록도 같이 조회
        this.getChatbotList(item);
      },

      // 사업지 등록
      newAdd() {
        this.dataFlag = this.dataFlag === 'I' ? '' : 'I';
        this.dataFlag === 'I' ? this.isActive('I') : this.isActive(); // 활성화 여부
        this.btnName = this.dataFlag === 'I' ? '취소' : '신규';
        this.clickChk = '';
        this.cleanData();
        this.chatbotGridDataText = [];

        if(this.CTCP_CODE !== '') {
          // 조회 후 신규 작성 시 조회 조건 반영
          this.CTCP_CD = this.dataFlag === 'I' ? this.CTCP_CODE : '';
        }
      },

      // 사업지 등록/수정 여부 확인
      async newBtnSave() {

        // 유효성 체크
        if(!this.saveValidate()) {
          return;
        }else{
          await this.fnBussNmChk();
        }
      },

      // 사업지 등록/수정 저장
      async newEditSave() {

        let requestData = {
          headers: this.initHeaders,
          sendData: {},
        };

        requestData.headers["URL"] = "/api/buss/buss-manage/regist";
        requestData.headers["SERVICE"] = "buss.buss-manage";
        requestData.headers["METHOD"] = "regist";
        requestData.headers["ASYNC"] = false;

        // 정렬순번을 입력하지 않은 경우
        if(this.ORDER_SEQ === '') {
          this.ORDER_SEQ = 0;
        }

        let ptObj = this.PART_TYPE_OBJ;
        let bussNm = this.BUSS_NAME.replaceAll(/\//g, '');
        let bussTYNm = bussNm.trim().substr(-2);

        let partTyNm = '';
        if(ptObj == 'MI') { // 입주
          partTyNm = '_입주'; // 변경 2023.11.15
        }

        if(bussTYNm == '입주') {
          bussNm = bussNm.substr(0, bussNm.length-2).trim();
        }
        bussNm = bussNm + partTyNm;
        


        if(this.dataFlag === 'I') {

          requestData.headers["TWB_SQL_ID"] = "insertBussNewInfo";
          requestData.sendData["DATA_FLAG"] = this.dataFlag;
          requestData.sendData["CTCP_CD"] = this.CTCP_CD;
          requestData.sendData["BUSS_NAME"] = bussNm;
          requestData.sendData["PART_TYPE"] = ptObj;
          requestData.sendData["RETV_PHONE"] = (this.RETV_PHONE).replaceAll('-', '');
          requestData.sendData["STAT_CD"] = this.STAT_CD;
          requestData.sendData["TPTB_NAME"] = this.TPTB_NAME;
          requestData.sendData["TPTB_PHONE"] = (this.TPTB_PHONE).replaceAll('-', '');
          requestData.sendData["ORDER_SEQ"] = Number(this.ORDER_SEQ);
          requestData.sendData["REG_ID"] = this.USER_ID;
          requestData.sendData["UPD_ID"] = this.USER_ID;
        }else if(this.dataFlag === 'U') {

          requestData.headers["TWB_SQL_ID"] = "updateBussInfo";
          requestData.sendData["DATA_FLAG"] = this.dataFlag;
          requestData.sendData["CTCP_CD"] = this.CTCP_CD;
          requestData.sendData["BUSS_CODE"] = this.BUSS_CODE;
          requestData.sendData["BUSS_NAME"] = bussNm;
          requestData.sendData["PART_TYPE"] = ptObj;
          requestData.sendData["RETV_PHONE"] = (this.RETV_PHONE).replaceAll('-', '');
          requestData.sendData["STAT_CD"] = this.STAT_CD;
          requestData.sendData["TPTB_NAME"] = this.TPTB_NAME;
          requestData.sendData["TPTB_PHONE"] = (this.TPTB_PHONE).replaceAll('-', '');
          requestData.sendData["ORDER_SEQ"] = Number(this.ORDER_SEQ);
          requestData.sendData["UPD_ID"] = this.USER_ID;
        }

        let response = await this.common_postCall(requestData);

        this.result(response);
      },

      // 사업지 등록/수정 저장 결과
      getGridDataSaveCallBack() {
        if(this.dataFlag === 'I') {
          this.common_alert(this.bum0100Msg.saveSuccess);
        }else if(this.dataFlag === 'U') {
          this.common_alert(this.bum0100Msg.editSuccess);
        }
        this.dataFlag = '';
        this.btnName = '신규';
        this.changeBussCode(this.CTCP_CODE);
        this.getBussList();
        this.isActive();
        this.cleanData();
      },

      // 사업지 삭제 여부 확인
      delInfo() {
        this.dataFlag = 'D';
        
        if(this.dataFlag === 'D') {
          this.common_confirm(this.bum0100Msg.bussDeleteChk, this.bussDel, '', null, null, "chk");
        }
      },

      // 사업지 삭제
      async bussDel() {
        let requestData = {
          headers: this.initHeaders,
          sendData: {},
        };

        requestData.headers["URL"] = "/api/buss/buss-manage/delete";
        requestData.headers["SERVICE"] = "buss.buss-manage";
        requestData.headers["METHOD"] = "delete";
        requestData.headers["TWB_SQL_ID"] = "deleteBussInfo";
        requestData.headers["ASYNC"] = false;

        requestData.sendData["BUSS_CODE"] = this.BUSS_CODE;
        requestData.sendData["UPD_ID"] = this.USER_ID;

        let response = await this.common_postCall(requestData);

        this.result(response);
      },

      // 사업지 삭제 결과
      getGridDataDeleteCallBack() {
        this.common_alert(this.bum0100Msg.deleteSuccess);
        this.dataFlag = '';
        this.changeBussCode(this.CTCP_CODE);
        this.getBussList();
        this.isActive();
        this.cleanData();
      },

      // 챗봇 목록 조회
      async getChatbotList(item) {
        this.clickChk = '';
        
        let requestData = {
          headers: this.initHeaders,
          sendData: {},
        };

        requestData.headers["URL"] = "/api/buss/buss-manage/chatbot/list";
        requestData.headers["SERVICE"] = "buss.buss-manage.chatbot";
        requestData.headers["METHOD"] = "list";
        requestData.headers["TWB_SQL_ID"] = "selectChatbotList";
        requestData.headers["ASYNC"] = true;
        requestData.headers["GRID_ID"] = "chatbotGridDataHeaders";

        requestData.sendData["CTCP_CD"] = item.CTCP_CD;
        requestData.sendData["BUSS_CODE"] = item.BUSS_CODE;

        let response = await this.common_postCall(requestData, false);

        this.result(response);
      },

      // 챗봇 목록 조회 결과
      chatbotGetGridDataCallBack(response) {
        let data = response.DATA;
        this.chatbotGridDataText = data;
        this.chatbotNewAddBtn = this.chatbotGridDataText.length > 0 ? true : false;
        
        // 그리드 데이터 가공
        let idx = 1;
        let dataText = this.chatbotGridDataText;

        for(let i in dataText) {
          this.chatbotGridDataText[i]["index"] = idx++;
        }
      },

      // 챗봇 목록 더블클릭 상세 조회
      chatbotGridRowDblclick(row, {item}) {
        this.dataFlag = 'U';
        this.BOT_ID = Object.assign({}, item).BOT_ID;

        this.BUP0110Param = {
          dataFlag  : this.dataFlag,
          ctcpCd    : this.CTCP_CD,
          ctcpNm    : this.CTCP_NAME,
          bussCd    : this.BUSS_CODE,
          bussNm    : this.BUSS_NAME,
          botId     : this.BOT_ID,
        };
        this.showDialog('BUP0110');
      },

      // 챗봇 등록
      chatbotNewAdd() {
        this.dataFlag = 'I';
        
        this.BUP0110Param = {
          dataFlag  : this.dataFlag,
          ctcpCd    : this.CTCP_CD,
          ctcpNm    : this.CTCP_NAME,
          bussCd    : this.BUSS_CODE,
          bussNm    : this.BUSS_NAME,
        };
        this.showDialog('BUP0110');
      },

      // 챗봇 목록 클릭
      chatbotGridRowClick(item) {
        this.BOT_ID = item.BOT_ID;
        this.BUSS_CODE = item.BUSS_CODE;
        this.clickChk = 'chk';
      },

      // 챗봇 삭제 여부 확인
      chatbotDelInfo() {
        this.dataFlag = 'D';

        if(this.clickChk !== 'chk') {
          this.common_alert(this.bum0100Msg.selectChatbotEmptyChk, "error");
          return;
        }
        
        if(this.dataFlag === 'D') {
          this.clickChk = '';
          this.common_confirm(this.bum0100Msg.chatbotDeleteChk, this.chatbotDel, '', null, null, "chk");
        }
      },

      // 챗봇 삭제
      async chatbotDel() {
        let requestData = {
          headers: this.initHeaders,
          sendData: {},
        };

        requestData.headers["URL"] = "/api/buss/buss-manage/chatbot/delete";
        requestData.headers["SERVICE"] = "buss.buss-manage.chatbot";
        requestData.headers["METHOD"] = "delete";
        requestData.headers["TWB_SQL_ID"] = "deleteChatbotInfo";
        requestData.headers["ASYNC"] = false;

        requestData.sendData["BUSS_CODE"] = this.BUSS_CODE;
        requestData.sendData["BOT_ID"] = this.BOT_ID;

        let response = await this.common_postCall(requestData);

        this.result(response);
      },

      // 챗봇 삭제 결과
      chatbotGetGridDataDeleteCallBack() {
        this.common_alert(this.bum0100Msg.deleteSuccess);

        let params = {
          CTCP_CD  : this.CTCP_CD,
          BUSS_CODE: this.BUSS_CODE,
        };
        this.getChatbotList(params);
      },

      // 결과 확인
      result(response) {
        if(response.HEADER.METHOD === "list" && response.HEADER.TWB_SQL_ID === "selectBussList") {
          this.bussGetGridDataCallBack(response);
        }else if(response.HEADER.METHOD === "regist") {
          this.getGridDataSaveCallBack();
        }else if(response.HEADER.METHOD === "delete" && response.HEADER.TWB_SQL_ID === "deleteBussInfo") {
          this.getGridDataDeleteCallBack();
        }else if(response.HEADER.METHOD === "list" && response.HEADER.TWB_SQL_ID === "selectChatbotList") {
          this.chatbotGetGridDataCallBack(response);
        }else if(response.HEADER.METHOD === "delete" && response.HEADER.TWB_SQL_ID === "deleteChatbotInfo") {
          this.chatbotGetGridDataDeleteCallBack();
        }
      },

      // 사업지 목록 선택 여부 표시
      isActiveBussRow(item) {
        return item.BUSS_CODE === this.BUSS_CODE ? 'active' : '';
      },

      // 챗봇 목록 선택 여부 표시
      isActiveChatbotRow(item) {
        if(this.clickChk === 'chk') {
          return item.BOT_ID === this.BOT_ID ? 'active' : '';
        }
      },

      // 목록 초기화
      listClean() {
        this.isActive();
        this.cleanData();
        this.chatbotGridDataText = [];
        this.bussGridDataText = [];
        this.dataFlag = '';
        this.btnName = '신규';
        this.BUSS_CODE = '';
        this.BOT_ID = '';
      },

      // 상세 초기화
      cleanData() {
        this.CTCP_CD = '';
        this.BUSS_NAME = '';
        this.PART_TYPE_OBJ = '';
        this.RETV_PHONE = '';
        this.STAT_CD = '';
        this.TPTB_NAME = '';
        this.TPTB_PHONE = '';
        this.ORDER_SEQ = 0;
      },

      // 사업지 코드 가져오기
      async changeBussCode(ctcpCode) {
        if(ctcpCode !== '') {
          this.BUSS_CD = '';
          this.dropBussCd = [];
          this.dropBussCd.push({ CD: '', CD_NM: '' });
          
          if(this.USER_AUTH_CHK === 'SYSTEMMANAGER') {
            // 시스템관리자인 경우
            this.dropBussCd = await this.mixin_busscode_get(ctcpCode);
          }else {
            this.dropBussCd = await this.mixin_busscode_get(ctcpCode, this.USER_ID);
            this.BUSS_CD = this.dropBussCd[0].CD;
          }
        }else {
          this.BUSS_CD = '';
          this.dropBussCd = [];
        }
      },

      // 유효성 체크
      saveValidate() {
        if(this.mixin_isEmpty(this.CTCP_CD)) {
          this.common_alert(this.bum0100Msg.ctcrCdEmptyChk, "chk");
          return false;
        }

        // if(this.mixin_isEmpty(this.PART_TYPE_OBJ)) {
        //   this.common_alert(this.bum0100Msg.partTypeCdEmptyChk, 'chk');
        //   return false;
        // }
        
        if(this.mixin_isEmpty(this.BUSS_NAME)) {
          this.common_alert(this.bum0100Msg.bussNameEmptyChk, "chk");
          return false;
        }

        if(this.mixin_isEmpty(this.STAT_CD)) {
          this.common_alert(this.bum0100Msg.statCdEmptyChk, "chk");
          return false;
        }

        return true;
      },

      // 사업지 상세 활성화 및 비활성화
      isActive(item) {
        if(item === 'I') {
          this.ctcpCd = false;          // 건설사코드
          this.bussCode = false;        // 사업지코드
          this.bussName = false;        // 사업지명
          this.retvPhone = false;       // 대표전화번호
          this.statCd = false;          // 운영상태
          this.tptbName = false;        // 담당자명
          this.tptbPhone = false;       // 담당자전화번호
          this.orderSeq = false;        // 정렬순번
          this.delBtn = true;           // 삭제버튼
          this.saveBtn = false;         // 저장버튼
          this.chatbotNewAddBtn = true; // 챗봇신규버튼
          this.chatbotDelBtn = true;    // 챗봇삭제버튼
        }else if(item === 'U') {
          this.ctcpCd = true;            // 건설사코드
          this.bussCode = true;          // 사업지코드
          this.bussName = false;         // 사업지명
          this.retvPhone = false;        // 대표전화번호
          this.statCd = false;           // 운영상태
          this.tptbName = false;         // 담당자명
          this.tptbPhone = false;        // 담당자전화번호
          this.orderSeq = false;         // 정렬순번
          this.delBtn = false;           // 삭제버튼
          this.saveBtn = false;          // 저장버튼
          this.chatbotNewAddBtn = false; // 챗봇신규버튼
          this.chatbotDelBtn = false;    // 챗봇삭제버튼
        }else {
          this.ctcpCd = true;           // 건설사코드
          this.bussCode = true;         // 사업지코드
          this.bussName = true;         // 사업지명
          this.retvPhone = true;        // 대표전화번호
          this.statCd = true;           // 운영상태
          this.tptbName = true;         // 담당자명
          this.tptbPhone = true;        // 담당자전화번호
          this.orderSeq = true;         // 정렬순번
          this.delBtn = true;           // 삭제버튼
          this.saveBtn = true;          // 저장버튼
          this.chatbotNewAddBtn = true; // 챗봇신규버튼
          this.chatbotDelBtn = true;    // 챗봇삭제버튼
        }
      },

      // 모달팝업 열기
      showDialog(type) {
        this[`dialog${type}`] = true;
      },

      // 모달 팝업 닫기
      hideDialog(type) {
        this.dataFlag = 'U';
        this.clickChk = '';
        this[`dialog${type}`] = false;
      },

      // 사업지명 중복 체크
      async fnBussNmChk() {
        
        let requestData = {
          headers: this.initHeaders,
          sendData: {},
        };

        requestData.headers["URL"] = "/api/buss/buss-manage/dupl/check";
        requestData.headers["SERVICE"] = "buss.buss-manage.dupl";
        requestData.headers["METHOD"] = "check";
        requestData.headers["ASYNC"] = false;
        
        requestData.sendData["BUSS_CODE"] = this.BUSS_CODE;
        requestData.sendData["BUSS_NAME"] = this.BUSS_NAME;

        let response = await this.common_postCall(requestData, false);

        return await this.fnBussNmChkCallBack(response);
      },

      async fnBussNmChkCallBack(response) {
        let header = response.HEADER;
        let data = response.DATA;

        if(header.ERROR_FLAG) {
          this.common_alert(this.bum0100Msg.errorMsg, 'error');
          return;
        } else {
          if(!this.mixin_isEmpty(data)) {
            this.common_alert(this.bum0100Msg.dataDuplChkMsg, "chk");
            return;
          }else{
            if(this.dataFlag === 'I') {
              this.common_confirm(this.bum0100Msg.saveChk, this.newEditSave, '', null, null, "chk");
            }else if(this.dataFlag === 'U') {
              this.common_confirm(this.bum0100Msg.editChk, this.newEditSave, '', null, null, "chk");
            }
          }
        }
      },

    },

    async mounted() {
      this.USER_ID = this.user_role.userId;
      this.allCodeList = await this.mixin_common_code_get_all(this.groupCdList);
      let atrtGroupNm = this.user_role.atrtGroupNm;
      
      this.sPartTypeCds = await this.mixin_common_code_get(this.allCodeList, 'HLW_PART_TYPE_CD');
      this.sPartTypeCds.unshift({CD:'', CD_NM:'전체'});

      this.partTypeCds = await this.mixin_common_code_get(this.allCodeList, 'HLW_PART_TYPE_CD');
      this.partTypeCds.unshift({CD:'', CD_NM:'선택없음'});

      if(atrtGroupNm === '시스템관리자') {
        this.USER_AUTH_CHK = 'SYSTEMMANAGER';  
        this.dropCtcpCd = await this.mixin_common_code_get(this.allCodeList, 'HLW_CTCP_CD', '전체');
        this.dropStatCd = await this.mixin_common_code_get(this.allCodeList, 'HLW_STAT_CD');
        this.detailDropCtcpCd = await this.mixin_common_code_get(this.allCodeList, 'HLW_CTCP_CD');
      }else{
        let usserAuth = await this.mixin_buss_ctcp_code_user_auto(this.USER_ID);

        this.dropCtcpCd = [{ CD_NM: usserAuth[0].CTCP_NAME, CD: usserAuth[0].CTCP_CODE }];
        this.dropStatCd = await this.mixin_common_code_get(this.allCodeList, 'HLW_STAT_CD');
        this.detailDropCtcpCd = [{ CD_NM: usserAuth[0].CTCP_NAME, CD: usserAuth[0].CTCP_CODE }];
        this.CTCP_CODE = this.dropCtcpCd[0].CD;
        this.changeBussCode(this.CTCP_CODE);
      }
      this.isActive(); // 비활성화
    },

    computed: {
      initHeaders() {
        return this.headers = {
          "SERVICE": "",
          "METHOD": "",
          "TWB_SQL_ID": "",
          "TYPE": this.HEADER_TYPE,
        };
      },
    },
  };
</script>
<style scoped>
.noti-font {
  font-size: 12px;
  color: red;
}
</style>