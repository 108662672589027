<template>
  <main>
    <div class="layout-subcontainer">
      <div class="box-wrap d-flex">
        <div class="col-6 pa-0">
          <h1 class="tit-h1 d-flex">
            상담사관리
            <!-- 조회 버튼 -->
            <div class="ml-auto align-self-center">
              <v-btn class="btn-etc2" outlined small @click="getListData">조회</v-btn>
            </div>
          </h1>
          <!-- 조회 -->
          <div class="box-ct mt-2">
            <div class="table-form d-flex">
              <table>
                <caption class="hide">
                  조회 항목
                </caption>
                <colgroup>
                  <col width="130px" />
                  <col width="180px" />
                  <col width="130px" />
                  <col width="430px" />
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="row">
                        건설사명
                    </th>
                    <td>
                      <div class="form-inp full">
                        <v-autocomplete
                          v-model="CTCP_CODE"
                          :items="ctcpTypeCd"
                          item-text="CD_NM"
                          item-value="CD"
                          outlined
                          hide-details
                          hide-no-data
                          placeholder="선택하세요"
                          @change="changeBussType(CTCP_CODE)"
                          >
                        </v-autocomplete>
                      </div>
                    </td>
                    <th scope="row">
                        사업지명
                    </th>
                    <td>
                      <div class="form-inp full">
                        <v-autocomplete
                          v-model="BUSS_CODE"
                          :items="bussTypeCd"
                          item-text="CD_NM"
                          item-value="CD"
                          outlined
                          hide-details
                          hide-no-data
                          placeholder="선택하세요"
                          :disabled="bussTypeCd.length === 0 ? true : false"
                        >
                        </v-autocomplete>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      상담사ID
                    </th>
                    <td>
                      <v-text-field
                        class="form-inp full"
                        v-model="S_USER_ID"
                        outlined
                        hide-details
                        placeholder="상담사ID"
                        @keyup.enter="getListData"
                      >
                      </v-text-field>
                    </td>
                    <th scope="row">
                      캠페인명
                    </th>
                    <td>
                      <v-text-field
                        class="form-inp full"
                        v-model="S_CAMP_NAME"
                        outlined
                        hide-details
                        placeholder="캠페인명"
                        @keyup.enter="getListData"
                      >
                      </v-text-field>
                    </td>
                  </tr>
                </tbody> 
              </table>
            </div>
          </div>
          <!-- 목록 -->
          <div class="box-ct mt-3">
            <h1 class="tit-h1 d-flex">
              상담사 목록
            </h1>
            <div class="mt-2">
              <v-data-table
                dense
                v-model="selGroupCd"
                :headers="gridDataHeaders"
                :items="gridDataText"
                :items-per-page="itemsPerPage"
                item-key="index"
                :item-class="clickActive"
                :page.sync="page"
                hide-default-footer
                @page-count="pageCount = $event"
                fixed-header
                class="grid-default"
                height="570px"
                no-data-text="검색된 결과가 없습니다."
                @click:row="gridRowClick"
              >
              </v-data-table>
              <div class="grid-paging text-center pt-2">
                <span class="grid-total-count">총 <strong>{{ gridDataText.length }}</strong>건</span>
                <v-pagination
                  v-model="page"
                  :length="pageCount"
                  :total-visible="totalVisible"
                  next-icon="svg-paging-next"
                  prev-icon="svg-paging-prev"
                ></v-pagination>
              </div>
            </div>
          </div>
        </div>
        <!-- 상세 -->
        <div class="ml-6 flex-grow-1">
          <h1 class="tit-h1 d-flex">
            상담사 상세내용
            <div class="ml-auto">
              <v-btn outlined small class="btn-etc" @click="groupAdd" :disabled="groupAddBtn">일괄생성</v-btn>
              <v-btn outlined small class="btn-default ml-2" @click="newBtnForm()">{{btnName}}</v-btn>
              <v-btn outlined small class="btn-etc ml-2" @click="clickBtnForm('DELETE')" :disabled="delBtn">삭제</v-btn>
              <v-btn outlined small class="btn-point ml-2" @click="clickBtnForm('UPDATE')" :disabled="saveBtn">저장</v-btn>
            </div>
            <div>
              <v-dialog 
                max-width="1000"
                persistent
                v-if="dialogCOP0510" 
                v-model="dialogCOP0510"
                content-class="square-modal min-auto"
              >
                <dialog-COP0510
                  headerTitle="상담사 일괄생성"
                  :param="COP0510Param"
                  @hide="hideDialog('COP0510')"
                  @select="getListData"
                >
                </dialog-COP0510>
              </v-dialog>
            </div>
          </h1>
          <div class="box-ct mt-2">
            <v-form ref="form" lazy-validation>
              <div class="table-form">
                <table>
                  <caption class="hide">
                    항목
                  </caption>
                  <colgroup>
                    <col width="120px" />
                    <col width="" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th scope="row">
                        <v-badge dot color="#EF9191" v-if="new_Type === 'INSERT'">
                          건설사
                        </v-badge>
                        <label v-else>
                          건설사
                        </label>
                      </th>
                      <td>
                        <div class="form-inp full">
                          <v-select
                            v-model="T_CTCP_CD"
                            :items="detailCtcpTypeCd"
                            item-text="CD_NM"
                            item-value="CD"
                            outlined
                            hide-details
                            placeholder="선택하세요"
                            :disabled="dis_ctcpCd"
                            @change="changeDetailBussType(T_CTCP_CD)"
                          >
                          </v-select>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <v-badge dot color="#EF9191" v-if="new_Type === 'INSERT'">
                          사업지명
                        </v-badge>
                        <label v-else>
                          사업지명
                        </label>
                      </th>
                      <td>
                        <div class="form-inp full">
                          <v-select
                            v-model="T_BUSS_CODE"
                            :items="detailBussTypeCd"
                            item-text="CD_NM"
                            item-value="CD"
                            outlined
                            hide-details
                            hide-no-data
                            placeholder="선택하세요"
                            :disabled="dis_bussCode"
                            @change="changeDetailCmpType(T_BUSS_CODE)"
                          >
                          </v-select>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <v-badge dot color="#EF9191" v-if="new_Type === 'INSERT'">
                          상담사ID
                        </v-badge>
                        <label v-else>
                          상담사ID
                        </label>
                      </th>
                      <td>
                        <v-text-field
                          class="form-inp full"
                          v-model="T_CNSLR_ID"
                          name="T_CNSLR_ID"
                          outlined
                          hide-details
                          :disabled="dis_cnslrId"
                        >
                        </v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <v-badge dot color="#EF9191" v-if="new_Type === 'INSERT'">
                          상담사 비밀번호
                        </v-badge>
                        <label v-else>
                          상담사 비밀번호
                        </label>
                      </th>
                      <td>
                        <v-text-field v-if="new_Type === 'UPDATE'"
                          class="form-inp full"
                          v-model="T_PWD"
                          name="T_PWD"
                          type="password"
                          maxLength="20"
                          outlined
                          hide-details
                          placeholder="비밀번호 변경 필요 시 입력하세요."
                          :disabled="dis_pwd"
                        >
                        </v-text-field>
                        <v-text-field v-else
                          class="form-inp full"
                          v-model="T_PWD"
                          name="T_PWD"
                          type="password"
                          maxLength="20"
                          outlined
                          hide-details
                          :disabled="dis_pwd"
                        >
                        </v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        대표전화번호
                      </th>
                      <td>
                        <v-text-field
                          class="form-inp full"
                          v-model="T_HP_NO"
                          name="T_HP_NO"
                          outlined
                          hide-details
                          :disabled="dis_hpNo"
                        >
                        </v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        상담사명
                      </th>
                      <td>
                        <v-textarea
                          class="form-inp full"
                          v-model="T_USER_NM"
                          counter
                          no-resize
                          outlined
                          :disabled="dis_userNm"
                          maxlength="10"
                        >
                        </v-textarea>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <v-badge dot color="#EF9191" v-if="new_Type === 'INSERT' || new_Type === 'UPDATE'">
                          상담석
                        </v-badge>
                        <label v-else>
                          상담석
                        </label>
                      </th>
                      <td>
                        <v-text-field
                          class="form-inp full"
                          v-model="T_SEAT_NO"
                          name="T_SEAT_NO"
                          type="number"
                          min="1"
                          max="9999"
                          outlined
                          hide-details
                          :disabled="dis_seatNo"
                        >
                        </v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        상태
                      </th>
                      <td>
                        <div class="form-inp full">
                          <v-select
                            v-model="T_USER_STAT_CD"
                            :items="userStatCd"
                            item-text="CD_NM"
                            item-value="CD"
                            outlined
                            hide-details
                            placeholder="선택하세요"
                            :disabled="dis_userStatCd"
                          >
                          </v-select>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <v-badge dot color="#EF9191" v-if="new_Type === 'INSERT'">
                          담당 캠페인
                        </v-badge>
                        <label v-else>
                          담당 캠페인
                        </label>
                      </th>
                      <td>
                        <v-select
                          class="form-inp full"
                          v-model="T_CMP_ID"
                          :items="cmpTypeCd"
                          item-text="CMP_NAME"
                          item-value="CMP_ID"
                          outlined
                          hide-details
                          required
                          placeholder="선택하세요"
                          :disabled="cmpTypeCd.length === 0 ? true : false"
                        >
                        </v-select>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </v-form>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  import GibberishAes_mixin from "@/mixin/gibberish-aes_mixin.js";
  import DialogCOP0510 from "./COP0510.vue";

  export default {
    name: "MENU_COM0500", //name은 'MENU_' + 파일명 조합
    components: {
      DialogCOP0510,
    },
    data() {
      return {
        // 공통코드
        allCodeList  : [],
        USER_AUTH_CHK: '', // 사용자 권한 체크

        // 조회
        CTCP_CODE  : '',
        CTCP_NAME  : '',
        BUSS_CODE  : '',
        S_USER_ID  : '',
        S_CAMP_NAME: '',

        // select box
        ctcpTypeCd      : [], // 건설사명
        bussTypeCd      : [], // 사업지명
        detailCtcpTypeCd: [], // 상세건설사명
        detailBussTypeCd: [], // 상세사업지명
        userStatCd      : [], // 상태
        cmpTypeCd       : [], // 캠페인명

        // 상담사 목록
        gridDataHeaders: [
          { text: "No", value: "index", align: "center", width: "50px", sortable: false  },
          { text: "건설사명", value: "CTCP_NAME", align: "center", width: "140px", sortable: false },
          { text: "사업지명", value: "BUSS_NAME", align: "center", width: "140px", sortable: false },
          { text: "상담사아이디", value: "CNSLR_ID", align: "center", width: "90px", sortable: false },
          { text: "상담석", value: "SEAT_NO", align: "center", width: "60px", sortable: false },
          { text: "상태", value: "USER_STAT_NAME", align: 'center', width: '60px', sortable: false },
          { text: "캠페인", value: "CMP_NAME", align: "center", width: "140px", sortable: false },
          { text: "사업지코드", value: "BUSS_CODE", align: ' d-none', width: '0px', sortable: false },
          { text: "건설사코드", value: "CTCP_CD", align: ' d-none', width: '0px', sortable: false },
          { text: "상담사비밀번호", value: "PWD", align: ' d-none', width: '0px', sortable: false },
          { text: "상담사명", value: "USER_NM", align: ' d-none', width: '0px', sortable: false },
          { text: "상담사전화번호", value: "HP_NO", align: ' d-none', width: '0px', sortable: false },
          { text: "상담사권한코드", value: "USER_GROUP_CD", align: ' d-none', width: '0px' , sortable: false },
          { text: "상태코드", value: "USER_STAT_CD", align: ' d-none', width: '0px', sortable: false },
          { text: "캠페인ID", value: "CMP_ID", align: " d-none", width: "0px", sortable: false },
        ],
        gridDataText: [], // 상담사 목록 데이터
        selGroupCd  : [],

        // 상담사 상세
        T_CTCP_CD      : '', // 건설사코드
        T_BUSS_CODE    : '', // 사업지코드
        T_BUSS_NAME    : '', // 사업지명
        T_CNSLR_ID     : '', // 상담사ID
        T_PWD          : '', // 비밀번호
        T_HP_NO        : '', // 전화번호
        T_USER_NM      : '', // 상담사명
        T_SEAT_NO      : '', // 좌석번호
        T_USER_STAT_CD : '', // 상태
        T_CMP_ID       : '', // 캠페인명
        ENCRYPT_KEY    : '', // 암호화키
        ORG_ENCRYPT_KEY: '', // 암호화키

        // 상담사 상세 비활성화
        dis_ctcpCd    : true,
        dis_bussCode  : true,
        dis_bussName  : true,
        dis_cnslrId   : true,
        dis_pwd       : true,
        dis_hpNo      : true,
        dis_userNm    : true,
        dis_seatNo    : true,
        dis_userStatCd: true,
        dis_cmpTypeCd : true,

        // 버튼 비활성화
        groupAddBtn: true,
        delBtn     : true,
        saveBtn    : true,

        // 일괄생성 팝업
        dialogCOP0510: false,
        COP0510Param : {}, // 일괄생성 팝업 전달 값

        // 기타
        new_Type  : '', // 처리구분(INSERT, UPDATE)
        GE_USER_ID: '', // 사용자아이디
        btnName   : '신규', // 버튼명

        // 메시지
        com0500Msg: {
          saveChk         : '상담사를 등록하시겠습니까?',
          editChk         : '상담사를 수정하시겠습니까?',
          deleteChk       : '상담사를 삭제하시겠습니까?',
          saveSuccess     : '저장되었습니다.',
          editSuccess     : '수정되었습니다.',
          deleteSuccess   : '삭제되었습니다.',
          ctcrCdEmptyChk  : '건설사를 선택하세요.',
          bussNameEmptyChk: '사업지명을 선택하세요.',
          cnslrIdEmptyChk : '상담사ID를 입력하세요.',
          cmpEmptyChk     : '캠페인을 선택하세요.',
          pwdEmptyChk     : '비밀번호를 입력하세요.',
          pwdLengthChk    : '비밀번호는 4자리 ~ 20자리 이내로 입력하세요.',
          pwdSpaceChk     : '비밀번호는 공백 없이 입력하세요.',
          seatNoEmptyChk  : '상담석 번호를 입력하세요.',
        },

        // 페이징
        page        : 1,
        pageCount   : 0,
        itemsPerPage: 20,
        totalVisible: 10,
      };
    },
    methods: {
      // 상담사관리 조회
      async getListData() {
        this.listClean();

        let requestData = {
          headers: {},
          sendData:{},
        };

        //header 세팅
        requestData.headers["URL"] = "/api/setting/agent/agent-cnslr-manage/list";
        requestData.headers["SERVICE"] = "setting.agent.agent-cnslr-manage";
        requestData.headers["METHOD"] = "list";
        requestData.headers["ASYNC"] = false;
        requestData.headers["GRID_ID"] = "gridDataHeaders";

        //sendData 초기화
        requestData.sendData["CTCP_CD"] = this.CTCP_CODE;
        requestData.sendData["BUSS_CODE"] = this.BUSS_CODE;
        requestData.sendData["S_USER_ID"] = this.S_USER_ID;

        let response = await this.common_postCall(requestData);

        this.result(response);
      },

      // 상담사관리 조회 결과
      getGridDataCallBack(res){
        let header = res.HEADER;
        this.totcount = header.TOT_COUNT;

        let data = res.DATA;
        this.gridDataText = data;

        if(!this.mixin_isEmpty(this.CTCP_CODE) && !this.mixin_isEmpty(this.BUSS_CODE) && this.gridDataText.length === 0) {
          // 건설사와 사업지로 조회 시 상담사가 없는 경우 일괄생성 가능
          this.groupAddBtn = false;
          
          for(let i = 0; i < this.ctcpTypeCd.length; i++) {
            if(this.ctcpTypeCd[i].CD === this.CTCP_CODE) {
              this.CTCP_NAME = this.ctcpTypeCd[i].CD_NM;
            }
          }
          
          for(let i = 0; i < this.bussTypeCd.length; i++) {
            if(this.bussTypeCd[i].CD === this.BUSS_CODE) {
              this.BUSS_NAME = this.bussTypeCd[i].CD_NM;
            }
          }
        }else {
          this.groupAddBtn = true;
        }

        let idx = 1;

        for(var i in this.gridDataText) {
          this.gridDataText[i]["index"] = idx++;
          this.gridDataText[i]["HP_NO"] = this.mixin_setPhoneNo(this.gridDataText[i]["HP_NO"]);
        }
      },

      // 상담사 목록 클릭 - 상담사 상세
      gridRowClick(item) {
        this.new_Type = "UPDATE";
        this.btnName = '신규';
        this.disabledActive("part");
        this.cleanValidation();
        
        this.changeDetailBussType(item.CTCP_CD);
        this.changeDetailCmpType(item.BUSS_CODE);

        this.T_CTCP_CD = item.CTCP_CD;
        this.T_BUSS_CODE = item.BUSS_CODE;
        this.T_CNSLR_ID = item.CNSLR_ID;
        this.T_HP_NO = this.mixin_setPhoneNo(item.HP_NO);
        this.T_USER_NM = item.USER_NM;
        this.T_SEAT_NO = item.SEAT_NO;
        this.T_USER_STAT_CD = item.USER_STAT_CD;
        this.T_CMP_ID = item.CMP_ID;
        this.ENCRYPT_KEY = item.ENCRYPT_KEY;
        this.ORG_ENCRYPT_KEY = item.ENCRYPT_KEY;
      },

      // 상담사 등록
      async newBtnForm() {
        this.new_Type === 'INSERT' ? this.new_Type = '' : this.new_Type = 'INSERT';
        this.new_Type === 'INSERT' ? this.disabledActive("false") : this.disabledActive();
        this.btnName = this.new_Type === 'INSERT' ? '취소' : '신규';
        this.cleanValidation();

        if(!this.mixin_isEmpty(this.CTCP_CODE) && !this.mixin_isEmpty(this.BUSS_CODE)) {
          // 조회 후 신규 작성 시 조회 조건 반영
          this.T_CTCP_CD = this.new_Type === 'INSERT' ? this.CTCP_CODE : '';
          this.changeDetailBussType(this.T_CTCP_CD);
        }
      },

      // 상담사 등록/수정/삭제 여부 확인
      clickBtnForm(btn) {
        // 유효성 체크
        if(!this.saveValidate()) {
          return;
        }

        // 암호화키 셋팅
        this.getKey();

        let delChk = "";

        if(this.new_Type === "INSERT") {
          delChk = this.com0500Msg.saveChk;
          btn = 'INSERT';
        }else {
          if(btn === "DELETE") {
            delChk = this.com0500Msg.deleteChk;
          }else if(btn === "UPDATE") {
            delChk = this.com0500Msg.editChk;
          }
        }
        this.common_confirm(delChk, this.clickBtnReg, btn, null, null, "chk");
      },

      // 상담사 등록/수정/삭제 처리
      async clickBtnReg(btn) {
        
        let requestData = {
          headers: {},
          sendData:{},
        };

        if(this.new_Type === "INSERT") {
          requestData.headers["URL"] = "/api/setting/agent/agent-cnslr-manage/insert";
          requestData.headers["SERVICE"] = "setting.agent.agent-cnslr-manage";
          requestData.headers["METHOD"] = "insert";
          requestData.headers["TWB_SQL_ID"] = "insertCnslrNewInfo";
        }else {
          if(btn === "DELETE") {
            requestData.headers["URL"] = "/api/setting/agent/agent-cnslr-manage/delete";
            requestData.headers["SERVICE"] = "setting.agent.agent-cnslr-manage";
            requestData.headers["METHOD"] = "delete";
            requestData.headers["TWB_SQL_ID"] = "deleteCnslrInfo";
          }else if(btn === "UPDATE") {
            requestData.headers["URL"] = "/api/setting/agent/agent-cnslr-manage/update";
            requestData.headers["SERVICE"] = "setting.agent.agent-cnslr-manage";
            requestData.headers["METHOD"] = "update";
            requestData.headers["TWB_SQL_ID"] = "updateCnslrInfo";
          }
          requestData.headers["ASYNC"] = false;
        }

        let insertList = [];

        // select-box 선택하지 않았을 경우 null 처리
        this.T_USER_STAT_CD === '' ? this.T_USER_STAT_CD = null : this.T_USER_STAT_CD;
        this.T_CMP_ID === '' ? this.T_CMP_ID = null : this.T_CMP_ID;

        let obj = {
          BTNTYPE: btn,  // 버튼타입
          USER_ID: this.T_CNSLR_ID,  // 상담사ID
          ENCRYPT_KEY: this.ENCRYPT_KEY,  // 암호화키
          HP_NO: this.T_HP_NO.replaceAll('-', ''),  // 대표전화번호
          USER_NM: this.T_USER_NM,  // 상담사명
          SEAT_NO: Number(this.T_SEAT_NO),  // 좌석번호
          USER_STAT_CD: this.T_USER_STAT_CD,  // 상태
          CMP_ID: this.T_CMP_ID,  // 캠페인
          USER_ATTR_A: this.T_CTCP_CD,  // 건설사코드
          USER_ATTR_B: this.T_BUSS_CODE, // 사업지코드
          SORT_ORD: 1, // 정렬순서
          BUSS_CODE: this.T_BUSS_CODE,
          REGR_ID: this.GE_USER_ID, // 사용자ID
          AMDR_ID: this.GE_USER_ID,
          PROC_ID: this.GE_USER_ID,
        };

        let passWd = null;
        if(!this.mixin_isEmpty(this.T_PWD)) {
          passWd = this.common_aesEncrypt(this.T_PWD, this.ENCRYPT_KEY);

          obj['PASSWORD'] = passWd;
        }

        insertList.push(obj);

        requestData.sendData = {
          DATA: insertList
        };
        
        let response = await this.common_postCall(requestData);

        this.result(response);
      },

      // 상담사 등록/수정 결과
      getGridDataSaveCallBack(response) {
        if(this.new_Type === 'INSERT') {
          if(!response.HEADER.ERROR_FLAG) {
            this.common_alert(this.com0500Msg.saveSuccess);
          }else {
            // 상담사ID 또는 상담좌석 중복
            this.common_alert(response.HEADER.ERROR_MSG, "chk");
            return;
          }
        }else {
          this.common_alert(this.com0500Msg.editSuccess);
        }
        this.new_Type = '';
        this.btnName = '신규';
        this.getListData();
        this.cleanValidation();
        this.disabledActive();
      },

      // 상담사 삭제 결과
      getGridDeleteDataCallBack() {
        this.common_alert(this.com0500Msg.deleteSuccess);
        this.new_Type = '';
        this.btnName = '신규';
        this.getListData();
        this.cleanValidation();
        this.disabledActive();
      },

      // 결과 확인
      result(response) {
        if(response.HEADER.METHOD === "list") {
          this.getGridDataCallBack(response);
        }else if(response.HEADER.METHOD === "insert" || response.HEADER.METHOD === "update") {
          this.getGridDataSaveCallBack(response);
        }else if(response.HEADER.METHOD === "delete") {
          this.getGridDeleteDataCallBack();
        }
      },

      // 상담사 일괄생성
      groupAdd() {
        this.COP0510Param = {
          ctcpCd: this.CTCP_CODE,
          ctcpNm: this.CTCP_NAME,
          bussCd: this.BUSS_CODE,
          bussNm: this.BUSS_NAME,
        }
        this.showDialog('COP0510')
      },

      // 목록 선택 시 색상 표시
      clickActive(item) {
        //클릭 시, 하이라이트
        return item.CNSLR_ID === this.T_CNSLR_ID ? 'active' : '';
      },

      // 유효성 체크
      saveValidate() {

        if(this.new_Type === 'INSERT') {
          if(this.mixin_isEmpty(this.T_CTCP_CD)) {
            this.common_alert(this.com0500Msg.ctcrCdEmptyChk, "chk");
            return;
          }

          if(this.mixin_isEmpty(this.T_BUSS_CODE)) {
            this.common_alert(this.com0500Msg.bussNameEmptyChk, "chk");
            return;
          }

          if(this.mixin_isEmpty(this.T_CNSLR_ID)) {
            this.common_alert(this.com0500Msg.cnslrIdEmptyChk, 'chk');
            return;
          }

          if(this.mixin_isEmpty(this.T_CMP_ID)) {
            this.common_alert(this.com0500Msg.cmpEmptyChk, 'chk');
            return;
          }
        }

        if(this.mixin_isEmpty(this.T_PWD) && !this.mixin_isEmpty(this.new_Type) && this.new_Type == 'INSERT') {
          this.common_alert(this.com0500Msg.pwdEmptyChk, 'chk');
          return;
        } else {
          if(!this.mixin_isEmpty(this.T_PWD) && !this.mixin_pwdChk(this.T_PWD)) {
            this.common_alert('비밀번호는 영문,숫자,특수문자를 포함 공백없이 4~20자 이내로 기입해주세요.', 'chk');
            return;
          }
        }

        if(this.mixin_isEmpty(this.T_SEAT_NO)) {
          this.common_alert(this.com0500Msg.seatNoEmptyChk, 'chk');
          return;
        }
        return true;
      },

      //암호화
      common_aesEncrypt(strPassWD, strEncryptKey) {
        return GibberishAes_mixin.aesEncrypt(strPassWD, strEncryptKey);
      },

      //암호화 키 호출
      async getKey() {
        //암호화 키가 없거나 특정건 선택한후 암호화된 상태의 키일경우
        if(this.ENCRYPT_KEY === '' || (this.ORG_ENCRYPT_KEY === this.ENCRYPT_KEY)) {

          let requestData = {
            headers: {},
            sendData: {
              USER_ID: this.T_CNSLR_ID,
              username: this.user_role.username,
              encryptKey: this.ENCRYPT_KEY,
            },
          };

          requestData.headers["URL"] = "/api/login/password-encpt/process";
          requestData.headers["SERVICE"] = "";
          requestData.headers["METHOD"] = "list";
          requestData.headers["ASYNC"] = false;

          let response = await this.common_postCall(requestData);

          if(response.HEADER.ERROR_FLAG) {
            this.common_toast("관리자에게 문의해주세요.", "error");
            return false;
          }else{
            if (!this.mixin_isEmpty(response.DATA)) {
              this.ENCRYPT_KEY = response.DATA[0].ENCRYPT_KEY;
            } else {
              this.common_toast("비밀번호 변경을 실패하였습니다.", "chk");
            }
          }
        }

      },

      // 목록 초기화
      listClean() {
        this.cleanValidation();
        this.disabledActive();
        this.gridDataText = [];
        this.cmpTypeCd = [];
        this.new_Type = '';
        this.btnName = '신규';
      },

      // 상세 초기화
      cleanValidation() {
        this.T_CTCP_CD = '';
        this.T_BUSS_CODE = '';
        this.T_BUSS_NAME = '';
        this.T_CNSLR_ID = '';
        this.T_PWD = '';
        this.T_HP_NO = '';
        this.T_SEAT_NO = '';
        this.T_USER_NM = '';
        this.T_USER_STAT_CD = '';
        this.T_CMP_ID = '';
        this.ENCRYPT_KEY = '';
        this.ORG_ENCRYPT_KEY = '';
        this.ORG_PWD = '';
      },

      //사업지 코드 가져오기
      async changeBussType(ctcpTypeCd) {
        if(ctcpTypeCd !== '') {
          this.BUSS_CODE = '';
          this.bussTypeCd = [];
          this.bussTypeCd.push({ CD: '', CD_NM: '' });

          if(this.USER_AUTH_CHK === "SYSTEMMANAGER") {
            this.bussTypeCd = await this.mixin_busscode_get(ctcpTypeCd);
          }else {
            this.bussTypeCd = await this.mixin_busscode_get(ctcpTypeCd, this.GE_USER_ID);
            this.BUSS_CODE = this.bussTypeCd[0].CD;
          }
        }else {
          this.BUSS_CODE = '';
          this.bussTypeCd = [];
        }
      },

      // 상세 사업지 코드 가져오기
      async changeDetailBussType(ctcpTypeCd) {
        
        if(!this.mixin_isEmpty(ctcpTypeCd)) {
          this.T_BUSS_CODE = '';
          this.detailBussTypeCd = [];
          this.detailBussTypeCd.push({ CD: '', CD_NM: '' });
          this.T_CMP_ID = "";
          this.cmpTypeCd = [];
          
          this.detailBussTypeCd = await this.mixin_busscode_get(ctcpTypeCd);

          for(let i = 0; i < this.detailBussTypeCd.length; i++) {
            if(this.detailBussTypeCd[i].CD === this.BUSS_CODE) {
              this.T_BUSS_CODE = this.detailBussTypeCd[i].CD;
            }
          }

          if(this.new_Type === 'INSERT') {
            this.changeDetailCmpType(this.T_BUSS_CODE);
          }
          this.dis_bussCode = this.detailBussTypeCd.length !== 0 ? false : true;																									
        }else {
          this.T_BUSS_CODE = '';
          this.detailBussTypeCd = [];
          this.T_CMP_ID =  "";
          this.cmpTypeCd = [];
        }
      },

      // 캠페인 코드 가져오기
      async changeDetailCmpType(bussTypeCd) {
        if(bussTypeCd !== '') {
          this.T_CMP_ID = "";
          this.cmpTypeCd = [];
          this.cmpTypeCd.push({ CMP_ID: "", CMP_NAME: "" });
          this.cmpTypeCd = await this.mixin_cmpcode_get(bussTypeCd);
        }else {
          this.T_CMP_ID = "";
          this.cmpTypeCd = [];
        }
      },

      // 상세내용 활성화,비활성화
      disabledActive(item) {
        if(item === "false") {
          // 상세내용비활성화
          this.dis_ctcpCd       = false;      // 건설사
          this.dis_bussCode     = true;       // 사업지코드
          this.dis_bussName     = false;      // 사업지명
          this.dis_cnslrId      = false;      // 상담사ID
          this.dis_pwd          = false;      // 상담사비밀번호
          this.dis_hpNo         = false;      // 대표전화번호
          this.dis_userNm       = false;      // 상담사명
          this.dis_seatNo       = false;      // 상담석
          this.dis_userStatCd   = false;      // 상태
          this.dis_cmpTypeCd    = false;      // 캠페인명
          this.delBtn           = true;       // 삭제버튼
          this.saveBtn          = false;      // 저장버튼
        }else if(item === "part") {
          this.dis_ctcpCd       = false;       // 건설사
          this.dis_bussCode     = false;       // 사업지코드
          this.dis_bussName     = true;       // 사업지명
          this.dis_cnslrId      = true;       // 상담사ID
          this.dis_pwd          = false;      // 상담사비밀번호
          this.dis_hpNo         = false;      // 대표전화번호
          this.dis_userNm       = false;      // 상담사명
          this.dis_seatNo       = false;      // 상담석
          this.dis_userStatCd   = false;      // 상태
          this.dis_cmpTypeCd    = false;      // 캠페인명
          this.delBtn           = false;      // 삭제버튼
          this.saveBtn          = false;      // 저장버튼
        }else {
          this.dis_ctcpCd       = true;       // 건설사
          this.dis_bussCode     = true;       // 사업지코드
          this.dis_bussName     = true;       // 사업지명
          this.dis_cnslrId      = true;       // 상담사ID
          this.dis_pwd          = true;       // 상담사비밀번호
          this.dis_hpNo         = true;       // 대표전화번호
          this.dis_userNm       = true;       // 상담사명
          this.dis_seatNo       = true;       // 상담석
          this.dis_userStatCd   = true;       // 상태
          this.dis_cmpTypeCd    = true;       // 캠페인명
          this.delBtn           = true;       // 삭제버튼
          this.saveBtn          = true;       // 저장버튼
        }
      },

      // 모달팝업 열기
      showDialog(type) {
        this[`dialog${type}`] = true;
      },

      // 모달팝업 닫기
      hideDialog(type) {
        this[`dialog${type}`] = false;
      },
    },

    async mounted() {
      let atrtGroupNm = this.user_role.atrtGroupNm;

      this.GE_USER_ID = this.user_role.userId;
      this.cleanValidation();
      this.disabledActive();

      let pArr = ['HLW_USE_YN', 'HLW_PLT_AUTH', 'HLW_CTCP_CD', 'HLW_CNSLR_STAT_CD'];

      this.allCodeList = await this.mixin_common_code_get_all(pArr);

      if(atrtGroupNm === "시스템관리자") {
        this.USER_AUTH_CHK = "SYSTEMMANAGER";  
        this.ctcpTypeCd = await this.mixin_common_code_get(this.allCodeList, 'HLW_CTCP_CD', '전체');
        this.userStatCd = await this.mixin_common_code_get(this.allCodeList, 'HLW_CNSLR_STAT_CD');
        this.detailCtcpTypeCd = await this.mixin_common_code_get(this.allCodeList, 'HLW_CTCP_CD');
      }else {
        let usserAuth = await this.mixin_buss_ctcp_code_user_auto(this.GE_USER_ID);
        
        this.ctcpTypeCd = [{ CD_NM: usserAuth[0].CTCP_NAME, CD: usserAuth[0].CTCP_CODE }];
        this.userStatCd = await this.mixin_common_code_get(this.allCodeList, 'HLW_CNSLR_STAT_CD');
        this.detailCtcpTypeCd = [{ CD_NM: usserAuth[0].CTCP_NAME, CD: usserAuth[0].CTCP_CODE }];
        this.CTCP_CODE = this.ctcpTypeCd[0].CD;
        this.changeBussType(this.CTCP_CODE);
      }
    },

    computed: {
      initHeaders(){
        return {
          SERVICE : 'setting.agent.manage',
          METHOD : "",
          TYPE : 'BIZ_SERVICE',
        };
      },
    },
  };
</script>

<style>

thead.v-data-table-header tr th {

  text-align-last: center;

}

</style>
