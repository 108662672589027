<template>
  <div>
    <v-fade-transition mode="out-in">
      <router-view />
    </v-fade-transition>
    <Toast />
    <Alert />
  </div>
</template>

<script>
import Toast from "@/components/ToastDefault";
import Alert from "@/components/AlertDefault";

export default {
  name: "App",
  components: {
    Toast,
    Alert,
  },
  mounted() {
    window.addEventListener("beforeunload", this.unLoadEvent);
  },
  methods: {
    unLoadEvent(event) {
      //로그인페이지가 아니라면 새로고침 클릭시 컴폼을 받지 않는다
      if (this.$route.fullPath !== "/login") {
        event.preventDefault();
        event.returnValue = true;
      }
    },
  },
  beforeDestroy() {
    //세션에 담나놓는거라 서비스가 종료되면 자연스럽게 삭제되지만
    //혹시몰라서 추가
    window.sessionStorage.clear();
  },
};
</script>
