const commonStore = {
  namespaced: true,
  state: () => ({
    tab: [], //전체 탭
    no_cash_tab: [], //전체 탭
    active_tab: {
      num: 0, //
      id: "",
      title: "",
      link: "",
    },
    help_dialog: false,
    help_dialog_id: "",
    progress_bar: false,
    imageExtArr: ['.png','.jpg','.gif','.jpeg','.jfif','.jpe','.jif','.exif','.tiff','.bmp','.ppm','.pgm','.pgm','.pbm','.pnm','.heif','.bpg'],
    qutnId:'',
  }),
  getters: {
    GE_COMMON_TAB: (state) => state.tab,
    GE_COMMON_NO_CASH_TAB: (state) => state.no_cash_tab,
    GE_COMMON_ACTIVE_TAB: (state) => state.active_tab,
    GE_COMMON_ACTIVE_TAB_NUM: (state) => state.active_tab.num,
    GE_COMMON_ACTIVE_TAB_ID: (state) => state.active_tab.id,
    GE_COMMON_ACTIVE_TAB_TITLE: (state) => state.active_tab.title,
    GE_COMMON_HELP_DIALOG: (state) => state.help_dialog,
    GE_COMMON_HELP_DIALOG_ID: (state) => state.help_dialog_id,
    GE_COMMON_PROGRESS_BAR: (state) => state.progress_bar,
    GE_COMMON_IMAGE_EXT_ARR: (state) => state.imageExtArr,
    GE_COMMON_SURVEY_ITEM: (state) => state.qutnId,
    // GE_COMMON_ACTIVE_TAB_NUM: state => state.active_tab_num,
    // GE_COMMON_ACTIVE_TAB_ID: state => state.active_tab_id,
    // GE_COMMON_ACTIVE_TAB_TITLE: state => state.active_tab_title,
  },
  mutations: {
    MU_COMMON_ACTIVE_TAB: (state, data) => {
      /*
                여기서는 payload를 객체로 받습니다.
                payload를 객체로 받으면, mutation를 조금더 유연하게 사용할 수 있기는 합니다.
            */
      let aleady = false; //기존에 있는 탭인지 여부
      //설문의 경우 삭제 후 재생성
      if(data.qutnId&&data.camBool) {
        state.tab.forEach((tab, i) => {
          if(tab.id === 'CAM0200' || tab.id === 'OMM0200'){
            state.tab.splice(i, 1);
          }
        });
      }else{
        data.qutnId=''
      }

      setTimeout(function () {

        //기존에 탭에 있는 탭인지 확인
        for (let i = 0; i < state.tab.length; i++) {
          if (state.tab[i].id === data.id) {
            aleady = true;
            state.active_tab.num = i; //기존 열려 있는 탭을 활성화 시킨다
          }
        }

        //기존에 열려 있는 탭이 아닌 신규탭이면 탭을 추가하고 활성화 시킨다
        if (aleady === false) {
          state.tab.push({
            id: data.id,
            title: data.title,
            icon: data.tabIcon,
            link: data.link,
          });
          state.active_tab.num = state.tab.length - 1;
        }

        state.active_tab.id = data.id;
        state.active_tab.title = data.title;
        state.qutnId = data.qutnId;

        //탭삭제
        for (let a = 0; a < state.no_cash_tab.length; a++) {
          if (state.no_cash_tab[a] === data.id) {
            state.no_cash_tab.splice(a, 1);
          }
        }
      }, 100);
    },
    MU_COMMON_DEACTIVE_TAB: (state, data) => {
      /*
                여기서는 payload를 객체로 받습니다.
                payload를 객체로 받으면, mutation를 조금더 유연하게 사용할 수 있기는 합니다.
            */

      setTimeout(function () {
        //탭삭제
        for (let i = 0; i < state.tab.length; i++) {
          if (state.tab[i].id === data.id) {
            state.tab.splice(i, 1);
          }
        }

        //삭제한 탭이 마지막 탭이거나 삭제한탭이 활성화 중인 탭이면
        //삭제한 후 남아있는 마지막 탭으로 이동
        if (
          state.active_tab.num > state.tab.length - 1 ||
          state.active_tab.id === data.id
        ) {
          state.active_tab.num = state.tab.length - 1;
          state.active_tab.id = state.tab[state.tab.length - 1].id;
          state.active_tab.title = state.tab[state.tab.length - 1].title;
          state.active_tab.link = state.tab[state.tab.length - 1].link;
        }

        //cache 하지 않아야 하는 탭리스트에 추가
        state.no_cash_tab.push(data.id);
      }, 100);
    },
    MU_COMMON_INIT_TAB: (state, data) => {
      state.tab = data;
      if (state.tab.length > 0) {
        state.active_tab.num = 0;
        state.active_tab.id = state.tab[0].id;
        state.active_tab.title = state.tab[0].title;
        state.active_tab.link = state.tab[0].link;
      }
    },
    MU_COMMON_DELETE_ALL_TAB: (state, data) => {
      let change_action_tab = true;

      //기본(default가 'Y')탭을 제외하고 모든탭을 삭제한다
      for (let i = 0; i < state.tab.length; i++) {
        if (state.tab[i].default !== "Y") {
          state.tab.splice(i, 1);
          i--;
        } else {
          //현재 열려있는 탭이 기본탭이면 탭변경을 하지 않는다
          if (state.active_tab.id === state.tab[i].id) {
            change_action_tab = false;
          }
        }
      }

      //탭을 변경한다
      if (change_action_tab === true && state.tab.length > 0) {
        state.active_tab.num = 0;
        state.active_tab.id = state.tab[0].id;
        state.active_tab.title = state.tab[0].title;
        state.active_tab.link = state.tab[0].link;
      }
    },
    MU_COMMON_HELP_DIALOG: (state, data) => {
      state.help_dialog = data.type;
    },
    MU_COMMON_HELP_DIALOG_ID: (state, data) => {
      state.help_dialog_id = data.id;
    },
    MU_COMMON_PROGRESS_BAR: (state, data) => {
      state.progress_bar = data;
    },
  },
  actions: {
    //신규탭 생성
    async AC_COMMON_ACTIVE_TAB({ commit }, data) {
      commit("MU_COMMON_ACTIVE_TAB", data);
    },
    //탭 삭제
    async AC_COMMON_DEACTIVE_TAB({ commit }, data) {
      commit("MU_COMMON_DEACTIVE_TAB", data);
    },
    //탭 초기화
    async AC_COMMON_INIT_TAB({ commit }, data) {
      commit("MU_COMMON_INIT_TAB", data);
    },
    //탭 전체삭제
    async AC_COMMON_DELETE_ALL_TAB({ commit }, data) {
      commit("MU_COMMON_DELETE_ALL_TAB", data);
    },
    //HELP DIALOG 상태 변경
    async AC_COMMON_HELP_DIALOG({ commit }, data) {
      commit("MU_COMMON_HELP_DIALOG", data);
    },
    //HELP DIALOG ID 셋팅
    async AC_COMMON_HELP_DIALOG_ID({ commit }, data) {
      commit("MU_COMMON_HELP_DIALOG_ID", data);
    },
    //HELP DIALOG ID 셋팅
    async AC_COMMON_PROGRESS_BAR({ commit }, data) {
      commit("MU_COMMON_PROGRESS_BAR", data);
    },
  },
};

export default commonStore;
