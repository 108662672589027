<template>
  <v-list-group
    :prepend-icon="item.ICON_CLASS_NM"
    :key="item.C_NODE_NO"
    color="none"
    no-action
  >
    <!-- {{ item }} -->
    <template v-slot:activator>
      <v-list-item-content>
        <v-list-item-title>{{ item.NODE_TITLE }}</v-list-item-title>
      </v-list-item-content>
    </template>
    <template v-for="(child, index) in item.child">
      <template v-if="child.child">
        <v-list-group
          :prepend-icon="child.ICON_CLASS_NM"
          :key="`group-${index}`"
          v-if="child.child"
          color="none"
          sub-group
          class="two-depth"
          active-class="active-icon"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ child.NODE_TITLE }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <template v-for="(child2, index2) in child.child">
            <!-- {{ child2.items }} -->
            <menu-list
              class="three-depth"
              :key="`child2-${index2}`"
              :item="child2"
              :user_menu="user_menu"
            />
          </template>
        </v-list-group>
      </template>
      <menu-list
        v-else
        class="two-depth"
        :key="`child-${index}`"
        :item="child"
        :user_menu="user_menu"
      />
    </template>
  </v-list-group>
</template>

<script>
  import MenuList from "./MenuList";

  export default {
    components: { MenuList },
    name: "MenuGroupList",
    props: {
      item: {
        type: Object,
        default: () => ({}),
        // type: Array,
        // default: () => [],
      },
      user_menu: {
        type: Array,
        default: () => [],
      },
    },
    data: () => ({
    }),
    computed: {
    },
    methods: {
      checkUserRole: (id, user_menu) => {
        var return_val = false;
        if (id) {
          if (user_menu) {
            for (var i = 0; i < user_menu.length; i++) {
              if (id === user_menu[i].C_NODE_NO) return_val = true;
            }
          }
        }
        return return_val;
      },
    },
  };
</script>

<style>
  .menu-list .v-list-item {
    padding: 0px !important;
    margin: 0px !important;
  }

  .menu-list .two-depth {
    padding-left: 32px !important;
  }

  .menu-list .v-list-item.two-depth {
    padding-left: 20px !important;
  }
  .menu-list .three-depth {
    padding-left: 20px !important;
  }

  .menu-list .v-list-item.three-depth {
    padding-left: 0px !important;
  }

  .menu-list .v-list-group.two-depth > .v-list-item {
    position: relative;
  }
  .menu-list .v-list-group.two-depth > .v-list-item:after {
    position: absolute;
    top: 0px;
    right: 14px;
    display: inline-block;
    font: normal normal normal 24px/1 "Material Design Icons";
    font-size: inherit;
    text-rendering: auto;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
    content: "\F0140";
    display: inline-block;
    font-size: 24px;
    width: 24px;
    height: 24px;
    padding: 0px !important;
    margin: 0;
    transform: rotate(0deg);
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), visibility 0s;
    color: #868894;
  }

  .menu-list .v-list-group.two-depth > .v-list-item.active-icon:after {
    transform: rotate(-180deg);
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), visibility 0s;
  }

  .menu-list .v-list-item__icon {
    margin-right: 16px !important;
  }
</style>
