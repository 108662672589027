<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">문의유형관리</h1>
    <div class="box-wrap d-flex">
      <div class="box-ct">
        <div class="form-inp sm">
          <v-select
            :items="dropItems"
            item-text="CD_NM"
            item-value="ASP_CUST_KEY"
            return-object
            outlined
            hide-details
            placeholder="선택하세요"
            @change="clickSelBoxItem"
            v-model="dropItemsV"
            :readonly="RDOnly"
          >
          <template v-slot:label>
            회사구분
            <v-icon color="#EF9191">mdi-circle-small</v-icon>
          </template>
          </v-select>
        </div>
      </div>
    </div>

    <div class="box-wrap">
      <v-tabs class="tabs-form" slider-color="#6C6E7A" slider-size="3">
        <v-tab @click="">카카오톡</v-tab>
        <v-tab-item>
          <div class="d-flex mt-5">
            <div
              style="
                padding: 20px;
                width: 400px;
                border: 1px solid #ddd;
                box-sizing: border-box;
              "
            >
              <div>
                <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnUnfold')" outlined class="btn-default" @click="unfoldTreeView"
                  >전체펼치기</v-btn
                >
                <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnFold')" outlined class="btn-default ml-2" @click="foldTreeView"
                  >전체접기</v-btn
                >
                <!-- 새로고침 버튼 -->
                <v-btn
                  v-if="this.mixin_set_btn(this.$options.name, 'btnRefresh')"
                  dark
                  small
                  class="btn-main-refresh pa-0 ml-2"
                  min-width="32px"
                  height="32px"
                  @click="clickBtnRefresh"
                  plain
                >
                  <v-icon small class="svg-main-refresh"></v-icon>
                </v-btn>
              </div>
              <div class="tree-wrap mt-5 scrollable" style="height: 488.9px">
                <template>
                  <v-treeview
                    :items="treeItems"
                    open-all
                    return-object
                    activatable
                    ref="treeViewM810602"
                    @update:active="clickTreeItem"
                  >
                    <template v-slot:prepend="{ open, item }">
                      <v-icon color="#8B98DD" v-if="item.children">
                        {{ open ? "mdi-folder-open" : "mdi-folder" }}
                      </v-icon>
                    </template>

                    <template v-slot:label="{ item }">
                      {{ item.name }}
                      <v-icon color="#F06969">
                        {{ item.locked ? "mdi-close-circle-outline" : "" }}
                      </v-icon>
                    </template>
                  </v-treeview>
                </template>
              </div>
            </div>

            <div v-if="detailsDivUse == true" class="ml-4">
              <h1 class="tit-h2 d-flex">
                {{ details.title }}
                <div class="ml-auto mr-0">
                  <v-switch
                    label="사용"
                    color="red"
                    inset
                    @click="clickTglBtnUseYn"
                    v-model="details.useYn"
                    >사용</v-switch
                  >
                </div>
              </h1>

              <div class="tt-card-body">
                <div class="tt-mssge-body">
                  <div class="tt-talk-container">
                    <div>
                      <v-img
                        class="tt-list-img"
                        @click="clickImgAdd"
                        :style="{
                          background: imgPath,
                          'background-size': 'cover',
                        }"
                      >
                      </v-img>
                    </div>
                    <div class="tt-talk-box">
                      <div class="d-flex">
                        <h4 class="mt-2">메시지</h4>
                        <v-icon color="#EF9191" left>mdi-circle-small</v-icon>
                      </div>
                      <textarea
                        readonly
                        cols="30"
                        rows="10"
                        placeholder="상담을 시작하면 서비스를 선택해 주세요."
                        @click="clickTextArea"
                        v-model="details.msg"
                      >
                      </textarea>
                      <div class="tt-list-btn">
                        <ul>
                          <draggable
                            v-model="detailChidList"
                            class="list-group"
                            ghost-class="ghost"
                            :move="checkMove"
                            @start="list1_dragging = true"
                            @end="list1_dragging = false"
                          >
                            <transition-group
                              type="transition"
                              name="flip-list1"
                            >
                              <li
                                v-for="(item, i) in detailChidList"
                                :key="item.CD"
                                :hidden="item.USE_YN=='D'"
                              >
                                <button
                                  type="button"
                                  class="tt-btn"
                                  @click="clickBtnChildren(item, i)"
                                >
                                  <i class="tt-handler is-vrt"></i>
                                  {{ item.INQRY_TYP_NM }}
                                </button>
                              </li>
                            </transition-group>
                          </draggable>
                          <li
                            v-if="Number(detailDataList.INQRY_TYP_DIV_CD) <= 3"
                          >
                            <button
                              type="button"
                              class="tt-btn"
                              @click="clickBtnChildrenAdd"
                            >
                              <i class="tt-handler is-vrt"></i>+
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center mt-5">
                <v-btn
                  v-if="this.mixin_set_btn(this.$options.name, 'btnCancle')"
                  class="btn-default"
                  outlined
                  @click="detailsDivUse = false"
                  >취소</v-btn
                >
                <v-btn
                  v-if="this.mixin_set_btn(this.$options.name, 'btnDelete')"
                  class="btn-etc ml-2"
                  outlined
                  @click="clickBtnDetailDelete"
                  >삭제</v-btn
                >
                <v-btn
                  v-if="this.mixin_set_btn(this.$options.name, 'btnSave')"
                  class="btn-point ml-2"
                  outlined
                  @click="clickBtnDetailSave"
                  >저장</v-btn
                >
              </div>
            </div>
          </div>
        </v-tab-item>
      </v-tabs>
    </div>
    <!-- 팝업 모달창 -->
    <div class="ml-auto">
      <v-dialog
        v-if="DialogM810602P01"
        max-width="500"
        persistent
        v-model="DialogM810602P01"
        content-class="square-modal"
      >
        <dialog-M810602P01
          headerTitle="dialog"
          @hide="hideDialog('M810602P01')"
          @Qsave="hideDialog('M810602P01')"
          :param="M810602P01Param"
          @getPopupData="setPopupData"
        >
        </dialog-M810602P01>
        <template v-slot:body> </template>
      </v-dialog>
      <v-dialog
        v-if="DialogM810602P02"
        max-width="655"
        width=""
        persistent
        v-model="DialogM810602P02"
        content-class="square-modal"
      >
        <dialog-M810602P02
          headerTitle="dialog"
          @hide="hideDialog('M810602P02')"
          :param="M810602P02Param"
          @submitUpload="uploadImg"
          @submitDelete="deleteImg"
        >
        </dialog-M810602P02>
        <template v-slot:body> </template>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import api from "@/store/apiUtil";
import { mixin } from "@/mixin/mixin";
import UserStore from "@/store/modules/userStore";
import DialogM810602P01 from "@/views/page/M810602P01";
import DialogM810602P02 from "@/views/page/M810602P02";

export default {
  name: "MENU_M810602", //name은 'MENU_' + 파일명 조합
  components: {
    DialogM810602P01,
    DialogM810602P02,
    draggable,
  },
  mixins: [mixin],

  data() {
    return {
      ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
      ASP_NEWCUST_KEY: "",
      FILE_GROUP_KEY: "",

      imgUrlKey: {
        FILE_PATH: "",
        SAVE_FILENAME: "",
        FILE_GROUP_KEY: "",
        FILE_KEY: "",
      },

      list1_enabled: true,
      list1_dragging: false,

      DialogM810602P01: false,
      DialogM810602P02: false,
      M810602P01Param: {},
      M810602P02Param: {},

      dropItems: [],
      dropItemsV: {},

      treeItems: [],
      treeItemsV: {},

      detailsDivUse: false,
      detailChidList: [],
      detailDataList: {},
      details: {
        title: "title",
        msg: "",
        useYn: 0,
        qType: "",
        qTypeNm: "",
        FILE_GROUP_KEY: "",
        FILE_KEY: "",
      },

      popupDropItems: [],
      //qTypeTit: this.details.title,

      imgPath: "",
      imgDir: "url(/upload/",
      
      RDOnly:false,
    };
  },
  methods: {
    //*********************************************
    //           회사구분, 트리메뉴 method
    //*********************************************
    //회사구분 셀렉트박스 아이템 호출
    async getSelBoxList() {
      let requestData = { headers: {}, sendData: {} };

      requestData.headers["URL"] = "/api/code/common/code-book/sender/aspinqry";
      requestData.headers["SERVICE"] = "code.common.code-book.sender";
      requestData.headers["METHOD"] = "aspinqry";
      requestData.headers["ASYNC"] = "false";
      requestData.headers["TYPE"] = "BIZ_SERVICE";

      requestData.sendData["ASP_USER_ID"] = this.ASP_USER_ID;

      let response = await this.common_postCall(requestData);
      this.getSelBoxListThen(response);
    },

    //회사구분 셀렉트박스 아이템 호출 후처리
    getSelBoxListThen(response) {
      this.dropItems = response[this.ASP_USER_ID];
    },

    //트리 아이템 호출
    async clickSelBoxItem(item) {
      this.ASP_NEWCUST_KEY = item.ASP_CUST_KEY;
      let requestData = { headers: {}, sendData: {} };

      requestData.headers["SERVICE"] = "chat.setting.inqry-ty-manage.chnnl-tr";
      requestData.headers["METHOD"] = "inqire";
      requestData.headers["ASYNC"] = "false";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["URL"] =
        "/api/chat/setting/inqry-ty-manage/chnnl-tr/inqire";

      requestData.sendData["BIZ_SERVICES_CD"] = "KAKAOBZC"; // 카카오톡
      requestData.sendData["ASP_NEWCUST_KEY"] = item.ASP_CUST_KEY;

      let response = await this.common_postCall(requestData);
      this.clickSelBoxItemThen(response);
    },

    //트리 아이템 호출 후처리(트리메뉴 구성)
    clickSelBoxItemThen(response) {
      let data = response.DATA;
      let items = [];

      if (data.length != 0 && data.at(0).USE_YN == "Y") {
        for (var i = 0; i < data.length; i++) {
          let element = {
            id: data[i].NODE_ID,
            name: data[i].NODE_TITLE,
            cNode: data[i].C_NODE_NO,
            pNode: data[i].P_NODE_NO,
            //dNode : data[i].D_NODE_NO,
            nodeInfo: data[i].NODE_ETC,
            nodeType: data[i].NODE_TYPE,
            nodeLvl: data[i].NODE_LVL,
            inqryUseCh: data[i].NODE_INQRY_USE_CHANNEL,
            nodeKey: data[i].NODE_KEY,
          };
          if (data[i].USE_YN == "N") element["locked"] = true;
          if (data[i].NODE_TYPE == "D") element.children = [];

          if (element.nodeLvl == 2) {
            for (var j = 0; j < items.length; j++) {
              if (element.pNode == items[j].cNode) {
                items[j].children.push(element);
              }
            }
          } else if (element.nodeLvl == 3) {
            for (var k = 0; k < items.length; k++) {
              if(items[k].children != undefined){
                for (var l = 0; l < items[k].children.length; l++) {
                  if (element.pNode == items[k].children[l].cNode) {
                    items[k].children[l].children.push(element);
                  }
                }
              }
            }
          } else if (element.nodeLvl == 4) {
            for (var k = 0; k < items.length; k++) {
              if(items[k].children != undefined){
                for (var l = 0; l < items[k].children.length; l++) {
                  if(items[k].children[l].children != undefined){
                    for (var m = 0; m < items[k].children[l].children.length; m++) {
                      if (element.pNode == items[k].children[l].children[m].cNode) {
                        items[k].children[l].children[m].children.push(element);
                      }
                    }
                  }
                }
              }
            }
          } else {
            items.push(element);
          }
        }
      } else if (data.length != 0 && data.at(0).USE_YN == "N") {
        for (var i = 0; i < data.length; i++) {
          let element = {
            id: data[i].NODE_ID,
            name: data[i].NODE_TITLE,
            cNode: data[i].C_NODE_NO,
            pNode: data[i].P_NODE_NO,
            //dNode: data[i].D_NODE_NO,
            nodeInfo: data[i].NODE_ETC,
            nodeType: data[i].NODE_TYPE,
            nodeLvl: data[i].NODE_LVL,
            inqryUseCh: data[i].NODE_INQRY_USE_CHANNEL,
            nodeKey: data[i].NODE_KEY,
            locked: true,
          };
          if (data[i].NODE_TYPE == "D") {
            element.children = [];
          }
          items.push(element);
        }
      } else {
        this.common_alert("조회된 데이터가 없습니다", "error");
      }

      this.treeItems = items;

      this.unfoldTreeView();
      this.getPopupSelBoxItems();
    },

    //트리 아이템 클릭 이벤트
    async clickTreeItem(item) {
      console.log("item", item);

      if (item.length == 0) {
        return;
      }
      const SYSTEM_MSG_ID = "MSG34899";
      (this.imgPath = ""), (this.treeItemsV = {});
      this.treeItemsV = item;

      let requestData = { headers: {}, sendData: {} };

      requestData.headers["URL"] = "/api/chat/setting/inqry-ty-manage/detail";
      requestData.headers["SERVICE"] = "chat.setting.inqry-ty-manage";
      requestData.headers["METHOD"] = "detail";
      requestData.headers["ASYNC"] = "true";
      requestData.headers["CALL_BACK"] =
        "ChatSettingInquiryTypeManage.callback_selectRtnNodeDetail";
      requestData.headers["TYPE"] = "BIZ_SERVICE";

      requestData.sendData["ASP_NEWCUST_KEY"] = this.dropItemsV.ASP_CUST_KEY;
      requestData.sendData["INQRY_TYP_CD"] = item.at(0).nodeKey;
      requestData.sendData["INQRY_USE_CHANNEL"] = item.at(0).inqryUseCh;
      requestData.sendData["SYSTEM_MSG_ID"] = SYSTEM_MSG_ID;

      let response = await this.common_postCall(requestData);
      this.clickTreeItemThen(response);
    },

    clickTreeItemThen(response) {
      let data = response;
      if (data.INQRY_DETAIL.lennth == 0) {
        this.common_alert("Detail Data Error", "error");
        return;
      }
      console.log("data", data);

      this.detailsDivUse = true;
      this.details.title = data.INQRY_DETAIL.at(0).CD_NM;
      this.details.msg =
        data.INQRY_DETAIL.at(0).INQRY_DESC != ""
          ? data.INQRY_DETAIL.at(0).INQRY_DESC
          : data.INQRY_DEFAULT_MSG.at(0).MSG_CONTENT;
      this.details.useYn = data.INQRY_DETAIL.at(0).USE_YN == "Y" ? true : false;
      this.details.qType = data.INQRY_DETAIL.at(0).INQRY_TYPE;
      this.details.qTypeNm = data.INQRY_DETAIL.at(0).INQRY_TYP_NM;

      this.detailDataList = data.INQRY_DETAIL.at(0);
      this.detailChidList = data.INQRY_CHILDREN;

      if (data.INQRY_DETAIL.at(0).FILE_PATH != "") {
        this.details.FILE_GROUP_KEY = data.INQRY_DETAIL.at(0).FILE_GROUP_KEY;
        this.details.FILE_KEY = data.INQRY_DETAIL.at(0).FILE_KEY;
        this.setImageUrl(data.INQRY_DETAIL.at(0));
      }
    },

    //새로고침 버튼 이벤트
    clickBtnRefresh() {
      this.clickSelBoxItem(this.dropItemsV);
      this.foldTreeView();
      this.detailsDivUse = false;
    },

    unfoldTreeView() {
      this.$refs.treeViewM810602.updateAll(true);
    },
    foldTreeView() {
      this.$refs.treeViewM810602.updateAll(false);
    },

    //*********************************************
    //             디테일 메뉴 메소드
    //*********************************************
    // 디테일 팝업 셀렉트박스 아이템 호출
    async getPopupSelBoxItems() {
      let requestData = { headers: {}, sendData: {} };

      requestData.headers["SERVICE"] = "code.common.code-book";
      requestData.headers["METHOD"] = "inqry";
      requestData.headers["ASYNC"] = "false";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["URL"] = "/api/code/common/code-book/inqry";

      requestData.sendData["GROUP_CD"] = "TALK029";

      let response = await this.common_postCall(requestData);
      this.getPopupSelBoxItemsThen(response);
    },

    // 디테일 팝업 셀렉트박스 아이템 호출 후처리
    getPopupSelBoxItemsThen(response) {
      this.popupDropItems = response.TALK029;
    },

    // 사용여부 useYn 스위치
    clickTglBtnUseYn() {
      console.log(this.details.useYn);
    },

    // 디테일 첨부이미지 팝업
    clickImgAdd() {
      console.log("clickImgAdd Click~!!!");
      this.M810602P02Param = {
        fileInfo: {},
        FILE_NUM: "1",
        FILE_SIZE: "5",
        FILE_NEWTY: [".jpg", ".png", ".gif"],

        FILE_TYPE: [
          { text: ".jpg", selected: true },
          { text: ".png", selected: true },
          { text: ".gif", selected: true },
          { text: ".pdf", selected: true, isLast: true },
        ],

        ASP_CUST_KEY: this.dropItemsV.CD,
        ASP_NEWCUST_KEY: this.dropItemsV.CD,
        UPLOAD_URI: "/api/file/chat/images/uploads",
        DOWNLOAD_URI: "/api/file/chat/images/downloads",
        DIR: "/PALETTE2/hkcloud/project/deploy/production/repository/web/chat/images",
        TEMP_DIR:
          "/PALETTE2/hkcloud/project/deploy/production/repository/web/chat/images/temp",
        TARGET_TY: "FILE",
        FILE_GROUP_KEY: this.imgUrlKey.FILE_GROUP_KEY,
      };
      this.showDialog("M810602P02");
    },

    // 디테일 textArea 팝업
    clickTextArea() {
      this.M810602P01Param = {
        headerTitle: "문의유형 상세",
        childFlag: "area",
        userId: this.$store.getters["userStore/GE_USER_ID"],
        selBoxItems: this.popupDropItems,
        popupDetails: {
          INQRY_TYPE: this.details.qType,
          msg: this.details.msg,
        },
      };
      this.showDialog("M810602P01");
    },

    // 디테일 하위메뉴 팝업
    clickBtnChildren(item, i) {
      this.M810602P01Param = {
        childFlag: "child",
        childIndex: i,
        userId: this.$store.getters["userStore/GE_USER_ID"],
        selBoxItems: this.popupDropItems,
        popupDetails: {
          INQRY_TYPE: item.INQRY_TYPE,
          INQRY_TYP_NM: item.INQRY_TYP_NM,
        },
      };
      this.showDialog("M810602P01");
    },

    // 디테일 하위메뉴 add 팝업
    clickBtnChildrenAdd() {
      this.M810602P01Param = {
        childFlag: "add",
        userId: this.$store.getters["userStore/GE_USER_ID"],
        selBoxItems: this.popupDropItems,
        popupDetails: {
          INQRY_TYPE: this.details.INQRY_TYPE,
        },
      };
      this.showDialog("M810602P01");
    },

    //팝업 후처리
    setPopupData(params) {
      switch (params.flag) {
        case "mod":
          if(params.cFlag === "area") {
            this.details.msg = params.msg;
            this.details.qType = params.inqryType
          }
          if(params.cFlag === "child") {
            this.detailChidList[params.childIndex].INQRY_TYPE = params.inqryType;
            this.detailChidList[params.childIndex].INQRY_TYP_NM = params.title;
          }
          break;

        case "del":
          //this.detailChidList.splice(params.sendData, 1);
          this.detailChidList[params.sendData].USE_YN = "D";
          break;

        case "add":
          let tempObj = {
            INQRY_TYP_CD: "",
            INQRY_TYP_NM: params.sendData.INQRY_TYP_NM,
            INQRY_TYPE: params.sendData.INQRY_TYPE,
            INQRY_TYP_DIV_CD: Number(this.detailDataList.INQRY_TYP_DIV_CD) + 1,
            USE_YN: "Y",
            CD: Date.now(),
          };
          this.detailChidList.push(tempObj);
          break;

        default:
          break;
      }
    },

    //이미지 파일 팝업 업로드 후
    async uploadImg(item) {
      this.details.FILE_GROUP_KEY = item[0].fileGroupKey;
      this.details.FILE_KEY = item[0].fileKey;

      let data = {
        FILE_PATH: item[0].filePath,
        SAVE_FILENAME: item[0].saveFilename,
      };
      this.setImageUrl(data);
      this.hideDialog("M810602P02");
    },

    // 첨부된 이미지 삭제(삭제 쿼리는 돌지 않음)
    deleteImg() {
      this.imgPath = "";

      this.details.FILE_GROUP_KEY = "";
      this.details.FILE_KEY = "";
    },

    // 첨부파일 리스트 불러오기
    async getAtcFileList(item) {
      let requestData = { headers: {}, sendData: {} };
      // header 세팅
      requestData.headers["URL"]     = "api/km/content/regist/file/inqire";
      requestData.headers["SERVICE"] = "km.content.regist.file";
      requestData.headers["METHOD"]  = "inqire";
      requestData.headers["TYPE"]    = "BIZ_SERVICE";
      requestData.headers["ASYNC"]   = false;

      // sendData 세팅
      requestData.sendData["FILE_GROUP_KEY"]  = item.fileGroupKey;
      requestData.sendData["ASP_NEWCUST_KEY"] = item.aspNewCustKey;

      // 결과 리턴
      let response = await this.common_postCall(requestData);
      this.setImageUrl(response);
    },

    setImageUrl(data) {
      // this.imgUrlKey.FILE_PATH = data[0].FILE_PATH;
      // this.imgUrlKey.SAVE_FILENAME = data[0].SAVE_FILENAME;
      this.imgPath =
        this.imgDir + data.FILE_PATH + "/" + data.SAVE_FILENAME + ")";
      console.log(this.imgPath);
    },

    // 디테일 삭제 confirm dialog
    clickBtnDetailDelete() {
      this.common_confirm(
        "삭제 하시겠습니까?",
        this.delConfirm,
        null,
        null,
        null,
        "error"
      );
    },

    async delConfirm() {
      let requestData = { headers: {}, sendData: {} };

      requestData.headers["URL"] = "/api/chat/setting/inqry-ty-manage/delete";
      requestData.headers["SERVICE"] = "chat.setting.inqry-ty-manage";
      requestData.headers["METHOD"] = "delete";
      requestData.headers["ASYNC"] = "false";
      requestData.headers["TYPE"] = "BIZ_SERVICE";

      requestData.sendData["INQRY_TYP_CD"] = this.detailDataList.INQRY_TYP_CD;
      requestData.sendData["INQRY_TYP_DIV_CD"] =
        this.detailDataList.INQRY_TYP_DIV_CD;
      requestData.sendData["ASP_NEWCUST_KEY"] = "HKCTALKMNG";

      let response = await this.common_postCall(requestData);
      this.clickBtnDetailDeleteThen(response);
    },

    clickBtnDetailDeleteThen(response) {
      if (!response.HEADER.ERROR_FLAG) {
        this.common_alert("정상적으로 삭제되었습니다.", "done");
        this.clickSelBoxItem(this.dropItemsV);
        this.detailsDivUse = false;
      } else {
        this.common_alert(response.HEADER.ERROR_MSG, "error");
      }
    },

    // 디테일 저장 버튼
    async clickBtnDetailSave() {
      let requestData = { headers: {}, sendData: {} };

      requestData.headers["SERVICE"] = "chat.setting.inqry-ty-manage";
      requestData.headers["METHOD"] = "regist";
      requestData.headers["ASYNC"] = "false";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["URL"] = "/api/chat/setting/inqry-ty-manage/regist";

      requestData.sendData["REGR_ID"] = this.ASP_USER_ID;
      requestData.sendData["REGR_DEPT_CD"] = this.detailDataList.REGR_DEPT_CD;
      requestData.sendData["TRANS_STATUS"] = "U";
      requestData.sendData["INQRY_USE_CHANNEL"] =
        this.detailDataList.INQRY_USE_CHANNEL;
      requestData.sendData["SPST_INQRY_TYP_CD"] =
        this.detailDataList.SPST_INQRY_TYP_CD;
      requestData.sendData["INQRY_TYP_CD"] = this.detailDataList.INQRY_TYP_CD;
      requestData.sendData["INQRY_TYP_NM"] = this.details.qTypeNm;
      requestData.sendData["SORT_ORD"] = this.detailDataList.SORT_ORD;
      requestData.sendData["INQRY_TYP_DIV_CD"] =
        this.detailDataList.INQRY_TYP_DIV_CD;
      requestData.sendData["USE_YN"] = this.details.useYn == true ? "Y" : "N";
      requestData.sendData["INQRY_TYPE"] = this.details.qType;
      requestData.sendData["INQRY_DESC"] = this.details.msg;
      requestData.sendData["ASP_NEWCUST_KEY"] = this.ASP_NEWCUST_KEY;
      requestData.sendData["FILE_GROUP_KEY"] = this.details.FILE_GROUP_KEY;
      requestData.sendData["FILE_KEY"] = this.details.FILE_KEY; // 4dep 은 여기까지만 data 보냄

      if (this.detailDataList.INQRY_TYP_DIV_CD != "4") {
        requestData.headers["DATA_OBJECT"] = "INQRY_CHILDREN";

        // children 있을 경우 param 담기
        let tempList = [];
        for (let i in this.detailChidList) {
          let tempObj = {
            SPST_INQRY_TYP_CD: this.detailDataList.INQRY_TYP_CD,
            SORT_ORD         : this.detailChidList[i].USE_YN == "D" ? "999" : i.toString(),
            INQRY_TYP_CD     : this.detailChidList[i].INQRY_TYP_CD,
            INQRY_TYP_NM     : this.detailChidList[i].INQRY_TYP_NM,
            INQRY_TYPE       : this.detailChidList[i].INQRY_TYPE,
            INQRY_TYP_DIV_CD : this.detailChidList[i].INQRY_TYP_DIV_CD,
            USE_YN           : this.detailChidList[i].USE_YN,
            DATA_FLAG        : "false",
          };
          if (i == 0) tempObj["ASP_NEWCUST_KEY"] = this.ASP_NEWCUST_KEY;
          tempList.push(tempObj);
        }
        requestData.sendData["INQRY_CHILDREN"] = tempList;
      }

      let response = await this.common_postCall(requestData);
      this.clickBtnDetailSaveThen(response);
    },

    clickBtnDetailSaveThen(response) {
      if (response.HEADER.ERROR_FLAG == true) {
        this.common_alert("시스템에 문제가 생겼습니다.<br>관리자에게 문의하세요.", "error");
      } else {
        this.common_alert("정상적으로 처리되었습니다.", "done");
        this.clickSelBoxItem(this.dropItemsV);
        this.clickTreeItem(this.treeItemsV);
      }
    },

    checkMove: function (e) {
      window.console.log("Future index: " + e.draggedContext.futureIndex);
    },

    showDialog(menu) {
      console.log("showDialog");
      this[`Dialog${menu}`] = true;
    },
    hideDialog(menu) {
      console.log("hideDialog");
      this[`Dialog${menu}`] = false;
    },
  },
  computed: {},

  created() {},

  mounted() {
    console.log("~init~ M810602(문의유형관리)");
    this.getSelBoxList();
    this.getPopupSelBoxItems();

    let chk = this.mixin_check_AspCustKey_number();
    if(chk == 'Y'){
      this.dropItemsV = this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD;
      this.RDOnly = true;
    } else {
      this.RDOnly = false;
    }
  },
};
</script>

<!--<template v-slot:label>-->
<!--  회사구분-->
<!--  <v-icon color="#EF9191">mdi-circle-small</v-icon>-->
<!--</template>-->

<style scoped>
.tt-card-body {
  background-color: #c8cdde;
  padding: 20px 20px 10px;
  width: 340px;
  box-sizing: border-box;
}
.tt-mssge-body {
  max-width: 320px;
  border-radius: 0 20px 20px;
  overflow: hidden;
  background-color: #fff;
}
.tt-list-img {
  width: 100%;
  min-height: 160px;
  background: url(../../assets/img-mssg-img.png) no-repeat 50% 50% #ebeef2;
  cursor: pointer;
}
.tt-talk-box {
  padding: 10px;
}
.tt-talk-box .tit-h3 {
  margin-bottom: 5px;
}
.tt-talk-box textarea {
  margin-bottom: 5px;
  padding: 10px;
  width: 100%;
  height: 100px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  font-size: 13px;
  line-height: 1.2;
  resize: none;
}
.tt-list-btn ul {
  padding: 0;
  margin: 0;
}
.tt-list-btn ul li {
  margin-bottom: 2px;
}
.tt-list-btn ul li .tt-btn {
  position: relative;
  width: 100%;
  height: 35px;
  line-height: 35px;
  border: 1px solid #b6b7bf;
  background-color: #ebecf2;
  font-size: 12px;
  font-weight: 500;
  box-sizing: border-box;
}
.tt-list-btn ul li .tt-btn .tt-handler {
  position: absolute;
  top: 0;
  left: 5px;
  width: 20px;
  height: 100%;
  background: url(../../assets/icon-handler-vrt.png) no-repeat center center;
}
.ghost {
  opacity: 0.5;
  box-shadow: 0 0 0 3px orange inset;
  background: red !important;
}
</style>
