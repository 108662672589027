<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">콜백조회</h1>
    <div class="box-wrap">
      <div class="box-ct d-flex">

        <div class="">
          <div class="form-inp sm">
            <v-select
                :items="aspCustList"
                item-text="CD_NM"
                item-value="CD"
                outlined
                label="회사구분"
                placeholder="선택하세요"
                v-model="ASP_NEWCUST_KEY"
                hide-details
                :readonly="RDOnly"
                @change="getUserGroup()"
            >
              <template v-slot:label>
                회사구분
                <v-icon color="#EF9191">mdi-circle-small</v-icon>
              </template>
            </v-select>
          </div>
          <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
              content-class="calendar-modal"
              color="#FFF"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="dateRangeText"
                  v-bind="attrs"
                  v-on="on"
                  readonly
                  outlined
                  hide-details
                  class="form-inp icon-calendar ml-2"
                  append-icon="svg-calendar"
                  label="접수일자"
              ></v-text-field>
            </template>
            <div class="datepicker-range">
              <v-date-picker
                  v-model="dates[0]"
                  no-title
                  dark
                  color="#F7AFAE"
                  @change="startDate"
                  :first-day-of-week="1"
                  locale="ko-KR"
                  :day-format="mixin_getDate"
              ></v-date-picker>
              <v-date-picker
                  v-model="dates[1]"
                  dark
                  color="#F7AFAE"
                  no-title
                  show-current="false"
                  :min="dates[0]"
                  @change="endDate"
                  :first-day-of-week="1"
                  locale="ko-KR"
                  :day-format="mixin_getDate"
              ></v-date-picker>
            </div>
            <div class="text-center pt-3 pb-3">
              <v-btn
                  outlined
                  class="btn-default"
                  @click="menu1 = false"
              >확인</v-btn>
            </div>
          </v-menu>
          <div class="form-inp ml-2">
            <v-select
                single-line
                v-model="selected_userGroup"
                :items="userGroup"
                item-value="CD"
                item-text="CD_NM"
                outlined
                label="팀"
                multiple
                hide-details
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index < 1" small>
                    <span>{{ item.CD_NM }}</span>
                    <template>
                      <v-btn
                        icon
                        x-small
                        class="svg-close20 ml-1"
                        v-show="dropItemRemove === true"
                        @click.stop="removeDropGroup(index)"
                      ></v-btn>
                    </template>
                  </v-chip>
                  <span v-if="index === 0" class="grey--text text-caption">
                    ...
                  </span>
                </template>
              </v-select>
          </div>
          <div class="form-inp ml-2">
            <v-select
                :items="eotTyCodeList"
                item-text="CD_NM"
                item-value="CD"
                outlined
                hide-details
                label="처리구분"
                placeholder="선택하세요"
                v-model="EOT_TY"
            >
            <template v-slot:label>
              처리구분
            </template>
            </v-select>
          </div>
        </div>
        <div class="ml-auto align-self-center">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')" outlined class="btn-etc2 ml-3" @click="getListData" @keyup.enter="getListData" >조회</v-btn>
        </div>
      </div>
    </div>
    <!-- 콜백리스트 -->
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">콜백리스트
        <div class="ml-auto">
          <v-btn
            v-if="this.mixin_set_btn(this.$options.name, 'btnExcelDown')"
            outlined class="btn-point ml-2" @click="downloadExcel('mainTable', '콜백리스트')">엑셀다운로드</v-btn>
        </div>
      </h2>
      <div class="box-ct">
        <v-data-table
            dense
            fixed-header
            height="384px"
            :headers="gridDataHeaders"
            :items="gridDataText"
            :items-per-page="30"
            single-select
            item-key="index"
            :page.sync="page"
            hide-default-footer
            class="grid-default case03"
            @page-count="pageCount = $event"
            no-data-text="검색된 결과가 없습니다."
            @click:row="rowClick"
            id="mainTable"
        >
         <template v-slot:[`item.IN_DATE`]="{ item }" >
            <div>{{ dateFormat(item.IN_DATE) }}</div>
          </template>
          <template v-slot:[`item.IN_TIME`]="{ item }">
            <div> {{timeFormat(item.IN_TIME)}} </div>
          </template>
          <template v-slot:[`item.CUST_NO`]="{ item }">
            <div> {{juminFormat(item.CUST_NO)}} </div>
          </template>
          <template v-slot:[`item.SEND_MAN_NO`]="{ item }" >
            <div>{{ phoneHyphen(item.SEND_MAN_NO) }}</div>
          </template>
          <template v-slot:[`item.CLBK_TEL_NO`]="{ item }" >
            <div>{{ phoneHyphen(item.CLBK_TEL_NO) }}</div>
          </template>
        </v-data-table>
        <div class="grid-paging text-center pt-2">
          <v-pagination
              v-model="page"
              :length="pageCount"
              :total-visible="totalVisible"
              next-icon="svg-paging-next"
              prev-icon="svg-paging-prev"></v-pagination>
        </div>
        <p class="caption text-left">
          총<strong class="clr-error">{{ gridDataText.length }}</strong
          >건
        </p>
      </div>
    </div>
    <!--// 콜백리스트 -->
    <!-- 콜백처리결과 -->
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">콜백처리결과
        <div class="ml-auto">
           <v-btn
            v-if="this.mixin_set_btn(this.$options.name, 'btnSave')"
            outlined class="btn-point ml-2" @click="alertRegisterBtn">등록</v-btn>
        </div>
      </h2>
      <div class="box-ct">
        <div class="table-form">
          <table>
            <caption class="hide">고객상세정보</caption>
            <colgroup>
              <col width="127px">
              <col width="700px">
              <col width="127px">
              <col width="">
            </colgroup>
            <tbody>
            <tr>
              <th scope="row">
                <v-badge dot color="#EF9191" >처리결과</v-badge>
              </th>
              <td>
                <div class="form-inp lg">
                  <v-select
                      :items="this.mixin_common_code_get(this.commonCode, 'OU003')"
                      outlined
                      placeholder="선택"
                      v-model="EOT_TY_RLT"
                      hide-details
                  >
                  </v-select>
                </div>
              </td>
              <th scope="row"  dot color="#EF9191" >재시도사유
              </th>
              <td>
                <div class="form-inp lg">
                  <v-select
                      :items="mixin_common_code_get(this.commonCode, 'OU005', '선택')"
                      outlined
                      hide-details
                      placeholder="선택"
                      v-model="PROC_CODE"
                  >
                  </v-select>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!--// 콜백처리결과 -->

  </div>
</template>


<script>
import api from '../../store/apiUtil.js';
import {mixin} from '../../mixin/mixin.js'
import {mapGetters} from "vuex";

export default {
  name: "MENU_M110301", //name은 'MENU_' + 파일명 조합
  components: {},
  mixins:[mixin], // mixin 등록
  data() {
    return{
      menu1: false,

      dates: [
        (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      ],
      gridDataHeaders: [
        {
          text: 'No',
          align: 'center',
          value: 'index',
          width: '70px',
          sortable: true,
        },
        { text: '업무구분', value: 'IN_TY', align: 'center', sortable: true, },
        { text: '접수일자', value: 'IN_DATE',align: 'center', sortable: true, },
        { text: '인입시간', value: 'IN_TIME', align: 'center',sortable: true, },
        { text: '주민번호', value: 'CUST_NO', align: 'center',sortable: true, },
        { text: '발신번호', value: 'SEND_MAN_NO', align: 'center',sortable: true, },
        { text: '접수전화번호', value: 'CLBK_TEL_NO', align: 'center',sortable: true, },
        { text: '시도횟수', value: 'TRY_CNT', align: 'center',sortable: true, },
        { text: '처리구분', value: 'EOT_TY_NM', align: 'center',sortable: true, },
        { text: '재시도사유', value: 'PROC_CODE_NM', align: 'center',sortable: true, },
      ],

      commonArr: ["RE022", "OU005", "OU003"],
      commonCode : [],
      eotTyCodeList: [],

      selectedRow: [],

      gridDataText: [],
      page: 1,
      pageCount: 0,
      totcount : 0,
      itemsPerPage: 10,
      totalVisible: 10,

      ASP_NEWCUST_KEY: '',
      START_DATE: '',
      END_DATE: '',
      IN_TY: '',
      EOT_TY: '',
      EOT_TY_RLT: '',
      PROC_CODE: '',
      REGISTER_CLBK_NO: '',

      userGroup: [], //사용자그룹
      selected_userGroup: [],

      RDOnly:false,
    }
  },
  mounted(){
    // 헤더 초기화 및 세팅
    this.newCommonCode;

    let chk = this.mixin_check_AspCustKey_number();
    if(chk == 'Y'){
      this.ASP_NEWCUST_KEY = this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD;
      this.RDOnly = true;
      this.getUserGroup();
    } else {
      this.RDOnly = false;
    }
  },
  methods: {

    startDate(e){
      this.dates[0] = e;
    },
    endDate(e){
      this.dates[1] = e;
    },
  /*  getListOrNot(){
      if(this.ASP_NEWCUST_KEY == '' || this.ASP_NEWCUST_KEY == null){
        this.common_alert('회사구분을 선택해주세요.', 'error');
      }else{
        this.getListData();
      }
    },*/
    async getListData(){
      if(this.ASP_NEWCUST_KEY == '' || this.ASP_NEWCUST_KEY == null){
        this.common_alert('회사구분을 선택해주세요.', 'error');
        return;
      }
      //조회
      let requestData = {
        headers: {},
        sendData:{}
      };
      
      let CHK_GROUP = this.selected_userGroup[0];
      for(let i=1;i<this.selected_userGroup.length;i++){
        CHK_GROUP = CHK_GROUP + "," + this.selected_userGroup[i];
      }

      //header세팅
      requestData.headers["URL"] = "/api/phone/callback/inqire/list";
      requestData.headers["SERVICE"] = "phone.callback.inqire";
      requestData.headers["METHOD"] = "list";
      requestData.headers["TYPE"] = "XML_SERVICE";
      requestData.headers["GRID_ID"] = "divGridClbkInqList";
      requestData.headers["MENU_ID"] = "";
      requestData.headers["ASYNC"] = false;
      requestData.headers["PAGES_CNT"] = 1;
      requestData.headers["ROW_CNT"] = 100;

      //sendData 초기화
      requestData.sendData["ASP_NEWCUST_KEY"] = this.ASP_NEWCUST_KEY; //회사코드
      requestData.sendData["START_DATE"] = this.dates[0];           //접수시작일자
      requestData.sendData["END_DATE"] = this.dates[1];               //접수종료일자
      requestData.sendData["IN_TY"] = this.IN_TY;                     //업무구분
      requestData.sendData["EOT_TY"] = this.EOT_TY;                   //처리구분
      requestData.sendData["USER_GROUP"] = CHK_GROUP;                 //사용자그룹;

      let response = await this.common_postCall(requestData);
      this.getListRes(response);

    },
    async getRegisterData(){
      //콜백처리결과
      let requestData = {
        headers: {},
        sendData: {},
      };

      //헤더 세팅
      requestData.headers["URL"] = "/api/phone/callback/inqire/modify";
      requestData.headers["SERVICE"] = "phone.callback.inqire";
      requestData.headers["METHOD"] = "modify";
      requestData.headers["ASYNC"] = false;

      //sendData 초기화
      requestData.sendData['CLBK_NO'] = this.REGISTER_CLBK_NO,   //콜백번호
      requestData.sendData['CHNG_MAN'] = this.$store.getters['userStore/GE_USER_ROLE'].userId,      //변경자 (임시하드코딩, 로그인 유저로 변경되어야 함)
      requestData.sendData['EOT_TY_RLT'] = this.EOT_TY_RLT,      //처리결과
      requestData.sendData['PROC_CODE'] = this.PROC_CODE        //재사유시도

      await this.common_postCall(requestData);

      //재조회
      this.getListData();

      //콜백처리결과 초기화
      this.EOT_TY_RLT = '';  //처리결과
      this.PROC_CODE ='';    //재시도사유
    },
    getListRes(response){
      /*let header= res.HEADER;
      this.totcount = header.TOT_COUNT;
*/
      let data = response.DATA;
      this.gridDataText = data;
      console.log(data);

      let idx = 1;
      for(var i in this.gridDataText){
          this.gridDataText[i]["index"] = idx++;
      }

      //조회 시, 콜백처리결과 초기화
      this.EOT_TY_RLT = '';  //처리결과
      this.PROC_CODE ='';    //재시도사유

      //조회 시, 선택된 행 초기화
      if(this.selectedRow != ""){
        this.activeRow(this.selectedRow, false);
        this.selectedRow = "";
      }
    },
    //콜백리스트 row 클릭이벤트
    rowClick (value, row ) {
      if( !this.mixin_isEmpty(value) ){
//      this.activeRow(row, true);
        row.select(true);
      }
      this.REGISTER_CLBK_NO = row.item.CLBK_NO ;

      //행 클릭 시, 콜백처리결과에 해당 행의 값들이 나타날 수 있도록
      this.EOT_TY_RLT = row.item.EOT_TY;
      this.PROC_CODE = row.item.PROC_CODE;

    },
    activeRow(row,value){
      this.selectedRow = row;
      row.select(Boolean(value));
      row.isSelected = Boolean(value);
    },

    alertRegisterBtn(){
      if (this.REGISTER_CLBK_NO == '' || this.REGISTER_CLBK_NO == null){
        //처리 결과를 등록할 row를 선택하지 않았을 때
        this.common_alert('처리결과 등록 할 콜백 목록을 선택해 주세요.', 'error');
      }else if (this.EOT_TY_RLT == '' || this.EOT_TY_RLT == null){
         //처리 결과 선택하지 않고 등록을 시도할 경우
        this.common_alert('처리결과를 선택해 주세요.', 'error');
      }else{
        this.common_confirm('처리결과를 등록 하시겠습니까?', this.getRegisterData, null, null, null, 'chk');
      }
    },
    downloadExcel(tableId, fileNm){
        //엑셀다운로드
        this.table_id = tableId;
        this.file_name = fileNm;
        this.mixin_common_exportExcel();
    },
    phoneHyphen(number){
      //핸드폰 번호 형식 만들기
      var phoneNum = number.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
      return phoneNum;
    },
    dateFormat(date){
      //날짜 포멧
      if(!date) return "";
      var newDate = "";

      //공백제거
      date = date.replace(/\s/gi, "");

      if(date.length == 8){
          newDate = date.replace(/(\d{4})(\d{2})(\d{2})/, '$1/$2/$3');
          return newDate;
      }else{
          return date;
      }
    },
    juminFormat(num){
      //주민 번호 형식 만들기
      var juminNum = num.replace(/-/g, '').replace(/(\d{6})(\d{7})/g, '$1-$2');
      return juminNum;
    },
    timeFormat(time){
      //접수시간 포맷
      var newTime ='';

      if(time.length == 6 ){
        newTime = time.substring(0,2) + ":" +  time.substring(2,4) + ":" +  time.substring(4,6);
        return newTime;
      }else{
        //추후 6자리 외의 데이터가 있을 경우 수정
        return time;
      }
    },

    //소속정보를 가져와 사용자 그룹 셀렉트 박스 만들기
    async getUserGroup() {
      this.userGroup = await this.mixin_attr_get(this.ASP_NEWCUST_KEY, "B", "");
      let userGroupLeng = this.userGroup.length;
      let i = 0;
      this.selected_userGroup = [];
      for (i;i<userGroupLeng;i++){
        this.selected_userGroup[i] = this.userGroup[i].CD;
      }
    },

    removeDropGroup(index) {
      this.selected_userGroup.splice(index, 1);
    },

  },
  computed: {
    dateRangeText () {
      var newStartDate = this.dates[0];
      var newEndDate = this.dates[1];
      return newStartDate + ' ~ ' + newEndDate;
    },
    ...mapGetters({
      aspCustList: "userStore/GE_USER_COMPANY",
    }),
    async newCommonCode(){
      this.commonCode = await this.mixin_common_code_get_all(this.commonArr);

      //처리구분 콤보박스에서 처리내용완판(00007) 삭제한 값 리턴
       let eotTy = this.mixin_common_code_get(this.commonCode, 'OU003', '전체');
       for(var i=0; i<eotTy.length; i++){
         if(eotTy[i].value != '00007'){
            let codeObj = {
              CD : eotTy[i].value,
              CD_NM : eotTy[i].text,
            }
            this.eotTyCodeList.push(codeObj);
         }
       }
    },
  },
}
</script>

<style>

</style>
