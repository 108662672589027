<template>
  <div class="modal-wrap">
    <h1 class="tit-h1 d-flex">
      {{ grid01Selected.LM_ID_NM }}
      <div class="ml-auto align-self-center">
        <v-icon x-small class="svg-close20" @click="closeDialog()"></v-icon>
      </div>
    </h1>
    <div class="modal-box">
      <div>
        <div class="text-right">
          <span class="testform-tag">
              <span class="testform-tit">문항수</span>
              <span class="testform-txt">{{ grid01Selected.LM_EVA_RST_COUNT }}</span>
          </span>
          <span class="testform-tag">
              <span class="testform-tit">점수</span>
              <span class="testform-txt">{{ grid01Selected.LM_EVA_SUM }}</span>
          </span>
          <span class="testform-tag">
              <span class="testform-tit">시작시간</span>
              <span class="testform-txt">{{ getCurrentTime }}</span>
          </span>
          <span class="testform-tag">
              <span class="testform-tit">진행시간</span>
              <span class="testform-txt">{{ getEndDate }}</span>
          </span>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSubmit')" outlined class="btn-point ml-2" @click="common_confirm('저장하시겠습니까?',processRtn, '', null,'','done')">
            제출하기
          </v-btn>
        </div>
        <div class="mt-9 scrollable" style="height: 680px">
          <div class="">
            <div class="qa-report">
              <template v-for="(lmQs) in LM_QS_LIST">
                <dl :key="lmQs.LM_QS_ID" class="mb-5">
                  <dt class="mb-5">[문제 {{ lmQs.SORT_ORD }}] {{ lmQs.LM_QS_NM }} <strong
                      class="qa-report--point">[{{ lmQs.LM_QS_TY_SC }}점]</strong>
                  </dt>
                  <dd>
                    <!-- 객관식(단식) -->
                    <template v-if="lmQs.LM_QS_TY == '10'">
                      <v-radio-group :name="'ans_'+lmQs.LM_QS_ID" :sort-ord="lmQs.SORT_ORD">
                        <template v-for="lmVe in LM_VE_LIST">
                          <template v-if="lmVe.LM_QS_ID == lmQs.LM_QS_ID">
                            <div class="mt-2" :key="lmVe.LM_QS_VE_ID">
                              <v-radio :label="lmVe.QS_NUMBER + '. ' + lmVe.LM_QS_VE_RT"
                                       :on-icon="'svg-rad-on'" :off-icon="'svg-rad-off'"
                                       :value="lmVe.LM_QS_VE_ID">
                              </v-radio>
                            </div>
                          </template>
                        </template>
                      </v-radio-group>
                    </template>
                    <!-- 서술형 -->
                    <template v-else-if="lmQs.LM_QS_TY == '20'">
                      <template v-for="lmVe in LM_VE_LIST">
                        <template v-if="lmVe.LM_QS_ID == lmQs.LM_QS_ID">
                          <div :key="lmVe.LM_QS_VE_ID">
                            <v-text-field class="form-inp full" label="답변"
                                          :name="'ans_'+lmQs.LM_QS_ID" outlined hide-details="" max="500"
                                          :lm-qs-ve-id="lmVe.LM_QS_VE_ID" :sort-ord="lmQs.SORT_ORD">
                            </v-text-field>
                          </div>
                        </template>
                      </template>
                    </template>
                    <!-- 객관식(복식) -->
                    <template v-else-if="lmQs.LM_QS_TY == '30'">
                      <template v-for="lmVe in LM_VE_LIST">
                        <template v-if="lmVe.LM_QS_ID == lmQs.LM_QS_ID">
                          <div :key="lmVe.LM_QS_VE_ID">
                            <v-checkbox :name="'ans_'+lmQs.LM_QS_ID" :on-icon="'svg-chk-on'"
                                        :off-icon="'svg-chk-off'"
                                        :label="lmVe.QS_NUMBER + '. ' + lmVe.LM_QS_VE_RT"
                                        class="mb-3"
                                        :value="lmVe.LM_QS_VE_ID" :sort-ord="lmQs.SORT_ORD">
                            </v-checkbox>
                          </div>
                        </template>
                      </template>
                    </template>
                    <!-- OX형 -->
                    <template v-else-if="lmQs.LM_QS_TY == '40'">
                      <v-radio-group :name="'ans_'+lmQs.LM_QS_ID" :sort-ord="lmQs.SORT_ORD">
                        <template v-for="lmVe in LM_VE_LIST">
                          <template v-if="lmVe.LM_QS_ID == lmQs.LM_QS_ID">
                            <div class="mt-2" :key="lmVe.LM_QS_VE_ID">
                              <v-radio :label="lmVe.LM_QS_VE_RT" :on-icon="'svg-rad-on'"
                                       :off-icon="'svg-rad-off'" :value="lmVe.LM_QS_VE_ID">
                              </v-radio>
                            </div>
                          </template>
                        </template>
                      </v-radio-group>
                    </template>
                  </dd>
                </dl>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/store/apiUtil.js"
import {
    mixin
} from "@/mixin/mixin.js"

export default {
  name: "MENU_M410301P01",
  mixins: [mixin],
  props: {
    grid01Selected: {},
  },
  data: () => ({
    LM_LIM_SS: 0,
    interval: "",
    LM_QS_LIST: [],
    LM_VE_LIST: [],
    LM_QS_ANS: [],
  }),
  created() {
    this.LM_LIM_SS = this.grid01Selected.LM_LIM_TIME * 60;
    this.selectRtnLmEvaPaper();

  },
  watch: {
    LM_LIM_SS() {
      if (this.LM_LIM_SS == 0) {
        clearInterval(this.interval);
        //시험 종료 처리 필요
        this.postAns();
      }
    }
  },
  methods: {
    closeDialog() {
      clearInterval(this.interval);
      this.$emit("hide");
    },

    async selectRtnLmEvaPaper() {
      let requestData = {
          headers: {},
          sendData: {}
      };
      // header 세팅
      requestData.headers["URL"] = "api/phone/lm/execut/eva-paper/inqire";
      requestData.headers["SERVICE"] = "phone.lm.execut.eva-paper";
      requestData.headers["METHOD"] = "inqire";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = false;

      // sendData 세팅
      requestData.sendData = this.grid01Selected;
      requestData.sendData['USER_ID'] = this.$store.getters['userStore/GE_USER_ID'];

      // 결과 리턴
      let response = await this.common_postCall(requestData);

      if (!response.HEADER.ERROR_FLAG) {
        this.LM_QS_LIST = response.lmQsList;
        this.LM_VE_LIST = response.lmVeList;

        //타이머 시작
        this.interval = setInterval(() => {
            this.LM_LIM_SS -= 1;
        }, 1000)
      } else {
        this.common_alert(response.HEADER.ERROR_MSG, "error");
      }
    },

    async postAns() {
      await this.setAnsData();

      let requestData = {
        headers: {},
        sendData: {}
      };
      // header 세팅
      requestData.headers["URL"] = "api/phone/lm/execut/regist";
      requestData.headers["SERVICE"] = "phone.lm.execut";
      requestData.headers["METHOD"] = "regist";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = false;
      requestData.headers["DATA_OBJECT"] = "LM_QS_ANS";

      // sendData 세팅
      requestData.sendData["LM_QS_ANS"] = this.LM_QS_ANS;
      requestData.sendData["ASP_NEWCUST_KEY"] = this.grid01Selected.ASP_NEWCUST_KEY;
      requestData.sendData["LM_DATA_ID"] = this.grid01Selected.LM_DATA_ID;
      requestData.sendData["LM_EVA_ID"] = this.grid01Selected.LM_EVA_ID;
      requestData.sendData["LM_ID"] = this.grid01Selected.LM_ID;
      requestData.sendData['USER_ID'] = this.$store.getters['userStore/GE_USER_ID'];
      // 결과 리턴
      let response = await this.common_postCall(requestData);

      if (!response.HEADER.ERROR_FLAG) {
        //타이머 종료
        clearInterval(this.interval);
        this.common_alert("시험시간이 종료되었습니다.", "done");
        this.$emit("submit");
      } else {
        this.common_alert(response.HEADER.ERROR_MSG, "error");
      }
    },

    async processRtn() {
      await this.setAnsData();

      // for(let i = 0 ; i < this.LM_QS_ANS.length; i++){
      //     if(this.LM_QS_ANS[i].LM_QS_VE_ID == ''){
      //         this.common_alert("답을 입력하지 않은 문제가 존재합니다.", 'noti');
      //         return;
      //     }
      // }

      let requestData = {
          headers: {},
          sendData: {}
      };
      // header 세팅
      requestData.headers["URL"] = "api/phone/lm/execut/regist";
      requestData.headers["SERVICE"] = "phone.lm.execut";
      requestData.headers["METHOD"] = "regist";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = false;
      requestData.headers["DATA_OBJECT"] = "LM_QS_ANS";

      // sendData 세팅
      requestData.sendData["LM_QS_ANS"] = this.LM_QS_ANS;
      requestData.sendData["ASP_NEWCUST_KEY"] = this.grid01Selected.ASP_NEWCUST_KEY;
      requestData.sendData["LM_DATA_ID"] = this.grid01Selected.LM_DATA_ID;
      requestData.sendData["LM_EVA_ID"] = this.grid01Selected.LM_EVA_ID;
      requestData.sendData["LM_ID"] = this.grid01Selected.LM_ID;
      requestData.sendData['USER_ID'] = this.$store.getters['userStore/GE_USER_ID'];
      // 결과 리턴
      let response = await this.common_postCall(requestData);

      if (!response.HEADER.ERROR_FLAG) {
          //타이머 종료
          clearInterval(this.interval);
          this.common_alert("정상처리되었습니다.", "done");
          this.$emit("submit");
      } else {
          this.common_alert(response.HEADER.ERROR_MSG, "error");
      }
    },

    async setAnsData() {
      this.LM_QS_ANS = [];
      this.LM_QS_LIST.map(e => {
        let input_obj = null;
        let data_obj = {};
        let ve_id_list = "";
        let lm_qs_ve_lo_ans = "";

        if (e.LM_QS_TY == '10') {
          input_obj = document.querySelectorAll("[name=ans_" + e.LM_QS_ID + "]:checked")[0];
          if (input_obj != undefined) {
              ve_id_list = input_obj.value;

          }
        } else if (e.LM_QS_TY == '20') {
          input_obj = document.querySelectorAll("[name=ans_" + e.LM_QS_ID + "]")[0];
          ve_id_list = input_obj.getAttribute("lm-qs-ve-id");
          lm_qs_ve_lo_ans = input_obj.value;

        } else if (e.LM_QS_TY == '30') {
          input_obj = document.querySelectorAll("[name=ans_" + e.LM_QS_ID + "]:checked");
          if (input_obj != undefined) {
              for (let i = 0; i < input_obj.length; i++) {
                  if (i != 0) {
                      ve_id_list += "|";
                  }
                  ve_id_list += input_obj[i].value;
              }
          }
        } else if (e.LM_QS_TY == '40') {
          input_obj = document.querySelectorAll("[name=ans_" + e.LM_QS_ID + "]:checked")[0];
          if (input_obj != undefined) {
              ve_id_list = input_obj.value;

          }
        }
        data_obj = {
          LM_QS_TY: e.LM_QS_TY,
          LM_QS_ID: e.LM_QS_ID,
          LM_QS_VE_ID: ve_id_list,
          LM_QS_VE_LO_ANS: lm_qs_ve_lo_ans,
        }
        this.LM_QS_ANS.push(data_obj);
      });
    },
  },
  computed: {
    getEndDate() {
      let min = Math.floor(this.LM_LIM_SS / 60);
      let sec = this.LM_LIM_SS % 60;
      let ret = min;
      if (min == 0) {
          ret += ":" + (sec < 10 ? "0" + sec : sec);
      } else {
          ret += "분";
      }
      return ret;
    },
    getCurrentTime() {
      let currentDate = new Date();
      let hours = currentDate.getHours();
      let minutes = currentDate.getMinutes();
      if (hours < 10) {
          hours = "0" + hours;
      }

      if (minutes < 10) {
          minutes = "0" + minutes;
      }
      return hours + ":" + minutes;
    },
  },
  beforeDestroy() {
      clearInterval(this.interval);
  },
}
</script>
<style scoped>
.qa-report {
    height: auto !important;
}

</style>