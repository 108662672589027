<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">채팅 상담사 현황</h1>
    <!-- 조회 폼 -->
    <div class="box-wrap d-flex">
      <div class="box-ct">
        <div class="form-inp sm">
          <v-select
            :items="aspCustList"
            item-text="CD_NM"
            item-value="CD"
            outlined
            hide-details
            label="회사구분"
            placeholder="선택하세요"
            v-model="selected"
            @change="selectRtnAgentDashboard"
            :readonly="RDOnly"
          >
          <template v-slot:label>
            회사구분
            <v-icon color="#EF9191">mdi-circle-small</v-icon>
          </template>
          </v-select>
        </div>
      </div>
      <div class="ml-auto align-self-center">
        <v-btn
          v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')"
          @click="selectRtnAgentDashboard"
          outlined
          class="btn-etc2 ml-3"
          >조회</v-btn
        >
      </div>
    </div>
    <!-- 상담사 현황 리스트 -->
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">
        <div class="ml-auto">
          최근 업데이트 : {{ recentUpdateTime }}
          <!-- 새로고침 버튼 -->
          <v-btn dark small
            v-if="this.mixin_set_btn(this.$options.name, 'btnRefresh')"
            class="btn-main-refresh pa-0 ml-2" min-width="32px" height="32px"
            @click="selectRtnAgentDashboard"
            plain
            >
            <v-icon small class="svg-main-refresh"></v-icon>
          </v-btn>
        </div>
      </h2>

      <div class="box-ct">
        <!-- 탭  -->
        <div class="tab-box">
          <div :class="{ active: status.ALL }" @click="toggle('ALL')">
            <v-icon small class="svg-tab-all"></v-icon>
              전체
            <v-chip>
              {{ statusType[0].AGENT_ALL_CNT }}
            </v-chip>
          </div>
          <div
            :class="{ active: status.LOGGING_IN }"
            @click="toggle('LOGGING_IN')"
          >
            <v-icon small class="svg-tab-login"></v-icon>
              로그인
            <v-chip>
              {{ statusType[0].AGENT_LOGGING_IN_CNT }}
            </v-chip>
          </div>
          <div :class="{ active: status.READY }" @click="toggle('READY')">
            <v-icon small class="svg-tab-wait"></v-icon>
              대기
            <v-chip>
              {{ statusType[0].AGENT_READY_CNT }}
            </v-chip>
          </div>
          <div
            :class="{ active: status.IN_CONSULTATION }"
            @click="toggle('IN_CONSULTATION')"
          >
            <v-icon small class="svg-tab-consult"></v-icon>
              상담
            <v-chip>
              {{ statusType[0].AGENT_IN_CONSULTATION_CNT }}
            </v-chip>
          </div>
          <div :class="{ active: status.AWAY }" @click="toggle('AWAY')">
            <v-icon small class="svg-tab-move"></v-icon>
                이석
            <v-chip>
              {{ statusType[0].AGENT_AWAY_CNT }}
            </v-chip>
          </div>
          <div :class="{ active: status.LOGOUT }" @click="toggle('LOGOUT')">
            <v-icon small class="svg-tab-logout"></v-icon>
              로그아웃
            <v-chip>
              {{ statusType[0].AGENT_LOGOUT_CNT }}
            </v-chip>
          </div>
        </div>
        <!--// 탭  -->
        <!-- 그리드 -->
        <v-data-table
          dense
          :headers="gridDataHeaders"
          :items="gridDataText"
          :items-per-page="30"
          height="427px"
          item-key="index"
          :page.sync="page"
          hide-default-header
          hide-default-footer
          class="grid-default mt-6"
          @page-count="pageCount = $event"
          @click:row="showRowInfo"
        >
          <template v-slot:header>
            <thead class="v-data-table-header">
              <tr>
                <th scope="row" rowspan="2" class="text-center">NO</th>
                <th scope="row" rowspan="2" class="text-center">사용자ID</th>
                <th scope="row" rowspan="2" class="text-center">사용자명</th>
                <th scope="row" rowspan="2" class="text-center">사용자닉네임</th>
                <th scope="row" rowspan="2" class="text-center">권한그룹명</th>
                <th scope="row" rowspan="2" class="text-center">상태</th>
                <th scope="row" rowspan="2" class="text-center">상태시작시간</th>
                <th scope="row" rowspan="2" class="text-center">상담허용(건)</th>
                <th scope="row" rowspan="2" class="text-center">상담가능(건)</th>
                <th scope="row" rowspan="2" class="text-center">상담중(건)</th>
                <th scope="row" rowspan="2" class="text-center">후처리(건)</th>
                <th scope="row" rowspan="2" class="text-center">상담완료(누적/건)</th>
                <th scope="row" colspan="3" class="text-center">평균</th>
              </tr>
              <tr>
                <th scope="row" class="text-center">채팅시간</th>
                <th scope="row" class="text-center">이석시간</th>
                <th scope="row" class="text-center">후처리시간</th>
              </tr>
            </thead>
          </template>
        </v-data-table>
        <div class="grid-paging text-center pt-2">
          <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="totalVisible"
            next-icon="svg-paging-next"
            prev-icon="svg-paging-prev"
          ></v-pagination>
        </div>
        <!--// 그리드 -->
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../store/apiUtil.js";
import { mixin } from "@/mixin/mixin.js";
import { mapGetters } from "vuex";
let jsonObj;

export default {
  name: "MENU_M010202", //name은 'MENU_' + 파일명 조합
  mixins: [mixin],
  data: () => ({
    selected: "",
    status: {
      ALL: true,
      LOGGING_IN: false,
      READY: false,
      IN_CONSULTATION: false,
      AWAY: false,
      LOGOUT: false,
    },
    recentUpdateTime: "",
    statusType: [
      {
        AGENT_ALL_CNT: 0,
        AGENT_LOGGING_IN_CNT: 0,
        AGENT_READY_CNT: 0,
        AGENT_IN_CONSULTATION_CNT: 0,
        AGENT_AWAY_CNT: 0,
        AGENT_LOGOUT_CNT: 0,
      },
    ],
    dates: [
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    ],
    gridDataHeaders: [
      { text: "", value: "index", align: "center", sortable: false },
      { text: "", value: "USER_ID", align: "center", sortable: false },
      { text: "", value: "USER_NM", align: "center", sortable: false },
      { text: "", value: "USER_NICK", align: "center", sortable: false },
      { text: "", value: "ATRT_GROUP_NM", align: "center", sortable: false },
      { text: "", value: "LAST_USER_STATUS_CD", align: "center", sortable: false },
      { text: "", value: "STATUS_START_TIME", align: "center", sortable: false },
      { text: "", value: "NUM_OF_CONSUL_ALLOWED", align: "center", sortable: false },
      { text: "", value: "NUM_OF_CONSUL_AVAILABLE", align: "center", sortable: false },
      { text: "", value: "NUM_OF_CONSUL", align: "center", sortable: false },
      { text: "", value: "AFTER_COUNSEL_CNT", align: "center", sortable: false },
      { text: "", value: "CUMULATIVE_CNSL_CMPL", align: "center", sortable: false },
      { text: "", value: "CUMULATIVE_CHAT_AVG_TIME", align: "center", sortable: false },
      { text: "", value: "CUMULATIVE_AWAY_AVG_TIME", align: "center", sortable: false },
      { text: "", value: "CUMULATIVE_AFTER_AVG_TIME", align: "center", sortable: false },
    ],
    gridDataText: [],
    gridRowDetail: "",
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    totalVisible: 10,

    RDOnly:false,
  }),
  methods: {
    selectValidation(){
      if (this.selected == "" || this.selected == undefined) {
        this.common_alert("회사를 먼저 선택해주세요.","error");
        return false;
      }
      return true;
    },
    startDate(e) {
      this.dates[0] = e;
    },
    endDate(e) {
      this.dates[1] = e;
    },
    showRowInfo(event, { item }) {
      this.editedIndex = this.gridDataText.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.gridRowDetail = this.editedItem;
    },
    //대시보드 탭의 카운트 수를 조회한다.
    async selectRtnAgentDashboard() {
      if(!this.selectValidation()) return;

      //현재 활성화된 탭
      let currentSelected = '';
      for(let key in this.status) {
        if(this.status[key]) currentSelected = key;
      }
      let URLData = "/api/chat/status/agent/dashboard/inqire";
      let HEADER_SERVICE = "chat.status.agent.dashboard";
      let HEADER_METHOD = "inqire";
      let HEADER_TYPE = "BIZ_SERVICE";
      let HEADER_ROW_CNT = 50;
      let HEADER_PAGES_CNT = 1;

      let jsonData = {
        ASP_NEWCUST_KEY: this.selected,
      };

      //api 호출 부분
      await api
        .post(URLData, jsonData, {
          head: {
            SERVICE: HEADER_SERVICE,
            METHOD: HEADER_METHOD,
            TYPE: HEADER_TYPE,
            ROW_CNT: HEADER_ROW_CNT,
            PAGES_CNT: HEADER_PAGES_CNT,
            ASYNC: "false",
          },
        })
        .then((response) => {
          //status count 세팅
          jsonObj = JSON.parse(JSON.stringify(response.data.DATA));
          this.statusType = jsonObj;

          //최근 업데이트 날짜 갱신
          this.recentUpdateTime = new Date().toLocaleString();

          //해당 탭 활성화 (여기서 selectRtnAgentMonitoringStatus() 호출 )
          this.toggle(currentSelected);
        })
        .catch((err) => {
          this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.", "error");
          console.log(err);
        });
    },
    toggle(value) { //대시보드 탭 클릭시 그리드 show/hide
      //validation
      if(!this.selectValidation()) return;

      //모든 값을 false로 바꾸고
      for (let key in this.status) {
        this.status[key] = false;
      }
      //value에 해당하는 값만 true로 바꾼다.
      this.status[value] = true;
      //value에 해당하는 조회 요청을 서버로 보낸다.
      this.selectRtnAgentMonitoringStatus(value);
    },
    //각 탭의 상세 내용을 조회해 그리드에 로드한다.
    async selectRtnAgentMonitoringStatus(dashboardType) {
      let URLData = "api/chat/status/agent/sttus/inqire";
      let HEADER_SERVICE = "chat.status.agent.sttus";
      let HEADER_METHOD = "inqire";
      let HEADER_TYPE = "BIZ_SERVICE";
      let HEADER_ROW_CNT = 50;
      let HEADER_PAGES_CNT = 1;
      let jsonData = {
        ASP_CUST_KEY: this.selected,
        ASP_NEWCUST_KEY: this.selected,
        DASHBOARD_TYPE: dashboardType == undefined || dashboardType == "" ? "ALL" : dashboardType,
      };
      //api 호출 부분
      await api
        .post(URLData, jsonData, {
          head: {
            SERVICE: HEADER_SERVICE,
            METHOD: HEADER_METHOD,
            TYPE: HEADER_TYPE,
            ROW_CNT: HEADER_ROW_CNT,
            PAGES_CNT: HEADER_PAGES_CNT,
            ASYNC: "false",
          },
        })
        .then((response) => {
          const jsonReturn = JSON.parse(JSON.stringify(response.data.DATA));
          for (let i in jsonReturn) {
            jsonReturn[i].index = Number(i) + 1;
          }
          this.gridDataText = jsonReturn;
        })
        .catch((err) => {
          this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.", "error");
          console.log(err);
        });
    },

  },

  mounted(){

    let chk = this.mixin_check_AspCustKey_number();
    if(chk == 'Y'){
      this.selected = this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD;
      this.RDOnly = true;
    } else {
      this.RDOnly = false;
    }
  },
  
  computed: {
    dateRangeText() {
      var newStartDate = this.dates[0];
      var newEndDate = this.dates[1];
      return newStartDate + " ~ " + newEndDate;
    },
    ...mapGetters({
      aspCustList: "userStore/GE_USER_COMPANY",
    }),
  },
};
</script>

<style></style>
