<template>
  <div class="layout-left-area"></div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
};
</script>

<style></style>
