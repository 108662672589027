<template>
  <div class="modal-wrap">
    <h1 class="tit-h1 d-flex">
      사용자조회
      <div class="ml-auto align-self-center">
        <v-icon x-small class="svg-close20" @click="[$emit('hide'), init(), initCust()]"></v-icon>
      </div>
    </h1>
    <div class="modal-box">
      <div class="box-ct d-flex gray-box">
        <div class>
          <v-text-field
            class="form-inp sm5 ml-2"
            label="ID"
            outlined
            hide-details
            v-model="USER_NEWID"
            @keyup.enter="selectUser(1)"
          >
            <template v-slot:label>ID</template>
          </v-text-field>
          <v-text-field
            class="form-inp sm5 ml-2"
            label="이름"
            outlined
            hide-details
            v-model="USER_NEWNM"
            @keyup.enter="selectUser(1)"
          >
            <template v-slot:label>이름</template>
          </v-text-field>
          <v-text-field
            class="form-inp sm5 ml-2"
            label="닉네임"
            outlined
            hide-details
            v-model="USER_NEWNICK"
            @keyup.enter="selectUser(1)"
          >
            <template v-slot:label>닉네임</template>
          </v-text-field>
        </div>
        <div class="ml-auto align-self-center">
          <v-btn outlined class="btn-etc2 ml-3" v-on:click="selectUser(1)">조회</v-btn>
        </div>
      </div>
      <div class="box-ct mt-3">
        <v-data-table
          dense
          height="521px"
          :headers="gridDataHeaders"
          :items="gridDataText"
          :items-per-page="itemsPerPage"
          item-key="RNUM"
          hide-default-footer
          class="grid-default"
          calculate-widths
          single-select
          @click:row="rowClick1"
          @dblclick:row="rowClick2"
        ></v-data-table>
        <div class="grid-paging text-center pt-2">
          <span class="grid-total-count">총 <strong>{{ totCount }}</strong>건</span>
          <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="totalVisible"
            @input="selectUser(page, false)"
          ></v-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "MENU_MP02", //name은 'MENU_' + 파일명 조합
    
    props: {
      userInfo: {
        type: Object,
        value: {},
      },
    },

    computed: {
    },

    components: {
    },

    data() {
      return {
        gridDataHeaders: [
          {
            text: 'No',
            align: 'center',
            value: 'RNUM',
            width: '7%',
            sortable: true,
          },
          { text: "ID", value: 'USER_ID', align: 'center', sortable: true, width: '20%',},
          { text: "이름", value: 'USER_NM', align: 'center', sortable: true, width: '30%',},
          { text: "닉네임", value: 'USER_NICK', align: 'center', sortable: true, width: '*%',},
        ],
        gridDataText: [],
        page: 1,
        pageCount: 0,
        totCount: 0,
        totalVisible: 10,
        itemsPerPage: 20,

        USER_ATTR_A: [
          {
            CD: '',
            CD_NM: '전체'
          },
        ],

        USER_ATTR_B: [
          {
            CD: '',
            CD_NM: '전체'
          },
        ],

        USER_ATTR_C: [
          {
            CD: '',
            CD_NM: '전체'
          },
        ],

        USER_ATTR_D: [
          {
            CD: '',
            CD_NM: '전체'
          },
        ],

        USER_NEWID: '',
        USER_NEWNM: '',
        USER_NEWNICK: '',

        USER_NEWATTR_A: '',
        USER_NEWATTR_B: '',
        USER_NEWATTR_C: '',
        USER_NEWATTR_D: '',

        READ_YN: '',
      };
    },

    methods: {
      init() {
        this.USER_NEWID = '';
        this.USER_NEWNM = '';
        this.USER_NEWNICK = '';

        this.USER_NEWATTR_A = '';
        this.USER_NEWATTR_B = '';
        this.USER_NEWATTR_C = '';
        this.USER_NEWATTR_D = '';
      },

      initCust(){
        this.userInfo.NO_CUST_KEY = 'true';
      },

      //사용자 소속 콤보박스 생성
      async getAttrDiv(attr) {

        let requestData = {
          headers: {
            "SERVICE": "palette.common.get-attr-div",
            "METHOD": "list",
            "TYPE": "BIZ_SERVICE",
          },
          sendData:{
            ATTR_CD: attr,
            ATTR_DIV_CD: '',
          },
        };

        //header 세팅
        requestData.headers["URL"] = "api/palette/common/get-attr-div/list";

        let response = await this.common_postCall(requestData, false);

        if (response.HEADER.ERROR_FLAG) {
          this.common_alert("관리자에게 문의해주세요.", "error");
          return;
        }else{
          if (attr == 'A'){
            this.USER_ATTR_A = [...this.USER_ATTR_A, ...response.data.DATA];
          } else if (attr == "B") {
            this.USER_ATTR_B = [...this.USER_ATTR_B, ...response.data.DATA];
          } else if (attr == "C") {
            this.USER_ATTR_C = [...this.USER_ATTR_C, ...response.data.DATA];
          } else if (attr == "D") {
            this.USER_ATTR_D = [...this.USER_ATTR_D, ...response.data.DATA];
          }
        }
      },

      async selectUser(pg, pgBool) {

        let bool = true;
        if(!this.mixin_isEmpty(pgBool)) {
          bool = pgBool;
        }

        this.page = pg;
        this.gridDataText = [];

        let plCnt = 0;
        if(this.page > 1) {
          plCnt = 1;
        }

        let strRow = ((this.page - 1) * (this.itemsPerPage)) + plCnt;
        let endRow = this.page * this.itemsPerPage;

        let requestData = {
          headers: {
            "SERVICE": "setting.agent.manage.user-info-inqire-popup",
            "METHOD": "inqire",
            "TYPE": "BIZ_SERVICE",
          },
          sendData:{
            USER_ID: this.USER_NEWID,
            USER_NM: this.USER_NEWNM,
            USER_NICK: this.USER_NEWNICK,
            USER_ATTR_A: this.USER_NEWATTR_A,
            USER_ATTR_B: this.USER_NEWATTR_B,
            USER_ATTR_C: this.USER_NEWATTR_C,
            USER_ATTR_D: this.USER_NEWATTR_D,
          },
        };

        //header 세팅
        requestData.headers["URL"] = "api/setting/agent/manage/user-info-inqire-popup/inqire";

        requestData.sendData["STR_ROW"] = strRow;
        requestData.sendData["END_ROW"] = endRow;

        let response = await this.common_postCall(requestData, bool);

        if (response.HEADER.ERROR_FLAG) {
          this.common_alert("관리자에게 문의해주세요.", "error");
          return;
        }else{

          let data = response.DATA;
          if(!this.mixin_isEmpty(data)) {
            this.totCount = Math.floor(Number(data[0].TWB_PAGING_TOT_COUNT));
            this.pageCount = Math.ceil(this.totCount / this.itemsPerPage);

            data.forEach((ele, i) => {
              ele.RNUM = Math.floor(Number(ele.RNUM));
            });
    
            this.gridDataText = data;
          }else{
            this.pageCount = 0;
            this.totCount = 0;
          }
        }
      },

      //Table row 클릭이벤트
      rowClick2(e, { item }) {
        this.userInfo.USER_ID = item.USER_ID;
        this.userInfo.USER_NM = item.USER_NM;
        this.userInfo.USER_NICK = item.USER_NICK;

        this.$emit("selectUser", this.userInfo);
        this.init();
        this.initCust()
        this.selectUser();
      },

      //Table row 클릭이벤트
      rowClick1(item, row) {
        if (item != null) {
          this.activeRow(row, true);
        }
      },

      activeRow(row, value) {
        console.log(row);
        this.selectedRow = row;
        row.select(Boolean(value));
        row.isSelected = Boolean(value);
      },

    },

    mounted() {
      this.USER_NEWID = this.userInfo.USER_ID;
      this.USER_NEWNM = this.userInfo.USER_NM;
      this.USER_NEWNICK = this.userInfo.USER_NICK;
      this.selectUser(1);
    },

    computed: {},

    watch: {
      userInfo() {
        this.init();

        this.USER_NEWID = this.userInfo.USER_ID;
        this.USER_NEWNM = this.userInfo.USER_NM;
        this.USER_NEWNICK = this.userInfo.USER_NICK;
        this.selectUser(1);
      }
    }
  };
</script>

<style></style>
