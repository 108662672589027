<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">평가관리</h1>
    <div class="box-wrap">
      <div class="box-ct d-flex">
        <div class="">
          <div class="form-inp sm">
            <v-select :items="$store.getters['userStore/GE_USER_COMPANY']" item-text="CD_NM" item-value="CD" outlined
              hide-details label="회사구분" placeholder="선택하세요" v-model="evlPaperSearch.ASP_NEWCUST_KEY"
              @change="companyChange"
              :readonly="RDOnly">
              <template v-slot:label>
                회사구분
                <v-icon color="#EF9191">mdi-circle-small</v-icon>
              </template>
            </v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select :items="mixin_common_code_get(allCodeList,'PLT008','전체')" item-text="text" item-value="value"
              outlined hide-details label="유형타입" placeholder="선택하세요" v-model="evlPaperSearch.QA_EVA_TY"></v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select :items="mixin_common_code_get(allCodeList,'PLT012','전체')" item-text="text" item-value="value"
              outlined hide-details label="사용여부" placeholder="선택하세요" v-model="evlPaperSearch.QA_EVA_YN"></v-select>
          </div>

          <v-text-field class="form-inp lg ml-2" name="name" label="평가명" outlined hide-details
            v-model="evlPaperSearch.QA_EVA_NM" @keyup.enter="selectRtnQaEva();">
          </v-text-field>
        </div>
        <div class="ml-auto align-self-center">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch1')" outlined class="btn-etc2 ml-3" @click="selectRtnQaEva();">조회</v-btn>
        </div>
      </div>
    </div>
    <div class="d-flex justify-space-between">
      <!-- 평가표리스트 -->
      <div class="box-wrap flex-grow-1 col-6">
        <h2 class="tit-h2 d-flex">평가표리스트
          <div class="ml-auto">
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnNew1')" outlined class="btn-default" @click="insertRtnEvlPaperForm()">신규</v-btn>
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnCopy1')" outlined class="btn-point ml-2"
              @click="common_confirm('복사하시겠습니까?',insertRtnCopyEvlPaper,'',null,'','chk')">복사</v-btn>
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnMody1')" outlined class="btn-point ml-2" @click="modifyEvlPaperForm()">수정</v-btn>
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDelete1')" outlined class="btn-etc ml-2" @click="common_confirm('삭제하시겠습니까?',deleteEvlPaper,'',null,'','error')">
              삭제</v-btn>
          </div>
        </h2>
        <v-dialog max-width="655" persistent v-model="dialogM310101P01" v-if="dialogM310101P01"
          content-class="square-modal min-auto">
          <dialog-M310101P01 headerTitle="평가표 리스트" p01 :isModify="isModify"
            :PLT008="mixin_common_code_get(allCodeList,'PLT008','선택')"
            :PLT012="mixin_common_code_get(allCodeList,'PLT012','선택')" @hide="hideDialog('M310101P01')"
            @submit="saveEvlPaper" :aspNewCustKey="evlPaperSearch.ASP_NEWCUST_KEY"
            :propsEvlPaper="isModify? checkedEvlPaper[0] : {}">
          </dialog-M310101P01>
        </v-dialog>
        <div class="box-ct">
          <v-data-table fixed-header dense :headers="evlPaperHeader" :items="evlPaperData" item-key="QA_EVA_ID" hide-default-footer
            class="grid-default" show-select height="200" no-data-text="검색된 결과가 없습니다." @click:row="setEvlPaper"
            v-model="checkedEvlPaper" :page.sync="evlPaperPaging.page" :items-per-page="30"
            @page-count="evlPaperPaging.pageCount = $event" :item-class="isActiveRow">
            <template v-slot:item.QA_EVA_CN_TWO="{item}">
              {{item.QA_EVA_CN >= '2' ? "Y":"N"}}
            </template>
            <template v-slot:item.QA_EVA_CN_THREE="{item}">
              {{item.QA_EVA_CN >= '3' ? "Y":"N"}}
            </template>
            <template v-slot:item.QA_EVA_YN="{item}">
              {{item.USE_COUNT > 0 ? "사용" : "미사용"}}
            </template>
          </v-data-table>
          <div class="grid-paging text-center pt-2">
            <v-pagination v-model="evlPaperPaging.page" :length="evlPaperPaging.pageCount"
              :total-visible="evlPaperPaging.totalVisible" next-icon="svg-paging-next" prev-icon="svg-paging-prev">
            </v-pagination>
          </div>
        </div>
      </div>
      <!-- 평가문항 대분류 -->
      <div class="box-wrap flex-grow-1 ml-4">
        <h2 class="tit-h2 d-flex">평가문항 대분류
          <div class="ml-auto">
            <div class="form-inp sm">
              <v-select :items="mixin_common_code_get(allCodeList,'PLT009','전체')" item-text="text" item-value="value"
                outlined hide-details label="대분류" placeholder="선택하세요" v-model="qaQsSearch.QA_TY_L_CD"
                @change="selectRtnQaQs"></v-select>
            </div>
            <div class="form-inp sm ml-2">
              <v-select :items="mixin_common_code_get(allCodeList,'PLT010','전체')" item-text="text" item-value="value"
                outlined hide-details label="소분류" placeholder="선택하세요" v-model="qaQsSearch.QA_TY_M_CD"
                @change="selectRtnQaQs"></v-select>
            </div>
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnNew2')" outlined class="btn-default ml-2" @click="insertRtnQaQsForm()">신규</v-btn>
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnCopy2')" outlined class="btn-point ml-2"
              @click="common_confirm('복사하시겠습니까?',insertRtnCopyQaQs,'',null,'','chk')">복사</v-btn>
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnMody2')" outlined class="btn-point ml-2" @click="modifyQaQsDialog()">수정</v-btn>
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDelete2')" outlined class="btn-etc ml-2" @click="common_confirm('삭제하시겠습니까?',deleteRtnQaQs,'',null,'','error')">삭제
            </v-btn>
          </div>
        </h2>
        <v-dialog max-width="1400" persistent v-model="dialogM310101P02" v-if="dialogM310101P02"
          content-class="square-modal modal-white">
          <dialog-M310101P01 headerTitle="평가문항" p02 :aspNewCustKey="evlPaperSearch.ASP_NEWCUST_KEY" :isModify="isModify"
            :PLT009="mixin_common_code_get(allCodeList,'PLT009','선택')"
            :PLT010="mixin_common_code_get(allCodeList,'PLT010','선택')"
            :PLT011="mixin_common_code_get(allCodeList,'PLT011','선택')"
            :PLT012="mixin_common_code_get(allCodeList,'PLT012','선택')" @hide="hideDialog('M310101P02')"
            @submit="saveQaQs" @init="initDialog()" :propsQaQs="isModify ?checkedQaQs[0] : {}">
          </dialog-M310101P01>
        </v-dialog>
        <div class="box-ct">
          <v-data-table fixed-header dense :headers="qaQsHeader" :items="qaQsData" item-key="QA_QS_ID" hide-default-footer
            class="grid-default" show-select height="200" no-data-text="검색된 결과가 없습니다." v-model="checkedQaQs"
            :page.sync="qaQsPaging.page" :items-per-page="30"
            @page-count="qaQsPaging.pageCount = $event" @click:row="setQaQs" :item-class="isActiveRow2">
            <template v-slot:item.QA_QS_YN="{item}">
              {{item.USE_COUNT > 0 ? '사용': "미사용"}}
            </template>
          </v-data-table>
          <div class="grid-paging text-center pt-2">
            <v-pagination v-model="qaQsPaging.page" :length="qaQsPaging.pageCount"
              :total-visible="qaQsPaging.totalVisible" next-icon="svg-paging-next" prev-icon="svg-paging-prev">
            </v-pagination>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-space-between">
      <!-- 평가 상세관리 -->
      <div class="box-wrap flex-grow-1 col-6">
        <h2 class="tit-h2 d-flex">평가 상세관리
          <p class="caption">({{selectedEvlPaper.QA_EVA_NM}})</p>
          <div class="ml-auto">
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnNew3')" outlined class="btn-default" @click="insertRtnEvlRstForm()">신규</v-btn>
            <!-- <v-btn outlined small class="btn-default ml-2">복사</v-btn> -->
            <!-- <v-btn outlined small class="btn-point ml-2">수정</v-btn> -->
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDelete3')" outlined class="btn-etc ml-2" @click="common_confirm('삭제하시겠습니까?',deleteRtnEvlRst,'',null,'','error')">
              삭제</v-btn>
          </div>
        </h2>
        <v-dialog max-width="655" persistent v-model="dialogM310101P03" v-if="dialogM310101P03"
          content-class="square-modal min-auto">
          <dialog-M310101P01 headerTitle="평가 상세관리" p03 :propsEvlRst="evlRstData" :propsEvlPaper="selectedEvlPaper"
            :PLT008="mixin_common_code_get(allCodeList,'PLT008','선택')"
            :PLT009="mixin_common_code_get(allCodeList,'PLT009','선택')"
            :PLT010="mixin_common_code_get(allCodeList,'PLT010','선택')"
            :PLT011="mixin_common_code_get(allCodeList,'PLT011','선택')"
            :PLT012="mixin_common_code_get(allCodeList,'PLT012','선택')" @hide="hideDialog('M310101P03')"
            @submit="insertRtnQaEvaRst" @addrow="addrowDialog('M310101P05')" @delrow="delEvlRst">
            <!-- deleteRtnEvlRst -->
          </dialog-M310101P01>
        </v-dialog>
        <div class="box-ct">
          <v-data-table fixed-header dense :headers="evlRstHeader" :items="evlRstData" item-key="QA_QS_ID" hide-default-footer
            class="grid-default" show-select height="200" no-data-text="검색된 결과가 없습니다." :page.sync="evlRstPaging.page"
            :items-per-page="30" @page-count="evlRstPaging.pageCount = $event"
            v-model="checkedEvlRst"  @click:row="setEvlRst" :item-class="isActiveRow3">
            <template v-slot:item.USE_YN>
              사용
            </template>
          </v-data-table>
          <div class="grid-paging text-center pt-2">
            <v-pagination v-model="evlRstPaging.page" :length="evlRstPaging.pageCount"
              :total-visible="evlRstPaging.totalVisible" next-icon="svg-paging-next" prev-icon="svg-paging-prev">
            </v-pagination>
          </div>
        </div>
      </div>
      <!-- 전체보기 -->
      <div class="box-wrap flex-grow-1 ml-4">
        <h2 class="tit-h2 d-flex">전체보기
          <div class="ml-auto">
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnAll')" outlined class="btn-default ml-2" @click="showDialog('M310101P04')">전체보기</v-btn>
          </div>
        </h2>
        <v-dialog max-width="1400" persistent v-model="dialogM310101P04"
          content-class="square-modal modal-white">
          <dialog-M310101P01 headerTitle="전체보기" p04 :preEvlRstData="evlRstData" :preViewList="preViewList"
            @hide="hideDialog('M310101P04')">
          </dialog-M310101P01>
        </v-dialog>
        <div class="box-ct">
          <div class="qa-report">
            <template v-for="(item, index) in evlRstData">
              <dl :key="index">
                <dt>{{index + 1}}. {{item.QA_QS_NM}}({{item.QA_QS_TY_SC}}점)</dt>
                <dd class="mt-3">
                  <template v-for="(ve, index2) in preViewList">
                    <div v-if="item.QA_QS_ID == ve.QA_QS_ID" :key="index2">
                      <!-- 서술형 -->
                      <template v-if="ve.QA_QS_TY === '20'">
                        <div>
                          <v-text-field class="form-inp" name="name" placeholder="직접입력" id="id" outlined hide-details
                            disabled>
                          </v-text-field>
                        </div>
                        <div>
                          <v-text-field class="form-inp sm " type="number" :value="ve.QA_QS_VE_SC" disabled>
                          </v-text-field>
                        </div>
                      </template>
                      <!-- 객관식, 체크박스 -->
                      <template v-else>
                        {{ve.QA_QS_VE_RT}}
                        <span class="value">{{ve.QA_QS_VE_SC}}</span>
                      </template>
                    </div>
                  </template>
                </dd>
              </dl>
            </template>
          </div>
        </div>
      </div>
    </div>
    <!-- 항목 추가 모달 dialogM310101P05 -->
    <v-dialog max-width="1400" persistent v-model="dialogM310101P05" v-if="dialogM310101P05"
      content-class="square-modal modal-white">
      <div class="modal-wrap">
        <h1 class="tit-h1 d-flex">문항조회
          <div class="ml-auto align-self-center">
            <v-icon x-small class="svg-close20" @click="dialogM310101P05=!dialogM310101P05"></v-icon>
          </div>
        </h1>
        <div class="modal-box">
          <div>
            <div class="d-flex gray-box">
              <div>
                <div class="form-inp sm">
                  <v-select :items="mixin_common_code_get(allCodeList,'PLT011','전체')" item-text="text"
                    item-value="value" outlined hide-details label="문항유형" placeholder="선택하세요"
                    v-model="qaQsDialogSearch.QA_QS_TY"></v-select>
                </div>
                <div class="form-inp sm ml-2">
                  <v-select :items="mixin_common_code_get(allCodeList,'PLT012','전체')" item-text="text"
                    item-value="value" outlined hide-details label="사용여부" placeholder="선택하세요"
                    v-model="qaQsDialogSearch.QA_QS_YN"></v-select>
                </div>
                <div class="form-inp sm ml-2">
                  <v-select :items="mixin_common_code_get(allCodeList,'PLT009','전체')" item-text="text"
                    item-value="value" outlined hide-details label="대분류" placeholder="선택하세요"
                    v-model="qaQsDialogSearch.QA_TY_L_CD"></v-select>
                </div>
                <div class="form-inp sm ml-2">
                  <v-select :items="mixin_common_code_get(allCodeList,'PLT010','전체')" item-text="text"
                    item-value="value" outlined hide-details label="소분류" placeholder="선택하세요"
                    v-model="qaQsDialogSearch.QA_TY_M_CD"></v-select>
                </div>
                <v-text-field class="form-inp sm ml-2" name="name" label="항목" outlined hide-details
                  v-model="qaQsDialogSearch.QA_QS_NM" @keyup.enter="selectRtnQaQsDialog">
                </v-text-field>
              </div>
              <div class="ml-auto align-self-center">
                <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch2')" outlined class="btn-etc2 ml-3" @click="selectRtnQaQsDialog">조회</v-btn>
              </div>
            </div>
            <div class="table-form mt-2">
              <v-data-table fixed-header dense :headers="qaQsDialogHeader" :items="qaQsDialogData" item-key="QA_QS_ID"
                hide-default-footer class="grid-default" show-select height="200" no-data-text="검색된 결과가 없습니다."
                v-model="checkedQaQsDialog" :page.sync="qaQsDialogPaging.page"
                :items-per-page="30" @page-count="qaQsDialogPaging.pageCount = $event">
              </v-data-table>
              <div class="grid-paging text-center pt-2">
                <v-pagination v-model="qaQsDialogPaging.page" :length="qaQsDialogPaging.pageCount"
                  :total-visible="qaQsDialogPaging.totalVisible" next-icon="svg-paging-next"
                  prev-icon="svg-paging-prev"></v-pagination>
              </div>
            </div>
            <!-- 이동버튼 -->
            <div class="d-flex justify-center pt-2">
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDelArrow')" class="btn-arrow" plain small @click="delEvlRst(checkedEvlRstDialog,evlRstDialogData)">
                <v-icon small class="svg-arrow-top"></v-icon>
              </v-btn>
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnAddArrow')" class="btn-arrow ml-2" plain small @click="addEvlRst()">
                <v-icon small class="svg-arrow-down"></v-icon>
              </v-btn>
            </div>
            <div class="table-form mt-2">
              <v-data-table fixed-header dense :headers="evlRstDialogHeader" :items="evlRstDialogData" item-key="QA_QS_ID"
                hide-default-footer class="grid-default" show-select height="200" no-data-text="검색된 결과가 없습니다."
                :page.sync="evlRstDialogPaging.page" :items-per-page="30"
                @page-count="evlRstDialogPaging.pageCount = $event" v-model="checkedEvlRstDialog">
                <template v-slot:item.QA_EVA_RST_OD="{item}">
                  <v-text-field class="form-inp sm ml-2" type="number" name="QA_EVA_RST_OD" label="" outlined
                    hide-details v-model.number="item.QA_EVA_RST_OD">
                  </v-text-field>
                </template>
              </v-data-table>
              <div class="grid-paging text-center pt-2">
                <v-pagination v-model="evlRstDialogPaging.page" :length="evlRstDialogPaging.pageCount"
                  :total-visible="evlRstDialogPaging.totalVisible" next-icon="svg-paging-next"
                  prev-icon="svg-paging-prev"></v-pagination>
              </div>
            </div>
          </div>
          <div class="text-right mt-3">
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnPreview')" outlined class="btn-default ml-2" @click="preView()">미리보기</v-btn>
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSave')" outlined class="btn-point ml-2"
              @click="common_confirm('저장하시겠습니까?',insertRtnQaEvaRst, evlRstDialogData, null,'','chk')">저장</v-btn>
          </div>
        </div>
      </div>
    </v-dialog>
    <v-dialog max-width="1400" max-height="600" persistent v-model="dialogM310101P06"
    content-class="square-modal modal-white"
    >
      <dialog-M310101P01 headerTitle="전체보기" p04 :preEvlRstData="evlRstDialogData" :preViewList="preVeDialog"
        @hide="hideDialog('M310101P06')">
      </dialog-M310101P01>
    </v-dialog>
  </div>


</template>

<script>
  import DialogM310101P01 from './M310101P01.vue'
  import api from '@/store/apiUtil.js'
  import { mixin } from '@/mixin/mixin.js'
  export default {
    name: "MENU_M310101", //name은 'MENU_' + 파일명 조합
    components: {
      DialogM310101P01,
    },
    mixins: [mixin],
    data() {
      return {
        dialogM310101P01: false,
        dialogM310101P02: false,
        dialogM310101P03: false,
        dialogM310101P04: false,
        dialogM310101P05: false,
        dialogM310101P06: false,

        evlPaperSearch: {
          ASP_NEWCUST_KEY: "",
          QA_EVA_TY: "",
          QA_EVA_YN: "",
          QA_EVA_NM: ""
        },
        qaQsSearch: {
          QA_TY_L_CD: "",
          QA_TY_M_CD: "",
        },
        qaQsDialogSearch: {
          QA_TY_L_CD: "",
          QA_TY_M_CD: "",
          QA_QS_NM: "",
          QA_QS_TY: "",
        },
        evlPaperHeader: [{
            text: 'No',
            align: 'center',
            value: 'QA_EVA_ID',
            width: '50px',
            sortable: false,
          },
          {
            text: '유형타입',
            value: 'QA_EVA_TY_NM',
            align: 'center',
            sortable: true,
          },
          {
            text: '평가표 명',
            value: 'QA_EVA_NM',
            align: 'left',
            sortable: true,
          },
          {
            text: '설명',
            value: 'QA_EVA_RT',
            align: 'left',
            sortable: true,
          },
          {
            text: '2차',
            value: 'QA_EVA_CN_TWO',
            align: 'center',
            sortable: true,
          },
          {
            text: '3차',
            value: 'QA_EVA_CN_THREE',
            align: 'center',
            sortable: true,
          },
          {
            text: '총점',
            value: 'QA_EVA_SUM',
            align: 'center',
            sortable: true,
          },
          {
            text: '사용여부',
            value: 'QA_EVA_YN',
            align: 'center',
            sortable: true,
          },

        ],
        evlPaperData: [],
        evlRstHeader: [{
            text: 'No',
            align: 'center',
            value: 'QA_QS_ID',
            width: '50px',
            sortable: false,
          },
          {
            text: '대분류',
            value: 'QA_TY_L_CD_NM',
            align: 'center',
            sortable: true,
          },
          {
            text: '소분류',
            value: 'QA_TY_M_CD_NM',
            align: 'center',
            sortable: true,
          },
          {
            text: '문항유형',
            value: 'QA_QS_TY_NM',
            align: 'center',
            sortable: true,
          },
          {
            text: '배점',
            value: 'QA_QS_TY_SC',
            align: 'center',
            sortable: true,
          },
          {
            text: '사용여부',
            value: 'USE_YN',
            align: 'center',
            sortable: true
          },
        ],
        evlRstData: [],
        qaQsHeader: [{
            text: 'No',
            align: 'center',
            value: 'QA_QS_ID',
            width: '50px',
            sortable: false,
          },
          {
            text: '대분류',
            value: 'QA_TY_L_CD_NM',
            align: 'center',
            sortable: true,
          },
          {
            text: '소분류',
            value: 'QA_TY_M_CD_NM',
            align: 'center',
            sortable: true,
          },
          {
            text: '항목',
            value: 'QA_QS_NM',
            align: 'left',
            sortable: true,
          },
          {
            text: '문항유형',
            value: 'QA_QS_TY_NM',
            align: 'center',
            sortable: true,
          },
          {
            text: '배점',
            value: 'QA_QS_TY_SC',
            align: 'center',
            sortable: true,
          },
          {
            text: '사용여부',
            value: 'QA_QS_YN',
            align: 'center',
            sortable: true,
          },
        ],
        qaQsData: [],
        qaQsDialogHeader: [{
            text: 'No',
            align: 'center',
            value: 'QA_QS_ID',
            width: '50px',
            sortable: false,
          },
          {
            text: '문항유형',
            value: 'QA_QS_TY_NM',
            align: 'center',
            sortable: true,
          },
          {
            text: '대분류',
            value: 'QA_TY_L_CD_NM',
            align: 'center',
            sortable: true,
          },
          {
            text: '소분류',
            value: 'QA_TY_M_CD_NM',
            align: 'center',
            sortable: true,
          },
          {
            text: '항목',
            value: 'QA_QS_NM',
            align: 'left',
            sortable: true,
          },
          {
            text: '배점',
            value: 'QA_QS_TY_SC',
            align: 'center',
            sortable: true,
          },
          {
            text: '문항수',
            value: 'QA_VE_COUNT',
            align: 'center',
            sortable: true,
          },
        ],
        evlRstDialogHeader: [{
            text: 'No',
            align: 'center',
            value: 'QA_QS_ID',
            width: '50px',
            sortable: false,
          },
          {
            text: '문항유형',
            value: 'QA_QS_TY_NM',
            align: 'center',
            sortable: true,
          },
          {
            text: '대분류',
            value: 'QA_TY_L_CD_NM',
            align: 'center',
            sortable: true,
          },
          {
            text: '소분류',
            value: 'QA_TY_M_CD_NM',
            align: 'center',
            sortable: true,
          },
          {
            text: '항목',
            value: 'QA_QS_NM',
            align: 'left',
            sortable: true,
          },
          {
            text: '정렬',
            value: 'QA_EVA_RST_OD',
            align: 'center',
            sortable: true,
          },
        ],
        evlRstDialogData: [],
        preViewList: [],
        qaQsDialogData: [],
        checkedEvlPaper: [],
        checkedQaQs: [],
        checkedQaQsDialog: [],
        checkedEvlRstDialog: [],
        checkedEvlRst: [],
        selectedEvlPaper: {},
        selectedQaQs: {},
        selectedEvlRst: {},
        QA_EVA_ID: "",
        QA_EVA_NM: "",
        allCodeList: [],
        isModify: false,
        evlPaperPaging: {
          page: 1,
          pageCount: 1,
          itemsPerPage: 5,
          totalVisible: 5,
        },
        qaQsPaging: {
          page: 1,
          pageCount: 0,
          itemsPerPage: 5,
          totalVisible: 5,
        },
        evlRstPaging: {
          page: 1,
          pageCount: 0,
          itemsPerPage: 5,
          totalVisible: 5,
        },
        evlRstDialogPaging: {
          page: 1,
          pageCount: 0,
          itemsPerPage: 5,
          totalVisible: 5,
        },
        qaQsDialogPaging: {
          page: 1,
          pageCount: 0,
          itemsPerPage: 5,
          totalVisible: 5,
        },
        preVeDialog: [],
        
        RDOnly:false,
      }
    },
    created() {

    },
    async mounted() {
      let codeList = ['PLT008', 'PLT009', 'PLT010', 'PLT011', 'PLT012'];
      this.allCodeList = await this.mixin_common_code_get_all(codeList);

      let chk = this.mixin_check_AspCustKey_number();
      if(chk == 'Y'){
        this.evlPaperSearch.ASP_NEWCUST_KEY = this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD;
        this.RDOnly = true;
      } else {
        this.RDOnly = false;
      }
    },
    watch: {
      selectedEvlPaper: function () {
        if (this.selectedEvlPaper.QA_EVA_ID != '') {
          this.selectRtnQaEvaRst();
          this.selectRtnQaQsDialog();
          this.selectRtnQaPreView();
        }
      },
    },
    methods: {
      companyChange() {
        this.selectRtnQaEva();
        this.selectRtnQaQs();
      },
      /* 데이터 호출 */
      async getAspData() {
        let jsonData = {
          'ASP_USER_ID': this.$store.getters['userStore/GE_USER_ROLE'].userId,
        }
        let URLData = "api/code/common/code-book/aspinqry";
        let HEADER_SERVICE = "code.common.code-book";
        let HEADER_METHOD = "aspinqry";
        let HEADER_TYPE = "BIZ_SERVICE";

        await api.post(URLData, jsonData, {
            head: {
              SERVICE: HEADER_SERVICE,
              METHOD: HEADER_METHOD,
              TYPE: HEADER_TYPE,
            }
          })
          .then((response) => {
            //console.log(JSON.stringify(response.data))
          })
          .catch((err) => {
            this.errorAlert();
          })
      },
      /* 평가표 리스트 조회 */
      async selectRtnQaEva() {

        //체크 초기화
        this.selectedEvlPaper = {};
        this.checkedEvlPaper = [];

        if (this.evlPaperSearch.ASP_NEWCUST_KEY != undefined && this.evlPaperSearch.ASP_NEWCUST_KEY != '') {
          let jsonData = {
            'ASP_NEWCUST_KEY': this.evlPaperSearch.ASP_NEWCUST_KEY,
            'QA_EVA_TY': this.evlPaperSearch.QA_EVA_TY,
            'QA_EVA_YN': this.evlPaperSearch.QA_EVA_YN,
            'QA_EVA_NM': this.evlPaperSearch.QA_EVA_NM,
          }

          let URLData = "api/phone/qa2/evl-paper-manage/list";
          let HEADER_SERVICE = "phone.evl-paper-manage";
          let HEADER_METHOD = "list";
          let HEADER_TYPE = "BIZ_SERVICE";

          await api.post(URLData, jsonData, {
              head: {
                SERVICE: HEADER_SERVICE,
                METHOD: HEADER_METHOD,
                TYPE: HEADER_TYPE,
              }
            })
            .then((response) => {
              this.evlPaperData = response.data.DATA;
            })
            .catch((err) => {
              this.errorAlert();
            })
        } else {
          this.warningAlert('회사를 먼저 선택해주세요.');
        }
      },
      /* 평가표 삭제 */
      async deleteEvlPaper() {

        if (this.checkedEvlPaper.length > 0) {
          let isUse = false;
          this.checkedEvlPaper.map(e => {
            if (e.USE_COUNT > 0) {
              isUse = true;
            }
          });

          if (!isUse) {

            let jsonData = {
              'ASP_NEWCUST_KEY': this.evlPaperSearch.ASP_NEWCUST_KEY,
              'checkedEvlPaper': this.checkedEvlPaper,
            }

            let URLData = "api/phone/qa2/evl-paper-manage/delete";
            let HEADER_SERVICE = "phone.evl-paper-manage";
            let HEADER_METHOD = "delete";
            let HEADER_TYPE = "BIZ_SERVICE";

            await api.post(URLData, jsonData, {
                head: {
                  SERVICE: HEADER_SERVICE,
                  METHOD: HEADER_METHOD,
                  TYPE: HEADER_TYPE,
                }
              })
              .then((response) => {
                this.successAlert();
                this.checkedEvlPaper = [];
                this.selectRtnQaEva();
              })
              .catch((err) => {
                this.errorAlert();
              })
          } else {
            this.warningAlert('사용중인 평가지는 삭제할 수 없습니다.');
          }

        } else {
          this.warningAlert('삭제할 평가지를 선택해주세요.');
        }
      },

      //평가표 복사
      async insertRtnCopyEvlPaper() {
        if (this.checkedEvlPaper.length > 0) {
          let jsonData = {
            'ASP_NEWCUST_KEY': this.evlPaperSearch.ASP_NEWCUST_KEY,
            'REG_ID': this.$store.getters['userStore/GE_USER_ROLE'].userId,
            'checkedEvlPaper': this.checkedEvlPaper,
          }

          let URLData = "api/phone/qa2/evl-paper-manage/copy/regist";
          let HEADER_SERVICE = "phone.evl-paper-manage.copy";
          let HEADER_METHOD = "regist";
          let HEADER_TYPE = "BIZ_SERVICE";

          await api.post(URLData, jsonData, {
              head: {
                SERVICE: HEADER_SERVICE,
                METHOD: HEADER_METHOD,
                TYPE: HEADER_TYPE,
              }
            })
            .then((response) => {
              this.successAlert();
              this.checkedEvlPaper = [];
              this.selectRtnQaEva();
            })
            .catch((err) => {
              this.errorAlert();
            })
        } else {
          this.warningAlert('복사할 평가지를 선택해주세요.');
        }

      },
      /* 평가 상세관리 조회 */
      async selectRtnQaEvaRst() {
        await api.post('api/phone/qa2/evl-qa-rst-manage/list', {
            ['ASP_NEWCUST_KEY']: this.evlPaperSearch.ASP_NEWCUST_KEY,
            ['QA_EVA_ID']: this.selectedEvlPaper.QA_EVA_ID,
          })
          .then((response) => {
            this.evlRstData = response.data.DATA;
          })
          .catch((err) => {
            this.errorAlert();
          })
      },
      async selectRtnDialogQaEvaRst() {
        await api.post('api/phone/qa2/evl-qa-rst-manage/list', {
            ['ASP_NEWCUST_KEY']: this.evlPaperSearch.ASP_NEWCUST_KEY,
            ['QA_EVA_ID']: this.selectedEvlPaper.QA_EVA_ID,
          })
          .then((response) => {
            this.evlRstDialogData = response.data.DATA;
          })
          .catch((err) => {
            this.errorAlert();
          })
      },
      //평가문항 조회
      async selectRtnQaQs() {

        //체크 초기화
        this.checkedQaQs = [];

        let jsonData = {
          'ASP_NEWCUST_KEY': this.evlPaperSearch.ASP_NEWCUST_KEY,
          'QA_TY_L_CD': this.qaQsSearch.QA_TY_L_CD,
          'QA_TY_M_CD': this.qaQsSearch.QA_TY_M_CD,
        }

        let URLData = "api/phone/qa2/evl-qs-manage/list";
        let HEADER_SERVICE = "phone.evl-qs-manage";
        let HEADER_METHOD = "list";
        let HEADER_TYPE = "BIZ_SERVICE";

        await api.post(URLData, jsonData, {
            head: {
              SERVICE: HEADER_SERVICE,
              METHOD: HEADER_METHOD,
              TYPE: HEADER_TYPE,
            }
          })
          .then((response) => {
            this.qaQsData = response.data.DATA;
          })
          .catch((err) => {
            this.errorAlert();
          })
      },
      //평가문항 조회 (dialog)
      async selectRtnQaQsDialog() {

        let jsonData = {
          'ASP_NEWCUST_KEY': this.evlPaperSearch.ASP_NEWCUST_KEY,
          'QA_TY_L_CD': this.qaQsDialogSearch.QA_TY_L_CD,
          'QA_TY_M_CD': this.qaQsDialogSearch.QA_TY_M_CD,
          'QA_QS_NM': this.qaQsDialogSearch.QA_QS_NM,
          'QA_QS_TY': this.qaQsDialogSearch.QA_QS_TY,
          'QA_QS_YN': this.qaQsDialogSearch.QA_QS_YN,
        }

        let URLData = "api/phone/qa2/evl-qs-manage/list";
        let HEADER_SERVICE = "phone.evl-qs-manage";
        let HEADER_METHOD = "list";
        let HEADER_TYPE = "BIZ_SERVICE";

        await api.post(URLData, jsonData, {
            head: {
              SERVICE: HEADER_SERVICE,
              METHOD: HEADER_METHOD,
              TYPE: HEADER_TYPE,
            }
          })
          .then((response) => {
            this.qaQsDialogData = response.data.DATA;
          })
          .catch((err) => {
            this.errorAlert();
          })
      },
      //평가문항 복사
      async insertRtnCopyQaQs() {
        if (this.checkedQaQs.length > 0) {
          let jsonData = {
            'ASP_NEWCUST_KEY': this.evlPaperSearch.ASP_NEWCUST_KEY,
            'REG_ID': this.$store.getters['userStore/GE_USER_ROLE'].userId,
            'checkedQaQs': this.checkedQaQs,
          }

          let URLData = "api/phone/qa2/evl-qs-manage/copy/regist";
          let HEADER_SERVICE = "phone.evl-qs-manage.copy";
          let HEADER_METHOD = "regist";
          let HEADER_TYPE = "BIZ_SERVICE";

          await api.post(URLData, jsonData, {
              head: {
                SERVICE: HEADER_SERVICE,
                METHOD: HEADER_METHOD,
                TYPE: HEADER_TYPE,
              }
            })
            .then((response) => {
              this.successAlert();
              this.checkedQaQs = [];
              this.selectRtnQaQs();
            })
            .catch((err) => {
              this.errorAlert();
            })
        } else {
          this.warningAlert('복사할 문항을 선택해주세요.');
        }
      },
      // 평가문항 삭제
      async deleteRtnQaQs() {
        if (this.checkedQaQs.length > 0) {
          if (!this.checkedQaQs.some(e => {
              return e.USE_COUNT > 0 ? true : false
            })) {
            let jsonData = {
              'ASP_NEWCUST_KEY': this.evlPaperSearch.ASP_NEWCUST_KEY,
              'checkedQaQs': this.checkedQaQs,
            }

            let URLData = "api/phone/qa2/evl-qs-manage/delete";
            let HEADER_SERVICE = "phone.evl-qs-manage";
            let HEADER_METHOD = "delete";
            let HEADER_TYPE = "BIZ_SERVICE";

            await api.post(URLData, jsonData, {
                head: {
                  SERVICE: HEADER_SERVICE,
                  METHOD: HEADER_METHOD,
                  TYPE: HEADER_TYPE,
                }
              })
              .then((response) => {
                this.successAlert();
                this.checkedQaQs = [];
                this.selectRtnQaQs();
              })
              .catch((err) => {
                this.errorAlert();
              })
          } else {
            this.warningAlert('사용중인 문항은 삭제할 수 없습니다.');
          }
        } else {
          this.warningAlert('삭제할 문항을 선택해주세요.');
        }
      },
      //평가 상세 저장
      async insertRtnQaEvaRst(evlRstList) {
        let jsonData = {
          'ASP_NEWCUST_KEY': this.evlPaperSearch.ASP_NEWCUST_KEY,
          'evlRstList': evlRstList,
          'QA_EVA_ID': this.selectedEvlPaper.QA_EVA_ID,
          'REG_ID': this.$store.getters['userStore/GE_USER_ROLE'].userId,
        }
        let URLData = "api/phone/qa2/evl-qa-rst-manage/regist";
        let HEADER_SERVICE = "phone.evl-qa-rst-manage";
        let HEADER_METHOD = "regist";
        let HEADER_TYPE = "BIZ_SERVICE";
        let DATA_OBJECT = "evlRstList"

        await api.post(URLData, jsonData, {
            head: {
              SERVICE: HEADER_SERVICE,
              METHOD: HEADER_METHOD,
              TYPE: HEADER_TYPE,
              DATA_OBJECT: DATA_OBJECT,
            }
          })
          .then((response) => {
            this.successAlert();
            this.selectRtnQaEvaRst();
            this.selectRtnQaQs();
            this.selectRtnQaEva();
            this.selectRtnDialogQaEvaRst();
            this.dialogM310101P05 = false;
          })
          .catch((err) => {
            this.errorAlert();
          })
      },

      //미리보기
      async selectRtnQaPreView() {
        let jsonData = {
          'ASP_NEWCUST_KEY': this.evlPaperSearch.ASP_NEWCUST_KEY,
          'QA_EVA_ID': this.selectedEvlPaper.QA_EVA_ID,
        }
        let URLData = "api/phone/qa2/evl-qa-rst-manage/pre/list";
        let HEADER_SERVICE = "phone.evl-qa-rst-manage.pre";
        let HEADER_METHOD = "list";
        let HEADER_TYPE = "BIZ_SERVICE";

        await api.post(URLData, jsonData, {
            head: {
              SERVICE: HEADER_SERVICE,
              METHOD: HEADER_METHOD,
              TYPE: HEADER_TYPE,
            }
          })
          .then((response) => {
            this.preViewList = response.data.DATA;
          })
          .catch((err) => {
            this.errorAlert();
          })
      },
      async selectRtnPreQaVe() {
        let URLData = "api/phone/qa2/evl-qa-rst-manage/pre/ve/list";
        let HEADER_SERVICE = "phone.qa2.evl-qa-rst-manage.pre.ve";
        let HEADER_METHOD = "list";
        let HEADER_TYPE = "BIZ_SERVICE";
        let DATA_OBJECT = "QA_QS_LIST";
        let jsonData = {
          'QA_QS_LIST': this.evlRstDialogData,
        };

        await api.post(URLData, jsonData, {
            head: {
              SERVICE: HEADER_SERVICE,
              METHOD: HEADER_METHOD,
              TYPE: HEADER_TYPE,
              DATA_OBJECT: DATA_OBJECT,
            }
          })
          .then((response) => {
            this.preVeDialog = response.data.DATA;
          })
          .catch((err) => {
            this.errorAlert();
          })
      },


      /* Dialog 호출*/

      /* 평가표 등록 호출 */
      insertRtnEvlPaperForm() {
        if (this.evlPaperSearch.ASP_NEWCUST_KEY != undefined && this.evlPaperSearch.ASP_NEWCUST_KEY != '') {
          this.isModify = false;
          this.showDialog('M310101P01');
        } else {
          this.warningAlert('회사를 먼저 선택해주세요.');
        }
      },
      /* 평가표 수정 호출 */
      modifyEvlPaperForm() {
        if (this.checkedEvlPaper.length === 1) {
          this.isModify = true;
          this.showDialog('M310101P01');
        } else if (this.checkedEvlPaper.length > 0) {
          this.warningAlert('수정할 평가표를 1건만 선택해주세요.');
        } else {
          this.warningAlert('수정할 평가표를 선택해주세요.');
        }
      },
      //평가문항 등록폼 호출
      insertRtnQaQsForm() {
        if (this.evlPaperSearch.ASP_NEWCUST_KEY != undefined && this.evlPaperSearch.ASP_NEWCUST_KEY != '') {
          this.isModify = false;
          this.showDialog('M310101P02');
        } else {
          this.warningAlert('회사를 먼저 선택해주세요.');
        }
      },

      showDialog(type) {
        this[`dialog${type}`] = true
      },
      hideDialog(type) {
        this[`dialog${type}`] = false
      },
      submitDialog(type) {
        this[`dialog${type}`] = false
      },
      addrowDialog(type) {
        this[`dialog${type}`] = true
      },
      delrowDialog() {
        console.log('del row');
      },
      initDialog() {
        console.log('init');
      },
      setEvlPaper(item, row) {
        this.selectedEvlPaper = item;
        this.checkedEvlRst = [];
      },
      setQaQs(item, row) {
        this.selectedQaQs = item;
      },
      setEvlRst(item, row) {
        this.selectedEvlRst = item;
      },
      saveEvlPaper() {
        this.selectRtnQaEva();
        this.submitDialog("M310101P01");
      },
      saveQaQs() {
        this.selectRtnQaQs();
        this.submitDialog("M310101P02");
      },
      changeQaTyL(value) {
        //소분류 코드
        console.log(this.qaQsSearch.QA_TY_L_CD);
        console.log(this.qaQsSearch.QA_TY_M_CD);

        //this.getCmmCd("PLT010",value,'','');
        this.selectRtnQaQs();
      },
      //평가 상세
      insertRtnEvlRstForm() {
        this.isModify = false;
        if (this.selectedEvlPaper.QA_EVA_ID != null && this.selectedEvlPaper.QA_EVA_ID != '') {
          this.checkedQaQsDialog = [];
          this.selectRtnDialogQaEvaRst();
          this.showDialog('M310101P03');
        } else {
          this.warningAlert('평가지를 선택해주세요.');
        }
      },
      //평가문항 수정 호출
      modifyQaQsDialog() {
        if (this.checkedQaQs.length === 1) {
          this.isModify = true;
          this.showDialog('M310101P02');
        } else if (this.checkedQaQs.length > 0) {
          this.warningAlert('수정할 문항을 1건만 선택해주세요.');
        } else {
          this.warningAlert('수정할 문항을 선택해주세요.');
        }
      },

      /** 기타 함수 **/
      //평가 상세 항목 추가
      addEvlRst() {
        if (this.checkedQaQsDialog.length > 0) {
          this.checkedQaQsDialog.map(e => {
            if (!this.evlRstDialogData.some(evlRst => evlRst.QA_QS_ID === e.QA_QS_ID)) {
              e.QA_EVA_RST_OD = this.getMaxSortOrder() + 10;
              this.evlRstDialogData.push(e);
            }
          });
        } else {
          this.warningAlert('추가할 항목을 선택해주세요.');
        }
      },
      deleteRtnEvlRst() {
        if (this.selectedEvlPaper.USE_COUNT > 0) {
          this.warningAlert('사용중인 평가 상세는 삭제할 수 없습니다.');
        } else {
          this.delEvlRst(this.checkedEvlRst, this.evlRstData);
          this.insertRtnQaEvaRst(this.evlRstData);
        }
      },
      //평가 상세 항목 삭제
      delEvlRst(deleteList, targetList) {
        if (deleteList.length > 0) {
          deleteList.map(e => {
            targetList.some((evlRst, index) => {
              if (evlRst === e) {
                targetList.splice(index, 1);
              }
            })
          });
        }
      },
      //평가 상세 항목 max 정렬 순번
      getMaxSortOrder() {
        let sortOrder = 0;
        this.evlRstDialogData.map(e => {
          if (e.QA_EVA_RST_OD > sortOrder) {
            sortOrder = e.QA_EVA_RST_OD;
          }
        })
        return parseInt(sortOrder);
      },

      preView() {
        // this.preQaQsDialog = qaQsList;
        // this.preVeDialog = veList;
        this.selectRtnPreQaVe();
        this.showDialog('M310101P06');
      },
      successAlert() {
        this.common_alert(`정상처리 되었습니다.`, 'done');
      },
      errorAlert() {
        this.common_alert(`시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.`, 'error');
      },
      warningAlert(text) {
        let alertText = `시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.`;
        if(!this.mixin_isEmpty(text)) {
          alertText = text;
        }
        this.common_alert(alertText, 'error');
      },
      isActiveRow(item) {
        return (item === this.selectedEvlPaper) ? 'active' : ''
      },
      isActiveRow2(item) {
        return (item === this.selectedQaQs) ? 'active' : ''
      },
      isActiveRow3(item) {
        return (item === this.selectedEvlRst) ? 'active' : ''
      },
    },
  }
</script>

<style>

</style>