<template>
  <div class="modal-wrap">
    <h1 class="tit-h1 d-flex">
      메신저연동관리 신규
      <div class="ml-auto align-self-center">
        <v-icon x-small class="svg-close20" @click="$emit('hide')"></v-icon>
      </div>
    </h1>
    <div class="modal-box">
      <div>
         <v-form ref="form"
            v-model="isFormValid"
            lazy-validation
    >
      <div class="table-form">
        <table>
          <caption class="hide">
            채널상세정보
          </caption>
          <colgroup>
            <col width="127px" />
            <col width="" />
            <col width="" />
            <col width="" />
          </colgroup>
            <tbody>
              <tr>
                <th scope="row">
                  <v-badge dot color="#EF9191">고객사</v-badge>
                </th>
                <td>
                  <v-select
                      name="ASP_CUST_INFO"
                      :items="userCompany"
                      class="form-inp full"
                      outlined
                      hide-details
                      placeholder="선택"
                      item-text="CD_NM"
                      item-value="CD"
                      v-model="formData.ASP_CUST_INFO"
                      required
                      :rules="[formRules.ASP_NEWCUST_KEY.required]"
                  >
                    <template v-slot:label>
                      고객사
                    </template>
                  </v-select>
                </td>
                <th scope="row">
                  채널서비스이용상태
                </th>
                <td>
                  <v-select
                      :items="mixin_common_code_get(this.common_code, 'ASP002', '선택')"
                      class="form-inp full"
                      outlined
                      hide-details
                      placeholder="선택"
                      v-model="formData.SERV_KEEP_YN"
                      required
                  >
                  </v-select>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <v-badge dot color="#EF9191">비즈서비스</v-badge>
                </th>
                <td>
                  <v-select
                      :items="bizServiceList"
                      class="form-inp full"
                      item-text="CD_NM"
                      item-value="CD"
                      outlined
                      hide-details
                      placeholder="선택"
                      v-model="formData.BIZ_SERVICES_CD"
                      required
                      :rules="[formRules.BIZ_SERVICES_CD.required]"
                      @change="chBiz(formData.BIZ_SERVICES_CD)"
                  >
                    <template v-slot:label>
                      비즈서비스
                    </template>
                  </v-select>
                </td>
                <th scope="row">
                  <v-badge dot color="#EF9191">톡UUID</v-badge>
                </th>
                <td colspan="3">
                  <v-text-field
                      v-model="formData.TALK_UUID"
                      class="form-inp full"
                      maxlength="100"
                      hide-details="auto"
                      outlined
                      required
                      :rules="[formRules.TALK_UUID.required]"
                      :disabled="disableYn"
                  >
                  </v-text-field>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <v-badge dot color="#EF9191">채팅샌더키</v-badge>
                </th>
                <td colspan="3">
                  <v-text-field
                      v-model="formData.TALK_SENDER_KEY"
                      class="form-inp full"
                      maxlength="100"
                      hide-details="auto"
                      outlined
                      required
                      :rules="[formRules.TALK_SENDER_KEY.required]"
                      :disabled="disableYn"
                  >
                  </v-text-field>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <v-badge dot color="#EF9191">채팅채널명</v-badge>
                </th>
                <td colspan="3">
                  <v-text-field
                      v-model="formData.TALK_SENDER_NM"
                      class="form-inp full"
                      maxlength="100"
                      hide-details="auto"
                      outlined
                      required
                      :rules="[formRules.TALK_SENDER_NM.required]"
                  >
                  </v-text-field>
                </td>
              </tr>
            </tbody>
        </table>
      </div>
    </v-form>
      </div>
      <div class="text-right mt-3">
        <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSave')" outlined class="btn-point ml-2" id="btnSave" @click="submit">저장</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../store/apiUtil";
import { mixin } from "@/mixin/mixin.js";
import { mapGetters } from "vuex";

let jsonObj

export default {
  name: "MENU_M810604P01", //name은 'MENU_' + 파일명 조합
  mixins:[mixin],
  data(){
    return {
      common_code: [],
      bizServiceList: [],
      mainVo: function() {
        this.ASP_NEWCUST_KEY=''
        this.ASP_CUST_INFO=''
        this.SERV_KEEP_YN=''
        this.BIZ_SERVICES_CD=''
        this.TALK_UUID=''
        this.TALK_SENDER_KEY=''
        this.TALK_SENDER_NM=''
      },
      formData: {},
      isFormValid: true,
      formRulesSubfix: ' 은(는) 필수입력입니다.',
      formRules: {
        ASP_NEWCUST_KEY: {
          required: v => !!(v  && (v+'').trim()!=='') || '고객사' + this.formRulesSubfix
        },
        BIZ_SERVICES_CD: {
          required: v => !!(v  && (v+'').trim()!=='') || '비즈서비스' + this.formRulesSubfix
        },
        TALK_UUID: {
          required: v => !!(v  && (v+'').trim()!=='') || '톡UUID' + this.formRulesSubfix
        },
        TALK_SENDER_KEY: {
          required: v => !!(v  && (v+'').trim()!=='') || '채팅샌더키' + this.formRulesSubfix
        },
        TALK_SENDER_NM: {
          required: v => !!(v  && (v+'').trim()!=='') || '채널채팅명' + this.formRulesSubfix
        }
      },
      disableYn: false
    }
  },
  mounted() {
    this.initData()
  },
  methods:{
    async initData(){
      this.$refs.form.resetValidation()
      this.formData = new this.mainVo()
      let codeName = ["ASP002"];
      this.common_code= await this.mixin_common_code_get_all(codeName);
      this.bizServiceList = await this.setBizComboList()
    },
    async submit(){
      let UUID = this.formData.TALK_UUID;
      let SENDER = this.formData.TALK_SENDER_KEY;
      if(this.formData.TALK_UUID == '자동생성'){
        UUID = '@NeedChange';
      }
      if(this.formData.TALK_SENDER_KEY == '자동생성'){
        SENDER = '00need00change00';
      } 

      if(!this.$refs.form.validate()) return false

      let URLData = "/api/chat/setting/messenger-connect-manage/regist-popup/regist";
      let HEADER_SERVICE = "chat.setting.messenger-connect-manage.regist-popup";
      let HEADER_METHOD = "regist";
      let HEADER_TYPE = "BIZ_SERVICE";
      let HEADER_ASYNC = false;

      let jsonData = {
        ASP_CUST_KEY : this.formData.ASP_CUST_INFO,
        ASP_CUST_INFO : this.formData.ASP_CUST_INFO,
        SERV_KEEP_YN : this.formData.SERV_KEEP_YN,
        BIZ_SERVICES_CD : this.formData.BIZ_SERVICES_CD,
        TALK_UUID : UUID,
        TALK_SENDER_KEY : this.formData.TALK_SENDER_KEY,
        TALK_SENDER_NM : this.formData.TALK_SENDER_NM,
        PROC_ID : this.userId,
        REGR_ID : this.userId,
        REGR_DEPT_CD : 'x',
        AMDR_ID : this.userId,
        AMDR_DEPT_CD : 'x'
      };

      //api 호출 부분
      await api
          .post(URLData, jsonData, {
            head: {
              SERVICE: HEADER_SERVICE,
              METHOD: HEADER_METHOD,
              TYPE: HEADER_TYPE,
              ASYNC: HEADER_ASYNC
            },
          })
          .then((response) => {
            if(!this.isEmpty(response) && !response.data.HEADER.ERROR_FLAG) {
              this.common_alert('정상처리 되었습니다.', 'done')
              this.$emit('hideAndGetList');
            } else {
              this.common_alert(response.data.HEADER.ERROR_MSG, 'error')
            }
          })
          .catch((err) => {
            alert(err);
          });
    },
    isEmpty(val) {
      if (val == null) return true
      if (typeof val === 'undefined') return true
      if (typeof val === 'string' && val === '' && (''+val) === '') return true
      if (Array.isArray(val) && val.length < 1) return true
      if (typeof val === 'object' && val.constructor.name === 'Object' && Object.keys(val).length < 1 && Object.getOwnPropertyNames(val) < 1) return true
      if (typeof val === 'object' && val.constructor.name === 'String' && Object.keys(val).length < 1) return true
    },
    async setBizComboList(){

      let responseData = [];
      await api
          .post(
              "/api/chat/setting/messenger-connect-manage/biz-combo-data/inqire",
              {
              },
              {
                head: {
                  SERVICE: "chat.setting.messenger-connect-manage.biz-combo-data",
                  METHOD: "inqire",
                  TYPE: "BIZ_SERVICE",
                },
              }
          )
          .then((response) => {
            if (response.data) {
              for (var i = 0; i < response.data.DATA.length; i++) {
                var jasonData = {};
                jasonData["CD"] = response.data.DATA[i].CD
                jasonData["CD_NM"] = response.data.DATA[i].CD_NM
                responseData.push(jasonData)
              }
            }

          })
          .catch((error) => {});

      return responseData;
    },
    chBiz(item){
      if(item === 'TTALK'){
        this.disableYn = true
        this.formData.TALK_UUID = '자동생성'
        this.formData.TALK_SENDER_KEY = '자동생성'
      } else {
        this.disableYn = false
        this.formData.TALK_UUID = ''
        this.formData.TALK_SENDER_KEY = ''
      }
    }
  },
  computed: {
    ...mapGetters({
      userId: 'userStore/GE_USER_ID',
      userCompany: 'userStore/GE_USER_COMPANY'
    }),
  },
};
</script>

<style></style>