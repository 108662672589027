<template>
  <main>
    <div class="modal-wrap">
      <h1 class="tit-h1 d-flex">
        상담사 일괄생성
        <!-- 창 닫기 아이콘 -->
        <div class="ml-auto align-self-center">
          <v-icon class="svg-close20" x-small @click="$emit('hide')"></v-icon>
        </div>
      </h1>
      <!-- 조회 -->
      <div class="modal-box d-flex">
        <div class="table-form">
          <table>
            <caption class="hide">
              조회 항목
            </caption>
            <colgroup>
              <col width="148px" />
              <col width="180px" />
              <col width="148px" />
              <col width="350px" />
              <col width="" />
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">
                  건설사명
                </th>
                <td>
                  <v-text-field
                    class="form-inp full"
                    v-model="CTCP_NAME"
                    name="CTCP_NAME"
                    outlined
                    hide-details
                    disabled
                  >
                  </v-text-field>
                </td>
                <th scope="row">
                  사업지명
                </th>
                <td>
                  <v-text-field
                    class="form-inp full"
                    v-model="BUSS_NAME"
                    name="BUSS_NAME"
                    outlined
                    hide-details
                    disabled
                  >
                  </v-text-field>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- 저장 버튼 -->
        <div class="ml-auto align-self-center">
          <v-btn outlined small class="btn-default ml-2" @click="newSaveBtn">일괄생성</v-btn>
        </div>
      </div>
      <!-- 입력 항목 -->
      <div class="modal-box flex-grow-1">
        <span class="noti-font"><strong>※</strong> 일괄생성 후 생성된 상담사별로 상담사명 및 캠페인 지정을 해야 합니다.</span>
        <div class="box-ct mt-2">
          <v-form ref="form" lazy-validation>
            <div class="table-form">
              <table>
                <caption class="hide">
                  항목
                </caption>
                <colgroup>
                  <col width="400px" />
                  <col width="" />
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="row">
                      <v-badge dot color="#EF9191">
                        상담사ID
                      </v-badge>
                    </th>
                    <td>
                      <v-text-field
                        class="form-inp full"
                        v-model="CNSLR_ID"
                        name="CNSLR_ID"
                        outlined
                        hide-details
                        placeholder="일괄생성 시 공통으로 사용할 ID를 입력하세요."
                      >
                      </v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <v-badge dot color="#EF9191">
                        상담사 인원수
                      </v-badge>
                    </th>
                    <td>
                      <v-text-field
                        class="form-inp full"
                        v-model="CNSLR_CNT"
                        name="CNSLR_CNT"
                        type="number"
                        min="1"
                        max="20"
                        outlined
                        hide-details
                      >
                      </v-text-field>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </v-form>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import {mixin} from "@/mixin/mixin";

export default {
  name: "MENU_COP0510", //name은 'MENU_' + 파일명 조합
  components: {},
  mixins:[mixin],
  props : {
    param: {
      type: Object,
    },
  },
  data() {
    return {
      HEADERResult: '',
      DATAResult  : '',
      
      HEADER_TYPE     : 'BIZ_SERVICE',
      HEADER_GRID_ID  : '',
      HEADER_ROW_CNT  : '',
      HEADER_PAGES_CNT: '',
      headers         : {},
      
      URLData : '',
      sendData: {},

      // 전달 받은 값 담을 변수
      CTCP_CODE  : '', // 건설사코드
      CTCP_NAME  : '', // 건설사명
      BUSS_CODE  : '', // 사업지코드
      BUSS_NAME  : '', // 사업지명

      // 팝업 내용
      CNSLR_ID : '', // 상담사ID
      CNSLR_CNT: '', // 상담사인원수

      // 기타
      USER_ID: '', // 사용자아이디

      // 메시지
      cop0510Msg: {
        saveChk         : '상담사 일괄생성을 하시겠습니까?',
        saveSuccess     : '일괄생성 되었습니다.',
        cnslrIdEmptyChk : '상담사 ID를 입력하세요.',
        cnslrCntEmptyChk: '상담사 인원수를 입력하세요.',
        cnslrCntChk     : '일괄생성은 1명 ~ 20명까지만 등록 가능합니다.',
      },
    };
  },
  methods: {
    // 일괄생성 여부 확인
    newSaveBtn() {
      // 유효성 체크
      if(!this.saveValidate()) {
        return;
      }
      this.common_confirm(this.cop0510Msg.saveChk, this.newSave, '', null, null, "done");
    },

    // 일괄생성 저장
    async newSave() {
      let requestData = {
        headers: this.initHeaders,
        sendData: {},
      }

      requestData.headers["URL"] = "/api/setting/agent/agent-cnslr-manage/process";
      requestData.headers["SERVICE"] = "setting.agent.agent-cnslr-manage";
      requestData.headers["METHOD"] = "process";
      requestData.headers["ASYNC"] = false;
      requestData.headers["TWB_SQL_ID"] = "insertCnslrNewInfo";

      requestData.sendData["CNSLR_ID"] = this.CNSLR_ID;
      requestData.sendData["CNSLR_CNT"] = this.CNSLR_CNT;
      requestData.sendData["USER_ATTR_A"] = this.CTCP_CODE;
      requestData.sendData["USER_ATTR_B"] = this.BUSS_CODE;
      requestData.sendData["SORT_ORD"] = 1;
      requestData.sendData["BUSS_CODE"] = this.BUSS_CODE;
      requestData.sendData["REGR_ID"] = this.USER_ID;
      requestData.sendData["AMDR_ID"] = this.USER_ID;
      requestData.sendData["PROC_ID"] = this.USER_ID;
      
      let response = await this.common_postCall(requestData);
      console.log(response);

      this.result(response);
    },

    // 등록 저장 결과
    getGridDataSaveCallBack(response) {
      if(!response.HEADER.ERROR_FLAG) {
        this.common_alert(this.cop0510Msg.saveSuccess);
      }else {
        // 상담사ID 중복
        this.common_alert(response.HEADER.ERROR_MSG, "error");
        return;
      }

      let params = {
        CTCP_CODE: this.CTCP_CODE,
        BUSS_CODE: this.BUSS_CODE,
      }
      this.$emit('hide'); // 팝업 닫기
      this.$emit('select', params); // 부모 창으로 값 전달
    },

    // 결과 확인
    result(response) {
      this.getGridDataSaveCallBack(response);
    },

    // 초기화
    cleanData() {
      this.CNSLR_ID = '';
      this.CNSLR_CNT = '';
    },

    // 유효성 체크
    saveValidate() {
      if(this.CNSLR_ID === '') {
        this.common_alert(this.cop0510Msg.cnslrIdEmptyChk, "error");
        return;
      }

      if(this.CNSLR_CNT === '') {
        this.common_alert(this.cop0510Msg.cnslrCntEmptyChk, "error");
        return;
      }

      if(this.CNSLR_CNT < 1 ||this.CNSLR_CNT > 20) {
        this.common_alert(this.cop0510Msg.cnslrCntChk, "error");
        return;
      }
      return true;
    },
  },
  async mounted() {
    this.USER_ID = this.$store.getters['userStore/GE_USER_ROLE'].userId;
    this.cleanData(); // 초기화

    // 부모 창에서 넘어온 값 담기
    this.CTCP_CODE = this.param.ctcpCd;
    this.CTCP_NAME = this.param.ctcpNm;
    this.BUSS_CODE = this.param.bussCd;
    this.BUSS_NAME = this.param.bussNm;
  },
  computed: {
    initHeaders() {
      return this.headers = {
        "SERVICE": "",
        "METHOD": "",
        "TWB_SQL_ID": "",
        "TYPE": this.HEADER_TYPE,
      };
    },
  },
};
</script>
<style scoped>
.noti-font {
  font-size: 12px;
  color: red;
}
</style>