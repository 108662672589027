<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">상담사 이석이력</h1>
    <div class="box-wrap">
      <div class="box-ct d-flex">
        <div>
          <div class="form-inp sm">
            <v-select
              :items="aspCustList"
              item-text="CD_NM"
              item-value="CD"
              outlined
              hide-details
              label="회사구분"
              placeholder="선택하세요"
              v-model="formData.ASP_NEWCUST_KEY"
              @change="changeAspCustKey"
              :readonly="RDOnly"
            >
              <template v-slot:label>
                회사구분
                <v-icon color="#EF9191">mdi-circle-small</v-icon>
              </template>
            </v-select>
          </div>
          <!-- <div class="form-inp ml-2"> -->
            <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
              content-class="calendar-modal"
              color="#FFF"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRangeText"
                  v-bind="attrs"
                  v-on="on"
                  readonly
                  outlined
                  hide-details
                  class="form-inp ml-2 icon-calendar"
                  append-icon="svg-calendar"
                ></v-text-field>
              </template>
              <div class="datepicker-range">
                <v-date-picker
                  v-model="dates[0]"
                  no-title
                  dark
                  color="#F7AFAE"
                  @change="startDate"
                  :first-day-of-week="1"
                  locale="ko-KR"
                  :day-format="mixin_getDate"
                ></v-date-picker>
                <v-date-picker
                  v-model="dates[1]"
                  dark
                  color="#F7AFAE"
                  no-title
                  show-current="false"
                  :min="dates[0]"
                  @change="endDate"
                  :first-day-of-week="1"
                  locale="ko-KR"
                  :day-format="mixin_getDate"
                ></v-date-picker>
              </div>
              <div class="text-center pt-3 pb-3">
                <v-btn
                  outlined
                  class="btn-default"
                  @click="menu1 = false"
                  >확인</v-btn
                >
              </div>
            </v-menu>
          <!-- </div> -->
          <div class="form-inp sm ml-2">
            <v-text-field
              class="form-inp"
              name="name"
              label="사용자ID"
              id="id"
              outlined
              hide-details
              v-model="formData.USER_ID"
            >
            </v-text-field>
          </div>
          <div class="form-inp sm ml-2">
            <v-text-field
              class="form-inp"
              name="name"
              label="이름"
              id="id"
              outlined
              hide-details
              v-model="formData.USER_NM"
            >
            </v-text-field>
          </div>
          <div class="form-inp sm ml-2">
            <v-text-field
              class="form-inp"
              name="name"
              label="닉네임"
              id="id"
              outlined
              hide-details
              v-model="formData.USER_NICK"
            >
            </v-text-field>
          </div>
          <div class="form-inp sm ml-2">
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnPopupUser')" outlined small class="btn-point" @click="[setUser(), showDialog('MP02')]">
              <v-icon small>mdi-magnify</v-icon>
            </v-btn>
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnInitUser')" outlined small class="btn-point ml-2" @click="clearData">
              <v-icon small>mdi-sync</v-icon>
            </v-btn>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              v-model="formData.USER_STATUS_CD"
              :items="mixin_common_code_get(this.common_code, 'TALK030','전체')"
              label="이석상태"
              outlined
              filled
              dense
              background-color="white"
            >
              <template v-slot:label> 이석상태 </template>
            </v-select>
          </div>
          <!-- 고객정보 모달창 -->
          <div>
            <v-dialog
              max-width="1200"
              max-height="1200"
              v-model="DialogMP02"
              content-class="square-modal"
            >
              <dialog-MP02
                  headerTitle="고객정보"
                  @hide="hideDialog('MP02')"
                  :userInfo="userInfo"
                  @input="userInfo = $event"
                  @selectUser="[hideDialog('MP02'), setUserData()]"
              >
              </dialog-MP02>
              <template v-slot:body>

              </template>
            </v-dialog>
          </div>
          <!-- 고객정보 모달창 -->
        </div>
      </div>
      <div>
        <div class="box-ct d-flex">
          <div class="form-inp">
            <v-select
              :items="USER_ATTR_A"
              item-text="CD_NM"
              item-value="CD"
              outlined
              hide-details
              label="소속A"
              placeholder="전체"
              v-model="formData.USER_ATTR_A"
            >
              <template v-slot:label> 소속A </template>
            </v-select>
          </div>
          <div class="form-inp ml-2">
            <v-select
              :items="USER_ATTR_B"
              item-text="CD_NM"
              item-value="CD"
              outlined
              hide-details
              label="소속B"
              placeholder="전체"
              v-model="formData.USER_ATTR_B"
            >
              <template v-slot:label> 소속B </template>
            </v-select>
          </div>
          <div class="form-inp ml-2">
            <v-select
              :items="USER_ATTR_C"
              item-text="CD_NM"
              item-value="CD"
              outlined
              hide-details
              label="소속C"
              placeholder="전체"
              v-model="formData.USER_ATTR_C"
            >
              <template v-slot:label> 소속C </template>
            </v-select>
          </div>
          <div class="form-inp ml-2">
            <v-select
              :items="USER_ATTR_D"
              item-text="CD_NM"
              item-value="CD"
              outlined
              hide-details
              label="소속D"
              placeholder="전체"
              v-model="formData.USER_ATTR_D"
            >
              <template v-slot:label> 소속D </template>
            </v-select>
          </div>
          <div class="ml-auto align-baseline">
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')" outlined small class="btn-etc2" @click="selectRtnCnslMgmt"
              >조회</v-btn
            >
          </div>
        </div>
      </div>
    </div>

    <!-- 상담사 이석상태목록 시작-->
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">상담사 이석상태목록</h2>
      <div class="box-ct">
        <v-data-table
          dense
          :headers="gridDataHeaders"
          :items="gridDataText"
          id="divGrid01"
          height="450px"
          :items-per-page="30"
          item-key="index"
          :page.sync="page"
          hide-default-footer
          class="grid-default"
          @page-count="pageCount = $event"
          @click:row="rowClick"
          :item-class="isActiveRow"
        >
        </v-data-table>
        <div class="grid-paging text-center pt-2">
          <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="totalVisible"
            next-icon="svg-paging-next"
            prev-icon="svg-paging-prev"
          ></v-pagination>
        </div>
      </div>
    </div>
    <!-- 상담사 이석상태목록 끝  -->
  </div>
</template>

<script>
import api from "../../store/apiUtil.js";
import { mixin } from "@/mixin/mixin.js";
import { mapGetters } from "vuex";
import DialogMP02 from "./MP02.vue"
export default {
  name: "MENU_M010203", //name은 'MENU_' + 파일명 조합
  mixins: [mixin],
  components:{
    DialogMP02
  },
  data: () => ({
    DialogMP02: false,
    userInfo:{},
    common_code: [],
    formData:{
      USER_ID : "",
      USER_NM : "",
      USER_NICK : "",
      USER_STATUS_CD : "",
      USER_ATTR_A : [],
      USER_ATTR_B : [],
      USER_ATTR_C : [],
      USER_ATTR_D : [],
      ASP_NEWCUST_KEY: "",
    },
    USER_ATTR_A: [],
    USER_ATTR_B: [],
    USER_ATTR_C: [],
    USER_ATTR_D: [],
    menu1: false,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    dates: [
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    ],
    gridDataHeaders: [
      { text: "No", align: "center", value: "index", width: "50px", sortable: false },
      { text: "날짜", value: "REG_DATE", align: "center", width: "100px", sortable: false },
      { text: "사용자ID", value: "USER_ID", align: "center", width: "100px", sortable: false },
      { text: "사용자명", value: "USER_NM", align: "center", width: "100px", sortable: false },
      { text: "닉네임", value: "USER_NICK", align: "center", width: "100px", sortable: false },
      { text: "소속A", value: "ATTR_DIV_NM_A", align: "center", width: "100px", sortable: false },
      { text: "소속B", value: "ATTR_DIV_NM_B", align: "center", width: "100px", sortable: false },
      { text: "소속C", value: "ATTR_DIV_NM_C", align: "center", width: "100px", sortable: false },
      { text: "소속D", value: "ATTR_DIV_NM_D", align: "center", width: "100px", sortable: false },
      { text: "이석상태", value: "USER_STATUS_CD", align: "center", width: "100px", sortable: false },
      { text: "이석시작시간", value: "TALK_START_DT", align: "center", width: "100px", sortable: true },
      { text: "이석종료시간", value: "TALK_END_DT", align: "center", width: "100px", sortable: true },
    ],
    gridDataText: [],
    page: 1,
    pageCount: 0,
    itemsPerPage: 13,
    totalVisible: 10,

    ROW:'',
    
    RDOnly:false,
  }),
  methods: {
    selectValidation(){
      if (this.formData.ASP_NEWCUST_KEY == "" || this.formData.ASP_NEWCUST_KEY == undefined) {
        this.common_alert("회사를 먼저 선택해주세요.","error");
        return false;
      }
      return true;
    },
    showDialog(type) {
      if(!this.selectValidation()) return;

      this[`Dialog${type}`] = true;
    },
    hideDialog(type) {
      this[`Dialog${type}`] = false;
    },
    setUser(){
      let user = {
        ASP_CUST_KEY : this.formData.ASP_NEWCUST_KEY,
        USER_ID: this.formData.USER_ID,
        USER_NICK:this.formData.USER_NICK,
        USER_NM:this.formData.USER_NM,
      };
      this.userInfo = user;
    },
    setUserData(){
      this.formData.USER_ID = this.userInfo.USER_ID;
      this.formData.USER_NM = this.userInfo.USER_NM;
      this.formData.USER_NICK = this.userInfo.USER_NICK;
    },
    startDate(e) {
      this.dates[0] = e;
    },
    endDate(e) {
      this.dates[1] = e;
    },
    async selectRtnCnslMgmt() {
      if(!this.selectValidation()) return;

      let URLData = "/api/chat/status/agent-away-history/list";
      let HEADER_SERVICE = "chat.status.agent-away-history";
      let HEADER_METHOD = "list";
      let HEADER_TYPE = "BIZ_SERVICE";
      let HEADER_ROW_CNT = 50;
      let HEADER_PAGES_CNT = 1;

      let jsonData = this.formData;
      jsonData.SEARCH_DATE_FROM = this.dates[0].replace(/-/gi, "");
      jsonData.SEARCH_DATE_TO = this.dates[1].replace(/-/gi, "");
      jsonData.ASP_CUST_KEY = this.formData.ASP_NEWCUST_KEY;

      //이 작업 안할시 Observer 객체가 들어가서 데이터 미선택시 조회가 안됨..
      jsonData.USER_ATTR_A = this.formData.USER_ATTR_A.length == 0 ? "" : this.formData.USER_ATTR_A;
      jsonData.USER_ATTR_B = this.formData.USER_ATTR_B.length == 0 ? "" : this.formData.USER_ATTR_B;
      jsonData.USER_ATTR_C = this.formData.USER_ATTR_C.length == 0 ? "" : this.formData.USER_ATTR_C;
      jsonData.USER_ATTR_D = this.formData.USER_ATTR_D.length == 0 ? "" : this.formData.USER_ATTR_D;

      await api
        .post(URLData, jsonData, {
          head: {
            SERVICE: HEADER_SERVICE,
            METHOD: HEADER_METHOD,
            TYPE: HEADER_TYPE,
            ROW_CNT: HEADER_ROW_CNT,
            PAGES_CNT: HEADER_PAGES_CNT,
            ASYNC: false,
          },
        })
        .then((response) => {
          const jsonObj = JSON.parse(JSON.stringify(response.data.DATA));
          for (let i = 0; i < jsonObj.length; i++) {
            jsonObj[i].index = i + 1;
          }
          this.gridDataText = jsonObj;
          //최근 업데이트 날짜 갱신
        })
        .catch((err) => {
          alert(err);
        });
    },
    async changeAspCustKey(){
      let aspNewcustKey = this.formData.ASP_NEWCUST_KEY;
      //회사구분 변경시 소속 콤보박스에 selected 된것 초기화

      this.USER_ATTR_A = await this.mixin_attr_get(aspNewcustKey, "A","전체");
      this.USER_ATTR_B = await this.mixin_attr_get(aspNewcustKey, "B","전체");
      this.USER_ATTR_C = await this.mixin_attr_get(aspNewcustKey, "C","전체");
      this.USER_ATTR_D = await this.mixin_attr_get(aspNewcustKey, "D","전체");

      this.formData.USER_ATTR_A = "";
      this.formData.USER_ATTR_B = "";
      this.formData.USER_ATTR_C = "";
      this.formData.USER_ATTR_D = "";
    },
    clearData(){
      this.formData.USER_ID = '';
      this.formData.USER_NM = '';
      this.formData.USER_NICK = '';
    },

    rowClick (item) {
      console.log("item", item);
      this.ROW = item.index;

    },

    isActiveRow(item){
      const activeClass = item.index === this.ROW ? 'active' : '';
      return activeClass;
    },
  },
  computed: {
    dateRangeText() {
      var newStartDate = this.dates[0];
      var newEndDate = this.dates[1];
      return newStartDate + " ~ " + newEndDate;
    },
    ...mapGetters({
      //store에 저장된 사용자의 company 정보를 가져온다
      aspCustList: "userStore/GE_USER_COMPANY",
    }),
  },
  async mounted(){
    let codeName = ["TALK030","TALK001"];
    this.common_code = await this.mixin_common_code_get_all(codeName);
    this.changeAspCustKey();

    let chk = this.mixin_check_AspCustKey_number();
    if(chk == 'Y'){
      this.formData.ASP_NEWCUST_KEY = this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD;
      this.RDOnly = true;
    } else {
      this.RDOnly = false;
    }
  }
};
</script>

<style>
</style>