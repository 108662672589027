<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">상담중 현황 및 3자 채팅</h1>
    <!-- 조회 폼 -->
    <div class="box-wrap d-flex">
      <div class="box-ct">
        <div>
          <div class="form-inp sm">
            <v-select
              :items="aspCustList"
              item-text="CD_NM"
              item-value="CD"
              outlined
              hide-details
              label="회사구분"
              placeholder="선택하세요"
              v-model="formData.ASP_NEWCUST_KEY"
              @change="changeAspCustKey"
              :readonly="RDOnly"
            >
              <template v-slot:label>
                회사구분
                <v-icon color="#EF9191">mdi-circle-small</v-icon>
              </template>
            </v-select>
          </div>
          <v-text-field
            class="form-inp sm ml-2"
            name="name"
            label="사용자ID"
            id="id"
            outlined
            hide-details
            v-model="formData.USER_ID"
          >
          </v-text-field>
          <v-text-field
            class="form-inp sm ml-2"
            name="name"
            label="이름"
            id="id"
            outlined
            hide-details
            v-model="formData.USER_NM"
          >
          </v-text-field>
          <v-text-field
            class="form-inp sm ml-2"
            name="name"
            label="닉네임"
            id="id"
            outlined
            hide-details
            v-model="formData.USER_NICK"
          >
          </v-text-field>
          <!-- 조회 버튼 -->
          <v-btn
            dark
            small
            class="btn-main-search pa-0 ml-2"
            min-width="32px"
            height="32px"
            plain
            @click="[setUser(), showDialog('MP02')]"
            v-if="this.mixin_set_btn(this.$options.name, 'btnPopupUser')"
          >
            <v-icon small class="svg-main-searh white"></v-icon>
          </v-btn>
          <!-- 새로고침 버튼 -->
          <v-btn
            v-if="this.mixin_set_btn(this.$options.name, 'btnInitUser')"
            dark
            small
            class="btn-main-refresh pa-0 ml-2"
            min-width="32px"
            height="32px"
            @click="clearData"
            plain
          >
            <v-icon small class="svg-main-refresh"></v-icon>
          </v-btn>

          <div class="form-inp sm ml-2">
            <v-select
              :items="USER_ATTR_A"
              item-text="CD_NM"
              item-value="CD"
              outlined
              hide-details
              label="소속A"
              placeholder="전체"
              v-model="formData.USER_ATTR_A"
            >
              <template v-slot:label> 소속A </template>
            </v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              :items="USER_ATTR_B"
              item-text="CD_NM"
              item-value="CD"
              outlined
              hide-details
              label="소속B"
              placeholder="전체"
              v-model="formData.USER_ATTR_B"
            >
              <template v-slot:label> 소속B </template>
            </v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              :items="USER_ATTR_C"
              item-text="CD_NM"
              item-value="CD"
              outlined
              hide-details
              label="소속C"
              placeholder="전체"
              v-model="formData.USER_ATTR_C"
            >
              <template v-slot:label> 소속C </template>
            </v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              :items="USER_ATTR_D"
              item-text="CD_NM"
              item-value="CD"
              outlined
              hide-details
              label="소속D"
              placeholder="전체"
              v-model="formData.USER_ATTR_D"
            >
              <template v-slot:label> 소속D </template>
            </v-select>
          </div>
        </div>
      </div>
      <div class="ml-auto align-self-center">
        <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')" outlined class="btn-etc2" @click="selectRtnCnslMgmt">조회</v-btn>
      </div>
    </div>
    <!-- 상담현황목록 -->
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">상담현황목록</h2>
      <!-- 개발전달 : 임시 채팅내역 버튼 -->
      <v-btn outlined small class="btn-point ml-2" @click="showDialog('Chat')">카카오톡</v-btn>
      <!-- 채팅내역 모달 -->
      <v-dialog
        max-width="655"
        v-model="DialogChat"
        content-class="square-modal modal-white">
        <dialog-Chat headerTitle="채팅내역" @hide="hideDialog('Chat')" HAS_BTN></dialog-Chat>
        <template v-slot:body></template>
      </v-dialog>

      <div class="box-ct">
        <v-data-table
          dense
          height="539px"
          :headers="gridDataHeaders"
          :items="gridDataText"
          :items-per-page="30"
          item-key="index"
          :page.sync="page"
          hide-default-header
          hide-default-footer
          class="grid-default mt-6"
          @page-count="pageCount = $event"
          no-data-text="검색된 결과가 없습니다."
        >
          <template v-slot:header>
            <thead class="v-data-table-header">
              <tr>
                <th scope="row" rowspan="2" class="text-center">NO</th>
                <th scope="row" rowspan="2" class="text-center">사용자ID</th>
                <th scope="row" rowspan="2" class="text-center">사용자명</th>
                <th scope="row" rowspan="2" class="text-center">
                  사용자닉네임
                </th>
                <th scope="row" rowspan="2" class="text-center">처리상태</th>
                <th scope="row" rowspan="2" class="text-center">상담톡</th>
                <th scope="row" rowspan="2" class="text-center">상담ID</th>
                <th scope="row" colspan="3" class="text-center">고객정보</th>
                <th scope="row" rowspan="2" class="text-center">
                  3자채팅
                </th>
                <th scope="row" rowspan="2" class="text-center">
                  문의유형1단계
                </th>
                <th scope="row" rowspan="2" class="text-center">
                  문의유형2단계
                </th>
                <th scope="row" rowspan="2" class="text-center">
                  상담요청일시
                </th>
                <th scope="row" rowspan="2" class="text-center">대기시간</th>
                <th scope="row" rowspan="2" class="text-center">
                  상담시작일시
                </th>
              </tr>
              <tr>
                <th scope="row" class="text-center">회원/비회원</th>
                <th scope="row" class="text-center">API ID</th>
                <th scope="row" class="text-center">UID</th>
              </tr>
            </thead>
          </template>
          <template v-slot:item.THIRD_CHAT="{ item }">
            <!--
              버튼권한을 줄 수 없음
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnHear')" outlined small class="btn-point ml-2">녹취</v-btn>
            -->
            <v-btn outlined small class="btn-point ml-2">3자채팅</v-btn>
          </template>
        </v-data-table>
        <div class="grid-paging text-center pt-2">
          <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="totalVisible"
            next-icon="svg-paging-next"
            prev-icon="svg-paging-prev"
          ></v-pagination>
        </div>
      </div>
    </div>
    <!-- 고객정보 모달창 -->
    <div>
      <v-dialog
        max-width="1400"
        max-height="1200"
        v-model="DialogMP02"
        content-class="square-modal"
      >
        <dialog-MP02
          headerTitle="고객정보"
          @hide="hideDialog('MP02')"
          :userInfo="userInfo"
          @input="userInfo = $event"
          @selectUser="[hideDialog('MP02'), setUserData()]"
        >
        </dialog-MP02>
        <template v-slot:body> </template>
      </v-dialog>
    </div>
    <!-- 고객정보 모달창 -->
  </div>
</template>

<script>
import api from "../../store/apiUtil.js";
import { mixin } from "@/mixin/mixin.js";
import { mapGetters } from "vuex";
import DialogMP02 from "./MP02.vue";
import DialogChat from "./ChatHistoryModal.vue";
export default {
  name: "MENU_M010204", //name은 'MENU_' + 파일명 조합
  mixins: [mixin],
  components: {
    DialogMP02,
    DialogChat
  },
  data: () => ({
    DialogMP02: false,
    DialogChat: false,
    userInfo: {},
    USER_ATTR_A: [],
    USER_ATTR_B: [],
    USER_ATTR_C: [],
    USER_ATTR_D: [],
    formData: {
      USER_ID: "",
      USER_NM: "",
      USER_NICK: "",
      USER_ATTR_A: "",
      USER_ATTR_B: "",
      USER_ATTR_C: "",
      USER_ATTR_D: "",
      ASP_CUST_KEY: "",
      ASP_NEWCUST_KEY: "",
    },
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    dates: [
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    ],
    dropItems: [{ title: "한국클라우드", value: "HKCTALKMNG" }],
    gridDataHeaders: [
      { text: "", value: "index", align: "center", sortable: false }, //No
      { text: "", value: "USER_ID", align: "center", sortable: false }, //사용자ID
      { text: "", value: "USER_NM", align: "center", sortable: false }, //사용자명
      { text: "", value: "USER_NICK", align: "center", sortable: false }, //사용자닉네임
      { text: "", value: "TALK_STAT_NM", align: "center", sortable: false }, //처리상태
      { text: "", value: "CNSL_SUPPORT", align: "center", sortable: false }, //상담톡
      { text: "", value: "TALK_USER_KEY", align: "center", sortable: false }, //상담ID
      { text: "", value: "CUSTOMER_MEMBER", align: "center", sortable: false }, //(하위)회원/비회원
      {
        text: "",
        value: "REFERENCE_CUSTOMER_KEY",
        align: "center",
        sortable: false,
      }, //(하위)API ID
      { text: "", value: "TID", align: "center", sortable: false }, //(하위)UID
      { text: "", value: "THIRD_CHAT", align: "center", sortable: false }, //3자채팅버튼
      { text: "", value: "TALK_INQRY_NM_1", align: "center", sortable: false }, //문의유형1단계
      { text: "", value: "TALK_INQRY_NM_2", align: "center", sortable: false }, //문의유형2단계
      { text: "", value: "TALK_DIST_DT", align: "center", sortable: false }, //상담요청일시
      { text: "", value: "WAIT_TIME", align: "center", sortable: false }, //대기시간
      { text: "", value: "TALK_START_DT", align: "center", sortable: false }, //상담시작일시
    ],
    gridDataText: [],
    page: 1,
    pageCount: 0,
    itemsPerPage: 5,
    totalVisible: 10,
    btnList: [],

    RDOnly:false,
  }),
  methods: {
    selectValidation() {
      if (
        this.formData.ASP_NEWCUST_KEY == "" ||
        this.formData.ASP_NEWCUST_KEY == undefined
      ) {
        this.common_alert("회사를 먼저 선택해주세요.", "error");
        return false;
      }
      return true;
    },
    showDialog(type) {
      if (!this.selectValidation()) return;
      this[`Dialog${type}`] = true;
    },
    hideDialog(type) {
      this[`Dialog${type}`] = false;
    },
    setUser() {
      let user = {
        ASP_CUST_KEY: this.formData.ASP_NEWCUST_KEY,
        USER_ID: this.formData.USER_ID,
        USER_NICK: this.formData.USER_NICK,
        USER_NM: this.formData.USER_NM,
      };
      this.userInfo = user;
    },
    setUserData() {
      this.formData.USER_ID = this.userInfo.USER_ID;
      this.formData.USER_NM = this.userInfo.USER_NM;
      this.formData.USER_NICK = this.userInfo.USER_NICK;
    },
    startDate(e) {
      this.dates[0] = e;
    },
    endDate(e) {
      this.dates[1] = e;
    },
    async selectRtnCnslMgmt() {
      if (!this.selectValidation()) return;
      let URLData = "api/chat/status/cnslt-list/inqire";
      let HEADER_SERVICE = "chat.status.cnslt-list";
      let HEADER_METHOD = "inqire";
      let HEADER_TYPE = "BIZ_SERVICE";
      let HEADER_ROW_CNT = 50;
      let HEADER_PAGES_CNT = 1;

      let jsonData = this.formData;

      //이 작업 안할시 Observer 객체가 들어가서 데이터 미선택시 조회가 안됨..
      jsonData.USER_ATTR_A =
        this.formData.USER_ATTR_A.length == 0 ? "" : this.formData.USER_ATTR_A;
      jsonData.USER_ATTR_B =
        this.formData.USER_ATTR_B.length == 0 ? "" : this.formData.USER_ATTR_B;
      jsonData.USER_ATTR_C =
        this.formData.USER_ATTR_C.length == 0 ? "" : this.formData.USER_ATTR_C;
      jsonData.USER_ATTR_D =
        this.formData.USER_ATTR_D.length == 0 ? "" : this.formData.USER_ATTR_D;

      await api
        .post(URLData, jsonData, {
          head: {
            SERVICE: HEADER_SERVICE,
            METHOD: HEADER_METHOD,
            TYPE: HEADER_TYPE,
            ROW_CNT: HEADER_ROW_CNT,
            PAGES_CNT: HEADER_PAGES_CNT,
          },
        })
        .then((response) => {
          if (response.data.HEADER.ERROR_FLAG == false) {
            const jsonObj = JSON.parse(JSON.stringify(response.data.DATA));
            for (let i = 0; i < jsonObj.length; i++) {
              jsonObj[i].index = i + 1;
            }
            this.gridDataText = jsonObj;
          } else {
            this.gridDataText = [];
          }
        })
        .catch((err) => {
          alert(err);
        });
    },
    async changeAspCustKey() {
      let aspNewcustKey = this.formData.ASP_NEWCUST_KEY;

      this.USER_ATTR_A = await this.mixin_attr_get(aspNewcustKey, "A", "전체");
      this.USER_ATTR_B = await this.mixin_attr_get(aspNewcustKey, "B", "전체");
      this.USER_ATTR_C = await this.mixin_attr_get(aspNewcustKey, "C", "전체");
      this.USER_ATTR_D = await this.mixin_attr_get(aspNewcustKey, "D", "전체");

      this.formData.USER_ATTR_A = "";
      this.formData.USER_ATTR_B = "";
      this.formData.USER_ATTR_C = "";
      this.formData.USER_ATTR_D = "";
    },
    clearData() {
      this.formData.USER_ID = "";
      this.formData.USER_NM = "";
      this.formData.USER_NICK = "";
    },
  },
  computed: {
    ...mapGetters({
      aspCustList: "userStore/GE_USER_COMPANY",
    }),
  },
  mounted() {
    this.changeAspCustKey();

    // this.btnList = this.mixin_set_btn(this.$options.name);
    // let keyArray = Object.keys(this.$refs);
    // if (keyArray.length > 0) {
    //   this.$refs[keyArray[0]].$el.parentNode.removeChild(
    //     this.$refs[keyArray[0]].$el
    //   );
    // }

    let chk = this.mixin_check_AspCustKey_number();
    if(chk == 'Y'){
      this.formData.ASP_NEWCUST_KEY = this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD;
      this.RDOnly = true;
    } else {
      this.RDOnly = false;
    }
  },
};
</script>

<style></style>
