<template>
  <div class="modal-wrap">
    <h1 class="tit-h1 d-flex">
      상담원 정보 변경
      <div class="ml-auto align-self-center">
        <v-icon
          x-small
          class="svg-close20"
          @click="[$emit('hide'), init()]"
        ></v-icon>
      </div>
    </h1>
    <div class="modal-box">
      <div class="csm-modal-wrap">
        <!-- 상담사 form -->
        <div class="d-flex">
          <div>
            <h2 class="tit-h2">상담원 정보</h2>
            <div class="csm-form">
              <div class="d-flex" style="margin-right: 97px">
                <v-text-field
                  class="form-inp full pt-0"
                  name="name"
                  placeholder="상담사명"
                  id="user_name"
                  outlined
                  hide-details
                  v-model="USER_NEW_NAME"
                >
                </v-text-field>
                <v-text-field
                  class="form-inp full pt-0 ml-2"
                  name="name"
                  placeholder="내선번호"
                  id="id"
                  outlined
                  hide-details
                  v-model="INLNE_NEW_NO"
                >
                </v-text-field>
              </div>
              <div class="d-flex mt-2" style="margin-right: 25px">
                <v-text-field
                  class="form-inp full pt-0"
                  type="password"
                  name="name"
                  placeholder="비밀번호"
                  id="id"
                  outlined
                  hide-details
                >
                </v-text-field>
                <!-- 변경 버튼 -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="btn-point pa-0 ml-2"
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      @click="$emit('modalPass')"
                      >변경
                    </v-btn>
                  </template>
                  <span>비밀번호 변경</span>
                </v-tooltip>
              </div>
              <div class="d-flex mt-2">
                <v-text-field
                  class="form-inp full pt-0"
                  name="cheeringMsg"
                  v-model="cheeringMsg"
                  placeholder="나의 응원 메시지 (최대 18자)"
                  outlined
                  hide-details
                  maxLength="18"
                >
                </v-text-field>
                <v-btn outlined class="btn-etc2 ml-2" v-on:click="changeMsg"
                  >등록/수정</v-btn
                >
              </div>
            </div>
          </div>
          <!-- 프로필 아이콘 -->
          <div style="margin-left: 30px">
            <h2 class="tit-h2">프로필 아이콘</h2>
            <div class="csm-profile">
              <div class="form-inp free pa-0">
                <v-select
                  :items="dropItemsProfile"
                  item-text="icon"
                  outlined
                  placeholder="프로필"
                  hide-details
                  name="profileIcon"
                  v-model="profileIcon"
                >
                  <template slot="selection" slot-scope="data">
                    <v-icon :class="[data.item.icon]"></v-icon>
                    <div
                      class="v-select__selection v-select__selection--comma"
                    ></div>
                  </template>
                  <template slot="item" slot-scope="data">
                    <v-icon :class="[data.item.icon]"></v-icon>
                    <div
                      class="v-list-item__title"
                      style="flex-basis: auto"
                    ></div>
                  </template>
                </v-select>
              </div>

              <!-- 변경 버튼 -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="btn-point pa-0 mt-2"
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    width="62px"
                    @click="changeIcon"
                    >변경
                  </v-btn>
                </template>
                <span>아이콘 변경</span>
              </v-tooltip>
            </div>
          </div>
        </div>
        <!-- 기념일 -->
        <div class="mt-3">
          <div class="calendar-wrap d-flex">
            <div>
              <div class="calendar-modal">
                <v-date-picker
                  v-model="calendar"
                  :events="arrayEventsCalendar"
                  :event-color="FnArrayEventColor"
                  full-width
                  color="#F7AFAE"
                  @click:date="checkDate"
                  dark
                  locale="ko-KR"
                  :first-day-of-week="1"
                  :day-format="getDay"
                  @update:picker-date="updatePickedMonth"
                >
                </v-date-picker>
              </div>
              <!-- 기념일 등록 -->
              <div class="mt-3">
                <div class="d-flex">
                  <div class="form-inp sm">
                    <v-text-field
                      v-model="date"
                      outlined
                      hide-details
                      readonly
                    >
                    </v-text-field>
                  </div>
                  <div class="form-inp sm ml-2">
                    <v-select
                      :items="dropItems"
                      item-text="title"
                      outlined
                      placeholder="선택하세요"
                      hide-details
                      name="daysl"
                      v-model="daysl"
                    >
                      <template slot="selection" slot-scope="data">
                        <v-icon :class="[data.item.icon]"></v-icon>
                        <div
                          class="v-select__selection v-select__selection--comma ml-1"
                        >
                          {{ data.item.title }}
                        </div>
                      </template>
                      <template slot="item" slot-scope="data">
                        <v-icon :class="[data.item.icon]"></v-icon>
                        <div
                          class="v-list-item__title ml-1"
                          style="flex-basis: auto"
                        >
                          {{ data.item.title }}
                        </div>
                      </template>
                    </v-select>
                  </div>
                </div>
                <div>
                  <v-text-field
                    class="form-inp full"
                    name="sub_title"
                    v-model="sub_title"
                    placeholder="제목 입력"
                    outlined
                    hide-details
                  >
                  </v-text-field>
                </div>
                <div class="mt-2">
                  <v-textarea
                    no-resize
                    name="sub_content"
                    v-model="sub_content"
                    outlined
                    rows="3"
                    :rules="textareaRules"
                    hide-details
                    value="내용을 입력하세요."
                  ></v-textarea>
                  <div class="mt-2">
                    <v-btn outlined class="btn-etc2" @click="scheduleReg"
                      >등록/수정</v-btn
                    >
                    <v-btn outlined class="btn-etc ml-2" @click="scheduleDel"
                      >삭제</v-btn
                    >
                  </div>
                </div>
              </div>
            </div>
            <!-- 기념일 목록 -->
            <div class="timeline-wrap">
              <v-timeline align-top dense>
                <v-timeline-item
                  small
                  v-for="(item, index) in arrayEvents"
                  :key="item.id"
                  :class="[
                    item.type === 'schedule' ? 'type-schedule' : 'type-evt',
                  ]"
                >
                  <v-row class="pt-1">
                    <v-col cols="3">
                      <strong
                        >{{ daySplit(item.day) }}
                        <span class="text-caption">일</span></strong
                      >
                    </v-col>
                    <v-col>
                      <strong> {{ item.titleLS }} </strong>
                      <div class="text-caption">
                        {{ item.desc }}
                      </div>
                    </v-col>
                  </v-row>
                </v-timeline-item>
              </v-timeline>
            </div>
            <!-- <div class="calendar-evt-wrap ml-2">
              <div v-for="(item, index) in arrayEvents" :key="item.id" :class="index !== 0 ? 'mt-2' : null">
                <div class="calendar-evt">
                  <div class="calendar-evt-title" :class="[item.type === 'schedule' ? 'type-schedule' : 'type-evt' ]">
                    <span class="calendar-evt-day">{{ daySplit(item.day) }} <span class="text-caption">일</span></span>
                  </div>

                  <v-btn outlined class="btn-default">
                    <v-icon :class="[ item.icon ]" ></v-icon>
                    <span class="calendar-evt-day">{{ item.title  }} <span class="text-caption"></span></span>
                  </v-btn>

                  <div class="mt-2">
                    <v-text-field
                      class="form-inp full pt-0"
                      outlined
                      :value="item.titleLS"
                      hide-details
                      >
                    </v-text-field>
                  </div>
                  <div class="calendar-evt-desc">
                    <v-textarea
                      no-resize
                      outlined
                      class="textarea"
                      background-color="white"
                      :value="item.desc"
                    ></v-textarea>

                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <div></div>
    </div>
  </div>
</template>

<script>
import gibberish from "@/mixin/gibberish-aes_mixin";
import api from "@/store/apiUtil";
import { mixin } from "../../mixin/mixin";
import DialogTopArea from "../../layouts/default/TopArea.vue";

export default {
  name: "MENU_TopAreaP01", //name은 'MENU_' + 파일명 조합
  mixins: [mixin, gibberish],
  props: {
    param: {
      type: Object,
    },
    agentInfo: {
      type: Object,
    },
  },
  data() {
    return {
      //USER_ID : this.param.test,
      //agentInfo : "",
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      dropItemsProfile: [
        {
          icon: "svg-profile f01",
        },
        {
          icon: "svg-profile f02",
        },
        {
          icon: "svg-profile f03",
        },
        {
          icon: "svg-profile f04",
        },
        {
          icon: "svg-profile m01",
        },
        {
          icon: "svg-profile m02",
        },
        {
          icon: "svg-profile m03",
        },
        {
          icon: "svg-profile m04",
        },
      ],
      dropItemsProfileM: [
        {
          icon: "svg-profile m01",
        },
        {
          icon: "svg-profile m02",
        },
        {
          icon: "svg-profile m03",
        },
        {
          icon: "svg-profile m04",
        },
      ],
      dropItems: [
        {
          title: "기념일",
          icon: "svg-dot-evt",
        },
        {
          title: "스케쥴",
          icon: "svg-dot-sch",
        },
      ],
      textareaRules: [(v) => v.length <= 100 || "최대 100자 제한"],
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      timeStep: "10:10",
      calendar: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      arrayEvents: [],
      //arrayEventsCalendar: null,
      arrayEventDetail: null,
      arrayEventColor: null,
      radioGroup: [
        {
          text: "1차",
        },
        {
          text: "2차",
        },
        {
          text: "3차",
        },
      ],
      radioGroupSelected: "1차",

      INLNE_NEW_NO: this.$store.getters["userStore/GE_USER_ROLE"].INLNE_NO,
      USER_NEW_NAME: this.$store.getters["userStore/GE_USER_ROLE"].userName,
      USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
      month: "",
      nowDate: "",
      sub_title: "",
      sub_content: "",
      cheeringMsg: this.$store.getters["userStore/GE_USER_TOPMSG"],
      daysl: "",
      daysl2: "",
      responseCheck: "",
      profileIcon: "",
      titleSE: "",

      pickerDate:null,
      initCNT:0,
    };
  },
  methods: {
    checkDate() {
      //alert(this.calendar);
      //alert("111111111");
      this.arrayEvents = [];
      this.date = this.calendar;
      this.calendarClick();
      this.calendarCheck();
    },

    changeIcon() {
      const USER_ID = this.USER_ID;
      const ICON = this.profileIcon.substr(12, 15);

      api
        .post(
          "api/topicon/update",
          {
            USER_ID: USER_ID,
            ICON: ICON,
          },
          {
            head: {
              SERVICE: "api/topicon",
              METHOD: "/update",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          this.common_alert("프로필 아이콘이 수정되었습니다.");
          this.btnRegIcon();
        });
    },

    changeMsg() {
      const USER_ID = this.USER_ID;
      const TOPMSG = this.cheeringMsg;

      api
        .post(
          "api/topmsg/update",
          {
            USER_ID: USER_ID,
            TOPMSG: TOPMSG,
          },
          {
            head: {
              SERVICE: "api/topmsg",
              METHOD: "/update",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          this.common_alert("응원메세지가 수정 또는 등록되었습니다..");
          this.btnRegi();
        });
    },

    //test 스케줄 목록조회
    calendarClick() {
      console.log("click");
      const USER_ID = this.USER_ID;
      const USER_DATE = this.nowDate.replace(/\-/g, "");
      //alert(USER_DATE);
      //alert(USER_DATE.substr(6,7));
      const FIRST_USER_DATE = USER_DATE.substr(0, 6) + "00";
      //alert(FIRST_USER_DATE);
      const LAST_USER_DATE = USER_DATE.substr(0, 6) + "99";
      //alert(LAST_USER_DATE);
      this.arrayEvents = [];
      api
        .post(
          "api/schedule/list",
          {
            USER_ID: USER_ID,
            FIRST_USER_DATE: FIRST_USER_DATE,
            LAST_USER_DATE: LAST_USER_DATE,
          },
          {
            head: {
              SERVICE: "api/schedule",
              METHOD: "/list",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          console.log("<<<",response.data);
          console.log(">>>",response.data.DATA.length);
          this.initCNT = 1;
          for (let index = 0; index < response.data.DATA.length; index++) {
            var dataList = {};
            var arrCal = [];
            var arrColor = [];
            dataList.id = index;
            //dataList.day = response.data.DATA[index]['USER_DATE'].substr(0,3)+"-"+response.data.DATA[index]['USER_DATE'].substr(4,5)+"-"+response.data.DATA[index]['USER_DATE'].substr(6,7)
            dataList.day = response.data.DATA[index]["USER_DATE"].replace(
              /([0-9]{4})([0-9]+)([0-9]{2})/,
              "$1-$2-$3"
            );
            dataList.titleLS = response.data.DATA[index]["TITLE"];
            dataList.desc = response.data.DATA[index]["CONTENT"];
            if (response.data.DATA[index]["SELECT_DAY"] == "기념일") {
              dataList.type = "evt";
              dataList.title = response.data.DATA[index]["SELECT_DAY"];
              dataList.icon = "svg-dot-evt";
            } else {
              dataList.type = "schedule";
              dataList.title = response.data.DATA[index]["SELECT_DAY"];
              dataList.icon = "svg-dot-sch";
            }

            this.arrayEvents.push(dataList);
            this.arrayEventsCalendar(this.arrayEvents[index].day);
          }
        });
    },

    //test 스케줄 목록조회
    FirstCalendarClick() {
      const USER_ID = this.USER_ID;
      const USER_DATE = this.nowDate.replace(/\-/g, "");
      const FIRST_USER_DATE = USER_DATE.substr(0, 6) + "00";
      const LAST_USER_DATE = USER_DATE.substr(0, 6) + "99";
      this.arrayEvents = [];
      api
        .post(
          "api/schedule/list",
          {
            USER_ID: USER_ID,
            FIRST_USER_DATE: FIRST_USER_DATE,
            LAST_USER_DATE: LAST_USER_DATE,
          },
          {
            head: {
              SERVICE: "api/schedule",
              METHOD: "/list",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          console.log(response.data.DATA.length);
          this.initCNT = 1;
          for (let index = 0; index < response.data.DATA.length; index++) {
            var dataList = {};
            var arrCal = [];
            var arrColor = [];
            dataList.id = index;
            //dataList.day = response.data.DATA[index]['USER_DATE'].substr(0,3)+"-"+response.data.DATA[index]['USER_DATE'].substr(4,5)+"-"+response.data.DATA[index]['USER_DATE'].substr(6,7)
            dataList.day = response.data.DATA[index]["USER_DATE"].replace(
              /([0-9]{4})([0-9]+)([0-9]{2})/,
              "$1-$2-$3"
            );
            dataList.titleLS = response.data.DATA[index]["TITLE"];
            dataList.desc = response.data.DATA[index]["CONTENT"];
            if (response.data.DATA[index]["SELECT_DAY"] == "기념일") {
              dataList.type = "evt";
              dataList.title = response.data.DATA[index]["SELECT_DAY"];
              dataList.icon = "svg-dot-evt";
            } else {
              dataList.type = "schedule";
              dataList.title = response.data.DATA[index]["SELECT_DAY"];
              dataList.icon = "svg-dot-sch";
            }

            this.arrayEvents.push(dataList);
            this.arrayEventsCalendar(this.arrayEvents[index].day);
          }
        });
    },

    searchData() {
      //alert(this.USER_ID);
      //alert(this.month); 현재 날짜 달
      //alert(this.nowDate); 현재 날짜
      INLNE_NEW_NO.value = this.INLNE_NO;
      USER_NEW_NAME.valText = this.USER_NAME;

      const USER_ID = this.USER_ID;
      const USER_DATE = "20220325";

      api
        .post("/api/schedule/list", {
          USER_ID: USER_ID,
          USER_DATE: USER_DATE,
        })
        .then((response) => {
          console.log(response.data);
          console.log(response.data.DATA);
        });
    },

    scheduleReg() {
      if (this.date == "" || this.date == undefined) {
        this.common_alert("날짜를 선택해주세요.", "error");
        return;
      }

      if (this.daysl == "" || this.daysl == undefined) {
        this.common_alert("기념일 또는 스케즐을 선택해주세요.", "error");
        return;
      }
      //alert(this.calendar);//달력에 표시
      //alert(this.date);//밑에 날짜.
      var responseCheck = "";
      this.calendarCheck();
      console.log(this.responseCheck);
      if (this.responseCheck == "false") {
        //등록 api
        const USER_ID = this.USER_ID;
        const USER_DATE = this.date.replace(/\-/g, "");
        const SELECT_DAY = this.daysl;
        const TITLE = this.sub_title;
        const CONTENT = this.sub_content;

        api
          .post(
            "api/schedule/insert",
            {
              USER_ID: USER_ID,
              USER_DATE: USER_DATE,
              SELECT_DAY: SELECT_DAY,
              TITLE: TITLE,
              CONTENT: CONTENT,
            },
            {
              head: {
                SERVICE: "api/schedule",
                METHOD: "/insert",
                TYPE: "BIZ_SERVICE",
              },
            }
          )
          .then((response) => {
            this.$store.getters['userStore/GE_USER_ROLE'].SCHEDULE = 1;
            console.log(response.data);
            this.common_alert("스케줄이 등록되었습니다.");
            this.calendarCheck();
            this.calendarClick();
          });
      } else {
        // 수정 api
        const USER_ID = this.USER_ID;
        const USER_DATE = this.date.replace(/\-/g, "");
        const SELECT_DAY = this.daysl;
        const TITLE = this.sub_title;
        const CONTENT = this.sub_content;

        api
          .post(
            "api/schedule/update",
            {
              USER_ID: USER_ID,
              USER_DATE: USER_DATE,
              SELECT_DAY: SELECT_DAY,
              TITLE: TITLE,
              CONTENT: CONTENT,
            },
            {
              head: {
                SERVICE: "api/schedule",
                METHOD: "/update",
                TYPE: "BIZ_SERVICE",
              },
            }
          )
          .then((response) => {
            console.log(response.data);
            this.$store.getters['userStore/GE_USER_ROLE'].SCHEDULE = 1;
            this.common_alert("스케줄이 수정되었습니다.");
            this.calendarCheck();
            this.calendarClick();
          });
      }
    },
    calendarCheck() {
      const USER_ID = this.USER_ID;
      const USER_DATE = this.date.replace(/\-/g, "");
      var responseCheck = "";
      api
        .post(
          "api/schedule/select",
          {
            USER_ID: USER_ID,
            USER_DATE: USER_DATE,
          },
          {
            head: {
              SERVICE: "api/schedule",
              METHOD: "/select",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          if (response.data.DATA == "") {
            this.daysl = "";
            this.sub_title = "";
            this.sub_content = "";
            this.responseCheck = "false";
          } else {
            this.daysl = response.data.DATA[0]["SELECT_DAY"];
            this.sub_title = response.data.DATA[0]["TITLE"];
            this.sub_content = response.data.DATA[0]["CONTENT"];
            this.responseCheck = "true";
          }
          //console.log(responseCheck);
        });
    },

    scheduleDel() {
      var responseCheck = "";
      this.calendarCheck();
      console.log(this.responseCheck);
      if (this.responseCheck == "false") {
        this.common_alert("삭제할 스케줄이 없습니다.");
      } else {
        // 삭제 api
        const USER_ID = this.USER_ID;
        const USER_DATE = this.date.replace(/\-/g, "");
        //const SELECT_DAY = this.daysl;
        //const TITLE = this.sub_title;
        //const CONTENT = this.sub_content;

        api
          .post(
            "api/schedule/delete",
            {
              USER_ID: USER_ID,
              USER_DATE: USER_DATE,
              //SELECT_DAY: SELECT_DAY,
              //TITLE: TITLE,
              //CONTENT: CONTENT,
            },
            {
              head: {
                SERVICE: "api/schedule",
                METHOD: "/delete",
                TYPE: "BIZ_SERVICE",
              },
            }
          )
          .then((response) => {
            console.log(response.data);
            this.$store.getters['userStore/GE_USER_ROLE'].SCHEDULE = 1;
            this.common_alert("스케줄이 삭제되었습니다.");
            this.calendarCheck();
            this.calendarClick();
          });
      }
    },

    FnArrayEventColor(e) {
      const index = this.arrayEvents.findIndex(function (item) {
        return item.day === e;
      });

      if (index < 0) return;

      return this.arrayEventColor[index];
    },

    setColor() {
      var allDates = document.querySelectorAll(
        ".csm-modal-wrap .v-date-picker-table .v-btn .v-date-picker-table__events"
      );
      var dates = this.arrayEvents;
      dates.forEach((e, index) => {
        //dates[index].type == 'schedule' ? allDates[index].classList.add('schedule') : allDates[index].classList.add('evt');
        console.log(index, allDates[index], dates[index].type);
      });
    },

    evtCreate() {
      var arrCal = [];
      var arrColor = [];
      for (let index = 0; index < this.arrayEvents.length; index++) {
        var element = this.arrayEvents[index].day;
        var element2 =
          this.arrayEvents[index].type === "schedule" ? "#FFF" : "#F7AFAE";

        arrCal[arrCal.length] = element;
        arrColor[arrColor.length] = element2;
      }
      this.arrayEventsCalendar = arrCal;
      this.arrayEventColor = arrColor;
      console.log(arrCal);
    },

    daySplit(e) {
      const day = e.split("-")[2];
      return day;
    },

    //스케쥴 달력에 포인트 찍기
    arrayEventsCalendar(date) {
      let colorArray = [];

      for (let i = 0; i < this.arrayEvents.length; i++) {
        if (this.arrayEvents[i].day === date) {
          if (this.arrayEvents[i].type === "schedule") {
            colorArray.push("#6DC4B1");
          } else if (this.arrayEvents[i].type === "evt") {
            colorArray.push("#F7B611");
          }
        }
      }
      // const [,, day] = date.split('-')
      // if ([12, 17, 28].includes(parseInt(day, 10))) return true
      // if ([1, 19, 22].includes(parseInt(day, 10))) return ['red', '#00f']
      return colorArray;
    },

    getDay(date) {
      const day = date.split("-")[2];
      return Number(day);
    },

    //암호화
    common_aesEncrypt(strPassWD, strEncryptKey) {
      return gibberish.aesEncrypt(strPassWD, strEncryptKey);
    },

    async passwordProcess(DATA_FLAG) {
      console.log("여기가 2번이냐?");
      let requestData = {
        headers: {
          SERVICE: this.initHeaders.SERVICE,
          METHOD: this.initHeaders.METHOD,
          TYPE: this.initHeaders.TYPE,
        },
        sendData: {},
      };

      // header 세팅
      requestData.headers["SERVICE"] = "login/password-encpt";
      requestData.headers["URL"] = "/api/login/password-encpt/process";
      requestData.headers["METHOD"] = "process";
      //requestData.headers["MD_KEY_LIST"] = "NO_KEY_LIST";
      requestData.headers["DATA_FLAG"] = DATA_FLAG;
      requestData.headers["passwordProcess"] = "passwordProcess";

      this.result(requestData);
    },
    passwordProcessCallBack(response) {
      let header = response.HEADER;
      let DATA_FLAG = header.DATA_FLAG;
      let strEncryptKey = response.DATA[0].ENCRYPT_KEY;
      let strPassWD = "";
      if (strEncryptKey === "") {
        return;
      }

      //생성된 암호화 키 정보를 사용하여 ID와 password필드값을 AES 256byte 알고리즘을 이용하여 암호화한다.
      if (DATA_FLAG == "I") {
        strPassWD = this.USER_ID;
      } else {
        strPassWD = this.detailList.USER_ID;
      }
      let PWD = this.common_aesEncrypt(strPassWD, strEncryptKey);
    },
    time() {
      var dateLS = new Date();

      var year = dateLS.getFullYear();
      var month = dateLS.getMonth() + 1;
      var day = dateLS.getDate();

      if (month < 10) {
        month = "0" + month;
      }

      if (day < 10) {
        day = "0" + day;
      }
      var nowDate = year + "-" + month + "-" + day;
      this.nowDate = nowDate;
      this.month = month;
      //return year + month + day
    },
    btnRegi() {
      this.agentInfo.MSG = this.cheeringMsg;
      //응원메시지 변경이면 OPTION을 "msg"
      this.agentInfo.OPTION = "msg";
      this.$emit("registAgent", this.agentInfo);
    },

    btnRegIcon() {
      this.agentInfo.ICON = this.profileIcon.substr(12, 15);
      //응원메시지 변경이면 OPTION을 "icon"
      this.agentInfo.OPTION = "icon";
      this.$emit("registAgent", this.agentInfo);
    },

    init() {
      this.arrayEvents = [];
      this.sub_title = "";
      (this.sub_content = ""), (this.date = "");
      this.profileIcon =
        "svg-profile " + this.$store.getters["userStore/GE_USER_ICON"];
      this.daysl = "";
      this.FirstCalendarClick();
    },

    updatePickedMonth(value){
      this.nowDate = value + "-01";

      if (this.initCNT != 0){
        this.calendarClick();
      }
    },
  },
  mounted() {
    this.time();
    this.init();
  },

  watch: {
  },
};
</script>

<style></style>
