<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">평가현황</h1>
    <div class="box-wrap">
      <div class="box-ct d-flex">
        <div class="form-inp sm">
          <v-select
            :items="$store.getters['userStore/GE_USER_COMPANY']"
            item-text="CD_NM"
            item-value="CD"
            outlined
            hide-details
            label="회사구분"
            placeholder="선택하세요"
            v-model="grid01Search.ASP_NEWCUST_KEY"
            @change="selectRtn"
            :readonly="RDOnly"
          >
          <template v-slot:label>
              회사구분
            <v-icon color="#EF9191">mdi-circle-small</v-icon>
          </template>
          </v-select>
        </div>
        <div class="form-inp sm ml-2">
          <v-select
            :items="mixin_common_code_get(allCodeList,'PLT008','전체')"
            item-text="text"
            item-value="value"
            outlined
            hide-details
            label="유형타입"
            v-model="grid01Search.QA_TY"
            placeholder="선택하세요"
          ></v-select>
        </div>
        <div class="sm ml-2">
          <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
            content-class="calendar-modal"
            color="#FFF"
          >
            <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="dateRangeText"
                v-bind="attrs"
                v-on="on"
                readonly
                outlined
                hide-details
                class="form-inp icon-calendar"
                append-icon="svg-calendar"
                label="전체평가기간"
              ></v-text-field>
            </template>
            <div class="datepicker-range">
              <v-date-picker
                  v-model="grid01Search.START_DATE"
                  no-title
                  dark
                  color="#F7AFAE"
                  locale="ko-KR"
                  :first-day-of-week="1"
                  :day-format="mixin_getDate"
                  @change="startDate"
                ></v-date-picker>
              <v-date-picker
                  v-model="grid01Search.END_DATE"
                  dark
                  color="#F7AFAE"
                  no-title
                  locale="ko-KR"
                  :first-day-of-week="1"
                  :day-format="mixin_getDate"
                  :min="grid01Search.START_DATE"
                  @change="endDate"
                ></v-date-picker>
            </div>
            <div class="text-center pt-3 pb-3">
              <v-btn
                outlined
                class="btn-default"
                @click="menu1 = false"
              >확인</v-btn>
            </div>
          </v-menu>
        </div>

        <div class="ml-2">
          <v-text-field
            class="form-inp lg"
            name="name"
            label="QA평가명"
            hide-details
            outlined hint=""
            v-model="grid01Search.QA_NM"
            @keyup.enter="selectRtn"
            persistent-hint>
          </v-text-field>
        </div>

        <div class="ml-auto align-self-center">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')" outlined class="btn-etc2 ml-3" @click="selectRtn" @keyup.enter="selectRtn" >조회</v-btn>
        </div>
      </div>
    </div>

    <!-- 상담품질평가 목록 -->
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">상담품질평가 목록</h2>
      <div class="box-ct">
        <v-data-table
          dense
          height="612px"
          :headers="gridDataHeaders"
          :items="gridDataText"
          item-key="ROW_NUMBER"
          :items-per-page="30"
          hide-default-footer
          class="grid-default case03"
          fixed-header
          single-select
          calculate-widths
          no-data-text="검색된 결과가 없습니다."
          v-model="selGroupCd"
          @click:row="getListSel"
        >
          <template v-slot:item.QA_DATE = {item}>
            {{item.QA_ST_DTTM}} ~ {{item.QA_EN_DTTM}}
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin } from "@/mixin/mixin.js";
export default {
  name: "MENU_M710301", //name은 'MENU_' + 파일명 조합
  components: {},
  mixins: [mixin],
  data() {
    return {
      selGroupCd : [],
      grid01Search : {
        ASP_NEWCUST_KEY : "",
        QA_TY : "",
        QA_NM : "",
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
        START_DATE : '',
        END_DATE : '',
      },
      menu1: false,

      gridDataHeaders: [
        {
          text: 'NO',
          sortable: false,
          value: 'ROW_NUMBER',
          align: "center",
          width: "60px",
        },
        { text: '회사구분', align: "center", width:"100px", value: 'ASP_CUST_NM', sortable: true, },
        { text: '평가년/월', align: "center", value: 'QA_DATE', width:"170px", sortable: true, },
        { text: '평가명', align: "left", value: 'QA_NM', sortable: true, },
        { text: '상담유형', align: "center", width:"80px", value: 'QA_TG_TY_NM', sortable: true, },
        { text: '대상', align: "center", width:"80px", value: 'QA_TG_CNT', sortable: true, },
        { text: '할당 수', align: "center", width:"80px", value: 'QA_DIV_CNT', sortable: true, },
        { text: '완료', align: "center", width:"80px",value: 'CP_CNT', sortable: true, },
        { text: 'QA인원', align: "center", width:"80px", value: 'QA_USER_CNT', sortable: true, },
        { text: '진행상태', align: "center", width:"80px", value: 'QA_TA_ST_NM', sortable: true, },
      ],
      gridDataText: [],
      groupCdList : ['PLT008'],
      allCodeList : [],
      
      RDOnly:false,
    };
  },
  async mounted(){
    this.allCodeList = await this.mixin_common_code_get_all(this.groupCdList);
  
    let chk = this.mixin_check_AspCustKey_number();
    if(chk == 'Y'){
      this.grid01Search.ASP_NEWCUST_KEY = this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD;
      this.RDOnly = true;
    } else {
      this.RDOnly = false;
    }
  },
  methods: {
    //Table row 클릭이벤트
    getListSel(item, row) {
      if(item != null){
        this.activeRow(row,true);
      }
    },

    activeRow(row, value){
      console.log(row);
      this.selectedRow = row;
      row.select(Boolean(value));
      row.isSelected = Boolean(value);
    },

    startDate(e){
      this.grid01Search.START_DATE = e;
    },
    endDate(e){
      this.grid01Search.END_DATE = e;
    },

    async selectRtn(){
      if(this.grid01Search.ASP_NEWCUST_KEY == undefined || this.grid01Search.ASP_NEWCUST_KEY == ''){
        this.common_alert("회사구분을 선택해주세요.","error");
      }else{
        this.selGroupCd = [];

        let requestData = {
          headers: {},
          sendData:{}
        };
        // header 세팅
        requestData.headers["URL"] = "api/statistics/phone/qa2/list";
        requestData.headers["SERVICE"] = "statistics.phone.qa2";
        requestData.headers["METHOD"] = "list";
        requestData.headers["TYPE"] = "BIZ_SERVICE";
        requestData.headers["ASYNC"] = false;

        // sendData 세팅
        requestData.sendData = this.grid01Search;

        // 결과 리턴
        let response = await this.common_postCall(requestData);

        if(!response.HEADER.ERROR_FLAG){
          this.gridDataText = response.DATA;
        }else{
          this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.","error");
        }

      }

    },
  },
  computed: {
    dateRangeText () {
      var newStartDate = this.grid01Search.START_DATE;
      var newEndDate = this.grid01Search.END_DATE;
      let returnVal = "";
      if(newStartDate !== '' && newEndDate !== ''){
        returnVal = newStartDate + " ~ " + newEndDate
      }
      return returnVal;
      // return this.dates.join(' ~ ')
    },

  },
};
</script>

<style></style>
