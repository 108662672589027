import api from "@/store/apiUtil";
const userStore = {
  namespaced: true,
  state: () => ({
    // user_name: "도로시",
    user_state: "none",
    user_role: {
      userId: "",
      userName: "",
      userNick: "",
      seatNo: "",
      bussCode: "",
      cmpId:"",
      userGroupCd:"",
      hpNo: "",
      atrtGroupId: "",
      atrtGroupNm: "",
      cnnctIp: "",
      lastLoginDt: "",
      INLNE_NO: "",
      CNNCT_IP: "",
      TOPMSG: "",
      ICON: "",
      SCHEDULE: 0,
      CHK_CUST: {
        CHK: 0,
        vue: "",
        CHK_CD: "",
        CHK_NM: "",
      },
      company: [
        // {
        //   CD: "1024",
        //   CD_NM: "테스트기업2",
        // },
        // {
        //   CD: "1001",
        //   CD_NM: "네이버",
        // },
        // {
        //   CD: "1040",
        //   CD_NM: "KPP",
        // },
        // {
        //   CD: "1041",
        //   CD_NM: "HKC",
        // },
        // {
        //   CD: "HKCTALKMNG",
        //   CD_NM: "한국클라우드",
        // },
      ],
      //채널 필요 없음
      // channel: [
      //   {
      //     aspCustKey: "hkcloud",
      //     aspSenderKey: "1041",
      //     talkSenderKey: "416dd5770fc1f9b0442f820f79c9082150482117",
      //   },
      //   {
      //     aspCustKey: "kpp",
      //     aspSenderKey: "1042",
      //     talkSenderKey: "4f8bb80ee62869721a03c409791c0e430164daa6",
      //   },
      // ],
      //사용자 소속 필요없음
      // attr: [
      //   {
      //     aspCustKey: "hkcloud",
      //     userAttrA: "A01",
      //     userAttrANm: "한국클라우드",
      //     userAttrB: "B04",
      //     userAttrBNm: "STAFF",
      //     userAttrC: "C01",
      //     userAttrCNm: "시스템관리",
      //     userAttrD: "D03",
      //     userAttrDNm: "팀장(S/V)",
      //   },
      //   {
      //     aspCustKey: "kpp",
      //     userAttrA: "A01",
      //     userAttrANm: "한국클라우드",
      //     userAttrB: "B01",
      //     userAttrBNm: "상담사",
      //     userAttrC: "C01",
      //     userAttrCNm: "상담사",
      //     userAttrD: "D01",
      //     userAttrDNm: "시스템관리자",
      //   },
      //],
    },
    user_menu: [], //사용자 메뉴권한 (tree 구조)
    user_menu_list: [], //사용자 메뉴 및 버튼 권한
    mobie_yn : "",      //모바일사용여부 
    time : 0,
    connTime : '',
  }),
  getters: {
    GE_USER_NAME: (state) => state.user_role.userName,
    GE_USER_STATE: (state) => state.user_state,
    GE_USER_ROLE: (state) => state.user_role,
    GE_USER_MENU: (state) => state.user_menu,
    GE_USER_MENU_LIST: (state) => state.user_menu_list,
    GE_USER_ID: (state) => state.user_role.userId,
    GE_USER_COMPANY: (state) => state.user_role.company,
    GE_USER_INLNE_NO: (state) => state.user_role.INLNE_NO,
    GE_USER_CNNCT_IP: (state) => state.user_role.CNNCT_IP,
    GE_USER_TOPMSG: (state) => state.user_role.TOPMSG,
    GE_USER_ICON: (state) => state.user_role.ICON,
    GE_USER_SCHEDULE: (state) => state.user_role.SCHEDULE,
    GE_USER_CHK_CUST: (state) => state.user_role.CHK_CUST,
    GE_USER_IP_ADDR: (state) => state.user_role.CNNCT_IP,       // 상담사로그인 IP
    GE_MOBILE_YN : (state) => state.mobie_yn,                   //모바일사용여부 
    GE_CONN_TIME : (state) => state.connTime,                   //접속 진행 시간
  },
  mutations: {
    MU_USER_NAME: (state, data) => {
      /*
                여기서는 payload를 객체로 받습니다.
                payload를 객체로 받으면, mutation를 조금더 유연하게 사용할 수 있기는 합니다.
            */
      state.user_name = data.userName;
    },
    MU_USER_STATE: (state, data) => {
      state.user_state = data.ustate;
    },
    MU_USER_COMPANY: (state, data) => {
      if (data) {
        let companyArray = [];
        for (var i = 0; i < data.length; i++) {
          companyArray.push({
            CD: data[i]["CD"],
            CD_NM: data[i]["CD_NM"],
          });
        }
        state.user_role.company = companyArray;
      }
    },
    MU_USER_MENU: (state, data) => {
      // state.ustate = date.ustate;
      if (data) {
        state.user_menu = data.MENU_ITEMS;
        state.user_menu_list = data.DATA;
      }
    },
    MU_USER_DETAIL: (state, data) => {
      // state.ustate = date.ustate;
      if (data.data) {
        let getData = data.data;
        state.user_role.userId = getData.USER_ID;
        state.user_role.userName = getData.USER_NM;
        state.user_role.userNick = getData.USER_NICK;
        state.user_role.seatNo = getData.SEAT_NO;
        state.user_role.bussCode = getData.BUSS_CODE;
        state.user_role.cmpId = getData.CMP_ID;
        state.user_role.userGroupCd = getData.USER_GROUP_CD;
        state.user_role.atrtGroupId = getData.AUTHOR_GROUP_ID_LIST;
        state.user_role.atrtGroupNm = getData.AUTHOR_GROUP_NM_LIST;
        state.user_role.cnnctIp = getData.CNNCT_IP;
        state.user_role.lastLoginDt = getData.LAST_LOGIN_DT;
        state.user_role.INLNE_NO = getData.INLNE_NO;
        state.user_role.CNNCT_IP = getData.CNNCT_IP;
        state.user_role.TOPMSG = getData.TOPMSG;
        state.user_role.ICON = getData.ICON;
        state.time = 0;
      }
    },

    /**
     * 접속 시간
     */
    MU_USER_CONN_TIME: (state, data) => { 
      state.time++;
      let hour = parseInt(String(state.time / (60 * 60)));
      let min = parseInt(String((state.time - (hour * 60 * 60)) / 60));
      let sec = state.time % 60;

      state.connTime = '사용시간 : ' + String(hour).padStart(2, '0') + ":" + String(min).padStart(2, '0') + ":" + String(sec).padStart(2, '0');
      return state.connTime;
    },

     /**
     * 모바일사용여부
     * @param {*} param0 
     * @param {*} data 
     */
    MU_MOBIE_YN: (state, data) => {   
      state.mobie_yn = data;
    },
  },
  actions: {
    
    /**
     * 모바일사용여부
     * @param {*} param0 
     * @param {*} data 
     */
    AC_MOBIE_YN: ({ commit }, data) => {
      commit("MU_MOBIE_YN", data);
    },

    AC_USER_NAME: ({ commit }, data) => {
      commit("MU_USER_NAME", data);
    },
    AC_USER_STATE: ({ commit }, data) => {
      commit("MU_USER_STATE", data);
    },
    //사용자 회사 조회
    async AC_USER_COMPANY({ commit }, data) {
      let set_headers = {
        SERVICE: "code.common.code-book.sender",
        METHOD: "aspinqry",
        TYPE: "BIZ_SERVICE",
        ASYNC: false,
      };

      api
        .post(
          "api/code/common/code-book/aspinqry",
          {
            ASP_USER_ID: data.user_id,
          },
          { head: set_headers }
        )
        .then((response) => {
          if (response.data[data.user_id]) {
            commit("MU_USER_COMPANY", response.data[data.user_id]);
          }
        })
        .catch((error) => {
          // 에러인 경우 처리
          console.error("company 조회 에러", error);
        });
    },
    //사용자 메뉴 조회
    async AC_USER_MENU({ commit }, data) {
      api
        .post("api/palette-main/menu/list", {
          USER_ID: data.user_id,
        })
        .then((response) => {
          commit("MU_USER_MENU", response.data);
        })
        .catch((error) => {
          // 에러인 경우 처리
          console.error("menu 조회 에러", error);
        });
    },
    //사용자 상세정보 조회
    async AC_USER_DETAIL({ commit }, data) {
      commit("MU_USER_DETAIL", data);
    },
  },
};

export default userStore;
