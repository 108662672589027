<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">아웃바운드 통계</h1>
    <!-- 상단 조회폼 시작 -->
    <div class="box-wrap d-flex">
      <div class="box-ct">
        <div>
          <div class="form-inp sm">
            <v-select
                :items="custItems"
                item-text="CD_NM"
                item-value="CD"
                outlined
                hide-details
                label="회사구분"
                placeholder="선택하세요"
                v-model="ASP_NEWCUST_KEY"
                v-on:change="companyChange"
                :readonly="RDOnly"
            >
              <template v-slot:label>
                회사구분
                <v-icon color="#EF9191">mdi-circle-small</v-icon>
              </template>
            </v-select>
          </div>
          <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
              content-class="calendar-modal"
              color="#FFF"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="dateRangeText"
                  v-bind="attrs"
                  v-on="on"
                  readonly
                  outlined
                  hide-details
                  class="form-inp icon-calendar ml-2"
                  append-icon="svg-calendar"
                  label="상담일자"
              ></v-text-field>
            </template>
            <div class="datepicker-range">
              <v-date-picker
                  v-model="dates[0]"
                  no-title
                  dark
                  color="#F7AFAE"
                  locale="ko-KR"
                  :first-day-of-week="1"
                  :day-format="mixin_getDate"
                  @change="startDate"
              ></v-date-picker>
              <v-date-picker
                  v-model="dates[1]"
                  dark
                  color="#F7AFAE"
                  no-title
                  locale="ko-KR"
                  :first-day-of-week="1"
                  :day-format="mixin_getDate"
                  :min="dates[0]"
                  @change="endDate"
              ></v-date-picker>
            </div>
            <div class="text-center pt-3 pb-3">
              <v-btn
                  outlined
                  class="btn-default"
                  @click="menu1 = false"
              >확인
              </v-btn>
            </div>
          </v-menu>
          <v-radio-group v-model="radioGroupSelected" row class="radio-inline ml-2" @change="chgCamTy">
            <template v-slot:label>
              <span class="in-label">조회구분</span>
            </template>
            <v-radio v-for="(rad01,index) in radioGroup" :key="radioGroup[index].text" :label="rad01.text"
                     :on-icon="'svg-rad-on'"
                     :off-icon="'svg-rad-off'"
                     :value="rad01.value">
            </v-radio>
          </v-radio-group>
          <v-text-field
              class="form-inp ml-2"
              name="name" label="아웃바운드명" id="id" outlined
              hide-details
              v-model="CAM_NM"
          >
          </v-text-field>
          <!-- 조회 버튼 -->
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnPopupObd')" dark small class="btn-main-search pa-0 ml-2" min-width="32px" height="32px" plain
                 @click="showDialog('M710103P01')">
            <v-icon small class="svg-main-searh white"></v-icon>
          </v-btn>
          <!-- 아웃바운드 조회 모달 -->
          <v-dialog max-width="1400" persistent v-model="dialogM710103P01" content-class="square-modal">
            <dialog-M710103P01
                headerTitle="아웃바운드 조회"
                @hide="hideDialog('M710103P01')"
                :funObndInfo="funObndInfo"
                @input="funObndInfo = $event"
                @selectCamNm="[hideDialog('M710103P01'), setCamNm()]"
            >
            </dialog-M710103P01>
          </v-dialog>
          <!-- 새로고침 버튼 -->
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnInitObd')" dark small class="btn-main-refresh pa-0 ml-2" min-width="32px" height="32px" plain
          >
            <v-icon small class="svg-main-refresh" @click="clearCamData"></v-icon>
          </v-btn>

        </div>
        <div class="mt-2">
          <div class="form-inp sm">
            <v-select
                :items="dropA"
                item-text="CD_NM"
                item-value="CD"
                outlined
                hide-details
                label="소속A"
                placeholder="선택하세요"
                v-model="USER_ATTR_A"
            ></v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
                :items="dropB"
                item-text="CD_NM"
                item-value="CD"
                outlined
                hide-details
                label="소속B"
                placeholder="선택하세요"
                v-model="USER_ATTR_B"
            ></v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
                :items="dropC"
                item-text="CD_NM"
                item-value="CD"
                outlined
                hide-details
                label="소속C"
                placeholder="선택하세요"
                v-model="USER_ATTR_C"
            ></v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
                :items="dropD"
                item-text="CD_NM"
                item-value="CD"
                outlined
                hide-details
                label="소속D"
                placeholder="선택하세요"
                v-model="USER_ATTR_D"
            ></v-select>
          </div>
          <v-text-field
              class="form-inp sm ml-2"
              name="name"
              label="사용자ID"
              outlined
              hide-details
              v-model="USER_ID"
          >
            <template v-slot:label>
              사용자ID
              <v-icon color="#EF9191">mdi-circle-small</v-icon>
            </template>
          </v-text-field>
          <v-text-field
              class="form-inp sm ml-2"
              name="name"
              label="사용자 이름"
              outlined
              hide-details
              v-model="USER_NM"
          ></v-text-field>
          <v-text-field
              class="form-inp sm ml-2"
              name="name"
              label="사용자 닉네임"
              outlined
              hide-details
              v-model="USER_NICK"
          ></v-text-field>
          <!-- 조회 버튼 -->
          <v-btn 
            v-if="this.mixin_set_btn(this.$options.name, 'btnPopupUser')"
            dark small
            class="btn-main-search pa-0 ml-2" min-width="32px" height="32px" plain
            @click="showDialog('MP02')">
            <v-icon small class="svg-main-searh white"></v-icon>
          </v-btn>
          <!-- 새로고침 버튼 -->
          <v-btn 
            v-if="this.mixin_set_btn(this.$options.name, 'btnInitUser')"
            dark small
            class="btn-main-refresh pa-0 ml-2" min-width="32px" height="32px"
            @click="clearUserData"
            plain
            >
            <v-icon small class="svg-main-refresh"></v-icon>
          </v-btn>
        </div>
      </div>
      <div class="ml-auto align-self-center">
        <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')" outlined class="btn-etc2" @click="searchfunObnd">조회</v-btn>
      </div>
    </div>
    <!-- 상단 조회폼 끝   -->
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">
        상담유형별통계 Report
        <div class="ml-auto">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnExcelDown')" outlined class="btn-point ml-2" id="btnObndExcel" @click="excelDownload('divGridObndSttc', '아웃바운드 톻계')">
            엑셀 다운로드
          </v-btn>
        </div>
      </h2>
      <div class="box-ct">
        <v-data-table
            dense
            height="466px"
            id="divGridObndSttc"
            :headers="gridDataHeaders"
            :items="gridDataText"
            :items-per-page="30"
            item-key="index"
            :page.sync="page"
            hide-default-footer
            class="grid-default case03"
            single-select
            calculate-widths
            fixed-header
            @page-count="pageCount = $event"
            @click:row="getListSel"
            v-model="selectedRow"
        >
        </v-data-table>
        <div class="grid-paging text-center pt-2">
          <v-pagination
              v-model="page"
              :length="pageCount"
              :total-visible="totalVisible"
              next-icon="svg-paging-next"
              prev-icon="svg-paging-prev"
          ></v-pagination>
          <p class="caption text-right">
            <strong class="clr-error">{{ gridDataText.length }}</strong
            >건의 통계가 검색되었습니다.
          </p>
        </div>
      </div>
    </div>
    <!-- 사용자정보 모달창 -->
    <div>
      <v-dialog
          max-width="1400"
          max-height="1200"
          v-model="dialogMP02"
          content-class="square-modal"
      >
        <dialog-MP02
            headerTitle="사용자정보"
            @hide="hideDialog('MP02')"
            :userInfo="userInfo"
            @input="userInfo = $event"
            @selectUser="[hideDialog('MP02'), setUserData()]"
        ></dialog-MP02>
        <template v-slot:body></template>
      </v-dialog>
    </div>
    <!-- 사용자정보 모달창 -->
  </div>
</template>

<script>
import {mixin} from "../../mixin/mixin";
import DialogM710103P01 from "./M710103P01.vue";
import dialogMP02 from "./MP02.vue"

export default {
  name      : "MENU_M710103", //name은 'MENU_' + 파일명 조합
  mixins    : [mixin],
  components: {
    DialogM710103P01,
    dialogMP02
  },
  data() {
    return {
      dialogM710103P01: false,
      userInfo        : {},
      funObndInfo     : {},
      dialogMP02      : false,

      //Items
      custItems         : [],
      listItems         : [
        {
          ATTR_CD    : "A",
          ATTR_DIV_CD: "",
        },
        {
          ATTR_CD    : "B",
          ATTR_DIV_CD: "",
        },
        {
          ATTR_CD    : "C",
          ATTR_DIV_CD: "",
        },
        {
          ATTR_CD    : "D",
          ATTR_DIV_CD: "",
        }
      ],
      dropA             : [{CD_NM: "전체", CD: ""}],
      dropB             : [{CD_NM: "전체", CD: ""}],
      dropC             : [{CD_NM: "전체", CD: ""}],
      dropD             : [{CD_NM: "전체", CD: ""}],
      dropItems         : [],
      menu1             : false,
      dates             : [
        '',
        ''
      ],
      radioGroup        : [
        {
          text : '캠페인별',
          value: 'CM'
        },
        {
          text : '업무구분별',
          value: 'BD'
        },
        {
          text : '배분상담원별',
          value: 'DV'
        }
      ],
      radioGroupSelected: 'DV',
      page              : 1,
      gridDataText      : [],
      gridDataHeaders   : [
        {text: "회사명", value: "ASP_NEWCUST_KEY", excel: true, sortable: false, align: 'center'},
        {text: "캠페인명", value: "CAM_NM", excel: true, align: 'left'},
        {text: "배분일자", value: "DSTR_DATE", excel: true, align: 'center'},
        {text: "배분상담원", value: "USER_NM", excel: true, align: 'left'},
        {text: "총배분건수", value: "TOTCNT", excel: true, align: 'center'},
        {text: "처리완료", value: "ENDCNT", excel: true, align: 'center'},
        {text: "미시도", value: "NOTENDCNT", excel: true, align: 'center'},
        {text: "처리불가", value: "CANNOTCNT", excel: true, align: 'center'},
        {text: "처리중", value: "INGCNT", excel: true, align: 'center'},
        {text: "최종시도실패", value: "FINALTRYCNT", excel: true, align: 'center'},
        {text: "처리완료_완판", value: "EDPFCNT", excel: true, align: 'center'},
        {text: "처리완료_불판", value: "EDFACNT", excel: true, align: 'center'},
        {text: "처리완료_10회", value: "EDTNCNT", excel: true, align: 'center'},
        {text: "기타", value: "ETCCNT", excel: true, align: 'center'},
        {text: "처리율", value: "RATE", excel: true, align: 'center'},
      ],
      pageCount         : 0,
      itemsPerPage      : 5,
      totalVisible      : 10,

      //v-model
      ASP_NEWCUST_KEY: '',
      USER_ATTR_A    : '',
      USER_ATTR_B    : '',
      USER_ATTR_C    : '',
      USER_ATTR_D    : '',
      USER_ID        : '',
      USER_NM        : '',
      USER_NICK      : '',
      CAM_ID         : '',
      CAM_NM         : '',
      selectedRow    : [],

      //msgList
      msgList : {
        noAspCustKey: "회사구분란에서 회사를 선택후 이용해주십시오",
        excelDwnError:    "[엑셀출력 실패] 조회된 정보가 없습니다.",
      },

      RDOnly:false,
    };
  },
  methods: {
    //Table row 클릭이벤트
    getListSel(item, row) {
      if(item != null){
        this.activeRow(row,true);
      }
    },

    activeRow(row, value){
      row.select(Boolean(value));
      row.isSelected = Boolean(value);
    },

    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${year}/${month}/${day}`
    },
    formatDate2(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${year}${month}${day}`
    },
    startDate(e) {
      this.dates[0] = e;
    },
    endDate(e) {
      this.dates[1] = e;
    },
    showDialog(type) {
      if(this.ASP_NEWCUST_KEY == null || this.ASP_NEWCUST_KEY == ''){
       this.common_alert(this.msgList.noAspCustKey, "error");
       return
      }

      if (type == "MP02") {
        let user = {
          ASP_CUST_KEY: this.ASP_NEWCUST_KEY,
          USER_ID     : this.USER_ID,
          USER_NM     : this.USER_NM,
          USER_NICK   : this.USER_NICK
        };
        this.userInfo = user;
      } else if (type == 'M710103P01') {
        let obnd = {
          ASP_CUST_KEY: this.ASP_NEWCUST_KEY,
          CAM_NM: this.CAM_NM,
        }
        this.funObndInfo = obnd;
      }
      this[`dialog${type}`] = true;
    },
    hideDialog(type) {
      this[`dialog${type}`] = false;
    },
    setUserData() {
      this.USER_ID = this.userInfo.USER_ID;
      this.USER_NM = this.userInfo.USER_NM;
      this.USER_NICK = this.userInfo.USER_NICK;
    },
    setCamNm() {
      this.CAM_NM = this.funObndInfo.CAM_NM;
      this.CAM_ID = this.funObndInfo.CAM_ID;

    },
    clearUserData() {
      this.USER_ID = "";
      this.USER_NM = "";
      this.USER_NICK = "";
    },
    clearCamData() {
      this.CAM_NM = '';
      this.CAM_ID = '';
    },
    //회사선택시 조회
    companyChange() {
      //드롭박스 초기화
      this.dropA = [{CD_NM: "전체", CD: ""}];
      this.dropB = [{CD_NM: "전체", CD: ""}];
      this.dropC = [{CD_NM: "전체", CD: ""}];
      this.dropD = [{CD_NM: "전체", CD: ""}];

      this.getAttrList();

    },
    //attr가져오기
    async getAttrList() {
      let requestData = {
        headers : {},
        sendData: {}
      };

      // header 세팅
      requestData.headers["URL"] = "/api/palette/common/attr-div/list";
      requestData.headers["METHOD"] = "list";
      requestData.headers["SERVICE"] = 'palette.common.attr-div';
      requestData.headers["TYPE"] = "BIZ_SERVICE";

      // sendData 세팅
      requestData.sendData["ASP_NEWCUST_KEY"] = this.ASP_NEWCUST_KEY;
      requestData.sendData["listItems"] = this.listItems;

      let attrResponse = await this.common_postCall(requestData);
      this.attrResponseCallback(attrResponse);
    },
    attrResponseCallback(response) {
      let dropA = response.A;
      let dropB = response.B;
      let dropC = response.C;
      let dropD = response.D;

      this.dropA.push(...dropA);
      this.dropB.push(...dropB);
      this.dropC.push(...dropC);
      this.dropD.push(...dropD);
    },

    async searchfunObnd() {

      if(this.ASP_NEWCUST_KEY == null || this.ASP_NEWCUST_KEY == ''){
        this.common_alert(this.msgList.noAspCustKey, 'error')
      }
      this.selectedRow = [];
      let requestData = {
        headers : {},
        sendData: {}
      };

      // header 세팅
      requestData.headers["URL"] = "/api/statistics/phone/outbnd/inqire";
      requestData.headers["METHOD"] = "inqire";
      requestData.headers["SERVICE"] = 'statistics.phone.outbnd';
      requestData.headers["TYPE"] = "XML_SERVICE";
      requestData.headers["GRID_ID"] = "divGridObndSttc";
      requestData.headers["MENU_ID"] = '';


      // sendData 세팅
      requestData.sendData["ASP_NEWCUST_KEY"] = this.ASP_NEWCUST_KEY;
      requestData.sendData["CAM_ID"] = this.CAM_ID;
      requestData.sendData["DATE_FROM"] = this.formatDate2(this.dates[0]);
      requestData.sendData["DATE_TO"] = this.formatDate2(this.dates[1]);
      requestData.sendData["SEARCH_TY_01"] = '';
      requestData.sendData["SEARCH_TY_02"] = '';
      requestData.sendData["SEARCH_TY_03"] = '';
      requestData.sendData["CAM_NM"] = this.CAM_NM;
      requestData.sendData["USER_ATTR_A"] = this.USER_ATTR_A;
      requestData.sendData["USER_ATTR_B"] = this.USER_ATTR_B;
      requestData.sendData["USER_ATTR_C"] = this.USER_ATTR_C;
      requestData.sendData["USER_ATTR_D"] = this.USER_ATTR_D;
      requestData.sendData["AGENT_ID"] = this.USER_ID;
      requestData.sendData["AGENT_NM"] = this.USER_NM;
      requestData.sendData["AGENT_NICK"] = this.USER_NICK;
      requestData.sendData["SEARCH_TY"] = this.radioGroupSelected;
      requestData.sendData["CENT_TY"] = 'G';

      let response = await this.common_postCall(requestData);
      this.searchfunObndCallback(response);
    },

    searchfunObndCallback(response) {
      this.gridDataText = response.DATA;
      this.TOT_COUNT = response.HEADER.TOT_COUNT;

      let idx = 1;
      let dataText = this.gridDataText;
      for (let i in dataText) {
        let id = this.ASP_NEWCUST_KEY + this.gridDataText[i]["CAM_NM"];
        this.gridDataText[i]["id"] = id;
        this.gridDataText[i]["index"] = idx++;
      }
    },

    chgCamTy() {
      switch (this.radioGroupSelected) {
        case "CM":
          this.gridDataHeaders = [
            {text: "회사명", value: "ASP_NEWCUST_KEY", excel: true, sortable: false, align: 'center'},
            {text: "캠페인명", value: "CAM_NM", excel: true, align: 'left'},
            {text: "배분일자", value: "DSTR_DATE", excel: true, align: 'center'},
            {text: "총배분건수", value: "TOTCNT", excel: true, align: 'center'},
            {text: "처리완료", value: "ENDCNT", excel: true, align: 'center'},
            {text: "미시도", value: "NOTENDCNT", excel: true, align: 'center'},
            {text: "처리불가", value: "CANNOTCNT", excel: true, align: 'center'},
            {text: "처리중", value: "INGCNT", excel: true, align: 'center'},
            {text: "최종시도실패", value: "FINALTRYCNT", excel: true, align: 'center'},
            {text: "처리완료_완판", value: "EDPFCNT", excel: true, align: 'center'},
            {text: "처리완료_불판", value: "EDFACNT", excel: true, align: 'center'},
            {text: "처리완료_10회", value: "EDTNCNT", excel: true, align: 'center'},
            {text: "기타", value: "ETCCNT", excel: true, align: 'center'},
            {text: "처리율", value: "RATE", excel: true, align: 'center'},
          ];
          this.searchfunObnd();
          break
        case "BD":
          this.gridDataHeaders = [
            {text: "회사명", value: "ASP_NEWCUST_KEY", excel: true, sortable: false, align: 'center'},
            {text: "캠페인명", value: "CAM_NM", excel: true, align: 'left'},
            {text: "업무구분", value: "CAM_TY_NM", excel: true, align: 'left'},
            {text: "배분상담원", value: "USER_NM", excel: true, align: 'left'},
            {text: "총배분건수", value: "TOTCNT", excel: true, align: 'center'},
            {text: "처리완료", value: "ENDCNT", excel: true, align: 'center'},
            {text: "미시도", value: "NOTENDCNT", excel: true, align: 'center'},
            {text: "처리불가", value: "CANNOTCNT", excel: true, align: 'center'},
            {text: "처리중", value: "INGCNT", excel: true, align: 'center'},
            {text: "최종시도실패", value: "FINALTRYCNT", excel: true, align: 'center'},
            {text: "처리완료_완판", value: "EDPFCNT", excel: true, align: 'center'},
            {text: "처리완료_불판", value: "EDFACNT", excel: true, align: 'center'},
            {text: "처리완료_10회", value: "EDTNCNT", excel: true, align: 'center'},
            {text: "기타", value: "ETCCNT", excel: true, align: 'center'},
            {text: "처리율", value: "RATE", excel: true, align: 'center'},
          ];
          this.searchfunObnd();
          break
        case "DV":
          this.gridDataHeaders = [
            {text: "회사명", value: "ASP_NEWCUST_KEY", excel: true, sortable: false, align: 'center'},
            {text: "캠페인명", value: "CAM_NM", excel: true, align: 'left'},
            {text: "배분일자", value: "DSTR_DATE", excel: true, align: 'center'},
            {text: "배분상담원", value: "USER_NM", excel: true, align: 'left'},
            {text: "총배분건수", value: "TOTCNT", excel: true, align: 'center'},
            {text: "처리완료", value: "ENDCNT", excel: true, align: 'center'},
            {text: "미시도", value: "NOTENDCNT", excel: true, align: 'center'},
            {text: "처리불가", value: "CANNOTCNT", excel: true, align: 'center'},
            {text: "처리중", value: "INGCNT", excel: true, align: 'center'},
            {text: "최종시도실패", value: "FINALTRYCNT", excel: true, align: 'center'},
            {text: "처리완료_완판", value: "EDPFCNT", excel: true, align: 'center'},
            {text: "처리완료_불판", value: "EDFACNT", excel: true, align: 'center'},
            {text: "처리완료_10회", value: "EDTNCNT", excel: true, align: 'center'},
            {text: "기타", value: "ETCCNT", excel: true, align: 'center'},
            {text: "처리율", value: "RATE", excel: true, align: 'center'},
          ];
          this.searchfunObnd();
          break
        default :
          console.log(this.radioGroupSelected);
      }
    },

    excelDownload(table_id, file_name) {
      if(table_id==='divGridObndSttc' && this.gridDataText.length < 1) {
        this.common_alert(this.msgList.excelDwnError, 'error')
        return false
      }
      this.table_id = table_id
      this.file_name = file_name
      this.mixin_common_exportExcel()
    },


  },
  mounted() {
    this.custItems = this.$store.getters['userStore/GE_USER_COMPANY'];

    let chk = this.mixin_check_AspCustKey_number();
    if(chk == 'Y'){
      this.ASP_NEWCUST_KEY = this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD;
      this.RDOnly = true;
    } else {
      this.RDOnly = false;
    }
  },
  computed: {
    dateRangeText() {
      let newStartDate = this.dates[0];
      let newEndDate = this.dates[1];
      let returnVal = "";
      if (newStartDate !== '' && newEndDate !== '') {
        returnVal = newStartDate + " ~ " + newEndDate
      }
      return returnVal;
    },
    computedDateFormatted() {
      return this.formatDate(this.date)
    },
    computedDateFormatted2() {
      return `${this.date} ${this.timeStep}`
    }
  },
};
</script>

<style></style>
