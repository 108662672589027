<template>
  <main>
    <div class="layout-subcontainer">
      <div class="box-wrap d-flex fontFm">
        <div class="col-6 pa-0">
          <h1 class="tit-h1 type-line">개인 상담관리
            <em v-if="!mixin_isEmpty(BUSS_NAME)" style="font-size: 14px;">({{ BUSS_NAME }})</em>
          </h1>

          <!-- 상담이력 목록 -->
          <div class="box-ct">
            <h2 class="tit-h2 d-flex align-center">상담이력 목록
            </h2>
            <v-data-table dense height="260px" :headers="gridDataHeaders" :items="gridLeftDataText"
              :items-per-page="itemsPerPage" item-key="IDX" :page.sync="page" hide-default-footer class="grid-default"
              fixed-header v-model="selRowItems" @page-count="pageCount = $event" @click:row="gridRowClick"
              :item-class="clickActive" no-data-text="검색된 결과가 없습니다.">

              <template v-slot:item.CNSL_QUST_CONT={item}>
                <div :title="decode(item.CNSL_QUST_CONT)">
                  {{ decode(item.CNSL_QUST_CONT) }}
                </div>
              </template>


            </v-data-table>
            <div class="grid-paging text-center pt-2">
              <span class="grid-total-count">총 <strong>{{ gridLeftDataText.length }}</strong>건</span>
              <v-pagination v-model="page" :length="pageCount" :total-visible="totalVisible" next-icon="svg-paging-next"
                prev-icon="svg-paging-prev"></v-pagination>
            </div>
          </div>

          <v-tabs class="tabs-form" slider-color="#F1B02A" slider-size="3">
            <!-- 설문내용 컨텐츠 -->
            <v-tab @click="fnChangeTab(1)" ref="subTab1">
              상세내용
            </v-tab>

            <v-tab @click="fnChangeTab(2)" ref="subTab2">
              설문조회
            </v-tab>

            <v-tab-item ref="tab1Item">
              <v-card-text>
                <v-form ref="subFormOne1" lazy-validation>
                  <div class="table-form save-form">
                    <table style="margin-top:7px; height: 33vh;">
                      <colgroup>
                        <col width="10%">
                        <col width="30%">
                        <col width="10%">
                        <col width="30%">
                      </colgroup>
                      <tbody>
                        <tr>
                          <th>
                            사업지명
                          </th>
                          <td>
                            <v-text-field class="form-inp full" outlined hide-details v-model="D_BUSS_NAME" readonly>
                            </v-text-field>
                          </td>
                          <th>
                            캠페인명
                          </th>
                          <td>
                            <v-text-field class="form-inp full" outlined hide-details v-model="D_CMP_NAME" readonly>
                            </v-text-field>
                          </td>
                        </tr>
                        <tr>
                          <th>
                            상담사아이디
                          </th>
                          <td>
                            <v-text-field class="form-inp full" outlined hide-details v-model="D_CNSLR_ID" readonly>
                            </v-text-field>
                          </td>
                          <th>
                            고객등급
                          </th>
                          <td>
                            <v-text-field class="form-inp full" outlined hide-details v-model="D_CUST_PPST_CD" readonly>
                            </v-text-field>
                          </td>
                        </tr>
                        <tr>
                          <th>
                            예약 | 방문일시
                          </th>
                          <td>
                            <v-text-field class="form-inp full" outlined hide-details v-model="D_VISET_RES_DATE" readonly>
                            </v-text-field>
                          </td>
                          <th>
                            상담신청일시
                          </th>
                          <td>
                            <v-text-field class="form-inp full" outlined hide-details v-model="D_COUNSL_DATE" readonly>
                            </v-text-field>
                          </td>
                        </tr>
                        <tr>
                          <th>
                            상담내용
                          </th>
                          <td colspan="3">
                            <v-textarea class="textarea-type0" outlined v-model="D_CNSL_QUST_CONT" rows="8" readonly
                              no-resize>
                            </v-textarea>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </v-form>
              </v-card-text>
            </v-tab-item>

            <v-tab-item ref="tab2Item">
              <v-card-text>
                <div class="survey-fix-list--footer" style="float: right;">
                  <v-btn outlined small class="btn-point ml-2" :disabled="surveySaveBool"
                    @click="fnSurveyUpdate">설문수정</v-btn>
                </div>
                <div class="survey-fix-list--footer" style="float: right;">
                  <v-btn outlined small class="btn-etc2 ml-2" @click="fnSurveyView">설문 불러오기</v-btn>
                </div>
                <div class="mo-reserve-wrap"
                  style="overflow: auto; height: 33.75vh; padding: 12px 120px 12px 120px; !important">
                  <div class="reserve-form-wrap" v-show="surveyExis">
                    <Survey ref="Survey" :RESULT_MODE="0" :PAGE_MODE="this.PAGE_MODE"
                      :SURVEY_AF_DATAS="this.SURVEY_AF_DATAS" @surveyEvent="fnSurveySave" @closeSurvey="fnClosePage"
                      @surveyCmpDatas="fnSurveyCmpDatas">
                    </Survey>
                  </div>
                </div>
              </v-card-text>
            </v-tab-item>
          </v-tabs>
        </div>

        <!-- 프로필 정보 -->
        <div class="ml-7 flex-grow-1">
          <h1 class="tit-h1 pt-7 d-flex align-center type-line">
            <div class="table-form d-flex" style="width:100%" v-if="rollChkBool">
              <table>
                <colgroup>
                  <col width="10%" />
                  <col width="20%" />
                  <col width="10%" />
                  <col width="20%" />
                  <col width="10%" />
                  <col width="20%" />
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="row">
                      <v-badge dot color="#EF9191">건설사명</v-badge>
                    </th>
                    <td>
                      <div class="form-inp">
                        <v-autocomplete :items="constructionCompanyNameArr" item-text="CD_NM" item-value="CD" outlined
                          hide-details placeholder="선택하세요" v-model="CTCP_CODE" :readonly="RDOnly" @change=chkCtcpCdFn>
                        </v-autocomplete>
                      </div>
                    </td>
                    <th scope="row">
                      <v-badge dot color="#EF9191">사업지명</v-badge>
                    </th>
                    <td>
                      <div class="form-inp">
                        <v-autocomplete :items="businessPlaceArr" item-text="CD_NM" item-value="CD" outlined hide-details
                          placeholder="선택하세요" @change="fnBussChoice" v-model="BUSS_CODE" :readonly="RDOnly">
                        </v-autocomplete>
                      </div>
                    </td>
                    <th scope="row">
                      <v-badge dot color="#EF9191">캠페인명</v-badge>
                    </th>
                    <td>
                      <div class="form-inp">
                        <v-autocomplete :items="cmpItems" item-text="CMP_NAME" item-value="CMP_ID" outlined hide-details
                          v-model="CMP_ID" placeholder="선택하세요" :readonly="RDOnly">
                        </v-autocomplete>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </h1>

          <div class="survey-fix-list">
            <!-- 프로필 플러그인 정보 -->
            <div class="survey-fix-list--field">
              <div class="survey-fix-list--title" style="padding-left: 15px;">프로필 플러그인 정보</div>
              <div class="survey-fix-list--form">
                <div class="box-ct mt-2">
                  <div class="table-form d-flex">
                    <table>
                      <caption class="hide">
                        사용자정보 항목
                      </caption>
                      <colgroup>
                        <col width="33%" />
                        <col width="33%" />
                        <col width="33%" />
                      </colgroup>
                      <tbody>
                        <tr>
                          <td>
                            <div class="form-inp full">
                              <v-text-field class="form-inp" label="이름&닉네임" outlined disabled style="width:100%;"
                                v-model="KKA_NAME">
                              </v-text-field>
                            </div>
                          </td>
                          <td>
                            <div class="form-inp full">
                              <v-text-field class="form-inp" label="전화번호" outlined disabled style="width:100%;"
                                v-model="KKA_PHONE">
                              </v-text-field>
                            </div>
                          </td>
                          <td>
                            <div class="form-inp full">
                              <v-text-field class="form-inp" label="이메일" outlined disabled style="width:100%;"
                                v-model="KKA_EMAIL">
                              </v-text-field>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <v-text-field class="form-inp" label="연령대" outlined disabled style="width:100%;"
                              v-model="KKA_RANGE">
                            </v-text-field>
                          </td>
                          <td colspan="2">
                            <v-text-field class="form-inp" label="성별" outlined disabled style="width:100%;"
                              v-model="KKA_GENDER">
                            </v-text-field>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!-- 개인정보 수집 및 마케팅 활용 여부 -->
            <div class="survey-fix-list--field">
              <div class="survey-fix-list--title" style="padding-left: 15px;">개인정보 수집 및 마케팅 활용 여부</div>
              <div class="survey-fix-list--form">
                <v-checkbox :on-icon="'svg-chk-on'" :off-icon="'svg-chk-off'" class="d-inline-flex"
                  v-model="R_PRSN_AGRE_YN" label="개인정보 수집동의"></v-checkbox>

                <v-checkbox :on-icon="'svg-chk-on'" :off-icon="'svg-chk-off'" class="d-flex" v-model="R_MKTG_AGRE_YN"
                  label="분양정보 제공 및 마케팅 활용 동의"></v-checkbox>
              </div>
            </div>
            <!-- 주소 입력 필드 -->
            <div class="survey-fix-list--field">
              <div class="survey-fix-list--title" style="padding-left: 15px;">주소</div>
              <div class="survey-fix-list--form">
                <v-text-field class="form-inp" label="읍/면/동까지 입력" outlined v-model="D_CUST_ADDRESS" style="width: 95%;">
                </v-text-field>
              </div>
            </div>
            <!-- 연령 필드 -->
            <div class="survey-fix-list--field">
              <div class="survey-fix-list--title" style="padding-left: 15px;">연령</div>
              <div class="survey-fix-list--form">
                <v-radio-group v-model="ageRadioGroupSelected" row class="radio-inline ml-3" hide-details>
                  <v-radio v-for="(rad01, index) in ageRadioGroup" :key="radioGroup[index].value" :label="rad01.text"
                    :on-icon="'svg-rad-on'" :off-icon="'svg-rad-off'" :value="rad01.value" hide-details>
                  </v-radio>
                </v-radio-group>
              </div>
            </div>
            <!-- 청약 유형 필드 -->
            <div class="survey-fix-list--field">
              <div class="survey-fix-list--title" style="padding-left: 15px;">청약유형</div>
              <div class="survey-fix-list--form">
                <v-radio-group v-model="subsRadioGroupSelected" row class="radio-inline ml-3" hide-details>
                  <v-radio v-for="(rad01, index) in subsRadioGroup" :key="radioGroup[index].value" :label="rad01.text"
                    :on-icon="'svg-rad-on'" :off-icon="'svg-rad-off'" :value="rad01.value" hide-details>
                  </v-radio>
                </v-radio-group>
              </div>
            </div>
            <!-- 선호 평형 필드 -->
            <div class="survey-fix-list--field">
              <div class="survey-fix-list--title" style="padding-left: 15px;">선호평형</div>
              <div class="survey-fix-list--form">
                <v-radio-group v-model="areaRadioGroupSelected" row class="radio-inline ml-3" hide-details>
                  <v-radio v-for="(rad01, index) in areaRadioGroup" :key="radioGroup[index].value" :label="rad01.text"
                    :on-icon="'svg-rad-on'" :off-icon="'svg-rad-off'" :value="rad01.value" hide-details>
                  </v-radio>
                </v-radio-group>
              </div>
            </div>
            <!-- 구입 목적 필드 -->
            <div class="survey-fix-list--field">
              <div class="survey-fix-list--title" style="padding-left: 15px;">구입목적</div>
              <div class="survey-fix-list--form">
                <v-radio-group v-model="reasonRadioGroupSelected" row class="radio-inline ml-3" hide-details>
                  <v-radio v-for="(rad01, index) in reasonRadioGroup" :key="radioGroup[index].value" :label="rad01.text"
                    :on-icon="'svg-rad-on'" :off-icon="'svg-rad-off'" :value="rad01.value" hide-details>
                  </v-radio>
                </v-radio-group>
              </div>
            </div>
            <!-- 이름 및 휴대폰 전화번호를 확인해 주세요 -->
            <div class="survey-fix-list--field">
              <div class="survey-fix-list--title" style="padding-left: 15px;">이름 및 휴대폰 전화번호를 확인해 주세요</div>
              <div class="survey-fix-list--form">
                <v-text-field class="form-inp" label="고객 성명" outlined v-model="R_CUST_NAME">
                </v-text-field>
                <v-text-field class="form-inp ml-2" label="고객 휴대전화번호" outlined disabled v-model="R_CNSLR_PHONE">
                </v-text-field>
              </div>
            </div>
            <!-- 고객 성향을 선택해주세요 -->
            <div class="survey-fix-list--field">
              <div class="survey-fix-list--title" style="padding-left: 15px;">고객 성향을 선택해주세요</div>
              <div class="survey-fix-list--form">
                <v-radio-group v-model="radioGroupSelected" row class="radio-inline ml-3" hide-details>
                  <v-radio v-for="(rad01, index) in radioGroup" :key="radioGroup[index].value" :label="rad01.text"
                    :on-icon="'svg-rad-on'" :off-icon="'svg-rad-off'" :value="rad01.value" hide-details>
                  </v-radio>
                </v-radio-group>
              </div>
            </div>
            <!-- 고객 코멘트 -->
            <div class="survey-fix-list--field">
              <div class="survey-fix-list--title" style="padding-left: 15px;">고객 코멘트</div>
              <div class="survey-fix-list--form">
                <v-textarea class="textarea-type0" placeholder="내용을 입력해주세요" outlined v-model="R_CNSL_QUST_CONT" rows="7">
                </v-textarea>
              </div>
            </div>
            <!-- 상담 고객 대기리스트 -->
            <div class="survey-fix-list--field">
              <div class="survey-fix-list--title" style="padding-left: 15px;">상담 고객 대기리스트</div>
              <div class="survey-fix-list--form">
                <div class="box-line">
                  <v-btn outlined small class="btn-etc2" @click="fnCallBfCorm" :disabled="callAbledChk">호출</v-btn>
                  <v-btn outlined small class="btn-point ml-2" @click="fnSaveCorm">상담종료</v-btn>
                  <v-btn outlined small class="btn-etc ml-2" @click="fnUnprocessedCorm">미처리</v-btn>
                  <v-btn outlined small class="btn-etc ml-2" @click="fnVoice()" :disabled="voiceBtnBool">재호출</v-btn>
                </div>
              </div>
            </div>
            <div class="survey-fix-list--field">
              <span class="survey-fix-list--field-total" style="position: absolute; right: 10px; bottom: 30px;">금일 대기명수:
                <strong class="counsTxt">{{ COUNS_WAIT_TOTAL }}명</strong></span>
              <span class="survey-fix-list--field-total" style="position: absolute; right: 10px; bottom: 7px;">금일 상담완료:
                <strong class="counsTxt">{{ COUNS_TOTAL }}건</strong></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { stompUtil } from "@/store/stomp-util.js";
import axios from "axios"
import Survey from "@/components/Survey";

export default {
  name: "MENU_CAM0400", //name은 'MENU_' + 파일명 조합
  components: {
    Survey
  },
  data() {
    return {
      PAGE_MODE: 'W',
      SURVEY_AF_DATAS: [],
      CMP_CNSL_ID: '',
      constructionCompanyNameArr: [],
      CTCP_CODE: '',
      BUSS_CODE: '',
      businessPlaceArr: [],

      radioGroupSelectedSurvey: [],
      radioGroupSelected: '',
      selectedRow: null,
      radioGroup: [
        {
          text: "S",
          value: "CUST_PPST_CD_05",
        },
        {
          text: "A",
          value: "CUST_PPST_CD_01",
        },
        {
          text: "B",
          value: "CUST_PPST_CD_02",
        },
        {
          text: "C",
          value: "CUST_PPST_CD_03",
        },
        {
          text: "D",
          value: "CUST_PPST_CD_04",
        },
      ],

      D_BUSS_NAME: '',
      D_CMP_NAME: '',
      D_CNSLR_ID: '',
      D_COUNSL_DATE: '',
      D_VISET_RES_DATE: '',
      D_CUST_PPST_CD: '',
      D_CNSL_QUST_CONT: '',

      D_CUST_ADDRESS: '',
      ageRadioGroupSelected: '',
      ageRadioGroup: [
        {
          text: '20대',
          value: "AGE_CD01"
        },        {
          text: '30대',
          value: "AGE_CD02"
        },        {
          text: '40대',
          value: "AGE_CD03"
        },        {
          text: '50대',
          value: "AGE_CD04"
        },        {
          text: '60대 이상',
          value: "AGE_CD05"
        }
      ],

      subsRadioGroupSelected: '',
      subsRadioGroup: [
      {
          text: '특별공급',
          value: "CUST_SUBS_CD_01"
        },        {
          text: '1순위',
          value: "CUST_SUBS_CD_02"
        },        {
          text: '2순위',
          value: "CUST_SUBS_CD_03"
        },        {
          text: '선착순',
          value: "CUST_SUBS_CD_04"
        },        {
          text: '기타',
          value: "CUST_SUBS_CD_05"
        }
      ],

      areaRadioGroupSelected: '',
      areaRadioGroup: [
        {
          text: '60㎡ 이하',
          value: "CUST_AREA_CD_01"
        },      
        {
          text: '80㎡ 이하',
          value: "CUST_AREA_CD_02"
        },     
        {
          text: '85㎡ 이하',
          value: "CUST_AREA_CD_03"
        },     
        {
          text: '85㎡ 초과',
          value: "CUST_AREA_CD_04"
        },     
        {
          text: '기타',
          value: "CUST_AREA_CD_05"
        }  
      ],

      reasonRadioGroupSelected: '',
      reasonRadioGroup: [
        {
          text: '실거주',
          value: "CUST_REASON_CD_01"
        },      
        {
          text: '투자',
          value: "CUST_REASON_CD_02"
        },     
        {
          text: '기타',
          value: "CUST_REASON_CD_03"
        }   
      ],

      CUST_PPST_CD: '',
      F_BUSS_CODE: '',
      CMP_ID: '',
      cmpItems: [],
      allCodeList: [],
      CNSL_SEAT_NO: '', //상담사 좌석번호
      CUST_ID: '', //고객 고유키
      CNSL_WAIT_NUM: '', //고객 대기번호
      PLUS_FRED_KEY: '',

      KKA_NAME: '',
      KKA_PHONE: '',
      KKA_EMAIL: '',
      KKA_RANGE: '',
      KKA_GENDER: '',
      R_PRSN_AGRE_YN: '',
      R_MKTG_AGRE_YN: '',
      R_CUST_NAME: '',
      R_CNSLR_PHONE: '',
      R_CNSL_QUST_CONT: '',
      SURVEY_LIST: [],
      CNSL_STAT_CD: 'CNSL_STAT_CD_01', //고객 예약상태 코드
      GE_USER_ID: '', //상담원 ID
      BUSS_NAME: '',
      page: 1,
      pageCount: 0,
      COUNS_WAIT_TOTAL: 0,
      COUNS_TOTAL: 0,
      itemsPerPage: 7,
      totalVisible: 10,
      RDOnly: true,
      atrtGroupNm: '',
      userGroupCd: '',
      callAbledChk: false,
      rollChkBool: false,
      surveySaveBool: true,

      // 음성 재생 데이터
      voiceList: [],
      greetingSpeech: new window.SpeechSynthesisUtterance(),
      synth: window.speechSynthesis,
      voiceBtnBool: true,

      gridLeftDataText: [],
      gridRightDataText: [],
      selRowItems: [],
      selRowItem: {},
      gridDataHeaders: [
        { text: "No", value: "IDX", align: "center", width: "60px", sortable: true },
        { text: "캠페인명", value: "CMP_NAME", align: "center", width: "170px", sortable: true },
        { text: "예약 | 방문일시", value: "CNSL_RSVT_DT_RES", align: "center", width: "120px", sortable: true },
        { text: "상담신청일시", value: "CNSL_RSVT_DT", align: "center", width: "120px", sortable: true },
        { text: "상담사아이디", value: "CNSLR_ID", align: "center", width: "120px", sortable: true },
        { text: "고객등급", value: "CUST_PPST_NAME", align: "center", width: "80px", sortable: true },
        { text: "상담내용", value: "CNSL_QUST_CONT", align: "center", width: "100px", sortable: true },
        { text: "설문명", value: "QUTN_NM", align: "center", width: "130px", sortable: true },

        { text: "고객상담아이디", value: "CUST_CNSL_ID", width: '0px', align: 'center', sortable: false, "align": " d-none", show: false },
        { text: "고객등급", value: "CUST_PPST_CD", width: '0px', align: 'center', sortable: false, "align": " d-none", show: false },
        { text: "사업지코드", value: "BUSS_CODE", width: '0px', align: 'center', sortable: false, "align": " d-none", show: false },
        { text: "캠페인아이디", value: "CMP_ID", width: '0px', align: 'center', sortable: false, "align": " d-none", show: false },
        { text: "방문등록", value: "CNSL_STAT_NAME", width: '0px', align: 'center', sortable: false, "align": " d-none", show: false },
        { text: "설문 항목 리스트", value: "QUTN_ITEM_LIST", width: '0px', align: 'center', sortable: false, "align": " d-none", show: false },
        { text: "체크박스형 설문 답변", value: "CHK_QUTN_RPLY", width: '0px', align: 'center', sortable: false, "align": " d-none", show: false },
        { text: "텍스트형 설문 답변", value: "TXT_QUTN_RPLY", width: '0px', align: 'center', sortable: false, "align": " d-none", show: false },
        { text: "선택형 설문 답변", value: "SEL_QUTN_RPLY", width: '0px', align: 'center', sortable: false, "align": " d-none", show: false },
        { text: "기타 설문 답변(체크박스형)", value: "ETC_QUTN_RPLY", width: '0px', align: 'center', sortable: false, "align": " d-none", show: false },
      ],

      alertMsg: {
        error: '관리자에게 문의하세요.',
        confirmSave: '저장 하시겠습니까.',
        saveChkMsg: '현재 상담중인 고객정보\n상담종료 또는 미처리 후 호출바랍니다.',
        callChkMsg: '다음 대기자에게 상담 알림을 보내시겠습니까?',
        unprocessedMsg: '미처리 하시겠습니까?',
        saveMsg: '저장 하시겠습니까?',
        noWaitMsg: '대기 고객이 없습니다.',
        noBussCdMsg: '사업지가 존재하지 않습니다.',
        noCmpIdMsg: '캠페인이 존재하지 않습니다.',
        noSurveyMsg: '설문이 존재하지 않습니다.',
        custCallChk: '상담 고객을 호출해주세요.',
        surveyUpdateChkMsg: '설문을 수정하시겠습니까?',
        surveyUpdateMsg: '수정됐습니다.',
      },

      ACCESS_TOKEN: "",    //Arthur.Kim_20220924 알림톡토큰
      EXPIRES_IN: "",    //Arthur.Kim_20220924 알림톡토큰유효시간

      QUTN_ID: null,
      surveyCallBool: true,

      CMP_INFO_DATAS: [],

      IDX: 0,
      tabNum: 0,
      surveyExis: true,
      FIRST_QUTN_ID: '',

      ACT_CALL_BOOL: false,

      TODAY_CNSL_DT: '',

      // 대기 고객이 존재하지 않을 때
      NOT_FOUND_CUST_CODE: "404",
      
      // 필수 파라미터가 1개 이상 누락되었을 때
      BAD_REQUEST_CODE: "400"
    };
  },

  methods: {

    //Survey comp func
    fnSurveySave(surveyAfSave) {
      this.SURVEY_AF_DATAS = surveyAfSave;

      //사전예약 및 설문데이터 저장 테이블 key sett
      if (this.SURVEY_AF_DATAS.length > 0) {
        this.SURVEY_AF_DATAS.forEach((element, i) => {
          this.CMP_CNSL_ID = element.CMP_CNSL_ID;
        });
      }
    },

    //Survey comp func
    fnClosePage() { },

    //Survey comp func 설문 초반 호출 후 받는 데이터
    fnSurveyCmpDatas(datas) {
      if (!this.mixin_isEmpty(datas.QUTN_ID)) {
        this.BUSS_NAME = datas.BUSS_NAME;
        this.START_DATE = datas.CMP_STDT;
        this.END_DATE = datas.CMP_ENDT;
        this.PRESENT_DATE = datas.CMP_ENDT;
        this.QUTN_ID = datas.QUTN_ID;
      }
    },

    fnChangeTab(num) {
      this.tabNum = num;
      if (this.tabNum === 2 && this.surveyCallBool && !this.mixin_isEmpty(this.QUTN_ID)) {
        this.surveyCallBool = false;
        setTimeout(() => this.fnBFSurveySave(), 333);
      }

      if (this.mixin_isEmpty(this.QUTN_ID) && this.FIRST_QUTN_ID == this.QUTN_ID) {
        this.surveySaveBool = true;
      }
    },

    fnBFSurveySave() {

      if (this.mixin_isEmpty(this.CMP_ID)) {
        this.common_alert(this.alertMsg.noCmpIdMsg, 'chk');
        return;
      }

      if (this.mixin_isEmpty(this.BUSS_CODE)) {
        this.common_alert(this.alertMsg.noBussCdMsg, 'chk');
        return;
      }

      //사전예약자 현장방문 상담신청시 초기 셋팅
      if (!this.mixin_isEmpty(this.QUTN_ID)) {
        this.fnCmpCustSurveyCall();
      } else {
        // 미사전예약자 현장방문 상담신청시
        if (this.fnCmpInfoSet()) {
          this.fnCmpCustSurveyCall();
        } else {
          // 설문저장 버튼 비활성화
          this.surveySaveBool = true;
          this.common_alert(this.alertMsg.noSurveyMsg, 'chk');
        }
      }
    },

    fnCmpCustSurveyCall() {
      if (!this.mixin_isEmpty(this.$refs.Survey)) {

        // 설문 답안지 초기화
        this.$refs.Survey.fnRegistAf();

        // 설문저장 버튼 활성화
        setTimeout(() => this.fnSurveyModiBtnChk(this.selRowItem), 100);

        // 설문 함수 호출
        let datas = {
          CMP_ID: this.CMP_ID,
          BUSS_CODE: this.BUSS_CODE,
          CUST_PHONE: this.R_CNSLR_PHONE,
          QUTN_ID: this.QUTN_ID,
          PLUS_FRED_KEY: 'counseCall',
          COUNSL_CALL: true, //상담사 호출 여부
          ANS_BOOL: true, //답변 조회 여부
          CMP_CNSL_ID: this.selRowItem.CMP_CNSL_ID,
          CUST_CNSL_ID: this.selRowItem.CUST_CNSL_ID,
        };

        this.$refs.Survey.fnCounsSurveyCall(datas);
      }
    },

    fnCmpInfoSet() {
      if (this.CMP_INFO_DATAS.length > 0) {
        for (const iterator of this.CMP_INFO_DATAS) {
          //미사전예약 현장방문일 경우
          if (!this.mixin_isEmpty(iterator.CMP_QUTN_ID)) {
            this.QUTN_ID = iterator.CMP_QUTN_ID;
            return true;
          }
        }
        return false;
      } else {
        return false;
      }
    },

    //건설사별 사업지 조회
    async chkCtcpCdFn() {

      //사업지
      this.businessPlaceArr = [];
      this.BUSS_CODE = '';
      this.BUSS_NAME = '';

      //캠페인
      this.cmpItems = [];
      this.CMP_ID = '';

      const retDatas = await this.mixin_busscode_get(this.CTCP_CODE, '');

      for (let index = 0; index < retDatas.length; index++) {
        const element = retDatas[index];
        this.businessPlaceArr.push(element);
      }
    },

    async changeCampainList(bussCode) {
      //캠페인
      this.cmpItems = [];
      this.CMP_ID = '';

      if (!this.mixin_isEmpty(bussCode)) {
        let requestData = {
          headers: {
            SERVICE: 'hlw.campaign.manage',
            METHOD: 'list',
            TYPE: 'BIZ_SERVICE',
          },
          sendData: {}
        };

        requestData.headers["URL"] = "/api/hlw/campaign/manage/combo/list";
        requestData.sendData["BUSS_CODE"] = bussCode;

        let campaignList = await this.common_postCall(requestData, false);
        let resHeader = campaignList.HEADER;
        let resData = campaignList.DATA;

        if (resHeader.ERROR_FLAG) {
          this.common_alert(this.alertMsg.error, "error");
          return false;
        }

        this.cmpItems = resData;
      }
    },

    clickActive(item) {
      this.selectedRow = item;
      return item.IDX == this.IDX ? "active" : "";
    },

    async init() {

      const pArr = ['HLW_CTCP_CD'];
      this.allCodeList = await this.mixin_common_code_get_all(pArr);

      // 음성 목소리 list 추출
      this.voiceList = this.synth.getVoices();

      this.synth.onvoiceschanged = () => {
        this.voiceList = this.synth.getVoices();
      }

      this.ACCESS_TOKEN = this.access_token;
      this.EXPIRES_IN = this.expires_in;

      this.user_role = this.$store.getters["userStore/GE_USER_ROLE"];

      this.atrtGroupNm = this.user_role.atrtGroupNm;
      this.userGroupCd = this.user_role.userGroupCd;
      this.GE_USER_ID = this.user_role.userId;
      this.CNSL_SEAT_NO = this.user_role.seatNo;

      //상담사 좌석 데이터가 없을 경우
      if (this.mixin_isEmpty(this.CNSL_SEAT_NO)) {
        this.callAbledChk = true;
      }

      if (this.userGroupCd == "SYSTEMMANAGER" || this.userGroupCd == "MANAGER") {
        this.rollChkBool = true;
        this.USER_AUTH_CHK = this.userGroupCd;
        this.constructionCompanyNameArr = await this.mixin_common_code_get(this.allCodeList, 'HLW_CTCP_CD', '');
        this.RDOnly = false;
      } else if (this.userGroupCd == 'COUNSELOR' || this.userGroupCd == 'FIELDMANAGER') {

        this.constructionCompanyNameArr = await this.mixin_common_code_get(this.allCodeList, 'HLW_CTCP_CD', '');

        //로그인 된 상담사의 사업지 조회
        let usserAuth = await this.mixin_buss_ctcp_code_user_auto(this.GE_USER_ID);

        this.constructionCompanyNameArr = [{ CD_NM: usserAuth[0].CTCP_NAME, CD: usserAuth[0].CTCP_CODE }];
        this.businessPlaceArr = [{ CD_NM: usserAuth[0].BUSS_NAME, CD: usserAuth[0].BUSS_CODE }];
        this.BUSS_NAME = usserAuth[0].BUSS_NAME;
        this.CTCP_CODE = usserAuth[0].CTCP_CODE;

        if (!this.mixin_isEmpty(usserAuth[0].BUSS_CODE)) {
          this.BUSS_CODE = usserAuth[0].BUSS_CODE;
          this.F_BUSS_CODE = usserAuth[0].BUSS_CODE;

          if (this.mixin_isEmpty(this.BUSS_CODE)) {
            this.BUSS_CODE = this.user_role.bussCode;
            this.F_BUSS_CODE = this.user_role.bussCode;
          }

          this.fnBussChoice();

          this.CMP_ID = usserAuth[0].CMP_ID;
          if (this.mixin_isEmpty(this.CMP_ID)) {
            this.CMP_ID = this.user_role.cmpId;
          }

        }
      }
      
      await this.checkCustCycle();

      // 3초 마다 대기 명수, 상담 완료 건 check
      setInterval(async () => await this.checkCnslrCount(), 3000);
    },

    async checkCustCycle() {
      let requestData = {
        headers: {},
        sendData: {}
      };

      requestData.headers["URL"] = "/api/hlw/campaign/cnslr-checkCust/cycle";
      requestData.headers["SERVICE"] = "hlw.campaign.cnslr-checkCust.cycle";
      requestData.headers["METHOD"] = "POST";
      requestData.headers["ASYNC"] = false;

      requestData.sendData["BUSS_CODE"] = this.BUSS_CODE;
      requestData.sendData["CMP_ID"] = this.CMP_ID; 
      requestData.sendData["CNSLR_ID"] = this.GE_USER_ID;

      const response = await this.common_postCall(requestData);
      if(!response.HEADER.ERROR_FLAG) {
        const data = response.DATA[0]
        this.CMP_ID = data.CMP_ID;
        this.QUTN_ID = data.QUTN_ID;

        this.BUSS_CODE = data.BUSS_CODE;
        this.CUST_CNSL_ID = data.CUST_CNSL_ID; //고객상담아이디
        this.CUST_ID = data.CUST_ID;
        this.CNSL_WAIT_NUM = data.CNSL_WAIT_NUM;
        this.PLUS_FRED_KEY = data.PLUS_FRED_KEY;
        this.CNSL_SEAT_NO = data.CNSL_SEAT_NO;
        this.custDataSett(data); //필드 데이터 셋팅

        if (this.mixin_isEmpty(this.gridLeftDataText)) {
          this.getLeftData(false);
        }

        if (this.mixin_isEmpty(this.QUTN_ID)) {
        // 설문저장 버튼 비활성화
          this.surveySaveBool = true;
        }

        //고객정보 불러올시 호출버튼 활성화
        this.voiceBtnBool = false;

        if (this.tabNum == 2) {
          this.fnSurveyView();
        }
      }
    },

    async checkCnslrCount() {
      let requestData = {
        headers: {},
        sendData: {}
      };

      requestData.headers["URL"] = "/api/hlw/campaign/cnslr-checkCust/count";
      requestData.headers["SERVICE"] = "hlw.campaign.cnslr-checkCust.count";
      requestData.headers["METHOD"] = "POST";
      requestData.headers["ASYNC"] = false;

      requestData.sendData["BUSS_CODE"] = this.BUSS_CODE;
      requestData.sendData["CMP_ID"] = this.CMP_ID; 
      requestData.sendData["CNSLR_ID"] = this.GE_USER_ID;

      const response = await this.common_postCall(requestData, false);

      if(!response.HEADER.ERROR_FLAG) {
        this.COUNS_WAIT_TOTAL = response.DATA[0].CNSL_WAIT_COUNT;
        this.COUNS_TOTAL = response.DATA[0].CNSL_COMPLETE_COUNT
      }
    },
    async recallCust() {
      let requestData = {
        headers: {},
        sendData: {}
      };

      requestData.headers["URL"] = "/api/hlw/campaign/cnslr-callCust/recall";
      requestData.headers["SERVICE"] = "hlw.campaign.cnslr-callCust.recall";
      requestData.headers["METHOD"] = "POST";
      requestData.headers["ASYNC"] = false;

      requestData.sendData["BUSS_CODE"] = this.BUSS_CODE;
      requestData.sendData["CMP_ID"] = this.CMP_ID; 
      requestData.sendData["CNSLR_ID"] = this.GE_USER_ID;
      requestData.sendData["CUST_CNSL_ID"] = this.CUST_CNSL_ID;

      await this.common_postCall(requestData, false);
    },

    gridRowClick(item, row) {
      if (item != null) {
        this.IDX = item.IDX;
        this.D_BUSS_NAME = item.BUSS_NAME;
        this.D_CMP_NAME = item.CMP_NAME;
        this.D_CNSLR_ID = item.CNSLR_ID;
        this.D_COUNSL_DATE = item.CNSL_RSVT_DT;
        this.D_VISET_RES_DATE = item.CNSL_RSVT_DT_RES;
        this.D_CUST_PPST_CD = item.CUST_PPST_NAME;
        this.D_CNSL_QUST_CONT = this.decode(item.CNSL_QUST_CONT);

        this.selRowItem = item;

        this.QUTN_ID = item.QUTN_ID;
        if (!this.mixin_isEmpty(item.QUTN_ID) && this.tabNum == 2) {
          this.fnSurveyView();
        } else {
          this.fnSurveyModiBtnChk(item);
        }
      }
    },

    //설문 수정 버튼 활성/비활성 체크
    fnSurveyModiBtnChk(item) {

      // 현재 호출한 고객과 클릭한 row가 다를 경우
      if (this.CUST_CNSL_ID != item.CUST_CNSL_ID) {
        this.surveySaveBool = true;
      } else {
        this.surveySaveBool = false;
      }

      // 설문참여 없을 경우
      if (this.mixin_isEmpty(item.QUTN_ID)) {
        this.surveySaveBool = true;
        this.surveyExis = false;
      } else {
        this.surveyExis = true;
      }
    },

    async getLeftData(bool) {

      if (!this.mixin_isEmpty(this.CUST_ID)) {
        let pgBool = true;
        if (!this.mixin_isEmpty(pgBool)) {
          pgBool = bool;
        }

        let requestData = {
          headers: {},
          sendData: {},
        };

        //header 세팅
        requestData.headers["URL"] = "/api/hlw/campaign/cnslr-custHist/manage/list";
        requestData.headers["SERVICE"] = "hlw.campaign.cnslr-custHist.manage";
        requestData.headers["METHOD"] = "list";
        requestData.headers["ASYNC"] = false;

        requestData.sendData["BUSS_CODE"] = this.BUSS_CODE;
        requestData.sendData["CUST_ID"] = this.CUST_ID;
        requestData.sendData["CUST_PHONE"] = this.R_CNSLR_PHONE;
        requestData.sendData["CNSLR_ID"] = this.GE_USER_ID; // hjkim 추가

        let response = await this.common_postCall(requestData, pgBool);

        if (response.HEADER.ERROR_FLAG) {
          this.common_alert("관리자에게 문의해주세요.", "error");
        } else {
          this.gridLeftDataText = response.DATA;

          if (!this.mixin_isEmpty(response.DATA)) {
            this.selRowItem = response.DATA[0];
            this.FIRST_QUTN_ID = this.selRowItem.QUTN_ID;
            this.profileFplug(this.selRowItem);
            this.gridRowClick(this.selRowItem);
          }
        }
      }
    },

    profileFplug(custData) {
      this.KKA_NAME = custData["KKA_NAME"];
      this.KKA_PHONE = custData["KKA_PHONE"];
      this.KKA_EMAIL = custData["CUST_EMAIL"];
      this.KKA_RANGE = custData["CUST_RANGE"];
      this.KKA_GENDER = custData["CUST_GENDER"];

      if (this.KKA_GENDER === "female") {
        this.KKA_GENDER = "여성";
      } else if (this.KKA_GENDER === "male") {
        this.KKA_GENDER = "남성";
      } else {
        this.KKA_GENDER = "";
      }
    },

    custDataSett(custData) {
      this.R_PRSN_AGRE_YN = custData["MKTG_AGRE_YN"];
      this.R_MKTG_AGRE_YN = custData["PRSN_AGRE_YN"];
      //this.R_CUST_NAME = custData["CUST_NAME"];
      this.R_CNSLR_PHONE = custData["CUST_PHONE"];
      this.CUST_PHONE = custData["CUST_PHONE"];
      //this.R_CNSL_QUST_CONT = this.decode(custData["CNSL_QUST_CONT"]);
      this.radioGroupSelected = custData["CUST_PPST_CD"];

      // 2024.04.17 요구사항 추가 kjh
      this.ageRadioGroupSelected = custData["CUST_AGE"];
      this.subsRadioGroupSelected = custData["SUBS_CD"];
      this.areaRadioGroupSelected = custData["AREA_CD"];
      this.reasonRadioGroupSelected = custData["PURC_CD"];
    },  

    //***************************************
    //   상담내용 저장
    //***************************************
    fnUnprocessedCorm() {
      if (this.mixin_isEmpty(this.CMP_ID) || this.mixin_isEmpty(this.CUST_CNSL_ID) || this.mixin_isEmpty(this.CNSL_WAIT_NUM)) {
        this.common_alert('고객 정보를 호출해주세요.', 'chk');
        return;
      }

      this.common_confirm(this.alertMsg.unprocessedMsg, this.fnUnprocessedCustUpdateInfo, null, null, null, 'chk');
    },

    fnUnprocessedCustUpdateInfo() {
      this.updateCustInfo('미처리');
    },

    fnSaveCorm() {
      if (this.mixin_isEmpty(this.CMP_ID) || this.mixin_isEmpty(this.CUST_CNSL_ID) || this.mixin_isEmpty(this.CNSL_WAIT_NUM)) {
        this.common_alert('고객 정보를 호출해주세요.', 'chk');
        return;
      }

      this.common_confirm(this.alertMsg.saveMsg, this.fnCustUpdateInfo, null, null, null, 'chk');
    },

    fnSurveyView() {
      if (!this.voiceBtnBool && !this.mixin_isEmpty(this.QUTN_ID)) {
        this.surveyCallBool = false;
        setTimeout(() => this.fnBFSurveySave(), 222);
      } else {
        if (!this.voiceBtnBool && this.mixin_isEmpty(this.QUTN_ID)) {
          this.common_alert(this.alertMsg.noSurveyMsg, 'chk');
        } else {
          this.common_alert(this.alertMsg.custCallChk, 'chk');
        }
      }
    },

    fnSurveyUpdate() {
      if (!this.voiceBtnBool) {
        this.common_confirm(this.alertMsg.surveyUpdateChkMsg, this.fnSurveyUpdateCall, null, null, null, 'chk');
      } else {
        this.common_alert(this.alertMsg.custCallChk, 'chk');
      }
    },

    fnSurveyUpdateCall() {
      if (!this.mixin_isEmpty(this.$refs.Survey)) {

        let surveySaveData = {
          BUSS_CODE: this.F_BUSS_CODE
          , CUST_PHONE: this.R_CNSLR_PHONE
        };

        //설문 데이터 수정
        this.$refs.Survey.fnImgSave(surveySaveData);

        setTimeout(() => this.common_alert(this.alertMsg.surveyUpdateMsg, 'chk'), 777);
      }
    },

    fnCustUpdateInfo() {
      this.updateCustInfo('');
    },

    async updateCustInfo(param) {

      //설문 재호출 가능
      this.surveyCallBool = true;
      this.surveySaveBool = true;

      let requestData = {
        headers: {},
        sendData: {},
      };

      //header 세팅
      requestData.headers["URL"] = "/api/hlw/campaign/cnslr-custInfo/manage/update";
      requestData.headers["SERVICE"] = "hlw.campaign.cnslr-custHist.manage";
      requestData.headers["METHOD"] = "update";
      requestData.headers["ASYNC"] = false;

      requestData.sendData["CNSL_QUST_CONT"] = this.encode(this.R_CNSL_QUST_CONT);
      requestData.sendData["CUST_PPST_CD"] = this.radioGroupSelected;
      requestData.sendData["MKTG_AGRE_YN"] = this.R_MKTG_AGRE_YN ? 'Y' : 'N';
      requestData.sendData["PRSN_AGRE_YN"] = this.R_PRSN_AGRE_YN ? 'Y' : 'N';
      requestData.sendData["CNSL_SEAT_NO"] = this.CNSL_SEAT_NO;
      requestData.sendData["CUST_PHONE"] = this.R_CNSLR_PHONE;
      requestData.sendData["CUST_NAME"] = this.R_CUST_NAME;
      requestData.sendData["CUST_EMAIL"] = this.KKA_EMAIL;
      requestData.sendData["CUST_GENDER"] = this.KKA_GENDER;
      requestData.sendData["CUST_RANGE"] = this.KKA_RANGE;

      // 2024.04.17 요구사항 추가 kjh 
      requestData.sendData["CUST_ADDRESS"] = this.D_CUST_ADDRESS;
      requestData.sendData["CUST_AGE"] = this.ageRadioGroupSelected;
      requestData.sendData["SUBS_CD"] = this.subsRadioGroupSelected;
      requestData.sendData["AREA_CD"] = this.areaRadioGroupSelected;
      requestData.sendData["PURC_CD"] = this.reasonRadioGroupSelected;

      if (param === '미처리') { //고객 성향 미처리
        requestData.sendData["CNSL_STAT_CD"] = 'CNSL_STAT_CD_03'; //상담 미처리
      } else {
        requestData.sendData["CNSL_STAT_CD"] = 'CNSL_STAT_CD_04'; //상담 완료처리
      }

      requestData.sendData["CNSLR_ID"] = this.GE_USER_ID;
      requestData.sendData["REGR_ID"] = this.GE_USER_ID;
      requestData.sendData["CUST_CNSL_ID"] = this.CUST_CNSL_ID;

      let response = await this.common_postCall(requestData);

      if (response.HEADER.ERROR_FLAG) {
        this.common_alert("관리자에게 문의해주세요.", "error");
      } else {
        this.common_alert(`정상처리 되었습니다.`);

        //알림톡발송 
        if (param === '미처리') { //고객 미처리
          this.sendMessage("OUT_STAND", this.R_CNSLR_PHONE.replaceAll("-", ""), "");
        }

        this.fnCustDataReset();

        //좌측 상담이력 초기화
        this.gridLeftDataText = [];

        //상담 종료후 재고객 호출까지 호출버튼 비활성화
        this.voiceBtnBool = true;

        this.fnSurveyClear();
      }
    },

    fnSurveyClear() {
      if (!this.mixin_isEmpty(this.$refs.Survey)) {
        //설문 함수 호출
        let datas = {
          CMP_ID: null,
          BUSS_CODE: null,
          CUST_PHONE: null,
          QUTN_ID: null,
          PLUS_FRED_KEY: null,
          COUNSL_CALL: false,
          ANS_BOOL: false, //답변 조회 여부
        };

        this.$refs.Survey.fnCounsSurveyCall(datas);
      }
    },

    fnCustDataReset() {
      this.KKA_NAME = '';
      this.KKA_PHONE = '';
      this.KKA_EMAIL = '';
      this.KKA_RANGE = '';
      this.KKA_GENDER = '';
      this.R_PRSN_AGRE_YN = '';
      this.R_MKTG_AGRE_YN = '';
      this.R_CUST_NAME = '';
      this.R_CNSLR_PHONE = '';
      this.R_CNSL_QUST_CONT = '';
      this.radioGroupSelected = '';
      this.radioGroupSelectedSurvey = [];
      // this.CMP_ID=''
      this.CUST_CNSL_ID = ''; //고객상담아이디
      this.CUST_ID = '';
      this.CUST_PHONE = '';
      this.CNSL_WAIT_NUM = '';

      this.D_BUSS_NAME = '';
      this.D_CMP_NAME = '';
      this.D_CNSLR_ID = '';
      this.D_COUNSL_DATE = '';
      this.D_VISET_RES_DATE = '';
      this.D_CUST_PPST_CD = '';
      this.D_CNSL_QUST_CONT = '';

      // 2024. 04. 23 필드 추가 대응 kjh
      // 고객 주소
      this.D_CUST_ADDRESS = '';
      // 고객 나이대
      this.ageRadioGroupSelected = '';
      // 청약 유형
      this.subsRadioGroupSelected = '';
      // 선호 평형
      this.areaRadioGroupSelected = '';
      // 구입 목적
      this.reasonRadioGroupSelected = '';

    },

    /**
    * XSS Encode
    */
    encode(strText) {
      if (strText === "" || strText == null) {
        return strText;
      }

      strText = strText.replaceAll("(", "&#40;");
      strText = strText.replaceAll(")", "&#41;");
      strText = strText.replaceAll("[", "&#91;");
      strText = strText.replaceAll("]", "&#93;");
      strText = strText.replaceAll("&", "&amp;");
      strText = strText.replaceAll('"', "&quot;");
      strText = strText.replaceAll("'", "&apos;");
      strText = strText.replaceAll("\r", "<br/>");
      strText = strText.replaceAll("\n", "<p>");
      strText = strText.replaceAll("<", "&lt;");
      strText = strText.replaceAll(">", "&gt;");

      return strText;
    },

    /**
    * XSS Decode
    */
    decode(strText) {

      //값존재유무 체크
      if (strText === "" || strText == null) {
        return strText;
      }
      strText = strText.toString();

      //문자열 길이가 4이상일 경우만 처리
      if (strText.length <= 3) {
        return strText;
      }
      //순서바뀌면 안나옴
      strText = strText.replaceAll("&lt;", "<");
      strText = strText.replaceAll("&gt;", ">");
      strText = strText.replaceAll("&amp;", "&");
      strText = strText.replaceAll("&quot;", '"');
      strText = strText.replaceAll("&apos;", "'");
      strText = strText.replaceAll("<br/>", "\r");
      strText = strText.replaceAll("<p>", "\n");
      strText = strText.replaceAll("&#91;", "[");
      strText = strText.replaceAll("&#93;", "]");
      strText = strText.replaceAll("&#40;", "(");
      strText = strText.replaceAll("&#41;", ")");

      return strText;
    },

    fnVoice() {
      this.voiceBtnBool = true;
      setTimeout(() => { this.voiceBtnBool = false; }, 20000);   // 재호출 20초 딜레이
      
      this.recallCust()
    },

    fnBussChoice() {
      //상단 사업지명 표시
      for (const idx in this.businessPlaceArr) {
        const element = this.businessPlaceArr[idx];
        if (this.BUSS_CODE === element.CD) {
          this.BUSS_NAME = element.CD_NM;
        }
      }

      //캠페인 조회
      this.changeCampainList(this.BUSS_CODE);
    },

    fnCallBfCorm() {
      if (!this.mixin_isEmpty(this.CUST_CNSL_ID) && !this.mixin_isEmpty(this.CNSL_WAIT_NUM)) {
        this.common_alert(this.alertMsg.saveChkMsg, 'chk');
        return;
      }

      this.common_confirm(this.alertMsg.callChkMsg, this.fnWaitCustCall, null, null, null, 'chk');
    },

    fnWaitCustCall() {
      this.surveyCallBool = true;
      this.ACT_CALL_BOOL = true;

      this.callCounsel();
    },

    async callCounsel() {
      let requestData = {
        headers: {},
        sendData: {}
      };

      requestData.headers["URL"] = "/api/hlw/campaign/cnslr-callCust/manage";
      requestData.headers["SERVICE"] = "hlw.campaign.cnslr-callCust.manage";
      requestData.headers["METHOD"] = "POST";
      requestData.headers["ASYNC"] = false;

      requestData.sendData["BUSS_CODE"] = this.BUSS_CODE;
      requestData.sendData["CMP_ID"] = this.CMP_ID; 
      requestData.sendData["CNSL_SEAT_NO"] = this.CNSL_SEAT_NO;
      requestData.sendData["CNSLR_ID"] = this.GE_USER_ID;
      requestData.sendData["REGR_ID"] = this.GE_USER_ID;
      requestData.sendData["CUST_CNSL_ID"] = this.CUST_CNSL_ID;

      const response = await this.common_postCall(requestData);
      if(response.HEADER.ERROR_FLAG == true) {
        if(response.HEADER.ERROR_CODE == this.NOT_FOUND_CUST_CODE) {
          return this.common_alert(this.alertMsg.noWaitMsg, 'chk');
        }
        
        return this.common_alert(this.alertMsg.error, 'chk');
      }

      const data = response.DATA[0]
      this.CMP_ID = data.CMP_ID;
      this.QUTN_ID = data.QUTN_ID;

      this.BUSS_CODE = data.BUSS_CODE;
      this.CUST_CNSL_ID = data.CUST_CNSL_ID; //고객상담아이디
      this.CUST_ID = data.CUST_ID;
      this.CNSL_WAIT_NUM = data.CNSL_WAIT_NUM;
      this.PLUS_FRED_KEY = data.PLUS_FRED_KEY;
      this.CNSL_SEAT_NO = data.CNSL_SEAT_NO;
      this.custDataSett(data); //필드 데이터 셋팅

      if (this.mixin_isEmpty(this.gridLeftDataText)) {
        this.getLeftData(false);
      }

      if (this.mixin_isEmpty(this.QUTN_ID)) {
      // 설문저장 버튼 비활성화
        this.surveySaveBool = true;
      }

      //고객정보 불러올시 호출버튼 활성화
      this.voiceBtnBool = false;

      if (this.tabNum == 2) {
        this.fnSurveyView();
      }
    },

    async fnCallCustStatUpdate(bool) {

      let pgBool = true;
      if (!this.mixin_isEmpty(pgBool)) {
        pgBool = bool;
      }

      let requestData = {
        headers: {},
        sendData: {},
      };

      //header 세팅
      requestData.headers["URL"] = "/api/hlw/campaign/cnslr-custInfo/manage/update";
      requestData.headers["SERVICE"] = "hlw.campaign.cnslr-custHist.manage";
      requestData.headers["METHOD"] = "update";
      requestData.headers["ASYNC"] = false;

      requestData.sendData["CNSL_SEAT_NO"] = this.CNSL_SEAT_NO;
      requestData.sendData["CNSL_STAT_CD"] = 'CNSL_STAT_CD_02'; //상담중
      requestData.sendData["CNSLR_ID"] = this.GE_USER_ID;
      requestData.sendData["REGR_ID"] = this.GE_USER_ID;
      requestData.sendData["CUST_CNSL_ID"] = this.CUST_CNSL_ID;
      requestData.sendData["UPDATE_FLAG"] = "fnCallCustStatUpdate";

      let response = await this.common_postCall(requestData, pgBool);

      console.log("fnCallCustStatUpdate >>>>>>>>");
      console.log(response);

      return response.HEADER.ERROR_CODE;
    },

    async sendMessage(call_type, phone, frontNum, waitNum) {
      this.ACCESS_TOKEN = this.$store.getters['authStore/GE_ACCESS_TOKEN'];
      this.EXPIRES_IN = this.$store.getters['authStore/GE_EXPIRES_IN'];

      let requestData = {
        headers: {},
        sendData: {},
      };

      //header 세팅
      requestData.headers["URL"] = "/api/hlw/campaign/alarmTalk-msgformat/manage/list";
      requestData.headers["SERVICE"] = "hlw.campaign.alarmTalk-msgformat.manage";
      requestData.headers["METHOD"] = "list";
      requestData.headers["ASYNC"] = false;

      //sendData 초기화
      requestData.sendData["BUSS_CODE"] = this.BUSS_CODE;              //사업지 아이디
      requestData.sendData["CMP_ID"] = this.CMP_ID;                   //캠페인 아이디

      if (!this.mixin_isEmpty(call_type)) {
        requestData.sendData["MSG_TMPL_TYPE"] = call_type;
        console.log("알림톡발송이벤트시작[call_type]", call_type);
      } else {
        return;
      }

      let response = await this.common_postCall(requestData);

      if (!response.HEADER.ERROR_FLAG) {

        let data = response.DATA;
        let data_btn = response.DATA_BTN;

        if (data.length > 0) {

          let msgFormat = data[0].MSG_CONT;  //메세지내용
          msgFormat = msgFormat.replace(/#{사업지명}/g, data[0].BUSS_NAME)
            .replace(/#{대기번호1}/g, waitNum)
            .replace(/#{대기번호2}/g, waitNum)
            .replace(/#{고객명}/g, phone)
            .replace(/#{상담신청일시}/g, this.TODAY_CNSL_DT)
            .replace(/#{상담석번호}/g, this.CNSL_SEAT_NO)
            .replace(/#{내앞대기자수}/g, frontNum)

          // let msgFormatDecode = this.decode(msgFormat);

          let setButtons = [];
          if (data_btn.length > 0) {

            for (let i = 0; i < data_btn.length; i++) {
              setButtons.push({
                name: data_btn[i].BTN_NAME,
                type: data_btn[i].BTN_LINK_TYPE,
                url_mobile: data_btn[i].BTN_LINK_URL
              });
            }
          }
          const sendButton = { "button": setButtons };

          let requestAlarmTalkData = {
            headers: {},
            sendData: {},
          };

          let buttonToString = `'${JSON.stringify(sendButton)}'`;

          requestAlarmTalkData.headers["URL"] = "/api/hlw/campaign/alarmTalk-postmsg/manage/post";
          requestAlarmTalkData.headers["SERVICE"] = "hlw.campaign.alarmTalk-postmsg.manage";
          requestAlarmTalkData.headers["METHOD"] = "post";
          requestAlarmTalkData.headers["ASYNC"] = false;

          requestAlarmTalkData.sendData["CMP_ID"] = this.CMP_ID;
          requestAlarmTalkData.sendData["TRAN_SENDER_KEY"] = data[0].SNDR_KEY;
          requestAlarmTalkData.sendData["TRAN_TMPL_CD"] = data[0].MSG_TMPL_CODE;
          requestAlarmTalkData.sendData["TRAN_PHONE"] = phone;
          requestAlarmTalkData.sendData["TRAN_CALLBACK"] = data[0].SNDR_PHONE.replaceAll("-", "");
          requestAlarmTalkData.sendData["TRAN_MSG"] = msgFormat;
          requestAlarmTalkData.sendData["TRAN_REPLACE_MSG"] = msgFormat;
          if(sendButton["button"].length != 0) requestAlarmTalkData.sendData["TRAN_BUTTON"] = this.encode(buttonToString);

          let alarmTalkResponse = await this.common_postCall(requestAlarmTalkData);
          if (alarmTalkResponse.HEADER.ERROR_FLAG) {
            // Case. 알림톡 발송 시 ERROR FLAG를 반환한 케이스
            this.common_alert("관리자에게 문의해주세요.", "error");
          }
        } else {
          // Case. 해당 캠페인에 상담과 관련한 알림톡 템플릿이 없는 케이스
          // this.common_alert("관리자에게 문의해주세요.", "error");
        }
      } 
    },

    callNo() {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
    },
  
  },


  mounted() {
    this.init();
  },

  computed: {
    initHeaders() {
      return {
      };
    },
    paramData() {
      return {
      };
    }
  }
};
</script>

<style>
.v-textarea.v-input--is-disabled fieldset {
  background: #ffffff;
  border: 1px solid #ffffff;
  color: #ffffff important;
  background: #ffffff;
}

.form-inp.v-input--is-disabled fieldset {
  background: #ffffff;
  color: #ffffff important;
  background: #ffffff;
}

.counsTxt {
  font-size: 17px;
  font-weight: 500;
}

.fontFm {
  font-family: 'Spoqa Han Sans Neo', 'sans-serif';
}

.age-label {
  display: flex;
  align-items: center;
  justify-content: right;
}
</style>

