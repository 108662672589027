<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">IP내선번호관리</h1>
    <div class="box-wrap">
      <div class="box-ct d-flex">
        <div class="">
          <div class="form-inp sm">
            <v-select
              :items="ASP_CUST_KEY"
              item-value="CD"
              item-text="CD_NM"
              outlined
              hide-details
              label="회사구분"
              placeholder="선택하세요"
              v-model="ASP_NEWCUST_KEY"
              @change="chgAspCus"
              :readonly="RDOnly"
            >
              <template v-slot:label>
                회사구분
                <v-icon color="#EF9191">mdi-circle-small</v-icon>
              </template>
            </v-select>
          </div>

          <div class="form-inp sm ml-2">
            <v-text-field
              class="form-inp sm ml-2"
              name="name"
              label="IP주소"
              outlined
              hide-details
              v-model="IP_NEWADDR"
            >
              <template v-slot:label> IP주소 </template>
            </v-text-field>
          </div>
          <div class="form-inp sm ml-2 mr-2">
            <v-text-field
              class="form-inp sm ml-2"
              name="name"
              label="IP주소"
              outlined
              hide-details
              v-model="INLNE_NEWNO"
            >
              <template v-slot:label> 내선번호 </template>
            </v-text-field>
          </div>
          <v-text-field
            class="form-inp sm ml-2"
            name="name"
            label="사용자 ID"
            outlined
            hide-details
            v-model="USER_ID"
          >
          </v-text-field>
          <v-text-field
            class="form-inp sm ml-2"
            name="name"
            label="사용자 이름"
            outlined
            hide-details
            v-model="USER_NM"
          >
          </v-text-field>
          <v-text-field
            class="form-inp sm ml-2"
            name="name"
            label="사용자 닉네임"
            outlined
            hide-details
            v-model="USER_NICK"
          >
          </v-text-field>
          <!-- 조회 버튼 -->
          <v-btn
            v-if="this.mixin_set_btn(this.$options.name, 'btnPopupUser')"
            dark
            small
            class="btn-main-search pa-0 ml-2"
            min-width="32px"
            height="32px"
            plain
            @click="[btnUser1(), showDialog('MP02')]"
          >
            <v-icon small class="svg-main-searh white"></v-icon>
          </v-btn>
          <!-- 새로고침 버튼 -->
          <v-btn
            v-if="this.mixin_set_btn(this.$options.name, 'btnInitUser')"
            dark
            small
            class="btn-main-refresh pa-0 ml-2"
            min-width="32px"
            height="32px"
            @click="initUser"
            plain
          >
            <v-icon small class="svg-main-refresh"></v-icon>
          </v-btn>
        </div>

        <div class="ml-auto align-self-center">
          <v-btn
            v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')"
            outlined
            class="btn-etc2"
            v-on:click="selectIP"
            >조회</v-btn
          >
        </div>
      </div>
    </div>
    <!-- 내선번호 -->
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">IP내선관리목록</h2>
      <div class="box-ct">
        <v-data-table
          dense
          height="206px"
          :headers="gridDataHeaders"
          :items="gridDataText"
          :items-per-page="30"
          item-key="index"
          :page.sync="page"
          hide-default-footer
          class="grid-default"
          @page-count="pageCount = $event"
          @click:row="rowClick"
          show-select
          v-model="SELIP"
          no-data-text="검색된 내선번호가 없습니다."
          :item-class="isActiveRow"
        >
        </v-data-table>
        <div class="grid-paging text-center pt-2">
          <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="totalVisible"
            next-icon="svg-paging-next"
            prev-icon="svg-paging-prev"
          ></v-pagination>
        </div>
        <p class="caption text-right">
          <strong class="clr-error">{{ gridDataText.length }}</strong
          >건의 내선번호가 검색되었습니다.
        </p>
      </div>
    </div>
    <!--// 내선번호 -->

    <!-- 내선번호 상세 -->
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">
        IP내선관리상세
        <div class="ml-auto">
          <v-btn
            v-if="this.mixin_set_btn(this.$options.name, 'btnInitIP')"
            outlined
            class="btn-point"
            v-on:click="initBtn"
            >초기화</v-btn
          >
          <v-btn
            v-if="this.mixin_set_btn(this.$options.name, 'btnDelete')"
            outlined
            class="btn-etc ml-2"
            v-on:click="showAlertDel"
            >삭제</v-btn
          >
          <v-btn
            v-if="this.mixin_set_btn(this.$options.name, 'btnMody')"
            outlined
            class="btn-point ml-2"
            v-on:click="showAlertModi"
            >수정</v-btn
          >
          <v-btn
            v-if="this.mixin_set_btn(this.$options.name, 'btnRegi')"
            outlined
            class="btn-point ml-2"
            v-on:click="showAlertReg"
            >등록</v-btn
          >
        </div>
      </h2>
      <div class="box-ct">
        <div class="table-form">
          <table>
            <caption class="hide">
              IP내선관리상세
            </caption>
            <colgroup>
              <col width="127px" />
              <col width="427px" />
              <col width="127px" />
              <col width="427px" />
              <col width="127px" />
              <col width="" />
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">
                  <v-badge dot color="#EF9191">회사구분</v-badge>
                </th>
                <td>
                  <div class="form-inp full">
                    <v-select
                      single-line
                      v-model="ASP_DETAILCUST_KEY"
                      :items="ASP_CUST_KEY"
                      item-value="CD"
                      item-text="CD_NM"
                      outlined
                      label="회사구분"
                      multiple
                      hide-details
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip small>
                          <span>{{ item.CD_NM }}</span>
                          <template>
                            <v-btn
                              icon
                              x-small
                              class="svg-close20 ml-1"
                              v-show="dropItemRemove === true"
                              @click.stop="removeDropitem(index)"
                            ></v-btn>
                          </template>
                        </v-chip>
                      </template>
                    </v-select>
                  </div>
                </td>
                <th scope="row">IP주소</th>
                <td>
                  <v-text-field
                    class="form-inp full"
                    name="name"
                    value="IP주소"
                    id="id"
                    outlined
                    hide-details=""
                    v-model="IP_DETAILADDR"
                  >
                  </v-text-field>
                </td>
                <th scope="row">내선번호</th>
                <td>
                  <v-text-field
                    class="form-inp full"
                    name="name"
                    value="내선번호"
                    id="id"
                    outlined
                    hide-details=""
                    v-model="INLNE_DETAILNO"
                  >
                  </v-text-field>
                </td>
              </tr>
              <tr>
                <th scope="row">등록일시</th>
                <td colspan="3">
                  <v-text-field
                    class="form-inp full"
                    name="name"
                    value="등록일시"
                    id="id"
                    outlined
                    hide-details=""
                    v-model="REG_DETAILDTIM"
                    readonly
                  >
                  </v-text-field>
                </td>
                <th scope="row">등록자</th>
                <td>
                  <v-text-field
                    class="form-inp full"
                    name="name"
                    value="등록자"
                    id="id"
                    outlined
                    hide-details=""
                    v-model="REG_DETAILMAN"
                    readonly
                  >
                  </v-text-field>
                </td>
              </tr>
              <tr>
                <th scope="row">변경일시</th>
                <td colspan="3">
                  <v-text-field
                    class="form-inp full"
                    name="name"
                    value="변경일시"
                    id="id"
                    outlined
                    hide-details=""
                    v-model="CHNG_DETAILDTIM"
                    readonly
                  >
                  </v-text-field>
                </td>
                <th scope="row">변경자</th>
                <td>
                  <v-text-field
                    class="form-inp full"
                    name="name"
                    value="변경자"
                    id="id"
                    outlined
                    hide-details=""
                    v-model="CHNG_DETAILMAN"
                    readonly
                  >
                  </v-text-field>
                </td>
              </tr>
              <tr>
                <th scope="row">사용자ID</th>
                <td colspan="3">
                  <v-text-field
                    class="form-inp full"
                    name="name"
                    value="사용자ID"
                    id="id"
                    outlined
                    hide-details=""
                    v-model="USER_DETAILID"
                    readonly
                  >
                  </v-text-field>
                </td>
                <th scope="row" rowspan="2">비고</th>
                <td rowspan="2">
                  <v-textarea
                    class="textarea-type0"
                    label=""
                    :rules="textareaRules"
                    :value="textareaValue"
                    no-resize
                    outlined
                    v-model="DETAILREM"
                  ></v-textarea>
                </td>
              </tr>
              <tr>
                <th scope="row">사용자명</th>
                <td colspan="3">
                  <v-text-field
                    class="form-inp sm"
                    name="name"
                    value="사용자명"
                    id="id"
                    outlined
                    hide-details=""
                    v-model="CSLT_DETAILNM"
                    readonly
                  >
                  </v-text-field>
                  <!-- 조회 버튼 -->
                  <v-btn
                    v-if="
                      this.mixin_set_btn(
                        this.$options.name,
                        'btnDetailPopupUser'
                      )
                    "
                    dark
                    small
                    class="btn-main-search pa-0 ml-2"
                    min-width="32px"
                    height="32px"
                    plain
                    @click="[btnUser2(), showDialog('MP02')]"
                  >
                    <v-icon small class="svg-main-searh white"></v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!--// 내선번호 상세 -->

    <!-- 고객정보 모달창 -->
    <div class="">
      <v-dialog
        max-width="1400"
        max-height="1200"
        v-model="DialogMP02"
        content-class="square-modal"
      >
        <dialog-MP02
          headerTitle="고객정보"
          @hide="hideDialog('MP02')"
          :userInfo="userInfo"
          @input="userInfo = $event"
          @selectUser="[hideDialog('MP02'), setUser()]"
        >
        </dialog-MP02>
        <template v-slot:body> </template>
      </v-dialog>
    </div>
    <!-- 고객정보 모달창 -->
  </div>
</template>

<script>
import api from "../../store/apiUtil.js";
import { mixin } from "@/mixin/mixin.js";
import DialogMP02 from "./MP02.vue";

export default {
  name: "MENU_M110701", //name은 'MENU_' + 파일명 조합
  mixins: [mixin],
  components: {
    DialogMP02,
  },
  data() {
    return {
      DialogMP02: false,
      userInfo: {},
      dropItemRemove: true,
      gridDataHeaders: [
        {
          text: "No",
          align: "center",
          value: "index",
          width: "50px",
          sortable: false,
        },
        {
          text: "회사명",
          value: "ASP_NEWCUST_KEY",
          align: "center",
          sortable: false,
        },
        { text: "IP주소", value: "IP_ADDR", align: "center", sortable: false },
        {
          text: "내선번호",
          value: "INLNE_NO",
          align: "center",
          sortable: false,
        },
        {
          text: "사용자명",
          value: "CSLT_NM",
          align: "center",
          sortable: false,
        },
        {
          text: "사용자ID",
          value: "USER_ID",
          align: "center",
          sortable: false,
        },
        { text: "비고", value: "REM", align: "center", sortable: false },
      ],
      gridDataText: [],

      page: 1,
      pageCount: 0,
      itemsPerPage: 5,
      totalVisible: 10,

      textareaRules: [(v) => v.length <= 200 || "Max 200 characters"],
      textareaValue: "200자 제한!",

      ASP_CUST_KEY: this.$store.getters["userStore/GE_USER_ROLE"].company,

      ASP_NEWCUST_KEY: "",
      IP_NEWADDR: "",
      INLNE_NEWNO: "",
      USER_ID: "",
      USER_NM: "",
      USER_NICK: "",

      ASP_DETAILCUST_KEY: "",
      IP_DETAILADDR: "",
      INLNE_DETAILNO: "",
      REG_DETAILDTIM: "",
      REG_DETAILMAN: "",
      CHNG_DETAILDTIM: "",
      CHNG_DETAILMAN: "",
      USER_DETAILID: "",
      DETAILREM: "",
      CSLT_DETAILNM: "",

      SELIP: [],

      SELECT_USER: "",
      DETAIL_USER: "",

      RDOnly: false,
    };
  },

  created() {},

  methods: {
    removeDropitem(index) {
      this.ASP_DETAILCUST_KEY.splice(index, 1);
    },

    //조회버튼 클릭 이벤트
    selectIP() {
      if (this.ASP_NEWCUST_KEY == "" || this.ASP_NEWCUST_KEY == undefined) {
        this.showAlert("회사를 먼저 선택해주세요.");
        return;
      }
      api
        .post(
          "api/phone/setting/ip-lxtn-no-managee/list", //api url입력
          {
            ASP_NEWCUST_KEY: this.ASP_NEWCUST_KEY,
            IP_ADDR: this.IP_NEWADDR,
            INLNE_NO: this.INLNE_NEWNO,
            CSLT_ID: this.USER_ID,
            CSLT_NM: this.USER_NM,
            CSLT_NICK: this.USER_NICK,
            ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_CUST_KEY:
              this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD,
          },
          {
            head: {
              SERVICE: "phone/setting",
              METHOD: "/ip-lxtn-no-managee/list",
              TYPE: "BIZ_SERVICE",
              ROW_CNT: "200",
              PAGES_CNT: "1",
            },
          }
        )
        .then((response) => {
          console.log(response.data.DATA);
          this.gridDataText = response.data.DATA;
          for (var i = 0; i < this.gridDataText.length; i++) {
            this.gridDataText[i]["index"] = i + 1;
          }
          this.page = 1;

          this.initBtn();
        })
        .catch((err) => {
          alert(err);
        });
    },

    chgAspCus() {},

    initUser() {
      this.USER_ID = "";
      this.USER_NM = "";
      this.USER_NICK = "";
    },

    showDialog(menu) {
      if (this.ASP_NEWCUST_KEY == "" || this.ASP_NEWCUST_KEY == undefined) {
        this.showAlert("회사를 먼저 선택해주세요.");
        return;
      }
      this[`Dialog${menu}`] = true;
    },
    hideDialog(menu) {
      this[`Dialog${menu}`] = false;
    },

    btnUser1() {
      this.SELECT_USER = "1";
      this.DETAIL_USER = "0";
      var user = {
        ASP_CUST_KEY: this.ASP_NEWCUST_KEY,
        USER_ID: this.USER_ID,
        USER_NM: this.USER_NM,
        USER_NICK: this.USER_NICK,
      };
      this.userInfo = user;
    },

    btnUser2() {
      this.SELECT_USER = "0";
      this.DETAIL_USER = "1";
      var user = {
        ASP_CUST_KEY: this.ASP_NEWCUST_KEY,
        USER_ID: this.USER_ID,
        USER_NM: this.USER_NM,
        USER_NICK: this.USER_NICK,
      };
      this.userInfo = user;
    },

    setUser() {
      if (this.SELECT_USER == "1" && this.DETAIL_USER == "0") {
        this.USER_ID = this.userInfo.USER_ID;
        this.USER_NM = this.userInfo.USER_NM;
        this.USER_NICK = this.userInfo.USER_NICK;
      } else if (this.SELECT_USER == "0" && this.DETAIL_USER == "1") {
        this.USER_DETAILID = this.userInfo.USER_ID;
        this.CSLT_DETAILNM = this.userInfo.USER_NM;
      }
    },

    getMultCom() {
      api
        .post(
          "api/phone/setting/ip-lxtn-no-managee/aspkey_check", //api url입력
          {
            IP_ADDR: this.IP_DETAILADDR,
            INLNE_NO: this.INLNE_DETAILNO,
            ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_CUST_KEY:
              this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD,
          },
          {
            head: {
              SERVICE: "phone/setting",
              METHOD: "ip-lxtn-no-managee/aspkey_check",
              TYPE: "BIZ_SERVICE",
              ROW_CNT: "200",
              PAGES_CNT: "1",
            },
          }
        )
        .then((response) => {
          console.log(response.data.DATA);
          this.ASP_DETAILCUST_KEY =
            response.data.DATA[0].ASP_CUST_KEY.split(",");
        })
        .catch((err) => {
          alert(err);
        });
    },

    //내선번호 상세정보
    //row click이벤트
    rowClick(item, row, idx) {
      this.IP_DETAILADDR = item.IP_ADDR;
      this.INLNE_DETAILNO = item.INLNE_NO;
      this.REG_DETAILDTIM = this.resTime(item.REG_DTIM);
      this.REG_DETAILMAN = item.REG_MAN;
      this.CHNG_DETAILDTIM = this.resTime(item.CHNG_DTIM);
      this.CHNG_DETAILMAN = item.CHNG_MAN;
      this.USER_DETAILID = item.USER_ID;
      this.DETAILREM = item.REM;
      this.CSLT_DETAILNM = item.CSLT_NM;
      this.getMultCom();
      console.log("CCC" + this.ASP_DETAILCUST_KEY);
    },

    isActiveRow(item) {
      const activeClass = item.IP_ADDR === this.IP_DETAILADDR ? "active" : "";
      return activeClass;
    },

    resTime(time) {
      let DT_Y = time.substr(0, 4);
      let DT_MON = time.substr(4, 2);
      let DT_D = time.substr(6, 2);
      let DT_H = time.substr(8, 2);
      let DT_MIN = time.substr(10, 2);
      let DT_S = time.substr(12, 2);

      return (
        DT_Y +
        "-" +
        DT_MON +
        "-" +
        DT_D +
        " " +
        DT_H +
        ":" +
        DT_MIN +
        ":" +
        DT_S
      );
    },

    reqTime(time) {
      let DT1_Array = time.split("-");
      let DT2_Array = DT1_Array[2].split(" ");
      let DT3_Array = DT2_Array[1].split(":");

      return (
        DT1_Array[0] +
        DT1_Array[1] +
        DT2_Array[0] +
        DT3_Array[0] +
        DT3_Array[1] +
        DT3_Array[2]
      );
    },

    initBtn() {
      this.ASP_DETAILCUST_KEY = "";
      this.IP_DETAILADDR = "";
      this.INLNE_DETAILNO = "";
      this.REG_DETAILDTIM = "";
      this.REG_DETAILMAN = "";
      this.CHNG_DETAILDTIM = "";
      this.CHNG_DETAILMAN = "";
      this.USER_DETAILID = "";
      this.DETAILREM = "";
      this.CSLT_DETAILNM = "";
    },

    deleteBtn() {
      api
        .post(
          "api/phone/setting/ip-lxtn-no-managee/delete2", //api url입력
          {
            IP_ADDR: this.IP_DETAILADDR,
            INLNE_NO: this.INLNE_DETAILNO,
            ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_CUST_KEY:
              this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD,
          },
          {
            head: {
              SERVICE: "phone/setting",
              METHOD: "ip-lxtn-no-managee/aspkey_check",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          this.selectIP();
          this.initBtn();
          this.success();
        })
        .catch((err) => {
          this.fail();
        });
    },

    modiBtn() {
      console.log("ASP_DETAILCUST_KEY", this.ASP_DETAILCUST_KEY);
      let ASP = "";
      for (let i = 0; i < this.ASP_DETAILCUST_KEY.length; i++) {
        if (i == 0) {
          ASP = this.ASP_DETAILCUST_KEY[i];
        } else {
          ASP = ASP + "," + this.ASP_DETAILCUST_KEY[i];
        }
      }
      console.log("###########ASP_CUST_KEY :", this.ASP_DETAILCUST_KEY);
      console.log("###########USER_ID :", this.USER_DETAILID);
      api
        .post(
          "api/phone/setting/ip-lxtn-no-managee/modify", //api url입력
          {
            ASP_NEWCUST_KEY: ASP,
            IP_ADDR: this.IP_DETAILADDR,
            INLNE_NO: this.INLNE_DETAILNO,
            REM: this.DETAILREM,
            CHNG_MAN: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            USER_ID: this.USER_DETAILID,
            TRANS_STATUS: this.CSLT_DETAILNM,
            ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_CUST_KEY: this.ASP_DETAILCUST_KEY,
          },
          {
            head: {
              SERVICE: "phone/setting",
              METHOD: "ip-lxtn-no-managee/aspkey_check",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          this.selectIP();
          this.initBtn();
          this.success();
        })
        .catch((err) => {
          this.fail();
        });
    },

    saveBtn() {
      api
        .post(
          "api/phone/setting/ip-lxtn-no-managee/registe", //api url입력
          {
            ASP_NEWCUST_KEY: this.ASP_DETAILCUST_KEY,
            IP_ADDR: this.IP_DETAILADDR,
            INLNE_NO: this.INLNE_DETAILNO,
            REM: this.DETAILREM,
            REG_MAN: this.REG_DETAILMAN,
            CHNG_MAN: this.CHNG_DETAILMAN,
            USER_ID: this.USER_DETAILID,
            TRANS_STATUS: this.CSLT_DETAILNM,
            ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_CUST_KEY:
              this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD,
          },
          {
            head: {
              SERVICE: "phone/setting",
              METHOD: "ip-lxtn-no-managee/aspkey_check",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          this.selectIP();
          this.initBtn();
          this.success();
        })
        .catch((err) => {
          this.fail();
        });
    },

    showAlert(msg) {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: msg,
        iconClass: "svg-error-lg",
        type: "default",
      });
    },

    showAlertReg() {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: "IP내선번호를 저장하시겠습니까?",
        iconClass: "svg-chk-lg",
        type: "confirm",
        callYes: this.callYes1,
        callNo: this.callNo1,
      });
    },

    callYes1() {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
      this.saveBtn();
    },

    callNo1() {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
    },

    showAlertModi() {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: "IP내선번호를 수정하시겠습니까?",
        iconClass: "svg-chk-lg",
        type: "confirm",
        callYes: this.callYes2,
        callNo: this.callNo2,
      });
    },

    callYes2() {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
      this.modiBtn();
    },

    callNo2() {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
    },

    showAlertDel() {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: "IP내선번호를 삭제하시겠습니까?",
        iconClass: "svg-error-lg",
        type: "confirm",
        callYes: this.callYes3,
        callNo: this.callNo3,
      });
    },

    callYes3() {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
      this.deleteBtn();
      this.initBtn();
      this.selectIP();
    },

    callNo3() {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
    },

    success() {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: "정상처리 되었습니다.",
        iconClass: "svg-done-lg",
        type: "default",
      });
    },

    fail() {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: "시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.",
        iconClass: "svg-error-lg",
        type: "default",
      });
    },
  },
  computed: {
    dateRangeText() {
      var newStartDate = this.dates[0];
      var newEndDate = this.dates[1];
      return newStartDate + " ~ " + newEndDate;
    },
  },

  async mounted() {
    let codeName = ["PLT007"];
    let common_code = await this.mixin_common_code_get_all(codeName);
    this.CALL_TY = common_code[0].group_value;

    let chk = this.mixin_check_AspCustKey_number();
    if (chk == "Y") {
      this.ASP_NEWCUST_KEY =
        this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD;
      this.RDOnly = true;
    } else {
      this.RDOnly = false;
    }
  },

  watch: {},
};
</script>

<style></style>
