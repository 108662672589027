import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VuetifyDialog from "vuetify-dialog";
import store from "./store";
import CKEditor from "ckeditor4-vue";
import { mixin } from "./mixin/mixin"
import VueMeta from 'vue-meta';
import vueCookies from "vue-cookies";

Vue.config.productionTip = false;

// 페이지 제목 설정
const DEFAULT_TITLE = 'HLW ADMIN';
router.afterEach(to => {
	Vue.nextTick(() => {
		document.title = to.meta.title || DEFAULT_TITLE
	})
});

Vue.mixin(mixin);

Vue.use(CKEditor);
Vue.use(VueMeta);
Vue.use(vueCookies);

new Vue({
  router,
  vuetify,
  store,
  VuetifyDialog,
  render: (h) => h(App),
}).$mount("#app");
