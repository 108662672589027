<template>
  <div class="modal-wrap">
    <h1 class="tit-h1 d-flex">
      {{ param.headerTitle }}
      <div class="ml-auto align-self-center">
        <v-icon x-small class="svg-close20" @click="$emit('hide')"></v-icon>
      </div>
    </h1>
    <div class="modal-box">
      <div>
        <v-form ref="form"
          v-model="valid"
          lazy-validation
        >
        <div class="table-form">
          <table>
            <caption class="hide">
              정보 항목
            </caption>
            <colgroup>
              <col width="15%" />
              <col width="35%" />
              <col width="15%" />
              <col width="35%" />
            </colgroup>
              <tbody>
                <tr>
                  <th scope="row">
                    <v-badge dot color="#EF9191">공통코드타입</v-badge>
                  </th>
                  <td>
                    <v-text-field
                      class="form-inp full"
                      name="GROUP_CD"
                      outlined
                      v-model="paramData.GROUP_CD"
                      :disabled="is.groupCd"
                      maxlength="20"
                      :rules="validateRules.groupCd"
                      required
                    >
                    </v-text-field>
                  </td>
                  <th scope="row">
                    <v-badge dot color="#EF9191">상세코드</v-badge>
                  </th>
                  <td>
                    <v-text-field
                      class="form-inp full"
                      name="CD"
                      outlined
                      v-model="paramData.CD"
                      :disabled="is.cd"
                      maxlength="20"
                      :rules="validateRules.cd"
                      required
                    >
                    </v-text-field>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <v-badge dot color="#EF9191">상세코드명</v-badge>
                  </th>
                  <td colspan="3">
                    <v-text-field
                      class="form-inp full"
                      name="CD_NM"
                      outlined
                      v-model="paramData.CD_NM"
                      maxlength="20"
                      :rules="validateRules.cdNm"
                      required
                    >
                    </v-text-field>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <v-badge dot color="#EF9191">사용시작일</v-badge>
                  </th>
                  <td>
                    <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        content-class="calendar-modal"
                        dark
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="date"
                            v-bind="attrs"
                            v-on="on"
                            outlined
                            hide-details
                            class="form-inp full icon-calendar"
                            append-icon="svg-calendar"
                            readonly
                            :disabled="is.cdUseFrDt"
                            :rules="validateRules.cdUseFrDt"
                            required
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                          v-model="date"
                          color="#F7AFAE"
                          @input="menu2 = false"
                          :first-day-of-week="1"
                          locale="ko-KR"
                          :day-format="mixin_getDate"
                      ></v-date-picker>
                    </v-menu>
                  </td>
                  <th scope="row">
                    <v-badge dot color="#EF9191">사용종료일</v-badge>
                  </th>
                  <td>
                    <v-menu
                        v-model="menu1"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        content-class="calendar-modal"
                        dark
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="date2"
                            v-bind="attrs"
                            v-on="on"
                            outlined
                            hide-details
                            class="form-inp full icon-calendar"
                            append-icon="svg-calendar"
                            readonly
                            :disabled="is.cdUseToDt"
                            :rules="validateRules.cdUseToDt"
                            required
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                          v-model="date2"
                          color="#F7AFAE"
                          @input="menu1 = false"
                          :first-day-of-week="1"
                          locale="ko-KR"
                          :day-format="mixin_getDate"
                      ></v-date-picker>
                    </v-menu>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <v-badge dot color="#EF9191">코드구분</v-badge>
                  </th>
                  <td colspan="3">
                    <v-text-field
                      class="form-inp full"
                      name="CD_TYPE"
                      outlined

                      v-model="paramData.CD_TYPE"
                      :disabled="is.cdType"
                      maxlength="20"
                      :rules="validateRules.cdType"
                      required
                    >
                    </v-text-field>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <v-badge dot color="#EF9191">정렬번호</v-badge>
                  </th>
                  <td>
                    <v-text-field
                      class="form-inp full"
                      name="SORT_ORD"
                      type="number"
                      id="sortOrd"
                      min="0"
                      max="9999"
                      v-model.number.lazy="paramData.SORT_ORD"
                      :rules="validateRules.sortOrd"
                      required
                    ></v-text-field>
                  </td>
                  <th scope="row">기타코드1</th>
                  <td>
                    <v-text-field
                      class="form-inp full"
                      name="ETC_INFO01"
                      outlined
                      hide-details=""
                      v-model="paramData.ETC_INFO01"
                      :disabled="is.etcInfo01"
                    >
                    </v-text-field>
                  </td>
                </tr>
                <tr>
                  <th scope="row">기타코드2</th>
                  <td>
                    <v-text-field
                      class="form-inp full"
                      name="ETC_INFO02"
                      outlined
                      hide-details=""
                      v-model="paramData.ETC_INFO02"
                      :disabled="is.etcInfo02"
                    >
                    </v-text-field>
                  </td>
                  <th scope="row">기타코드3</th>
                  <td>
                    <v-text-field
                      class="form-inp full"
                      name="ETC_INFO03"
                      outlined
                      hide-details=""
                      v-model="paramData.ETC_INFO03"
                      :disabled="is.etcInfo03"
                    >
                    </v-text-field>
                  </td>
                </tr>
              </tbody>
          </table>
        </div>
      </v-form>
      <div class="text-right mt-3">
        <v-btn  outlined class="btn-default ml-2" @click="submit"  >저장</v-btn>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "MENU_COP0200", //name은 'MENU_' + 파일명 조합
    props: {
      param: {
        type: Object,
      },
    },
    data(){
      return {
        menu1: false,
        menu2: false,
        date: {},
        date2: {},
        valid: true,

        validateRules:{
          groupCd: [
            v => !!v || '공통코드타입은 필수입니다.',
            // v => /^[a-zA-Z0-9]*$/.test(v)  || '영문, 숫자만 사용 가능합니다.',
          ],
          cd: [
            v => !!v || '코드는 필수입니다.',
            // v => /^[a-zA-Z0-9]*$/.test(v)  || '영문, 숫자만 사용 가능합니다.',
          ],
          cdNm: [
            v => !!v || '공통코드명은 필수입니다.',
          ],
          cdUseFrDt: [
            v => !!v || '사용시작일은 필수입니다.',
            v => /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/.test(v) || '날짜는 YYYY-MM-DD 형식으로 작성해 주세요.',
          ],
          cdUseToDt: [
            v => !!v || '사용종료일은 필수입니다.',
            v => /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/.test(v) || '날짜는 YYYY-MM-DD 형식으로 작성해 주세요.',
          ],
          cdType: [
            v => !!v || '코드구분은 필수입니다.',
          ],
          sortOrd: [
            //v => !!v || '정렬번호는 필수입니다.',
            v => /^[0-9]*$/.test(v) || '정렬번호는 숫자만 입력 가능합니다.',
            v => (v >= 0 && v < 10000 ) || '0 ~ 9999 이내로 입력해 주세요.',
          ],
        },
        footerHideTitle: "닫기",
        useYnDropItems: [
          { name: "사용", value:"Y" },
          { name: "미사용", value:"N" },
        ],
        is: {
          groupCd: false,
          cd: true,
          cdUseFrDt: false,
          cdUseToDt: false,
          cdType: false,
          etcInfo01: false,
          etcInfo02: false,
          etcInfo03: false,
        },
      }
    },
    methods:{
      init(){
        let type = this.param.type;

        if(type == "R"){
          this.date= this.getDateFormat(this.paramData.CD_USE_FR_DT);
          this.date2= this.getDateFormat(this.paramData.CD_USE_TO_DT);
          this.is = {
            groupCd: true,
            cd: true,
            cdUseFrDt: true,
            cdUseToDt: true,
            cdType: true,
            etcInfo01: true,
            etcInfo02: true,
            etcInfo03: true,
          };
        }else{
          this.is = {
            groupCd: true,
            cd: false,
            cdUseFrDt: false,
            cdUseToDt: false,
            cdType: false,
            etcInfo01: false,
            etcInfo02: false,
            etcInfo03: false,
          };
          this.paramData.CD = "";
          this.paramData.CD_NM = "";
          this.date= new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
              .toISOString()
              .substr(0, 10);
          this.date2= new Date("2999-12-31").toISOString().substr(0, 10);
          this.paramData.CD_USE_FR_DT = this.date;
          this.paramData.CD_USE_TO_DT = this.date2;
          this.paramData.SORT_ORD = 0;
          this.paramData.ETC_INFO01 = "";
          this.paramData.ETC_INFO02 = "";
          this.paramData.ETC_INFO03 = "";
        }
      },
      getDateFormat(value){
        let resultDate = value.substr(0,4) + "-" + value.substr(4,2) + "-" + value.substr(6,2);
        return resultDate;
      },
      removeChar(event){
        let num = Number(event.target.value);
        if(isNaN(num)){
          this.paramData.SORT_ORD = 0;
        }else{
          this.paramData.SORT_ORD = num;
        }
      },
      validate () {
        return this.$refs.form.validate();
      },
      reset () {
        this.$refs.form.reset();
      },
      resetValidation () {
        this.$refs.form.resetValidation();
      },
      async submit(){
        let type = this.param.type;

        if(!this.validate()){
          return;
        }

        let requestData = {
          headers: {
            SERVICE: this.initHeaders.SERVICE,
            METHOD: this.initHeaders.METHOD,
            TYPE: this.initHeaders.TYPE,
          },
          sendData:{}
        };


        this.paramData.CD_USE_FR_DT = this.date.replaceAll("-","");
        this.paramData.CD_USE_TO_DT = this.date2.replaceAll("-","");

        // 신규
        if(type === "C"){
          requestData.headers["URL"] = "/api/setting/system/cmmn-code-manage/regist";
          requestData.headers["METHOD"] = "regist";
          requestData.headers["ASYNC"] = false;

          requestData.sendData= {};

          for(let prop in this.paramData){
            requestData.sendData[prop] = this.paramData[prop];
          }
          requestData.sendData["USE_YN"]= "Y";
          requestData.sendData["DATA_FLAG"]= "I";
          requestData.sendData["STR_DIV"]= "TYPE";
          requestData.sendData["PROC_ID"]= this.user_id;
          requestData.sendData["REGR_DEPT_CD"]= "x";
          requestData.sendData["REGR_ID"]= this.user_id;
          requestData.sendData["CD_PRE_TYPE"]= "HLW";
        }else{ // 상세 업데이트
          requestData.headers["URL"] = "/api/setting/system/cmmn-code-manage/modify";
          requestData.headers["METHOD"] = "modify";
          requestData.headers["ASYNC"] = false;
          requestData.sendData= {};
          for(let prop in this.paramData){
            requestData.sendData[prop] = this.paramData[prop];
          }
          requestData.sendData["DATA_FLAG"]= "U";
          requestData.sendData["STR_DIV"]= "TYPE";
          requestData.sendData["PROC_ID"]= this.user_id;
          requestData.sendData["AMDR_DEPT_CD"]= "x";
          requestData.sendData["AMDR_ID"]= this.user_id;
        }
        const response = await this.common_postCall(requestData);
        this.submitCallBack(response);
      },

      submitCallBack(response){
        if(this.mixin_isEmpty(response) || response.HEADER.ERROR_FLAG) {
          let msg = `시스템에 오류가 발생하였습니다.`;
          if(!this.mixin_isEmpty(response.HEADER.ERROR_MSG)) {
            msg = response.HEADER.ERROR_MSG;
          }
          this.common_alert(msg, 'error');
          return false;
        }
        this.common_alert(`정상처리 되었습니다.`);
        this.$emit('hideAndGetDetailList');
        return;
      },
    },
    mounted() {
      this.init();
    },
    computed: {
      initHeaders(){
        return {
          SERVICE: 'setting.system.cmmn-code-manage',
          METHOD: "",
          TYPE: 'BIZ_SERVICE',
        };
      },
      paramData(){
        return {
          GROUP_CD: this.param.data.GROUP_CD,
          CD: this.param.data.CD,
          CD_NM: this.param.data.CD_NM,
          CD_USE_FR_DT: this.param.data.CD_USE_FR_DT,
          CD_USE_TO_DT: this.param.data.CD_USE_TO_DT,
          CD_TYPE: this.param.data.CD_TYPE,
          USE_YN: this.param.data.USE_YN,
          SORT_ORD: this.param.data.SORT_ORD,
          ETC_INFO01: this.param.data.ETC_INFO01,
          ETC_INFO02: this.param.data.ETC_INFO02,
          ETC_INFO03: this.param.data.ETC_INFO03,
        };
      }
    },
  };
</script>

<style></style>
