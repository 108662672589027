<template>
  <div class="modal-wrap">
    <h1 class="tit-h1 d-flex">
      {{ headerTitle }}
      <div class="ml-auto align-self-center">
        <v-icon x-small class="svg-close20" @click="$emit('hide')"></v-icon>
      </div>
    </h1>
    <div class="modal-box chat-dialog">
      <div class="layout-chatmain--chat-history-box">
         <template v-for="(item, index) in talkHistory">
          <div :class="
            item.SNDRCV_CD == 'RCV'
              ? 'layout-chatmain--chat-history--cs-msg'
              : 'layout-chatmain--chat-history--manager-msg'
          " :key="index">
            <img v-if="item.TYPE=='IM' || 'photo'" :src="item.IMAGE_URL">
            <div v-if="item.TYPE!='IM'">
              {{ item.CONTENT }}
            </div>
            <div v-if="item.TYPE!='IM'" class="layout-chatmain--chat-history--time">
              {{ item.CHAT_DATE }}
            </div>
          </div>
        </template>
      </div>
      <template v-if="HAS_BTN" >
        <div class="layout-chatmain--chat-history--form mt-2">
          <v-textarea
           label=""
           no-resize
           outlined
           placeholder="내용을 입력하세요"
           background-color="white"
           dense
           class="layout-chatmain--chat-history--form-textarea textarea-type0"
           v-model.trim="CHAT_MSG"
           v-on:keyup.enter="sendMsg"
           ></v-textarea>
        </div>
        <div class="text-right mt-2">
          <v-btn
            outlined
            class="btn-point ml-auto"
            v-on:click="sendMsg"
            >
              전송
            </v-btn>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { mixin } from "@/mixin/mixin.js"

export default {
  name: "MENU_ChatHistoryModal", //name은 'MENU_' + 파일명 조합
  mixins: [mixin],
  props: {
    params:{
      type: Object,
      default: () => ({})
    },
    headerTitle: {
      type: String,
      default: ''
    },
    HAS_BTN: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      CHAT_MSG: '',
      talkHistory: [
        {
          'SNDRCV_CD' : 'RCV',
          'TYPE' : '',
          'CONTENT' : '채팅 대화 내역입니다. 채팅 대화 내역입니다. 채팅 대화 내역입니다.채팅 대화 내역입니다.채팅 대화 내역입니다.채팅 대화 내역입니다.',
          'CHAT_DATE' : '13:00'
        },
        {
          'SNDRCV_CD' : '',
          'TYPE' : '',
          'CONTENT' : '채팅 대화 내역입니다. 채팅 대화 내역입니다. 채팅 대화 내역입니다.채팅 대화 내역입니다.채팅 대화 내역입니다.채팅 대화 내역입니다.22222',
          'CHAT_DATE' : '13:00'
        },
        {
          'SNDRCV_CD' : 'RCV',
          'TYPE' : '',
          'CONTENT' : '채팅 대화 내역입니다. 채팅 대화 내역입니다. 채팅 대화 내역입니다.채팅 대화 내역입니다.채팅 대화 내역입니다.채팅 대화 내역입니다.333333',
          'CHAT_DATE' : '13:00'

        },
        {
          'SNDRCV_CD' : '',
          'TYPE' : '',
          'CONTENT' : '채팅 대화 내역입니다. 채팅 대화 내역입니다. 채팅 대화 내역입니다.채팅 대화 내역입니다.채팅 대화 내역입니다.채팅 대화 내역입니다.4444',
          'CHAT_DATE' : '13:00'
        },
        {
          'SNDRCV_CD' : 'RCV',
          'TYPE' : '',
          'CONTENT' : '채팅 대화 내역입니다. 채팅 대화 내역입니다. 채팅 대화 내역입니다.채팅 대화 내역입니다.채팅 대화 내역입니다.채팅 대화 내역입니다.',
          'CHAT_DATE' : '13:00'
        },
        {
          'SNDRCV_CD' : '',
          'TYPE' : '',
          'CONTENT' : '채팅 대화 내역입니다. 채팅 대화 내역입니다. 채팅 대화 내역입니다.채팅 대화 내역입니다.채팅 대화 내역입니다.채팅 대화 내역입니다.22222',
          'CHAT_DATE' : '13:00'
        },
        {
          'SNDRCV_CD' : 'RCV',
          'TYPE' : '',
          'CONTENT' : '채팅 대화 내역입니다. 채팅 대화 내역입니다. 채팅 대화 내역입니다.채팅 대화 내역입니다.채팅 대화 내역입니다.채팅 대화 내역입니다.333333',
          'CHAT_DATE' : '13:00'

        },
        {
          'SNDRCV_CD' : '',
          'TYPE' : '',
          'CONTENT' : '채팅 대화 내역입니다. 채팅 대화 내역입니다. 채팅 대화 내역입니다.채팅 대화 내역입니다.채팅 대화 내역입니다.채팅 대화 내역입니다.4444',
          'CHAT_DATE' : '13:00'
        },
        {
          'SNDRCV_CD' : 'RCV',
          'TYPE' : '',
          'CONTENT' : '채팅 대화 내역입니다. 채팅 대화 내역입니다. 채팅 대화 내역입니다.채팅 대화 내역입니다.채팅 대화 내역입니다.채팅 대화 내역입니다.',
          'CHAT_DATE' : '13:00'
        },
        {
          'SNDRCV_CD' : '',
          'TYPE' : '',
          'CONTENT' : '채팅 대화 내역입니다. 채팅 대화 내역입니다. 채팅 대화 내역입니다.채팅 대화 내역입니다.채팅 대화 내역입니다.채팅 대화 내역입니다.22222',
          'CHAT_DATE' : '13:00'
        },
        {
          'SNDRCV_CD' : 'RCV',
          'TYPE' : '',
          'CONTENT' : '채팅 대화 내역입니다. 채팅 대화 내역입니다. 채팅 대화 내역입니다.채팅 대화 내역입니다.채팅 대화 내역입니다.채팅 대화 내역입니다.333333',
          'CHAT_DATE' : '13:00'

        },
        {
          'SNDRCV_CD' : '',
          'TYPE' : '',
          'CONTENT' : '채팅 대화 내역입니다. 채팅 대화 내역입니다. 채팅 대화 내역입니다.채팅 대화 내역입니다.채팅 대화 내역입니다.채팅 대화 내역입니다.4444',
          'CHAT_DATE' : '13:00'
        },
        {
          'SNDRCV_CD' : 'RCV',
          'TYPE' : '',
          'CONTENT' : '채팅 대화 내역입니다. 채팅 대화 내역입니다. 채팅 대화 내역입니다.채팅 대화 내역입니다.채팅 대화 내역입니다.채팅 대화 내역입니다.',
          'CHAT_DATE' : '13:00'
        },
        {
          'SNDRCV_CD' : '',
          'TYPE' : '',
          'CONTENT' : '채팅 대화 내역입니다. 채팅 대화 내역입니다. 채팅 대화 내역입니다.채팅 대화 내역입니다.채팅 대화 내역입니다.채팅 대화 내역입니다.22222',
          'CHAT_DATE' : '13:00'
        },
        {
          'SNDRCV_CD' : 'RCV',
          'TYPE' : '',
          'CONTENT' : '채팅 대화 내역입니다. 채팅 대화 내역입니다. 채팅 대화 내역입니다.채팅 대화 내역입니다.채팅 대화 내역입니다.채팅 대화 내역입니다.333333',
          'CHAT_DATE' : '13:00'

        },
        {
          'SNDRCV_CD' : '',
          'TYPE' : '',
          'CONTENT' : '채팅 대화 내역입니다. 채팅 대화 내역입니다. 채팅 대화 내역입니다.채팅 대화 내역입니다.채팅 대화 내역입니다.채팅 대화 내역입니다.4444',
          'CHAT_DATE' : '13:00'
        },
        {
          'SNDRCV_CD' : null,
          'TYPE' : 'photo',
          'CONTENT' : '채팅 대화 내역입니다. 채팅 대화 내역입니다. 채팅 대화 내역입니다.채팅 대화 내역입니다.채팅 대화 내역입니다.채팅 대화 내역입니다.',
          'CHAT_DATE' : '13:00',
          'IMAGE_URL' : ''

        }
      ]

    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    sendMsg() {
      console.log('sendMsg');
    }
  },
  filters: {

  },
  computed: {

  },
}
</script>

<style></style>
