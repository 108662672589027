<template>
  <div class="modal-wrap">
    <h1 class="tit-h1 d-flex">
      {{ headerTitle }}
     <div class="ml-auto align-self-center">
        <v-icon x-small class="svg-close20" @click="$emit('hide')"></v-icon>
      </div>
    </h1>
    <div class="modal-box">
       <div class="mt-2">
        <v-data-table
          dense
          :headers="gridDataHeaders"
          :items="gridDataText"
          item-key="SEQ"
          hide-default-footer
          class="grid-default case03"
          single-select
          fixed-header
          :items-per-page="30"
          :page.sync="page"
          @page-count="pageCount = $event"
          @click:row="getListSel"
          v-model="selectedRow"
        >
        </v-data-table>
      </div>

      <div class="grid-paging text-center pt-2">
        <v-pagination
          v-model="page"
          :length="pageCount"
          :total-visible="totalVisible"
          next-icon="svg-paging-next"
          prev-icon="svg-paging-prev"
        ></v-pagination>
        <p class="caption text-left">
          총 <strong class="clr-error">{{ gridDataText.length }}</strong
          >건
        </p>
      </div>

    </div>
  </div>
</template>

<script>
import {mixin} from '@/mixin/mixin.js'
export default {
  name: "MENU_M510201P05", //name은 'MENU_' + 파일명 조합
  props: {
    headerTitle: {
      type: String,
      default: "제목",
    },
    CNTNT_ID : {
      type : String,
      default: "",
    },
    ASP_NEWCUST_KEY : {
      type : String,
      default: "",
    },
  },
  mixins : [mixin],
  data: () => ({
    selectedRow : [],
    gridDataHeaders: [
        { text: "순서", value: "SEQ", align: "center", sortable: true },
        { text: "버전", value: "VER", align: "center", sortable: true },
        { text: "수정내용", value: "CHG_HIST_CONTN", align: "left", sortable: true },
        { text: "등록자", value: "REGR_NM", align: "left", sortable: true },
        { text: "등록일시", value: "REG_DTTM", align: "center", sortable: true },
      ],
    gridDataText: [],
    page: 1,
    pageCount: 0,
    itemsPerPage: 5,
    totalVisible: 10,
  }),
  created(){
    this.selectRtnHistory();
  },
  methods: {
    //Table row 클릭이벤트
    getListSel(item, row) {
      if(item != null){
        this.activeRow(row,true);
      }
    },

    activeRow(row, value){
      row.select(Boolean(value));
      row.isSelected = Boolean(value);
    },

    async selectRtnHistory(){
      let requestData = {
        headers: {},
        sendData:{}
      };
      // header 세팅
      requestData.headers["URL"] = "api/km/content/detail/modify-hist/inqire";
      requestData.headers["SERVICE"] = "km.content.detail.modify-hist";
      requestData.headers["METHOD"] = "inqire";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = false;
      requestData.headers["ROW_CNT"] = 1000
      requestData.headers["PAGES_CNT"] = 1


      // sendData 세팅
      requestData.sendData['CNTNT_ID'] = this.CNTNT_ID;
      requestData.sendData['ASP_NEWCUST_KEY'] = this.ASP_NEWCUST_KEY;

      // 결과 리턴
      let response = await this.common_postCall(requestData);

      if(!response.HEADER.ERROR_FLAG){
        this.gridDataText = response.DATA;
      }
    },
  },

  computed: {

  },
};
</script>

<style></style>
