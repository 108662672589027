<template>
  <v-container fluid>
    <h3>공통코드</h3>
    <div class="d-flex mt-2">
      <div class="flex-glow-1 w200">
        <v-select
          v-model="select01"
          :items="mixin_common_code_get(this.common_code, 'TALK021')"
          label="코드: TALK021"
          outlined
          filled
          hide-details
          dense
          background-color="white"
        ></v-select>
        선택 코드: {{ select01 }}
      </div>
      <div class="flex-glow-1 ml-4 w200">
        <v-select
          v-model="select02"
          :items="mixin_common_code_get(this.common_code, 'TALK022')"
          label="코드: TALK022"
          outlined
          filled
          hide-details
          dense
          background-color="white"
        ></v-select>
        선택 코드: {{ select02 }}
      </div>
      <div class="flex-glow-1 ml-4 w200">
        <v-select
          v-model="select03"
          :items="mixin_common_code_get(this.common_code, 'TALK023')"
          label="코드: TALK023"
          outlined
          filled
          hide-details
          dense
          background-color="white"
        ></v-select>
        선택 코드: {{ select03 }}
      </div>
    </div>

    <h3 class="mt-5">사용자 그룹</h3>
    <div class="d-flex mt-2">
      <div class="w200">
        <v-select
          v-model="select_user_auth_code"
          :items="user_auth_code"
          item-text="NM"
          item-value="CD"
          label="사용자 그룹"
          outlined
          filled
          hide-details
          dense
          background-color="white"
        ></v-select>
      </div>
    </div>

    <h3 class="mt-5">문의유형/상담유형/소속 코드/채널 (회사 선택 후 활성화)</h3>
    <div class="d-flex mt-2">
      <div class="w200">
        <v-select
          v-model="asp_cust_key"
          :items="aspCustList"
          item-text="CD_NM"
          item-value="CD"
          label="회사선택"
          outlined
          filled
          hide-details
          dense
          background-color="white"
          @change="changeAspCustKey()"
        ></v-select>
      </div>

      <div class="d-flex flex-glow-1 flex-column">
        <div class="d-inline-flex ml-4">
          <v-select
            v-model="select_inqry_code_1"
            :items="inqry_code_1"
            item-text="CD_NM"
            item-value="CD"
            label="문의유형 대분류"
            outlined
            filled
            hide-details
            dense
            background-color="white"
            @change="changeInqryKey(asp_cust_key, select_inqry_code_1, '1')"
            no-data-text="조회된 값이 없습니다"
            class="w200"
          ></v-select>
          <v-select
            v-model="select_inqry_code_2"
            :items="inqry_code_2"
            item-text="CD_NM"
            item-value="CD"
            label="문의유형 중분류"
            outlined
            filled
            hide-details
            dense
            background-color="white"
            @change="changeInqryKey(asp_cust_key, select_inqry_code_2, '2')"
            no-data-text="조회된 값이 없습니다"
            class="w200 ml-4"
          ></v-select>
          <v-select
            v-model="select_inqry_code_3"
            :items="inqry_code_3"
            item-text="CD_NM"
            item-value="CD"
            label="문의유형 소분류"
            outlined
            filled
            hide-details
            dense
            background-color="white"
            no-data-text="조회된 값이 없습니다"
            class="w200 ml-4"
          ></v-select>
        </div>
        <div class="d-inline-flex ml-4 mt-3">
          <v-select
            v-model="select_counsel_type_code_1"
            :items="counsel_type_code_1"
            item-text="CD_NM"
            item-value="CD"
            label="상담유형 대분류"
            outlined
            filled
            hide-details
            dense
            background-color="white"
            no-data-text="조회된 값이 없습니다"
            class="w200"
            @change="
              changeCouselTyeKey(asp_cust_key, select_counsel_type_code_1, '1')
            "
          ></v-select>
          <v-select
            v-model="select_counsel_type_code_2"
            :items="counsel_type_code_2"
            item-text="CD_NM"
            item-value="CD"
            label="상담유형 중분류"
            outlined
            filled
            hide-details
            dense
            background-color="white"
            no-data-text="조회된 값이 없습니다"
            class="w200 ml-4"
            @change="
              changeCouselTyeKey(asp_cust_key, select_counsel_type_code_2, '2')
            "
          ></v-select>
          <v-select
            v-model="select_counsel_type_code_3"
            :items="counsel_type_code_3"
            item-text="CD_NM"
            item-value="CD"
            label="상담유형 소분류"
            outlined
            filled
            hide-details
            dense
            background-color="white"
            no-data-text="조회된 값이 없습니다"
            class="w200 ml-4"
          ></v-select>
        </div>
        <div class="d-inline-flex ml-4 mt-3">
          <v-select
            v-model="select_attr_code_A"
            :items="attr_code_A"
            item-text="CD_NM"
            item-value="CD"
            label="소속A"
            outlined
            filled
            hide-details
            dense
            background-color="white"
            no-data-text="조회된 값이 없습니다"
            class="w200"
            @change="
              changeCouselTyeKey(asp_cust_key, select_counsel_type_code_1, '1')
            "
          ></v-select>
          <v-select
            v-model="select_attr_code_B"
            :items="attr_code_B"
            item-text="CD_NM"
            item-value="CD"
            label="소속B"
            outlined
            filled
            hide-details
            dense
            background-color="white"
            no-data-text="조회된 값이 없습니다"
            class="w200 ml-4"
            @change="
              changeCouselTyeKey(asp_cust_key, select_counsel_type_code_2, '2')
            "
          ></v-select>
          <v-select
            v-model="select_attr_code_C"
            :items="attr_code_C"
            item-text="CD_NM"
            item-value="CD"
            label="소속C"
            outlined
            filled
            hide-details
            dense
            background-color="white"
            no-data-text="조회된 값이 없습니다"
            class="w200 ml-4"
          ></v-select>
          <v-select
            v-model="select_attr_code_D"
            :items="attr_code_D"
            item-text="CD_NM"
            item-value="CD"
            label="소속D"
            outlined
            filled
            hide-details
            dense
            background-color="white"
            no-data-text="조회된 값이 없습니다"
            class="w200 ml-4"
          ></v-select>
        </div>
        <div class="d-inline-flex ml-4 mt-3">
          <v-select
            v-model="select_channel_code"
            :items="channel_code"
            item-text="NM"
            item-value="CD"
            label="채널"
            outlined
            filled
            hide-details
            dense
            background-color="white"
            no-data-text="조회된 값이 없습니다"
            class="w200"
          ></v-select>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { mixin } from "@/mixin/mixin.js";
export default {
  name: "CommonCode",
  mixins: [mixin],
  components: {},
  props: {},
  data() {
    return {
      common_code: [], //컴포넌트에서만 사용되는 공통코드를 담는 변수(mixin_common_code_get 함수에 변수로 사용)
      // button_code: [], //컴포넌트에서만 사용되는 공통코드를 담는 변수(mixin_common_code_get 함수에 변수로 사용)
      /** 공통코드 변수 */
      select01: "", //selectbox의 선택된 value값을 사용
      select02: "", //selectbox의 선택된 value값을 사용
      select03: "", //selectbox의 선택된 value값을 사용

      /** 회사선택 변수 */
      asp_cust_key: "",

      /** 문의유형 3depth 변수 */
      inqry_code_1: [],
      select_inqry_code_1: "",
      inqry_code_2: [],
      select_inqry_code_2: "",
      inqry_code_3: [],
      select_inqry_code_3: "",

      /** 상담유형 3depth 변수 */
      counsel_type_code_1: [],
      select_counsel_type_code_1: "",
      counsel_type_code_2: [],
      select_counsel_type_code_2: "",
      counsel_type_code_3: [],
      select_counsel_type_code_3: "",

      /** 사용자 소속 리스트 */
      attr_code_A: [],
      select_attr_code_A: "",
      attr_code_B: [],
      select_attr_code_B: "",
      attr_code_C: [],
      select_attr_code_C: "",
      attr_code_D: [],
      select_attr_code_D: "",

      /** 채널 */
      channel_code: [],
      select_channel_code: "",

      /** 사용자 그룹 */
      user_auth_code: [],
      select_user_auth_code: "",
    };
  },
  watch: {},
  computed: {
    ...mapGetters({
      //store에 저장된 사용자의 company 정보를 가져온다
      aspCustList: "userStore/GE_USER_COMPANY",
    }),
  },
  created() {},
  async mounted() {
    //컴포넌트에서 사용되는 공통코드 묶음을 가져온다
    let companyArray = ["TALK021", "TALK022", "TALK023"];
    this.common_code = await this.mixin_common_code_get_all(companyArray);
    //사용자 그룹 조회
    this.user_auth_code = await this.mixin_user_auth_get();
    // this.button_code = await this.mixin_common_button_auth(
    //   "20210405154851920KC00302"
    // );
    console.log("****************************************************");
    console.log(this.mixin_common_code_get(this.common_code, "TALK021"));
  },
  methods: {
    //회사코드 변경시 이벤트
    async changeAspCustKey() {
      //문의유형 대분류 조회
      this.inqry_code_1 = await this.mixin_inqry_code_get(
        this.asp_cust_key,
        "*"
      );

      //상담유형 대분류 조회
      this.counsel_type_code_1 = await this.mixin_counsel_type_code_get(
        this.asp_cust_key,
        "*",
        ""
      );

      //소속A 조회
      this.attr_code_A = await this.mixin_attr_get(this.asp_cust_key, "A");

      //소속A 조회
      this.attr_code_B = await this.mixin_attr_get(this.asp_cust_key, "B");

      //소속A 조회
      this.attr_code_C = await this.mixin_attr_get(this.asp_cust_key, "C");

      //소속A 조회
      this.attr_code_D = await this.mixin_attr_get(this.asp_cust_key, "D");

      //채널 조회
      this.channel_code = await this.mixin_channel_get(this.asp_cust_key);
    },
    //문의유형 중/소분류 조회
    async changeInqryKey(asp_cust_key, spst_inqry_typ_cd, data_type) {
      //data_type: 1  -> 대분류 변경시 중분류 조회
      //data_type: 2  -> 중분류 변경시 소분류 조회

      if (data_type === "1") {
        //대분류 변경시 중소분류 선택값 초기화
        this.select_inqry_code_2 = "";
        this.select_inqry_code_3 = "";
        this.inqry_code_2 = await this.mixin_counsel_type_code_get(
          asp_cust_key,
          spst_inqry_typ_cd,
          ""
        );
      } else if (data_type === "2") {
        //중분류 변경시 소분류 선택값 초기화
        this.select_inqry_code_3 = "";
        this.inqry_code_3 = await this.mixin_counsel_type_code_get(
          asp_cust_key,
          spst_inqry_typ_cd,
          ""
        );
      }
    },
    //상담유형 중/소분류 조회
    async changeCouselTyeKey(asp_cust_key, spst_coundel_typ_cd, data_type) {
      //data_type: 1  -> 대분류 변경시 중분류 조회
      //data_type: 2  -> 중분류 변경시 소분류 조회

      if (data_type === "1") {
        //대분류 변경시 중소분류 선택값 초기화
        this.select_counsel_type_code_2 = "";
        this.select_counsel_type_code_3 = "";
        this.counsel_type_code_2 = await this.mixin_counsel_type_code_get(
          asp_cust_key,
          spst_coundel_typ_cd,
          ""
        );
      } else if (data_type === "2") {
        //중분류 변경시 소분류 선택값 초기화
        this.select_counsel_type_code_3 = "";
        this.counsel_type_code_3 = await this.mixin_counsel_type_code_get(
          asp_cust_key,
          spst_coundel_typ_cd,
          ""
        );
      }
    },
  },
  destroyed() {},
};
</script>

<style scoped>
.w200 {
  width: 200px;
}
</style>
