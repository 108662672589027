<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">지식 컨텐츠 템플릿 현황</h1>
    <div class="box-wrap">
      <div class="box-ct d-flex">
        <div class="">
          <div class="form-inp sm">
            <v-select
                :items="this.$store.getters['userStore/GE_USER_COMPANY']"
                item-text="CD_NM"
                item-value="CD"
                outlined
                hide-details
                label="회사구분"
                placeholder="선택하세요"
                v-model="ASP_NEWCUST_KEY"
                :readonly="RDOnly"
            >
              <template v-slot:label>
                회사구분
                <v-icon color="#EF9191">mdi-circle-small</v-icon>
              </template>
            </v-select>
          </div>
          <div class="form-inp ml-2">
            <v-select
                :items="this.mixin_common_code_get(this.commonCode, 'TWB151', '전체')"
                outlined
                hide-details
                label="템플릿 타입"
                placeholder="선택하세요"
                v-model="TMPLAT_TYPE_CD"
            >
            </v-select>
          </div>
          <v-text-field
              class="form-inp ml-2"
              name="name" label="제목" outlined hide-details
              v-model="TITL"
              @keyup.enter="aspCustKeyAlert"
          >
          </v-text-field>
          <v-text-field
              class="form-inp ml-2"
              name="name" label="내용" outlined hide-details
              v-model="CONTN"
              @keyup.enter="aspCustKeyAlert"
          >
          </v-text-field>
        </div>
        <div class="ml-auto align-self-center">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')" outlined class="btn-etc2 ml-3" @click="aspCustKeyAlert">조회</v-btn>
        </div>
      </div>
    </div>
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">지식 컨텐츠 템플릿 목록
        <div class="ml-auto">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnNew')" outlined class="btn-default" @click="showDialog('M510301P01')">신규</v-btn>
            <v-dialog max-width="1400" :retain-focus="false" v-model="dialogM510301P01" v-if="dialogM510301P01"
              content-class="square-modal modal-white"
            >
              <dialog-M510301P01
                headerTitle="지식컨텐츠 템플릿 등록"
                p01
                :params = "newTmpId"
                :TWB151="this.mixin_common_code_get(commonCode, 'TWB151', '선택')"
                @hide="hideDialog('M510301P01')"
              >
              </dialog-M510301P01>
            </v-dialog>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDetail')" outlined class="btn-default ml-2" @click="showDialogDetail('M510301P02', '')">상세</v-btn>
            <v-dialog max-width="1400" :retain-focus="false" v-model="dialogM510301P02" v-if="dialogM510301P02"
              content-class="square-modal modal-white">
              <dialog-M510301P01
                headerTitle="지식컨텐츠 템플릿 상세"
                p02
                :TWB151="this.mixin_common_code_get(commonCode, 'TWB151', '선택')"
                :detailContent = "detailContent"
                @hide="hideDialog('M510301P02')"
                @select="getListData"
              >
              </dialog-M510301P01>
            </v-dialog>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDelete')" outlined class="btn-etc ml-2" @click="deleteBtn">삭제</v-btn>
        </div>
      </h2>
      <div class="box-ct">
        <v-data-table
            dense
            height="512px"
            :headers="gridDataHeaders"
            :items="gridDataText"
            :items-per-page="30"
            item-key="index"
            :page.sync="page"
            hide-default-footer
            class="grid-default"
            fixed-header
            show-select
            @page-count="pageCount = $event"
            id="mainTable"
            v-model="selectedData"

            :item-class="isActiveRow"
            @click:row="rowSelect"
        >
          <template v-slot:item.REG_DTTM="{item}">
            <div>{{ dateFormat(item.REG_DTTM) }}</div>
          </template>
          <template v-slot:item.UPD_DTTM="{item}">
            <div>{{ dateFormat(item.UPD_DTTM) }}</div>
          </template>
          <template v-slot:item.PRE_VIEW="{item}">
            <v-btn v-if="mixin_set_btn($options.name, 'btnPreview')" outlined class="btn-default ml-3" @click="showDialogDetail('M510301P03', item.PRE_VIEW)">미리보기</v-btn>
          </template>
        </v-data-table>
        <div class="grid-paging text-center pt-2">
          <v-pagination
              v-model="page"
              :length="pageCount"
              :total-visible="totalVisible"
              next-icon="svg-paging-next"
              prev-icon="svg-paging-prev"></v-pagination>
        </div>
        <p class="caption text-left">
          총<strong class="clr-error">{{ gridDataText.length }}</strong
        >건
        </p>
      </div>
        <v-dialog max-width="1400" v-model="dialogM510301P03" v-if="dialogM510301P03"
          content-class="square-modal modal-white"
        >
          <dialog-M510301P01
            headerTitle="지식컨텐츠 템플릿 미리보기"
            :params = "detailParams"
            p03
            @hide="hideDialog('M510301P03')"
          >
          </dialog-M510301P01>
        </v-dialog>
    </div>
  </div>
</template>

<script>
import {mixin} from "../../mixin/mixin.js";
import DialogM510301P01 from "./M510301P01.vue"

export default {
  mixins:[mixin], // mixin 등록
  name: "MENU_M510301", //name은 'MENU_' + 파일명 조합
  components: {
    DialogM510301P01,
  },
  async mounted(){
    this.commonCode = await this.mixin_common_code_get_all(this.commonCodeArr);
  
    let chk = this.mixin_check_AspCustKey_number();
    if(chk == 'Y'){
      this.ASP_NEWCUST_KEY = this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD;
      this.RDOnly = true;
    } else {
      this.RDOnly = false;
    }
  },
  data() {
    return {
      dialogM510301P01: false,
      dialogM510301P02: false,
      dialogM510301P03: false,

      commonCodeArr : ["TWB151"],
      commonCode : [],

      detailParams: {},
      detailContent: {},
      newTmpId: {},

      gridDataHeaders: [
        {
          text: 'No',
          align: 'center',
          value: 'index',
          width: '50px',
          sortable: true,
        },
        { text: '템플릿타입', value: 'TMPLAT_TYPE_NM', align: 'center', sortable: true, },
        { text: '템플릿명', value: 'TITL',align: 'left', sortable: true, },
        { text: '등록자부서명', value: 'REGR_DEPT_NM', align: 'left',sortable: true, },
        { text: '등록자', value: 'REGR_NM', align: 'left',sortable: true, },
        { text: '등록일시', value: 'REG_DTTM', align: 'center',sortable: true, },
        { text: '수정자부서', value: 'AMDR_DEPT_NM', align: 'left',sortable: true, },
        { text: '수정자', value: 'AMDR_NM', align: 'left',sortable: true, },
        { text: '수정일시', value: 'UPD_DTTM', align: 'center',sortable: true, },
        { text: '미리보기', value: 'PRE_VIEW', align: 'center',sortable: true, },
      ],
      gridDataText: [],
      checkedList: [],

      selectedData: [],

      ASP_NEWCUST_KEY: '',
      TMPLAT_TYPE_CD: '',
      TITL: '',
      CONTN: '',
      TMPLAT_ID: '',

      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      totalVisible: 10,
      totcount: 0,

      selectedRow: null,
      
      RDOnly:false,
    };
  },
  methods: {
    aspCustKeyAlert(){
      if(this.ASP_NEWCUST_KEY == '' || this.ASP_NEWCUST_KEY == null){
        this.common_alert('회사구분을 선택해주세요.', 'error');
        return;
      } else {
        this.getListData();
      }
    },
    async getListData(){
      //조회
      let requestData = {
        headers: {},
        sendData:{}
      };

      //header세팅
      requestData.headers["URL"] = "/api/km/template/manage/page/list";
      requestData.headers["SERVICE"] = "km.template.manage.page";
      requestData.headers["METHOD"] = "list";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = false;
      requestData.headers["PAGES_CNT"] = 1;
      requestData.headers["ROW_CNT"] = 100;

      //sendData 초기화
      requestData.sendData["ASP_NEWCUST_KEY"] = this.ASP_NEWCUST_KEY;  //회사코드
      requestData.sendData["TMPLAT_TYPE_CD"] = this.TMPLAT_TYPE_CD;    //템플릿 타입
      requestData.sendData["TITL"] = this.TITL;                        //제목
      requestData.sendData["CONTN"] = this.CONTN;                      //내용

      //선택데이터초기화
      this.selectedData = [];

      let response = await this.common_postCall(requestData);
      this.getListRes(response);

    },
    getListRes(response){
      let header = response.HEADER;
      this.totcount = header.TOT_COUNT;

      let data = response.DATA;

      this.gridDataText= [];

      for(var i = 0; i < data.length; i++){
        this.gridDataText.push({
            index: i+1,
            TMPLAT_ID: data[i].TMPLAT_ID,
            TMPLAT_TYPE_NM: data[i].TMPLAT_TYPE_NM,
            TITL: data[i].TITL,
            REGR_DEPT_NM: data[i].REGR_DEPT_NM,
            REGR_NM: data[i].REGR_NM,
            REG_DTTM: data[i].REG_DTTM,
            AMDR_DEPT_NM: data[i].AMDR_DEPT_NM,
            AMDR_NM: data[i].AMDR_NM,
            UPD_DTTM: data[i].UPD_DTTM,
            PRE_VIEW: data[i].TMPLAT_ID,
        });
      }
    },
    dateFormat(date){
      //날짜포맷
      if(!date){ return "" }
      var newDate = "";

      //공백제거
      date = date.replace(/\s/gi, "");

      if(date.length == 12 ){
        newDate = date.replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1/$2/$3 $4:$5');
        return newDate;
      } else {
        return date;
      }
    },
    async showDialogDetail(type, tmpId){

      if(type == 'M510301P02'){
        //체크한 템플릿 목록이 조회될 수 있도록
        if( this.mixin_isEmpty(this.selectedData) ){
          this.common_alert('상세 조회할 템플릿을 선택해 주세요.', 'error');
          return;
        } else if (this.selectedData.length > 1) {
          this.common_alert('상세 조회는 단건만 선택 가능합니다.', 'error');
          return;
        } else {
          let chkData = this.selectedData;
          tmpId = chkData[0].TMPLAT_ID;
        }
      }

      let requestData = {
        headers: {},
        sendData: {},
      };

        requestData.headers["URL"] = "/api/km/template/detail/inqire";
        requestData.headers["SERVICE"] = 'km.template.detail';
        requestData.headers["METHOD"] = "inqire";
        requestData.headers["ASYNC"] = false;

        requestData.sendData["TMPLAT_ID"] = tmpId;       //템플릿ID
        requestData.sendData["ASP_NEWCUST_KEY"] =this.ASP_NEWCUST_KEY;  //회사구분


        let response = await this.common_postCall(requestData);
        this.showDetailRes(type, response);

    },
    showDetailRes(type, response){
      let data = response.DATA;

      if(type == 'M510301P02'){
        this.detailContent = {
            DETAIL_ID: data[0].TMPLAT_ID,                            //템플릿ID
            DETAIL_TMPLAT_TYPE_NM: data[0].TMPLAT_TYPE_NM,           //템플릿구분
            DETAIL_TMPLAT_TYPE_CD: data[0].TMPLAT_TYPE_CD,           //템플릿구분코드
            DETAIL_TITLE: data[0].TITL,                              //제목
            DETAIL_REGR_NM: data[0].REGR_NM,                         //등록자명
            DETAIL_REG_DTTM: data[0].REG_DTTM,                       //등록일시
            DETAIL_AMDR_NM: data[0].AMDR_NM,                         //수정자
            DETAIL_UPD_DTTM: data[0].UPD_DTTM,                       //수정일시
            DETAIL_CONTN: this.restoreXSS_CKeditor(data[0].CONTN),   //내용
            DETAIL_REGR_ATTR_DIV_NM: data[0].REGR_ATTR_DIV_NM,       //등록자 소속
            DETAIL_AMDR_ATTR_DIV_NM: data[0].AMDR_ATTR_DIV_NM,       //수정자소속
        }
      } else if(type == 'M510301P03'){
        this.detailParams = {
          id : data[0].TMPLAT_ID,
          contn : this.restoreXSS_CKeditor(data[0].CONTN),
        }
      }
        this[`dialog${type}`] = true;
    },
    deleteBtn(){
      //체크된 데이터 삭제
      var chkData = this.selectedData;
      var delIdList = [];
      for(var i in chkData) {
        let obj = {
          TMPLAT_ID : chkData[i].TMPLAT_ID,
        }
        delIdList.push(obj);
      }

      if(chkData == null || chkData == ''){
        //체크한 데이터가 없을 경우
        this.common_alert('삭제할 템플릿을 선택해주세요.', 'error');
        return;
      } else {
        //체크한 데이터가 있을 경우
        this.common_confirm('선택한 템플릿을 삭제 하시겠습니까?', this.delData, delIdList, null, null , 'error');
      }
    },
    async delData(delIdList){
      let requestData = {
        headers: {},
        sendData: {
          DATA : delIdList
        },
      };

      requestData.headers["URL"] = "/api/km/template/manage/delete";
      requestData.headers["SERVICE"] = 'km.template.manage';
      requestData.headers["METHOD"] = "delete";
      requestData.headers["ASYNC"] = false;

      await this.common_postCall(requestData);

      //삭제 후 재조회
      this.getListData();

      //삭제 후 selected 초기화
      this.selectedData = [];

    },
    showDialog(type){
      if(this.ASP_NEWCUST_KEY == '' || this.ASP_NEWCUST_KEY == null){
        this.common_alert('회사구분을 선택해주세요.', 'error');
        return;
      } else {
        this.newTmpId = {
          id: this.ASP_NEWCUST_KEY,
        }
        this[`dialog${type}`] = true
      }
    },
    hideDialog(type){
      if(type == 'M510301P01'){
         //삭제 후 재조회
         this.getListData();
      }

      this[`dialog${type}`] = false
    },
    rowClick (item, row) {
      row.select(true);
    },
    rowSelect(idx) {
      this.selectedRow = idx;
    },
    isActiveRow(item){
      const activeClass = item === this.selectedRow ? 'active' : '';
      return activeClass;
    },
  },

  computed: {

  },
};
</script>

<style></style>
