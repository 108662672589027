<template>
  <div class="modal-wrap">
    <h1 class="tit-h1 d-flex">
      {{ headerTitle }}
     <div class="ml-auto align-self-center">
        <v-icon x-small class="svg-close" @click="$emit('hide')"></v-icon>
      </div>
    </h1>
    <div class="modal-box">
      <div class="box-ct">
        <h2 class="tit-h2 d-flex">
          <div class="ml-auto">
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')" outlined small class="btn-point" @click="search()">조회</v-btn>
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnConfirm')" outlined small class="btn-etc01 ml-2" @click="submit()">확인</v-btn>
          </div>
        </h2>
        <div class="table-form mt-3">
          <table>
            <caption class="hide">
              컨텐츠 항목
            </caption>
            <colgroup>
              <col width="100px"/>
              <col width=""/>
              <col width=""/>
              <col width=""/>
              <col width=""/>
              <col width=""/>
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">
                  컨텐츠분류
                </th>
                <td colspan="5" class="px-2">
                  <div class="form-inp sm">
                    <v-select
                      :items="DIV_ID_1LEVEL_ITEMS"
                      item-text="text"
                      item-value="value"
                      outlined
                      hide-details
                      placeholder="전체하세요"
                      v-model="gridSearch.DIV_ID_1LEVEL"
                      @change="changeDivId('1')"
                    >
                    </v-select>
                  </div>
                  <div class="form-inp sm ml-2">
                    <v-select
                      :items="DIV_ID_2LEVEL_ITEMS"
                      item-text="text"
                      item-value="value"
                      outlined
                      hide-details
                      placeholder="전체하세요"
                      v-model="gridSearch.DIV_ID_2LEVEL"
                      @change="changeDivId('2')"
                    >
                    </v-select>
                  </div>
                  <div class="form-inp sm ml-2">
                    <v-select
                      :items="DIV_ID_3LEVEL_ITEMS"
                      item-text="text"
                      item-value="value"
                      outlined
                      hide-details
                      placeholder="전체하세요"
                      v-model="gridSearch.DIV_ID_3LEVEL"
                    >
                    </v-select>
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  제목
                </th>
                <td colspan="3" class="px-2">
                  <v-text-field
                    class="form-inp full"
                    name="name" label="" id="id" outlined
                    hide-details
                    v-model="gridSearch.TITL"
                    @keyup.enter="search()"
                    >
                  </v-text-field>
                </td>
                <th scope="row">
                  컨텐츠번호
                </th>
                <td colspan="" class="px-2">
                  <v-text-field
                    class="form-inp full"
                    name="name" label="" id="id" outlined
                    hide-details
                    v-model="gridSearch.CNTNT_NO"
                    @keyup.enter="search()"
                    >
                  </v-text-field>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="box-ct mt-3">
        <h2 class="tit-h2 d-flex">
          컨텐츠현황
        </h2>
        <div class="mt-2">
          <v-data-table
            dense
            :headers="gridDataHeaders"
            :items="grid01Data"
            item-key="CNTNT_NO"
            hide-default-footer
            class="grid-default case03"
            fixed-header
            single-select
            :items-per-page="30"
            :page.sync="page"
            @page-count="pageCount = $event"
            no-data-text="검색된 결과가 없습니다."
            @click:row="moveDown"
            v-model="seletedRow"
            @update:page="selectRtn"
          >
          </v-data-table>
        </div>

        <div class="grid-paging text-center pt-2">
          <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="totalVisible"
            next-icon="svg-paging-next"
            prev-icon="svg-paging-prev"
          ></v-pagination>
          <p class="caption text-left">
            총 <strong class="clr-error">{{ grid01Data.length }}</strong
            >건
          </p>
        </div>
      </div>
      <div class="box-ct mt-3">
        <h2 class="tit-h2 d-flex">
          연결컨텐츠현황
          <div class="ml-auto">
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDelete')" outlined small class="btn-etc3" @click="remove">삭제</v-btn>
          </div>
        </h2>
        <div class="mt-2">
          <v-data-table
            dense
            :headers="gridDataHeaders"
            :items="grid02Data"
            item-key="CNTNT_NO"
            hide-default-footer
            class="grid-default"
            fixed-header
            show-select
            v-model="grid02Selected"
            no-data-text="검색된 결과가 없습니다."
            v-on:click:row="getGrid1_ListSel"
            :item-class="isActiveRow1"
          >
          </v-data-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mixin} from '@/mixin/mixin.js'
export default {
  name: "MENU_M510201P06", //name은 'MENU_' + 파일명 조합
  props: {

    headerTitle: {
      type: String,
      default: "제목",
    },
    ASP_NEWCUST_KEY : {
      type : String,
      default: "",
    },
    gridData : [],
  },
  mixins : [mixin],
  data: () => ({
    DIV_ID_1LEVEL_ITEMS:[],
    DIV_ID_2LEVEL_ITEMS:[],
    DIV_ID_3LEVEL_ITEMS:[],

    gridDataHeaders: [
      { text:"컨텐츠번호" , value:"CNTNT_NO", align:"center", sortable: true,},
      { text:"컨텐츠분류" , value:"FULL_DIV_NM", align:"left", sortable: true,},
      { text:"제목" , value:"TITL", align:"left", sortable: true,},
    ],
    detailData : [],
    seletedRow : [],
    grid01Data: [],
    grid02Data: [],
    grid02Selected:[],
    page: 1,
    pageCount: 0,
    itemsPerPage: 5,
    totalVisible: 10,
    //totalCount: 0,
    gridSearch: {
      DIV_ID_1LEVEL : "",
      DIV_ID_2LEVEL : "",
      DIV_ID_3LEVEL : "",
      TITL : "",
      CNTNT_NO : "",
    },
  }),
  async mounted(){
    let DIV_ID_1LEVEL = await this.mixin_getDivCodeData('0');
    this.DIV_ID_1LEVEL_ITEMS = this.mixin_common_code_get(DIV_ID_1LEVEL,'0','전체');
    this.DIV_ID_2LEVEL_ITEMS = this.mixin_common_code_get("","","전체");
    this.DIV_ID_3LEVEL_ITEMS = this.mixin_common_code_get("","","전체");
  },
  created(){
    if(this.gridData.length > 0){
      this.grid02Data = Object.assign([], this.gridData);
    }
    this.search();
  },
  methods: {
    //Table row 클릭이벤트
    getListSel(item, row) {
      if(item != null){
        this.activeRow(row,true);
      }
    },

    activeRow(row, value){
      row.select(Boolean(value));
      row.isSelected = Boolean(value);
    },

    getGrid1_ListSel(item){
      this.detailData = item;
      console.log(item);
    },

    isActiveRow1(item){
      console.log(item);
      return item.CNTNT_NO == this.detailData.CNTNT_NO ? 'active':'';
    },



    search(){
      this.page = 1;
      this.selectRtn();
    },
    async selectRtn(){
      let requestData = {
        headers: {},
        sendData:{}
      };
      // header 세팅
      requestData.headers["URL"] = "api/km/content/cnnc/list";
      requestData.headers["SERVICE"] = "km.content.cnnc";
      requestData.headers["METHOD"] = "list";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = false;
      requestData.headers["ROW_CNT"] = this.itemsPerPage;
      requestData.headers["PAGES_CNT"] = this.page;

      // sendData 세팅
      requestData.sendData = this.gridSearch;
      requestData.sendData['ASP_NEWCUST_KEY'] = this.ASP_NEWCUST_KEY

      if(this.gridSearch.DIV_ID_1LEVEL != "" && this.gridSearch.DIV_ID_2LEVEL != "" && this.gridSearch.DIV_ID_3LEVEL != ""){
        requestData.sendData['SPST_DIV_ID'] = this.gridSearch.DIV_ID_3LEVEL;
      }else if(this.gridSearch.DIV_ID_1LEVEL != "" && this.gridSearch.DIV_ID_2LEVEL != "" && this.gridSearch.DIV_ID_3LEVEL == ""){
        requestData.sendData['SPST_DIV_ID'] = this.gridSearch.DIV_ID_2LEVEL;
      }else if(this.gridSearch.DIV_ID_1LEVEL != "" && this.gridSearch.DIV_ID_2LEVEL == "" && this.gridSearch.DIV_ID_3LEVEL == ""){
        requestData.sendData['SPST_DIV_ID'] = this.gridSearch.DIV_ID_1LEVEL;
      }

      // 결과 리턴
      let response = await this.common_postCall(requestData);

      if(!response.HEADER.ERROR_FLAG){
        this.grid01Data = response.DATA;
        //this.totalCount = response.DATA[0].TWB_PAGING_TOT_COUNT;
      }
    },

    async changeDivId(level){
      if(level == '1'){
        if(this.gridSearch.DIV_ID_1LEVEL != ""){
          let DIV_ID_2LEVEL = await this.mixin_getDivCodeData(this.gridSearch.DIV_ID_1LEVEL);
          this.DIV_ID_2LEVEL_ITEMS = this.mixin_common_code_get(DIV_ID_2LEVEL, this.gridSearch.DIV_ID_1LEVEL,"전체");
          this.DIV_ID_3LEVEL_ITEMS = this.mixin_common_code_get("","","전체");
        }

        this.gridSearch.DIV_ID_2LEVEL = "";
        this.gridSearch.DIV_ID_3LEVEL = "";
      }else if(level == '2'){
        if(this.gridSearch.DIV_ID_2LEVEL != ""){
          let DIV_ID_3LEVEL = await this.mixin_getDivCodeData(this.gridSearch.DIV_ID_2LEVEL);
          this.DIV_ID_3LEVEL_ITEMS = this.mixin_common_code_get(DIV_ID_3LEVEL, this.gridSearch.DIV_ID_2LEVEL,"전체");
        }

        this.gridSearch.DIV_ID_3LEVEL = "";
      }
    },

    moveDown(item, row){
      let compare = this.grid02Data.filter(e=> {return e.CNTNT_NO === item.CNTNT_NO})

      if(compare.length == 0){
        this.grid02Data.push(item);
        this.activeRow(row,true);
      }else{
        this.common_alert("이미 연결된 컨텐츠입니다.", "error");
      }
    },
    remove(){
      if(this.grid02Selected.length > 0){
        // this.grid02Data.map((e,index) => {
        //   this.grid02Selected.some(item => {
        //     if(e === item){
        //       this.grid02Data.splice(index, 1);
        //     }
        //   })
        // })
        for(let i in this.grid02Selected){
          this.grid02Data.splice(this.grid02Data.indexOf(this.grid02Selected[i]), 1)
        }

      }else{
        this.common_alert('선택된 항목이 존재하지 않습니다.', 'error')
        return;
      }
      this.seletedRow = [];
      this.detailData = [];
      this.grid02Selected = [];
    },
    submit(){
      this.$emit('submit', this.grid02Data);
    },
  },

  computed: {

  },
};
</script>

<style></style>
