<template>
  <div class="layout-subcontainer">
    <!-- <v-app> -->
    <!-- link -->
    <div class="text-right topNav" style="">
      <v-menu offset-y open-on-hover>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark v-bind="attrs" v-on="on">
            INDEX
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            dense
            v-for="(item, index) in toplink"
            :key="index"
            style="min-height: 30px"
          >
            <v-list-item-title>
              <a
                :href="`#${item.name}`"
                class="d-inline-block"
                style="font-size: 14px"
              >
                {{ item.name }}
              </a>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <!-- //mark: font 0-->
    <v-card outlined light class="mt-3">
      <v-card-title :id="`${toplink[0].name}`">{{
        toplink[0].name
      }}</v-card-title>

      <v-divider />
      <v-row class="pa-3">
        <v-col>
          <div class="font-weight-bold">BOLD</div>
          <div class="font-weight-medium">MEDIUM</div>
          <div class="">NORMAL</div>
        </v-col>
        <!-- source -->
        <v-col cols="6">
          <v-tabs v-model="srcTab" dark class="src-tab">
            <v-tab v-for="item in srcFont" :key="item.tab">
              {{ item.tab }}
            </v-tab>
            <v-btn depressed absolute x-small right @click="srcView">
             source fold
            </v-btn>
          </v-tabs>

          <v-tabs-items v-model="srcTab">
            <v-tab-item v-for="item in srcFont" :key="item.tab">
              <pre
                class="guide-code"
                v-highlightjs
              ><code class="html">{{item.content}}</code></pre>
            </v-tab-item>
          </v-tabs-items>
          <!--// source -->
        </v-col>
      </v-row>
    </v-card>

    <!-- //mark: input 1-->
    <v-card outlined light class="mt-3">
      <v-card-title :id="`${toplink[1].name}`">{{
        toplink[1].name
      }}</v-card-title>

      <v-divider />
      <v-row class="pa-3">
        <v-col>
          <h3>case 01:</h3>
          <div>
            <v-text-field
              class="form-inp sm"
              name="name"
              label="size sm 124px"
              outlined
            >
            </v-text-field>
            <v-text-field
              class="form-inp lg ml-2"
              name="name"
              label="size lg 256px"
              outlined
            >
            </v-text-field>
            <br />
            <v-text-field
              class="form-inp lg"
              name="name"
              label="label"
              outlined
              hint="고정 메시지 출력"
              persistent-hint
            >
            </v-text-field>
            <v-text-field
              class="form-inp lg"
              name="name"
              label="readonly"
              value="hide-details"
              outlined
              hide-details
              readonly
            >
            </v-text-field>
            <v-text-field
              class="form-inp lg"
              name="name"
              value="disabled hide-details"
              outlined
              disabled
            >
            </v-text-field>
            <v-text-field
              class="form-inp free"
              name="name"
              label="width auto "
              outlined
              disabled
            ></v-text-field>
            <v-text-field
              class="form-inp full"
              name="name"
              label="width 100% "
              outlined
              disabled
            >
            </v-text-field>
          </div>
          <h3>case 02: 버튼 있는 경우</h3>
          <div>
            <v-text-field
              class="form-inp"
              name="name"
              label="버튼 있는 경우"
              outlined
            >
              <template v-slot:append>
                <v-btn
                  depressed
                  icon
                  x-small
                  @click="callMethod('call red')"
                  class="fomr-inp--btn svg-call-red"
                >
                </v-btn>
              </template>
            </v-text-field>
            <v-text-field
              class="form-inp"
              name="name"
              label="버튼 있는 경우"
              outlined
            >
              <template v-slot:append>
                <v-btn
                  depressed
                  icon
                  x-small
                  @click="callMethod('search')"
                  class="fomr-inp--btn svg-search"
                >
                </v-btn>
              </template>
            </v-text-field>
          </div>
          <h3>case 03: number</h3>
          <div>
            <v-text-field
              class="form-inp sm"
              type="number"
              value="42"
            ></v-text-field>
          </div>
          <h3>case 04: suffix</h3>
          <v-text-field
            class="form-inp sm"
            name="name"
            label="조회건수"
            id="id"
            outlined
            hide-details=""
            suffix="건"
          >
          </v-text-field>
        </v-col>
        <!-- source -->
        <v-col cols="6">
          <v-tabs v-model="srcTab2" dark>
            <v-tab v-for="item in srcInput" :key="item.tab">
              {{ item.tab }}
            </v-tab>
            <v-btn depressed absolute x-small right @click="srcView">
             source fold
            </v-btn>
          </v-tabs>

          <v-tabs-items v-model="srcTab2">
            <v-tab-item v-for="item in srcInput" :key="item.tab">
              <pre
                class="guide-code"
                v-highlightjs
              ><code class="html">{{item.content}}</code></pre>
            </v-tab-item>
          </v-tabs-items>
          <!--// source -->
        </v-col>
      </v-row>
    </v-card>

    <!-- //mark: dropdown 2-->
    <v-card outlined light class="mt-3">
      <v-card-title :id="`${toplink[2].name}`">{{
        toplink[2].name
      }}</v-card-title>

      <v-divider />
      <v-row class="pa-3">
        <v-col>
          <h3>case 01: v-select</h3>
          <div class="form-inp">
            <v-select
              :items="dropItems"
              item-text="title"
              outlined
              placeholder="선택하세요"
            ></v-select>
          </div>
          <div class="form-inp sm ml-3">
            <v-select
              :items="dropItems"
              item-text="title"
              outlined
              placeholder="error-messages"
              error-messages="에러 메시지 출력"
            ></v-select>
          </div>
          <div class="form-inp lg">
            <v-select
              :items="dropItems"
              item-text="title"
              outlined
              placeholder="disalbed"
              disabled
            ></v-select>
          </div>
          <h3>case 02: v-select check multi 4개 이상 ... 표시</h3>
          <div class="form-inp free">
            <v-select
              single-line
              v-model="dropItemM"
              :items="dropItems"
              item-text="title"
              outlined
              label="Select Item"
              multiple
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index < 4" small>
                  <span>{{ item.title }}</span>
                  <template>
                    <v-btn
                      icon
                      x-small
                      class="svg-close20 ml-1"
                      v-show="dropItemRemove === true"
                      @click.stop="removeDropitem(index)"
                    ></v-btn>
                  </template>
                </v-chip>
                <span v-if="index === 3" class="grey--text text-caption">
                  ...
                </span>
              </template>
            </v-select>
          </div>
          <h3>case 03: required</h3>
          <div class="form-inp sm">
            <v-select
              :items="dropItems"
              item-text="title"
              outlined
              hide-details
              label="회사구분"
              placeholder="선택하세요"
            >
              <template v-slot:label>
                회사구분
                <v-icon color="#EF9191">mdi-circle-small</v-icon>
              </template>
            </v-select>
          </div>
          <h3>case 04: disabled</h3>
          <div class="form-inp sm">
            <v-select
              :items="dropItems"
              item-text="title"
              outlined
              hide-details
              label="회사구분"
              placeholder="선택하세요"
              disabled
            >
              <template v-slot:label>
                회사구분
                <v-icon color="#EF9191">mdi-circle-small</v-icon>
              </template>
            </v-select>
          </div>
        </v-col>
        <!-- source -->
        <v-col cols="6">
          <v-tabs v-model="srcTab3" dark>
            <v-tab v-for="item in srcDropdown" :key="item.tab">
              {{ item.tab }}
            </v-tab>
            <v-btn depressed absolute x-small right @click="srcView">
             source fold
            </v-btn>
          </v-tabs>

          <v-tabs-items v-model="srcTab3">
            <v-tab-item v-for="item in srcDropdown" :key="item.tab">
              <pre
                class="guide-code"
                v-highlightjs
              ><code class="html">{{item.content}}</code></pre>
            </v-tab-item>
          </v-tabs-items>
          <!--// source -->
        </v-col>
      </v-row>
    </v-card>

    <!-- //mark: check 3-->
    <v-card outlined light class="mt-3">
      <v-card-title :id="`${toplink[3].name}`">{{
        toplink[3].name
      }}</v-card-title>

      <v-divider />
      <v-row class="pa-3">
        <v-col>
          <h3>case 01:</h3>
          <v-checkbox
            :on-icon="'svg-chk-on'"
            :off-icon="'svg-chk-off'"
            label="label"
          ></v-checkbox>
          <h3>case 02: inline</h3>
          <v-checkbox
            v-for="(chk01, index) in chkTypes"
            :key="chkTypes[index].text"
            :label="chk01.text"
            :on-icon="'svg-chk-on'"
            :off-icon="'svg-chk-off'"
            v-model="chk01.selected"
            class="d-inline-flex"
            :class="[chk01.isLast !== true ? 'pr-4' : '']"
          ></v-checkbox>
        </v-col>
        <!-- source -->
        <v-col cols="6">
          <v-tabs v-model="srcTab4" dark>
            <v-tab v-for="item in srcCheck" :key="item.tab">
              {{ item.tab }}
            </v-tab>
            <v-btn depressed absolute x-small right @click="srcView">
             source fold
            </v-btn>
          </v-tabs>

          <v-tabs-items v-model="srcTab4">
            <v-tab-item v-for="item in srcCheck" :key="item.tab">
              <pre
                class="guide-code"
                v-highlightjs
              ><code class="html">{{item.content}}</code></pre>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
        <!--// source -->
      </v-row>
    </v-card>
    <!-- //mark: radio 4-->
    <v-card outlined light class="mt-3">
      <v-card-title :id="`${toplink[4].name}`">{{
        toplink[4].name
      }}</v-card-title>

      <v-divider />
      <v-row class="pa-3">
        <v-col>
          <h3>case 01:</h3>
          <div>
            <v-radio-group v-model="radioGroupSelected">
              <v-radio
                v-for="(rad01, index) in radioGroup"
                :key="radioGroup[index].text"
                :label="rad01.text"
                :on-icon="'svg-rad-on'"
                :off-icon="'svg-rad-off'"
                :value="rad01.text"
              >
              </v-radio>
            </v-radio-group>
          </div>
          <h3>case 02: inline</h3>
          <div>
            <v-radio-group
              v-model="radioGroupSelected"
              row
              class="radio-inline"
            >
              <v-radio
                v-for="(rad01, index) in radioGroup"
                :key="radioGroup[index].text"
                :label="rad01.text"
                :on-icon="'svg-rad-on'"
                :off-icon="'svg-rad-off'"
                :value="rad01.text"
              >
              </v-radio>
            </v-radio-group>
          </div>
          <h3>case 03: label</h3>
          <v-radio-group
            v-model="radioGroupSelected"
            row
            class="radio-inline ml-2"
          >
            <template v-slot:label>
              <span class="in-label">정렬조건</span>
            </template>
            <v-radio
              v-for="(rad01, index) in radioGroup"
              :key="radioGroup[index].text"
              :label="rad01.text"
              :on-icon="'svg-rad-on'"
              :off-icon="'svg-rad-off'"
              :value="rad01.text"
            >
            </v-radio>
          </v-radio-group>
        </v-col>
        <!-- source -->
        <v-col cols="6">
          <v-tabs v-model="srcTab5" dark>
            <v-tab v-for="item in srcRadio" :key="item.tab">
              {{ item.tab }}
            </v-tab>
            <v-btn depressed absolute x-small right @click="srcView">
             source fold
            </v-btn>
          </v-tabs>

          <v-tabs-items v-model="srcTab5">
            <v-tab-item v-for="item in srcRadio" :key="item.tab">
              <pre
                class="guide-code"
                v-highlightjs
              ><code class="html">{{item.content}}</code></pre>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
        <!--// source -->
      </v-row>
    </v-card>
    <!-- //mark: button 5 -->
    <v-card outlined light class="mt-3">
      <v-card-title :id="`${toplink[5].name}`">{{
        toplink[5].name
      }}</v-card-title>

      <v-divider />
      <v-row class="pa-3">
        <v-col>
          <h3>case 01: small</h3>
          <v-btn outlined small class="btn-default">임시저장</v-btn>
          <v-btn outlined small class="btn-etc ml-2">취소</v-btn>
          <v-btn outlined small class="btn-etc2 ml-2">조회</v-btn>
          <v-btn outlined small class="btn-point ml-2">전송</v-btn>
          <v-btn outlined small disabled class="ml-2">disabled</v-btn>
          <v-btn outlined small class="btn-etc3 ml-2">
            <v-icon small class="mr-2">mdi-plus</v-icon>
            더보기
          </v-btn>

          <h3>case 01-2: medium</h3>
          <v-btn outlined class="btn-default">임시저장</v-btn>
          <v-btn outlined class="btn-etc ml-2">취소</v-btn>
          <v-btn outlined class="btn-etc2 ml-2">조회</v-btn>
          <v-btn outlined class="btn-point ml-2">전송</v-btn>
          <v-btn outlined disabled class="ml-2">disabled</v-btn>

          <h3>case 01-3: large</h3>
          <v-btn outlined large class="btn-default">임시저장</v-btn>
          <v-btn outlined large class="btn-etc ml-2">취소</v-btn>
          <v-btn outlined large class="btn-etc2 ml-2">조회</v-btn>
          <v-btn outlined large class="btn-point ml-2">전송</v-btn>
          <br />
          <div class="mt-3">
            <v-btn outlined large disabled class="btn-default">임시저장</v-btn>
            <v-btn outlined large disabled class="btn-etc ml-2">취소</v-btn>
            <v-btn outlined large disabled class="btn-etc2 ml-2">조회</v-btn>
            <v-btn outlined large disabled class="btn-point ml-2">전송</v-btn>
          </div>

          <h3>case 02: 버튼 그룹</h3>
          <div class="d-inline">
            <v-btn-toggle v-model="datePeriod">
              <v-btn outlined small class="btn-default">전체</v-btn>
              <v-btn outlined small class="btn-default">전체</v-btn>
              <v-btn outlined small class="btn-default">전체</v-btn>
              <v-btn outlined small class="btn-default">전체</v-btn>
              <v-btn outlined small class="btn-default">전체</v-btn>
            </v-btn-toggle>
          </div>

          <h3>case 03: icon</h3>
          <p>
            <a href="https://materialdesignicons.com/" target="_blank"
              >https://materialdesignicons.com/</a
            >
            <br />위 사이트에서 필요한 아이콘 검색 후 '&lt;v-icon&gt;'태그 내에
            mdi-{아이콘명} 형태로 사용 <br />&lt;v-icon small
            &gt;mdi-arrow-collapse-down&lt;/v-icon&gt;
          </p>
          <v-btn outlined small class="btn-point">
            point
            <v-icon x-small class="svg-close"></v-icon>
          </v-btn>
          <v-btn outlined small class="btn-point">
            <v-icon x-small class="svg-close"></v-icon>
            point
          </v-btn>
          <v-btn outlined small class="btn-point">
            <v-icon x-small class="svg-close"></v-icon>
            point
            <v-icon x-small class="svg-close"></v-icon>
          </v-btn>
          <v-btn outlined small class="btn-point">
            <v-icon small>mdi-magnify</v-icon>
          </v-btn>
          <v-btn outlined small class="btn-point">
            <v-icon small>mdi-sync</v-icon>
          </v-btn>
          <h3>case 04: only icon & tooltip</h3>
          <!-- 조회 버튼 -->
          <v-btn
            dark
            small
            class="btn-main-search pa-0"
            min-width="32px"
            height="32px"
            plain
            @click="showDialog('M110101P02')"
          >
            <v-icon small class="svg-main-searh white"></v-icon>
          </v-btn>
          <!-- 상세조회 버튼 -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                small
                class="btn-main-search pa-0 ml-2"
                min-width="32px"
                height="32px"
                plain
                @click="csDetailSearch2 = true"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small class="svg-main-search2"></v-icon>
              </v-btn>
            </template>
            <span>상세조회</span>
          </v-tooltip>
          <!-- 수정/저장 버튼 -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                small
                class="btn-main-refresh pa-0 ml-2"
                min-width="32px"
                height="32px"
                plain
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small class="svg-main-down"></v-icon>
              </v-btn>
            </template>
            <span>수정/저장</span>
          </v-tooltip>
          <!-- 새로고침 버튼 -->
          <v-btn
            dark
            small
            class="btn-main-refresh pa-0 ml-2"
            min-width="32px"
            height="32px"
            plain
          >
            <v-icon small class="svg-main-refresh"></v-icon>
          </v-btn>
          <!-- 필터 버튼 -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                small
                class="btn-main-refresh pa-0 ml-2"
                min-width="32px"
                height="32px"
                plain
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small class="svg-main-filter"></v-icon>
              </v-btn>
            </template>
            <span>필터</span>
          </v-tooltip>
          <!-- 좌우화살표 -->
          <v-btn class="btn-arrow" plain small>
            <v-icon small class="svg-arrow-left"></v-icon>
          </v-btn>
          <v-btn class="btn-arrow" plain small>
            <v-icon small class="svg-arrow-right"></v-icon>
          </v-btn>
          <v-btn class="btn-arrow" plain small>
            <v-icon small class="svg-arrow-top"></v-icon>
          </v-btn>
          <v-btn class="btn-arrow" plain small>
            <v-icon small class="svg-arrow-down"></v-icon>
          </v-btn>

          <div class="mt-2">
            <!-- 조회 버튼 -->
            <v-btn
              dark
              small
              disabled
              class="btn-main-search pa-0"
              min-width="32px"
              height="32px"
              plain
              @click="showDialog('M110101P02')"
            >
              <v-icon small class="svg-main-searh white"></v-icon>
            </v-btn>
            <!-- 상세조회 버튼 -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dark
                  small
                  disabled
                  class="btn-main-search pa-0 ml-2"
                  min-width="32px"
                  height="32px"
                  plain
                  @click="csDetailSearch2 = true"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon small class="svg-main-search2"></v-icon>
                </v-btn>
              </template>
              <span>상세조회</span>
            </v-tooltip>
            <!-- 수정/저장 버튼 -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dark
                  small
                  disabled
                  class="btn-main-refresh pa-0 ml-2"
                  min-width="32px"
                  height="32px"
                  plain
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon small class="svg-main-down"></v-icon>
                </v-btn>
              </template>
              <span>수정/저장</span>
            </v-tooltip>
            <!-- 새로고침 버튼 -->
            <v-btn
              dark
              small
              disabled
              class="btn-main-refresh pa-0 ml-2"
              min-width="32px"
              height="32px"
              plain
            >
              <v-icon small class="svg-main-refresh"></v-icon>
            </v-btn>
            <!-- 필터 버튼 -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dark
                  small
                  disabled
                  class="btn-main-refresh pa-0 ml-2"
                  min-width="32px"
                  height="32px"
                  plain
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon small class="svg-main-filter"></v-icon>
                </v-btn>
              </template>
              <span>필터</span>
            </v-tooltip>
          </div>
        </v-col>
        <!-- source -->
        <v-col cols="6">
          <v-tabs v-model="srcTab6" dark>
            <v-tab v-for="item in srcButton" :key="item.tab">
              {{ item.tab }}
            </v-tab>
            <v-btn depressed absolute x-small right @click="srcView">
             source fold
            </v-btn>
          </v-tabs>

          <v-tabs-items v-model="srcTab6">
            <v-tab-item v-for="item in srcButton" :key="item.tab">
              <pre
                class="guide-code"
                v-highlightjs
              ><code class="html">{{item.content}}</code></pre>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
        <!--// source -->
      </v-row>
    </v-card>

    <!-- //mark: textarea 6-->
    <v-card outlined light class="mt-3">
      <v-card-title :id="`${toplink[6].name}`">{{
        toplink[6].name
      }}</v-card-title>

      <v-divider />
      <v-row class="pa-3">
        <v-col>
          <h3>case 01:</h3>
          <v-textarea
            counter
            label=""
            :rules="textareaRules"
            :value="textareaValue"
            no-resize
            outlined
          ></v-textarea>
          <h3>case 02: 카운터 없는 경우</h3>
          <v-textarea
            class="textarea-type0"
            label=""
            :rules="textareaRules"
            :value="textareaValue"
            no-resize
            outlined
          ></v-textarea>
        </v-col>
        <!-- source -->
        <v-col cols="6">
          <v-tabs v-model="srcTab7" dark>
            <v-tab v-for="item in srcTextarea" :key="item.tab">
              {{ item.tab }}
            </v-tab>
            <v-btn depressed absolute x-small right @click="srcView">
             source fold
            </v-btn>
          </v-tabs>

          <v-tabs-items v-model="srcTab7">
            <v-tab-item v-for="item in srcTextarea" :key="item.tab">
              <pre
                class="guide-code"
                v-highlightjs
              ><code class="html">{{item.content}}</code></pre>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
        <!--// source -->
      </v-row>
    </v-card>
    <!-- //mark: chips 7-->
    <v-card outlined light class="mt-3">
      <v-card-title :id="`${toplink[7].name}`">{{
        toplink[7].name
      }}</v-card-title>

      <v-divider />
      <v-row class="pa-3">
        <v-col>
          <h3>case 01:</h3>
          <v-chip class="ma-2"> Default </v-chip>

          <v-chip class="ma-2" color="primary"> Primary </v-chip>

          <v-chip class="ma-2" color="secondary"> Secondary </v-chip>

          <v-chip class="ma-2" color="red" text-color="white">
            Red Chip
          </v-chip>

          <v-chip class="ma-2" color="green" text-color="white">
            Green Chip
          </v-chip>
        </v-col>
        <!-- source -->
        <v-col cols="6">
          <v-tabs v-model="srcTab8" dark>
            <v-tab v-for="item in srcChips" :key="item.tab">
              {{ item.tab }}
            </v-tab>
            <v-btn depressed absolute x-small right @click="srcView">
             source fold
            </v-btn>
          </v-tabs>

          <v-tabs-items v-model="srcTab8">
            <v-tab-item v-for="item in srcChips" :key="item.tab">
              <pre
                class="guide-code"
                v-highlightjs
              ><code class="html">{{item.content}}</code></pre>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
        <!--// source -->
      </v-row>
    </v-card>
    <!-- //mark: badges 8-->
    <v-card outlined light class="mt-3">
      <v-card-title :id="`${toplink[8].name}`">{{
        toplink[8].name
      }}</v-card-title>

      <v-divider />
      <v-row class="pa-3">
        <v-col>
          <h3>case 01:</h3>
          <div>
            <v-badge bordered color="primary" :content="badgeCnt"
              >오늘의 뉴스</v-badge
            >
          </div>
          <div class="mt-3">
            <v-badge bordered color="red" :content="badgeCnt"
              >내일의 뉴스</v-badge
            >
          </div>
        </v-col>
        <!-- source -->
        <v-col cols="6">
          <v-tabs v-model="srcTab9" dark>
            <v-tab v-for="item in srcBadges" :key="item.tab">
              {{ item.tab }}
            </v-tab>
            <v-btn depressed absolute x-small right @click="srcView">
             source fold
            </v-btn>
          </v-tabs>

          <v-tabs-items v-model="srcTab9">
            <v-tab-item v-for="item in srcBadges" :key="item.tab">
              <pre
                class="guide-code"
                v-highlightjs
              ><code class="html">{{item.content}}</code></pre>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
        <!--// source -->
      </v-row>
    </v-card>
    <!-- //mark: tooltip 9-->
    <v-card outlined light class="mt-3">
      <v-card-title :id="`${toplink[9].name}`">{{
        toplink[9].name
      }}</v-card-title>

      <v-divider />
      <v-row class="pa-3">
        <v-col>
          <h3>case 01:</h3>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on">
                아래서 나오는 툴팁
              </v-btn>
            </template>
            <span>{{ tooltipMsg }}</span>
          </v-tooltip>
          <v-tooltip bottom max-width="190px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on" class="ml-2">
                여러줄 툴팁
              </v-btn>
            </template>
            <span>{{ tooltipMsg2 }}</span>
          </v-tooltip>
        </v-col>
        <!-- source -->
        <v-col cols="6">
          <v-tabs v-model="srcTab10" dark>
            <v-tab v-for="item in srcTooltip" :key="item.tab">
              {{ item.tab }}
            </v-tab>
            <v-btn depressed absolute x-small right @click="srcView">
             source fold
            </v-btn>
          </v-tabs>

          <v-tabs-items v-model="srcTab10">
            <v-tab-item v-for="item in srcTooltip" :key="item.tab">
              <pre
                class="guide-code"
                v-highlightjs
              ><code class="html">{{item.content}}</code></pre>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
        <!--// source -->
      </v-row>
    </v-card>
    <!-- //mark: toast 10-->
    <v-card outlined light class="mt-3">
      <v-card-title :id="`${toplink[10].name}`">{{
        toplink[10].name
      }}</v-card-title>

      <v-divider />

      <v-row class="pa-3">
        <v-col>
          <h3>case 01 :</h3>
          <div class="">
            <v-btn dark @click="showToast">기본</v-btn>
            <v-btn dark @click="showToastSucess" class="ml-1">sucess</v-btn>
            <v-btn dark @click="showToastInfo" class="ml-1">info</v-btn>
            <v-btn dark @click="showToastWarn" class="ml-1">warning</v-btn>
            <v-btn dark @click="showToastError" class="ml-1">error</v-btn>
          </div>
        </v-col>
        <!-- source -->
        <v-col cols="6">
          <v-tabs v-model="srcTab11" dark>
            <v-tab v-for="item in srcToast" :key="item.tab">
              {{ item.tab }}
            </v-tab>
            <v-btn depressed absolute x-small right @click="srcView">
             source fold
            </v-btn>
          </v-tabs>

          <v-tabs-items v-model="srcTab11">
            <v-tab-item v-for="item in srcToast" :key="item.tab">
              <pre
                class="guide-code"
                v-highlightjs
              ><code class="html">{{item.content}}</code></pre>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
        <!--// source -->
      </v-row>
    </v-card>
    <!-- //mark: alert 11-->
    <v-card outlined light class="mt-3">
      <v-card-title :id="`${toplink[11].name}`">{{
        toplink[11].name
      }}</v-card-title>

      <v-divider />
      <v-row class="pa-3">
        <v-col>
          <h3>case 01: alert</h3>
          <v-btn dark @click="showAlert">경고 메세지</v-btn>
          <v-btn dark @click="showAlert2" class="ml-1">완료 메세지</v-btn>

          <h3 class="mt-3">case 02: 버튼 있는 alert</h3>
          <v-btn dark @click="showAlert3">로그아웃</v-btn>
          <v-btn dark @click="showAlert4" class="ml-1">내용 확인</v-btn>
          <v-btn dark @click="showAlert5" class="ml-1">삭제 확인</v-btn>

          <h3 class="mt-3">case 03: 긴급 alert</h3>
          <v-btn dark @click="showAlert6">콜백</v-btn>
          <v-btn dark @click="showAlert7" class="ml-1">예약콜</v-btn>
        </v-col>
        <!-- source -->
        <v-col cols="6">
          <v-tabs v-model="srcTab12" dark>
            <v-tab v-for="item in srcAlert" :key="item.tab">
              {{ item.tab }}
            </v-tab>
            <v-btn depressed absolute x-small right @click="srcView">
             source fold
            </v-btn>
          </v-tabs>

          <v-tabs-items v-model="srcTab12">
            <v-tab-item v-for="item in srcAlert" :key="item.tab">
              <pre
                class="guide-code"
                v-highlightjs
              ><code class="html">{{item.content}}</code></pre>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
        <!--// source -->
      </v-row>
    </v-card>
    <!-- //mark: modal 12-->
    <v-card outlined light class="mt-3">
      <v-card-title :id="`${toplink[12].name}`">{{
        toplink[12].name
      }}</v-card-title>

      <v-divider />
      <v-row class="pa-3">
        <v-col>
          <strong class="clr-error">
            모달 사이즈 공통 중간사이즈 max-width="655",
            content-class="square-modal modal-white"<br />
            모달 사이즈 공통 대형사이즈 max-width="1400,
            content-class="square-modal "<br />
            (예외) 모달 사이즈 시험지 max-width="1748",
            content-class="square-modal "<br />
            모달 기본높이값 auto
            content-class="square-modal min-auto"<br />
            draggable 가능하게
            content-class="square-modal dialog-draggable"
          </strong>
          <br />
          <h3>case 01: 모달 폼 중간 사이즈 dialogSample.vue</h3>
          <div class="">
            <v-btn dark color="green" @click="showDialog('Text')">
              show dialog 중간
            </v-btn>
            <v-dialog
              max-width="655"
              v-model="dialogText"
              content-class="square-modal modal-white"
            >
              <dialog-default
                header-title="텍스트"
                @hide="hideDialog('Text')"
                @submit="submitDialog('Text')"
              >
                <template v-slot:body> </template>
              </dialog-default>
            </v-dialog>
          </div>
          <h3 class="mt-3">case 02: 모달 폼 대형 사이즈 dialogForm.vue</h3>
          <div class="mt-2">
            <v-btn dark @click="showDialog('Form')"> show dialog 대형 </v-btn>
            <v-dialog
              max-width="1400"
              v-model="dialogForm"
              content-class="square-modal"
            >
              <dialog-Form
                header-title="Form Dialog"
                @hide="hideDialog('Form')"
                @submit="submitDialog('Form')"
              >
                <template v-slot:body> </template>
              </dialog-Form>
            </v-dialog>
          </div>
          <h3 class="mt-3">case 03: draggabel modal</h3>
          <p>베경 제거 hide-overlay, 배경 클릭시 창 안닫힘 persistent, 바닥 클릭시 바운스 제거 no-click-animation</p>
          <div class="mt-2">
            <v-btn dark color="blue" @click="showDialog('Dragable')">
              show dialog draggable
            </v-btn>
            <v-dialog
              max-width="655"
              v-model="dialogDragable"
              content-class="square-modal modal-white dialog-draggable"
              persistent
              hide-overlay
              no-click-animation
            >
              <div class="modal-wrap">
                <h1 class="tit-h1 d-flex">
                  Dialog Draggable Sample
                  <div class="ml-auto align-self-center">
                    <v-icon x-small class="svg-close20" @click="hideDialog('Dragable')"></v-icon>
                  </div>
                </h1>
                <div class="modal-box">
                  <div>
                    모달 컨텐츠
                  </div>
                  <div class="text-right mt-3">
                    버튼
                  </div>
                </div>
              </div>
            </v-dialog>
          </div>

        </v-col>
        <!-- source -->
        <v-col cols="6">
          <v-tabs v-model="srcTab13" dark>
            <v-tab v-for="item in srcModal" :key="item.tab">
              {{ item.tab }}
            </v-tab>
            <v-btn depressed absolute x-small right @click="srcView">
             source fold
            </v-btn>
          </v-tabs>

          <v-tabs-items v-model="srcTab13">
            <v-tab-item v-for="item in srcModal" :key="item.tab">
              <pre
                class="guide-code"
                v-highlightjs
              ><code class="html">{{item.content}}</code></pre>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
        <!--// source -->
      </v-row>
    </v-card>
    <!-- //mark: treeview 13-->
    <v-card outlined light class="mt-3">
      <v-card-title :id="`${toplink[13].name}`">{{
        toplink[13].name
      }}</v-card-title>

      <v-divider />
      <v-row class="pa-3">
        <v-col>
          <div class="tree-wrap">
            <template>
              <v-treeview :items="treeItems" activatable open-on-click>
                <template v-slot:prepend="{ open }">
                  <v-icon color="#8B98DD">
                    {{ open ? "mdi-folder-open" : "mdi-folder" }}
                  </v-icon>
                </template>
              </v-treeview>
            </template>
          </div>
        </v-col>
        <!-- source -->
        <v-col cols="6">
          <v-tabs v-model="srcTab14" dark>
            <v-tab v-for="item in srcTreeview" :key="item.tab">
              {{ item.tab }}
            </v-tab>
            <v-btn depressed absolute x-small right @click="srcView">
             source fold
            </v-btn>
          </v-tabs>

          <v-tabs-items v-model="srcTab14">
            <v-tab-item v-for="item in srcTreeview" :key="item.tab">
              <pre
                class="guide-code"
                v-highlightjs
              ><code class="html">{{item.content}}</code></pre>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
        <!--// source -->
      </v-row>
    </v-card>
    <!-- //mark: calendar 14-->
    <v-card outlined light class="mt-3">
      <v-card-title :id="`${toplink[14].name}`">{{
        toplink[14].name
      }}</v-card-title>

      <v-divider />
      <v-row class="pa-3">
        <v-col>
          <div class="calendar-wrap">
            <div class="calendar-modal">
              <v-date-picker
                v-model="calendar"
                :events="arrayEventsCalendar"
                full-width
                no-title
                color="#F4B228"
                event-color="#F4B228"
                @change="calendarLog"
                locale="ko-KR"
                :first-day-of-week="1"
                :day-format="mixin_getDate"
              ></v-date-picker>
            </div>
            <div class="calendar-evt timeline-wrap" style="height: 150px">
              <v-timeline align-top dense>
                <v-timeline-item
                  small
                  v-for="item in arrayEvents"
                  :key="item.id"
                  :class="[
                    item.type === 'schedule' ? 'type-schedule' : 'type-evt',
                  ]"
                >
                  <v-row>
                    <v-col cols="4">
                      <strong
                        >{{ daySplit(item.day) }}
                        <span class="text-caption">일</span></strong
                      >
                    </v-col>
                    <v-col>
                      <strong> {{ item.titleLS }} </strong>
                      <div class="text-caption">
                        {{ item.desc }}
                      </div>
                    </v-col>
                  </v-row>
                </v-timeline-item>
              </v-timeline>
            </div>
          </div>
        </v-col>
        <!-- source -->
        <v-col cols="6">
          <v-tabs v-model="srcTab15" dark>
            <v-tab v-for="item in srcCalendar" :key="item.tab">
              {{ item.tab }}
            </v-tab>
            <v-btn depressed absolute x-small right @click="srcView">
             source fold
            </v-btn>
          </v-tabs>

          <v-tabs-items v-model="srcTab15">
            <v-tab-item v-for="item in srcCalendar" :key="item.tab">
              <pre
                class="guide-code"
                v-highlightjs
              ><code class="html">{{item.content}}</code></pre>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
        <!--// source -->
      </v-row>
    </v-card>
    <!-- //mark: picker 15-->
    <v-card outlined light class="mt-3">
      <v-card-title :id="`${toplink[15].name}`">{{
        toplink[15].name
      }}</v-card-title>

      <v-divider />
      <v-row class="pa-3">
        <v-col>
          <p class="clr-error">
            2022.04.15 : time , date&time에 ref속성 click이벤트 추가(자동 닫힘)
          </p>
          <div class="">
            <h3>case 01: date</h3>
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
              content-class="calendar-modal"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  hide-details
                  class="form-inp sm icon-calendar"
                  append-icon="svg-calendar"
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                color="#F4B228"
                @input="menu2 = false"
                :first-day-of-week="1"
                locale="ko-KR"
                :day-format="mixin_getDate"
              ></v-date-picker>
            </v-menu>
            <h3 class="mt-4">case 01-1: date range</h3>
            <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
              content-class="calendar-modal"
              color="#FFF"
            >
              <template v-slot:activator="{ on, attrs }">
                <div>
                  <v-text-field
                    v-model="dateRangeText"
                    v-bind="attrs"
                    v-on="on"
                    readonly
                    outlined
                    hide-details
                    class="form-inp icon-calendar"
                    append-icon="svg-calendar"
                    label="전체평가기간"
                  ></v-text-field>
                  <!-- 새로고침 버튼 -->
                  <v-btn
                    dark
                    small
                    class="btn-main-refresh pa-0 ml-2"
                    min-width="32px"
                    height="32px"
                    plain
                  >
                    <v-icon small class="svg-main-refresh"></v-icon>
                  </v-btn>
                </div>
              </template>
              <div class="datepicker-range">
                <v-date-picker
                  v-model="dates[0]"
                  no-title
                  color="#F4B228"
                  @change="startDate"
                  :first-day-of-week="1"
                  locale="ko-KR"
                  :day-format="mixin_getDate"
                ></v-date-picker>
                <v-date-picker
                  v-model="dates[1]"
                  color="#F4B228"
                  no-title
                  show-current="false"
                  :min="dates[0]"
                  @change="endDate"
                  :first-day-of-week="1"
                  locale="ko-KR"
                  :day-format="mixin_getDate"
                ></v-date-picker>
              </div>
              <div class="text-center pb-3">
                <v-btn outlined class="btn-default ml-3" @click="menu1 = false"
                  >확인</v-btn
                >
              </div>
            </v-menu>
          </div>
          <div class="mt-4">
            <h3>case 02: time</h3>
            <v-menu
              ref="menu3"
              v-model="menu3"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
              content-class="calendar-modal"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="timeStep"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  hide-details
                  class="form-inp sm icon-calendar"
                  append-icon="svg-time"
                >
                </v-text-field>
              </template>
              <v-time-picker
                v-model="timeStep"
                :allowed-minutes="allowedStep"
                color="#F4B228"
                format="24hr"
                @click:minute="$refs.menu3.save()"
              ></v-time-picker>
            </v-menu>
          </div>
          <div class="mt-4">
            <h3>case 03: date &amp; time</h3>
            <v-menu
              ref="menu4"
              v-model="menu4"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
              content-class="calendar-modal dateTime"
              color="#FFF"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="computedDateFormatted2"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  hide-details
                  class="form-inp icon-calendar"
                  append-icon="svg-calendar"
                ></v-text-field>
              </template>
              <div class="d-flex">
                <v-date-picker
                  v-model="date"
                  color="#F4B228"
                  show-current="false"
                  class="borderR16"
                  :first-day-of-week="1"
                  locale="ko-KR"
                  :day-format="mixin_getDate"
                ></v-date-picker>
                <v-time-picker
                  v-model="timeStep"
                  :allowed-minutes="allowedStep"
                  color="#F4B228"
                  format="24hr"
                  class="borderR16"
                  @click:minute="$refs.menu4.save()"
                ></v-time-picker>
              </div>
            </v-menu>
          </div>
        </v-col>
        <!-- source -->
        <v-col cols="6">
          <v-tabs v-model="srcTab16" dark>
            <v-tab v-for="item in srcPick" :key="item.tab">
              {{ item.tab }}
            </v-tab>
            <v-btn depressed absolute x-small right @click="srcView">
             source fold
            </v-btn>
          </v-tabs>

          <v-tabs-items v-model="srcTab16">
            <v-tab-item v-for="item in srcPick" :key="item.tab">
              <pre
                class="guide-code"
                v-highlightjs
              ><code class="html">{{item.content}}</code></pre>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
        <!--// source -->
      </v-row>
    </v-card>
    <!-- //mark: grid 16-->
    <v-card outlined light class="mt-3">
      <v-card-title :id="`${toplink[16].name}`">{{
        toplink[16].name
      }}</v-card-title>

      <v-divider />
      <v-row class="pa-3">
        <v-col>
          <div class="">
            <h3>case 01:</h3>
            <p>
              col resizing 기능 추가 :<br> methods //그리드 col resizing <span class="clr-primary">resizableGrid()</span><br>
              mounted : //그리드 col resizing 참고<br>
              말줄임 표시 클래스 ellipse  추가(data에 width속성 필요)<br/>
              드랍박스 선택에 따라 '회사' 컬럼명 변경기능

            </p>
            <div>
              <div class="form-inp sm">
                <v-select
                  :items="dropItemsCompany"
                  item-text="title"
                  outlined
                  hide-details
                  label="회사구분"
                  placeholder="선택하세요"
                  @change="FnGridChg"
                >
                  <template v-slot:label>
                    회사구분
                    <v-icon color="#EF9191">mdi-circle-small</v-icon>
                  </template>
                </v-select>
              </div>
              <v-data-table
                v-model="gridSelected"
                dense
                :headers="gridDataHeaders"
                :items="gridDataText"
                :items-per-page="30"
                item-key="name"
                :search="gridSearch"
                :page.sync="page"
                hide-default-footer
                class="grid-default mt-2"
                show-select
                calculate-widths
                fixed-header
                height="250px"
                @page-count="pageCount = $event"
              >
                <template v-slot:header.name>
                  {{ CURRENT_COMPANY }}
                </template>
              </v-data-table>
            </div>
            <h3>case 02:</h3>
            <div class="col-8">
              <v-data-table
                v-model="gridSelected"
                dense
                :headers="gridDataHeaders"
                :items="gridDataText"
                :items-per-page="30"
                item-key="name"
                :search="gridSearch"
                :page.sync="page"
                class="grid-default"
                hide-default-footer
                show-select
                calculate-widths
                @page-count="pageCount = $event"
              >
              </v-data-table>
              <div class="grid-paging text-center pt-2">
                <v-pagination
                  v-model="page"
                  :length="pageCount"
                  :total-visible="totalVisible"
                  next-icon="svg-paging-next"
                  prev-icon="svg-paging-prev"
                ></v-pagination>
              </div>
            </div>
            <h3>case 03: row click,합계</h3>
            <p>
              단일 선택 활성화시 single-select 속성 추가 기본 푸터 사용
              hide-details 제거
              <span class="clr-error">class="grid-default case03"</span>
            </p>
            <div class="col-12">
              <v-data-table
                dense
                :headers="gridDataHeaders"
                :items="gridDataText"
                :items-per-page="30"
                item-key="name"
                :page.sync="page"
                class="grid-default case03"
                @page-count="pageCount = $event"
                @click:row="rowClick"
                single-select
              >
                <template slot="body.append">
                  <tr class="grid-default-sum">
                    <td class="grid-default-sum--head">합계</td>
                    <td>
                      {{ mixin_sum_field(this.gridDataText, "index01") }}
                    </td>
                    <td>
                      {{ mixin_sum_field(this.gridDataText, "index02") }}
                    </td>
                    <td>
                      {{ mixin_sum_field(this.gridDataText, "index03") }}
                    </td>
                    <td>
                      {{ mixin_sum_field(this.gridDataText, "index04") }}
                    </td>
                    <td>
                      {{ mixin_sum_field(this.gridDataText, "index05") }}
                    </td>
                    <td>
                      {{ mixin_sum_field(this.gridDataText, "index06") }}
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </div>
            <h3>case 04: 체크박스, 선택 색상, 헤더 고정</h3>
            <div>
              <v-data-table
                dense
                height="300px"
                fixed-header
                hide-default-footer
                :headers="gridDataHeaders"
                :items="gridDataText"
                :items-per-page="30"
                item-key="name"
                :page.sync="page"
                class="grid-default"
                @page-count="pageCount = $event"
                :item-class="isActiveRow"
                show-select
                @click:row="rowSelect"
              ></v-data-table>
              <div class="grid-paging text-center pt-2">
                <v-pagination
                  v-model="page"
                  :length="pageCount"
                  :total-visible="totalVisible"
                  next-icon="svg-paging-next"
                  prev-icon="svg-paging-prev"
                ></v-pagination>
              </div>
            </div>
          </div>
        </v-col>
        <!-- source -->
        <v-col cols="6">
          <v-tabs v-model="srcTab17" dark>
            <v-tab v-for="item in srcGrid" :key="item.tab">
              {{ item.tab }}
            </v-tab>
            <v-btn depressed absolute x-small right @click="srcView">
             source fold
            </v-btn>
          </v-tabs>

          <v-tabs-items v-model="srcTab17">
            <v-tab-item v-for="item in srcGrid" :key="item.tab">
              <pre
                class="guide-code"
                v-highlightjs
              ><code class="html">{{item.content}}</code></pre>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
        <!--// source -->
      </v-row>
    </v-card>
    <!-- //mark: spacing 17-->
    <v-card outlined light class="mt-3">
      <v-card-title :id="`${toplink[17].name}`">{{
        toplink[17].name
      }}</v-card-title>

      <v-divider />
      <v-row class="pa-3">
        <v-col>
          <div class="">
            <h3>case 01: 기본 form 사이의 여백 설정</h3>
            '뒤에오는 DOM의 클래스에 ml-2(margin left)로 8px 여백을 준다'
            <div>
              <div class="form-inp">
                <v-select
                  :items="dropItems"
                  outlined
                  placeholder="선택하세요"
                ></v-select>
              </div>
              <div class="form-inp sm ml-2">
                <v-select
                  :items="dropItems"
                  outlined
                  placeholder="error-messages"
                  error-messages="에러 메시지 출력"
                ></v-select>
              </div>
            </div>
            <h3>case 02: 여러줄</h3>
            'form일 경우 각 라인 별로 div 태그로 묶은 뒤 뒤에 오는 DOM에 클래스
            mt-2(margin top)로 8px로 준다'
            <div>
              <div >
                <v-text-field
                  class="form-inp sm"
                  name="name"
                  label="주민번호"
                  value="000000"
                  outlined
                  hide-details
                >
                </v-text-field>
                <v-text-field
                  class="form-inp sm ml-2"
                  name="name"
                  label="휴대전화번호"
                  value=""
                  outlined
                  hide-details
                >
                </v-text-field>
              </div>
            </div>
            <div class="mt-2">
              <v-text-field
                class="form-inp"
                name="name"
                label="휴대전화번호"
                outlined
                hide-details
              >
              </v-text-field>
              <v-text-field
                class="form-inp ml-2"
                name="name"
                label="휴대전화번호"
                outlined
                hide-details
              >
              </v-text-field>
            </div>
          </div>
        </v-col>
        <!-- source -->
        <v-col cols="6">
          <v-tabs v-model="srcTab18" dark>
            <v-tab v-for="item in srcSpacing" :key="item.tab">
              {{ item.tab }}
            </v-tab>
            <v-btn depressed absolute x-small right @click="srcView">
             source fold
            </v-btn>
          </v-tabs>

          <v-tabs-items v-model="srcTab18">
            <v-tab-item v-for="item in srcSpacing" :key="item.tab">
              <pre
                class="guide-code"
                v-highlightjs
              ><code class="html">{{item.content}}</code></pre>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
        <!--// source -->
      </v-row>
    </v-card>
    <!-- //mark: tabs 18-->
    <v-card outlined light class="mt-3">
      <v-card-title :id="`${toplink[18].name}`">{{
        toplink[18].name
      }}</v-card-title>

      <v-divider />
      <v-row class="pa-3">
        <v-col>
          <div class="">
            <h3>case 01:</h3>
            <v-tabs class="tabs-form" slider-color="#6C6E7A" slider-size="3">
              <v-tab>스킬사별 상담사설정</v-tab>
              <v-tab>상담사별 스킬설정</v-tab>

              <v-tab-item>
                <v-card-text>Tab1</v-card-text>
              </v-tab-item>
              <v-tab-item>
                <v-card-text>Tab2</v-card-text>
              </v-tab-item>
            </v-tabs>
          </div>
        </v-col>
        <!-- source -->
        <v-col cols="6">
          <v-tabs v-model="srcTab19" dark>
            <v-tab v-for="item in srcTabs" :key="item.tab">
              {{ item.tab }}
            </v-tab>
            <v-btn depressed absolute x-small right @click="srcView">
             source fold
            </v-btn>
          </v-tabs>

          <v-tabs-items v-model="srcTab19">
            <v-tab-item v-for="item in srcTabs" :key="item.tab">
              <pre
                class="guide-code"
                v-highlightjs
              ><code class="html">{{item.content}}</code></pre>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
        <!--// source -->
      </v-row>
    </v-card>
    <!-- //mark: table 19-->
    <v-card outlined light class="mt-3">
      <v-card-title :id="`${toplink[19].name}`">{{
        toplink[19].name
      }}</v-card-title>

      <v-divider />
      <v-row class="pa-3">
        <v-col>
          <div class="">
            <h3>case 01: th항목은 127px</h3>
            <div class="table-form">
              <table>
                <caption class="hide">
                  사용자정보 항목
                </caption>
                <colgroup>
                  <col width="127px" />
                  <col width="" />
                  <col width="127px" />
                  <col width="" />
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="row">
                      <v-badge dot color="#EF9191">회사구분</v-badge>
                    </th>
                    <td colspan="3">
                      <div class="form-inp sm">
                        <v-select
                          :items="dropItems"
                          item-text="title"
                          outlined
                          hide-details
                          placeholder="선택하세요"
                        >
                        </v-select>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <v-badge dot color="#EF9191">사용자ID</v-badge>
                    </th>
                    <td>
                      <v-text-field
                        class="form-inp full"
                        name="name"
                        value=""
                        id="id"
                        outlined
                        hide-details=""
                      >
                      </v-text-field>
                    </td>
                    <th scope="row">
                      <v-badge dot color="#EF9191">사용자명</v-badge>
                    </th>
                    <td>
                      <v-text-field
                        class="form-inp full"
                        name="name"
                        value=""
                        id="id"
                        outlined
                        hide-details=""
                      >
                      </v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <v-badge dot color="#EF9191">닉네임</v-badge>
                    </th>
                    <td colspan="3">
                      <v-text-field
                        class="form-inp sm"
                        name="name"
                        value=""
                        id="id"
                        outlined
                        hide-details=""
                      >
                      </v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <v-badge dot color="#EF9191">소속</v-badge>
                    </th>
                    <td colspan="3">
                      <div>
                        <div class="form-inp sm">
                          <v-select
                            :items="dropItems"
                            item-text="title"
                            outlined
                            hide-details
                            placeholder="선택하세요"
                          >
                          </v-select>
                        </div>
                        <div class="form-inp sm ml-2">
                          <v-select
                            :items="dropItems"
                            item-text="title"
                            outlined
                            hide-details
                            placeholder="선택하세요"
                          >
                          </v-select>
                        </div>
                      </div>
                      <div class="mt-2">
                        <div class="form-inp sm">
                          <v-select
                            :items="dropItems"
                            item-text="title"
                            outlined
                            hide-details
                            placeholder="선택하세요"
                          >
                          </v-select>
                        </div>
                        <div class="form-inp sm ml-2">
                          <v-select
                            :items="dropItems"
                            item-text="title"
                            outlined
                            hide-details
                            placeholder="선택하세요"
                          >
                          </v-select>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h3 class="mt-2">case 02: 상단 테이블</h3>
            <div class="table-form">
              <table>
                <caption class="hide">
                  사용자정보 항목
                </caption>
                <colgroup>
                  <col width="50%" />
                  <col width="" />
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col">항목1</th>
                    <th scope="col">항목2</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>내용1</td>
                    <td>내용2</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </v-col>
        <!-- source -->
        <v-col cols="6">
          <v-tabs v-model="srcTab20" dark>
            <v-tab v-for="item in srcTable" :key="item.tab">
              {{ item.tab }}
            </v-tab>
            <v-btn depressed absolute x-small right @click="srcView">
             source fold
            </v-btn>
          </v-tabs>

          <v-tabs-items v-model="srcTab20">
            <v-tab-item v-for="item in srcTable" :key="item.tab">
              <pre
                class="guide-code"
                v-highlightjs
              ><code class="html">{{item.content}}</code></pre>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
        <!--// source -->
      </v-row>
    </v-card>
  </div>
</template>

<script>
//mark: script
import hljs from "highlight.js";
import DialogDefault from "@/components/dialogSample.vue";
import DialogForm from "@/components/dialogForm.vue";
import { mixin } from "@/mixin/mixin.js";

export default {
  name: "HelpGuide",
  //highlight syntax
  mixins: [mixin],
  directives: {
    highlightjs: {
      bind: function (el, binding) {
        let targets = el.querySelectorAll("code");
        targets.forEach((target) => {
          if (binding.value) {
            target.innerHTML = binding.value;
          }
          hljs.highlightBlock(target);
        });
      },
      componentUpdated: function (el, binding) {
        // after an update, re-fill the content and then highlight
        let targets = el.querySelectorAll("code");
        targets.forEach((target) => {
          if (binding.value) {
            target.textContent = binding.value;
            hljs.highlightBlock(target);
          }
        });
      },
    },
  },
  components: {
    DialogDefault,
    DialogForm,
  },
  data() {
    return {
      dialogText: false,
      dialogForm: false,
      dialogDragable: false,
      toast01: false,
      toplink: [
        {
          name: "FONT",
        },
        {
          name: "INPUT",
        },
        {
          name: "DROPDOWN",
        },
        {
          name: "CHECK",
        },
        {
          name: "RADIO",
        },
        {
          name: "BUTTON",
        },
        {
          name: "TEXTAREA",
        },
        {
          name: "CHIPS",
        },
        {
          name: "BADGES",
        },
        {
          name: "TOOLTIP",
        },
        {
          name: "TOAST",
        },
        {
          name: "ALERT",
        },
        {
          name: "MODAL",
        },
        {
          name: "TREEVIEW",
        },
        {
          name: "CALENDAR",
        },
        {
          name: "PICKER",
        },
        {
          name: "GRID",
        },
        {
          name: "SPACING",
        },
        {
          name: "TABS",
        },
        {
          name: "TABLE",
        },
      ],
      dropItemM: null,
      dropItems: [
        {
          title: "FONT",
        },
        {
          title: "INPUT",
        },
        {
          title: "DROPDOWN",
        },
        {
          title: "CHECK",
        },
        {
          title: "RADIO",
        },
        {
          title: "BUTTON",
        },
        {
          title: "TEXTAREA",
        },
        {
          title: "CHIPS",
        },
        {
          title: "BADGES",
        },
        {
          title: "TOOLTIP",
        },
        {
          title: "TOAST",
        },
        {
          title: "ALERT",
        },
        {
          title: "MODAL",
        },
        {
          title: "TREEVIEW",
        },
      ],
      dropItemsCompany: [
        { title : '회사 A'},
        { title : '회사 B'},
        { title : '회사 C'},
      ],
      dropItemRemove: true,
      chkTypes: [
        {
          text: "a",
          selected: true,
        },
        {
          text: "b",
          selected: true,
        },
        {
          text: "c",
          selected: false,
          isLast: true,
        },
      ],
      radioGroup: [
        {
          text: "radio01",
        },
        {
          text: "radio02",
        },
        {
          text: "radio03",
        },
      ],
      radioGroupSelected: "radio02",
      datePeriod: 0,
      textareaRules: [(v) => v.length <= 200 || "Max 200 characters"],
      textareaValue: "200자 제한!",
      badgeCnt: 999,
      tooltipMsg: "이것은 툴팁입니다",
      tooltipMsg2:
        "이것은 툴팁입니다. 이것은 툴팁입니다. 이것은 툴팁입니다. 이것은 툴팁입니다. 이것은 툴팁입니다. 이것은 툴팁입니다. 이것은 툴팁입니다. 이것은 툴팁입니다. ",
      modalDialog: false,
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,

      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dates: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      ],
      calendar: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      arrayEvents: [
        {
          "id": 0,
          "day": "2022-06-01",
          "titleLS": "기념일1",
          "desc": "기념일 세부내용",
          "type": "evt",
          "title": "기념일",
          "icon": "svg-dot-evt"
        },
        {
          "id": 1,
          "day": "2022-06-03",
          "titleLS": "기념일222",
          "desc": "기념일 세부내용222",
          "type": "evt",
          "title": "기념일",
          "icon": "svg-dot-evt"
        },
        {
          "id": 2,
          "day": "2022-06-04",
          "titleLS": "스케쥴임",
          "desc": "스케쥴 세뷰내용",
          "type": "schedule",
          "title": "스케쥴",
          "icon": "svg-dot-sch"
        }
      ],
      timeStep: "10:10",
      treeItems: [
        {
          id: 1,
          name: "menu1 :",
          children: [
            {
              id: 2,
              name: "menu1-1",
            },
            {
              id: 3,
              name: "menu1-2",
            },
            {
              id: 4,
              name: "menu1-3",
            },
          ],
        },
        {
          id: 5,
          name: "menu2 :",
          children: [
            {
              id: 6,
              name: "menu2-1 :",
              children: [
                {
                  id: 7,
                  name: "menu2-1-1 :",
                  children: [
                    {
                      id: 8,
                      name: "menu2-1-1-1",
                    },
                    {
                      id: 9,
                      name: "menu2-1-1-2",
                    },
                  ],
                },
              ],
            },
            {
              id: 10,
              name: "menu2-2 :",
              children: [
                {
                  id: 11,
                  name: "menu2-2-1 :",
                  children: [
                    {
                      id: 12,
                      name: "menu2-2-1-1",
                    },
                    {
                      id: 13,
                      name: "menu2-2-1-2",
                    },
                    {
                      id: 14,
                      name: "menu2-2-1-3",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: 15,
          name: "menu3 :",
          children: [
            {
              id: 16,
              name: "menu3-1",
            },
            {
              id: 17,
              name: "menu3-2",
            },
            {
              id: 18,
              name: "menu3-3",
            },
          ],
        },
      ],
      gridSelected: [],
      gridSearch: "",
      gridDataText: [
        {
          name: "Frozen Yogurt",
          index01: 159,
          index02: 6.0,
          index03: 24,
          index04: 4.0,
          index05: "1%",
          index06: true,
        },
        {
          name: "Ice cream sandwich",
          index01: 237,
          index02: 9.0,
          index03: 37,
          index04: 4.3,
          index05: "1%",
          index06: false,
        },
        {
          name: "Eclair",
          index01: 262,
          index02: 16.0,
          index03: 23,
          index04: 6.0,
          index05: "7%",
          index06: true,
        },
        {
          name: "Cupcake",
          index01: 305,
          index02: 3.7,
          index03: 67,
          index04: 4.3,
          index05: "8%",
          index06: false,
        },
        {
          name: "Gingerbread",
          index01: 356,
          index02: 16.0,
          index03: 49,
          index04: 3.9,
          index05: "16%",
          index06: false,
        },
        {
          name: "Jelly bean",
          index01: 375,
          index02: 0.0,
          index03: 94,
          index04: 0.0,
          index05: "0%",
          index06: false,
        },
        {
          name: "Lollipop",
          index01: 392,
          index02: 0.2,
          index03: 98,
          index04: 0,
          index05: "2%",
          index06: false,
        },
        {
          name: "Honeycomb",
          index01: 408,
          index02: 3.2,
          index03: 87,
          index04: 6.5,
          index05: "45%",
          index06: false,
        },
        {
          name: "Donut",
          index01: 452,
          index02: 25.0,
          index03: 51,
          index04: 4.9,
          index05: "22%",
          index06: false,
        },
        {
          name: "KitKat",
          index01: 518,
          index02: 26.0,
          index03: 65,
          index04: 7,
          index05: "6%",
          index06: false,
        },
      ],
      gridDataHeaders: [
        {
          text: "회사",
          align: "center",
          sortable: false,
          value: "name",
          width: "70px",
          cellClass: "ellipse"
        },

        {
          text: "이름",
          width: "80px",
          align: "center",
          value: "index01",
        },
        {
          text: "번호",
          width: "80px",
          align: "center",
          value: "index02",
        },
        {
          text: "제목2",
          align: "center",
          width: "80px",
          value: "index03",
        },
        {
          text: "제목3",
          width: "80px",
          align: "center",
          value: "index04",
        },
        {
          text: "제목4",
          width: "80px",
          align: "center",
          value: "index05",
        },
        {
          text: "첨부",
          width: "80px",
          align: "center",
          value: "index06",
        },
      ],
      CURRENT_COMPANY: '회사 A',
      page: 1,
      pageCount: 0,
      itemsPerPage: 5,
      totalVisible: 10,
      selectedRow: null,
      selectedRows: [],
      srcTab: null,
      srcTab2: null,
      srcTab3: null,
      srcTab4: null,
      srcTab5: null,
      srcTab6: null,
      srcTab7: null,
      srcTab8: null,
      srcTab9: null,
      srcTab10: null,
      srcTab11: null,
      srcTab12: null,
      srcTab13: null,
      srcTab14: null,
      srcTab15: null,
      srcTab16: null,
      srcTab17: null,
      srcTab18: null,
      srcTab19: null,
      srcTab20: null,
      srcFont: [
        {
          tab: "html",
          content: `<div class="font-weight-bold">BOLD</div>
<div class="font-weight-medium">MEDIUM</div>
<div class="">NORMAL</div>`,
        },
        {
          tab: "script",
          content: ``,
        },
      ],
      srcInput: [
        {
          tab: "html",
          content: `<h3>case 01:</h3>
<div>
  <v-text-field
    class="form-inp sm"
    name="name" label="size sm 124px" outlined >
  </v-text-field>
  <v-text-field
    class="form-inp lg"
    name="name"
    label="size lg 256px"
    outlined >
  </v-text-field>
  <v-text-field
    class="form-inp lg"
    name="name"
    label="label"
    outlined hint="고정 메시지 출력"
    persistent-hint>
  </v-text-field>
  <v-text-field
    class="form-inp lg"
    name="name"
    label="readonly"
    value="hide-details"
    outlined
    hide-details
    readonly>
  </v-text-field>
  <v-text-field
    class="form-inp lg"
    name="name"
    value="disabled hide-details"
    outlined
    disabled>
  </v-text-field>
  <v-text-field
    class="form-inp free"
    name="name"
    label="width auto "
    outlined
    disabled
  >
  </v-text-field>
  <v-text-field
    class="form-inp full"
    name="name"
    label="width 100% "
    outlined
    disabled
  >
  </v-text-field>
</div>
<h3>case 02: 버튼 있는 경우</h3>
<div>
  <v-text-field
    class="form-inp"
    name="name" label="버튼 있는 경우" outlined >
    <template v-slot:append>
      <v-btn
        depressed
        icon
        x-small
        @click="callMethod('call red')"
        class="fomr-inp--btn svg-call-red">
      </v-btn>
    </template>
  </v-text-field>
  <v-text-field
    class="form-inp"
    name="name" label="버튼 있는 경우" outlined >
    <template v-slot:append>
      <v-btn
        depressed
        icon
        x-small
        @click="callMethod('search')"
        class="fomr-inp--btn svg-search">
      </v-btn>
    </template>
  </v-text-field>
</div>
<h3>case 03: number</h3>
<div>
  <v-text-field
    class="form-inp sm"
    type="number"
    value="42"
  ></v-text-field>
<h3>case 04: suffix</h3>
<v-text-field
  class="form-inp sm"
  name="name"
  label="조회건수"
  id="id"
  outlined
  hide-details=""
  suffix="건"
  >
</v-text-field>
</div>`,
        },
        {
          tab: "script",
          content: `methods: {
  callRed(){
    alert('call red');
  }
},`,
        },
      ],
      srcDropdown: [
        {
          tab: "html",
          content: `<h3>case 01: v-select</h3>
<div class="form-inp">
  <v-select
    :items="dropItems"
    item-text="title"
    outlined
    placeholder="선택하세요"
  ></v-select>
</div>
<div class="form-inp sm ml-3">
  <v-select
    :items="dropItems"
    item-text="title"
    outlined
    placeholder="error-messages"
    error-messages="에러 메시지 출력"
  ></v-select>
</div>
<div class="form-inp lg">
  <v-select
    :items="dropItems"
    item-text="title"
    outlined
    placeholder="disalbed"
    disabled
  ></v-select>
</div>
<h3>case 02: v-select check multi 4개 이상 ... 표시</h3>
<div class="form-inp free">
  <v-select
    single-line
    v-model="dropItemM"
    :items="dropItems"
    item-text="title"
    outlined
    label="Select Item"
    multiple
  >
    <template v-slot:selection="{ item, index }">
      <v-chip v-if="index < 4" small>
        <span>{{ item.title }}</span>
        <template>
          <v-btn
            icon
            x-small
            class="svg-close20 ml-1"
            v-show="dropItemRemove === true"
            @click.stop="removeDropitem(index)"
          ></v-btn>
        </template>
      </v-chip>
      <span
        v-if="index === 3"
        class="grey--text text-caption"
      >
        ...
      </span>
    </template>
  </v-select>
</div>
<h3>case 03: required</h3>
<div class="form-inp sm">
  <v-select
    :items="dropItems"
    item-text="title"
    outlined
    hide-details
    label="회사구분"
    placeholder="선택하세요"
  >
    <template v-slot:label>
      회사구분
      <v-icon color="#EF9191">mdi-circle-small</v-icon>
    </template>
  </v-select>
</div>`,
        },
        {
          tab: "script",
          content: `data...
dropItemM : null,
dropItems: [
    {title:'FONT'}
  ,{title:'INPUT'}
  ,{title:'DROPDOWN'}
  ,{title:'CHECK'}
  ,{title:'RADIO'}
  ,{title:'BUTTON'}
  ,{title:'TEXTAREA'}
  ,{title:'CHIPS'}
  ,{title:'BADGES'}
  ,{title:'TOOLTIP'}
  ,{title:'TOAST'}
  ,{title:'ALERT'}
  ,{title:'MODAL'}
  ,{title:'TREEVIEW'}
],
dropItemRemove: true,
methods...
removeDropitem(index){
  this.dropItemM.splice(index, 1);
},`,
        },
      ],
      srcCheck: [
        {
          tab: "html",
          content: `<h3>case 01:</h3>
<v-checkbox
  :on-icon="'svg-chk-on'"
  :off-icon="'svg-chk-off'"
  label="label"></v-checkbox>
<h3>case 02: inline</h3>
<v-checkbox
  v-for="(chk01,index) in chkTypes"
  :key="chkTypes[index].text"
  :label="chk01.text"
  :on-icon="'svg-chk-on'"
  :off-icon="'svg-chk-off'"
  v-model="chk01.selected"
  class="d-inline-flex" :class="[chk01.isLast !== true ? 'pr-4' : '']"></v-checkbox>`,
        },
        {
          tab: "script",
          content: `chkTypes: [{
    text: 'a',
    selected: true
  },
  {
    text: 'b',
    selected: true
  },
  {
    text: 'c',
    selected: false,
    isLast: true
  },
],`,
        },
      ],
      srcRadio: [
        {
          tab: "html",
          content: `<h3>case 01:</h3>
<v-radio-group v-model="radioGroupSelected">
  <v-radio
    v-for="(rad01,index) in radioGroup" :key="radioGroup[index].text" :label="rad01.text"
    :on-icon="'svg-rad-on'"
    :off-icon="'svg-rad-off'"
    :value="rad01.text">
  </v-radio>
</v-radio-group>
<h3>case 01: inline</h3>
<v-radio-group v-model="radioGroupSelected" row class="radio-inline">
  <v-radio v-for="(rad01,index) in radioGroup" :key="radioGroup[index].text" :label="rad01.text"
    :on-icon="'svg-rad-on'"
    :off-icon="'svg-rad-off'"
    :value="rad01.text">
  </v-radio>
</v-radio-group>
<h3>case 03: label</h3>
<v-radio-group v-model="radioGroupSelected" row class="radio-inline ml-2">
  <template v-slot:label >
    <span class="in-label">정렬조건</span>
  </template>
  <v-radio v-for="(rad01,index) in radioGroup" :key="radioGroup[index].text" :label="rad01.text"
    :on-icon="'svg-rad-on'"
    :off-icon="'svg-rad-off'"
    :value="rad01.text">
  </v-radio>
</v-radio-group>`,
        },
        {
          tab: "script",
          content: `radioGroup: [{
    text: 'radio01'
  },
  {
    text: 'radio02'
  },
  {
    text: 'radio03'
  }
],
radioGroupSelected: 'radio02',`,
        },
      ],
      srcButton: [
        {
          tab: "html",
          content: `<h3>case 01: small</h3>
<v-btn outlined small class="btn-default">임시저장</v-btn>
<v-btn outlined small class="btn-etc ml-2">취소</v-btn>
<v-btn outlined small class="btn-etc2 ml-2">조회</v-btn>
<v-btn outlined small class="btn-point ml-2">전송</v-btn>
<v-btn outlined small disabled class="ml-2">disabled</v-btn>
<v-btn outlined small class="btn-etc3 ml-2">
  <v-icon small class="mr-2">mdi-plus</v-icon>
  더보기
</v-btn>

<h3>case 01-2: medium</h3>
<v-btn outlined class="btn-default">임시저장</v-btn>
<v-btn outlined class="btn-etc ml-2">취소</v-btn>
<v-btn outlined class="btn-etc2 ml-2">조회</v-btn>
<v-btn outlined class="btn-point ml-2">전송</v-btn>
<v-btn outlined disabled class="ml-2">disabled</v-btn>

<h3>case 01-3: large</h3>
<v-btn outlined large class="btn-default">임시저장</v-btn>
<v-btn outlined large class="btn-etc ml-2">취소</v-btn>
<v-btn outlined large class="btn-etc2 ml-2">조회</v-btn>
<v-btn outlined large class="btn-point ml-2">전송</v-btn>
<br>
<div class="mt-3">
  <v-btn outlined large disabled class="btn-default ">임시저장</v-btn>
  <v-btn outlined large disabled class="btn-etc ml-2">취소</v-btn>
  <v-btn outlined large disabled class="btn-etc2 ml-2">조회</v-btn>
  <v-btn outlined large disabled class="btn-point ml-2">전송</v-btn>
</div>


<h3>case 02: 버튼 그룹</h3>
<div class=" d-inline">
  <v-btn-toggle
    v-model="datePeriod"
  >
    <v-btn outlined small class="btn-default">전체</v-btn>
    <v-btn outlined small class="btn-default">전체</v-btn>
    <v-btn outlined small class="btn-default">전체</v-btn>
    <v-btn outlined small class="btn-default">전체</v-btn>
    <v-btn outlined small class="btn-default">전체</v-btn>
  </v-btn-toggle>
</div>

<h3>case 03: icon</h3>
<p>
  <a href="https://materialdesignicons.com/" target="_blank">https://materialdesignicons.com/</a>
  <br>위 사이트에서 필요한 아이콘 검색 후 '&lt;v-icon&gt;'태그 내에 mdi-{아이콘명} 형태로 사용
  <br>&lt;v-icon small &gt;mdi-arrow-collapse-down&lt;/v-icon&gt;
</p>
<v-btn outlined small class="btn-point">
  point
  <v-icon x-small class="svg-close"></v-icon>
</v-btn>
<v-btn outlined small class="btn-point">
  <v-icon x-small class="svg-close"></v-icon>
  point
</v-btn>
<v-btn outlined small class="btn-point">
  <v-icon x-small class="svg-close"></v-icon>
  point
  <v-icon x-small class="svg-close"></v-icon>
</v-btn>
<v-btn outlined small class="btn-point">
  <v-icon small >mdi-magnify</v-icon>
</v-btn>
<v-btn outlined small class="btn-point">
  <v-icon small >mdi-sync</v-icon>
</v-btn>
<h3>case 04: only icon & tooltip</h3>
<!-- 조회 버튼 -->
<v-btn dark small class="btn-main-search pa-0" min-width="32px" height="32px" plain @click="showDialog('M110101P02')">
  <v-icon small class="svg-main-searh white"></v-icon>
</v-btn>
<!-- 상세조회 버튼 -->
<v-tooltip bottom>
  <template v-slot:activator="{ on, attrs }">
    <v-btn dark small class="btn-main-search pa-0 ml-2" min-width="32px" height="32px" plain
      @click="csDetailSearch2 = true"
      v-bind="attrs"
      v-on="on">
      <v-icon small class="svg-main-search2"></v-icon>
    </v-btn>
  </template>
  <span>상세조회</span>
</v-tooltip>
<!-- 수정/저장 버튼 -->
<v-tooltip bottom>
  <template v-slot:activator="{ on, attrs }">
    <v-btn dark small class="btn-main-refresh pa-0 ml-2" min-width="32px" height="32px" plain
      v-bind="attrs"
      v-on="on">
      <v-icon small class="svg-main-down"></v-icon>
    </v-btn>
  </template>
  <span>수정/저장</span>
</v-tooltip>
<!-- 새로고침 버튼 -->
<v-btn dark small class="btn-main-refresh pa-0 ml-2" min-width="32px" height="32px"
  plain
  >
  <v-icon small class="svg-main-refresh"></v-icon>
</v-btn>
<!-- 필터 버튼 -->
<v-tooltip bottom>
  <template v-slot:activator="{ on, attrs }">
    <v-btn dark small class="btn-main-refresh pa-0 ml-2" min-width="32px" height="32px" plain
      v-bind="attrs"
      v-on="on">
      <v-icon small class="svg-main-filter"></v-icon>
    </v-btn>
  </template>
  <span>필터</span>
</v-tooltip>
<!-- 좌우화살표 -->
<v-btn
  class="btn-arrow"
  plain
  small
>
  <v-icon small class="svg-arrow-left"></v-icon>
</v-btn>
<v-btn
  class="btn-arrow"
  plain
  small
>
  <v-icon small class="svg-arrow-right"></v-icon>
</v-btn>
<v-btn
  class="btn-arrow"
  plain
  small
>
  <v-icon small class="svg-arrow-top"></v-icon>
</v-btn>
<v-btn
  class="btn-arrow"
  plain
  small
>
  <v-icon small class="svg-arrow-down"></v-icon>
</v-btn>
<div class="mt-2">
  <!-- 조회 버튼 -->
  <v-btn dark small disabled class="btn-main-search pa-0" min-width="32px" height="32px" plain @click="showDialog('M110101P02')">
    <v-icon small class="svg-main-searh white"></v-icon>
  </v-btn>
  <!-- 상세조회 버튼 -->
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn dark small disabled class="btn-main-search pa-0 ml-2" min-width="32px" height="32px" plain
        @click="csDetailSearch2 = true"
        v-bind="attrs"
        v-on="on">
        <v-icon small class="svg-main-search2"></v-icon>
      </v-btn>
    </template>
    <span>상세조회</span>
  </v-tooltip>
  <!-- 수정/저장 버튼 -->
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn dark small disabled class="btn-main-refresh pa-0 ml-2" min-width="32px" height="32px" plain
        v-bind="attrs"
        v-on="on">
        <v-icon small class="svg-main-down"></v-icon>
      </v-btn>
    </template>
    <span>수정/저장</span>
  </v-tooltip>
  <!-- 새로고침 버튼 -->
  <v-btn dark small disabled class="btn-main-refresh pa-0 ml-2" min-width="32px" height="32px"
    plain
    >
    <v-icon small class="svg-main-refresh"></v-icon>
  </v-btn>
  <!-- 필터 버튼 -->
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn dark small disabled class="btn-main-refresh pa-0 ml-2" min-width="32px" height="32px" plain
        v-bind="attrs"
        v-on="on">
        <v-icon small class="svg-main-filter"></v-icon>
      </v-btn>
    </template>
    <span>필터</span>
  </v-tooltip>
</div>`,
        },
        {
          tab: "script",
          content: `datePeriod: 0`,
        },
      ],
      srcTextarea: [
        {
          tab: "html",
          content: `<h3>case 01:</h3>
<v-textarea
  counter
  label=""
  :rules="textareaRules"
  :value="textareaValue"
  no-resize
  outlined
></v-textarea>
<h3>case 02: 카운터 없는 경우</h3>
<v-textarea
  class="textarea-type0"
  label=""
  :rules="textareaRules"
  :value="textareaValue"
  no-resize
  outlined
></v-textarea>`,
        },
        {
          tab: "script",
          content: `textareaRules: [v => v.length <= 200 || 'Max 200 characters'],
textareaValue: '200자 제한!',`,
        },
      ],
      srcChips: [
        {
          tab: "html",
          content: `<v-chip class="ma-2">
  Default
</v-chip>

<v-chip class="ma-2" color="primary">
  Primary
</v-chip>

<v-chip class="ma-2" color="secondary">
  Secondary
</v-chip>

<v-chip class="ma-2" color="red" text-color="white">
  Red Chip
</v-chip>

<v-chip class="ma-2" color="green" text-color="white">
  Green Chip
</v-chip>`,
        },
        {
          tab: "script",
          content: ``,
        },
      ],
      srcBadges: [
        {
          tab: "html",
          content: `<h3>case 01:</h3>
<div>
  <v-badge
    bordered
    color="primary"
    :content="badgeCnt"
  >오늘의 뉴스</v-badge>
</div>
<div class="mt-3">
  <v-badge
    bordered
    color="red"
    :content="badgeCnt"
  >내일의 뉴스</v-badge>
</div>`,
        },
        {
          tab: "script",
          content: `badgeCnt: 999,`,
        },
      ],
      srcTooltip: [
        {
          tab: "html",
          content: `<v-tooltip bottom>
  <template v-slot:activator="{ on, attrs }">
    <v-btn
      color="primary"
      dark
      v-bind="attrs"
      v-on="on"
    >
      아래서 나오는 툴팁
    </v-btn>
  </template>
  <span>{{ tooltipMsg }}</span>
</v-tooltip>

<v-tooltip bottom max-width="190px">
  <template v-slot:activator="{ on, attrs }">
    <v-btn
      color="primary"
      dark
      v-bind="attrs"
      v-on="on"
      class="ml-3"
    >
      여러줄 툴팁
    </v-btn>
  </template>
  <span>{{ tooltipMsg2 }}</span>
</v-tooltip>`,
        },
        {
          tab: "script",
          content: `tooltipMsg: '이것은 툴팁입니다',`,
        },
      ],
      srcToast: [
        {
          tab: "html",
          content: `<v-btn dark @click="showToast" >기본</v-btn>
<v-btn dark @click="showToastSucess" >sucess</v-btn>
<v-btn dark @click="showToastInfo" >info</v-btn>
<v-btn dark @click="showToastWarn" >warning</v-btn>
<v-btn dark @click="showToastError" >error</v-btn>`,
        },
        {
          tab: "script",
          content: `showToast() {
  this.$store.commit("toastStore/POP", {
    act: true,
    msg: '기본 메시지입니다.',
    class: '',
    hasToastIcon: false,
    icon: '',
  })
},
showToastSucess() {
  this.$store.commit("toastStore/POP", {
    act: true,
    msg: '기본 메시지입니다.',
    class: 'toast-sucess',
    hasToastIcon: true,
    icon: 'svg-sucess',
  })
},
showToastInfo() {
  this.$store.commit("toastStore/POP", {
    act: true,
    msg: '기본 메시지입니다.',
    class: 'toast-info',
    hasToastIcon: true,
    icon: 'svg-info',
  })
},
showToastWarn() {
  this.$store.commit("toastStore/POP", {
    act: true,
    msg: '기본 메시지입니다.',
    class: 'toast-warning',
    hasToastIcon: true,
    icon: 'svg-warning',
  })
},
showToastError() {
  this.$store.commit("toastStore/POP", {
    act: true,
    msg: '기본 메시지입니다.',
    class: 'toast-error',
    hasToastIcon: true,
    icon: 'svg-error',
  })
},`,
        },
      ],
      srcAlert: [
        {
          tab: "html",
          content: `<h3>case 01: alert</h3>
<v-btn dark @click="showAlert">경고 메세지</v-btn>
<v-btn dark @click="showAlert2" class="ml-1">완료 메세지</v-btn>

<h3 class="mt-3">case 02: 버튼 있는 alert</h3>
<v-btn dark @click="showAlert3">로그아웃</v-btn>
<v-btn dark @click="showAlert4" class="ml-1">내용 확인</v-btn>
<v-btn dark @click="showAlert5" class="ml-1">삭제
 확인</v-btn>

<h3 class="mt-3">case 03: 긴급 alert</h3>
<v-btn dark @click="showAlert6">콜백</v-btn>
<v-btn dark @click="showAlert7" class="ml-1">예약콜</v-btn>`,
        },
        {
          tab: "script",
          content: `showAlert(){
  this.$store.commit("alertStore/openAlertDialog", {
    alertDialogToggle: true,
    msg: '시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.',
    iconClass: 'svg-error-lg',
    type: 'default'
  })
},
showAlert2(){
  this.$store.commit("alertStore/openAlertDialog", {
    alertDialogToggle: true,
    msg: '정상처리 되었습니다.',
    iconClass: 'svg-done-lg',
    type: 'default'
  })
},
showAlert3(){
  this.$store.commit("alertStore/openAlertDialog", {
    alertDialogToggle: true,
    msg: '로그아웃 하시겠습니까?',
    iconClass: 'svg-noti-lg',
    type: 'confirm'
  })
},
showAlert4(){
  this.$store.commit("alertStore/openAlertDialog", {
    alertDialogToggle: true,
    msg: '내용을 저장하시겠습니까?',
    iconClass: 'svg-chk-lg',
    type: 'confirm'
  })
},
showAlert5(){
  this.$store.commit("alertStore/openAlertDialog", {
    alertDialogToggle: true,
    msg: '정말 삭제하시겠습니까?',
    iconClass: 'svg-error-lg',
    type: 'confirm'
  })
},
showAlert6(){
  this.$store.commit("alertStore/openAlertDialog", {
    alertDialogToggle: true,
    type: 'callback',
    count: 20,
  })
},
showAlert7(){
  this.$store.commit("alertStore/openAlertDialog", {
    alertDialogToggle: true,
    type: 'reserveCall',
    count: 10,
    reserveCallTime: 40,
  })
},`,
        },
      ],
      srcModal: [
        {
          tab: "html",
          content: `<h3>case 01: 모달 폼 중간 사이즈 dialogSample.vue</h3>
<div class="">
  <v-btn dark color="green" @click="showDialog('Text')" >
    show dialog text!
  </v-btn>
  <v-dialog max-width="655" v-model="dialogText" content-class="square-modal modal-white">
    <dialog-default
      header-title="텍스트"
      @hide="hideDialog('Text')"
      @submit="submitDialog('Text')"
    >
      <template v-slot:body> </template>
    </dialog-default>
  </v-dialog>
</div>
<h3 class="mt-3">case 02: 모달 폼 대형 사이즈 dialogForm.vue</h3>
<div class="mt-2">
  <v-btn dark @click="showDialog('Form')"> show dialog Form! </v-btn>
  <v-dialog max-width="1400" v-model="dialogForm" content-class="square-modal">
    <dialog-Form
      header-title="Form Dialog"
      @hide="hideDialog('Form')"
      @submit="submitDialog('Form')"
    >
      <template v-slot:body> </template>
    </dialog-Form>
  </v-dialog>
</div>
<h3 class="mt-3">case 03: draggabel modal</h3>
<div class="mt-2">
  <v-btn dark color="green" @click="showDialog('Text')">
    show dialog draggable
  </v-btn>
  <v-dialog
    max-width="655"
    v-model="dialogText"
    content-class="square-modal modal-white dialog-draggable"
    persistent
    hide-overlay
    no-click-animation
  >
    <div class="modal-wrap">
      <h1 class="tit-h1 d-flex">
        Dialog Draggable Sample
        <div class="ml-auto align-self-center">
          <v-icon x-small class="svg-close20" @click="hideDialog('Dragable')"></v-icon>
        </div>
      </h1>
      <div class="modal-box">
        <div>
          모달 컨텐츠
        </div>
        <div class="text-right mt-3">
          버튼
        </div>
      </div>
    </div>
  </v-dialog>
</div>
///////////////////////////////////
<div class="modal-wrap">
  <h1 class="tit-h1 d-flex">
    {{ headerTitle }}
    <div class="ml-auto align-self-center">
      <v-icon x-small class="svg-close20" @click="$emit('hide')"></v-icon>
    </div>
  </h1>
  <div class="modal-box">
    <div>
      모달 컨텐츠
    </div>
    <div class="text-right mt-3">
      버튼
    </div>
  </div>
</div>`,
        },
        {
          tab: "script",
          content: `import DialogDefault from './dialogSample.vue'
import DialogForm from './dialogForm.vue'
...
components: {
  DialogDefault,
  DialogForm,
},
...
data() {
  return {
    dialogText: false,
    dialogForm: false,
...
methods: {
  showDialog(type){
    this[\`dialog\${type}\`] = true
  },
  hideDialog(type){
    this[\`dialog\${type}\`] = false
  },
  submitDialog(type){
    console.log('완료');
    this[\`dialog\${type}\`] = false
  },`,
        },
      ],
      srcTreeview: [
        {
          tab: "html",
          content: `<div class="tree-wrap">
  <template>
    <v-treeview
      :items="treeItems"
      activatable
      open-on-click
    >
    <template v-slot:prepend="{ open }">
      <v-icon color="#8B98DD">
        {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
      </v-icon>

    </template>
    </v-treeview>
  </template>
</div>`,
        },
        {
          tab: "script",
          content: `treeItems: [
  {
    id: 1,
    name: 'menu1 :',
    children: [
      { id: 2, name: 'menu1-1' },
      { id: 3, name: 'menu1-2' },
      { id: 4, name: 'menu1-3' },
    ],
  },
  {
    id: 5,
    name: 'menu2 :',
    children: [
      {
        id: 6,
        name: 'menu2-1 :',
        children: [
          {
            id: 7,
            name: 'menu2-1-1 :',
            children: [
              { id: 8, name: 'menu2-1-1-1' },
              { id: 9, name: 'menu2-1-1-2' },
            ],
          },
        ],
      },
      {
        id: 10,
        name: 'menu2-2 :',
        children: [
          {
            id: 11,
            name: 'menu2-2-1 :',
            children: [
              { id: 12, name: 'menu2-2-1-1' },
              { id: 13, name: 'menu2-2-1-2' },
              { id: 14, name: 'menu2-2-1-3' },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 15,
    name: 'menu3 :',
    children: [
      { id: 16, name: 'menu3-1' },
      { id: 17, name: 'menu3-2' },
      { id: 18, name: 'menu3-3' },
    ],
  },
],`,
        },
      ],
      srcCalendar: [
        {
          tab: "html",
          content: `<div class="calendar-wrap">
  <div class="calendar-modal">
    <v-date-picker
      v-model="calendar"
      :events="arrayEventsCalendar"
      full-width
      no-title
      color="#F4B228"
      event-color="#F4B228"
      @change="calendarLog"
      locale="ko-KR"
      :first-day-of-week="1"
      :day-format="mixin_getDate"
    ></v-date-picker>
  </div>
  <div class="calendar-evt timeline-wrap" style="height: 150px">
    <v-timeline align-top dense>
      <v-timeline-item
        small
        v-for="item in arrayEvents"
        :key="item.id"
        :class="[
          item.type === 'schedule' ? 'type-schedule' : 'type-evt',
        ]"
      >
        <v-row>
          <v-col cols="4">
            <strong
              >{{ daySplit(item.day) }}
              <span class="text-caption">일</span></strong
            >
          </v-col>
          <v-col>
            <strong> {{ item.titleLS }} </strong>
            <div class="text-caption">
              {{ item.desc }}
            </div>
          </v-col>
        </v-row>
      </v-timeline-item>
    </v-timeline>
  </div>
</div>`,
        },
        {
          tab: "script",
          content: `data...
arrayEvents: [
  {
    "id": 0,
    "day": "2022-06-01",
    "titleLS": "기념일1",
    "desc": "기념일 세부내용",
    "type": "evt",
    "title": "기념일",
    "icon": "svg-dot-evt"
  },
  {
    "id": 1,
    "day": "2022-06-03",
    "titleLS": "기념일222",
    "desc": "기념일 세부내용222",
    "type": "evt",
    "title": "기념일",
    "icon": "svg-dot-evt"
  },
  {
    "id": 2,
    "day": "2022-06-04",
    "titleLS": "스케쥴임",
    "desc": "스케쥴 세뷰내용",
    "type": "schedule",
    "title": "스케쥴",
    "icon": "svg-dot-sch"
  }
],

methods....

//캘린더에 포인트 찍기
arrayEventsCalendar(date) {
  let colorArray = [];

  for (let i = 0; i < this.arrayEvents.length; i++) {
    if (this.arrayEvents[i].day === date) {
      if (this.arrayEvents[i].type === "schedule") {
        colorArray.push("#6DC4B1");
      } else if (this.arrayEvents[i].type === "evt") {
        colorArray.push("#F7B611");
      }
    }
  }
  return colorArray;
},
//캘린더 클릭 로그
  calendarLog(e) {
  const index = this.arrayEvents.findIndex(function (item) {
    return item.day === e;
  });

  if (index < 0) return;

  console.log("index = " + index);
  this.arrayEventDetail =
    this.arrayEvents[index].desc ?? this.arrayEvents[index].desc;
},

daySplit(e) {
  const day = e.split("-")[2];
  return day;
},

mounted....

//달력 이벤트 초기값
for(let index = 0; index < this.arrayEvents.length; index++){
  this.arrayEventsCalendar(this.arrayEvents[index].day);
}

`,
        },
      ],
      srcPick: [
        {
          tab: "html",
          content: `<div class="">
  <h3>case 01: date</h3>
  <v-menu
    v-model="menu2"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto"
    content-class="calendar-modal"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="date"
        v-bind="attrs"
        v-on="on"
        outlined
        hide-details
        class="form-inp sm icon-calendar"
        append-icon="svg-calendar"
      >
      </v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      color="#F4B228"
      @input="menu2 = false"
      :first-day-of-week="1"
      locale="ko-KR"
      :day-format="mixin_getDate"
    ></v-date-picker>
  </v-menu>
  <h3 class="mt-4">case 01-1: date range</h3>
  <v-menu
    v-model="menu1"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto"
    content-class="calendar-modal"
    color="#FFF"
  >
    <template v-slot:activator="{ on, attrs }">
      <div>
        <v-text-field
          v-model="dateRangeText"
          v-bind="attrs"
          v-on="on"
          readonly
          outlined
          hide-details
          class="form-inp icon-calendar"
          append-icon="svg-calendar"
          label="전체평가기간"
        ></v-text-field>
        <!-- 새로고침 버튼 -->
        <v-btn
          dark
          small
          class="btn-main-refresh pa-0 ml-2"
          min-width="32px"
          height="32px"
          plain
        >
          <v-icon small class="svg-main-refresh"></v-icon>
        </v-btn>
      </div>
    </template>
    <div class="datepicker-range">
      <v-date-picker
        v-model="dates[0]"
        no-title
        color="#F4B228"
        @change="startDate"
        :first-day-of-week="1"
        locale="ko-KR"
        :day-format="mixin_getDate"
      ></v-date-picker>
      <v-date-picker
        v-model="dates[1]"
        color="#F4B228"
        no-title
        show-current="false"
        :min="dates[0]"
        @change="endDate"
        :first-day-of-week="1"
        locale="ko-KR"
        :day-format="mixin_getDate"
      ></v-date-picker>
    </div>
    <div class="text-center pb-3">
      <v-btn outlined class="btn-default ml-3" @click="menu1 = false"
        >확인</v-btn
      >
    </div>
  </v-menu>
</div>
<div class="mt-4">
  <h3>case 02: time</h3>
  <v-menu
    ref="menu3"
    v-model="menu3"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto"
    content-class="calendar-modal "
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="timeStep"
        v-bind="attrs"
        v-on="on"
        outlined
        hide-details
        class="form-inp sm icon-calendar"
        append-icon="svg-time"
      >
      </v-text-field>
    </template>
    <v-time-picker
      v-model="timeStep"
      :allowed-minutes="allowedStep"
      color="#F4B228"
      format="24hr"
      @click:minute="$refs.menu3.save()"
    ></v-time-picker>
  </v-menu>
</div>
<div class="mt-4">
  <h3>case 03: date &amp; time</h3>
  <v-menu
    ref="menu4"
    v-model="menu4"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto"
    content-class="calendar-modal dateTime"
    color="#FFF"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="computedDateFormatted2"
        v-bind="attrs"
        v-on="on"
        outlined
        hide-details
        class="form-inp icon-calendar"
        append-icon="svg-calendar"
      ></v-text-field>
    </template>
    <div class="d-flex">
      <v-date-picker
        v-model="date"
        color="#F4B228"
        show-current="false"
        class="borderR16"
        :first-day-of-week="1"
        locale="ko-KR"
        :day-format="mixin_getDate"
      ></v-date-picker>
      <v-time-picker
        v-model="timeStep"
        :allowed-minutes="allowedStep"
        color="#F4B228"
        format="24hr"
        class="borderR16"
        @click:minute="$refs.menu4.save()"
      ></v-time-picker>
    </div>
  </v-menu>
</div>`,
        },
        {
          tab: "script",
          content: `data...
menu1: false,
menu2: false,
menu3: false,
menu4: false,
date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
dates: [
  (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
  (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
],
timeStep: '10:10',
....
methods:{
  formatDate (date) {
    if (!date) return null

    const [year, month, day] = date.split('-')
    return \`\${year}/\${month}/\${day}\`
  },
  startDate(e){
    this.dates[0] = e;
  },
  endDate(e){
    this.dates[1] = e;
  },
  allowedStep: (m) => m % 10 === 0,
}
....
computed: {
  dateRangeText () {
    var newStartDate = this.dates[0];
    var newEndDate = this.dates[1];
    return newStartDate + ' ~ ' + newEndDate;
    // return this.dates.join(' ~ ')
  },
  computedDateFormatted(){
      return this.formatDate(this.date)
  },
  computedDateFormatted2(){
      return \`\${this.date} \${this.timeStep}\`
  },

},`,
        },
      ],
      srcGrid: [
        {
          tab: "html",
          content: `<h3>case 01:</h3>
<div>
  <div class="form-inp sm">
    <v-select
      :items="dropItemsCompany"
      item-text="title"
      outlined
      hide-details
      label="회사구분"
      placeholder="선택하세요"
      @change="FnGridChg"
    >
      <template v-slot:label>
        회사구분
        <v-icon color="#EF9191">mdi-circle-small</v-icon>
      </template>
    </v-select>
  </div>
  <template>
    <v-text-field
      class="form-inp"
      v-model="gridSearch"
      name="name"
      label="버튼 있는 경우"
      outlined
    >
      <template v-slot:append>
        <v-btn
          depressed
          icon
          x-small
          @click="callMethod('search')"
          class="fomr-inp--btn svg-search"
        >
        </v-btn>
      </template>
    </v-text-field>
  </template>
  <v-data-table
    v-model="gridSelected"
    dense
    :headers="gridDataHeaders"
    :items="gridDataText"
    :items-per-page="30"
    item-key="name"
    :search="gridSearch"
    :page.sync="page"
    hide-default-footer
    class="grid-default"
    show-select
    calculate-widths
    fixed-header
    height="100px"
    @page-count="pageCount = $event"
  >
  <template v-slot:header.name>
    {{ CURRENT_COMPANY }}
  </template>
  </v-data-table>
</div>
<h3>case 02:</h3>
<div class="col-8">
  <template>
    <v-text-field
      class="form-inp"
      v-model="gridSearch"
      name="name"
      label="버튼 있는 경우"
      outlined
    >
      <template v-slot:append>
        <v-btn
          depressed
          icon
          x-small
          @click="callMethod('search')"
          class="fomr-inp--btn svg-search"
        >
        </v-btn>
      </template>
    </v-text-field>
  </template>
  <v-data-table
    v-model="gridSelected"
    dense
    :headers="gridDataHeaders"
    :items="gridDataText"
    :items-per-page="30"
    item-key="name"
    :search="gridSearch"
    :page.sync="page"
    hide-default-footer
    class="grid-default"
    show-select
    calculate-widths
    @page-count="pageCount = $event"
  >
  </v-data-table>
  <div class="grid-paging text-center pt-2">
    <v-pagination
      v-model="page"
      :length="pageCount"
      :total-visible="totalVisible"
      next-icon="svg-paging-next"
      prev-icon="svg-paging-prev"
    ></v-pagination>
  </div>
</div>
<h3>case 03: row click,합계 </h3>
<p>
  단일 선택 활성화시 single-select 속성 추가
</p>
<div class="col-12">
  <v-data-table
    dense
    :headers="gridDataHeaders"
    :items="gridDataText"
    :items-per-page="30"
    item-key="name"
    :page.sync="page"
    class="grid-default case03"
    @page-count="pageCount = $event"
    @click:row="rowClick"
    single-select
  >
  <template slot="body.append" v-if="gridDataText.length > 0">
    <tr class="grid-default-sum">
      <td class="grid-default-sum--head">합계</td>
      <td>{{ mixin_sum_field(this.gridDataText,'index01') }}</td>
      <td>{{ mixin_sum_field(this.gridDataText,'index02') }}</td>
      <td>{{ mixin_sum_field(this.gridDataText,'index03') }}</td>
      <td>{{ mixin_sum_field(this.gridDataText,'index04') }}</td>
      <td>{{ mixin_sum_field(this.gridDataText,'index05') }}</td>
      <td>{{ mixin_sum_field(this.gridDataText,'index06') }}</td>
    </tr>
  </template>
  </v-data-table>
  <div class="grid-paging text-center pt-2">
    <v-pagination
      v-model="page"
      :length="pageCount"
      :total-visible="totalVisible"
      next-icon="svg-paging-next"
      prev-icon="svg-paging-prev"
    ></v-pagination>
  </div>
</div>
<h3>case 04: 체크박스, 선택 색상, 헤더 고정</h3>
<div>
  <v-data-table
    dense
    height="300px"
    fixed-header
    hide-default-footer
    :headers="gridDataHeaders"
    :items="gridDataText"
    :items-per-page="30"
    item-key="name"
    :page.sync="page"
    class="grid-default"
    @page-count="pageCount = $event"
    :item-class="isActiveRow"
    show-select
    @click:row="rowSelect"
  ></v-data-table>
  <div class="grid-paging text-center pt-2">
    <v-pagination
      v-model="page"
      :length="pageCount"
      :total-visible="totalVisible"
      next-icon="svg-paging-next"
      prev-icon="svg-paging-prev"
    ></v-pagination>
  </div>
</div>`,
        },
        {
          tab: "script",
          content: `
.....import mixin....
import { mixin } from "@/mixin/mixin.js";
mixins: [mixin],
CURRENT_COMPANY: '회사 A',
dropItemsCompany: [
  { title : '회사 A'},
  { title : '회사 B'},
  { title : '회사 C'},
],
//그리드 드랍박스
FnGridChg(e ) {
  this.CURRENT_COMPANY = e;
}

data...
gridSearch: '',
gridDataText: [
  {
    name: 'Frozen Yogurt',
    index01: 159,
    index02: 6.0,
    index03: 24,
    index04: 4.0,
    index05: '1%',
  },
  {
    name: 'Ice cream sandwich',
    index01: 237,
    index02: 9.0,
    index03: 37,
    index04: 4.3,
    index05: '1%',
  },
  {
    name: 'Eclair',
    index01: 262,
    index02: 16.0,
    index03: 23,
    index04: 6.0,
    index05: '7%',
  },
  {
    name: 'Cupcake',
    index01: 305,
    index02: 3.7,
    index03: 67,
    index04: 4.3,
    index05: '8%',
  },
  {
    name: 'Gingerbread',
    index01: 356,
    index02: 16.0,
    index03: 49,
    index04: 3.9,
    index05: '16%',
  },
  {
    name: 'Jelly bean',
    index01: 375,
    index02: 0.0,
    index03: 94,
    index04: 0.0,
    index05: '0%',
  },
  {
    name: 'Lollipop',
    index01: 392,
    index02: 0.2,
    index03: 98,
    index04: 0,
    index05: '2%',
  },
  {
    name: 'Honeycomb',
    index01: 408,
    index02: 3.2,
    index03: 87,
    index04: 6.5,
    index05: '45%',
  },
  {
    name: 'Donut',
    index01: 452,
    index02: 25.0,
    index03: 51,
    index04: 4.9,
    index05: '22%',
  },
  {
    name: 'KitKat',
    index01: 518,
    index02: 26.0,
    index03: 65,
    index04: 7,
    index05: '6%',
  },
],
gridDataHeaders: [
  {
    text: '제목',
    align: 'start',
    sortable: false,
    value: 'name',
  },
  { text: '이름', value: 'index01' },
  { text: '번호', value: 'index02' },
  { text: '제목2', value: 'index03' },
  { text: '제목3', value: 'index04' },
  { text: '제목4', value: 'index05' },
],
page: 1,
pageCount: 0,
itemsPerPage: 5,
totalVisible: 10,
selectedRows: [],
selectedRow: null,

.....methods....
  rowClick: function (item, row) {
    const id = row.item.calories // just temporary
    const rowNo = this.selectedRows.indexOf(id)
    if(rowNo == -1) {
      this.selectedRows.push(id)
      row.select(true);
    } else {
      this.selectedRows.splice(rowNo, 1)
      row.select(false);
    }
    console.log(this.selectedRows)
  },
  log: function (event) {
    console.log(event)
  },
  rowSelect(idx) {
    this.selectedRow = idx;
  },
  isActiveRow(item){
    const activeClass = item === this.selectedRow ? 'active' : '';
    return activeClass;
  },`,
        },
      ],
      srcSpacing: [
        {
          tab: "html",
          content: `<h3>case 01: 기본 form 사이의 여백 설정</h3>
'뒤에오는 DOM의 클래스에 ml-2(margin left)로 8px 여백을 준다'
<div>
  <div class="form-inp">
    <v-select
      :items="dropItems"
      outlined
      placeholder="선택하세요"
    ></v-select>
  </div>
  <div class="form-inp sm ml-2">
    <v-select
      :items="dropItems"
      outlined
      placeholder="error-messages"
      error-messages="에러 메시지 출력"
    ></v-select>
  </div>
</div>
<h3>case 02: 여러줄 </h3>
'form일 경우 각 라인 별로 div 태그로 묶은 뒤 뒤에 오는 DOM에 클래스 mt-2(margin top)로 8px로 준다'
<div>
  <div>
    <v-text-field
      class="form-inp sm"
      name="name"
      label="주민번호"
      value="000000"
      outlined
      hide-details
    >
    </v-text-field>
    <v-text-field
      class="form-inp sm ml-2"
      name="name"
      label="휴대전화번호"
      value=""
      outlined
      hide-details
    >
    </v-text-field>
  </div>

</div>
<div class="mt-2">
  <v-text-field
      class="form-inp"
      name="name"
      label="휴대전화번호"
      outlined
      hide-details
    >
    </v-text-field>
    <v-text-field
      class="form-inp ml-2"
      name="name"
      label="휴대전화번호"
      outlined
      hide-details
    >
    </v-text-field>
</div>`,
        },
        {
          tab: "script",
          content: ``,
        },
      ],
      srcTabs: [
        {
          tab: "html",
          content: `<v-tabs
  class="tabs-form"
  slider-color="#6C6E7A"
  slider-size="3"
>
  <v-tab>스킬사별 상담사설정</v-tab>
  <v-tab>상담사별 스킬설정</v-tab>

  <v-tab-item>
    <v-card-text>Tab1</v-card-text>
  </v-tab-item>
  <v-tab-item>
    <v-card-text>Tab2</v-card-text>
  </v-tab-item>
</v-tabs>`,
        },
        {
          tab: "script",
          content: ``,
        },
      ],
      srcTable: [
        {
          tab: "html",
          content: `<div class="table-form">
  <table>
    <caption class="hide">
      사용자정보 항목
    </caption>
    <colgroup>
      <col width="127px" />
      <col width="" />
      <col width="127px" />
      <col width="" />
    </colgroup>
    <tbody>
      <tr>
        <th scope="row">
          <v-badge dot color="#EF9191">회사구분</v-badge>
        </th>
        <td colspan="3">
          <div class="form-inp sm">
            <v-select
              :items="dropItems"
              item-text="title"
              outlined
              hide-details
              placeholder="선택하세요"
            >
            </v-select>
          </div>
        </td>
      </tr>
      <tr>
        <th scope="row">
          <v-badge dot color="#EF9191">사용자ID</v-badge>
        </th>
        <td>
          <v-text-field
            class="form-inp sm"
            name="name"
            value=""
            id="id"
            outlined
            hide-details=""
          >
          </v-text-field>
        </td>
        <th scope="row">
          <v-badge dot color="#EF9191">사용자명</v-badge>
        </th>
        <td>
          <v-text-field
            class="form-inp sm"
            name="name"
            value=""
            id="id"
            outlined
            hide-details=""
          >
          </v-text-field>
        </td>
      </tr>
      <tr>
        <th scope="row">
          <v-badge dot color="#EF9191">닉네임</v-badge>
        </th>
        <td colspan="3">
          <v-text-field
            class="form-inp sm"
            name="name"
            value=""
            id="id"
            outlined
            hide-details=""
          >
          </v-text-field>
        </td>
      </tr>
      <tr>
        <th scope="row">
          <v-badge dot color="#EF9191">소속</v-badge>
        </th>
        <td colspan="3">
          <div>
            <div class="form-inp sm">
              <v-select
                :items="dropItems"
                item-text="title"
                outlined
                hide-details
                placeholder="선택하세요"
              >
              </v-select>
            </div>
            <div class="form-inp sm ml-2">
              <v-select
                :items="dropItems"
                item-text="title"
                outlined
                hide-details
                placeholder="선택하세요"
              >
              </v-select>
            </div>
          </div>
          <div class="mt-2">
            <div class="form-inp sm">
              <v-select
                :items="dropItems"
                item-text="title"
                outlined
                hide-details
                placeholder="선택하세요"
              >
              </v-select>
            </div>
            <div class="form-inp sm ml-2">
              <v-select
                :items="dropItems"
                item-text="title"
                outlined
                hide-details
                placeholder="선택하세요"
              >
              </v-select>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<h3 class="mt-2">case 02: 상단 테이블</h3>
<div class="table-form">
  <table>
    <caption class="hide">
      사용자정보 항목
    </caption>
    <colgroup>
      <col width="50%" />
      <col width="" />
    </colgroup>
    <thead>
      <tr>
        <th scope="col">항목1</th>
        <th scope="col">항목2</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>내용1</td>
        <td>내용2</td>
      </tr>
    </tbody>
  </table>
</div>`,
        },
        {
          tab: "script",
          content: ``,
        },
      ],
      srcViewVal: false,
    };
  },
  // mark: methods
  methods: {

    callMethod(text) {
      alert(text);
    },
    srcView(e) {
      var _t =
        e.currentTarget.parentElement.parentElement.parentElement.parentElement
          .nextElementSibling;
      var elms = _t.querySelector(".v-window__container");

      if (elms.style.display === "none") {
        elms.style.display = "block";
      } else {
        elms.style.display = "none";
      }
    },
    showDialog(type) {
      this[`dialog${type}`] = true;
    },
    hideDialog(type) {
      this[`dialog${type}`] = false;
    },
    submitDialog(type) {
      console.log("완료");
      this[`dialog${type}`] = false;
    },
    showAlert() {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: "시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.",
        iconClass: "svg-error-lg",
        type: "default",
      });
    },
    showAlert2() {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: "정상처리 되었습니다.",
        iconClass: "svg-done-lg",
        type: "default",
      });
    },
    showAlert3() {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: "로그아웃 하시겠습니까?",
        iconClass: "svg-noti-lg",
        type: "confirm",
        callYes: this.callYes,
        callNo: this.callNo,
      });
    },
    callYes() {
      console.log("call yes");
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
    },
    callNo() {
      console.log("call No");
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
    },
    showAlert4() {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: "내용을 저장하시겠습니까?",
        iconClass: "svg-chk-lg",
        type: "confirm",
        callYes: this.callYes,
        callNo: this.callNo,
      });
    },
    showAlert5() {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: "정말 삭제하시겠습니까?",
        iconClass: "svg-error-lg",
        type: "confirm",
        callYes: this.callYes,
        callNo: this.callNo,
      });
    },
    showAlert6() {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        type: "callback",
        count: 20,
      });
    },
    showAlert7() {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        type: "reserveCall",
        count: 10,
        reserveCallTime: 40,
      });
    },
    showToast() {
      this.$store.commit("toastStore/POP", {
        act: true,
        msg: "기본 메시지입니다.",
        class: "",
        hasToastIcon: false,
        icon: "",
      });
    },
    showToastSucess() {
      this.$store.commit("toastStore/POP", {
        act: true,
        msg: "기본 메시지입니다.",
        class: "toast-sucess",
        hasToastIcon: true,
        icon: "svg-sucess",
      });
    },
    showToastInfo() {
      this.$store.commit("toastStore/POP", {
        act: true,
        msg: "기본 메시지입니다.",
        class: "toast-info",
        hasToastIcon: true,
        icon: "svg-info",
      });
    },
    showToastWarn() {
      this.$store.commit("toastStore/POP", {
        act: true,
        msg: "기본 메시지입니다.",
        class: "toast-warning",
        hasToastIcon: true,
        icon: "svg-warning",
      });
    },
    showToastError() {
      this.$store.commit("toastStore/POP", {
        act: true,
        msg: "기본 메시지입니다.",
        class: "toast-error",
        hasToastIcon: true,
        icon: "svg-error",
      });
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${year}/${month}/${day}`;
    },
    startDate(e) {
      this.dates[0] = e;
    },
    endDate(e) {
      this.dates[1] = e;
    },
    allowedStep: (m) => m % 10 === 0,
    removeDropitem(index) {
      this.dropItemM.splice(index, 1);
    },
    rowClick: function (item, row) {
      const id = row.item; // just temporary
      const rowNo = this.selectedRows.indexOf(id);
      if (rowNo == -1) {
        row.select(true);
        this.selectedRows.push(id);
      } else {
        this.selectedRows.splice(rowNo, 1);
        row.select(false);
      }
      console.log(this.selectedRows);
    },
    log: function (event) {
      console.log(event);
    },
    rowSelect(idx) {
      this.selectedRow = idx;
    },
    isActiveRow(item) {
      const activeClass = item === this.selectedRow ? "active" : "";
      return activeClass;
    },
    // 그리드 col resizing
    resizableGrid(table) {
      var row = table.querySelectorAll('tr')[0],
          rowHead = table.querySelectorAll('thead tr')[0],
          cols = row ? row.children : undefined,
          colsHead = rowHead ? rowHead.children : undefined;

      if (!cols) return;

      // table.style.overflow = 'hidden';

      var tableHeight = table.offsetHeight;


      for (var i = 0; i < cols.length; i++) {
        var div = createDiv(tableHeight);
        cols[i].appendChild(div);
        cols[i].style.position = 'relative';
        setListeners(div);
        colsHead[i].style.position = 'sticky';
      }

      function setListeners(div) {
        var pageX, curCol, nxtCol, curColWidth, nxtColWidth;

        div.addEventListener('mousedown', function (e) {
          curCol = e.target.parentElement;
          nxtCol = curCol.nextElementSibling;
          pageX = e.pageX;

          var padding = paddingDiff(curCol);

          curColWidth = curCol.offsetWidth - padding;
          if (nxtCol)
            nxtColWidth = nxtCol.offsetWidth - padding;
        });

        div.addEventListener('mouseover', function (e) {
          e.target.style.borderRight = '2px solid #EA6472';
        })

        div.addEventListener('mouseout', function (e) {
          e.target.style.borderRight = '';
        })

        document.addEventListener('mousemove', function (e) {
          if (curCol) {
            var diffX = e.pageX - pageX;

            if (nxtCol)
              nxtCol.style.width = (nxtColWidth - (diffX)) + 'px';

            curCol.style.width = (curColWidth + diffX) + 'px';
          }
        });

        document.addEventListener('mouseup', function (e) {
          curCol = undefined;
          nxtCol = undefined;
          pageX = undefined;
          nxtColWidth = undefined;
          curColWidth = undefined
        });
      }

      function createDiv(height) {
        var div = document.createElement('div');
        div.style.top = 0;
        div.style.right = 0;
        div.style.width = '5px';
        div.style.position = 'absolute';
        div.style.cursor = 'col-resize';
        div.style.userSelect = 'none';
        div.style.height = height + 'px';
        div.style.zIndex = 1000;
        return div;
      }

      function paddingDiff(col) {

        if (getStyleVal(col, 'box-sizing') == 'border-box') {
          return 0;
        }

        var padLeft = getStyleVal(col, 'padding-left');
        var padRight = getStyleVal(col, 'padding-right');
        return (parseInt(padLeft) + parseInt(padRight));

      }

      function getStyleVal(elm, css) {
        return (window.getComputedStyle(elm, null).getPropertyValue(css))
      }
    },
    //캘린더에 포인트 찍기
    arrayEventsCalendar(date) {
      let colorArray = [];

      for (let i = 0; i < this.arrayEvents.length; i++) {
        if (this.arrayEvents[i].day === date) {
          if (this.arrayEvents[i].type === "schedule") {
            colorArray.push("#6DC4B1");
          } else if (this.arrayEvents[i].type === "evt") {
            colorArray.push("#F7B611");
          }
        }
      }
      return colorArray;
    },
    //캘린더 클릭 로그
     calendarLog(e) {
      const index = this.arrayEvents.findIndex(function (item) {
        return item.day === e;
      });

      if (index < 0) return;

      console.log("index = " + index);
      this.arrayEventDetail =
        this.arrayEvents[index].desc ?? this.arrayEvents[index].desc;
    },

    daySplit(e) {
      const day = e.split("-")[2];
      return day;
    },
    //그리드 드랍박스
    FnGridChg(e ) {
      this.CURRENT_COMPANY = e;
    }
  },
  computed: {
    dateRangeText() {
      // var newStartDate = this.formatDate(this.dates[0]);
      // var newEndDate = this.formatDate(this.dates[1]);
      var newStartDate = this.dates[0];
      var newEndDate = this.dates[1];
      return newStartDate + " ~ " + newEndDate;
      // return this.dates.join(' ~ ')
    },
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    computedDateFormatted2() {
      return `${this.date} ${this.timeStep}`;
    },
  },
  mounted() {
    // 그리드 col resizing
    var tables = document.querySelectorAll('.grid-default table');
    for (var i = 0; i < tables.length; i++) {
      this.resizableGrid(tables[0]);
    }
    //달력 이벤트 초기값
    for(let index = 0; index < this.arrayEvents.length; index++){
      this.arrayEventsCalendar(this.arrayEvents[index].day);
    }
  },
};

//dialog draggable
(function () { // make vuetify dialogs movable
    const d = {};
    document.addEventListener("mousedown", e => {
      const closestDialog = e.target.closest(".dialog-draggable.v-dialog.v-dialog--active");
        if (e.button === 0 && closestDialog != null && e.target.classList.contains("tit-h1")) { // element which can be used to move element
            d.el = closestDialog; // element which should be moved
            d.mouseStartX = e.clientX;
            d.mouseStartY = e.clientY;
            d.elStartX = d.el.getBoundingClientRect().left;
            d.elStartY = d.el.getBoundingClientRect().top;
            d.el.style.position = "fixed";
            d.el.style.margin = 0;
            d.oldTransition = d.el.style.transition;
            d.el.style.transition = "none"
        }
    });
    document.addEventListener("mousemove", e => {
        if (d.el === undefined) return;
        d.el.style.left = Math.min(
            Math.max(d.elStartX + e.clientX - d.mouseStartX, 0),
            window.innerWidth - d.el.getBoundingClientRect().width
        ) + "px";
        d.el.style.top = Math.min(
            Math.max(d.elStartY + e.clientY - d.mouseStartY, 0),
            window.innerHeight - d.el.getBoundingClientRect().height
        ) + "px";
    });
    document.addEventListener("mouseup", () => {
        if (d.el === undefined) return;
        d.el.style.transition = d.oldTransition;
        d.el = undefined
    });
    setInterval(() => { // prevent out of bounds
        const dialog = document.querySelector(".v-dialog.v-dialog--active");
        if (dialog === null) return;
        dialog.style.left = Math.min(parseInt(dialog.style.left), window.innerWidth - dialog.getBoundingClientRect().width) + "px";
        dialog.style.top = Math.min(parseInt(dialog.style.top), window.innerHeight - dialog.getBoundingClientRect().height) + "px";
    }, 100);
})();
</script>

<style>
/* html,
body {
  overflow-y: auto !important;
} */
.guide-code,
.guide-code-inline {
  overflow: auto;
  width: 100%;
  max-height: 500px;
}

.guide-code code,
.guide-code-inline code {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.guide-code code:before,
.guide-code-inline code:before {
  display: none;
}

.guide-code .hljs,
.guide-code-inline .hljs {
  display: block;
  overflow-x: auto;
  padding: 12px 15px;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono,
    Courier New;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.8;
}

.guide-code .hljs,
.guide-code .hljs-subst,
.guide-code-inline .hljs,
.guide-code-inline .hljs-subst {
  color: #444;
}

.guide-code .hljs-comment,
.guide-code-inline .hljs-comment {
  color: #5c6370;
}

.guide-code .hljs-keyword,
.guide-code .hljs-attribute,
.guide-code .hljs-selector-tag,
.guide-code .hljs-meta-keyword,
.guide-code .hljs-doctag,
.guide-code .hljs-name,
.guide-code-inline .hljs-keyword,
.guide-code-inline .hljs-attribute,
.guide-code-inline .hljs-selector-tag,
.guide-code-inline .hljs-meta-keyword,
.guide-code-inline .hljs-doctag,
.guide-code-inline .hljs-name {
  font-weight: 400;
}

.guide-code .hljs-type,
.guide-code .hljs-string,
.guide-code .hljs-number,
.guide-code .hljs-selector-id,
.guide-code .hljs-selector-class,
.guide-code .hljs-quote,
.guide-code .hljs-template-tag,
.guide-code .hljs-deletion,
.guide-code-inline .hljs-type,
.guide-code-inline .hljs-string,
.guide-code-inline .hljs-number,
.guide-code-inline .hljs-selector-id,
.guide-code-inline .hljs-selector-class,
.guide-code-inline .hljs-quote,
.guide-code-inline .hljs-template-tag,
.guide-code-inline .hljs-deletion {
  color: #880000;
}

.guide-code .hljs-title,
.guide-code .hljs-section,
.guide-code-inline .hljs-title,
.guide-code-inline .hljs-section {
  color: #880000;
  font-weight: bold;
}

.guide-code .hljs-regexp,
.guide-code .hljs-symbol,
.guide-code .hljs-variable,
.guide-code .hljs-template-variable,
.guide-code .hljs-link,
.guide-code .hljs-selector-attr,
.guide-code .hljs-selector-pseudo,
.guide-code-inline .hljs-regexp,
.guide-code-inline .hljs-symbol,
.guide-code-inline .hljs-variable,
.guide-code-inline .hljs-template-variable,
.guide-code-inline .hljs-link,
.guide-code-inline .hljs-selector-attr,
.guide-code-inline .hljs-selector-pseudo {
  color: #bc6060;
}

.guide-code .hljs-literal,
.guide-code-inline .hljs-literal {
  color: #78a960;
}

.guide-code .hljs-built_in,
.guide-code .hljs-bullet,
.guide-code .hljs-code,
.guide-code .hljs-addition,
.guide-code-inline .hljs-built_in,
.guide-code-inline .hljs-bullet,
.guide-code-inline .hljs-code,
.guide-code-inline .hljs-addition {
  color: #397300;
}

.guide-code .hljs-meta,
.guide-code-inline .hljs-meta {
  color: #1f7199;
}

.guide-code .hljs-meta-string,
.guide-code-inline .hljs-meta-string {
  color: #4d99bf;
}

.guide-code .hljs-emphasis,
.guide-code-inline .hljs-emphasis {
  font-style: italic;
}

.guide-code .hljs-strong,
.guide-code-inline .hljs-strong {
  font-weight: bold;
}

.guide-code .hljs,
.guide-code-inline .hljs {
  color: #383a42;
  background: transparent;
}

.guide-code .hljs-comment,
.guide-code .hljs-quote,
.guide-code-inline .hljs-comment,
.guide-code-inline .hljs-quote {
  color: rgba(0, 0, 0, 0.4);
}

.guide-code .hljs-meta,
.guide-code-inline .hljs-meta {
  color: #5697d9;
}

.guide-code .hljs-doctag,
.guide-code .hljs-keyword,
.guide-code .hljs-formula,
.guide-code-inline .hljs-doctag,
.guide-code-inline .hljs-keyword,
.guide-code-inline .hljs-formula {
  color: #4078f2;
}

.guide-code .hljs-section,
.guide-code .hljs-name,
.guide-code .hljs-selector-tag,
.guide-code .hljs-deletion,
.guide-code .hljs-subst,
.guide-code-inline .hljs-section,
.guide-code-inline .hljs-name,
.guide-code-inline .hljs-selector-tag,
.guide-code-inline .hljs-deletion,
.guide-code-inline .hljs-subst {
  color: #5697d9;
}

.guide-code .hljs-literal,
.guide-code-inline .hljs-literal {
  color: #0184bb;
}

.guide-code .hljs-string,
.guide-code .hljs-regexp,
.guide-code .hljs-addition,
.guide-code .hljs-meta-string,
.guide-code-inline .hljs-string,
.guide-code-inline .hljs-regexp,
.guide-code-inline .hljs-addition,
.guide-code-inline .hljs-meta-string {
  color: #e06c75;
}

.guide-code .hljs-built_in,
.guide-code .hljs-class .hljs-title,
.guide-code-inline .hljs-built_in,
.guide-code-inline .hljs-class .hljs-title {
  color: #d98d41;
}

.guide-code .hljs-attr,
.guide-code .hljs-variable,
.guide-code .hljs-template-variable,
.guide-code .hljs-type,
.guide-code .hljs-selector-class,
.guide-code .hljs-selector-attr,
.guide-code .hljs-selector-pseudo,
.guide-code .hljs-number,
.guide-code-inline .hljs-attr,
.guide-code-inline .hljs-variable,
.guide-code-inline .hljs-template-variable,
.guide-code-inline .hljs-type,
.guide-code-inline .hljs-selector-class,
.guide-code-inline .hljs-selector-attr,
.guide-code-inline .hljs-selector-pseudo,
.guide-code-inline .hljs-number {
  color: #d98d41;
}

.guide-code .hljs-symbol,
.guide-code .hljs-bullet,
.guide-code .hljs-link,
.guide-code .hljs-selector-id,
.guide-code .hljs-attribute,
.guide-code .hljs-title,
.guide-code-inline .hljs-symbol,
.guide-code-inline .hljs-bullet,
.guide-code-inline .hljs-link,
.guide-code-inline .hljs-selector-id,
.guide-code-inline .hljs-attribute,
.guide-code-inline .hljs-title {
  color: #4078f2;
}

.guide-code .hljs-emphasis,
.guide-code-inline .hljs-emphasis {
  font-style: normal;
}

.guide-code .hljs-strong,
.guide-code-inline .hljs-strong {
  font-weight: bold;
}

.guide-code .hljs-link,
.guide-code-inline .hljs-link {
  text-decoration: underline;
}

.guide-code .hljs-tag,
.guide-code-inline .hljs-tag {
  color: #9e9e9e;
}

.guide-code-inline {
  width: auto;
}
.topNav {
  position: fixed;
  right: 80px;
  z-index: 10;
  transition: all 0.2s;
  opacity: .5;
}
.topNav:hover {
  opacity: 1;
}

.timeline-wrap .v-timeline .v-timeline-item.type-schedule .v-timeline-item__inner-dot {
    background-color: #6DC4B1 !important;
    border-color: #6DC4B1 !important;
}
.timeline-wrap .v-timeline .v-timeline-item.type-evt .v-timeline-item__inner-dot {
    background-color: #F7B611 !important;
    border-color: #F7B611 !important;
}
</style>
