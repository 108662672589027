<template>
<div class="modal-wrap">
  <h1 class="tit-h1 d-flex">
    {{ headerTitle }}
    <div class="ml-auto align-self-center">
      <v-icon x-small class="svg-close20" @click="closeDialog()"></v-icon>
    </div>
  </h1>
  <div class="modal-box">
    <div>
      <template v-if="p01">
        <div class="gray-box">
          <div class="d-flex">
            <div class="form-inp sm">
              <v-select
                :items="PLT008"
                item-text="text"
                item-value="value"
                outlined
                hide-details
                label="유형타입"
                placeholder="선택하세요"
                v-model="evalPaper.QA_EVA_TY"
                :disabled="USE_YN ? true : false"
              >
                <template v-slot:label>
                  유형타입
                  <v-icon color="#EF9191">mdi-circle-small</v-icon>
                </template>
              </v-select>
            </div>
            <div class="form-inp sm ml-2">
              <v-select
                disabled
                :items="PLT012"
                item-text="text"
                item-value="value"
                outlined
                hide-details
                label="사용여부"
                placeholder="선택하세요"
              ></v-select>
            </div>
            <v-text-field
              class="form-inp ml-2"
              name="name"
              label="평가표명"
              outlined
              hide-details
              v-model="evalPaper.QA_EVA_NM"
              :disabled="USE_YN ? true : false"
              >
              <template v-slot:label>
                평가표명
                <v-icon color="#EF9191">mdi-circle-small</v-icon>
              </template>
            </v-text-field>
          </div>
          <div class="mt-2">
            <div class="form-inp sm">
              <v-select
                :items="PLT012"
                item-text="text"
                item-value="value"
                outlined
                hide-details
                label="이의제기"
                placeholder="선택하세요"
                v-model="evalPaper.QA_EVA_RE_YN"
                :disabled="USE_YN ? true : false"
              >
                <template v-slot:label>
                이의제기
                <v-icon color="#EF9191">mdi-circle-small</v-icon>
              </template>
              </v-select>
            </div>
            <v-radio-group v-model="evalPaper.QA_EVA_CN" row class="radio-inline ml-2" :disabled="USE_YN ? true : false">
                <template v-slot:label >
                  <span class="in-label">차수 <v-icon color="#EF9191">mdi-circle-small</v-icon></span>
                </template>
                <v-radio v-for="(rad01,index) in radioGroup" :key="radioGroup[index].text" :label="rad01.text"
                  :on-icon="'svg-rad-on'"
                  :off-icon="'svg-rad-off'"
                  :value="rad01.value">
                </v-radio>
              </v-radio-group>
            <v-text-field
              class="form-inp sm ml-2"
              name="name" label="총점" outlined hide-details v-model="evalPaper.QA_EVA_SUM" disabled>
            </v-text-field>
          </div>
        </div>
        <div class="mt-3">
          <v-textarea
            class="textarea-type0"
            label=""
            no-resize
            outlined
            v-model="evalPaper.QA_EVA_RT"
            max="500"
            :disabled="USE_YN ? true : false"
          ></v-textarea>
        </div>
      </template>
      <template v-if="p02">
        <div class="gray-box">
          <div class="d-flex">
            <div class="form-inp sm">
              <v-select
                :items="PLT011"
                item-text="text"
                item-value="value"
                outlined
                hide-details
                label="유형"
                placeholder="선택하세요"
                v-model="qaQs.QA_QS_TY"
                :disabled="USE_YN ? true : false"
              >
              <template v-slot:label>
                유형
                <v-icon color="#EF9191">mdi-circle-small</v-icon>
              </template>
              </v-select>
            </div>
            <div class="form-inp sm ml-2">
              <v-select
                :items="PLT012"
                item-text="text"
                item-value="value"
                outlined
                hide-details
                label="사용여부"
                placeholder="선택하세요"
                v-model="qaQs.QA_QS_YN"
                disabled
              ></v-select>
            </div>
            <v-text-field
              class="form-inp ml-2"
              name="name" label="항목명" outlined hide-details
              v-model="qaQs.QA_QS_NM"
              max="50"
              :disabled="USE_YN ? true : false"
              >
              <template v-slot:label>
                항목명
                <v-icon color="#EF9191">mdi-circle-small</v-icon>
              </template>
            </v-text-field>
            <div class="form-inp sm ml-2">
              <v-select
                :items="PLT009"
                item-text="text"
                item-value="value"
                outlined
                hide-details
                label="대분류"
                placeholder="선택하세요"
                v-model="qaQs.QA_TY_L_CD"
                :disabled="USE_YN ? true : false"
              >
                <template v-slot:label>
                  대분류
                  <v-icon color="#EF9191">mdi-circle-small</v-icon>
                </template>
              </v-select>
            </div>
            <div class="form-inp sm ml-2">
              <v-select
                :items="PLT010"
                item-text="text"
                item-value="value"
                outlined
                hide-details
                label="소분류"
                placeholder="선택하세요"
                v-model="qaQs.QA_TY_M_CD"
                :disabled="USE_YN ? true : false"
              >
                <template v-slot:label>
                  소분류
                  <v-icon color="#EF9191">mdi-circle-small</v-icon>
                </template>
              </v-select>
            </div>

            <v-text-field
              class="form-inp sm ml-2"
              name="name" label="배점" outlined hide-details readonly
              v-model="qaQs.QA_QS_TY_SC"
              >
            </v-text-field>
          </div>
        </div>
        <div class="mt-3">
          <h2 class="tit-h2 d-flex">문항 및 보기 등록
            <div class="ml-auto">
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnAdd1')" outlined class="btn-point" @click="addQaQsRow()" :disabled="USE_YN ? true : false">추가</v-btn>
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDelete1')" outlined class="btn-etc ml-2" @click="delQaQsRow()" :disabled="USE_YN ? true : false">삭제</v-btn>
            </div>
          </h2>
          <div class="table-form mt-2">
            <table>
              <caption class="hide"> 항목</caption>
              <colgroup>
                <col width="40px">
                <col width="">
                <col width="">
                <col width="">
                <col width="">
              </colgroup>
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">NO</th>
                  <th scope="col">문항</th>
                  <th scope="col">점수</th>
                  <th scope="col">정렬</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(qaVe, index) in qaVeList" :key="index">
                  <td>
                    <v-checkbox
                      :on-icon="'svg-chk-on'"
                      :off-icon="'svg-chk-off'"
                      hide-details
                      :id="'qaVe_'+index"
                      name="qaVe"
                      v-model="qaVe.isChecked"
                      :disabled="USE_YN ? true : false"
                      >
                    </v-checkbox>
                  </td>
                  <td class="text-center">{{index + 1}}</td>
                  <td>
                    <v-text-field
                      class="form-inp lg"
                      name="name" label="문항" outlined hide-details
                      v-model="qaVe.QA_QS_VE_RT"
                      max="1000"
                      :disabled="USE_YN ? true : false"
                    >
                    <template v-slot:label>
                      문항
                      <v-icon color="#EF9191">mdi-circle-small</v-icon>
                    </template>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      class="form-inp sm"
                      name="name" label="배점" outlined hide-details
                      v-model="qaVe.QA_QS_VE_SC"
                      type="number"
                      @change="setQaQsSc"
                      min="0"
                      max="9999"
                      :disabled="USE_YN ? true : false"
                      >
                      <template v-slot:label>
                        배점
                        <v-icon color="#EF9191">mdi-circle-small</v-icon>
                      </template>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      class="form-inp sm"
                      name="name" label="정렬" outlined hide-details
                      v-model="qaVe.QA_QS_VE_OD"
                      type="number"
                      :disabled="USE_YN ? true : false"
                      >
                      <template v-slot:label>
                        정렬
                        <v-icon color="#EF9191">mdi-circle-small</v-icon>
                      </template>
                    </v-text-field>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </template>
      <template v-if="p03">
         <div class="d-flex gray-box">
          <div class="form-inp sm">
            <v-select
              :items="PLT008"
              item-text="text"
              item-value="value"
              outlined
              hide-details
              label="유형 타입"
              placeholder="선택하세요"
              v-model="propsEvlPaper.QA_EVA_TY"
              disabled
            ></v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              :items="PLT012"
              item-text="text"
              item-value="value"
              outlined
              hide-details
              label="사용여부"
              placeholder="선택하세요"
              :value="propsEvlPaper.USE_COUNT > 0? 'Y' : 'N'"
              disabled
            ></v-select>
          </div>
          <v-text-field
            class="form-inp sm ml-2"
            name="name" label="평가표명" outlined hide-details
            v-model="propsEvlPaper.QA_EVA_NM"
            disabled>
          </v-text-field>
          <v-text-field
            class="form-inp sm ml-2"
            name="name" label="총점" outlined hide-details
            v-model="getSc"
            disabled>
          </v-text-field>
        </div>
        <div class="mt-3">
          <h2 class="tit-h2 d-flex">문항 및 보기 등록
            <div class="ml-auto">
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnAdd2')" outlined class="btn-point" @click="$emit('addrow')" :disabled="USE_YN ? true : false">추가</v-btn>
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDelete2')" outlined class="btn-etc ml-2" @click="delEvlRstRow()" :disabled="USE_YN ? true : false">삭제</v-btn>
            </div>
          </h2>
          <div class="table-form mt-2">
            <table>
              <caption class="hide">항목</caption>
              <colgroup>
                <col width="40px">
                <col width="">
                <col width="">
                <col width="">
                <col width="">
              </colgroup>
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">NO</th>
                  <th scope="col">문항</th>
                  <th scope="col">점수</th>
                  <th scope="col">정렬</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in evlRstList" :key="index">
                  <td>
                    <v-checkbox
                      :on-icon="'svg-chk-on'"
                      :off-icon="'svg-chk-off'"
                      hide-details
                      v-model="item.isChecked"
                      :disabled="USE_YN ? true : false"
                      >
                    </v-checkbox>
                  </td>
                  <td class="text-center">{{index + 1}}</td>
                  <td>{{item.QA_QS_NM}}</td>
                  <td>{{item.QA_QS_TY_SC}}</td>
                  <td>{{item.QA_EVA_RST_OD}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </template>
      <template v-if="p04">
        <div class="qa-report" style="height:auto">
          <template v-for="(item, index) in preEvlRstData">
            <dl :key="index">
              <dt>{{index + 1}}. {{item.QA_QS_NM}}({{item.QA_QS_TY_SC}}점)</dt>
              <dd class="mt-3">
                <template v-for="(ve, index2) in preViewList" >
                  <div v-if="item.QA_QS_ID == ve.QA_QS_ID" :key="index2" class="">
                    <!-- 서술형 -->
                    <template v-if="ve.QA_QS_TY === '20'">
                      <div>
                        <v-text-field
                          class="form-inp full"
                          name="name" placeholder="직접입력" id="id" outlined hide-details disabled>
                        </v-text-field>
                      </div>
                      <div>
                        <v-text-field
                          class="form-inp full "
                          type="number"
                          :value="ve.QA_QS_VE_SC"
                          disabled
                        ></v-text-field>
                      </div>
                    </template>
                    <!-- 객관식, 체크박스 -->
                    <template v-else>
                      {{ve.QA_QS_VE_RT}}
                      <span class="value">({{ve.QA_QS_VE_SC}})</span>
                    </template>
                  </div>
                </template>

              </dd>
            </dl>
          </template>
         </div>
      </template>
    </div>
    <div class="text-right mt-3">
      <template v-if="p02">
        <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnInit')" outlined class="btn-point" @click="$emit('init')" :disabled="USE_YN ? true : false">초기화</v-btn>
      </template>
      <template v-if="!p04">
        <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSave')" outlined class="btn-point ml-2" @click="common_confirm('저장하시겠습니까?',processRtn, '', null,'','chk')" :disabled="USE_YN ? true : false">저장</v-btn>
      </template>
    </div>
  </div>
</div>
</template>

<script>
import api from "@/store/apiUtil.js"
import {mixin} from "@/mixin/mixin.js"

export default {
  name: "MENU_M310101P01",
  mixins : [mixin],
  props: {
    p01: {
      type: Boolean,
      default: false,
    },
    p02: {
      type: Boolean,
      default: false,
    },
    p03: {
      type: Boolean,
      default: false,
    },
    p04: {
      type: Boolean,
      default: false,
    },

    headerTitle: {
      type: String,
      default: '제목',
    },
    PLT008 : [],
    PLT009 : [],
    PLT010 : [],
    PLT011 : [],
    PLT012 : [],
    isModify : {
      type : Boolean,
      default: false,
    },
    propsEvlPaper : {
        QA_EVA_NM : "",
        QA_EVA_TY : "",
        QA_EVA_RE_YN : "",
        QA_EVA_CN : "",
        QA_EVA_SUM : "",
        QA_EVA_RT : "",
        QA_EVA_ID : "",
    },
    propsQaQs : {

    },
    propsEvlRst : [],
    preEvlRstData : [],
    preViewList : [],
    aspNewCustKey : {
      type : String
    }
  },
  data: () => ({
    valid: true,
    footerHideTitle: '닫기',
    dropItems: [
      {title:'option1', value :"1"}
    ],
    radioGroup: [{
        text: '1차'
        , value : '1'
      },
      {
        text: '2차'
        , value : '2'
      },
      {
        text: '3차'
        , value : '3'
      }
    ],
    radioGroupSelected: '1차',
    evalPaper : {
      QA_EVA_NM : "",
      QA_EVA_TY : "",
      QA_EVA_RE_YN : "",
      QA_EVA_CN : "",
      QA_EVA_SUM : "",
      QA_EVA_RT : "",
      QA_EVA_ID : "",
    },
    qaQs : {
      QA_QS_ID : "",
      QA_TY_L_CD : "",
      QA_TY_M_CD : "",
      QA_QS_NM : "",
      QA_QS_TY : "",
      QA_QS_TY_SC : "",
      QA_QS_YN : "",
    },
    qaVeList : [
    ],
    delQaVeList : [

    ],
    evlRstList : [],
    USE_YN : false,

  }),
  created(){
    this.getPropsEvlPaper();
    this.getPropsQaQs();
    this.getPropsEvlRst();
  },
  watch:{
    propsEvlPaper : function(){
      this.getPropsEvlPaper();
    },
    propsQaQs : function(){
      this.getPropsQaQs();
    },
    propsEvlRst : function(){
      this.getPropsEvlRst();
    }
  },
  methods: {
    getPropsEvlRst(){
      // this.propsEvlRst.map(e => this.evlRstList.push(e));
      this.evlRstList = this.propsEvlRst;
    },
    getPropsEvlPaper(){
      if(this.propsEvlPaper != null){
        this.evalPaper.QA_EVA_NM = this.propsEvlPaper.QA_EVA_NM;
        this.evalPaper.QA_EVA_TY = this.propsEvlPaper.QA_EVA_TY;
        this.evalPaper.QA_EVA_RE_YN = this.propsEvlPaper.QA_EVA_RE_YN;
        this.evalPaper.QA_EVA_CN = this.propsEvlPaper.QA_EVA_CN;
        this.evalPaper.QA_EVA_SUM = this.propsEvlPaper.QA_EVA_SUM;
        this.evalPaper.QA_EVA_RT = this.propsEvlPaper.QA_EVA_RT;
        this.evalPaper.QA_EVA_ID = this.propsEvlPaper.QA_EVA_ID;
        this.USE_YN = this.propsEvlPaper.USE_COUNT > 0 ? true : false;
      }
    },
    getPropsQaQs(){
      if(this.propsQaQs != null){
        this.qaQs.QA_QS_ID = this.propsQaQs.QA_QS_ID;
        this.qaQs.QA_TY_L_CD = this.propsQaQs.QA_TY_L_CD;
        this.qaQs.QA_TY_M_CD = this.propsQaQs.QA_TY_M_CD;
        this.qaQs.QA_QS_NM = this.propsQaQs.QA_QS_NM;
        this.qaQs.QA_QS_TY = this.propsQaQs.QA_QS_TY;
        this.qaQs.QA_QS_TY_SC = this.propsQaQs.QA_QS_TY_SC;
        this.qaQs.QA_QS_YN = this.propsQaQs.QA_QS_YN;
        this.USE_YN = this.propsQaQs.USE_COUNT > 0 ? true : false;
        this.selectRtnQaVe();
      }
    },
    processRtn(){
      if(this.p01){
        this.insertRtnEvlPaper();
      }else if(this.p02){
        this.insertRtnQaQs();
      }else if(this.p03){
        this.$emit('submit', this.evlRstList);
      }
    },
    async insertRtnEvlPaper(){
      if(this.evalPaper.QA_EVA_TY == undefined || this.evalPaper.QA_EVA_TY == ''){
        this.warningAlert("유형타입은 필수입니다.");
        return;
      }

      if(this.evalPaper.QA_EVA_NM == undefined || this.evalPaper.QA_EVA_NM == ''){
        this.warningAlert("평가표명은 필수입니다.");
        return;
      }

      if(this.evalPaper.QA_EVA_RE_YN == undefined || this.evalPaper.QA_EVA_RE_YN == ''){
        this.warningAlert("이의제기는 필수입니다.");
        return;
      }

      if(this.evalPaper.QA_EVA_CN == undefined || this.evalPaper.QA_EVA_CN == ''){
        this.warningAlert("차수는 필수입니다.");
        return;
      }


      await api.post('api/phone/qa2/evl-paper-manage/regist',{
        ['ASP_NEWCUST_KEY']: this.aspNewCustKey,
        ['QA_EVA_TY']: this.evalPaper.QA_EVA_TY,
        ['QA_EVA_NM']: this.evalPaper.QA_EVA_NM,
        ['QA_EVA_RE_YN'] : this.evalPaper.QA_EVA_RE_YN,
        ['QA_EVA_CN'] : this.evalPaper.QA_EVA_CN,
        ['QA_EVA_SUM'] : this.evalPaper.QA_EVA_SUM,
        ['QA_EVA_RT'] : this.evalPaper.QA_EVA_RT,
        ['REG_ID'] : this.$store.getters['userStore/GE_USER_ROLE'].userId,
        ['QA_EVA_ID'] : this.evalPaper.QA_EVA_ID,
      })
      .then((response) => {
        this.$emit('submit', this.evalPaper);
        this.successAlert();
      })
      .catch((err) => {
        this.errorAlert();
      })
    },
    async insertRtnQaQs(){

      if(this.qaQs.QA_QS_TY == undefined || this.qaQs.QA_QS_TY == ''){
        this.warningAlert("유형은 필수입니다.");
        return;
      }else if(this.qaQs.QA_QS_NM == undefined || this.qaQs.QA_QS_NM == ''){
        this.warningAlert("항목명은 필수입니다.");
        return;
      }else if(this.qaQs.QA_TY_L_CD == undefined || this.qaQs.QA_TY_L_CD == ''){
        this.warningAlert("대분류는 필수입니다.");
        return;
      }else if(this.qaQs.QA_TY_M_CD == undefined || this.qaQs.QA_TY_M_CD == ''){
        this.warningAlert("소분류는 필수입니다.");
        return;
      }


      if(this.qaVeList.length == 0){
        this.warningAlert("문항 보기를 등록해 주세요.");
        return;
      }

      if(this.qaQs.QA_QS_TY === '10'){
        //객관식
        if(this.qaVeList.length < 2){
          this.warningAlert("객관식은 문항 보기를 2개 이상 등록해 주세요.");
          return;
        }
      }else if(this.qaQs.QA_QS_TY === '20'){
        //서술형
        if(this.qaVeList.length != 1){
          this.warningAlert("서술형은 문항 보기를 1개만 등록해 주세요.");
          return;
        }
      }else if(this.qaQs.QA_QS_TY === '30'){
        //체크박스형
        if(this.qaVeList.length < 2){
          this.warningAlert("체크박스형은 문항 보기를 2개 이상 등록해 주세요.");
          return;
        }
      }

      let qa_ve_empty = false;
      let qa_ve_sc_empty = false;
      let qa_ve_odr_empty = false;

      this.qaVeList.map(e => {
        if(e.QA_QS_VE_RT == undefined || e.QA_QS_VE_RT == ''){
          qa_ve_empty = true;
        }

        if(e.QA_QS_VE_OD == undefined || e.QA_QS_VE_OD == ''){
          qa_ve_odr_empty = true;
        }

        if(e.QA_QS_VE_SC == undefined || e.QA_QS_VE_SC == ''){
          qa_ve_sc_empty = true;
        }
      });

      if(qa_ve_empty){
        this.warningAlert("보기 문항은 필수입니다.");
        return;
      }else if(qa_ve_sc_empty){
        this.warningAlert("보기 점수는 필수입니다.");
        return;
      }else if(qa_ve_odr_empty){
        this.warningAlert("보기 정렬은 필수입니다.");
        return;
      }

      let URLData = "api/phone/qa2/evl-qs-manage/regist";
      let HEADER_SERVICE = "phone.qa2.evl-qs-manage";
      let HEADER_METHOD = "regist";
      let HEADER_TYPE = "BIZ_SERVICE";
      let DATA_OBJECT = "qaVeList,delQaVeList"

      let jsonData = {
        'ASP_NEWCUST_KEY': this.aspNewCustKey,
        'QA_QS_ID' : this.qaQs.QA_QS_ID,
        'QA_TY_L_CD' : this.qaQs.QA_TY_L_CD,
        'QA_TY_M_CD' : this.qaQs.QA_TY_M_CD,
        'QA_QS_NM' : this.qaQs.QA_QS_NM,
        'QA_QS_TY' : this.qaQs.QA_QS_TY,
        'QA_QS_TY_SC' : this.qaQs.QA_QS_TY_SC,
        'QA_QS_YN' : this.qaQs.QA_QS_YN,
        'REG_ID' : this.$store.getters['userStore/GE_USER_ROLE'].userId,
        'qaVeList' : this.qaVeList,
        'delQaVeList' : this.delQaVeList,
      };

      await api.post(URLData, jsonData, {
        head : {
          SERVICE: HEADER_SERVICE,
          METHOD: HEADER_METHOD,
          TYPE: HEADER_TYPE,
          DATA_OBJECT : DATA_OBJECT,
        }
      })
      .then((response) => {
        this.$emit('submit', this.qaQs);
        this.successAlert();
      })
      .catch((err) => {
        this.errorAlert();
      })
    },
    successAlert(){
      this.common_alert(`정상처리 되었습니다.`, 'done');
    },
    errorAlert(){
      this.common_alert(`시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.`, 'error');
    },
    warningAlert(text){
      let alertText = `시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.`;
      if(!this.mixin_isEmpty(text)) {
        alertText = text;
      }
      this.common_alert(alertText, 'error');
    },
    addQaQsRow(){
      this.qaVeList.push({
        QA_QS_VE_ID : "",
        QA_QS_VE_RT : "",
        QA_QS_VE_SC : "",
        QA_QS_VE_OD : "",
      });
    },
    delQaQsRow(){
      this.qaVeList = this.qaVeList.filter(item => {
        if(!item.isChecked){
          return item;
        }else{
          if(item.QA_QS_VE_ID != ''){
            this.delQaVeList.push(item);
          }
        }

        });
    },
    delEvlRstRow(){
      this.evlRstList = this.evlRstList.filter(item => {
        if(!item.isChecked){
          return item;
        }
      });
    },
    closeDialog(){
      this.$emit('hide');
    },
    async selectRtnQaVe(){
      if(this.isModify){
        let URLData = "api/phone/qa2/evl-qs-manage/ve/list";
        let HEADER_SERVICE = "phone.qa2.evl-qs-manage.ve";
        let HEADER_METHOD = "list";
        let HEADER_TYPE = "BIZ_SERVICE";

        let jsonData = {
          'ASP_NEWCUST_KEY': this.aspNewCustKey,
          'REG_ID' : this.$store.getters['userStore/GE_USER_ROLE'].userId,
          'QA_QS_ID' : this.qaQs.QA_QS_ID ,
        };

        await api.post(URLData, jsonData, {
          head : {
            SERVICE: HEADER_SERVICE,
            METHOD: HEADER_METHOD,
            TYPE: HEADER_TYPE,
          }
        })
        .then((response) => {
          // this.$emit('submit', this.qaQs);
          // this.successAlert();
          this.qaVeList = response.data.DATA;
        })
        .catch((err) => {
          this.errorAlert();
        })
      }
    },
    setQaQsSc(){
      let sc = 0;
      if(this.qaVeList != null){
        this.qaVeList.map((value) => {
          if(value.QA_QS_VE_SC > 0){
            if(this.qaQs.QA_QS_TY == '10' || this.qaQs.QA_QS_TY == '20'){
              //객관식, 서술형 (각 보기의 최고 점수가 배점)
              if(sc < parseInt(value.QA_QS_VE_SC)){
                sc = parseInt(value.QA_QS_VE_SC);
              }
            }else if(this.qaQs.QA_QS_TY == '30'){
              //체크박스형 (각 보기의 점수의 합이 배점)
              sc += parseInt(value.QA_QS_VE_SC);
            }
          }
        })
      }
      this.qaQs.QA_QS_TY_SC = sc;
    },
  },
  computed : {
    getSc(){
      let sc = 0;
      this.propsEvlRst.map(e => {
        sc += parseInt(e.QA_QS_TY_SC)
      });
      return sc;
    },
  }
}
</script>

<style>

</style>