<template>
<div class="modal-wrap">
  <h1 class="tit-h1 d-flex">
    금칙어 신규등록
    <div class="ml-auto align-self-center">
      <v-icon x-small class="svg-close20" @click="$emit('hide')"></v-icon>
    </div>
  </h1>
  <div class="modal-box">
    <div>
      <v-form ref="form"
        v-model="isFormValid"
        lazy-validation>
      <div class="table-form">
        <table>
          <caption class="hide">
            정보 항목
          </caption>
          <colgroup>
            <col width="127px" />
            <col width="" />
          </colgroup>
            <tbody>
              <tr>
                <th scope="row">
                  <v-badge dot color="#EF9191">금칙어</v-badge>
                </th>
                <td colspan="3">
                  <v-text-field
                    v-model="formWordData.NEW_PROH_WORD"
                    class="form-inp full"
                    maxlength="100"
                    hide-details="auto"
                    outlined
                    required
                    :rules="[formRules.newWord.required]"
                  >
                  </v-text-field>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <v-badge dot color="#EF9191">변환어</v-badge>
                </th>
                <td colspan="3">
                  <v-text-field
                      v-model="formWordData.NEW_CHANGE_WORD"
                      class="form-inp full"
                      maxlength="100"
                      hide-details="auto"
                      outlined
                      required
                      :rules="[formRules.chWord.required]"
                  >
                  </v-text-field>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <v-badge dot color="#EF9191">사용여부</v-badge>
                </th>
                <td colspan="3">
                  <v-select
                      :items="mixin_common_code_get(this.common_code, 'TWB001')"
                      class="form-inp full"
                      outlined
                      hide-details
                      placeholder="사용여부"
                      v-model="formWordData.NEW_USE_YN"
                      required
                      :rules="[formRules.useYn.required]"
                  >
                    <template v-slot:label>
                      사용여부
                    </template>
                  </v-select>
                </td>
              </tr>
            </tbody>
        </table>
      </div>
      </v-form>
      <div class="text-right mt-3">
        <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSave')" outlined class="btn-point" id="btnSave" @click="submit">저장</v-btn>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import api from "../../store/apiUtil";
import { mixin } from "@/mixin/mixin.js";
import { mapGetters } from "vuex";

let jsonObj

export default {
  name: "MENU_M810608P01", //name은 'MENU_' + 파일명 조합
  mixins:[mixin],
  props: {
    searchCustKey: {
      type: String,
      default: ''
    },
  },
  data(){
    return {
      common_code: [],
      mainVo: function() {
        this.NEW_PROH_WORD=''
        this.NEW_CHANGE_WORD=''
        this.NEW_USE_YN=''
        this.ASP_NEWCUST_KEY=''
      },
      formWordData: {},
      isFormValid: true,
      formRulesSubfix: ' 은(는) 필수입력입니다.',
      formRules: {
        newWord: {
          required: v => !!v || '금칙어' + this.formRulesSubfix
        },
        chWord: {
          required: v => !!(v  && (v+'').trim()!=='') || '변환어' + this.formRulesSubfix
        },
        useYn: {
          required: v => !!(v  && (v+'').trim()!=='') || '사용여부' + this.formRulesSubfix
        }
      },
    }
  },
  mounted() {
    this.initData()
  },
  methods:{
    async initData(){
      this.$refs.form.resetValidation()
      this.formWordData = new this.mainVo()

      let codeName = ["TWB001"];
      this.common_code= await this.mixin_common_code_get_all(codeName);
    },
    async submit(){

      if(!this.$refs.form.validate()) return false

      let URLData = "/api/chat/setting/banned-word/regist";
      let HEADER_SERVICE = "chat.setting.banned-word";
      let HEADER_METHOD = "regist";
      let HEADER_TYPE = "BIZ_SERVICE";

      let jsonData = {
        ASP_NEWCUST_KEY: this.searchCustKey,
        NEW_PROH_WORD: this.formWordData.NEW_PROH_WORD,
        NEW_CHANGE_WORD: this.formWordData.NEW_CHANGE_WORD,
        USE_YN: this.formWordData.NEW_USE_YN,
        USER_NM: this.$store.getters['userStore/GE_USER_NAME'],
        USER_ID: this.$store.getters['userStore/GE_USER_ID']
      };

      //api 호출 부분
      await api
          .post(URLData, jsonData, {
            head: {
              SERVICE: HEADER_SERVICE,
              METHOD: HEADER_METHOD,
              TYPE: HEADER_TYPE
            },
          })
          .then((response) => {
            if(!this.isEmpty(response) && !response.data.HEADER.ERROR_FLAG) {
              this.common_alert('정상처리 되었습니다.', 'done')
              this.$emit('hideAndGetList');
            } else {
              this.common_alert(response.data.HEADER.ERROR_MSG, 'error')
            }
          })
          .catch((err) => {
            alert(err);
          });
    },
    isEmpty(val) {
      if (val == null) return true
      if (typeof val === 'undefined') return true
      if (typeof val === 'string' && val === '' && (''+val) === '') return true
      if (Array.isArray(val) && val.length < 1) return true
      if (typeof val === 'object' && val.constructor.name === 'Object' && Object.keys(val).length < 1 && Object.getOwnPropertyNames(val) < 1) return true
      if (typeof val === 'object' && val.constructor.name === 'String' && Object.keys(val).length < 1) return true
    },
  }
};
</script>

<style></style>
