<template>
  <v-list-item :key="item.title">
    <v-list-item-content>
      <v-list-item-title
        v-text="item.title"
        class="text--primary subtitle-1 font-weight-bold"
      ></v-list-item-title>
            <v-list-item-subtitle
        v-html="item.text"
        color="red"
        class="black--text body-1 font-weight-normal pt-1 pl-5"
      ></v-list-item-subtitle>
      <!-- <code
        v-text="item.text"
        class="black--text body-1 font-weight-normal mt-1"
      ></code> -->
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default { 
        name: "HelpDiscription",  
  components: {
    //   HelloWorld, 
  },
  props: {
    item: {
      type: Object,
            default: () => ({}),
    }, 
  },
};
</script>
  
<style scoped>
.subtitle-1 {
  color: red !important;
}
</style> 