<template>
  <div class="modal-wrap">
    <h1 class="tit-h1 d-flex">
      {{ headerTitle }}
     <div class="ml-auto align-self-center">
        <v-icon x-small class="svg-close" @click="$emit('hide')"></v-icon>
      </div>
    </h1>
    <div class="modal-box">
      <div class="box-ct">
        <h2 class="tit-h2 d-flex">
          신규/변경 이력관리
          <div class="ml-auto">
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSave')" outlined small class="btn-point" @click="common_confirm('저장하시겠습니까?',save,'',null,'','chk')">저장</v-btn>
          </div>
        </h2>
        <div class="table-form mt-3">
          <table>
            <caption class="hide">
              신규/변경 이력
            </caption>
            <colgroup>
              <col width="100px"/>
              <col width=""/>
              <col width="100px"/>
              <col width=""/>
              <col width="100px"/>
              <col width=""/>
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">
                 <v-badge dot color="#EF9191">버전</v-badge>
                </th>
                <td>
                  <div class="form-inp sm">
                    <v-text-field
                        v-model="registParams.VER"
                        outlined
                        hide-details
                        class="form-inp sm"
                        readonly
                    >
                    </v-text-field>
                  </div>
                </td>
                <th scope="row">
                  <v-badge dot color="#EF9191">최초개시일자</v-badge>
                </th>
                <td>
                  <v-menu
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    content-class="calendar-modal"
                    dark
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="registParams.FST_STRT_DT"
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        hide-details
                        class="form-inp sm icon-calendar"
                        append-icon="svg-calendar"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="registParams.FST_STRT_DT"
                      color="#F7AFAE"
                      no-title
                      @input="menu1 = false"
                      :first-day-of-week="1"
                      locale="ko-KR"
                      :day-format="mixin_getDate"
                    ></v-date-picker>
                  </v-menu>
                </td>
                <th scope="row">이력저장여부</th>
                <td>
                  <div class="">
                    <v-checkbox
                    :on-icon="'svg-chk-on'"
                    :off-icon="'svg-chk-off'"
                    label=""
                    true-value="Y"
                    false-value="N"
                    v-model="registParams.SAVE_HIST_YN"
                    ></v-checkbox>
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <v-badge dot color="#EF9191">수정사항</v-badge>
                </th>
                <td colspan="5">
                  <v-textarea
                    label=""
                    max="500"
                    no-resize
                    outlined
                    v-model="registParams.CHG_HIST_CONTN"
                  ></v-textarea>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mixin} from '@/mixin/mixin.js'

export default {
  name: "MENU_M510201P08", //name은 'MENU_' + 파일명 조합
  props: {
    headerTitle: {
      type: String,
      default: "제목",
    },
    STATE : "",
    PROC_CASE : "",
    CNTNT_ID : "",
    CNTNT_SEQ : "",
    KEY_VER_SEQ : "",
  },
  mixins : [mixin],
  data: () => ({
    menu1 : false,
    VER_LIST : [],
    registParams : {
      SAVE_HIST_YN : "Y",
      VER : "1.0",
      CHG_HIST_CONTN : "",
      FST_STRT_DT : new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      CNTNT_SEQ : "",
      KEY_VER_SEQ : "",
    },
  }),
  async mounted(){
    await this.getContentVerData();
  },
  created(){

  },
  methods: {
    async selectRtn(){
      let requestData = {
        headers: {},
        sendData:{}
      };
      // header 세팅
      requestData.headers["URL"] = "api/km/template/manage/page/list-pop";
      requestData.headers["SERVICE"] = "km.template.manage.page";
      requestData.headers["METHOD"] = "list-pop";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = false;
      requestData.headers["ROW_CNT"] = this.itemsPerPage;
      requestData.headers["PAGES_CNT"] = this.page;

      // sendData 세팅
      requestData.sendData = this.gridSearch;
      requestData.sendData['ASP_NEWCUST_KEY'] = this.ASP_NEWCUST_KEY

      // 결과 리턴
      let response = await this.common_postCall(requestData);

      if(!response.HEADER.ERROR_FLAG){
        this.gridData = response.DATA;
        this.totalCount = parseInt(response.DATA[0].TWB_PAGING_TOT_COUNT);
      }else{
        this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.","error");
      }
    },
    async getContentVerData(){
      let requestData = {
        headers: {},
        sendData:{}
      };
      // header 세팅
      requestData.headers["URL"] = "api/km/content/chghst-manage/ver/list";
      requestData.headers["SERVICE"] = "km.content.chghst-manage.ver";
      requestData.headers["METHOD"] = "list";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = false;

      let cntnt_id = this.CNTNT_ID;
      if(cntnt_id == undefined || cntnt_id == ""){
        cntnt_id = 0;
      }
      // sendData 세팅
      requestData.sendData['CNTNT_ID'] = cntnt_id;

      // 결과 리턴
      let response = await this.common_postCall(requestData);

      if(!response.HEADER.ERROR_FLAG){
        this.registParams.VER = response.VER[0].CD;
      }
    },
    startDate(e){
      this.registParams.FST_STRT_DT = e;
    },
    async save(){
      if(this.registParams.VER == ""){
        this.common_alert("버전을 선택해주세요.", "noti");
        return
      }

      if(this.registParams.FST_STRT_DT == ""){
        this.common_alert("최초개시일자를 선택해주세요.", "noti");
        return;
      }

      if(this.registParams.CHG_HIST_CONTN == ""){
        this.common_alert("수정사항을 입력해주세요.", "noti");
        return;
      }

      if(this.STATE == "NEW" || this.STATE == "TEMP" || this.STATE == "MODIFY"){
        this.registParams.CNTNT_SEQ = this.mixin_rpad((Number(this.CNTNT_SEQ)).toString(), 5, "0");
        this.registParams.KEY_VER_SEQ = this.getBizKeyVerSeq(this.CNTNT_ID, this.registParams.VER, this.mixin_rpad((Number(this.CNTNT_SEQ)).toString(), 5, "0"));

      }

      if(this.registParams.SAVE_HIST_YN == "Y"){
        if(this.STATE == "MODIFY"){
          this.registParams.CNTNT_SEQ = this.mixin_rpad((Number(this.CNTNT_SEQ) + 1).toString(), 5, "0");
          this.registParams.KEY_VER_SEQ = this.getBizKeyVerSeq(this.CNTNT_ID, this.registParams.VER, this.mixin_rpad((Number(this.CNTNT_SEQ) + 1).toString(), 5, "0"));

          if(!await this.validationDupleKeyData()){
            return;
          }
        }
      }

      this.$emit('submit', this.registParams);
    },

    getBizKeyVerSeq(strID, strVer, strSeq){
      let strKeyValue = "";
      if(typeof(strVer) === "undefined"){strVer = "v0100";}
      if(typeof(strSeq) === "undefined"){strSeq = "00001";}
      if(strVer === "v0100" && strSeq === "00001"){
        strKeyValue = strID + "_"+ strVer +"_"+ strSeq;
      } else {
        let strNewVer = this.mixin_rpad(Math.floor(strVer).toString(),2,"0") + this.mixin_rpad(((parseFloat(strVer)*10)%10).toString(),2,"0");
        strKeyValue = strID + "_v"+ strNewVer +"_"+ strSeq;
      }
      return strKeyValue ;
    },

    async validationDupleKeyData(){
      let blnRetVal = true;

      let requestData = {
        headers: {},
        sendData:{}
      };
      // header 세팅
      requestData.headers["URL"] = "api/km/content/chghst-manage/hist/inqire";
      requestData.headers["SERVICE"] = "km.content.chghst-manage.hist";
      requestData.headers["METHOD"] = "inqire";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = false;

      // sendData 세팅
      requestData.sendData['CNTNT_ID'] = this.CNTNT_ID;
      requestData.sendData['SEQ'] = this.registParams.CNTNT_SEQ;
      requestData.sendData['KEY_VER_SEQ'] = this.registParams.KEY_VER_SEQ;

      // 결과 리턴
      let response = await this.common_postCall(requestData);

       if(!response.HEADER.ERROR_FLAG){
         if(response.DATA.length > 0){
           this.common_alert("중복된 데이터가 존재합니다.", "noti");
           blnRetVal = false;
         }
       }else{
         this.common_alert(response.HEADER.ERROR_MSG, "noti");
         blnRetVal = false;
       }

      return blnRetVal;
    },
  },

  computed: {

  },
};
</script>

<style></style>
