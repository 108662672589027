<template>
  <div class="modal-wrap">
    <h1 class="tit-h1 d-flex">
      {{ param.headerTitle }}
      <div class="ml-auto align-self-center">
        <v-icon x-small class="svg-close20" @click="$emit('hide')"></v-icon>
      </div>
    </h1>
    <div class="modal-box">
      <div>
        <v-form ref="form"
              v-model="valid"
              lazy-validation
      >
        <div class="table-form">
          <table>
            <caption class="hide">
              정보 항목
            </caption>
            <colgroup>
              <col width="127px" />
              <col width="150px" />
              <col width="127px" />
              <col width="" />
            </colgroup>
            <tbody>
            <tr>
              <th scope="row">
                <v-badge dot color="#EF9191">메시지제목</v-badge>
              </th>
              <td colspan="3">
                <v-text-field
                    class="form-inp full"
                    name="TEXT_MSG_DESC"
                    outlined
                    maxlength="20"
                    required
                    v-model="MSG_DESC"
                    :rules="validateRules.msgDesc"
                >
                </v-text-field>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <v-badge dot color="#EF9191">메시지</v-badge>
              </th>
              <td colspan="3">
                <v-textarea
                    class="textarea-type0"
                    name="TEXT_MSG_CONTENT"
                    outlined
                    no-resize
                    v-model="MSG_CONTENT"
                    :rules="validateRules.msgContent"
                >
                </v-textarea>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <v-badge dot color="#EF9191">사용여부</v-badge>
              </th>
              <td>
                <div class="form-inp full">
                  <v-select
                      :items="dropYnItems"
                      name="TEXT_USE_YN"
                      outlined
                      hide-details
                      placeholder="선택하세요"
                      required
                      v-model="USE_YN"
                      :rules="validateRules.useYn"
                  >
                  </v-select>
                </div>

              </td>
              <th scope="row">
                <v-badge dot color="#EF9191">수정자</v-badge>
              </th>
              <td>
                <v-text-field
                    class="form-inp full"
                    name="TEXT_UPD_ID"
                    outlined
                    v-model="REGR_ID"
                    maxlength="20"
                    required
                    disabled
                >
                </v-text-field>
              </td>
            </tr>
            <tr>
              <th scope="row">
                메시지시간
              </th>
              <td>
                <v-text-field
                    class="form-inp full"
                    name="TEXT_MSG_TIME"
                    type="number"
                    id="sortOrd"
                    min="0"
                    max="9999"
                    required
                    v-model="MSG_TIME"
                    :rules="validateRules.MSG_TIME"
                ></v-text-field>
              </td>
              <th scope="row"></th>
              <td>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <!-- 웹링크 -->
        <div class="mt-3" v-if="showWeblinkType">
          <h4 class="tit-h2">웹링크</h4>
          <div class="mt-3 d-flex">
            <div class="col-6 pa-0">
              <v-text-field
                  class="form-inp full"
                  name="name" label="" id="id" outlined
                  hide-details
                  disabled
                  value="1"
              >
                <template v-slot:label>
                  순번
                  <v-icon color="#EF9191">mdi-circle-small</v-icon>
                </template>
              </v-text-field>
            </div>
            <div class="col-6 pa-0 pl-2">
              <v-text-field
                  class="form-inp full"
                  name="name" label="" id="id" outlined
                  hide-details
                  v-model="SORT_ORD"
                  :rules="validateRules.SORT_ORD"
              >
                <template v-slot:label>
                  정렬순서
                  <v-icon color="#EF9191">mdi-circle-small</v-icon>
                </template>
              </v-text-field>
            </div>
          </div>
          <div class="mt-3 d-flex">
            <div class="col-6 pa-0">
              <v-text-field
                  class="form-inp full"
                  name="name" label="" id="id" outlined
                  hide-details
                  v-model="BTN_NM"
                  :rules="validateRules.BTN_NM"
              >
                <template v-slot:label>
                  버튼명
                  <v-icon color="#EF9191">mdi-circle-small</v-icon>
                </template>
              </v-text-field>
            </div>
            <div class="col-6 pa-0 pl-2">
              <div class="form-inp full">
                <v-select
                    :items="dropYnItems"
                    outlined
                    placeholder="선택하세요"
                    v-model="LINK_USE_YN"
                    hide-details
                    :rules="validateRules.useYn"
                >
                  <template v-slot:label>
                    사용여부
                    <v-icon color="#EF9191">mdi-circle-small</v-icon>
                  </template>
                </v-select>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <v-text-field
                class="form-inp full"
                name="name" label="MOBILE URL" id="id" outlined
                hide-details
                v-model="URL_MOBILE"
                :rules="validateRules.URL_MOBILE"
            >
              <template v-slot:label>
                MOBILE URL
                <v-icon color="#EF9191">mdi-circle-small</v-icon>
              </template>
            </v-text-field>
          </div>
          <div class="mt-3">
            <v-text-field
                class="form-inp full"
                name="name" label="PC URL" id="id" outlined
                hide-details
                v-model="URL_PC"
                :rules="validateRules.URL_PC"
            >
              <template v-slot:label>
                PC URL
                <v-icon color="#EF9191">mdi-circle-small</v-icon>
              </template>
            </v-text-field>
          </div>


        </div>
        <!-- 키워드 -->
        <div class="mt-3"  v-if="showKeywordType">
          <h4 class="tit-h2">키워드</h4>
          <div class="mt-3 d-flex">
            <div class="col-6 pa-0">
              <v-text-field
                  class="form-inp full"
                  name="name" label="" id="id" outlined
                  hide-details
                  disabled
                  value="1"
              >
                <template v-slot:label>
                  순번
                  <v-icon color="#EF9191">mdi-circle-small</v-icon>
                </template>
              </v-text-field>
            </div>
            <div class="col-6 pa-0 pl-2">
              <v-text-field
                  class="form-inp full"
                  name="name" label="" id="id" outlined
                  hide-details
                  v-model="SORT_ORD"
                  :rules="validateRules.SORT_ORD"
              >
                <template v-slot:label>
                  정렬순서
                  <v-icon color="#EF9191">mdi-circle-small</v-icon>
                </template>
              </v-text-field>
            </div>
          </div>
          <div class="mt-3 d-flex">
            <div class="col-6 pa-0">
              <v-text-field
                  class="form-inp full"
                  name="name" label="" id="id" outlined
                  hide-details
                  v-model="BTN_NM"
                  :rules="validateRules.BTN_NM"
              >
                <template v-slot:label>
                  버튼명
                  <v-icon color="#EF9191">mdi-circle-small</v-icon>
                </template>
              </v-text-field>
            </div>
            <div class="col-6 pa-2 pl-2">
              <v-select
                  :items="dropYnItems"
                  outlined
                  placeholder="선택하세요"
                  v-model="LINK_USE_YN"
                  hide-details
                  :rules="validateRules.useYn"

              >
                <template v-slot:label>
                  사용여부
                  <v-icon color="#EF9191">mdi-circle-small</v-icon>
                </template>
              </v-select>
            </div>
          </div>
        </div>
      </v-form>
      </div>
      <div class="text-right mt-3">
        <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSave')" outlined class="btn-point" @click="submitGrid01Pop"  >저장</v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import api from "../../store/apiUtil";
import {mixin} from "../../mixin/mixin";

export default {
  name: "MENU_M810610P01", //name은 'MENU_' + 파일명 조합
  props: {
    param: {
      type: Object,
    },
  },
  mixins:[mixin],
  data(){
    return {
      REGR_ID : this.$store.getters['userStore/GE_USER_NAME'],
      menu1: false,
      menu2: false,
      showLinkType: false,
      showKeywordType: false,
      showWeblinkType: false,
      date: {},
      date2: {},
      footerHideTitle: "닫기",
      dropYnItems: [
        { text: "선택", value:"" },
        { text: "사용", value:"Y" },
        { text: "미사용", value:"N" },
      ],
      is: {
        textMsgDesc: false,//메시지제목
        textMsgContent: false,//메시지내용
        textUseYn: false,
        textRegdName: true,//등록자TEXT_UPD_ID
        textMsgTime: false,
      },
      //valid관련 데이터
      valid        : true,
      validateRules: {
        msgDesc: [
          v => !!v || '메시지명 은(는) 필수입력입니다.',
          // v => /^[a-zA-Z0-9]*$/.test(v)  || '영문, 숫자만 사용 가능합니다.',
        ],
        msgContent       : [
          v => !!v || '시스템메시지 은(는) 필수입력입니다.',
          // v => /^[a-zA-Z0-9]*$/.test(v)  || '영문, 숫자만 사용 가능합니다.',
        ],
        SORT_ORD :[
          v => /[0-9]/.test(v) || '숫자만 입력 가능합니다.',
          v => (v >= 0 && v < 10000 ) || '0 ~ 9999 이내로 입력해 주세요.',
        ],
        BTN_NM :[
          v => !!v || '시스템메시지 은(는) 필수입력입니다.',
        ],
        useYn : [
          v => !!v || '사용여부은(는) 필수입력입니다.',
        ],
        URL_PC:[
          v => !!v || 'URL_PC(는) 필수입력입니다.',
          v => /^(http(s)?:\/\/|www.)([a-z0-9\w]+\.*)+[a-z0-9]{2,4}/.test(v) || '형식이 맞지 않습니다.'
        ],
        URL_MOBILE:[
          v => !!v || 'URL_MOBILE(는) 필수입력입니다.',
          v => /^(http(s)?:\/\/|www.)([a-z0-9\w]+\.*)+[a-z0-9]{2,4}/.test(v) || '형식이 맞지 않습니다.'
        ],
      },
      //v-model
      MSG_DESC : '',
      MSG_CONTENT: '',
      MSG_TIME : 0,
      USE_YN : '',
      LINK_USE_YN : '',
      EXTRA :'',
      BTN_NM : '',
      SORT_ORD : 0,
      URL_MOBILE : '',
      URL_PC: '',
      SYSTEM_MSG_LINKS_ID : '',
    }
  },
  methods:{
    init(){
      let type = this.param.type;
      if(type == "R"){//상세
        this.is = {
          textMsgDesc: false,
          textMsgContent: false,
          textUseYn: false,
          textRegdName: false,
          textMsgTime: false,
          textUpdId:false,
          strDivId:false,
          textUpdName:false,
          textMsgTimeOrg:false,
        };
      }
      this.USE_YN = this.param.data.USE_YN;
      this.MSG_DESC = this.param.data.MSG_DESC;
      this.MSG_CONTENT = this.restoreXSS(this.param.data.MSG_CONTENT);
      this.MSG_TIME = this.param.data.MSG_TIME == '' ? 0 : this.param.data.MSG_TIME;
    },
    validate () {
      return this.$refs.form.validate();
    },
    reset () {
      this.$refs.form.reset();
    },
    resetValidation () {
      this.$refs.form.resetValidation();
    },
    async getGroupCdView(){
      let requestData = {
        headers : {},
        sendData: {}
      };
      requestData.headers["URL"]     = "/api/chat/setting/system-mssage-manage/link-info/inqire";
      requestData.headers["SERVICE"] = "chat.setting.system-mssage-manage.link-info";
      requestData.headers["METHOD"]  = "inqire";
      requestData.headers["ASYNC"]   = "false";
      requestData.headers["TYPE"]    = "BIZ_SERVICE";

      requestData.sendData["SYSTEM_MSG_ID"] = this.param.data.SYSTEM_MSG_ID;
      requestData.sendData["ASP_NEWCUST_KEY"] = this.param.ASP_NEWCUST_KEY;

      let response = await this.common_postCall(requestData);
      this.getGroupCdViewCallBack(response);
    },
    getGroupCdViewCallBack(response){

      let data = response.DATA[0];
      if(this.paramData.LINKS_TYPE == "WL"){
        this.showWeblinkType = true;
        this.SORT_ORD = this.mixin_isEmpty(data.SORT_ORD) ? 0 : data.SORT_ORD;
        this.BTN_NM = data.BTN_NM;
        this.LINK_USE_YN = data.USE_YN;
        this.URL_MOBILE = data.URL_MOBILE;
        this.URL_PC = data.URL_PC;
        this.SYSTEM_MSG_LINKS_ID = data.SYSTEM_MSG_LINKS_ID;
      }else if(this.paramData.LINKS_TYPE == "BK"){
        this.showKeywordType = true;
        this.SORT_ORD = this.mixin_isEmpty(data.SORT_ORD) ? 0 : data.SORT_ORD;
        this.BTN_NM = data.BTN_NM;
        this.LINK_USE_YN = data.USE_YN;
        this.URL_MOBILE = data.URL_MOBILE;
        this.URL_PC = data.URL_PC;
        this.SYSTEM_MSG_LINKS_ID = data.SYSTEM_MSG_LINKS_ID;
      }

    },
    submitGrid01Pop(){
      if(this.paramData.LINKS_TYPE == "WL" || this.paramData.LINKS_TYPE == "BK"){
        this.submitLi();
      }else{
        this.submitTx();
      }
    },
    submitTx(){
      //validation체크
      if (!this.validate()) {
        return;
      }
      let requestData = {
        headers: {
          SERVICE: this.initHeaders.SERVICE,
          METHOD: this.initHeaders.METHOD,
          TYPE: this.initHeaders.TYPE,
        },
        sendData:{}
      };
      // header 세팅
      requestData.headers["URL"] = "/api/chat/setting/system-mssage-manage/text/modify";
      requestData.headers["METHOD"] = "modify";
      requestData.headers["ASYNC"] = false;
      requestData.sendData = {};
      for(let prop in this.paramData){
        requestData.sendData[prop] = this.paramData[prop];
      }
      // sendData 세팅
      requestData.sendData["TEXT_MSG_DESC"] = this.MSG_DESC;//제목
      requestData.sendData["TEXT_MSG_CONTENT"] = this.restoreXSS_Dec(this.MSG_CONTENT);//내용
      requestData.sendData["TEXT_UPD_ID"] = this.$store.getters['userStore/GE_USER_ROLE'].userId;//작성자
      requestData.sendData["TEXT_UPD_DEPT_CODE"] ="x";
      requestData.sendData["strDivID"] = "systemMsg";
      requestData.sendData["TEXT_UPD_NAME"] = this.$store.getters['userStore/GE_USER_ROLE'].userName;
      requestData.sendData["TEXT_USE_YN"] = this.USE_YN;
      requestData.sendData["TEXT_MSG_TIME"] = this.TEXT_MSG_TIME;
      requestData.sendData["TEXT_MSG_TIME_ORG"] = this.param.data.MSG_TIME;
      requestData.sendData["ASP_NEWCUST_KEY"] = this.paramData.ASP_NEWCUST_KEY;
      requestData.sendData["TEXT_SYSTEM_MSG_ID"] = this.param.data.SYSTEM_MSG_ID;
      this.result(requestData);
    },
    submitCallBack(response){
      let header = response.HEADER;
      let data = response.DATA;
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: "정상처리 되었습니다.",
        iconClass: "svg-done-lg",
        type: "default",
      });
      this.$emit('hideAndGetList');
      return;
    },

    async submitLi(){
      //validation체크
      if (!this.validate()) {
        return;
      }
      let requestData = {
        headers : {},
        sendData: {}
      };
      let tableArray = [];
      let tableObj = {};
      if(this.paramData.LINKS_TYPE == "BK"){
        tableObj.ASP_NEWCUST_KEY = this.param.ASP_NEWCUST_KEY;
        tableObj.BTN_NM = this.BTN_NM;
        tableObj.SORT_ORD = this.SORT_ORD;
        tableObj.USE_YN = this.LINK_USE_YN;
        tableObj.SYSTEM_MSG_LINKS_ID = this.SYSTEM_MSG_LINKS_ID == '' ? await this.mixin_common_bizSeq_get("LNK") : this.SYSTEM_MSG_LINKS_ID;
        tableArray.push(tableObj);
      }else if(this.paramData.LINKS_TYPE == "WL"){
        tableObj.ASP_NEWCUST_KEY = this.param.ASP_NEWCUST_KEY;
        tableObj.BTN_NM = this.BTN_NM;
        tableObj.SORT_ORD = this.SORT_ORD;
        tableObj.URL_MOBILE = this.URL_MOBILE;
        tableObj.URL_PC = this.URL_PC;
        tableObj.USE_YN = this.LINK_USE_YN;
        tableObj.SYSTEM_MSG_LINKS_ID = this.SYSTEM_MSG_LINKS_ID == '' ? await this.mixin_common_bizSeq_get("LNK") : this.SYSTEM_MSG_LINKS_ID;
        tableArray.push(tableObj);
      }
      requestData.headers["URL"]     = "/api/chat/setting/system-mssage-manage/link/modify";
      requestData.headers["SERVICE"] = "chat.setting.system-mssage-manage.link";
      requestData.headers["METHOD"]  = "modify";
      requestData.headers["ASYNC"]   = "false";
      requestData.headers["TYPE"]    = "BIZ_SERVICE";
      requestData.headers["DATA_OBJECT"] = "LINKDATA";

      requestData.sendData["SYSTEM_MSG_ID"] = this.param.data.SYSTEM_MSG_ID;
      requestData.sendData["ASP_NEWCUST_KEY"] = this.param.ASP_NEWCUST_KEY;
      requestData.sendData["SYSTEM_MSG_COUNT"] = "0";
      requestData.sendData["LINK_MSG_DESC"] = this.MSG_DESC;
      requestData.sendData["LINK_MSG_CONTENT"] = this.restoreXSS_Dec(this.MSG_CONTENT);
      requestData.sendData["LINK_USE_YN"] = this.USE_YN;
      requestData.sendData["LINK_MSG_TIME"] = this.MSG_TIME;
      requestData.sendData["LINK_TYPE"] = this.paramData.LINKS_TYPE;
      requestData.sendData["LINKDATA"] = tableArray;
      requestData.sendData["LINK_UPD_ID"] = this.$store.getters['userStore/GE_USER_ROLE'].userId;//작성자
      requestData.sendData["LINK_UPD_DEPT_CODE"] ="x";
      requestData.sendData["LINK_UPD_NAME"] = this.$store.getters['userStore/GE_USER_ROLE'].userName;

      let response = await this.common_postCall(requestData);
      this.submitLiCallBack(response);
    },
    submitLiCallBack(response){
      if(!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG) {
        this.common_alert(`정상적으로 수정되었습니다.`, 'done');
        this.$emit("hideAndGetList");
      }else {
        this.common_alert(`시스템에 오류가 발생하였습니다.`, 'error')
      }
    },
    closeAlert(){//
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
    },
    async result(requestData){
      let head='';
      let dataa='';
      //결과값을 보여주는 부분 -> 실제 사용 시 dataa의 value를 사용
      //api 호출 부분
      await api.post(requestData.headers.URL,   //api url입력
          //await axios.post('https://local.hkpalette.com:8443/'+this.URLData,{
          requestData.sendData
          , //api에서 사용될 data 입력
          {head: requestData.headers
          }) //api에 로그를 남기기위한 값입력 (필수) (SERVICE, METHOD, TYPE)
          .then((response) => {
            if(typeof(response.data) === "string"){
              let responseData = JSON.parse(response.data.replaceAll("\n", ""));
              // string으로 넘어올 경우 에러임.
              this.$store.commit("alertStore/openAlertDialog", {
                alertDialogToggle: true,
                msg: responseData.HEADER.ERROR_MSG,
                iconClass: "svg-error-lg",
                type: "default",
              });
              return;
            }
            if(response.data.HEADER.METHOD === "modify"){
              this.submitCallBack(response.data);
            }
          }) //api 호출 성공 이후 수행
          .catch((err) => {
            alert(err);
          }); //api호출 에러 작업 수행
    },
  },
  mounted(){
    this.init();
    this.getGroupCdView();
  },
  computed: {
    initHeaders(){
      return {
        SERVICE : 'chat.setting.system-mssage-manage.text',
        METHOD : "",
        TYPE : 'BIZ_SERVICE',
      };
    },
    paramData(){
      return {
        ASP_NEWCUST_KEY : this.param.ASP_NEWCUST_KEY,
        TEXT_MSG_CONTENT: this.restoreXSS(this.param.data.MSG_CONTENT),
        TEXT_MSG_DESC: this.param.data.MSG_DESC,
        TEXT_MSG_TIME: this.param.data.MSG_TIME,
        TEXT_MSG_TIME_ORG: this.param.data.MSG_TIME,
        TEXT_SYSTEM_MSG_ID: this.param.data.SYSTEM_MSG_ID,
        TEXT_UPD_DEPT_CODE: "x",
        TEXT_UPD_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        TEXT_UPD_NAME: this.$store.getters['userStore/GE_USER_ROLE'].userName,
        TEXT_USE_YN: this.param.data.USE_YN,
        strDivID: "systemMsg",
        LINKS_TYPE : this.param.data.LINKS_TYPE,
      };
    }
  },
};
</script>
<style></style>
