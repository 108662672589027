<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">날짜별상담통계</h1>
    <div class="box-wrap">
      <div class="box-ct d-flex">
        <div>
          <div>
            <div class="form-inp sm">
              <v-select
                :items="ASP_CUST_KEY"
                item-value="CD"
                item-text="CD_NM"
                outlined
                hide-details
                label="회사구분"
                placeholder="선택하세요"
                v-model="ASP_NEWCUST_KEY"
                v-on:change="chnASP"
                :readonly="RDOnly"
              >
                <template v-slot:label>
                  회사구분
                  <v-icon color="#EF9191">mdi-circle-small</v-icon>
                </template>
              </v-select>
            </div>
            <div class="form-inp sm ml-2">
              <v-select
                :items="SEARCH_TY"
                item-value="CD"
                item-text="NM"
                outlined
                hide-details
                placeholder="선택하세요"
                v-model="SEARCH_NEWTY"
                v-on:change="[chkTime(),chkInqTY()]"
              >
                <template v-slot:label> 조회유형 </template>
              </v-select>
            </div>
            <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
              content-class="calendar-modal"
              color="#FFF"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRangeText"
                  v-bind="attrs"
                  v-on="on"
                  readonly
                  outlined
                  hide-details
                  class="form-inp icon-calendar ml-2"
                  append-icon="svg-calendar"
                  label="조회기간"
                ></v-text-field>
              </template>
              <div class="datepicker-range">
                <v-date-picker
                  v-model="dates[0]"
                  no-title
                  dark
                  color="#F7AFAE"
                  @change="startDate"
                  :first-day-of-week="1"
                  locale="ko-KR"
                  :day-format="mixin_getDate"
                ></v-date-picker>
                <v-date-picker
                  v-model="dates[1]"
                  dark
                  color="#F7AFAE"
                  no-title
                  show-current="false"
                  :min="dates[0]"
                  :max="CANT_DATE"
                  @change="endDate"
                  :first-day-of-week="1"
                  locale="ko-KR"
                  :day-format="mixin_getDate"
                ></v-date-picker>
              </div>
              <div class="text-center pt-3 pb-3">
                <v-btn
                  outlined
                  class="btn-default"
                  @click="menu1 = false"
                  >확인</v-btn
                >
              </div>
            </v-menu>
            <div class="form-inp sm ml-2">
              <v-select
                :items="INQ_TYP_1"
                item-value="CD"
                item-text="CD_NM"
                outlined
                hide-details
                placeholder="선택하세요"
                v-model="INQ_NEWTYP_1"
                ref="SELECT_INQ2"
                v-on:change="chnInq()"
                return-object
              >
                <template v-slot:label> 문의유형 </template>
              </v-select>
            </div>
            <div class="form-inp ml-2">
              <v-select
                :items="INQ_TYP_2"
                item-value="CD"
                item-text="CD_NM"
                outlined
                hide-details
                placeholder="선택하세요"
                v-model="INQ_NEWTYP_2"
                ref="SELECT_INQ1"
                return-object
              >
                <template v-slot:label> 문의유형 </template>
              </v-select>
            </div>
            <div class="form-inp ml-2">
              <v-select
                :items="ASP_SENDER_KEY"
                item-value="CD"
                item-text="NM"
                outlined
                hide-details
                placeholder="선택하세요"
                v-model="ASP_NEWSENDER_KEY"
              >
                <template v-slot:label> 채널 </template>
              </v-select>
            </div>
            <div class="form-inp ml-2">
              <v-checkbox
                :on-icon="'svg-chk-on'"
                :off-icon="'svg-chk-off'"
                label="시간대별"
                v-model="CHK_HOUR"
                v-on:change="check($event)"
              >
              </v-checkbox>
            </div>
          </div>
          <div class="mt-2">
            <div class="form-inp free">
              <v-select
                single-line
                v-model="CHK_NEWDAY"
                :items="CHK_DAY"
                item-value="value"
                item-text="text"
                outlined
                label="요일"
                multiple
                hide-details
                v-on:change="[chgChkDay(),chkNoDay()]"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip small>
                    <span>{{ item.text }}</span>
                    <template>
                      <v-btn
                        icon
                        x-small
                        class="svg-close20 ml-1"
                        v-show="dropItemRemove === true"
                        @click.stop="removeDropDay(item)"
                      ></v-btn>
                    </template>
                  </v-chip>
                </template>
              </v-select>
            </div>
            <div class="form-inp free ml-2">
              <v-select
                single-line
                v-model="CHK_NEWTIME"
                :items="CHK_TIME"
                item-value="value"
                item-text="text"
                outlined
                label="조회시간"
                multiple
                hide-details
                v-on:change="[chgChkTime(),chkNoTime()]"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index < 4" small>
                    <span>{{ item.text }}</span>
                    <template>
                      <v-btn
                        icon
                        x-small
                        class="svg-close20 ml-1"
                        v-show="dropItemRemove === true"
                        @click.stop="removeDropTime(item)"
                      ></v-btn>
                    </template>
                  </v-chip>
                  <span v-if="index === 3" class="grey--text text-caption">
                    ...
                  </span>
                </template>
              </v-select>
            </div>
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnAllCheck')" outlined class="btn-default ml-2" @click="allClick"
              >전체선택</v-btn
            >
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnAllUncheck')" outlined class="btn-default ml-2" @click="[allCloseClick(),chkNoTime()]"
              >전체해제</v-btn
            >
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnWork')" outlined class="btn-default ml-2" @click="selectTime"
              >업무시간선택</v-btn
            >
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnTurn')" outlined class="btn-default ml-2" @click="selectTurn"
              >선택반전</v-btn
            >
          </div>
        </div>
        <div class="ml-auto align-self-center">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')" outlined class="btn-etc2" v-on:click="selectList">
            조회
          </v-btn>
        </div>
      </div>
    </div>
    <div class="box-wrap">
      <h2 class="tit-h2 mt-3">리스트</h2>
      <div class="box-ct">
        <v-data-table
          dense
          height="463px"
          :headers="gridDataHeaders"
          :items="gridDataText"
          :items-per-page="30"
          item-key="index"
          :page.sync="page"
          hide-default-header
          hide-default-footer
          class="grid-default"
          calculate-widths
          @page-count="pageCount = $event"
          @click:row="rowClick"
          single-select
          no-data-text="통계 결과가 없습니다."
          :item-class="isActiveRow"
          fixed-header
        >
          <template v-slot:header>
            <thead class="v-data-table-header">
              <tr>
                <th scope="row" rowspan="2" class="text-center" style="min-width: 30px">NO</th>
                <th scope="row" rowspan="2" class="text-center" style="min-width: 100px">회사명</th>
                <th scope="row" rowspan="2" class="text-center" style="min-width: 100px">조회기간1</th>
                <th scope="row" rowspan="2" class="text-center" style="min-width: 100px">조회기간2</th>
                <th scope="row" rowspan="2" class="text-center" style="min-width: 100px">문의유형</th>
                <th scope="row" rowspan="2" class="text-center" style="min-width: 100px">상담톡인입</th>
                <th scope="row" rowspan="2" class="text-center" style="min-width: 100px">상담요청</th>
                <th scope="row" colspan="4" class="text-center" style="min-width: 400px">고객포기</th>
                <th scope="row" colspan="4" class="text-center" style="min-width: 450px">BUSY</th>
                <th scope="row" colspan="3" class="text-center" style="min-width: 300px">상담진행</th>
                <th scope="row" rowspan="2" class="text-center" style="min-width: 100px">
                  상담응대율[%]
                </th>
                <th scope="row" rowspan="2" class="text-center" style="min-width: 100px">
                  평균배정시간
                </th>
                <th scope="row" colspan="3" class="text-center" style="min-width: 100px">
                  평균상담시간
                </th>
              </tr>
              <tr>
                <th scope="row" class="text-center" style="min-width: 100px">상담요청중포기</th>
                <th scope="row" class="text-center" style="min-width: 100px">상담배정중포기</th>
                <th scope="row" class="text-center" style="min-width: 100px">상담대기중포기</th>
                <th scope="row" class="text-center" style="min-width: 100px">전달대기중포기</th>
                <th scope="row" class="text-center" style="min-width: 100px">상담요청불가</th>
                <th scope="row" class="text-center" style="min-width: 150px">
                  상담배정불가<br>(SYSTEM CLOSED)
                </th>
                <th scope="row" class="text-center" style="min-width: 100px">상담요청미선택</th>
                <th scope="row" class="text-center" style="min-width: 100px">
                  상담사배정불가<br>(TIME OUT)
                </th>
                <th scope="row" class="text-center" style="min-width: 100px">총상담처리</th>
                <th scope="row" class="text-center" style="min-width: 100px">상담</th>
                <th scope="row" class="text-center" style="min-width: 100px">전달</th>
                <th scope="row" class="text-center" style="min-width: 100px">평균상담시간</th>
                <th scope="row" class="text-center" style="min-width: 100px">평균후처리시간</th>
                <th scope="row" class="text-center" style="min-width: 100px">평균처리시간</th>
              </tr>
            </thead>
          </template>
          <template slot="body.append" v-if="gridDataText.length > 0">
            <tr class="grid-default-sum">
              <td class="grid-default-sum--head text-center" colspan="5">
                합계
              </td>
              <td class="text-center">
                {{ mixin_sum_field(this.gridDataText, "NUMBER_INCOMING") }}
              </td>
              <td class="text-center">
                {{
                  mixin_sum_field(this.gridDataText, "NUMBER_CUSTOMER_REQUEST")
                }}
              </td>
              <td class="text-center">
                {{
                  mixin_sum_field(
                    this.gridDataText,
                    "NUMBER_CUSTOMER_GIVE_UP_90"
                  )
                }}
              </td>
              <td class="text-center">
                {{
                  mixin_sum_field(
                    this.gridDataText,
                    "NUMBER_CUSTOMER_GIVE_UP_91"
                  )
                }}
              </td>
              <td class="text-center">
                {{
                  mixin_sum_field(
                    this.gridDataText,
                    "NUMBER_CUSTOMER_GIVE_UP_92"
                  )
                }}
              </td>
              <td class="text-center">
                {{
                  mixin_sum_field(
                    this.gridDataText,
                    "NUMBER_CUSTOMER_GIVE_UP_93"
                  )
                }}
              </td>
              <td class="text-center">
                {{
                  mixin_sum_field(
                    this.gridDataText,
                    "NUMBER_CUSTOMER_GIVE_UP_80"
                  )
                }}
              </td>
              <td class="text-center">
                {{
                  mixin_sum_field(
                    this.gridDataText,
                    "NUMBER_CUSTOMER_GIVE_UP_81"
                  )
                }}
              </td>
              <td class="text-center">
                {{
                  mixin_sum_field(
                    this.gridDataText,
                    "NUMBER_CUSTOMER_GIVE_UP_82"
                  )
                }}
              </td>
              <td class="text-center">
                {{
                  mixin_sum_field(
                    this.gridDataText,
                    "NUMBER_CUSTOMER_GIVE_UP_83"
                  )
                }}
              </td>
              <td class="text-center">
                {{ mixin_sum_field(this.gridDataText, "NUMBER_TOT_PROGRESS") }}
              </td>
              <td class="text-center">
                {{ mixin_sum_field(this.gridDataText, "NUMBER_PROGRESS") }}
              </td>
              <td class="text-center">
                {{ mixin_sum_field(this.gridDataText, "NUMBER_RELAY") }}
              </td>
              <td class="text-center">
                {{
                  mixin_avg_field(
                    this.gridDataText,
                    "NUMBER_TOT_PROGRESS",
                    "NUMBER_CUSTOMER_REQUEST"
                  )
                }}
              </td>
              <td class="text-center">
                {{
                  mixin_date_avg_field(this.gridDataText, "AVG_AWT_ASSIGNMENT")
                }}
              </td>
              <td class="text-center">
                {{ mixin_date_avg_field(this.gridDataText, "AVG_CHATTING") }}
              </td>
              <td class="text-center">
                {{
                  mixin_date_avg_field(this.gridDataText, "AVG_AFTER_TREATMENT")
                }}
              </td>
              <td class="text-center">
                {{
                  mixin_date_avg_field(
                    this.gridDataText,
                    "AVG_CHATTING_AFTER_TREATMENT"
                  )
                }}
              </td>
            </tr>
          </template>
        </v-data-table>
        <div
          class="grid-paging text-center pt-2"
          v-if="gridDataText.length > 0"
        >
          <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="totalVisible"
            next-icon="svg-paging-next"
            prev-icon="svg-paging-prev"
          ></v-pagination>
        </div>
        <p class="caption text-right">
          <strong class="clr-error">{{ gridDataText.length }}</strong
          >건의 통계가 검색되었습니다.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../store/apiUtil.js";
import { mixin } from "@/mixin/mixin.js";
import moment from "moment";

export default {
  name: "MENU_M710204", //name은 'MENU_' + 파일명 조합
  mixins: [mixin],
  components: {},
  data() {
    return {
      menu1: false,
      dropItemRemove: true,
      dates: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      ],
      CANT_DATE: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      gridDataHeaders: [
        {
          text: "No",
          align: "center",
          value: "index",
          width: "50px",
          sortable: false,
        },
        { text: "", value: "COMPANY_NM", align: "center", sortable: false },
        {
          text: "",
          value: "SEARCH_PERIOD_01",
          align: "center",
          sortable: false,
        },
        {
          text: "",
          value: "SEARCH_PERIOD_02",
          align: "center",
          sortable: false,
        },
        { text: "", value: "INQRY_TYP_NM", align: "center", sortable: false },
        {
          text: "",
          value: "NUMBER_INCOMING",
          align: "center",
          sortable: false,
        },
        {
          text: "",
          value: "NUMBER_CUSTOMER_REQUEST",
          align: "center",
          sortable: false,
        },
        {
          text: "",
          value: "NUMBER_CUSTOMER_GIVE_UP_90",
          align: "center",
          sortable: false,
        },
        {
          text: "",
          value: "NUMBER_CUSTOMER_GIVE_UP_91",
          align: "center",
          sortable: false,
        },
        {
          text: "",
          value: "NUMBER_CUSTOMER_GIVE_UP_92",
          align: "center",
          sortable: false,
        },
        {
          text: "",
          value: "NUMBER_CUSTOMER_GIVE_UP_93",
          align: "center",
          sortable: false,
        },
        {
          text: "",
          value: "NUMBER_CUSTOMER_GIVE_UP_80",
          align: "center",
          sortable: false,
        },
        {
          text: "",
          value: "NUMBER_CUSTOMER_GIVE_UP_81",
          align: "center",
          sortable: false,
        },
        {
          text: "",
          value: "NUMBER_CUSTOMER_GIVE_UP_82",
          align: "center",
          sortable: false,
        },
        {
          text: "",
          value: "NUMBER_CUSTOMER_GIVE_UP_83",
          align: "center",
          sortable: false,
        },
        {
          text: "",
          value: "NUMBER_TOT_PROGRESS",
          align: "center",
          sortable: false,
        },
        {
          text: "",
          value: "NUMBER_PROGRESS",
          align: "center",
          sortable: false,
        },
        { text: "", value: "NUMBER_RELAY", align: "center", sortable: false },
        { text: "", value: "RATE_RESPONSE", align: "center", sortable: false },
        {
          text: "",
          value: "AVG_AWT_ASSIGNMENT",
          align: "center",
          sortable: false,
        },
        { text: "", value: "AVG_CHATTING", align: "center", sortable: false },
        {
          text: "",
          value: "AVG_AFTER_TREATMENT",
          align: "center",
          sortable: false,
        },
        {
          text: "",
          value: "AVG_CHATTING_AFTER_TREATMENT",
          align: "center",
          sortable: false,
        },
      ],
      gridDataText: [],
      page: 1,
      pageCount: 0,
      itemsPerPage: 5,
      totalVisible: 10,

      ASP_CUST_KEY: this.$store.getters["userStore/GE_USER_ROLE"].company,

      SEARCH_TY: [
        {
          CD: "TIME",
          NM: "시간",
        },
        {
          CD: "DAY",
          NM: "일",
        },
        {
          CD: "MONTH",
          NM: "월",
        },
        {
          CD: "YEAR",
          NM: "년",
        },
        {
          CD: "DAY_OF_THE_WEEK",
          NM: "요일",
        },
      ],

      ASP_SENDER_KEY: [
        {
          CD: "",
          NM: "전체",
        },
      ],

      INQ_TYP_1: [
        {
          CD: "",
          CD_NM: "전체",
        },
      ],

      INQ_TYP_2: [
        {
          CD: "",
          CD_NM: "전체",
        },
      ],

      CHK_DAY: [
        {
          text: "일",
          value: "1",
        },
        {
          text: "월",
          value: "2",
        },
        {
          text: "화",
          value: "3",
        },
        {
          text: "수",
          value: "4",
        },
        {
          text: "목",
          value: "5",
        },
        {
          text: "금",
          value: "6",
        },
        {
          text: "토",
          value: "7",
        },
      ],

      CHK_TIME: [
        {
          text: "00시",
          selected: true,
          value: "00",
        },
        {
          text: "01시",
          selected: true,
          value: "01",
        },
        {
          text: "02시",
          selected: true,
          value: "02",
        },
        {
          text: "03시",
          selected: true,
          value: "03",
        },
        {
          text: "04시",
          selected: true,
          value: "04",
        },
        {
          text: "05시",
          selected: true,
          value: "05",
        },
        {
          text: "06시",
          selected: true,
          value: "06",
        },
        {
          text: "07시",
          selected: true,
          value: "07",
        },
        {
          text: "08시",
          selected: true,
          value: "08",
        },
        {
          text: "09시",
          selected: true,
          value: "09",
        },
        {
          text: "10시",
          selected: true,
          value: "10",
        },
        {
          text: "11시",
          selected: true,
          value: "11",
        },
        {
          text: "12시",
          selected: true,
          value: "12",
        },
        {
          text: "13시",
          selected: true,
          value: "13",
        },
        {
          text: "14시",
          selected: true,
          value: "14",
        },
        {
          text: "15시",
          selected: true,
          value: "15",
        },
        {
          text: "16시",
          selected: true,
          value: "16",
        },
        {
          text: "17시",
          selected: true,
          value: "17",
        },
        {
          text: "18시",
          selected: true,
          value: "18",
        },
        {
          text: "19시",
          selected: true,
          value: "19",
        },
        {
          text: "20시",
          selected: true,
          value: "20",
        },
        {
          text: "21시",
          selected: true,
          value: "21",
        },
        {
          text: "22시",
          selected: true,
          value: "22",
        },
        {
          text: "23시",
          selected: true,
          value: "23",
        },
      ],

      CHK_HOUR: false,
      ASP_NEWCUST_KEY: "",
      SEARCH_NEWTY: "DAY",
      ASP_NEWSENDER_KEY: "",
      INQ_NEWTYP_1: {
        CD: "",
        NM: "전체",
      },
      INQ_NEWTYP_2: {
        CD: "",
        NM: "전체",
      },

      CHK_NEWDAY: ["1", "2", "3", "4", "5", "6", "7"],
      CHK_NEWTIME: [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
      ],
      
      ROW_DATA:{},
      
      RDOnly:false,
    };
  },

  mounted(){

    let chk = this.mixin_check_AspCustKey_number();
    if(chk == 'Y'){
      this.ASP_NEWCUST_KEY = this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD;
      this.RDOnly = true;
    } else {
      this.RDOnly = false;
    }
  },

  created() {

  },

  methods: {
    startDate(e) {
      this.dates[0] = e;
      if (this.SEARCH_NEWTY == 'TIME'){
        this.cantPick();
      } else {
        this.CANT_DATE = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10);
      }
    },
    endDate(e) {
      this.dates[1] = e;
    },

    removeDropDay(item) {
      this.CHK_NEWDAY.splice((item.value-1), 1);

      let DayArray = this.CHK_NEWDAY;
      this.CHK_NEWDAY = [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ]
      for(let i = 0; i < DayArray.length; i++){
        if(DayArray[i] != "" || DayArray[i] != null){
          let m = parseInt(DayArray[i]);
          this.CHK_NEWDAY[m-1] = DayArray[i];
        }
      }

      this.chkNoDay();
    },
    removeDropTime(item) {
      this.CHK_NEWTIME.splice(parseInt(item.value), 1);

      let TimeArray = this.CHK_NEWTIME;
      this.CHK_NEWTIME = [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ]
      for(let i = 0; i < TimeArray.length; i++){
        if(TimeArray[i] != "" || TimeArray[i] != null){
          let m = parseInt(TimeArray[i]);
          this.CHK_NEWTIME[m] = TimeArray[i];
        }
      }

      this.chkNoTime();
    },

    //회사 구분에 콤보박스 조회
    async chnASP() {
      this.INQ_TYP_1 = [{ CD: "", CD_NM: "전체" }];
      this.INQ_TYP_2 = [{ CD: "", CD_NM: "전체" }];
      this.INQ_NEWTYP_1 = { CD: "", NM: "전체" };
      this.INQ_NEWTYP_2 = { CD: "", NM: "전체" };
      this.ASP_SENDER_KEY = [{ CD: "", NM: "전체"}];
      let sender = await this.mixin_channel_get(this.ASP_NEWCUST_KEY);
      this.ASP_SENDER_KEY = [...this.ASP_SENDER_KEY, ...sender];
      this.getInqTy("1");
    },

    //문의유형1 변경시 문의유형2 조회
    chnInq() {
      this.getInqTy(this.INQ_NEWTYP_1.CD);
    },

    //문의유형 콤보박스 조회
    getInqTy(num) {
      if (num == "1") {
        this.INQ_TYP_1 = [{ CD: "", CD_NM: "전체" }];
        this.INQ_NEWTYP_1 = "";
        this.INQ_TYP_2 = [{ CD: "", CD_NM: "전체" }];
        this.INQ_NEWTYP_2 = "";
        var TYP_CD = "*";
      } else {
        this.INQ_TYP_2 = [{ CD: "", CD_NM: "전체" }];
        this.INQ_NEWTYP_2 = "";
        var TYP_CD = num;
      }
      api
        .post(
          "api/chat/setting/inqry-ty-manage/list", //api url입력
          {
            ASP_NEWCUST_KEY: this.ASP_NEWCUST_KEY,
            USE_YN: "Y",
            SPST_INQRY_TYP_CD: TYP_CD,
            ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_CUST_KEY:
              this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD,
          },
          {
            head: {
              SERVICE: "chat.setting.inqry-ty-manage",
              METHOD: "list",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          console.log(response.data.DATA);
          if (num == "1") {
            this.INQ_TYP_1 = [...this.INQ_TYP_1, ...response.data.DATA];
          } else {
            if (this.INQ_NEWTYP_1.CD == "") {
              this.INQ_TYP_2 = [{ CD: "", CD_NM: "전체" }];
              this.INQ_NEWTYP_2 = "";
            } else {
              this.INQ_TYP_2 = [...this.INQ_TYP_2, ...response.data.DATA];
            }
          }
        })
        .catch((err) => {
          alert(err);
        });
    },

    allClick() {
      this.CHK_NEWTIME = [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
      ];
    },

    allCloseClick() {
      this.CHK_NEWTIME = [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ];
    },

    async selectTime() {
      if (this.ASP_NEWCUST_KEY == "" || this.ASP_NEWCUST_KEY == undefined) {
        this.showAlert("회사를 먼저 선택해주세요.");
        return;
      }
      await api
        .post(
          "api/statistics/chat/common/start-job-time/inqire", //api url입력
          {
            ASP_NEWCUST_KEY: this.ASP_NEWCUST_KEY,
            ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_CUST_KEY:
              this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD,
          },
          {
            head: {
              SERVICE: "statistics.chat.common.start-job-time",
              METHOD: "inqire",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          var ST_TIME = response.data.DATA[0].WORK_START_TIME;

          var ST_HOUR = ST_TIME.substr(0, 2);
          this.ST_JOB_TIME = ST_HOUR;
        })
        .catch((err) => {
          alert(err);
        });

      await api
        .post(
          "api/statistics/chat/common/end-job-time/inqire", //api url입력
          {
            ASP_NEWCUST_KEY: this.ASP_NEWCUST_KEY,
            ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_CUST_KEY:
              this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD,
          },
          {
            head: {
              SERVICE: "statistics.chat.common.end-job-time",
              METHOD: "inqire",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          var END_TIME = response.data.DATA[0].WORK_END_TIME;

          var END_HOUR = END_TIME.substr(0, 2);
          this.END_JOB_TIME = END_HOUR;
        })
        .catch((err) => {
          alert(err);
        });

      this.CHK_NEWTIME = [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ];

      for (
        var i = parseInt(this.ST_JOB_TIME);
        i <= parseInt(this.END_JOB_TIME);
        i++
      ) {
        if (i < 10) {
          let DB_TIME = "0" + i;
          this.CHK_NEWTIME[i] = DB_TIME;
        } else {
          this.CHK_NEWTIME[i] = i + "";
        }
      }
    },

    selectTurn() {
      var NEWTIME = [];

      for (var i = 0; i < 24; i++) {
        NEWTIME[i] = this.CHK_NEWTIME[i];
        if (i < 10) {
          if (NEWTIME[i] != "") {
            NEWTIME[i] = "";
          } else if (NEWTIME[i] == "") {
            let DB_TIME = "0" + i;
            NEWTIME[i] = DB_TIME;
          }
        } else {
          if (NEWTIME[i] != "") {
            NEWTIME[i] = "";
          } else if (NEWTIME[i] == "") {
            NEWTIME[i] = i + "";
          }
        }
      }
      this.CHK_NEWTIME = [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ];
      this.CHK_NEWTIME = NEWTIME;
    },

    selectList() {
      if (this.ASP_NEWCUST_KEY == "" || this.ASP_NEWCUST_KEY == undefined) {
        this.showAlert("회사를 먼저 선택해주세요.");
        return;
      }
      let DT1 = this.dates[0];
      let DT1_Array = DT1.split("-");
      var YEAR1 = DT1_Array[0];
      var MONTH1 = DT1_Array[0] + DT1_Array[1];
      var ST_DATE = DT1_Array[0] + DT1_Array[1] + DT1_Array[2];

      let DT2 = this.dates[1];
      let DT2_Array = DT2.split("-");
      var MONTH2 = DT2_Array[0] + DT2_Array[1];
      var END_DATE = DT2_Array[0] + DT2_Array[1] + DT2_Array[2];

      var INQ_TYP_NM = "";
      var INQ_TYP_NM1 = "";
      var INQ_TYP_NM2 = "";
      if (
        this.INQ_NEWTYP_1.CD_NM == undefined ||
        this.INQ_NEWTYP_1.CD_NM == ""
      ) {
        INQ_TYP_NM1 = "전체";
      } else {
        INQ_TYP_NM1 = this.INQ_NEWTYP_1.CD_NM;
      }
      if (
        this.INQ_NEWTYP_2.CD_NM == undefined ||
        this.INQ_NEWTYP_2.CD_NM == ""
      ) {
        INQ_TYP_NM2 = "전체";
      } else {
        INQ_TYP_NM2 = this.INQ_NEWTYP_2.CD_NM;
      }
      INQ_TYP_NM = INQ_TYP_NM1 + "/" + INQ_TYP_NM2;

      let CHK_NEWHOUR = "";
      if (this.CHK_HOUR == true) {
        CHK_NEWHOUR = "chkHourly";
      }

      console.log("this.CHK_NEWTIME[0]" + this.CHK_NEWTIME[0]);
      console.log("this.CHK_NEWTIME[0]" + this.CHK_NEWTIME[15]);

      console.log("this.CHK_NEWTIME[0]" + this.CHK_NEWTIME[16]);
      api
        .post(
          "api/statistics/chat/counsel-by-date/inqire", //api url입력
          {
            ASP_NEWCUST_KEY: this.ASP_NEWCUST_KEY,
            SEARCH_TYPE: this.SEARCH_NEWTY,
            SEARCH_FROM: ST_DATE,
            SEARCH_TO: END_DATE,
            SEARCH_MONTH_FROM: MONTH1,
            SEARCH_MONTH_TO: MONTH2,
            SEARCH_YEAR: YEAR1,
            CHK_DAY_OF_THE_WEEK_1: this.CHK_NEWDAY[0],
            CHK_DAY_OF_THE_WEEK_2: this.CHK_NEWDAY[1],
            CHK_DAY_OF_THE_WEEK_3: this.CHK_NEWDAY[2],
            CHK_DAY_OF_THE_WEEK_4: this.CHK_NEWDAY[3],
            CHK_DAY_OF_THE_WEEK_5: this.CHK_NEWDAY[4],
            CHK_DAY_OF_THE_WEEK_6: this.CHK_NEWDAY[5],
            CHK_DAY_OF_THE_WEEK_7: this.CHK_NEWDAY[6],
            SEARCH_TIME_0: this.CHK_NEWTIME[0],
            SEARCH_TIME_1: this.CHK_NEWTIME[1],
            SEARCH_TIME_2: this.CHK_NEWTIME[2],
            SEARCH_TIME_3: this.CHK_NEWTIME[3],
            SEARCH_TIME_4: this.CHK_NEWTIME[4],
            SEARCH_TIME_5: this.CHK_NEWTIME[5],
            SEARCH_TIME_6: this.CHK_NEWTIME[6],
            SEARCH_TIME_7: this.CHK_NEWTIME[7],
            SEARCH_TIME_8: this.CHK_NEWTIME[8],
            SEARCH_TIME_9: this.CHK_NEWTIME[9],
            SEARCH_TIME_10: this.CHK_NEWTIME[10],
            SEARCH_TIME_11: this.CHK_NEWTIME[11],
            SEARCH_TIME_12: this.CHK_NEWTIME[12],
            SEARCH_TIME_13: this.CHK_NEWTIME[13],
            SEARCH_TIME_14: this.CHK_NEWTIME[14],
            SEARCH_TIME_15: this.CHK_NEWTIME[15],
            SEARCH_TIME_16: this.CHK_NEWTIME[16],
            SEARCH_TIME_17: this.CHK_NEWTIME[17],
            SEARCH_TIME_18: this.CHK_NEWTIME[18],
            SEARCH_TIME_19: this.CHK_NEWTIME[19],
            SEARCH_TIME_20: this.CHK_NEWTIME[20],
            SEARCH_TIME_21: this.CHK_NEWTIME[21],
            SEARCH_TIME_22: this.CHK_NEWTIME[22],
            SEARCH_TIME_23: this.CHK_NEWTIME[23],
            INQRY_TYP_CD_1LEVEL: this.INQ_NEWTYP_1.CD,
            INQRY_TYP_CD_2LEVEL: this.INQ_NEWTYP_2.CD,
            ASP_SENDER_KEY: this.ASP_NEWSENDER_KEY,
            INQRY_TYP_NM: INQ_TYP_NM,
            HOURLY: CHK_NEWHOUR,
            ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_CUST_KEY:
              this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD,
          },
          {
            head: {
              SERVICE: "statistics.chat.counsel-by-date",
              METHOD: "inqire",
              TYPE: "BIZ_SERVICE",
              ROW_CNT: "200",
              PAGES_CNT: "1",
            },
          }
        )
        .then((response) => {
          console.log(response.data.DATA);
          this.gridDataText = response.data.DATA;
          for (var i = 0; i < this.gridDataText.length; i++) {
            this.gridDataText[i]["index"] = i + 1;
          }
          this.page = 1;
        })
        .catch((err) => {
          alert(err);
        });
    },

    //Table row 클릭이벤트
    rowClick(item, row) {
      this.ROW_DATA = item;
      console.log("item",item);
    },

    isActiveRow(item){
      const activeClass = (item.SEARCH_PERIOD_01 === this.ROW_DATA.SEARCH_PERIOD_01 ? (item.SEARCH_PERIOD_02 === this.ROW_DATA.SEARCH_PERIOD_02 ? 'active' : '') : '')
      return activeClass;
    },

    showAlert(msg) {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: msg,
        iconClass: "svg-error-lg",
        type: "default",
      });
    },

    chkTime() {
      if (this.SEARCH_NEWTY == "TIME") {
        this.CHK_HOUR = true;

        this.cantPick()
      } else {
        this.CHK_HOUR = false;

        this.CANT_DATE = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10);
      }
    },

    check(e) {
      if (this.CHK_HOUR == true) {
        this.SEARCH_NEWTY = "TIME";
      }
    },

    cantPick(){

      this.CANT_DATE = moment(this.dates[0]).add(1, "month").format("YYYY-MM-DD");

      if ( this.dates[1] > this.CANT_DATE ){
        this.showAlert("조회 유형이 [시간]일 때 날짜는 30일을 넘을 수 없습니다 <br> 자동으로 조회 기간이 변경됩니다");

        this.dates[1] = this.CANT_DATE;

        let start_date = this.dates[0];
        let newArray = [];
        newArray.push(start_date);
        newArray.push(this.CANT_DATE);
        this.dates = newArray;
      }
    },

    chgChkTime(){
      let TimeArray = this.CHK_NEWTIME;
      this.CHK_NEWTIME = [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ]
      for(let i = 0; i < TimeArray.length; i++){
        if(TimeArray[i] != "" || TimeArray[i] != null){
          let m = parseInt(TimeArray[i]);
          this.CHK_NEWTIME[m] = TimeArray[i];
        }
      }
     this.CHK_NEWTIME.splice(24,1);
    },

    chgChkDay(){
      let DayArray = this.CHK_NEWDAY;
      this.CHK_NEWDAY = [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ]
      for(let i = 0; i < DayArray.length; i++){
        if(DayArray[i] != "" || DayArray[i] != null){
          let m = parseInt(DayArray[i])-1;
          this.CHK_NEWDAY[m] = DayArray[i];
        }
      }
     this.CHK_NEWDAY.splice(7,1);
    },

    chkInqTY(){
      if (this.SEARCH_NEWTY == "TIME"){
        let TIME_CNT = 0;
        for (let i = 0; i<this.CHK_NEWTIME.length;i++){
          if (this.CHK_NEWTIME[i] != ""){
            TIME_CNT = TIME_CNT + 1;
          }
        }
        if (TIME_CNT == 0){
          this.showAlert("조회 유형이 [시간]일 때<br>시간 조회조건이 하나 이상 필요합니다.<br><br>자동으로 [09시]를 선택합니다.");
          this.CHK_NEWTIME = [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "09",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
          ]
        }
      } else if (this.SEARCH_NEWTY == "DAY_OF_THE_WEEK"){
        let DAY_CNT = 0;
        for (let i = 0; i<this.CHK_NEWDAY.length;i++){
          if (this.CHK_NEWDAY[i] != ""){
            DAY_CNT = DAY_CNT + 1;
          }
        }
        if (DAY_CNT == 0){
          this.showAlert("조회 유형이 [요일]일 때<br>요일 조회조건이 하나 이상 필요합니다.<br><br>자동으로 [월요일]를 선택합니다.");
          this.CHK_NEWDAY = [
            "",
            "2",
            "",
            "",
            "",
            "",
            "",
          ]
        }
      }
    },

    chkNoDay(){
      let DAY_CNT = 0;
      for (let i = 0; i<this.CHK_NEWDAY.length;i++){
        if (this.CHK_NEWDAY[i] != ""){
          DAY_CNT = DAY_CNT + 1;
        }
      }
      if (DAY_CNT == 0){
        if (this.SEARCH_NEWTY == "DAY_OF_THE_WEEK"){
          this.showAlert("조회 유형이 [요일]일 때<br>요일 조회조건이 하나 이상 필요합니다.<br><br>자동으로 [월요일]를 선택합니다.");
          this.CHK_NEWDAY = [
            "",
            "2",
            "",
            "",
            "",
            "",
            "",
          ]
        }
      }
    },

    chkNoTime(){
      let TIME_CNT = 0;
      for (let i = 0; i<this.CHK_NEWTIME.length;i++){
        if (this.CHK_NEWTIME[i] != ""){
          TIME_CNT = TIME_CNT + 1;
        }
      }
      if (TIME_CNT == 0){
        if (this.SEARCH_NEWTY == "TIME"){
          this.showAlert("조회 유형이 [시간]일 때<br>시간 조회조건이 하나 이상 필요합니다.<br><br>자동으로 [09시]를 선택합니다.");
          this.CHK_NEWTIME = [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "09",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
          ]
        }
      }
    },
  },

  computed: {
    dateRangeText() {
      var newStartDate = this.dates[0];
      var newEndDate = this.dates[1];
      return newStartDate + " ~ " + newEndDate;
    },
  },
};
</script>

<style></style>
