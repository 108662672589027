<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">QAA설정(담당자)</h1>
    <div class="box-wrap">
      <div class="box-ct d-flex">
        <div>
          <div class="">
            <div class="form-inp sm">
              <v-select
                :items="$store.getters['userStore/GE_USER_COMPANY']"
                item-text="CD_NM"
                item-value="CD"
                outlined
                hide-details
                label="회사구분"
                placeholder="선택하세요"
                v-model="aspCustKey"
              :readonly="RDOnly"
              >
              <template v-slot:label>
                회사구분
                <v-icon color="#EF9191">mdi-circle-small</v-icon>
              </template>
              </v-select>
            </div>
            <div class="form-inp sm ml-2">
              <v-select
                :items="dropGroupItems"
                item-text="NM"
                item-value="CD"
                outlined
                hide-details
                label="권한구분"
                placeholder="선택하세요"
                v-model="USER_ROLE"
              >
              </v-select>
            </div>
            <v-text-field
              class="form-inp ml-2"
              name="name"
              label="사용자명"
              id="id"
              outlined
              hide-details
              v-model="USER_NM"
              @keyup.enter="selectRtnMngrUser"
            >
            </v-text-field>

            <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
              content-class="calendar-modal"
              color="#FFF"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRangeText"
                  v-bind="attrs"
                  v-on="on"
                  readonly
                  outlined
                  hide-details
                  class="form-inp icon-calendar ml-2"
                  append-icon="svg-calendar"
                  label="최근접속일시"
                >
                </v-text-field>
              </template>
              <div class="datepicker-range">
                <v-date-picker
                  v-model="dates[0]"
                  no-title
                  dark
                  color="#F7AFAE"
                  locale="ko-KR"
                  :first-day-of-week="1"
                  :day-format="mixin_getDate"
                  @change="startDate"
                ></v-date-picker>
                <v-date-picker
                  v-model="dates[1]"
                  dark
                  color="#F7AFAE"
                  no-title
                  show-current="false"
                  :min="dates[0]"
                  locale="ko-KR"
                  :first-day-of-week="1"
                  :day-format="mixin_getDate"
                  @change="endDate"
                ></v-date-picker>
              </div>
              <div class="text-center pt-3 pb-3">
                <v-btn outlined class="btn-default" @click="menu1=false">확인</v-btn>
              </div>
            </v-menu>
          </div>
        </div>
        <div class="ml-auto align-self-center">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')" outlined class="btn-etc2" @click="search">조회</v-btn>
        </div>
      </div>
    </div>
    <!-- 그리드 목록 -->
    <div class="box-wrap d-flex">
      <div style="width: 47%">
        <h2 class="tit-h2 d-flex">관리자 목록</h2>
        <div class="box-ct">
          <div>
            <v-data-table
              fixed-header
              dense
              height="549px"
              :headers="gridDataHeaders"
              :items="mngrUser"
              :items-per-page="30"
              item-key="USER_ID"
              :page.sync="mngr.page"
              hide-default-footer
              show-select
              class="grid-default"
              @page-count="mngr.pageCount = $event"
              no-data-text="검색된 결과가 없습니다."
              v-model="selectedMngr"
              @click:row="setGridData"
              :item-class="isActiveRow"
            >
            </v-data-table>
            <div class="grid-paging text-center pt-2">
              <v-pagination
                v-model="mngr.page"
                :length="mngr.pageCount"
                :total-visible="mngr.totalVisible"
                next-icon="svg-paging-next"
                prev-icon="svg-paging-prev"
              ></v-pagination>
            </div>
          </div>
        </div>
      </div>
      <!-- 좌우 화살표 -->
      <div class="btn-arrow-wrap-vertical">
        <div class="">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDelArrow')" class="btn-arrow" plain small @click="moveLeft()">
            <v-icon small class="svg-arrow-left"></v-icon>
          </v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnAddArrow')" class="btn-arrow" plain small @click="moveRight()">
            <v-icon small class="svg-arrow-right"></v-icon>
          </v-btn>
        </div>
      </div>

      <div class="flex-grow-1">
        <h2 class="tit-h2 d-flex">
          QA 담당자 목록
          <div class="ml-auto">
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSave')" outlined class="btn-point" @click="processRtn">저장</v-btn>
          </div>
        </h2>
        <div class="box-ct">
          <div>
            <v-data-table
              fixed-header
              dense
              height="549px"
              :headers="gridDataHeaders"
              :items="raterUser"
              :items-per-page="30"
              item-key="USER_ID"
              :page.sync="rater.page"
              hide-default-footer
              show-select
              class="grid-default"
              @page-count="rater.pageCount = $event"
              no-data-text="검색된 결과가 없습니다."
              v-model="selectedRater"
              @click:row="setGridData2"
              :item-class="isActiveRow2"
            >
            </v-data-table>
            <div class="grid-paging text-center pt-2">
              <v-pagination
                v-model="rater.page"
                :length="rater.pageCount"
                :total-visible="rater.totalVisible"
                next-icon="svg-paging-next"
                prev-icon="svg-paging-prev"
              ></v-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DialogM810401P01 from "./M810401P01.vue";
import api from "@/store/apiUtil.js"
import {mixin} from "@/mixin/mixin.js"

export default {
  name: "MENU_M310102", //name은 'MENU_' + 파일명 조합
  components: {
    DialogM810401P01,
  },
  mixins : [mixin],
  data() {
    return {
      dialogM810401P01: false,
      dialogM810401P02: false,
      dialogM810401P03: false,
      menu1: false,
      menu2: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dates: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      ],
      USER_NM: "",
      aspCustKey: "",
      USER_ROLE : "",
      radioGroup: [
        {
          text: "사용자명",
        },
        {
          text: "최근접속일시",
        },
      ],
      radioGroupSelected: "사용자명",
      dropGroupItems:[],
      gridDataHeaders: [
        {
          text: "회사구분",
          value: "ASP_NEWCUST_KEY_NM",
          align: "center",
          sortable: true,
        },
        { text: "ID", value: "USER_ID", align: "left", sortable: true },
        { text: "성명", value: "USER_NM", align: "left", sortable: true },
        { text: "그룹명", value: "ATRT_GROUP_NM", align: "center", sortable: true },
        { text: "소속A", value: "ATTR_DIV_NM_A", align: "center", sortable: true },
        { text: "소속B", value: "ATTR_DIV_NM_B", align: "center", sortable: true },
        { text: "소속C", value: "ATTR_DIV_NM_C", align: "center", sortable: true },
        { text: "소속D", value: "ATTR_DIV_NM_D", align: "center", sortable: true },
      ],
      page: 1,
      pageCount: 0,
      itemsPerPage: 5,
      totalVisible: 10,
      textareaRules: [(v) => v.length <= 200 || "Max 200 characters"],
      textareaValue: "200자 제한!",
      mngrUser: [],
      raterUser: [],
      mngr:{
        page: 1,
        pageCount: 1,
        itemsPerPage: 10,
        totalVisible: 10,
      },
      rater:{
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
        totalVisible: 10,
      },
      selectedMngr:[],
      selectedRater:[],
      selectedGridData:{},
      selectedGridData2:{},
      
      RDOnly:false,
    };
  },
  created() {
  },
  async mounted() {
    this.dropGroupItems = await this.mixin_user_auth_get("전체");

    let chk = this.mixin_check_AspCustKey_number();
    if(chk == 'Y'){
      this.aspCustKey = this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD;
      this.RDOnly = true;
    } else {
      this.RDOnly = false;
    }
  },
  methods: {
    search(){
      if(this.aspCustKey == undefined || this.aspCustKey == ""){
        this.common_alert("회사구분을 선택해주세요.","error");
      }else{
        this.selectRtnMngrUser();
        this.selectRtnQaUsRater();
      }
    },
    startDate(e) {
      this.dates[0] = e;
    },
    endDate(e) {
      this.dates[1] = e;
    },
    showDialog(type) {
      this[`dialog${type}`] = true;
    },
    hideDialog(type) {
      this[`dialog${type}`] = false;
    },
    submitDialog(type) {
      console.log("완료");
      this[`dialog${type}`] = false;
    },
    resetDialog() {
      console.log("reset");
    },
    unlockDialog() {
      console.log("unlock");
    },
    async selectRtnMngrUser(){
      this.selectedMngr = [];
      this.selectedRater = [];
      let URLData = "api/phone/qa2/rater-qa-manage/mngr/list";
      let HEADER_SERVICE = "phone.qa2.rater-qa-manage.mngr";
      let HEADER_METHOD = "list";
      let HEADER_TYPE = "BIZ_SERVICE";
      let HEADER_ROW_CNT = 100;
      let HEADER_PAGES_CNT = 1;

      let jsonData = {
        ASP_NEWCUST_KEY: this.aspCustKey,
        START_DATE: this.dates[0],
        END_DATE: this.dates[1],
        USER_NM: this.USER_NM,
        USER_ROLE : this.USER_ROLE,
      };

      await api.post(URLData, jsonData,
      {
          head: {
            SERVICE: HEADER_SERVICE,
            METHOD: HEADER_METHOD,
            TYPE: HEADER_TYPE,
            ROW_CNT: HEADER_ROW_CNT,
            PAGES_CNT: HEADER_PAGES_CNT,
          },

      })
      .then((response) => {
        this.mngrUser = response.data.DATA;
      })
      .catch((err) => {
        this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.","error");
      })
    },


    async selectRtnQaUsRater(){

      let URLData = "api/phone/qa2/rater-qa-manage/rater/list";
      let HEADER_SERVICE = "phone.qa2.rater-qa-manage.rater";
      let HEADER_METHOD = "list";
      let HEADER_TYPE = "BIZ_SERVICE";
      let HEADER_ROW_CNT = 100;
      let HEADER_PAGES_CNT = 1;

      let jsonData = {
        ASP_NEWCUST_KEY: this.aspCustKey,
      };

      await api.post(URLData, jsonData, {
        head: {
            SERVICE: HEADER_SERVICE,
            METHOD: HEADER_METHOD,
            TYPE: HEADER_TYPE,
            ROW_CNT: HEADER_ROW_CNT,
            PAGES_CNT: HEADER_PAGES_CNT,
          },

      })
      .then((response) => {
        this.raterUser = response.data.DATA;
      })
      .catch((err) => {
        this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.","error");
      })
    },
    moveLeft(){
      if(this.selectedRater.length > 0){
        this.selectedRater.map(e =>{
          this.raterUser.some((rater, index) => {
            if(rater === e){
              this.raterUser.splice(index,1);
            }
          })
        });
      }else{
        this.common_alert("QA 담당자로 지정할 관리자를 선택해주세요.","error");
      }

      this.selectedRater = [];
    },
    moveRight(){
      let count = 0;
      if(this.selectedMngr.length > 0){
        this.selectedMngr.map(e =>{
          if(!this.raterUser.some(rater => rater.USER_ID === e.USER_ID)){
            this.raterUser.push(e);
            this.mngrUser.some((mngr, index) => {
              if(mngr === e){
                this.mngrUser.splice(index,1);
              }
            })
            count++;
          }
        });

        if(this.selectedMngr.length != count){
          this.common_alert("중복된 관리자가 존재합니다.","error");
        }
      }else{
        this.common_alert("QA 담당자에서 제외할 관리자를 선택해주세요.","error");
      }

      this.selectedMngr = [];
    },

    async processRtn(){

      let URLData = "api/phone/qa2/rater-qa-manage/regist";
      let HEADER_SERVICE = "phone.qa2.rater-qa-manage";
      let HEADER_METHOD = "regist";
      let HEADER_TYPE = "BIZ_SERVICE";
      let DATA_OBJECT = 'raterUser';

      let jsonData = {
        'raterUser' : this.raterUser,
        'REG_ID' : this.$store.getters['userStore/GE_USER_ROLE'].userId,
        'ASP_NEWCUST_KEY' : this.aspCustKey,
      };
      await api.post(URLData, jsonData,
      {
        head: {
            SERVICE: HEADER_SERVICE,
            METHOD: HEADER_METHOD,
            TYPE: HEADER_TYPE,
            DATA_OBJECT : DATA_OBJECT,
          },
      }).then((response) =>{
        this.common_alert("정상처리되었습니다.","done");
      }).catch((e) => {

      });
    },
    setGridData(item, row) {
      this.selectedGridData = item;
    },
    setGridData2(item, row) {
      this.selectedGridData2 = item;
    },
    isActiveRow(item) {
      return (item === this.selectedGridData) ? 'active' : ''
    },
    isActiveRow2(item) {
      return (item === this.selectedGridData2) ? 'active' : ''
    },
  },
  computed: {
    dateRangeText() {
      var newStartDate = this.dates[0];
      var newEndDate = this.dates[1];
      return newStartDate + " ~ " + newEndDate;
    },
  },
};
</script>

<style></style>
