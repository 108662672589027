<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">평가관리</h1>
    <div class="box-wrap">
      <div class="box-ct d-flex">
        <div class="">
          <div class="form-inp sm">
            <v-select
              :items="$store.getters['userStore/GE_USER_COMPANY']"
              item-text="CD_NM"
              item-value="CD"
              outlined
              hide-details
              label="회사구분"
              placeholder="선택하세요"
              v-model="evlPaperSearch.ASP_NEWCUST_KEY"
              @change="companyChange"
              :readonly="RDOnly"
            >
              <template v-slot:label>
                회사구분
                <v-icon color="#EF9191">mdi-circle-small</v-icon>
              </template>
            </v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              :items="mixin_common_code_get(allCodeList,'PLT018','전체')"
              item-text="text"
              item-value="value"
              outlined
              hide-details
              label="유형타입"
              placeholder="선택하세요"
              v-model="evlPaperSearch.LM_EVA_TY"
            ></v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              :items="mixin_common_code_get(allCodeList,'PLT021','전체')"
              item-text="text"
              item-value="value"
              outlined
              hide-details
              label="사용여부"
              placeholder="선택하세요"
              v-model="evlPaperSearch.LM_EVA_YN"
            ></v-select>
          </div>

          <v-text-field
            class="form-inp sm ml-2"
            name="name" label="시험지명" outlined hide-details v-model="evlPaperSearch.LM_EVA_NM" @keyup.enter="selectRtnLmEva();">
          </v-text-field>
        </div>
        <div class="ml-auto align-self-center">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch1')" outlined class="btn-etc2 ml-3" @click="selectRtnLmEva();">조회</v-btn>
        </div>
      </div>
    </div>
    <div class="d-flex justify-space-between">
      <!-- 시험지 리스트 -->
      <div class="box-wrap flex-grow-1 col-6">
        <h2 class="tit-h2 d-flex">시험지 리스트
          <div class="ml-auto">
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnNew1')" outlined class="btn-default" @click="insertRtnEvlPaperForm()">신규</v-btn>
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnCopy1')" outlined class="btn-point ml-2" @click="common_confirm('복사하시겠습니까?',insertRtnCopyEvlPaper,'',null,'','chk')">복사</v-btn>
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnMody1')" outlined class="btn-point ml-2" @click="modifyEvlPaperForm()">수정</v-btn>
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDelete1')" outlined class="btn-etc ml-2" @click="common_confirm('삭제하시겠습니까?',deleteEvlPaper,'',null,'','chk')">삭제</v-btn>
          </div>
        </h2>
        <v-dialog
          max-width="655"
          persistent
          v-model="dialogM410101P01"
          v-if="dialogM410101P01"
          content-class="square-modal min-auto"
        >
          <dialog-M410101P01
            headerTitle="시험지 리스트"
            p01
            :isModify="isModify"
            :PLT018="mixin_common_code_get(allCodeList,'PLT018','선택')"
            :PLT021="mixin_common_code_get(allCodeList,'PLT021','선택')"
            :PLT022="mixin_common_code_get(allCodeList,'PLT022','선택')"
            @hide="hideDialog('M410101P01')"
            @submit="saveEvlPaper"
            :aspNewCustKey="evlPaperSearch.ASP_NEWCUST_KEY"
            :propsEvlPaper="isModify? checkedEvlPaper[0] : {}"
          >
          </dialog-M410101P01>
        </v-dialog>
        <div class="box-ct">
          <v-data-table
            dense
            :headers="evlPaperHeader"
            :items="evlPaperData"
            item-key="LM_EVA_ID"
            hide-default-footer
            class="grid-default"
            show-select
            height="200"
            no-data-text="검색된 결과가 없습니다."
            @click:row="setEvlPaper"
            v-model="checkedEvlPaper"
            :page.sync="evlPaperPaging.page"
            :items-per-page="30"
            @page-count="evlPaperPaging.pageCount = $event"
            :item-class="isActiveRow"
          >
            <template v-slot:item.LM_EVA_YN="{item}">
              {{item.USE_COUNT > 0 ? "사용" : "미사용"}}
            </template>
          </v-data-table>
          <div class="grid-paging text-center pt-2">
            <v-pagination
              v-model="evlPaperPaging.page"
              :length="evlPaperPaging.pageCount"
              :total-visible="evlPaperPaging.totalVisible"
              next-icon="svg-paging-next"
              prev-icon="svg-paging-prev"
            ></v-pagination>
          </div>
        </div>
      </div>
      <!-- 시험문항 -->
      <div class="box-wrap flex-grow-1 ml-4">
        <h2 class="tit-h2 d-flex">시험문항
          <div class="ml-auto">
            <div class="form-inp sm">
              <v-select
                :items="mixin_common_code_get(allCodeList,'PLT019','전체')"
                item-text="text"
                item-value="value"
                outlined
                hide-details
                label="유형"
                placeholder="선택하세요"
                v-model="lmQsSearch.LM_QS_TY"
                @change="selectRtnLmQs"
              ></v-select>
            </div>
            <div class="form-inp sm ml-2">
              <v-select
                :items="mixin_common_code_get(allCodeList,'PLT020','전체')"
                item-text="text"
                item-value="value"
                outlined
                hide-details
                label="난이도"
                placeholder="선택하세요"
                v-model="lmQsSearch.LM_QS_DIC"
                @change="selectRtnLmQs"
              ></v-select>
            </div>
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnNew2')" outlined class="btn-default ml-2" @click="insertRtnLmQsForm()">신규</v-btn>
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnCopy2')" outlined class="btn-point ml-2" @click="common_confirm('복사하시겠습니까?',insertRtnCopyLmQs,'',null,'','chk')">복사</v-btn>
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnMody2')" outlined class="btn-point ml-2" @click="modifyLmQsDialog()">수정</v-btn>
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDelete2')" outlined class="btn-etc ml-2" @click="common_confirm('삭제하시겠습니까?',deleteRtnLmQs,'',null,'','chk')">삭제</v-btn>
          </div>
        </h2>
        <v-dialog
          max-width="655"
          persistent
          v-model="dialogM410101P02"
          v-if="dialogM410101P02"
          content-class="square-modal min-auto"
        >
          <dialog-M410101P01
            headerTitle="평가문항"
            p02
            :aspNewCustKey="evlPaperSearch.ASP_NEWCUST_KEY"
            :isModify="isModify"
            :PLT019="mixin_common_code_get(allCodeList,'PLT019','선택')"
            :PLT020="mixin_common_code_get(allCodeList,'PLT020','선택')"
            :PLT021="mixin_common_code_get(allCodeList,'PLT021','선택')"
            @hide="hideDialog('M410101P02')"
            @submit="saveLmQs"
            @init="initDialog()"
            :propsLmQs="isModify ?checkedLmQs[0] : {}"
          >
          </dialog-M410101P01>
        </v-dialog>
        <div class="box-ct">
          <v-data-table
            dense
            :headers="lmQsHeader"
            :items="lmQsData"
            item-key="LM_QS_ID"
            hide-default-footer
            class="grid-default"
            show-select
            fixed-header
            height="200"
            no-data-text="검색된 결과가 없습니다."
            v-model="checkedLmQs"
            :page.sync="lmQsPaging.page"
            :items-per-page="30"
            @page-count="lmQsPaging.pageCount = $event"
            :item-class="isActiveRow2"
            @click:row="clickTblRow2"
          >
          <template v-slot:item.LM_QS_YN="{item}">
            {{item.USE_COUNT > 0 ? '사용': "미사용"}}
          </template>
          </v-data-table>
          <div class="grid-paging text-center pt-2">
            <v-pagination
              v-model="lmQsPaging.page"
              :length="lmQsPaging.pageCount"
              :total-visible="lmQsPaging.totalVisible"
              next-icon="svg-paging-next"
              prev-icon="svg-paging-prev"
            ></v-pagination>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-space-between">
      <!-- 시험지 상세관리 -->
      <div class="box-wrap flex-grow-1 col-6">
        <h2 class="tit-h2 d-flex">시험지 상세관리
          <p class="caption">({{selectedEvlPaper.LM_EVA_NM}})</p>
          <div class="ml-auto">
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnNew3')" outlined class="btn-default" @click="insertRtnEvlRstForm()">신규</v-btn>
            <!-- <v-btn outlined class="btn-default ml-2">복사</v-btn> -->
            <!-- <v-btn outlined class="btn-point ml-2">수정</v-btn> -->
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDelete3')" outlined class="btn-etc ml-2" @click="common_confirm('삭제하시겠습니까?',deleteRtnEvlRst,'',null,'','chk')">삭제</v-btn>
          </div>
        </h2>
         <v-dialog
          max-width="655"
          persistent
          v-model="dialogM410101P03"
          v-if="dialogM410101P03"
          content-class="square-modal min-auto"
        >
          <dialog-M410101P01
            headerTitle="평가 상세관리"
            p03
            :propsEvlRst="evlRstData"
            :propsEvlPaper="selectedEvlPaper"
            :PLT018="mixin_common_code_get(allCodeList,'PLT018','선택')"
            :PLT019="mixin_common_code_get(allCodeList,'PLT019','선택')"
            :PLT020="mixin_common_code_get(allCodeList,'PLT020','선택')"
            :PLT021="mixin_common_code_get(allCodeList,'PLT021','선택')"
            @hide="hideDialog('M410101P03')"
            @submit="insertRtnLmEvaRst"
            @addrow="addrowDialog('M410101P05')"
            @delrow="delEvlRst"
          >
          <!-- deleteRtnEvlRst -->
          </dialog-M410101P01>
        </v-dialog>
        <div class="box-ct">
          <v-data-table
            dense
            :headers="evlRstHeader"
            :items="evlRstData"
            item-key="LM_QS_ID"
            hide-default-footer
            class="grid-default"
            show-select
            fixed-header
            height="200"
            no-data-text="검색된 결과가 없습니다."
            :page.sync="evlRstPaging.page"
            :items-per-page="30"
            @page-count="evlRstPaging.pageCount = $event"
            v-model="checkedEvlRst"
            :item-class="isActiveRow3"
            @click:row="clickTblRow3"
          >
          <template v-slot:item.USE_YN>
            사용
          </template>
          </v-data-table>
          <div class="grid-paging text-center pt-2">
            <v-pagination
              v-model="evlRstPaging.page"
              :length="evlRstPaging.pageCount"
              :total-visible="evlRstPaging.totalVisible"
              next-icon="svg-paging-next"
              prev-icon="svg-paging-prev"
            ></v-pagination>
          </div>
        </div>
      </div>
      <!-- 전체보기 -->
      <div class="box-wrap flex-grow-1 ml-4">
        <h2 class="tit-h2 d-flex">전체보기
          <div class="ml-auto">
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnAll')" outlined class="btn-default ml-2" @click="showDialog('M410101P04')">전체보기</v-btn>
          </div>
        </h2>
        <v-dialog
          max-width="1748"
          max-height="600"
          persistent
          v-model="dialogM410101P04"
          content-class="square-modal modal-white"
        >
          <dialog-M410101P01
            headerTitle="전체보기"
            p04
            :preEvlRstData="evlRstData"
            :preViewList="preViewList"
            @hide="hideDialog('M410101P04')"
          >
          </dialog-M410101P01>
        </v-dialog>
        <div class="box-ct scrollable" style="height: 255px">
          <div class="qa-report">
            <template v-for="(item, index) in evlRstData">
              <dl :key="index">
                <dt>{{index + 1}}. {{item.LM_QS_NM}}({{item.LM_QS_TY_SC}}점)</dt>
                <dd class="mt-3">
                  <template v-for="(ve, index2) in preViewList" >
                    <div v-if="item.LM_QS_ID == ve.LM_QS_ID" :key="index2">
                      <!-- 서술형 -->
                      <template v-if="ve.LM_QS_TY === '20'">
                        <v-text-field
                          class="form-inp full"
                          name="name" placeholder="직접입력" id="id" outlined hide-details disabled>
                        </v-text-field>
                        <v-text-field
                          class="form-inp full "
                          type="number"
                          :value="ve.LM_QS_VE_SC"
                          disabled
                        ></v-text-field>
                      </template>
                      <!-- 객관식, 체크박스 -->
                      <template v-else>
                        {{ve.LM_QS_VE_RT}}
                      </template>
                    </div>
                  </template>
                </dd>
              </dl>
            </template>
          </div>
        </div>
      </div>
    </div>
    <!-- 항목 추가 모달 dialogM410101P05 -->
    <v-dialog
      max-width="1400"
      persistent
      v-model="dialogM410101P05"
      v-if="dialogM410101P05"
      content-class="square-modal modal-white"
    >
      <div class="modal-wrap">
        <h1 class="tit-h1 d-flex">시험조회
          <div class="ml-auto align-self-center">
            <v-icon x-small class="svg-close20" @click="dialogM410101P05 = !dialogM410101P05"></v-icon>
          </div>
        </h1>
        <div class="modal-box">
          <div>
            <div class="d-flex gray-box">
              <div>
                <div class="form-inp sm">
                  <v-select
                    :items="mixin_common_code_get(allCodeList,'PLT019','전체')"
                    item-text="text"
                    item-value="value"
                    outlined
                    hide-details
                    label="문항유형"
                    placeholder="선택하세요"
                    v-model="lmQsDialogSearch.LM_QS_TY"
                  ></v-select>
                </div>
                <div class="form-inp sm ml-2">
                  <v-select
                    :items="mixin_common_code_get(allCodeList,'PLT021','전체')"
                    item-text="text"
                    item-value="value"
                    outlined
                    hide-details
                    label="사용여부"
                    placeholder="선택하세요"
                    v-model="lmQsDialogSearch.LM_QS_YN"
                  ></v-select>
                </div>
                <div class="form-inp sm ml-2">
                  <v-select
                    :items="mixin_common_code_get(allCodeList,'PLT020','전체')"
                    item-text="text"
                    item-value="value"
                    outlined
                    hide-details
                    label="난이도"
                    placeholder="선택하세요"
                    v-model="lmQsDialogSearch.LM_QS_DIC"
                  ></v-select>
                </div>
                <v-text-field
                  class="form-inp sm ml-2"
                  name="name" label="문제" outlined hide-details
                  v-model="lmQsDialogSearch.LM_QS_NM"
                  @keyup.enter="selectRtnLmQsDialog">
                </v-text-field>
              </div>
              <div class="ml-auto align-self-center">
                <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch2')" outlined class="btn-etc2 ml-3" @click="selectRtnLmQsDialog">조회</v-btn>
              </div>
            </div>
            <div class="table-form mt-2">
              <v-data-table
                dense
                :headers="lmQsDialogHeader"
                :items="lmQsDialogData"
                item-key="LM_QS_ID"
                hide-default-footer
                class="grid-default"
                show-select
                fixed-header
                height="200"
                no-data-text="검색된 결과가 없습니다."
                v-model="checkedLmQsDialog"
                :page.sync="lmQsDialogPaging.page"
                :items-per-page="30"
                @page-count="lmQsDialogPaging.pageCount = $event"
              >
              </v-data-table>
              <div class="grid-paging text-center pt-2">
                <v-pagination
                  v-model="lmQsDialogPaging.page"
                  :length="lmQsDialogPaging.pageCount"
                  :total-visible="lmQsDialogPaging.totalVisible"
                  next-icon="svg-paging-next"
                  prev-icon="svg-paging-prev"
                ></v-pagination>
              </div>
            </div>
            <!-- 이동버튼 -->
            <div class="d-flex justify-center pt-2">
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDelArrow')" class="btn-arrow" plain small @click="delEvlRst(checkedEvlRstDialog,evlRstDialogData)">
                <v-icon small class="svg-arrow-top"></v-icon>
              </v-btn>
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnAddArrow')" class="btn-arrow ml-2" plain small @click="addEvlRst()">
                <v-icon small class="svg-arrow-down"></v-icon>
              </v-btn>
            </div>
            <div class="table-form mt-2">
              <v-data-table
                dense
                :headers="evlRstDialogHeader"
                :items="evlRstDialogData"
                item-key="LM_QS_ID"
                hide-default-footer
                class="grid-default"
                show-select
                fixed-header
                height="200"
                no-data-text="검색된 결과가 없습니다."
                :page.sync="evlRstDialogPaging.page"
                :items-per-page="30"
                @page-count="evlRstDialogPaging.pageCount = $event"
                v-model="checkedEvlRstDialog"
              >
                <template v-slot:item.LM_EVA_RST_OD="{item}">
                    <v-text-field
                      class="form-inp sm ml-2"
                      type="number"
                      name="LM_EVA_RST_OD" label="" outlined hide-details
                      v-model.number="item.LM_EVA_RST_OD"
                    >
                    </v-text-field>
                </template>
              </v-data-table>
              <div class="grid-paging text-center pt-2">
                <v-pagination
                  v-model="evlRstDialogPaging.page"
                  :length="evlRstDialogPaging.pageCount"
                  :total-visible="evlRstDialogPaging.totalVisible"
                  next-icon="svg-paging-next"
                  prev-icon="svg-paging-prev"
                ></v-pagination>
              </div>
            </div>

          </div>
          <div class="text-right mt-3">
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnPreview')" outlined class="btn-default" @click="preView()">미리보기</v-btn>
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSave')" outlined class="btn-point ml-2" @click="common_confirm('저장하시겠습니까?',insertRtnLmEvaRst, evlRstDialogData, null,'','chk')">저장</v-btn>
          </div>
        </div>

      </div>
    </v-dialog>
    <v-dialog
          max-width="1400"
          max-height="600"
          persistent
          v-model="dialogM410101P06"
          content-class="square-modal modal-white"
        >
          <dialog-M410101P01
            headerTitle="전체보기"
            p04
            :preEvlRstData="evlRstDialogData"
            :preViewList="preVeDialog"
            @hide="hideDialog('M410101P06')"
          >
          </dialog-M410101P01>
        </v-dialog>
  </div>


</template>

<script>
import DialogM410101P01 from './M410101P01.vue'
import api from '@/store/apiUtil.js'
import {mixin} from '@/mixin/mixin.js'

export default {
  name: "MENU_M410101",
  components: {
    DialogM410101P01,
  },
  mixins : [mixin],
  data() {
    return{
      dialogM410101P01: false,
      dialogM410101P02: false,
      dialogM410101P03: false,
      dialogM410101P04: false,
      dialogM410101P05: false,
      dialogM410101P06: false,

      evlPaperSearch : {
        ASP_NEWCUST_KEY : ""
        , LM_EVA_TY : ""
        , LM_EVA_YN : ""
        , LM_EVA_NM : ""
      },
      lmQsSearch : {
        LM_QS_DIC : "",
      },
      lmQsDialogSearch : {
        LM_QS_DIC : "",
        LM_QS_NM : "",
        LM_QS_TY : "",
      },
      evlPaperHeader : [
         {
          text: 'No',
          align: 'center',
          value: 'LM_EVA_ID',
          width: '70px',
          sortable: true,
        },
        { text: '유형타입', value: 'LM_EVA_TY_NM', align: 'center', sortable: true, },
        { text: '시험지 명', value: 'LM_EVA_NM',align: 'left', sortable: true, },
        { text: '문제수', value: 'LM_RST_COUNT', align: 'center',sortable: true, },
        { text: '질문랜덤', value: 'LM_EVA_RM_YN', align: 'center',sortable: true, },
        { text: '총점', value: 'LM_EVA_SUM', align: 'center',sortable: true, },
        { text: '사용여부', value: 'LM_EVA_YN', align: 'center',sortable: true, },

      ],
      evlPaperData: [],
      evlRstHeader: [
         {
          text: 'No',
          align: 'center',
          value: 'LM_QS_ID',
          width: '70px',
          sortable: true,
        },
        { text: '유형', value: 'LM_QS_TY_NM', align: 'center', sortable: true, },
        { text: '난이도', value: 'LM_QS_DIC_NM',align: 'center', sortable: true, },
        { text: '문제', value: 'LM_QS_NM', align: 'left',sortable: true, },
        { text: '배점', value: 'LM_QS_TY_SC', align: 'center',sortable: true, },
        { text: '사용여부', value: 'USE_YN', align: 'center',sortable: true },
      ],
      evlRstData:[],
      lmQsHeader:[
        {
          text: 'No',
          align: 'center',
          value: 'LM_QS_ID',
          width: '70px',
          sortable: true,
        },
        { text: '유형', value: 'LM_QS_TY_NM', align: 'center', sortable: true, },
        { text: '난이도', value: 'LM_QS_DIC_NM',align: 'center', sortable: true, },
        { text: '문제', value: 'LM_QS_NM',align: 'left', sortable: true, },
        { text: '최근출제일', value: 'LM_QS_WT_DTTM',align: 'center', sortable: true, },
        { text: '출제횟수', value: 'LM_QS_WT_CNT',align: 'center', sortable: true, },
        { text: '배점', value: 'LM_QS_TY_SC', align: 'center',sortable: true, },
        { text: '사용여부', value: 'LM_QS_YN', align: 'center',sortable: true, },
      ],
      lmQsData:[],
      lmQsDialogHeader:[
        {
          text: 'No',
          align: 'center',
          value: 'LM_QS_ID',
          width: '50px',
          sortable: false,
        },
        { text: '유형', value: 'LM_QS_TY_NM', align: 'center',sortable: false, },
        { text: '난이도', value: 'LM_QS_DIC_NM',align: 'center', sortable: false, },
        { text: '문제', value: 'LM_QS_NM',align: 'left', sortable: false, },
        { text: '최근출제일', value: 'LM_QS_WT_DTTM', align: 'center',sortable: false, },
        { text: '출제횟수', value: 'LM_QS_WT_CNT', align: 'center',sortable: false, },
        { text: '배점', value: 'LM_QS_TY_SC', align: 'center',sortable: false, },
        { text: '사용여부', value: 'USE_YN', align: 'center',sortable: false, },
      ],
      evlRstDialogHeader:[
        {
          text: 'No',
          align: 'center',
          value: 'LM_QS_ID',
          width: '50px',
          sortable: false,
        },
        { text: '유형', value: 'LM_QS_TY_NM', align: 'center',sortable: false, },
        { text: '난이도', value: 'LM_QS_DIC_NM',align: 'center', sortable: false, },
        { text: '문제', value: 'LM_QS_NM',align: 'left', sortable: false, },
        { text: '배점', value: 'LM_QS_TY_SC', align: 'center',sortable: false, },
        { text: '정렬', value: 'LM_EVA_RST_OD', align: 'center' },
      ],
      evlRstDialogData:[],
      preViewList:[],
      lmQsDialogData:[],
      checkedEvlPaper : [],
      checkedLmQs : [],
      checkedLmQsDialog : [],
      checkedEvlRstDialog : [],
      checkedEvlRst : [],
      selectedEvlPaper : {},
      LM_EVA_ID : "",
      LM_EVA_NM : "",
      allCodeList : [],
      isModify : false,
      evlPaperPaging:{
        page: 1,
        pageCount: 1,
        itemsPerPage: 5,
        totalVisible: 5,
      },
      lmQsPaging:{
        page: 1,
        pageCount: 0,
        itemsPerPage: 5,
        totalVisible: 5,
      },
      evlRstPaging:{
        page: 1,
        pageCount: 0,
        itemsPerPage: 5,
        totalVisible: 5,
      },
      evlRstDialogPaging:{
        page: 1,
        pageCount: 0,
        itemsPerPage: 5,
        totalVisible: 5,
      },
      lmQsDialogPaging:{
        page: 1,
        pageCount: 0,
        itemsPerPage: 5,
        totalVisible: 5,
      },
      preVeDialog:[],

      selectedTbl2: null,
      selectedTbl3: null,
      
      RDOnly:false,
    }
  },
  created (){

    //this.getAspData();
  },
  async mounted () {
    let codeList = ['PLT018','PLT019','PLT020','PLT021','PLT022'];
    this.allCodeList = await this.mixin_common_code_get_all(codeList);

    let chk = this.mixin_check_AspCustKey_number();
    if(chk == 'Y'){
      this.evlPaperSearch.ASP_NEWCUST_KEY = this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD;
      this.RDOnly = true;
    } else {
      this.RDOnly = false;
    }
  },
  watch : {
    selectedEvlPaper : function(newValue, oldValue){
      if(this.selectedEvlPaper.LM_EVA_ID != ''){
        this.selectRtnLmEvaRst();
        this.selectRtnLmQsDialog();
        this.selectRtnLmPreView();
      }

      if(newValue != oldValue){
        this.checkedEvlRst = [];
      }
    },

  },
  methods: {
    companyChange(){
      this.selectRtnLmEva();
      this.selectRtnLmQs();
    },
    /* 시험지 리스트 조회 */
    async selectRtnLmEva(){

      //체크 초기화
      this.checkedEvlPaper = [];
      this.selectedEvlPaper = [];
      this.evlRstData = [];
      this.checkedEvlRst = [];

      if(this.evlPaperSearch.ASP_NEWCUST_KEY != undefined && this.evlPaperSearch.ASP_NEWCUST_KEY != ''){
        let jsonData = {
          'ASP_NEWCUST_KEY' : this.evlPaperSearch.ASP_NEWCUST_KEY,
          'LM_EVA_TY': this.evlPaperSearch.LM_EVA_TY,
          'LM_EVA_YN': this.evlPaperSearch.LM_EVA_YN,
          'LM_EVA_NM': this.evlPaperSearch.LM_EVA_NM,
        }

        let URLData = "api/phone/lm/evl-paper-manage/list";
        let HEADER_SERVICE = "phone.lm.evl-paper-manage";
        let HEADER_METHOD = "list";
        let HEADER_TYPE = "BIZ_SERVICE";

        await api.post(URLData,jsonData,{
          head : {
              SERVICE: HEADER_SERVICE,
              METHOD: HEADER_METHOD,
              TYPE: HEADER_TYPE,
          }
        })
        .then((response) => {
          this.evlPaperData = response.data.DATA;
        })
        .catch((err) => {
          this.errorAlert();
        })
      }else{
        this.warningAlert('회사를 먼저 선택해주세요.');
      }
    },
    /* 시험지 삭제 */
    async deleteEvlPaper(){

      if(this.checkedEvlPaper.length > 0){
        let isUse = false;
        this.checkedEvlPaper.map(e=>{
          if(e.USE_COUNT > 0){
            isUse = true;
          }
        });

        if(!isUse){

          let jsonData = {
            'ASP_NEWCUST_KEY' : this.evlPaperSearch.ASP_NEWCUST_KEY,
            'checkedEvlPaper': this.checkedEvlPaper,
          }

          let URLData = "api/phone/lm/evl-paper-manage/delete";
          let HEADER_SERVICE = "phone.lm.evl-paper-manage";
          let HEADER_METHOD = "delete";
          let HEADER_TYPE = "BIZ_SERVICE";

          await api.post(URLData,jsonData,{
            head : {
              SERVICE: HEADER_SERVICE,
              METHOD: HEADER_METHOD,
              TYPE: HEADER_TYPE,
            }
          })
          .then((response) => {
            this.successAlert();
            this.checkedEvlPaper = [];
            this.selectRtnLmEva();
          })
          .catch((err) => {
            this.errorAlert();
          })
        }else{
          this.warningAlert('사용중인 시험지는 삭제할 수 없습니다.');
        }

      }else{
        this.warningAlert('삭제할 시험지를 선택해주세요.');
      }
    },

    //시험지 복사
    async insertRtnCopyEvlPaper(){
      if(this.checkedEvlPaper.length > 0){
        let jsonData = {
          'ASP_NEWCUST_KEY' : this.evlPaperSearch.ASP_NEWCUST_KEY,
          'REG_ID' : this.$store.getters['userStore/GE_USER_ROLE'].userId,
          'checkedEvlPaper': this.checkedEvlPaper,
        }

        let URLData = "api/phone/lm/evl-paper-manage/copy/regist";
        let HEADER_SERVICE = "phone.lm.evl-paper-manage.copy";
        let HEADER_METHOD = "regist";
        let HEADER_TYPE = "BIZ_SERVICE";

        await api.post(URLData,jsonData,{
          head : {
            SERVICE: HEADER_SERVICE,
            METHOD: HEADER_METHOD,
            TYPE: HEADER_TYPE,
          }
        })
        .then((response) => {
          this.successAlert();
          this.checkedEvlPaper = [];
          this.selectRtnLmEva();
        })
        .catch((err) => {
          this.errorAlert();
        })
      }else{
        this.warningAlert('복사할 시험지를 선택해주세요.');
      }

    },
    /* 평가 상세관리 조회 */
    async selectRtnLmEvaRst(){
      await api.post('api/phone/lm/evl-qa-rst-manage/list',{
        ['ASP_NEWCUST_KEY']: this.evlPaperSearch.ASP_NEWCUST_KEY,
        ['LM_EVA_ID']: this.selectedEvlPaper.LM_EVA_ID,
      })
      .then((response) => {
        this.evlRstData = response.data.DATA;
      })
      .catch((err) => {
        this.errorAlert();
      })
    },
    async selectRtnDialogQaEvaRst(){
      await api.post('api/phone/lm/evl-qa-rst-manage/list',{
        ['ASP_NEWCUST_KEY']: this.evlPaperSearch.ASP_NEWCUST_KEY,
        ['LM_EVA_ID']: this.selectedEvlPaper.LM_EVA_ID,
      })
      .then((response) => {
        this.evlRstDialogData = response.data.DATA;
      })
      .catch((err) => {
        this.errorAlert();
      })
    },
    //시험문항 조회
    async selectRtnLmQs(){

      //체크 초기화
      this.checkedLmQs = [];
      if(this.evlPaperSearch.ASP_NEWCUST_KEY != undefined && this.evlPaperSearch.ASP_NEWCUST_KEY != ''){
        let jsonData = {
          'ASP_NEWCUST_KEY' : this.evlPaperSearch.ASP_NEWCUST_KEY,
          'LM_QS_TY' : this.lmQsSearch.LM_QS_TY,
          'LM_QS_DIC': this.lmQsSearch.LM_QS_DIC,
        }

        let URLData = "api/phone/lm/evl-qs-manage/list";
        let HEADER_SERVICE = "phone.lm.evl-qs-manage";
        let HEADER_METHOD = "list";
        let HEADER_TYPE = "BIZ_SERVICE";

        await api.post(URLData, jsonData, {
          head : {
            SERVICE: HEADER_SERVICE,
            METHOD: HEADER_METHOD,
            TYPE: HEADER_TYPE,
          }
        })
        .then((response) => {
          this.lmQsData = response.data.DATA;
        })
        .catch((err) => {
          this.errorAlert();
        })
      }
    },
    //시험문항 조회 (dialog)
    async selectRtnLmQsDialog(){

      let jsonData = {
        'ASP_NEWCUST_KEY' : this.evlPaperSearch.ASP_NEWCUST_KEY,
        'LM_QS_DIC': this.lmQsDialogSearch.LM_QS_DIC,
        'LM_QS_NM' : this.lmQsDialogSearch.LM_QS_NM,
        'LM_QS_TY' : this.lmQsDialogSearch.LM_QS_TY,
        'LM_QS_YN' : this.lmQsDialogSearch.LM_QS_YN,
      }

      let URLData = "api/phone/lm/evl-qs-manage/list";
      let HEADER_SERVICE = "phone.lm.evl-qs-manage";
      let HEADER_METHOD = "list";
      let HEADER_TYPE = "BIZ_SERVICE";

      await api.post(URLData, jsonData, {
        head : {
          SERVICE: HEADER_SERVICE,
          METHOD: HEADER_METHOD,
          TYPE: HEADER_TYPE,
        }
      })
      .then((response) => {
        this.lmQsDialogData = response.data.DATA;
      })
      .catch((err) => {
        this.errorAlert();
      })
    },
    //시험문항 복사
    async insertRtnCopyLmQs(){
      if(this.checkedLmQs.length > 0){
        let jsonData = {
          'ASP_NEWCUST_KEY' : this.evlPaperSearch.ASP_NEWCUST_KEY,
          'REG_ID' : this.$store.getters['userStore/GE_USER_ROLE'].userId,
          'checkedLmQs': this.checkedLmQs,
        }

        let URLData = "api/phone/lm/evl-qs-manage/copy/regist";
        let HEADER_SERVICE = "phone.lm.evl-qs-manage.copy";
        let HEADER_METHOD = "regist";
        let HEADER_TYPE = "BIZ_SERVICE";

        await api.post(URLData,jsonData,{
          head : {
            SERVICE: HEADER_SERVICE,
            METHOD: HEADER_METHOD,
            TYPE: HEADER_TYPE,
          }
        })
        .then((response) => {
          this.successAlert();
          this.checkedLmQs = [];
          this.selectRtnLmQs();
          this.selectRtnLmQsDialog();
        })
        .catch((err) => {
          this.errorAlert();
        })
      }else{
        this.warningAlert('복사할 문항을 선택해주세요.');
      }
    },
    // 시험문항 삭제
    async deleteRtnLmQs(){
      if(this.checkedLmQs.length > 0){
        if(!this.checkedLmQs.some(e=>{return e.USE_COUNT > 0? true: false})){
          let jsonData = {
            'ASP_NEWCUST_KEY' : this.evlPaperSearch.ASP_NEWCUST_KEY,
            'checkedLmQs': this.checkedLmQs,
          }

          let URLData = "api/phone/lm/evl-qs-manage/delete";
          let HEADER_SERVICE = "phone.lm.evl-qs-manage";
          let HEADER_METHOD = "delete";
          let HEADER_TYPE = "BIZ_SERVICE";

          await api.post(URLData,jsonData,{
            head : {
              SERVICE: HEADER_SERVICE,
              METHOD: HEADER_METHOD,
              TYPE: HEADER_TYPE,
            }
          })
          .then((response) => {
            this.successAlert();
            this.checkedLmQs = [];
            this.selectRtnLmQs();
            this.selectRtnLmQsDialog();
          })
          .catch((err) => {
            this.errorAlert();
          })
        }else{
          this.warningAlert('사용중인 문항은 삭제할 수 없습니다.');
        }
      }else{
        this.warningAlert('삭제할 문항을 선택해주세요.');
      }
    },
    //시험지 상세 저장
    async insertRtnLmEvaRst(evlRstList){
      let jsonData = {
          'ASP_NEWCUST_KEY' : this.evlPaperSearch.ASP_NEWCUST_KEY,
          'evlRstList': evlRstList,
          'LM_EVA_ID' : this.selectedEvlPaper.LM_EVA_ID,
          'REG_ID' : this.$store.getters['userStore/GE_USER_ROLE'].userId,
        }
        let URLData = "api/phone/lm/evl-qa-rst-manage/regist";
        let HEADER_SERVICE = "phone.lm.evl-qa-rst-manage";
        let HEADER_METHOD = "regist";
        let HEADER_TYPE = "BIZ_SERVICE";
        let DATA_OBJECT = "evlRstList"

        await api.post(URLData,jsonData,{
          head : {
            SERVICE: HEADER_SERVICE,
            METHOD: HEADER_METHOD,
            TYPE: HEADER_TYPE,
            DATA_OBJECT : DATA_OBJECT,
          }
        })
        .then((response) => {
          this.successAlert();
          this.selectRtnLmEva();
          this.selectRtnLmQs();
          this.selectRtnLmEvaRst();
          this.selectRtnDialogQaEvaRst();
          this.selectRtnLmPreView();
          this.dialogM410101P05 = false;
        })
        .catch((err) => {
          this.errorAlert();
        })
    },

    //미리보기
    async selectRtnLmPreView(){
      let jsonData = {
          'ASP_NEWCUST_KEY' : this.evlPaperSearch.ASP_NEWCUST_KEY,
          'LM_EVA_ID' : this.selectedEvlPaper.LM_EVA_ID,
        }
        let URLData = "api/phone/lm/evl-qa-rst-manage/pre/list";
        let HEADER_SERVICE = "phone.lm.evl-qa-rst-manage.pre";
        let HEADER_METHOD = "list";
        let HEADER_TYPE = "BIZ_SERVICE";

        await api.post(URLData,jsonData,{
          head : {
            SERVICE: HEADER_SERVICE,
            METHOD: HEADER_METHOD,
            TYPE: HEADER_TYPE,
          }
        })
        .then((response) => {
          this.preViewList = response.data.DATA;
        })
        .catch((err) => {
          this.errorAlert();
        })
    },
    async selectRtnPreLmVe(){
      let URLData = "api/phone/lm/evl-qa-rst-manage/pre/ve/list";
      let HEADER_SERVICE = "phone.lm.evl-qa-rst-manage.pre.ve";
      let HEADER_METHOD = "list";
      let HEADER_TYPE = "BIZ_SERVICE";
      let DATA_OBJECT = "LM_QS_LIST";
      let jsonData = {
        'LM_QS_LIST' : this.evlRstDialogData,
      };

      await api.post(URLData, jsonData, {
        head : {
          SERVICE: HEADER_SERVICE,
          METHOD: HEADER_METHOD,
          TYPE: HEADER_TYPE,
          DATA_OBJECT : DATA_OBJECT,
        }
      })
      .then((response) => {
        this.preVeDialog = response.data.DATA;
      })
      .catch((err) => {
        this.errorAlert();
      })
    },


    /* Dialog 호출*/

    /* 시험지 등록 호출 */
    insertRtnEvlPaperForm(){
      if(this.evlPaperSearch.ASP_NEWCUST_KEY != undefined && this.evlPaperSearch.ASP_NEWCUST_KEY != ''){
        this.isModify = false;
        this.showDialog('M410101P01');
      }else{
        this.warningAlert('회사를 먼저 선택해주세요.');
      }
    },
    /* 시험지 수정 호출 */
    modifyEvlPaperForm(){
      if(this.checkedEvlPaper.length === 1){
        this.isModify = true;
        this.showDialog('M410101P01');
      }else if(this.checkedEvlPaper.length > 0){
         this.warningAlert('수정할 시험지를 1건만 선택해주세요.');
      }else{
        this.warningAlert('수정할 시험지를 선택해주세요.');
      }
    },
    //평가문항 등록폼 호출
    insertRtnLmQsForm(){
      if(this.evlPaperSearch.ASP_NEWCUST_KEY != undefined && this.evlPaperSearch.ASP_NEWCUST_KEY != ''){
        this.isModify = false;
        this.showDialog('M410101P02');
      }else{
        this.warningAlert('회사를 먼저 선택해주세요.');
      }
    },

    showDialog(type){
    this[`dialog${type}`] = true
    },
    hideDialog(type){
      this[`dialog${type}`] = false
    },
    submitDialog(type){
      this[`dialog${type}`] = false
    },
    addrowDialog(type){
      this[`dialog${type}`] = true
    },
    delrowDialog(){
      console.log('del row');
    },
    initDialog(){
      console.log('init');
    },
    setEvlPaper(item, row){
      this.selectedEvlPaper = item;
    },
    saveEvlPaper(){
      this.selectRtnLmEva();
      this.submitDialog("M410101P01");
    },
    saveLmQs(){
      this.selectRtnLmQs();
      this.selectRtnLmQsDialog();
      this.submitDialog("M410101P02");
    },
    //시험 상세
    insertRtnEvlRstForm(){
      this.isModify = false;
      if(this.selectedEvlPaper.LM_EVA_ID != null && this.selectedEvlPaper.LM_EVA_ID != ''){
        this.checkedLmQsDialog = [];
        this.selectRtnDialogQaEvaRst();
        this.showDialog('M410101P03');
      }else{
        this.warningAlert('시험지를 선택해주세요.');
      }
    },
    //평가문항 수정 호출
    modifyLmQsDialog(){
      if(this.checkedLmQs.length === 1){
        this.isModify = true;
        this.showDialog('M410101P02');
      }else if(this.checkedLmQs.length > 0){
        this.warningAlert('수정할 문항을 1건만 선택해주세요.');
      }else{
        this.warningAlert('수정할 문항을 선택해주세요.');
      }
    },

    /** 기타 함수 **/
    //평가 상세 항목 추가
    addEvlRst(){
      if(this.checkedLmQsDialog.length > 0){
        this.checkedLmQsDialog.map(e => {
          if(!this.evlRstDialogData.some(evlRst => evlRst.LM_QS_ID === e.LM_QS_ID)){
            e.LM_EVA_RST_OD = this.getMaxSortOrder() + 10;
            this.evlRstDialogData.push(e);
          }
        });
      }else{
        this.warningAlert('추가할 항목을 선택해주세요.');
      }
    },
    deleteRtnEvlRst(){
      if(this.selectedEvlPaper.USE_COUNT > 0){
        this.warningAlert('사용중인 시험지 상세는 삭제할 수 없습니다.');
      }else{
        this.delEvlRst(this.checkedEvlRst ,this.evlRstData);
        this.insertRtnLmEvaRst(this.evlRstData);
      }
    },
    //평가 상세 항목 삭제
    delEvlRst(deleteList, targetList){
      if(deleteList.length > 0){
        deleteList.map(e =>{
          targetList.some((evlRst, index) => {
            if(evlRst === e){
              targetList.splice(index,1);
            }
          })
        });
      }
    },
    //평가 상세 항목 max 정렬 순번
    getMaxSortOrder(){
      let sortOrder = 0;
      this.evlRstDialogData.map(e => {
        if(e.LM_EVA_RST_OD > sortOrder){
          sortOrder = e.LM_EVA_RST_OD;
        }
      })
      return parseInt(sortOrder);
    },

    preView(){
      this.selectRtnPreLmVe();
      this.showDialog('M410101P06');
    },
    successAlert(){
      this.common_alert('정상처리 되었습니다.', 'done')
      //   this.$store.commit("alertStore/openAlertDialog", {
      //   alertDialogToggle: true,
      //   msg: '정상처리 되었습니다.',
      //   iconClass: 'svg-done-lg',
      //   type: 'default'
      // })
    },
    errorAlert(){
      this.common_alert('시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.', 'error')
      // this.$store.commit("alertStore/openAlertDialog", {
      //   alertDialogToggle: true,
      //   msg: '시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.',
      //   iconClass: 'svg-error-lg',
      //   type: 'default'
      // })
    },
    warningAlert(text){
      let alertText = '시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.';

      // if(text != null && text != ''){
      if(!this.mixin_isEmpty(text)){
        alertText = text;
      }
      this.common_alert(alertText, 'error')
      // this.$store.commit("alertStore/openAlertDialog", {
      //   alertDialogToggle: true,
      //   msg: alertText,
      //   iconClass: 'svg-error-lg',
      //   type: 'default'
      // })
    },

    isActiveRow(item){
      return item.LM_EVA_ID == this.selectedEvlPaper.LM_EVA_ID? 'active':'';
    },

    isActiveRow2(item) {
      return item.LM_QS_ID === this.selectedTbl2 ? 'active' : '';
    },

    isActiveRow3(item) {
      return item.LM_QS_ID === this.selectedTbl3 ? 'active' : '';
    },

    clickTblRow2(item, row){
      this.selectedTbl2 = item.LM_QS_ID;
    },

    clickTblRow3(item, row){
      this.selectedTbl3 = item.LM_QS_ID;
    },
  },
  computed: {

  },
}
</script>

<style>


</style>
