<template>
  <v-app>
    <LayoutTop @drawer="drawer = !drawer"></LayoutTop>
    <v-main>
      <LayoutMdi></LayoutMdi>
      <LayoutLeft></LayoutLeft>
      <v-fade-transition mode="out-in">
        <LayoutContent></LayoutContent>
      </v-fade-transition>
      <!-- <LayoutRight></LayoutRight> -->
      <!--<LayoutFooter></LayoutFooter>-->
      <LayoutMenu v-model="drawer"></LayoutMenu>

    </v-main>
  </v-app>
</template>

<script>
import LayoutTop from "./TopArea";
import LayoutMdi from "./MdiArea";
import LayoutLeft from "./LeftArea";
// import LayoutRight from "./RightArea";
import LayoutFooter from "./FooterArea";
import LayoutContent from "./ContentsArea";
import LayoutMenu from "./MenuArea";

export default {
  name: "MainLayout",
  components: {
    LayoutTop,
    LayoutMdi,
    LayoutLeft, // @modi
    // LayoutRight, // @modi
    LayoutFooter,
    LayoutContent,
    LayoutMenu,
  },
  data: () => ({
    drawer: false,
  }),
  methods: {

  }
};
</script>

<style></style>
