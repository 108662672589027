<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">메시지설정</h1>
    <div class="box-wrap d-flex">
      <div class="box-ct">
        <div class="form-inp sm">
          <v-select
              :items="aspDataDropItem"
              item-text="CD_NM"
              item-value="CD"
              outlined
              hide-details
              label=""
              placeholder="선택하세요"
              v-model="aspNewcustKey"
              v-on:change="getList"
              :readonly="RDOnly"
          >
          <template v-slot:label>
            회사구분
            <v-icon color="#EF9191">mdi-circle-small</v-icon>
          </template>
          </v-select>
        </div>
      </div>
    </div>
    <div class="box-wrap">
      <v-tabs v-if="aspNewcustKey != ''"
              class="tabs-form"
              slider-color="#6C6E7A"
              slider-size="3"
      >
        <v-tab @click="selectRtnCustAcptMsg">접수지연</v-tab>
        <v-tab @click="selectRtnLateRouteMsg">응대지연</v-tab>
        <v-tab @click="selectRtnCustNoRespMsg">고객무응답</v-tab>
        <v-tab-item>
          <v-card-text>
            <ul class="mb-10">
              <li>상담종료 메시지는 고객이 종료하였을 때 수신되는 메시지 입니다.</li>
              <li>접수지연 메시지는 고객이 문의유형을 선택하지 않을 때 발송되는 메시지 입니다.</li>
              <li>접수지연 메시지는 3차메시지 까지 설정 가능합니다.</li>
            </ul>
            <div>
              <div class="message_wrap type02"
                   v-for="(obj, i) in SYSTEM_MSG"
                   :key="obj.SYSTEM_MSG_ID"
              >
                <div class="message">
                  <div class="message_titleArea">
                    <div class="tit">
                      {{ obj.MSG_DESC }}
                    </div>
                    <div class="btn_area">
                      <!--<v-btn outlined small class="btn-default">삭제</v-btn>-->
                      <v-btn v-if="mixin_set_btn($options.name, 'btnMody1')" outlined class="btn-default ml-2" @click="openPopupUpdateSystemMsg(i)">수정</v-btn>
                    </div>
                  </div>
                  <div class="message_box_wrap">
                    <div class="message_box">
                      <div class="photo">
                        <i aria-hidden="true" class="v-icon notranslate material-icons theme--light svg-main-chat-channel"></i>
                      </div>
                      <span class="name">[고객채널명]</span>
                      <div class="txtBox" v-html="obj.MSG_CONTENT">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex flex-wrap justify-start">
                <div class="message_wrap type02"
                    v-for="(obj, i) in CUST_ACPT_MSG"
                    :key="obj.SYSTEM_MSG_ID"
                >
                  <div class="message">
                    <div class="message_titleArea">
                      <div class="tit">
                        {{ obj.MSG_CL_NAME }} {{ i + 1 }}차 <span class="num">{{ obj.MSG_TIME }}</span>분
                      </div>
                      <div class="btn_area">
                        <v-btn v-if="mixin_set_btn($options.name, 'btnDelete1')" outlined class="btn-etc" @click="confirmDeleteMsg(obj)" >삭제</v-btn>
                        <v-btn v-if="mixin_set_btn($options.name, 'btnMody2')" outlined class="btn-default ml-2" @click="openPopupUpdateCustAcptMsg(i)" >수정</v-btn>
                      </div>
                    </div>
                    <div class="message_box_wrap">
                      <div class="message_box">
                        <div class="photo">
                          <i aria-hidden="true" class="v-icon notranslate material-icons theme--light svg-main-chat-channel"></i>
                        </div>
                        <span class="name">[고객채널명]</span>
                        <div class="txtBox" v-html="obj.MSG_CONTENT" >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center">
                <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnAddRECPDelayMSG')" :disabled="CUST_ACPT_MSG.length >= 3" outlined large class="btn-point" @click="openPopupCreateMsg('03')" >접수지연 메세지 추가</v-btn>
              </div>
            </div>
          </v-card-text>
        </v-tab-item>
        <v-tab-item>
          <v-card-text>
            <ul class="mb-10">
              <li>상담준비 메시지는 고객이 문의유형을 선택하였을 때 발송되는 메시지 입니다.</li>
              <li>고객이 문의유형 선택 후 상담사 배정이 지연됐을 때 발송되는 메시지 입니다.</li>
              <li>최대 3차 메시지까지 설정 가능합니다.</li>
            </ul>
            <div>
              <div class="message_wrap type02"
                   v-for="(obj, i) in READY_INFORM_MSG"
                   :key="obj.SYSTEM_MSG_ID"
              >
                <div class="message">
                  <div class="message_titleArea">
                    <div class="tit">
                      {{ obj.MSG_DESC }}
                    </div>
                    <div class="btn_area">
                      <!--<v-btn outlined small class="btn-default">삭제</v-btn>-->
                      <v-btn v-if="mixin_set_btn($options.name, 'btnMody3')" outlined class="btn-default ml-2" @click="openPopupUpdateReadyInformMsg(i)">수정</v-btn>
                    </div>
                  </div>
                  <div class="message_box_wrap">
                    <div class="message_box">
                      <div class="photo">
                        <i aria-hidden="true" class="v-icon notranslate material-icons theme--light svg-main-chat-channel"></i>
                      </div>
                      <span class="name">[고객채널명]</span>
                      <div class="txtBox" v-html="obj.MSG_CONTENT" >
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="d-flex flex-wrap justify-start">
                <div class="message_wrap type02"
                    v-for="(obj, i) in LATE_ROUTE_MSG"
                    :key="obj.SYSTEM_MSG_ID"
                >
                  <div class="message">
                    <div class="message_titleArea">
                      <div class="tit">
                        {{ obj.MSG_CL_NAME }} {{ i + 1 }}차 <span class="num">{{ obj.MSG_TIME }}</span>분
                      </div>
                      <div class="btn_area">
                        <v-btn v-if="mixin_set_btn($options.name, 'btnDelete2')" outlined class="btn-etc" @click="confirmDeleteMsg(obj)" >삭제</v-btn>
                        <v-btn v-if="mixin_set_btn($options.name, 'btnMody4')" outlined class="btn-default ml-2" @click="openPopupUpdateLateRouteMsg(i)" >수정</v-btn>
                      </div>
                    </div>
                    <div class="message_box_wrap">
                      <div class="message_box">
                        <div class="photo">
                          <i aria-hidden="true" class="v-icon notranslate material-icons theme--light svg-main-chat-channel"></i>
                        </div>
                        <span class="name">[고객채널명]</span>
                        <div class="txtBox" v-html="obj.MSG_CONTENT" >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center">
                <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnAddNoDelayMSG')" :disabled="LATE_ROUTE_MSG.length >= 3" outlined large class="btn-point" @click="openPopupCreateMsg('01')">응대지연 메세지 추가</v-btn>
              </div>
            </div>
          </v-card-text>
        </v-tab-item>
        <v-tab-item>
          <v-card-text>
            <ul>
              <li>상담중 고객이 응답이 없을 때 발송되는 메시지 입니다.</li>
              <li>최대 3차 메시지까지 설정 가능합니다.</li>
            </ul>
            <div class="box-wrap col-4 mb-10">
              마지막 고객무응답 메시지 발송 후 자동종료 설정
              <v-radio-group v-model="CHAT_END.ENV_SET_VALUE" row hide-details @change="updateCustNorespChatend">
                <v-radio
                    name="CUST_NORESP_CHATEND"
                    label="ON"
                    value="Y"
                    on-icon="svg-rad-on"
                    off-icon="svg-rad-off"
                />
                <v-radio
                    name="CUST_NORESP_CHATEND"
                    label="OFF"
                    value="N"
                    on-icon="svg-rad-on"
                    off-icon="svg-rad-off"
                />
              </v-radio-group>
            </div>
            <div>
              <div class="message_wrap type02"
                   v-for="(obj, i) in CUST_NO_RESP_MSG"
                   :key="obj.SYSTEM_MSG_ID"
              >
                <div class="message">
                  <div class="message_titleArea">
                    <div class="tit">
                      {{ obj.MSG_CL_NAME }} {{ i + 1 }}차 <span class="num">{{ obj.MSG_TIME }}</span>분
                    </div>
                    <div class="btn_area">
                      <v-btn v-if="mixin_set_btn($options.name, 'btnDelete3')" outlined class="btn-etc" @click="confirmDeleteMsg(obj)" >삭제</v-btn>
                      <v-btn v-if="mixin_set_btn($options.name, 'btnMody5')" outlined class="btn-default ml-2" @click="openPopupUpdateCustNoRespMsg(i)" >수정</v-btn>
                    </div>
                  </div>
                  <div class="message_box_wrap">
                    <div class="message_box">
                      <div class="photo">
                        <i aria-hidden="true" class="v-icon notranslate material-icons theme--light svg-main-chat-channel"></i>
                      </div>
                      <span class="name">[고객채널명]</span>
                      <div class="txtBox" v-html="obj.MSG_CONTENT" >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center">
                <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnAddNoRECPMSG')" :disabled="CUST_NO_RESP_MSG.length >= 3" outlined large class="btn-point" @click="openPopupCreateMsg('02')">고객무응답 메세지 추가</v-btn>
              </div>
            </div>
          </v-card-text>
        </v-tab-item>
      </v-tabs>
      <v-dialog v-if="dialogM810601P01" max-width="655" persistent v-model="dialogM810601P01"
        content-class="square-modal min-auto">
        <dialog-M810601P01
            :param="M810601P01Param"
            p01
            @hide="hideDialog('M810601P01')"
        >
        </dialog-M810601P01>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import {mixin} from "@/mixin/mixin";
import DialogM810601P01 from "./M810601P01.vue";

export default {
  name: "MENU_M810601", //name은 'MENU_' + 파일명 조합
  components: {
    DialogM810601P01,
  },
  mixins:[mixin],
  data() {
    return {
      aspNewcustKey: '',
      aspDataDropItem: [],
      SYSTEM_MSG: [],
      CUST_ACPT_MSG: [],
      READY_INFORM_MSG: [],
      LATE_ROUTE_MSG: [],
      CHAT_END: {},
      CUST_NO_RESP_MSG: [],
      dialogM810601P01: false,
      // 상세
      M810601P01Param: {
        headerTitle: '',
        data: {},
        userId: this.$store.getters['userStore/GE_USER_ROLE'].userId,
      },
      RDOnly:false,
    };
  },
  created() {
    //this.common_getAspDataS(this.getAspDataSCallBack);
    this.aspDataDropItem = this.$store.getters['userStore/GE_USER_COMPANY'];
    //this.aspNewcustKey = this.aspDataDropItem[this.aspDataDropItem.length-1].CD;
    //this.getList();
  },
  methods: {
    restoreXSS2(strText) {
      //값존재유무 체크
      if (strText === "" || strText == null) {
        return strText;
      }
      strText = strText.toString();

      //문자열 길이가 4이상일 경우만 처리
      if (strText.length <= 3) {
        return strText;
      }
      strText = strText.replaceAll("&lt;", "<");
      strText = strText.replaceAll("&gt;", ">");
      strText = strText.replaceAll("&#40;", "(");
      strText = strText.replaceAll("&#41;", ")");
      strText = strText.replaceAll("&#91;", "[");
      strText = strText.replaceAll("&#93;", "]");
      strText = strText.replaceAll("&quot;", '"');
      strText = strText.replaceAll("&apos;", "'");
      //strText = strText.replaceAll("<br>", "\r");
      strText = strText.replaceAll("<p>", "<br>");

      return strText;
    },
    openPopupUpdateSystemMsg(i) {
      let obj = this.SYSTEM_MSG[i];
      obj["ASP_NEWCUST_KEY"] = this.aspNewcustKey;
      obj["strDivID"] = "divHelloMsg";
      this.M810601P01Param.data = obj;
      this.M810601P01Param.headerTitle = "메시지 수정";
      this.M810601P01Param.CRUD = "U";
      this.showDialog('M810601P01');
    },
    async deleteMsg(obj){
      let requestData = {
        headers: {},
        sendData:{}
      };
      // header 세팅
      requestData.headers["URL"] = "/api/chat/setting/message-manage/delete";
      requestData.headers["SERVICE"] = "chat.setting.message-manage";
      requestData.headers["METHOD"] = "delete";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = false;

      // sendData 세팅
      requestData.sendData["SYSTEM_MSG_ID"] = obj.SYSTEM_MSG_ID
      requestData.sendData["ASP_NEWCUST_KEY"] = this.aspNewcustKey;

      let response = await this.common_postCall(requestData);

      if(!response.HEADER.ERROR_FLAG){
        this.common_alert("정상적으로 삭제되었습니다.");
      }else{
        this.common_alert("시스템 에러가 발생하였습니다.", "error");
      }
      this.getList();
    },
    async confirmDeleteMsg(obj){
      this.common_confirm("정말 삭제하시겠습니까?", this.deleteMsg, obj, null, null,"error");
    },
    openPopupUpdateCustAcptMsg(i) {
      let obj = this.CUST_ACPT_MSG[i];
      obj["ASP_NEWCUST_KEY"] = this.aspNewcustKey;
      obj["strDivID"] = "divCnslMsg";
      this.M810601P01Param.data = obj;
      this.M810601P01Param.headerTitle = "메시지 수정";
      this.M810601P01Param.CRUD = "U";
      this.showDialog('M810601P01');
    },
    openPopupUpdateReadyInformMsg(i){
      let obj = this.READY_INFORM_MSG[i];
      obj["ASP_NEWCUST_KEY"] = this.aspNewcustKey;
      obj["strDivID"] = "divHelloMsg";
      this.M810601P01Param.data = obj;
      // 신규
      this.M810601P01Param.headerTitle = "메시지 수정";
      this.M810601P01Param.CRUD = "U";
      this.showDialog('M810601P01');
    },
    openPopupUpdateLateRouteMsg(i){
      let obj = this.LATE_ROUTE_MSG[i];
      obj["ASP_NEWCUST_KEY"] = this.aspNewcustKey;
      obj["strDivID"] = "divCnslMsg";
      this.M810601P01Param.data = obj;
      // 신규
      this.M810601P01Param.headerTitle = "메시지 수정";
      this.M810601P01Param.CRUD = "U";
      this.showDialog('M810601P01');
    },
    openPopupUpdateCustNoRespMsg(i){
      let obj = this.CUST_NO_RESP_MSG[i];
      obj["ASP_NEWCUST_KEY"] = this.aspNewcustKey;
      obj["strDivID"] = "divCnslMsg";
      this.M810601P01Param.data = obj;
      // 신규
      this.M810601P01Param.headerTitle = "메시지 수정";
      this.M810601P01Param.CRUD = "U";
      this.showDialog('M810601P01');
    },
    async updateCustNorespChatend(){
      //CHAT_END.ENV_SET_VALUE
      let requestData = {
        headers: {},
        sendData:{}
      };
      // header 세팅
      requestData.headers["URL"] = "/api/chat/setting/manage/modify";
      requestData.headers["SERVICE"] = "chat.setting.manage";
      requestData.headers["METHOD"] = "modify";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = false;

      // sendData 세팅
      requestData.sendData = this.CHAT_END;
      requestData.sendData["PROC_ID"] = this.$store.getters['userStore/GE_USER_ROLE'].userId;
      requestData.sendData["ASP_NEWCUST_KEY"] = this.aspNewcustKey;

      let response = await this.common_postCall(requestData);

      if(typeof (response) === "string"){
        this.common_alert("시스템 에러가 발생하였습니다.", "error");
      }else{
        if(!response.HEADER.ERROR_FLAG){
          this.common_alert("정상처리 되었습니다.");
        }
      }
    },
    async getList(){
      let requestData = {
        headers: {},
        sendData:{}
      };
      // header 세팅
      requestData.headers["URL"] = "/api/chat/setting/message-manage/rcept-delay/inqire";
      requestData.headers["SERVICE"] = "chat.setting.message-manage.rcept-delay";
      requestData.headers["METHOD"] = "inqire";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = false;

      // sendData 세팅
      requestData.sendData["MSG_CL"] = "03";
      requestData.sendData["SYSTEM_MSG_ID"] = "20180403040958193MSG34895";
      requestData.sendData["ASP_NEWCUST_KEY"] = this.aspNewcustKey;

      this.$store.dispatch("commonStore/AC_COMMON_PROGRESS_BAR", true);
      let response03 = await this.common_postCall(requestData, false);

      // 응대지연
      requestData.headers["URL"] = "/api/chat/setting/message-manage/rspons-delay/inqire";
      requestData.headers["SERVICE"] = "chat.setting.message-manage.rspons-delay";

      requestData.sendData["MSG_CL"] = "01";
      requestData.sendData["SYSTEM_MSG_ID"] = "20180403040958193MSG34892";
      let response01 = await this.common_postCall(requestData, false);

      // 고객무응답
      requestData.headers["URL"] = "/api/chat/setting/message-manage/cstmr-nthg-rspons/inqire";
      requestData.headers["SERVICE"] = "chat.setting.message-manage.cstmr-nthg-rspons";
      requestData.sendData["MSG_CL"] = "02";
      delete requestData.sendData["SYSTEM_MSG_ID"];
      let response02 = await this.common_postCall(requestData, false);

      this.$store.dispatch("commonStore/AC_COMMON_PROGRESS_BAR", false);

      this.selectRtnCustAcptMsgCallback(response03);
      this.selectRtnLateRouteMsgCallback(response01);
      this.selectRtnCustNoRespMsgCallback(response02);
    },
    // 접수지연 (MSG_CL:03, SYSTEM_MSG_ID:20180403040958193MSG34895)
    async selectRtnCustAcptMsg(){
      if(this.aspNewcustKey === ""){
        this.common_alert("회사를 선택해 주세요.", "error");
        return;
      }
      let requestData = {
        headers: {},
        sendData:{}
      };

      // header 세팅
      requestData.headers["URL"] = "/api/chat/setting/message-manage/rcept-delay/inqire";
      requestData.headers["SERVICE"] = "chat.setting.message-manage.rcept-delay";
      requestData.headers["METHOD"] = "inqire";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = false;

      // sendData 세팅
      requestData.sendData["MSG_CL"] = "03";
      requestData.sendData["SYSTEM_MSG_ID"] = "20180403040958193MSG34895";
      requestData.sendData["ASP_NEWCUST_KEY"] = this.aspNewcustKey;
      let response = await this.common_postCall(requestData);
      this.selectRtnCustAcptMsgCallback(response);
    },
    selectRtnCustAcptMsgCallback(data){
      this.SYSTEM_MSG = data.SYSTEM_MSG;
      this.CUST_ACPT_MSG = data.CUST_ACPT_MSG;
      for( let i in this.SYSTEM_MSG ){
        this.SYSTEM_MSG[i].MSG_CONTENT = this.restoreXSS2(this.SYSTEM_MSG[i].MSG_CONTENT);
      }
      for( let i in this.CUST_ACPT_MSG ){
        this.CUST_ACPT_MSG[i].MSG_CONTENT = this.restoreXSS2(this.CUST_ACPT_MSG[i].MSG_CONTENT);
      }
    },
    // 응대지연 (MSG_CL:01, SYSTEM_MSG_ID:20180403040958193MSG34892)
    async selectRtnLateRouteMsg(){
      if(this.aspNewcustKey === ""){
        this.common_alert("회사를 선택해 주세요.", "error");
        return;
      }
      let requestData = {
        headers: {},
        sendData:{}
      };

      // header 세팅
      requestData.headers["URL"] = "/api/chat/setting/message-manage/rspons-delay/inqire";
      requestData.headers["SERVICE"] = "chat.setting.message-manage.rspons-delay";
      requestData.headers["METHOD"] = "inqire";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = false;

      // sendData 세팅
      requestData.sendData["MSG_CL"] = "01";
      requestData.sendData["SYSTEM_MSG_ID"] = "20180403040958193MSG34892";
      requestData.sendData["ASP_NEWCUST_KEY"] = this.aspNewcustKey;
      let response = await this.common_postCall(requestData);
      this.selectRtnLateRouteMsgCallback(response);
    },
    selectRtnLateRouteMsgCallback(data){
      this.READY_INFORM_MSG = data.READY_INFORM_MSG;
      this.LATE_ROUTE_MSG = data.LATE_ROUTE_MSG;
      for( let i in this.READY_INFORM_MSG ){
        this.READY_INFORM_MSG[i].MSG_CONTENT = this.restoreXSS2(this.READY_INFORM_MSG[i].MSG_CONTENT);
      }
      for( let i in this.LATE_ROUTE_MSG ){
        this.LATE_ROUTE_MSG[i].MSG_CONTENT = this.restoreXSS2(this.LATE_ROUTE_MSG[i].MSG_CONTENT);
      }
    },
    // 고객무응답 (MSG_CL:02)
    async selectRtnCustNoRespMsg(){
      if(this.aspNewcustKey === ""){
        this.common_alert("회사를 선택해 주세요.", "error");
        return;
      }

      let requestData = {
        headers: {},
        sendData:{}
      };

      // header 세팅
      requestData.headers["URL"] = "/api/chat/setting/message-manage/cstmr-nthg-rspons/inqire";
      requestData.headers["SERVICE"] = "chat.setting.message-manage.cstmr-nthg-rspons";
      requestData.headers["METHOD"] = "inqire";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = false;

      // sendData 세팅
      requestData.sendData["MSG_CL"] = "02";
      requestData.sendData["ASP_NEWCUST_KEY"] = this.aspNewcustKey;
      let response = await this.common_postCall(requestData);
      this.selectRtnCustNoRespMsgCallback(response);
    },
    selectRtnCustNoRespMsgCallback(data){
      let chatEndList = data.CHAT_END;
      for(let i in chatEndList){
        if(chatEndList[i].ENV_CD === "CUST_NORESP_CHATEND"){
          this.CHAT_END = chatEndList[i];
          break;
        }
      }
      this.CUST_NO_RESP_MSG = data.CUST_NO_RESP_MSG;
      for( let i in this.CUST_NO_RESP_MSG ){
        this.CUST_NO_RESP_MSG[i].MSG_CONTENT = this.restoreXSS2(this.CUST_NO_RESP_MSG[i].MSG_CONTENT);
      }
    },
    openPopupCreateMsg(MSG_CL){
      // 신규
      this.M810601P01Param = {
        headerTitle: '',
            data: {},
        userId: this.$store.getters['userStore/GE_USER_ROLE'].userId,
      }
      this.M810601P01Param.headerTitle = "메시지 추가";
      this.M810601P01Param.CRUD = "C";
      this.M810601P01Param.data.ASP_NEWCUST_KEY = this.aspNewcustKey;
      this.M810601P01Param.data.MSG_CL = MSG_CL;
      this.M810601P01Param.data.MSG_TIME = 1;
      this.M810601P01Param.data.MSG_CONTENT = "";

      this.showDialog('M810601P01');
    },
    allowedStep: (m) => m % 10 === 0,
    showDialog(type) {
      this[`dialog${type}`] = true;
    },
    hideDialog(type) {
      this[`dialog${type}`] = false;
      this.getList();
    },
    submitDialog(type) {
      this[`dialog${type}`] = false;
    },
    resetDialog() {
      console.log("reset");
    },
    unlockDialog() {
      console.log("unlock");
    },

  },
  computed: {},

  mounted(){

    let chk = this.mixin_check_AspCustKey_number();
    if(chk == 'Y'){
      this.aspNewcustKey = this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD;
      this.RDOnly = true;
    } else {
      this.RDOnly = false;
    }
  },
};
</script>

<style scoped>
.message_wrap{display:flex; margin-bottom:20px;}
.message_wrap.type02{width: 33.3333%}
.message_wrap .message{width:100%; margin:0 0 20px 0; padding: 0 10px;}
.message_wrap.type02:nth-child(3n) .message{}

.message_titleArea:after{ clear: both; content: "."; display: block; height: 0; visibility: hidden; font-size: 0;}
.message_titleArea{margin-bottom:5px;}
.message_titleArea .tit{float:left; font-size:16px; font-weight:600;}
.message_titleArea .btn_area{float:right; font-size:16px; font-weight:600;}
.message_box_wrap{padding:20px; background-color:#c8cdde;}
.message_box{position:Relative;  padding-left:50px;}
.message_box .photo{position:absolute; left:0; top:0;}
.message_box .photo .v-icon{width:40px; height:40px; background-size:100%;}
.message_box .name{margin-bottom:5px; font-size:12px; display:block; font-weight:600;}
.message_box .txtBox{padding:10px 12px; border-radius:0 20px 20px; background-color:#fff; max-width:calc(100% - 60px); font-size:13px; box-sizing:border-box;}
.message_titleArea .num{display:inline-block; width:24px; height:24px; line-height:24px; text-align:center; background-color:#f2da91; color:#f0182e; font-size:14px; font-weight:600; border-radius:50%;}
</style>
