<template xmlns="http://www.w3.org/1999/html">
  <div class="layout-subcontainer">
    <h1 class="tit-h1">사용자별 권한관리</h1>
    <!-- tabs -->
    <v-tabs class="tabs-form mt-2" slider-color="#6C6E7A" slider-size="3">
      <v-tab>사용자별 권한관리</v-tab>
      <!-- <v-tab>권한그룹별 상담사설정</v-tab> -->

      <!-- tab : 사용자별 권한관리 -->
      <v-tab-item>
        <div class="box-wrap mt-0">
          <div class="box-ct d-flex">
            <div>
              <div class="">

                <v-text-field
                  class="form-inp ml-2"
                  name="name"
                  outlined
                  hide-details
                  v-model="tab2_userId"
                  @keyup.enter="getList2OrNot"
                >
                  <template v-slot:label>
                    ID
                    <v-icon color="#EF9191">mdi-circle-small</v-icon>
                  </template>
                </v-text-field>

                <v-text-field
                  class="form-inp ml-2"
                  name="name"
                  label="이름"
                  outlined
                  hide-details
                  v-model="tab2_userNm"
                  @keyup.enter="getList2OrNot"
                >
                </v-text-field>
                
                <v-text-field
                  class="form-inp ml-2"
                  name="name"
                  label="닉네임"
                  id="id"
                  outlined
                  hide-details
                  v-model="tab2_userNick"
                  @keyup.enter="getList2OrNot"
                >
                  <template v-slot:append>
                    <v-btn
                      depressed
                      icon
                      x-small
                      @click="btnUser"
                      class="fomr-inp--btn svg-search"
                    >
                    </v-btn>
                  </template>
                </v-text-field>
                <v-btn 
                  dark small
                  class="btn-main-refresh pa-0 ml-2" min-width="32px" height="32px"
                  @click="initUser"
                  plain
                >
                  <v-icon small class="svg-main-refresh"></v-icon>
                </v-btn>

              </div>
            </div>
            <div class="ml-auto align-self-center" v-on:click="getList2OrNot">
              <v-btn outlined class="btn-etc2" id="btnSearch">조회</v-btn>
            </div>
          </div>
        </div>
        <!-- 미할당그룹 -->
        <div class="box-wrap">
          <h2 class="tit-h2 d-flex">
            <div class="ml-auto">
              <v-btn outlined class="btn-point ml-2" v-on:click="submitGroupConfirm" id="btnSave_user">저장</v-btn>
            </div>
          </h2>
          <div class="box-ct d-flex">
            <div style="width: 47%">
              <span>미할당그룹</span>
              <v-data-table
                dense
                :headers="divGridAuthGroupNoAlloc"
                :items="divGridAuthGroupNoAllocDataText"
                :items-per-page="itemsPerPage"
                item-key="ATRT_GROUP_ID"
                :page.sync="page"
                hide-default-footer
                fixed-header
                show-select
                single-select
                class="grid-default"
                height="570px"
                @page-count="pageCount = $event"
                no-data-text="검색된 결과가 없습니다."
                v-model="selectedDivGridAuthGroupNoAllocList"
                :item-class="isActiveRow2"
                v-on:click:row="getGrid1ListSel"
              >
              </v-data-table>
              <div class="grid-paging text-center pt-2">
              </div>
            </div>
            <div class="btn-arrow-wrap-vertical">
              <div class="">
                <v-btn class="btn-arrow" plain small @click="moveLeft_tab2">
                  <v-icon small class="svg-arrow-left"></v-icon>
                </v-btn>
                <v-btn class="btn-arrow" plain small @click="moveRight_tab2">
                  <v-icon small class="svg-arrow-right"></v-icon>
                </v-btn>
              </div>
            </div>
            <div class="flex-grow-1">
              <span>할당그룹</span>
              <v-data-table
                dense
                :headers="divGridAuthGroupAlloc"
                :items="divGridAuthGroupAllocDataText"
                :items-per-page="itemsPerPage"
                item-key="ATRT_GROUP_ID"
                :page.sync="page"
                hide-default-footer
                show-select
                single-select
                fixed-header
                class="grid-default "
                height="570px"
                @page-count="pageCount = $event"
                no-data-text="검색된 결과가 없습니다."
                v-model="selectedDivGridAuthGroupAllocList"
                :item-class="isActiveRow2"
                v-on:click:row="getGrid1ListSel"
              >
              </v-data-table>
            </div>
          </div>
          <div class="">
            <v-dialog
                max-width="1400"
                max-height="1200"
                v-model="DialogMP02"
                content-class="square-modal"
            >
              <dialog-MP02
                  headerTitle="고객정보"
                  @hide="hideDialog('MP02')"
                  :userInfo="userInfo"
                  @input="userInfo = $event"
                  @selectUser="[hideDialog('MP02'), setUser()]"
              >
              </dialog-MP02>
              <template v-slot:body></template>
            </v-dialog>
          </div>
        </div>
        <!--// 미할당스킬 -->
      </v-tab-item>

      <v-tab-item>
        <div class="box-wrap mt-0">
          <div class="box-ct d-flex">
            <div>
              <div class="">
                <div class="form-inp sm">
                  <v-select
                      :items="dropItems"
                      item-text="CD_NM"
                      item-value="CD"
                      outlined
                      hide-details
                      label="권한그룹"
                      placeholder="선택"
                      v-on:change="getGroupList"
                      v-model="atrtgroupId"
                  >
                    <template v-slot:label>
                      권한그룹
                      <v-icon color="#EF9191">mdi-circle-small</v-icon>
                    </template>
                  </v-select>
                </div>

                <v-text-field
                    class="form-inp ml-2"
                    name="name"
                    label="사용자ID"
                    v-model="tab_userId"
                    outlined
                    hide-details
                >
                </v-text-field>
                <v-text-field
                    class="form-inp ml-2"
                    name="name"
                    label="사용자이름"
                    v-model="userNm"
                    outlined
                    hide-details
                >
                </v-text-field>
                <v-text-field
                    class="form-inp ml-2"
                    name="name"
                    label="사용자닉네임"
                    v-model="userNick"
                    outlined
                    hide-details
                >
                </v-text-field>
              </div>
            </div>
            <div class="ml-auto align-self-center">
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch1')" outlined class="btn-etc2"  v-on:click="getGroupList">조회</v-btn>
            </div>
          </div>
        </div>

        <!-- 미할당사용자 -->
        <div class="box-wrap">
          <h2 class="tit-h2 d-flex">
            <div class="ml-auto">
            </div>
          </h2>
          <div class="box-ct d-flex">
            <div style="width: 47%">
              <h2 class="tit-h2 d-flex">
                미할당사용자</h2>
              <v-data-table
                  dense
                  :headers="divGridAuthUserNoAlloc"
                  :items="divGridAuthUserNoAllocDataText"
                  :items-per-page="30"
                  item-key="USER_ID"
                  :page.sync="divGridAuthUserNoAllocPage"
                  hide-default-footer
                  show-select
                  fixed-header
                  class="grid-default"
                  height="500px"
                  @page-count="divGridAuthUserNoAllocPageCount = $event"
                  no-data-text="검색된 결과가 없습니다."
                  v-model="selectedDivGridAuthUserNoAllocList"
                  :item-class="isActiveRow1"
                  v-on:click:row="getGrid1ListSel"
              >
              </v-data-table>
              <div class="grid-paging text-center pt-2">
                <v-pagination
                    v-model="divGridAuthUserNoAllocPage"
                    :length="divGridAuthUserNoAllocPageCount"
                    :total-visible="divGridAuthUserNoAllocTotalVisible"
                    next-icon="svg-paging-next"
                    prev-icon="svg-paging-prev"
                ></v-pagination>
              </div>
            </div>
            <div class="btn-arrow-wrap-vertical">
              <div class="">
                <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDelArrow1')" class="btn-arrow" plain small @click="moveLeftDeleteConfirm">
                  <v-icon small class="svg-arrow-left"></v-icon>
                </v-btn>
                <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnAddArrow1')" class="btn-arrow" plain small @click="moveRightSaveConfirm">
                  <v-icon small class="svg-arrow-right"></v-icon>
                </v-btn>
              </div>
            </div>
            <div class="flex-grow-1">

              <h2 class="tit-h2 d-flex">
                할당사용자</h2>
              <v-data-table
                  dense
                  :headers="divGridAuthUserAlloc"
                  :items="divGridAuthUserAllocDataText"
                  :items-per-page="30"
                  item-key="USER_ID"
                  :page.sync="page"
                  hide-default-footer
                  show-select
                  fixed-header
                  class="grid-default"
                  height="500px"
                  @page-count="pageCount = $event"
                  no-data-text="검색된 결과가 없습니다."
                  v-model="selectedDivGridAuthUserAllocList"
                  :item-class="isActiveRow1"
                  v-on:click:row="getGrid1ListSel"
              >
              </v-data-table>
              <div class="grid-paging text-center pt-2">

                <p class="caption text-right">
                  <strong class="clr-error">{{ divGridAuthUserAllocDataText.length }}</strong
                  >건의 할당 사용자가 검색되었습니다.
                </p>
              </div>
            </div>
          </div>
        </div>
        <!--// 미할당스킬 -->
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
  import api from "@/store/apiUtil";
  import DialogMP02 from "./MP02.vue";

  export default {
    name: "MENU_M810705", //name은 'MENU_' + 파일명 조합
    components: {
      DialogMP02
    },
    data() {
      return {
        DialogMP02:false,
        HEADERResult:'',
        DATAResult:'',
        HEADER_SERVICE:'',
        HEADER_TYPE:'BIZ_SERVICE',
        HEADER_GRID_ID:'',
        HEADER_ROW_CNT:'',
        HEADER_PAGES_CNT:'',
        userInfo: {},
        headers:{},
        sendData: {},
        URLData:'',
        userNick:'',
        userNm:'',
        ASP_CUST_KEY:'',
        aspCustKey:'',
        tab_userId:'',
        tab2_userId:'',
        tab2_userNm:'',
        tab2_userNick:'',
        regDttm:'',
        atrtgroupId:'',
        atrtgroupNm:'',
        nullAble:'',
        dataFlag:'',
        userAttra:'',
        userAttrb:'',
        userAttrc:'',
        userAttrd:'',
        mngrYn:'',
        etcInfo01:'',
        etcInfo02:'',
        etcInfo02Nm:'',
        groupXpln:'',
        ASP_NEWCUST_KEY:'',
        custItems:[],

        dialogM810401P01: false,
        dialogM810401P02: false,
        dialogM810401P03: false,
        menu1: false,
        menu2: false,
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
        dates: [
          new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
              .toISOString()
              .substr(0, 10),
          new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
              .toISOString()
              .substr(0, 10),
        ],
        radioGroup: [
          {
            text: "사용자명",
          },
          {
            text: "최근접속일시",
          },
        ],
        radioGroupSelected: "사용자명",
        dropItems: [],
        divGridAuthUserNoAlloc: [
          {
            text: "사용자ID",
            value: "USER_ID",
            align: "left",
            sortable: true,
          },
          {
            text: "사용자명",
            value: "USER_NM",
            align: "left",
            sortable: true,
          },
          {
            text: "사용자닉네임",
            value: "USER_NICK",
            align: "left",
            sortable: true,
          },
          {
            text: "등록일시",
            value: "REG_DTTM",
            align: "center",
            sortable: true,
          },
        ],
        divGridAuthUserAlloc: [
          {
            text: "사용자ID",
            value: "USER_ID",
            align: "left",
            sortable: true,
          },
          {
            text: "사용자명",
            value: "USER_NM",
            align: "left",
            sortable: true,
          },
          {
            text: "사용자닉네임",
            value: "USER_NICK",
            align: "left",
            sortable: true,
          },
          {
            text: "등록일시",
            value: "REG_DTTM",
            align: "center",
            sortable: true,
          },
        ],
        divGridAuthUserNoAllocDataText: [],
        divGridAuthUserNoAllocPage: 1,
        divGridAuthUserNoAllocPageCount: 0,
        divGridAuthUserNoAllocItemsPerPage: 100,
        divGridAuthUserNoAllocTotalVisible: 10,
        divGridAuthUserAllocDataText:[],
        selectedDivGridAuthUserNoAllocList:[],
        selectedDivGridAuthUserAllocList:[],
        divGridAuthGroupNoAlloc:[
          {
            text: "그룹명",
            value: "ATRT_GROUP_NM",
            align: "center",
            sortable: true,
          },
          {
            text: "사용자구분",
            value: "ETC_INFO02_NM",
            align: "center",
            sortable: true,
          },
        ],
        divGridAuthGroupAlloc:[
          {
            text: "그룹명",
            value: "ATRT_GROUP_NM",
            align: "center",
            sortable: true,
          },
          {
            text: "그룹정보",
            value: "GROUP_XPLN",
            align: "center",
            sortable: true,
          },
          {
            text: "관리자여부",
            value: "MNGR_YN",
            align: "center",
            sortable: true,
          },
          {
            text: "우선순위",
            value: "ETC_INFO01",
            align: "center",
            sortable: true,
          },
          {
            text: "사용자구분",
            value: "ETC_INFO02_NM",
            align: "center",
            sortable: true,
          },
          {
            text: "기타03",
            value: "ETC_INFO03",
            align: "center",
            sortable: true,
          },
        ],
        detailData: {},
        divGridAuthGroupNoAllocDataText:[], //미할당그룹
        divGridAuthGroupAllocDataText:[], //할당그룹
        selectedDivGridAuthGroupAllocList:[],
        selectedDivGridAuthGroupNoAllocList:[],
        page: 1,
        pageCount: 0,
        itemsPerPage: -1,
        totalVisible: 10,
        textareaRules: [(v) => v.length <= 200 || "Max 200 characters"],
        textareaValue: "200자 제한!",
      };
    },
    methods: {

      groupCodeInqire(){
        this.headers = this.initHeaders;
        this.URLData = "/api/setting/system/menu-author-manage/author-group-code/inqire";
        this.headers["SERVICE"] = "setting.system.menu-author-manage.author-group-code";
        this.headers["METHOD"] = "inqire";
        this.headers["ASYNC"] = false;
        this.result();
      },

      groupCodeInqireCallBack(response){
        let header = response.HEADER;
        let data = response.DATA;
        this.dropItems = data;
      },

      startDate(e) {
        this.dates[0] = e;
      },

      endDate(e) {
        this.dates[1] = e;
      },

      submitDialog(type) {
        this[`dialog${type}`] = false;
      },

      initUser(){
        this.tab2_userId = '';
        this.tab2_userNm = '';
        this.tab2_userNick = '';
      },

      showDialog(menu) {
        this[`Dialog${menu}`] = true;
      },

      hideDialog(menu) {
        this[`Dialog${menu}`] = false;
      },

      btnUser(){
        
        var user = {
          NO_CUST_KEY:'false'
          , USER_ID:this.tab2_userId
          , USER_NM:this.tab2_userNm
          , USER_NICK:this.tab2_userNick
        };

        this.userInfo = user;
        this.showDialog('MP02');
      },

      setUser(){
        this.tab2_userId = this.userInfo.USER_ID;
        this.tab2_userNm = this.userInfo.USER_NM;
        this.tab2_userNick = this.userInfo.USER_NICK;
      },

      getGrid1ListSel(item){
        this.detailData = JSON.parse(JSON.stringify(item));
      },

      isActiveRow1(item){
        return item.USER_ID== this.detailData.USER_ID? 'active':'';
      },

      isActiveRow2(item){
        return item.ATRT_GROUP_ID== this.detailData.ATRT_GROUP_ID? 'active':'';
      },

      getList2OrNot(){
        if(this.mixin_isEmpty(this.tab2_userId)) {
          this.common_alert('사용자 아이디를 입력해 주세요.', 'chk');
          return;
        } else {
          this.divGridAuthGroupNoAllocDataText = [];
          this.divGridAuthGroupAllocDataText = [];
          this.getGroupList2();
        }
      },

      async getGroupList2(){// 사용자별 권한관리 tab
        await this.getDivGridAuthGroupAlloc(); // 할당그룹
      },

      async getDivGridAuthGroupAlloc(){
        this.divGridAuthGroupAllocDataText = [];
        this.headers = this.initHeaders;
        this.URLData = "/api/setting/system/menu-author-manage/asgn-info-by-agent/inqire";
        this.headers["SERVICE"] ="setting.system.menu-author-manage.asgn-info-by-agent";
        this.headers["METHOD"] = "inqire";
        this.headers["ASYNC"] = false;
        this.headers["GRID_ID"] = "divGridAuthGroupAlloc";

        this.sendData = {};
        this.sendData["USER_ID"] = this.tab2_userId;
        this.sendData["USER_NM"] = this.tab2_userNm;
        this.sendData["USER_NICK"] = this.tab2_userNick;
        this.sendData["NULLABLE"] = "IS NOT NULL";

        this.result();
      },

      async getDivGridAuthGroupNoAlloc(){
        this.divGridAuthGroupNoAllocDataText = [];
        this.headers = this.initHeaders;
        this.URLData = "/api/setting/system/menu-author-manage/asgn-info-by-agent/inqire";
        this.headers["SERVICE"] ="setting.system.menu-author-manage.asgn-info-by-agent";
        this.headers["METHOD"] = "inqire";
        this.headers["ASYNC"] = false;
        this.headers["GRID_ID"] = "divGridAuthGroupNoAlloc";

        this.sendData = {};
        this.sendData["USER_ID"] = this.tab2_userId;
        this.sendData["USER_NM"] = this.tab2_userNm;
        this.sendData["USER_NICK"] = this.tab2_userNick;
        this.sendData["NULLABLE"] = "IS NULL";

        this.result();
      },

      getDivGridAuthGroupAllocCallback(response){
        let header = response.HEADER;

        if(header.ERROR_FLAG) {
          if(this.mixin_isEmpty(header.ERROR_MSG)) {
            this.common_alert('관리자에게 문의해주세요.', 'error');
          }else{
            this.common_alert(header.ERROR_MSG, 'error');
          }
          return;
        }else{
          let data = response.DATA;
          this.divGridAuthGroupAllocDataText = data;
          this.detailData = [];

          this.getDivGridAuthGroupNoAlloc(); // 미할당 그룹
        }
      },

      getDivGridAuthGroupNoAllocCallback(response){
        let data = response.DATA;
        this.divGridAuthGroupNoAllocDataText = data;
        this.detailData = [];
      },

      moveRight_tab2() {
        let grid1_selected = this.selectedDivGridAuthGroupNoAllocList;  // 미할당 그룹 체크박스
        let grid1 = this.divGridAuthGroupNoAllocDataText;     // 미할당그룹
        let grid2 = this.divGridAuthGroupAllocDataText;       // 할당그룹
        if (grid1_selected.length == 1) {
          for(var i in grid1_selected){
            grid1.splice(grid1.indexOf(grid1_selected[i]) , 1);
            grid2.push(grid1_selected[i]);
          }
          this.selectedDivGridAuthGroupNoAllocList = [];
        } else if (this.selectedDivGridAuthGroupNoAllocList.length < 1) {
          this.common_alert('미할당 그룹을 선택해주세요', 'chk');
        } else {
          this.common_alert("미할당 그룹을 한개만 선택해주세요.", "chk");
        }
      },

      moveLeft_tab2(){
        let grid2_selected = this.selectedDivGridAuthGroupAllocList;  // 할당그룹 사용 체크박스
        let grid1 = this.divGridAuthGroupNoAllocDataText;     // 미할당그룹
        let grid2 = this.divGridAuthGroupAllocDataText;       // 할당그룹
        if (grid2_selected.length ==1 ) {
          for(var i in grid2_selected) {
            grid2.splice(grid2.indexOf(grid2_selected[i]), 1);
            grid1.push(grid2_selected[i]);
          }
          this.selectedDivGridAuthGroupAllocList = [];
        } else if (this.selectedDivGridAuthGroupAllocList.length < 1) {
          this.common_alert('할당 그룹을 선택해주세요', 'chk');
        } else {
          this.common_alert("할당 그룹이 한개 이상 선택되었습니다.","chk");
        }
      },

      submitGroupConfirm(){//저장
        if(this.divGridAuthGroupAllocDataText.length > 1){
          this.common_alert('할당 그룹은 한개의 그룹만 지정할 수 있습니다.', 'chk')
          return;
        }
        this.common_confirm('저장 하시겠습니까?', this.submitGroupGrid, null, null, null, 'chk');
      },

      submitGroupGrid(){//사용자별 권한관리 저장
        this.headers = this.initHeaders;
        this.URLData = "/api/setting/system/author-by-agent-manage/author-group-by-agent/regist";
        this.headers["SERVICE"] = "setting.system.author-by-agent-manage.author-group-by-agent";
        this.headers["METHOD"] = "regist";
        this.headers["ASYNC"] = false;
        this.headers["REGIST"] = "TAB_2";
        let submitList = [];

        let headersObj = {
          DATA_FLAG:"D",
          USER_ID:this.tab2_userId,
        };

        submitList.push(headersObj);
        for(let i in this.divGridAuthGroupAllocDataText){
          let obj = {
            divGridAlloc_CHECKBOX:'0',
            ATRT_GROUP_NM: this.divGridAuthGroupAllocDataText[i].ATRT_GROUP_NM,
            GROUP_XPLN: this.divGridAuthGroupAllocDataText[i].GROUP_XPLN,
            MNGR_YN: this.divGridAuthGroupAllocDataText[i].MNGR_YN,
            ETC_INFO01: this.divGridAuthGroupAllocDataText[i].ETC_INFO01,
            ETC_INFO02_NM: this.divGridAuthGroupAllocDataText[i].ETC_INFO02_NM,
            ETC_INFO03: this.divGridAuthGroupAllocDataText[i].ETC_INFO03,
            ATRT_GROUP_ID: this.divGridAuthGroupAllocDataText[i].ATRT_GROUP_ID,
            USER_ID:this.tab2_userId,
            ETC_INFO02: this.divGridAuthGroupAllocDataText[i].ETC_INFO02,
            DATA_FLAG:"I",
            PROC_ID: this.user_id,
            REGR_DEPT_CD:"X",
            REGR_ID: this.user_id,
          };
          submitList.push(obj);
        }

        this.sendData  = {
          list:submitList
        };

        this.result();
      },

      submitGroupGridCallBack(response){//
        this.common_alert("정상처리 되었습니다.");
        this.getGroupList2();
      },

      closeAlert(){//
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: false,
        });
      },

      getGroupList(){// 권한그룹별 상담사설정 tab
        if(this.mixin_isEmpty(this.atrtgroupId)) {
          this.common_alert('권한그룹을 선택해주세요.', 'chk');
          return false;
        }
        this.getDivGridAuthUserNoAlloc(); //미할당사용자
        this.getDivGridAuthUserAllocList(); //할당사용자
      },

      getDivGridAuthUserNoAlloc(){
        this.headers = this.initHeaders;
        this.URLData = "/api/setting/system/author-by-agent-manage/asgn-info-by-agent/inqire";
        this.headers["SERVICE"] = "setting.system.author-by-agent-manage.asgn-info-by-agent";
        this.headers["METHOD"] = "inqire";
        this.headers["ASYNC"] = false;
        this.headers["GRID_ID"] = "divGridAuthUserNoAlloc";
        this.headers["ROW_CNT"] = "50";
        this.headers["PAGES_CNT"] = "1";

        this.sendData= {};
        this.sendData["ATRT_GROUP_ID"] = this.atrtgroupId;
        this.sendData["USER_ID"] = this.tab_userId;;
        this.sendData["USER_NM"] = this.userNm;
        this.sendData["USER_NICK"] = this.userNick;
        this.sendData["NULLABLE"] = "IS NULL";
        this.divGridAuthUserNoAllocDataText = [];

        this.result();
      },

      getDivGridAuthUserAllocList(){
        this.URLData = "/api/setting/system/author-by-agent-manage/asgn-info-by-agent/inqire";
        this.headers = this.initHeaders;
        this.headers["SERVICE"] = "setting.system.author-by-agent-manage.asgn-info-by-agent";
        this.headers["METHOD"] = "inqire";
        this.headers["ASYNC"] = false;
        this.headers["GRID_ID"] = "divGridAuthUserAlloc";

        this.sendData= {};
        this.sendData["USER_NICK"] = this.userNick;
        this.sendData["USER_NM"] = this.userNm;
        this.sendData["USER_ID"] = this.tab_userId;
        this.sendData["ATRT_GROUP_ID"] = this.atrtgroupId;

        this.result();
      },

      getDivGridAuthUserNoAllocCallBack(response) {
        let data = response.DATA;
        this.divGridAuthUserNoAllocDataText = data;
        // 그리드 데이터 가공
        let idx = 1;
        let dataText = this.divGridAuthUserNoAllocDataText;
        for (let i in dataText) {
          this.divGridAuthUserNoAllocDataText[i]["index"] = idx++;
        }
        this.selectedDivGridAuthUserNoAllocList =[];
        this.detailData = [];
      },

      getDivGridAuthUserAllocCallBack(response){
        let data = response.DATA;
        this.divGridAuthUserAllocDataText = data;
        let idx = 1;
        let dataText = this.divGridAuthUserAllocDataText;
        for (let i in dataText) {
          this.divGridAuthUserAllocDataText[i]["index"] = idx++;
        }
        this.selectedDivGridAuthUserAllocList =[];
        this.detailData = [];
      },

      moveLeftDeleteConfirm() {//권한그룹별 상담사설정 저장
        if (this.selectedDivGridAuthUserAllocList.length < 1) {
          this.common_alert('할당사용자를 선택해주세요', 'chk');
        } else{
          this.common_confirm('선택한사용자를 미할당 처리 하시겠습니까?', this.moveLeftDelete, null, null, null, 'chk');
        }
      },

      moveLeftDelete(){
        this.headers = this.initHeaders;
        this.URLData = "/api/setting/system/author-by-agent-manage/agent-by-author-group/delete";
        this.headers["SERVICE"] = "setting.system.author-by-agent-manage.agent-by-author-group";
        this.headers["METHOD"] = "delete";
        this.headers["ASYNC"] = false;
        let grid1_selected = this.selectedDivGridAuthUserNoAllocList;  // 미할당 사용 체크박스
        let grid2_selected = this.selectedDivGridAuthUserAllocList;   // 할당 사용 체크박스
        let grid1 = this.divGridAuthUserNoAllocDataText;     // 미할당사용자 그리드
        let grid2 = this.divGridAuthUserAllocDataText;       // 할당사용자 그리드
        let submitDeleteList = [];
        if (grid2_selected.length > 0) {
          for(var i in grid2_selected) {
            grid2.splice(grid2.indexOf(grid2_selected[i]), 1);
            grid1.push(submitDeleteList[i]);
          }
          for(let i in this.selectedDivGridAuthUserAllocList){
            let obj = {
              divGridAuthUserAlloc_CHECKBOX: "1",
              USER_ID: this.selectedDivGridAuthUserAllocList[i].USER_ID,
              USER_NM: this.selectedDivGridAuthUserAllocList[i].USER_NM,
              USER_NICK: this.selectedDivGridAuthUserAllocList[i].USER_NICK,
              REG_DTTM: this.selectedDivGridAuthUserAllocList[i].REG_DTTM,
              ATRT_GROUP_ID: this.selectedDivGridAuthUserAllocList[i].ATRT_GROUP_ID,
              DATA_FLAG: "N",
            };
            submitDeleteList.push(obj);
          }
          this.sendData  = {
            list:submitDeleteList
          }
          this.result();
        }
      },

      moveLeftDeleteCallBack(response){//
        this.common_alert("정상처리 되었습니다.");
        this.getGroupList();
      },

      moveRightSaveConfirm(){//권한그룹별 상담사설정 저장
        if(this.selectedDivGridAuthUserNoAllocList.length < 1) {
          this.common_alert('미할당사용자를 선택해주세요', 'chk');
        }else {
          this.common_confirm('선택한사용자를 할당 처리 하시겠습니까?', this.moveRightSave, null, null, null, 'chk');
        }
      },

      moveRightSave(){//저장
        this.headers = this.initHeaders;
        this.URLData = "/api/setting/system/author-by-agent-manage/agent-by-author-group/regist";
        this.headers["SERVICE"] = "setting.system.author-by-agent-manage.agent-by-author-group";
        this.headers["METHOD"] = "regist";
        this.headers["ASYNC"] = false;
        this.headers["REGIST"] = "TAB_1";
        let grid1_selected = this.selectedDivGridAuthUserNoAllocList;  // 미할당 사용 체크박스
        let grid2_selected = this.selectedDivGridAuthUserAllocList;   // 할당 사용 체크박스
        let grid1 = this.divGridAuthUserNoAllocDataText;     // 미할당사용자 그리드
        let grid2 = this.divGridAuthUserAllocDataText;       // 할당사용자 그리드
        let submitList = [];
        if (grid1_selected.length > 0) {
          for(var i in grid1_selected) {
            grid1.splice(grid1.indexOf(grid1_selected[i]), 1);
            grid2.push(submitList[i]);
          }
          for(let i in this.selectedDivGridAuthUserNoAllocList){
            let obj = {
              divGridAlloc_CHECKBOX: "0",
              USER_ID: this.selectedDivGridAuthUserNoAllocList[i].USER_ID,
              USER_NM: this.selectedDivGridAuthUserNoAllocList[i].USER_NM,
              USER_NICK: this.selectedDivGridAuthUserNoAllocList[i].USER_NICK,
              REG_DTTM: this.selectedDivGridAuthUserNoAllocList[i].REG_DTTM,
              ATRT_GROUP_ID: this.selectedDivGridAuthUserNoAllocList[i].ATRT_GROUP_ID,
              DATA_FLAG: "I",
              PROC_ID: this.user_id,
              REGR_DEPT_CD:"X",
              REGR_ID: this.user_id,
            };
            submitList.push(obj);
          }
          this.sendData  = {
            list:submitList
          }
          this.result();
        }
      },

      moveRightSaveCallBack(response){//
        this.common_alert("정상처리 되었습니다.");
        this.getGroupList();
      },

      async result(){
        let head='';
        let dataa='';
        //결과값을 보여주는 부분 -> 실제 사용 시 dataa의 value를 사용
        //api 호출 부분
        await api.post(this.URLData,   //api url입력
            //await axios.post('https://local.hkpalette.com:8443/'+this.URLData,{
            this.sendData
            , //api에서 사용될 data 입력
            {head: this.headers
            }) //api에 로그를 남기기위한 값입력 (필수) (SERVICE, METHOD, TYPE)
            .then((response) => {
              if(typeof(response.data) === "string"){
                let responseData = JSON.parse(response.data.replaceAll("\n", ""));
                this.$store.commit("alertStore/openAlertDialog", {
                  alertDialogToggle: true,
                  msg: responseData.HEADER.ERROR_MSG,
                  iconClass: "svg-error-lg",
                  type: "default",
                });
                return;
              }
              if(response.data.HEADER.METHOD == "inqire" &&response.data.HEADER.TWB_SQL_ID =="selectRtnNotAuthAllocAll"){
                this.getDivGridAuthUserNoAllocCallBack(response.data);
              }else if(response.data.HEADER.METHOD == "inqire" &&response.data.HEADER.TWB_SQL_ID =="selectRtnAuthAllocAll"){
                this.getDivGridAuthUserAllocCallBack(response.data);
              }else if(response.data.HEADER.METHOD == "inqire"&&response.data.HEADER.GRID_ID =="divGridAuthGroupNoAlloc"){
                this.getDivGridAuthGroupNoAllocCallback(response.data);
              }else if(response.data.HEADER.METHOD == "inqire"&&response.data.HEADER.GRID_ID =="divGridAuthGroupAlloc"){
                this.getDivGridAuthGroupAllocCallback(response.data);
              }else if(response.data.HEADER.METHOD == "regist"&&response.data.HEADER.REGIST =="TAB_2") {
                this.submitGroupGridCallBack(response.data);
              }else if(response.data.HEADER.METHOD == "regist"&&response.data.HEADER.REGIST =="TAB_1") {
                this.moveRightSaveCallBack(response.data);
              }else if(response.data.HEADER.METHOD == "delete") {
                this.moveLeftDeleteCallBack(response.data);
              }else if(response.data.HEADER.METHOD == "inqire"&&response.data.HEADER.SERVICE =="setting.system.menu-author-manage.author-group-code") {
                this.groupCodeInqireCallBack(response.data);
              }
            }) //api 호출 성공 이후 수행
            .catch((err) => {
              this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.", "error");
            }) //api호출 에러 작업 수행

        this.HEADERResult = head;
        this.DATAResult = dataa;
      }

    },

    mounted() {
      this.groupCodeInqire();
    },

    computed: {
      dateRangeText() {
        var newStartDate = this.dates[0];
        var newEndDate = this.dates[1];
        return newStartDate + " ~ " + newEndDate;
      },

      initHeaders(){
        return this.headers = {
          "SERVICE" : "",
          "METHOD" : "",
          "TWB_SQL_ID":"",
          "TYPE" : this.HEADER_TYPE,
        };
      },
    },
  };
</script>
<style>

</style>
