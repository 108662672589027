<!-- <template>
  <div class="layout-maincontainer">
    <h1 class="tit-h1">홈</h1>
    <div class="layout-home">
      <div class="layout-home-stat-wrap">
        <div class="box-wrap">
          <div class="layout-home--ci">
            <v-radio-group
              v-model="radioGroupSelected"
              row
              class="radio-inline ml-2"
            >
              <template v-slot:label>
                <span class="in-label">업체선택</span>
              </template>

              <v-radio
                v-for="(item, index) in radioGroup"
                :key="radioGroup[index].id"
                :label="item.text"
                :on-icon="'svg-rad-on'"
                :off-icon="'svg-rad-off'"
                :value="item.cd"
                @click="clickBoardList(item)"
              >
              </v-radio>
            </v-radio-group>
            <div class="ml-auto">
              <span class="layout-home-time">
                {{ lastUpdateTime }}
              </span>
              <v-btn
                class="pa-0 ml-2"
                min-width="32px"
                height="32px"
                plain
                v-on:click="
                  setTime();
                  FnLoading();
                  getBoardList1();
                  getBoardList2();
                  getCountCNSL();
                  MonitoringVue();
                "
              >
                <v-icon
                  small
                  class="svg-home-refresh"
                  :class="{ 'custom-loader': loading === true }"
                ></v-icon>
              </v-btn>
            </div>
          </div>
        </div>
        <div class="d-flex">
          <div class="col-6 pa-0">
            <div class="box-wrap" style="height: 271px">
              <h3 class="tit-h3 d-flex">
                게시판
                <div class="ml-auto align-self-center">
                  <v-btn outlined small class="btn-etc3" @click="setActiveTab1">
                    <v-icon small class="mr-2">mdi-plus</v-icon>
                    더보기
                  </v-btn>
                </div>
              </h3>
              <div class="">
                <ul class="layout-home--board">
                  <li
                    v-for="(item, index) in boardList1"
                    :key="index"
                    @click="setAgent(item)"
                  >
                    <span
                      class="layout-home--board-category"
                      :class="[item.categoryClass]"
                      >[{{ item.category }}]</span
                    >
                    <span class="layout-home--board-title">{{
                      item.title
                    }}</span>
                    <span class="layout-home--board-file">
                      <v-icon
                        v-show="item.file === true"
                        small
                        class="svg-clip-file"
                      ></v-icon>
                    </span>
                    <span class="layout-home--board-date">{{ item.date }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-6 pa-0">
            <div class="box-wrap ml-3" style="height: 271px">
              <h3 class="tit-h3 d-flex">
                공지사항
                <div class="ml-auto align-self-center">
                  <v-btn outlined small class="btn-etc3" @click="setActiveTab2">
                    <v-icon small class="mr-2">mdi-plus</v-icon>
                    더보기
                  </v-btn>
                </div>
              </h3>
              <div class="">
                <ul class="layout-home--board">
                  <li
                    v-for="(item, index) in boardList2"
                    :key="index"
                    @click="setAgent(item)"
                  >
                    <span
                      class="layout-home--board-category"
                      :class="[item.categoryClass]"
                      >[{{ item.category }}]</span
                    >
                    <span class="layout-home--board-title">{{
                      item.title
                    }}</span>
                    <span class="layout-home--board-file">
                      <v-icon
                        v-show="item.file === true"
                        small
                        class="svg-clip-file"
                      ></v-icon>
                    </span>
                    <span class="layout-home--board-date">{{ item.date }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <v-dialog
            max-width="577"
            v-model="dialogHomeP"
            content-class="square-modal maincalltrans-modal"
          >
            <dialog-HomeP
              headerTitle="공지사항"
              @hide="hideDialog('HomeP')"
              :agentInfo="agentInfo"
              @input="agentInfo = $event"
              @registAgent="setAgent()"
              :isinit="isInit"
            >
            </dialog-HomeP>
          </v-dialog>
        </div>
        <div class="d-flex">
          <div class="box-wrap flex-grow-1 col-4">
            <h3 class="tit-h3">개인상담이력</h3>
            <div class="layout-home--status">
              <div class="layout-home--title mt-0">
                <v-icon class="svg-home-call"></v-icon>
                콜 상담
              </div>
              <div class="layout-home--cnt">
                <div>
                  <v-icon class="svg-home-day"></v-icon>
                  <span>금일상담</span>
                  <span class="layout-home--num">{{ PHN_DAY_CNT }}</span>
                </div>
                <div>
                  <v-icon class="svg-home-week"></v-icon>
                  <span class="layout-home--status-chat">주간상담</span>
                  <span class="layout-home--num">{{ PHN_WEEK_CNT }}</span>
                </div>
                <div>
                  <v-icon class="svg-home-month"></v-icon>
                  <span class="layout-home--status-chat">월간상담</span>
                  <span class="layout-home--num">{{ PHN_MONTH_CNT }}</span>
                </div>
              </div>
              <div class="layout-home--title type01">
                <v-icon class="svg-home-chat"></v-icon>
                챗 상담
              </div>
              <div class="layout-home--cnt type02">
                <div>
                  <v-icon class="svg-home-day"></v-icon>
                  <span>금일상담</span>
                  <span class="layout-home--num">{{ CHT_DAY_CNT }}</span>
                </div>
                <div>
                  <v-icon class="svg-home-week"></v-icon>
                  <span class="layout-home--status-chat">주간상담</span>
                  <span class="layout-home--num">{{ CHT_WEEK_CNT }}</span>
                </div>
                <div>
                  <v-icon class="svg-home-month"></v-icon>
                  <span class="layout-home--status-chat">월간상담</span>
                  <span class="layout-home--num">{{ CHT_MONTH_CNT }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="box-wrap flex-grow-1 ml-3">
            <h3 class="tit-h3">모니터링</h3>
            <div class="layout-home--status">
              <div class="layout-home--title mt-0">
                <v-icon class="svg-home-call"></v-icon>
                전체 콜 현황
              </div>
              <div class="layout-home--cnt2">
                <div>
                  <span> IB 콜 </span>
                  <span class="layout-home--num">{{ ALL_IB_NUM }}</span>
                </div>
                <div>
                  <span> OB 콜 </span>
                  <span class="layout-home--num">{{ ALL_OB_NUM }}</span>
                </div>
                <div>
                  <span> 응대율 </span>
                  <span class="layout-home--num">{{ ALL_RES_RATE }}</span>
                </div>
              </div>
            </div>
            <div class="layout-home--status">
              <div class="layout-home--title mt-0">
                <v-icon class="svg-home-call"></v-icon>
                개인 콜 현황
              </div>
              <div class="layout-home--cnt2">
                <div>
                  <span> 개인 IB 콜 </span>
                  <span class="layout-home--num">{{ IND_IB_NUM }}</span>
                </div>
                <div>
                  <span> 개인 IB시간 </span>
                  <span class="layout-home--num">{{ IND_OB_NUM }}</span>
                </div>
                <div>
                  <span> 개인 OB 콜 </span>
                  <span class="layout-home--num">{{ IND_IB_CNT }}</span>
                </div>
                <div>
                  <span> 개인 OB시간 </span>
                  <span class="layout-home--num">{{ IND_OB_NCNT }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box-wrap layout-home--schedule">
        <h3 class="tit-h3">스케줄정보</h3>
        <div class="box-ct mt-4">
          <div class="calendar-modal">
            <v-date-picker
              v-model="calendar"
              :events="arrayEventsCalendar"
              color="#F7AFAE"
              full-width
              event-color="#F06969"
              @change="calendarLog"
              @click:date="checkDate"
              dark
              :first-day-of-week="1"
              locale="ko-KR"
              :day-format="mixin_getDate"
              @update:picker-date="updatePickedMonth"
            ></v-date-picker>
          </div>
          <div class="timeline-wrap mt-3 pl-0" style="height: 290px">
            <v-timeline align-top dense>
              <v-timeline-item
                small
                v-for="item in arrayEvents"
                :key="item.id"
                :class="[
                  item.type === 'schedule' ? 'type-schedule' : 'type-evt',
                ]"
              >
                <v-row>
                  <v-col cols="3">
                    <strong
                      >{{ daySplit(item.day) }}
                      <span class="text-caption">일</span></strong
                    >
                  </v-col>
                  <v-col>
                    <strong> {{ item.titleLS }} </strong>
                    <div class="text-caption">
                      {{ item.desc }}
                    </div>
                  </v-col>
                </v-row>
              </v-timeline-item>
            </v-timeline>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DialogHomeP from "./DialogHomeP.vue"; //고객정보조회
import api from "@/store/apiUtil";
import moment from "moment";

export default {
  name: "MENU_home", //name은 'MENU_' + 파일명 조합
  components: {
    DialogHomeP,
  },
  data() {
    return {
      dialogHomeP: false,
      lastUpdateTime: "2022-02-22 09:01:00",
      radioGroup: [],
      radioGroupSelected:
        this.user_role.company[0].CD,
      // 공지사항
      boardList1: [],
      boardList2: [],
      // 나의 스케쥴
      dropItems: [
        {
          title: "기념일",
          icon: "svg-dot-evt",
        },
        {
          title: "스케쥴",
          icon: "svg-dot-sch",
        },
      ],
      agentInfo: {
        BRD_NO: "",
        date: "",
        BRD_ID: "",
        ASP_NEWCUST_KEY: "",
        BRD_RMK: "",
        FILE_GROUP_KEY: "",
        title: "",
      },

      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      calendar: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      arrayEvents: [],
      // arrayEventsCalendar: null,
      arrayEventDetail: null,
      USER_ID: this.user_role.userId,
      USER_COMPANY: [],
      CD_NM: "",
      nowDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dropItemM: "",
      date1: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      date2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      isInit: false,

      initCNT: 0,

      CHK_CUST: {
        CHK: 1,
        CHK_CD: this.user_role.company[0].CD,
        CHK_NM: this.user_role.company[0].CD_NM,
      },
      //loader
      loading: false,

      CHT_DAY_CNT: "0",
      CHT_WEEK_CNT: "0",
      CHT_MONTH_CNT: "0",

      PHN_DAY_CNT: "0",
      PHN_WEEK_CNT: "0",
      PHN_MONTH_CNT: "0",
      //INLNE_NO: "",

      ALL_IB_NUM: "",
      ALL_OB_NUM: "",
      ALL_RES_RATE: "",
      IND_IB_NUM: "",
      IND_OB_NUM: "",
      IND_IB_CNT: "",
      IND_OB_NCNT: "",

      CALL_MOR_URL: process.env.VUE_APP_IPCC_TRANSFER_URL + "db_select.php",
    };
  },
  methods: {
    setActiveTab1() {
      this.user_role.CHK_CUST = this.CHK_CUST;
      this.user_role.CHK_CUST.vue = "M6101";
      let setId = "M6101";
      this.$store.dispatch("commonStore/AC_COMMON_ACTIVE_TAB", {
        id: "M6101",
        title: "게시판",
        default: "N",
        tabIcon: "",
        link: "/M6101",
      });
    },

    setActiveTab2() {
      this.user_role.CHK_CUST = this.CHK_CUST;
      this.user_role.CHK_CUST.vue = "M6102";
      let setId = "M6102";
      this.$store.dispatch("commonStore/AC_COMMON_ACTIVE_TAB", {
        id: "M6102",
        title: "실시간공지사항",
        default: "N",
        tabIcon: "",
        link: "/M6102",
      });
    },

    //기업선택 항목
    companyList() {
      this.USER_COMPANY = this.user_role.company;
      //radioGroup
      for (let index = 0; index < this.USER_COMPANY.length; index++) {
        var dataList = {};
        dataList.id = index + 1;
        dataList.cd = this.USER_COMPANY[index]["CD"];
        dataList.text = this.USER_COMPANY[index]["CD_NM"];
        this.radioGroup.push(dataList);
      }
      //console.log("#################!!!!!!!!!!!!!!!!!!!!!!!!");
      //console.log(this.radioGroup);
    },

    //모달 전송
    setAgent(item) {
      api
        .post(
          "/api/board/detail-popup/inqire",
          {
            BRD_ID: item.BRD_ID,
            BRD_NO: item.BRD_NO,
            ASP_NEWCUST_KEY: item.ASP_NEWCUST_KEY,
          },
          {
            head: {
              SERVICE: "api/board/detail-popup",
              METHOD: "/inqire",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          //console.log(response.data.DATA);
          let INFO = {
            BRD_NO: "",
            date: "",
            BRD_ID: "",
            ASP_NEWCUST_KEY: "",
            BRD_RMK: "",
            FILE_GROUP_KEY: "",
            title: "",
          };
          INFO.BRD_RMK = this.restoreXSS(
            this.restoreXSS_CKeditor(response.data.DATA[0]["BRD_RMK"])
          );
          INFO.FILE_GROUP_KEY = response.data.DATA[0]["FILE_GROUP_KEY"];
          INFO.BRD_NO = item.BRD_NO;
          INFO.date = item.date;
          INFO.BRD_ID = item.BRD_ID;
          INFO.ASP_NEWCUST_KEY = item.ASP_NEWCUST_KEY;
          INFO.title = item.title;

          this.agentInfo = INFO;

          this.showDialog("HomeP");
          //this.$emit("registAgent", this.agentInfo);
        });
    },

    //달력 클릭 체크
    checkDate() {
      //this.getBoardList1();
      //this.getBoardList2();
      this.arrayEvents = [];
      this.date = this.calendar;
      this.calendarClick();
    },

    //달력 클릭시 스케줄api 호출
    calendarClick() {
      const USER_ID = this.USER_ID;
      const USER_DATE = this.nowDate.replace(/\-/g, "");
      //alert(USER_DATE);
      const FIRST_USER_DATE = USER_DATE.substr(0, 6) + "00"; //월초 날짜 세팅
      const LAST_USER_DATE = USER_DATE.substr(0, 6) + "99"; //월말 날짜 세팅
      this.arrayEvents = [];
      api
        .post(
          "api/schedule/list",
          {
            USER_ID: USER_ID,
            FIRST_USER_DATE: FIRST_USER_DATE,
            LAST_USER_DATE: LAST_USER_DATE,
          },
          {
            head: {
              SERVICE: "api/schedule",
              METHOD: "/list",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          this.initCNT = 1;
          this.user_role.SCHEDULE = 0;
          for (let index = 0; index < response.data.DATA.length; index++) {
            var dataList = {};
            var arrCal = [];
            var arrColor = [];
            dataList.id = index;
            //dataList.day = response.data.DATA[index]['USER_DATE'].substr(0,3)+"-"+response.data.DATA[index]['USER_DATE'].substr(4,5)+"-"+response.data.DATA[index]['USER_DATE'].substr(6,7)
            dataList.day = response.data.DATA[index]["USER_DATE"].replace(
              /([0-9]{4})([0-9]+)([0-9]{2})/,
              "$1-$2-$3"
            );
            dataList.titleLS = response.data.DATA[index]["TITLE"];
            dataList.desc = response.data.DATA[index]["CONTENT"];
            if (response.data.DATA[index]["SELECT_DAY"] == "기념일") {
              dataList.type = "evt";
              dataList.title = response.data.DATA[index]["SELECT_DAY"];
              dataList.icon = "svg-dot-evt";
            } else {
              dataList.type = "schedule";
              dataList.title = response.data.DATA[index]["SELECT_DAY"];
              dataList.icon = "svg-dot-sch";
            }

            this.arrayEvents.push(dataList);
            this.arrayEventsCalendar(this.arrayEvents[index].day);
          }
        });
    },

    //초기 스케줄 세팅
    FirstCalendarClick() {
      const USER_ID = this.USER_ID;
      //console.log("this.nowDate", this.nowDate);
      const USER_DATE = this.nowDate.replace(/\-/g, "");
      const FIRST_USER_DATE = USER_DATE.substr(0, 6) + "00";
      const LAST_USER_DATE = USER_DATE.substr(0, 6) + "99";
      this.arrayEvents = [];
      api
        .post(
          "api/schedule/list",
          {
            USER_ID: USER_ID,
            FIRST_USER_DATE: FIRST_USER_DATE,
            LAST_USER_DATE: LAST_USER_DATE,
          },
          {
            head: {
              SERVICE: "api/schedule",
              METHOD: "/list",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          this.initCNT = 1;
          for (let index = 0; index < response.data.DATA.length; index++) {
            var dataList = {};
            var arrCal = [];
            var arrColor = [];
            dataList.id = index;
            //dataList.day = response.data.DATA[index]['USER_DATE'].substr(0,3)+"-"+response.data.DATA[index]['USER_DATE'].substr(4,5)+"-"+response.data.DATA[index]['USER_DATE'].substr(6,7)
            dataList.day = response.data.DATA[index]["USER_DATE"].replace(
              /([0-9]{4})([0-9]+)([0-9]{2})/,
              "$1-$2-$3"
            );
            dataList.titleLS = response.data.DATA[index]["TITLE"];
            dataList.desc = response.data.DATA[index]["CONTENT"];
            if (response.data.DATA[index]["SELECT_DAY"] == "기념일") {
              dataList.type = "evt";
              dataList.title = response.data.DATA[index]["SELECT_DAY"];
              dataList.icon = "svg-dot-evt";
            } else {
              dataList.type = "schedule";
              dataList.title = response.data.DATA[index]["SELECT_DAY"];
              dataList.icon = "svg-dot-sch";
            }

            this.arrayEvents.push(dataList);
            this.arrayEventsCalendar(this.arrayEvents[index].day);
          }
        });
    },

    FnArrayEventColor(e) {
      const index = this.arrayEvents.findIndex(function (item) {
        return item.day === e;
      });

      if (index < 0) return;

      return this.arrayEventColor[index];
    },

    // 모달 공통
    showDialog(type) {
      this[`dialog${type}`] = true;
    },
    //모달 상세
    showDialogDetail(type, item1, item2, item3, item4, item5) {
      //this.isInit = !this.isInit;
      this[`dialog${type}`] = true;
      this.agentInfo.BRD_NO = item1;
      this.agentInfo.date = item2;
      this.agentInfo.BRD_ID = item3;
      this.agentInfo.ASP_NEWCUST_KEY = item4;
      this.agentInfo.title = item5;
      console.log(this.agentInfo);

      this.setAgent();
      //this.$emit("registAgent", this.agentInfo);
    },

    hideDialog(type) {
      this.isInit = !this.isInit;
      this[`dialog${type}`] = false;
    },
    // 나의 스케쥴
    daySplit(e) {
      const day = e.split("-")[2];
      return day;
    },
    calendarLog(e) {
      const index = this.arrayEvents.findIndex(function (item) {
        return item.day === e;
      });

      if (index < 0) return;

      this.arrayEventDetail =
        this.arrayEvents[index] ?? this.arrayEvents[index];
    },
    // evtCreate() {
    //   var arrCal = [];
    //   var arrColor = [];
    //   for (let index = 0; index < this.arrayEvents.length; index++) {
    //     var element = this.arrayEvents[index].day;
    //     var element2 =
    //       this.arrayEvents[index].type === "schedule" ? "#FFF" : "#F7AFAE";

    //     arrCal[arrCal.length] = element;
    //     arrColor[arrColor.length] = element2;
    //   }
    //   this.arrayEventsCalendar = arrCal;
    //   this.arrayEventColor = arrColor;
    //   console.log(arrCal);
    // },
    //스케쥴 달력에 포인트 찍기
    arrayEventsCalendar(date) {
      let colorArray = [];

      for (let i = 0; i < this.arrayEvents.length; i++) {
        if (this.arrayEvents[i].day === date) {
          if (this.arrayEvents[i].type === "schedule") {
            colorArray.push("#6DC4B1");
          } else if (this.arrayEvents[i].type === "evt") {
            colorArray.push("#F7B611");
          }
        }
      }
      // const [,, day] = date.split('-')
      // if ([12, 17, 28].includes(parseInt(day, 10))) return true
      // if ([1, 19, 22].includes(parseInt(day, 10))) return ['red', '#00f']
      return colorArray;
    },
    getDay(date) {
      const day = date.split("-")[2];
      return Number(day);
    },

    time() {
      var dateLS = new Date();

      var year = dateLS.getFullYear();
      var month = dateLS.getMonth() + 1;
      var day = dateLS.getDate();
      var hour = dateLS.getHours();
      var minute = dateLS.getMinutes();
      var second = dateLS.getSeconds();
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      if (hour < 10) {
        hour = "0" + hour;
      }
      if (minute < 10) {
        minute = "0" + minute;
      }
      if (second < 10) {
        second = "0" + second;
      }
      var fullNowDate =
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        second;
      this.nowDate = year + "-" + month + "-" + day;
      this.lastUpdateTime = fullNowDate;
      //return year + month + day
    },

    setTime() {
      let nowDate = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      ).toISOString();
      let nowDatArray = nowDate.split("T");
      let nowTimeArray = nowDatArray[1].split(".");
      this.lastUpdateTime = nowDatArray[0] + " " + nowTimeArray[0];
    },

    //시스템공지사항 리스트 불러오기
    getBoardList1() {
      this.boardList1 = [];
      api
        .post(
          "/api/board/list",
          {
            BRD_ID: "2", //BRD_ID 2(게시판)
            ASP_NEWCUST_KEY: this.radioGroupSelected,
          },
          {
            head: {
              SERVICE: "/api/board",
              METHOD: "/list",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          var dataLength = "";
          if (response.data.DATA.length == 0) {
            return;
          } else if (response.data.DATA.length > 4) {
            dataLength = 5;
          } else {
            dataLength = response.data.DATA.length;
          }
          for (let index = 0; index < dataLength; index++) {
            var dataList = {};
            dataList.category = response.data.DATA[index]["BRD_QUICK_TY_NM"];
            dataList.title = response.data.DATA[index]["BRD_TIT"];
            dataList.BRD_NO = response.data.DATA[index]["BRD_NO"];
            dataList.BRD_ID = "2";
            dataList.ASP_NEWCUST_KEY = this.radioGroupSelected;
            if (
              response.data.DATA[index]["FILE_CNT"] == "" ||
              response.data.DATA[index]["FILE_CNT"] == "undefined" ||
              response.data.DATA[index]["FILE_CNT"] == "0"
            ) {
              dataList.file = false;
            } else {
              dataList.file = true;
            }
            dataList.date = response.data.DATA[index]["LAST_BRD_DT"].replace(
              /(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/,
              "$1-$2-$3 $4:$5:$6"
            );
            this.boardList1.push(dataList);
          }
        });
    },
    //업체별공지사항 리스트 불러오기
    getBoardList2() {
      this.boardList2 = [];
      api
        .post(
          "/api/board/list",
          {
            BRD_ID: "6",
            ASP_NEWCUST_KEY: this.radioGroupSelected,
          },
          {
            head: {
              SERVICE: "/api/board",
              METHOD: "/list",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          var dataLength = "";
          if (response.data.DATA.length == 0) {
            return;
          } else if (response.data.DATA.length > 4) {
            dataLength = 5;
          } else {
            dataLength = response.data.DATA.length;
          }
          for (let index = 0; index < dataLength; index++) {
            var dataList = {};
            dataList.category = response.data.DATA[index]["BRD_QUICK_TY_NM"];
            dataList.title = response.data.DATA[index]["BRD_TIT"];
            dataList.BRD_NO = response.data.DATA[index]["BRD_NO"];
            dataList.BRD_ID = "6";
            dataList.ASP_NEWCUST_KEY = this.radioGroupSelected;
            if (
              response.data.DATA[index]["FILE_CNT"] == "" ||
              response.data.DATA[index]["FILE_CNT"] == "undefined" ||
              response.data.DATA[index]["FILE_CNT"] == "0"
            ) {
              dataList.file = false;
            } else {
              dataList.file = true;
            }
            dataList.date = response.data.DATA[index]["LAST_BRD_DT"].replace(
              /(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/,
              "$1-$2-$3 $4:$5:$6"
            );
            this.boardList2.push(dataList);
          }
        });
    },

    //개인상담이력
    getCountCNSL() {
      let currentDay = new Date();
      let theYear = currentDay.getFullYear();
      let theMonth = currentDay.getMonth() + 1;
      let theDate = currentDay.getDate();
      let theDayOfWeek = currentDay.getDay();

      let firstDay = new Date(theYear, theMonth, 1); //당월 1일
      let lastDay = new Date(theYear, theMonth, 0); //당월 말일

      let firstDate = firstDay.getDate();
      let lastDate = lastDay.getDate();

      let toDay = theYear + "" + theMonth + "" + theDate;
      let thisWeek = [];
      let thisMonth = [];

      thisMonth[0] = theYear + "0" + theMonth + "0" + firstDate;
      thisMonth[1] = theYear + "0" + theMonth + "" + lastDate;

      for (let i = 0; i < 7; i++) {
        let resultDay = new Date(
          theYear,
          theMonth,
          theDate + (i - theDayOfWeek)
        );
        let yyyy = resultDay.getFullYear();
        let mm = Number(resultDay.getMonth());
        let dd = resultDay.getDate();

        mm = String(mm).length === 1 ? "0" + mm : mm;
        dd = String(dd).length === 1 ? "0" + dd : dd;

        thisWeek[i] = yyyy + mm + dd;
      }
      api
        .post(
          "/api/palette-main/cnsl-count/inqire",
          {
            USER_ID: this.user_role.userId,
            DAY_ST_DT: toDay,
            WEEK_ST_DT: thisWeek[0],
            MONTH_ST_DT: thisMonth[0],
            DAY_END_DT: toDay,
            WEEK_END_DT: thisWeek[6],
            MONTH_END_DT: thisMonth[1],
          },
          {
            head: {
              SERVICE: "/api/palette-main/cnsl-count",
              METHOD: "/inqire",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          this.CHT_DAY_CNT = response.data.DATA[0].CHT_DAY_CNT;
          this.CHT_WEEK_CNT = response.data.DATA[0].CHT_WEEK_CNT;
          this.CHT_MONTH_CNT = response.data.DATA[0].CHT_MONTH_CNT;
          this.PHN_DAY_CNT = response.data.DATA[0].PHN_DAY_CNT;
          this.PHN_WEEK_CNT = response.data.DATA[0].PHN_WEEK_CNT;
          this.PHN_MONTH_CNT = response.data.DATA[0].PHN_MONTH_CNT;
        });
    },

    //기업선택시 리스트 불러오기
    clickBoardList(item) {
      this.CHK_CUST = { CHK: 1, CHK_CD: item.cd, CHK_NM: item.text };
      this.getBoardList1();
      this.getBoardList2();
    },

    updatePickedMonth(value) {
      this.nowDate = value + "-01";

      if (this.initCNT != 0) {
        this.calendarClick();
      }
    },

    //loader
    FnLoading() {
      this.loading = !this.loading;

      setTimeout(() => (this.loading = false), 1000);
      // this.loading = null;
    },

    intervalCallbar1() {
      this.CALL_BAR_TIMER = setInterval(() => {
        //this.nowTime = moment().format("HHmmss");
        // 5분마다 호출
        //if (this.nowTime % 500 == 0) {
        this.MonitoringVue();
        //}

        //
      }, 600000); //10분마다 체크
    },
    /**
     * 콜 모니터링 화면
     */
    //콜 모니터링 화면 세팅
    setMor(data) {
      const parseData = JSON.parse(data);
      this.ALL_IB_NUM = parseData.td_mon_3; //IB콜수
      this.ALL_OB_NUM = parseData.td_mon_4; //OB콜수
      this.ALL_RES_RATE = parseData.td_mon_5; //응대율
      this.IND_IB_NUM = parseData.td_mon_6; //개인실적IB건수
      this.IND_OB_NUM = parseData.td_mon_7; //개인실적OB
      this.IND_IB_CNT = parseData.td_mon_8; //개인실적IB시간
      this.IND_OB_NCNT = parseData.td_mon_9; //개인실적OB시간
    },
    //콜모니터링 API호출
    MonitoringVue() {
      let callMorUr =
        this.CALL_MOR_URL + "?mode=monView2" + "&ext=" + this.user_role.INLNE_NO; // 모니터링 URL http://meta.hkipcc.co.k/api/db_select.php?mode=monView2&ext=내선번호
      let tmpMornitoring = (data) => this.setMor(data);
      $.ajax({
        url: callMorUr,
        dataType: "html",
        type: "GET",
        contentType: "application/json",
        crossDomain: true,
        success: function (data) {
          tmpMornitoring(data);
        },
        error: function (request, status, error) {
          console.log(
            "code:" +
              request.status +
              "\n" +
              "message:" +
              request.responseText +
              "\n" +
              "error:" +
              error
          );
        },
      });
    },
  },
  mounted() {
    this.getBoardList1();
    this.getBoardList2();
    //this.FirstCalendarClick();
    //this.companyList();
    //this.time();
    //setInterval(this.time, 1000);
    this.getCountCNSL();
    this.MonitoringVue();
    this.intervalCallbar1();
  },

  created() {
    this.FirstCalendarClick();
    this.companyList();
    this.time();
    //setInterval(this.time, 1000);
  },

  computed: {
    getSCHEDULE() {
      return this.user_role.SCHEDULE;
    },
  },

  watch: {
    getSCHEDULE(val, oldVal) {
      this.calendarClick();
    },
  },
};
</script>

<style></style> -->



























<template>
  <div class="layout-subcontainer">
    <div class="tab_contents_wrap">
      <table>
        <colgroup>
          <col />
          <col />
          <col />
          <col />
          <col width="100px" />
        </colgroup>
        <thead>
          <th>detph1</th>
          <th>detph2</th>
          <th>detph3</th>
          <th>path</th>
          <th>etc</th>
        </thead>
        <tbody>
          <tr
            v-for="item in items"
            v-html="item.data"
            link
            @click="mixin_set_active_tab(item)"
            style="cursor:pointer"
          >
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  export default {
    methods: {
      closePop() {
        this.$store.dispatch("commonStore/AC_COMMON_HELP_DIALOG", {
          type: false,
        });
      },
    },
    computed: {
    },
    mounted() {
      this.init()
    },
    data() {
      return {
        items: [
  
          {
            C_NODE_NO: "CAM0400",
            NODE_TITLE: "개인상담관리",
            class: "",
            data: "<td class='depth1'></td>"+
                  "<td class='depth2'>캠페인</td>"+
                  "<td class='depth3'>개인상담관리</td>"+
                  "<td class='page'>CAM0400</td>"
          },
          {
            C_NODE_NO: "CAM0500",
            NODE_TITLE: "현장관리",
            class: "",
            data: "<td class='depth1'></td>"+
                  "<td class='depth2'>캠페인</td>"+
                  "<td class='depth3'>현장관리</td>"+
                  "<td class='page'>CAM0500</td>"
          },
        ],
        right: null,
      };
    },

    methods: {
      init() {
        if(this.user_role.atrtGroupNm==='상담사'){
          this.items=[{
            C_NODE_NO: "CAM0400",
            NODE_TITLE: "개인상담관리",
            class: "",
            data: "<td class='depth1'></td>"+
                  "<td class='depth2'>캠페인</td>"+
                  "<td class='depth3'>개인상담관리</td>"+
                  "<td class='page'>CAM0400</td>"
          }]
        }else if(this.user_role.atrtGroupNm==='현장관리자'){
          this.items=[{
            C_NODE_NO: "CAM0500",
            NODE_TITLE: "현장관리",
            class: "",
            data: "<td class='depth1'></td>"+
                  "<td class='depth2'>캠페인</td>"+
                  "<td class='depth3'>현장관리</td>"+
                  "<td class='page'>CAM0500</td>"
          }]
        }

      },
    }
  };
</script>

<style scoped>
  .tab_contents_wrap {
    width: 100%;
    margin-top: 10px;
    border-top: none;
  }
  .tab_contents_wrap table {
    width: 100%;
    background-color: #fff;
  }
  .tab_contents_wrap table th {
    background: #e3e3e3;
    border: 1px solid #eee;
  }
  .tab_contents_wrap table td {
    border: 1px solid #eee;
  }
  .tab_contents_wrap table tr td:first-child {
    position: relative;
  }
  .tab_contents_wrap table tr:hover td {
    background-color: #dcf1ff;
  }
  .tab_contents_wrap table tr.focus:hover td {
    background-color: #f7e4e4;
  }
  .tab_contents_wrap table td.etc {
    position: relative;
    padding-right: 15px;
  }
  .tab_contents_wrap table td.etc .moreBtn {
    position: absolute;
    top: 3px;
    right: 5px;
    color: #ccc;
  }
  .tab_contents_wrap table td.etc .moreBtn.on {
    right: 5px;
    opacity: 1;
    transition: all ease-in-out 0.26s;
  }
  .tab_contents_wrap table td.etc .moreBtn.on:hover {
    color: #666;
  }
</style>
