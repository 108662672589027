<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">상담이미지 관리</h1>
    <div class="box-wrap">
      <div class="box-ct d-flex">
        <div class="form-inp sm">
          <!-- 회사 구분 완료 -->
          <v-select
              :items="aspCustList"
              item-text="CD_NM"
              item-value="CD"
              outlined
              hide-details
              label="회사구분"
              return-object
              placeholder="선택하세요"
              v-on:change="getListCM"
              v-model="selBexBizV"
              :readonly="RDOnly"
          >
            <template v-slot:label>
              회사구분
              <v-icon color="#EF9191">mdi-circle-small</v-icon>
            </template>
          </v-select>
        </div>
        <div class="sm ml-2">
          <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
              content-class="calendar-modal"
              color="#FFF"
          >

            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="dateRangeText"
                  v-bind="attrs"
                  v-on="on"
                  readonly
                  outlined
                  hide-details
                  class="form-inp icon-calendar"
                  append-icon="svg-calendar"
                  label="등록일자"
              ></v-text-field>
            </template>
            <div class="datepicker-range">
              <v-date-picker
                  v-model="dates[0]"
                  no-title
                  dark
                  color="#F7AFAE"
                  locale="ko-KR"
                  :first-day-of-week="1"
                  :day-format="mixin_getDate"
                  :max="dates[1]"
              ></v-date-picker>
              <v-date-picker
                  v-model="dates[1]"
                  dark
                  color="#F7AFAE"
                  no-title
                  locale="ko-KR"
                  :first-day-of-week="1"
                  :day-format="mixin_getDate"
                  :min="dates[0]"
              ></v-date-picker>
            </div>
            <div class="text-center pt-3 pb-3">
              <v-btn
                  outlined
                  class="btn-default"
                  @click="menu1 = false"
              >확인
              </v-btn>
            </div>
          </v-menu>
          <v-btn dark small class="btn-main-refresh pa-0 ml-2" min-width="32px" height="32px" plain
                 @click="resetDate()">
            <v-icon small class="svg-main-refresh"></v-icon>
          </v-btn>
        </div>
        <div class="ml-2">
          <v-text-field
              class="form-inp lg"
              name="name"
              outlined
              hide-details
              label="제목"
              v-model="BRD_TIT"
          >
          </v-text-field>
        </div>

        <div class="ml-auto align-self-center">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')" outlined class="btn-etc2" @click="getImageList">조회</v-btn>
        </div>
      </div>
    </div>

    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">
        상담이미지목록
        <div class="ml-auto">
          보기정렬
          <div class=" d-inline ml-2">
            <span v-for="i in CONST_BTNCOUNT.TO"
                  :key="i">
              <v-btn v-if="i >= CONST_BTNCOUNT.FROM && mixin_set_btn($options.name, 'btnShowRow')" outlined small class="btn-default" @click="changeRow(i)">
                <span class="v-btn__content">{{ i }}열</span>
              </v-btn>
              </span>
          </div>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnNew')" outlined class="btn-default ml-2" @click="newTable">신규</v-btn>

        </div>
      </h2>
      <template>
        <table style="display: grid;">
          <div class="image_list">
            <v-card
                class="mx-auto"
            >
              <v-container fluid v-if="historyList.length > 0">
                <v-row
                    v-for=" i in rows"
                    :key="i">
                  <v-col
                      v-for="j in cols"
                      :key="j">
                    <v-card v-if="i == 1 && historyList[j-1] != undefined" @click="vcardClick(historyList[j-1])">
                      <v-card-title v-text="historyList[j-1].BRD_TIT"></v-card-title>
                      <v-img
                          :style="{'background': getFileSrc(1,cols,j), 'background-size': 'cover'}"
                          height="200px"
                      >
                      </v-img>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-card-title v-text="historyList[j-1].FST_BRD_DT_D"></v-card-title>
                      </v-card-actions>
                    </v-card>
                    <v-card v-if="i == 2 && historyList[cols + j-1] != undefined"
                            @click="vcardClick(historyList[cols + j-1])">
                      <v-card-title v-text="historyList[cols + j-1].BRD_TIT"></v-card-title>
                      <v-img
                          :style="{'background': getFileSrc(2,cols,j), 'background-size': 'cover'}"
                          height="200px"
                      >
                      </v-img>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-card-title v-text="historyList[cols + j-1].FST_BRD_DT_D"></v-card-title>
                      </v-card-actions>
                    </v-card>
                    <v-card v-if="i == 3 && historyList[cols + cols + j-1] != undefined "
                            @click="vcardClick(cols + cols + j-1)">
                      <v-card-title v-text="historyList[cols + cols + j-1].BRD_TIT"></v-card-title>
                      <v-img
                          :style="{'background': getFileSrc(3,cols,j), 'background-size': 'cover'}"
                          height="200px"
                      >
                      </v-img>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-card-title v-text="historyList[cols + cols + j-1].FST_BRD_DT_D"></v-card-title>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </div>
        </table>
      </template>
      <div class="grid-paging text-center pt-2 mb-5">
        <v-pagination class="pagination mb-2" v-model="page" :length="pages" @input="updatePage"></v-pagination>
      </div>
      <v-dialog v-if="dialogM810607P01" max-width="1200" persistent v-model="dialogM810607P01"
                content-class="square-modal">
        <dialog-M810607P01
            headerTitle="새글 등록"
            :param="M810607P01Param"
            p01
            @hide="hideDialog('M810607P01')"
            @submit="submitDialog('M810607P01')"
        >
        </dialog-M810607P01>
      </v-dialog>
      <v-dialog v-if="dialogM810607P02" max-width="1200" persistent v-model="dialogM810607P02"
                content-class="square-modal">
        <dialog-M810607P02
            headerTitle="상세내용보기"
            :param="M810607P02Param"
            p02
            @hide="hideDialog('M810607P02')"
            @submit="submitDialog('M810607P02')"
        >
        </dialog-M810607P02>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import DialogM810607P01 from "./M810607P01.vue";
import DialogM810607P02 from "./M810607P02.vue";
import {mixin} from "../../mixin/mixin.js";
import {mapState, mapGetters, mapActions} from "vuex";

export default {
  name      : "MENU_M810607", //name은 'MENU_' + 파일명 조합
  mixins    : [mixin], // mixin 등록
  components: {
    DialogM810607P01,
    DialogM810607P02
  },

  data() {
    return {
      CONST_BTNCOUNT  : {"FROM": 3, "TO": 7},
      dialogM810607P01: false,
      dialogM810607P02: false,
      M810607P01Param : {},
      M810607P02Param : {},

      URLData         : '',
      ASP_CUST_KEY    : '',
      ASP_NEWCUST_KEY : '',
      selBexBizV      : '',
      gridListDataText: [],

      // as is list data
      FST_BRD_DT  : '',
      PATH_TYPE   : '',
      FILE_KEY    : '',
      FILE_NM     : '',
      FST_BRD_DT_D: '',

      BRD_TIT  : '', // 검색할 제목
      BRD_ID   : '',
      PAGES_CNT: 1, // header 현재 보여지는 페이지 기본 1
      ROW_CNT  : 1000, // 한 페이지에 보여줄 게시글의 수
      pageCount: 1,

      TOT_COUNT: '', // 총 게시글 수

      //페이징
      page       : 1,
      pageSize   : 10,
      listCount  : 0,
      historyList: [],
      rows       : 2,

      // css 열 바꿈
      articles: [],
      cols    : 5,


      alertMsg: {
        comChk     : "회사구분란에서 회사를 선택 후 이용해주십시오.",
        newWriteChk: '조회 후 작성 가능합니다.'

      },

      alertIcon: {
        noti: "svg-done-lg",
        err : "svg-error-lg",
      },

      alertType: {
        default: "default",
        confirm: "confirm",
      },
      dropItems: [],

      menu1: false,
      dates: ['', ''],

      IMAGE_TYPE   : '',
      FILE_EXTS    : '',
      FILE_BLOB    : '',
      FILE_PATH    : '',
      SAVE_FILENAME: '',
      viewBtnGrp   : 2,

      RDOnly:false,
    };
  },
  methods: {
    getListCM() { // 회사구분 콤보박스 조회
      var date = new Date("00:00:01");
      console.log("#### date=", date);
      console.log("#### seconds=", date.getSeconds());

      var user = {ASP_CUST_KEY: this.selBexBizV};
      console.log(user); // 회사 코드 확인완료

    },

    getBrdId() {
      let menuList = {};
      menuList = this.$store.getters["userStore/GE_USER_MENU"];
      // for (let i in menuList) {
      //   if (menuList[i].C_NODE_NO === "M810607") {
      //     let tempUrl = menuList[i].NODE_URL;
      //     let idIndex = tempUrl.indexOf("BRD_ID=");
      //     console.log("bordID ::::::::::::::" + tempUrl.substring(idIndex + 7));
      //     return tempUrl.substring(idIndex + 7);
      //   }
      // }
      //
      // for (let i in menuList) {
      //   let tempUrl = {};
      //   if (menuList[i].C_NODE_NO === "M81") {
      //     for (let j in menuList[i].child) {
      //       if (menuList[i].child[j].C_NODE_NO === "M8106") {
      //         tempUrl = menuList[i].child[j];
      //
      //         for (let i in tempUrl.child) {
      //           if (tempUrl.child[i].C_NODE_NO === "M810607") {
      //             let brdIdUrl = tempUrl.child[i].NODE_URL_MENUID;
      //             let idIndex = brdIdUrl.indexOf("BRD_ID=");
      //             return brdIdUrl.substring(idIndex + 7);
      //           }
      //         }
      //       }
      //     }
      //   }
      // }


      for(let i of menuList){
        if(i.C_NODE_NO == "M81"){
          for(let j of i.child){
            if(j.C_NODE_NO == "M8106"){
              for(let k of j.child){
                if(k.C_NODE_NO == "M810607"){
                  let brdIdUrl = k.NODE_URL_MENUID;
                  let idIndex = brdIdUrl.indexOf("BRD_ID=");
                  return brdIdUrl.substring(idIndex + 7);
                }
              }
            }
          }
        }
      }
    },

    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${year}/${month}/${day}`
    },

    formatDate2(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${year}${month}${day}`
    },

    showDialog(type) {
      this[`dialog${type}`] = true;
    },

    hideDialog(type) {
      this[`dialog${type}`] = false;
      this.getImageList();
    },

    submitDialog(type) {
      console.log("완료");
      this[`dialog${type}`] = false;
      this.getImageList();
    },


    async getImageList() { // 조회 버튼 클릭

      this.historyList = [];

      // 해당 페이지 접속 시 회사선택은 필수사항 알람창 띄우기
      if (this.selBexBizV == null || this.selBexBizV == "") {
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: true,
          msg              : this.alertMsg.comChk,
          iconClass        : this.alertIcon.err,
          type             : this.alertType.default,
        });
        return false;
      }

      let imageListUrl = "/api/chat/setting/image-manage/list";
      let requestData = {
        headers : {},
        sendData: {}
      };
      // header 세팅
      requestData.headers["URL"] = imageListUrl;
      requestData.headers["SERVICE"] = "chat.setting.image-manage";
      requestData.headers["METHOD"] = "list";
      requestData.headers["PAGES_CNT"] = this.PAGES_CNT;
      requestData.headers["ROW_CNT"] = this.ROW_CNT;
      requestData.headers["ASYNC"] = false;
      requestData.headers["TYPE"] = "BIZ_SERVICE";

      // sendData 세팅
      requestData.sendData = {};
      requestData.sendData["ASP_NEWCUST_KEY"] = this.selBexBizV;
      requestData.sendData["BRD_ID"] = this.BRD_ID;
      requestData.sendData["START_DT"] = this.formatDate2(this.dates[0]);
      requestData.sendData["END_DT"] = this.formatDate2(this.dates[1]);
      requestData.sendData["BRD_TIT"] = this.BRD_TIT;
      requestData.sendData["PAGES_CNT"] = this.PAGES_CNT;
      requestData.sendData["ROW_CNT"] = this.ROW_CNT;
      requestData.sendData["ASP_USER_ID"] = this.$store.getters['userStore/GE_USER_ROLE'].userId;
      requestData.sendData["ASP_CUST_KEY"] = this.selBexBizV;

      // 결과 리턴
      let response = await this.common_postCall(requestData);

      if (!response.HEADER.ERROR_FLAG) {
        this.setImageList(response);

      }
    },

    setImageList(response) {
      let header = response.HEADER;
      let data = response.DATA;
      this.gridListDataText = response.DATA;

      this.articles = Object.assign([], data);

      //페이징
      this.initPage();

    },

    // 3, 4, 5, 6, 7열 클릭 시 보여주는 게시글 조절
    changeRow(cols) {
      //histroyList =  한 페이지에 들어가는 articles
      this.cols = cols; //3
      this.pageSize = cols < 6 ? cols * 2 : cols * 3

      this.historyList = this.articles.slice(0, this.pageSize);
      //row 구하기
      let totalLength = this.historyList.length; //6
      if (totalLength % cols > 0) {
        this.rows = Math.ceil(totalLength / cols);
      } else {
        this.rows = totalLength / cols;
      }

      //페이징
      this.initPage();

    },

    initPage() {
      let _this = this;
      _this.page = 1;
      _this.listCount = _this.articles.length;
      if (_this.listCount < _this.pageSize) {
        _this.historyList = _this.articles;
      } else {
        _this.historyList = _this.articles.slice(0, _this.pageSize);
      }
    },

    updatePage(pageIndex) {
      let _this = this;
      let _start = (pageIndex - 1) * _this.pageSize;
      let _end = pageIndex * _this.pageSize;
      _this.historyList = _this.articles.slice(_start, _end);
      _this.page = pageIndex;
    },

    getFileSrc(row, cols, j) {
      let ojbIdx = (row - 1) * cols;
      let strImageUrl = "";

      if (row == 1) {
        strImageUrl = `url(/upload/${this.historyList[ojbIdx + j - 1].FILE_PATH}/${this.historyList[ojbIdx + j - 1].SAVE_FILENAME})`;
      } else if (row == 2) {
        strImageUrl = `url(/upload/${this.historyList[ojbIdx + j - 1].FILE_PATH}/${this.historyList[ojbIdx + j - 1].SAVE_FILENAME})`;
      } else if (row == 3) {
        strImageUrl = `url(/upload/${this.historyList[ojbIdx + j - 1].FILE_PATH}/${this.historyList[ojbIdx + j - 1].SAVE_FILENAME})`;
      }
      return strImageUrl;
    },

    newTable() {
      if (this.isEmpty(this.selBexBizV)) {
        this.common_alert(this.alertMsg.newWriteChk, "error");
        return;
      }

      this.M810607P01Param = {
        flag       : 'new',
        headerTitle: "새글 등록",
        userId     : this.$store.getters['userStore/GE_USER_ID'],
        bizCd      : this.selBexBizV,
        bizNm      : this.mixin_getCompanyNM(this.selBexBizV), 
        item       : this.gridListDataText[0],
      }

      this.showDialog('M810607P01');
    },

    vcardClick(item) {
      console.log(item)

      this.M810607P02Param = {
        headerTitle: "상세내용보기",
        userId     : this.$store.getters['userStore/GE_USER_ID'],
        bizCd      : this.selBexBizV,
        bizNm      : this.mixin_getCompanyNM(this.selBexBizV), //확인필요
        item       : item,
      }

      this.showDialog('M810607P02');
    },
    isEmpty(val) {
      if (val == null) return true
      if (typeof val === 'undefined') return true
      if (typeof val === 'string' && val === '' && ('' + val) === '') return true
      if (Array.isArray(val) && val.length < 1) return true
      if (typeof val === 'object' && val.constructor.name === 'Object' && Object.keys(val).length < 1) return true
      if (typeof val === 'object' && val.constructor.name === 'String' && Object.keys(val).length < 1) return true
      if (val.constructor === Object && Object.keys(val).length === 0) return true
    },
    resetDate() {
      this.dates = ['', ''];
    },
  },

  mounted() {
    this.BRD_ID = this.getBrdId();

    let chk = this.mixin_check_AspCustKey_number();
    if(chk == 'Y'){
      this.selBexBizV = this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD;
      //this.selBexBizV.CD = this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD;
      this.RDOnly = true;
    } else {
      this.RDOnly = false;
    }
  },

  computed: {
    pages() {
      let _this = this;
      if (_this.pageSize == null || _this.listCount == null) return 0;
      return Math.ceil(_this.listCount / _this.pageSize);
    },

    dateRangeText() {
      let returnVal = '';
      let newStartDate = this.dates[0];
      let newEndDate = this.dates[1];

      if (!this.isEmpty(newStartDate) && !this.isEmpty(newEndDate)) {
        returnVal = newStartDate + " ~ " + newEndDate;
      } else if (!this.isEmpty(newStartDate) && this.isEmpty(newEndDate)) {
        returnVal = newStartDate + " ~ "
      } else if (this.isEmpty(newStartDate) && !this.isEmpty(newEndDate)) {
        returnVal = " ~ " + newEndDate;
      }
      return returnVal;
    },
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    computedDateFormatted2() {
      return `${this.date} ${this.timeStep}`
    },

    ...mapGetters({
      //store에 저장된 사용자의 company 정보를 가져온다
      aspCustList: "userStore/GE_USER_COMPANY",
    }),

  },
};
</script>