<template>
  <div class="modal-wrap">
    <h1 class="tit-h1 d-flex">
      {{ headerTitle }}
      <div class="ml-auto align-self-center">
        <v-icon x-small class="svg-close20" @click="$emit('hide')"></v-icon>
      </div>
    </h1>
    <div class="modal-box">
      <div>
        <!-- 분류 1레벨 정보 -->
      <template v-if="p01">
        <div class="box-ct gray-box">
          <div class="table-form">
            <table>
              <colgroup>
                <col width="127px" />
                <col width="150px" />
                <col width="127px" />
                <col width="150px" />
              </colgroup>
              <tbody>
              <tr>
                <th scope="row">상위분류ID <v-icon color="#EF9191">mdi-circle-small</v-icon> </th>
                <td>
                  <v-text-field
                      class="form-inp full"
                      name="name"
                      outlined
                      readonly
                      :value="0"
                      hide-details>
                  </v-text-field>
                </td>
                <th scope="row">분류명 <v-icon color="#EF9191">mdi-circle-small</v-icon> </th>
                <td>
                  <v-text-field
                      class="form-inp full"
                      name="name"
                      outlined
                      hide-details
                      v-model="DIV_NM"
                  >
                  </v-text-field>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </template>

      <!-- 찾기 -->
      <template v-if="p02">
        <div class="d-flex gray-box">
          <div class="form-inp">
            <v-select
                :items="this.$store.getters['userStore/GE_USER_ROLE'].company"
                item-text="CD_NM"
                item-value="CD"
                outlined
                hide-details
                label="회사구분"
                placeholder="선택하세요"
                v-model="aspCustKey"
                readonly
            >
            </v-select>
          </div>
          <div class="form-inp">
            <v-select
                :items="this.mixin_common_code_get(this.commonCode, 'TWB151', '전체')"
                outlined
                hide-details
                label="템플릿 타입"
                placeholder="선택하세요"
                v-model="TMPLAT_TYPE_CD"
            >
            </v-select>
          </div>
          <div class="ml-auto align-self-center">
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')" outlined class="btn-etc2" @click="tmplatList">조회</v-btn>
          </div>
        </div>
        <h2 class="tit-h2 mt-3">지식 컨텐츠 템플릿 현황</h2>
        <div class="box-ct mt-3">
          <v-data-table
            dense
            :headers="gridDataHeaders"
            :items="gridDataText"
            :items-per-page="30"
            item-key="index"
            :page.sync="page"
            hide-default-footer
            class="grid-default"
            height="493px"
            @page-count="pageCount = $event"
          >
            <template v-slot:item.PRE_VIEW="{item}">
              <v-btn v-if="mixin_set_btn($options.name, 'btnPreview')" outlined small class="btn-etc2 ml-3" @click="showDialogDetail('M510301P03', item.PRE_VIEW)">미리보기</v-btn>
            </template>
            <template v-slot:item.SELECTED_VIEW="{item}">
              <v-btn v-if="mixin_set_btn($options.name, 'btnChoice')" outlined small class="btn-etc2 ml-3" @click="selectTmplatId(item.SELECTED_VIEW, item.TITL)">선택</v-btn>
            </template>
          </v-data-table>
          <div class="grid-paging text-center pt-2">
            <v-pagination
              v-model="page"
              :length="pageCount"
              :total-visible="totalVisible"
              next-icon="svg-paging-next"
              prev-icon="svg-paging-prev"
            ></v-pagination>
          </div>
          <p class="caption">
            총 <strong class="clr-error">{{ gridDataText.length }}</strong
            >건
          </p>
        </div>
      </template>
      <!-- 미리보기 -->
      <template v-if="p03">
        <div class="box-ct">
          <ckeditor :value="previewContn.contn" :config="readEditorConfig" :read-only="true"></ckeditor>
        </div>
      </template>
      </div>
      <div class="text-right mt-3">
        <div v-if="this.mixin_set_btn(this.$options.name, 'btnSave')">
          <v-btn outlined class="btn-point" @click="divNmIsNull" v-if="p01">저장</v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mixin} from "../../mixin/mixin.js";

export default {
  mixins:[mixin],
  name: "MENU_M510101P01", //name은 'MENU_' + 파일명 조합
  props: {
    p01: {
      type: Boolean,
      default: false,
    },
    p02: {
      type: Boolean,
      default: false,
    },
    p03: {
      type: Boolean,
      default: false,
    },


    headerTitle: {
      type: String,
      default: "제목",
    },

    aspCustKey: {
      type: String,
    },

    previewContn: {
      type: Object
    }
  },
  async mounted(){
    this.commonCode = await this.mixin_common_code_get_all(this.commonCodeArr);
    this.tmplatList();
  },
  data: () => ({
    footerHideTitle: "닫기",
    dropItems: [{ title: "option1" }],

    commonCodeArr : ["TWB151"],
    commonCode : [],
    TMPLAT_TYPE_CD: '',

    DIV_NM: '',


    gridDataHeaders: [
      {
        text: 'No',
        align: 'center',
        value: 'index',
        width: '50px',
        sortable: false,
      },
      { text: '템플릿타입', value: 'TMPLAT_TYPE_NM', align: 'center', sortable: false, },
      { text: '템플릿명', value: 'TITL',align: 'center', sortable: false, },
      { text: '미리보기', value: 'PRE_VIEW', align: 'center',sortable: false, },
      { text: '선택', value: 'SELECTED_VIEW', align: 'center',sortable: false, },
    ],
    gridDataText: [],

    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    totalVisible: 10,
    totcount: 0,

    readEditorConfig: {
      toolbarGroups: [
        { name: 'forms', groups: [ 'forms' ] },
        { name: 'basicstyles', groups: [ 'basicstyles' ] },
        { name: 'styles', groups: [ 'styles' ] },
        { name: 'colors', groups: [ 'colors' ] },
        { name: 'insert', groups: [ 'insert' ] },
        { name: 'links', groups: [ 'links' ] },
        { name: 'tools', groups: [ 'tools' ] },
      ],
      height: "500px",
      toolbarCanCollapse:  true,
      toolbarStartupExpanded: false,
    },

  }),
  methods:{
    divNmIsNull(){
      if(this.DIV_NM == '' || this.DIV_NM == null){
        this.common_alert("분류명을 입력해주세요.", 'error');
        return;
      };

      this.insertFirstNode();
    },
    async insertFirstNode(){
      let params = {
        SPST_DIV_ID: 0,
        SCR_DISP_YN: "Y",
        DIV_NM: this.DIV_NM,
      };
      this.$emit('insert', params);
      this.$emit('hide');
    },

    async tmplatList(){
      //조회
      let requestData = {
        headers: {},
        sendData:{}
      };

      //header세팅
      requestData.headers["URL"] = "/api/km/template/manage/page/list-pop";
      requestData.headers["SERVICE"] = "km.template.manage.page";
      requestData.headers["METHOD"] = "list-pop";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = false;
      requestData.headers["PAGES_CNT"] = 1;
      requestData.headers["ROW_CNT"] = 100;

      //sendData 초기화
      requestData.sendData["ASP_NEWCUST_KEY"] = this.aspCustKey;  //회사코드
      requestData.sendData["TMPLAT_TYPE_CD"] = this.TMPLAT_TYPE_CD;    //템플릿 타입

      let response = await this.common_postCall(requestData);
      this.tmplatListRes(response);
    },
    tmplatListRes(response){
      //템플릿명 > 템플릿 찾기 리스트
      let header = response.HEADER;
      this.totcount = header.TOT_COUNT;

      let data = response.DATA;

      this.gridDataText= [];

      for(var i = 0; i < data.length; i++){
        this.gridDataText.push({
          index: i+1,
          TMPLAT_ID: data[i].TMPLAT_ID,
          TMPLAT_TYPE_NM: data[i].TMPLAT_TYPE_NM,
          TITL: data[i].TITL,
          PRE_VIEW: data[i].TMPLAT_ID,
          SELECTED_VIEW: data[i].TMPLAT_ID,
        });
      }
    },
    async showDialogDetail(type, tmpId){
      let previewParams = {
        type : type,
        TMPLAT_ID: tmpId
      }

      this.$emit('openPreview', previewParams);
    },
    selectTmplatId(tmplatId, titl){
      let params = {
        TMPLAT_ID: tmplatId,
        TMPLAT_TITL: titl,
      }

      this.$emit('selectId', params);
      this.$emit('hide');
    },
  },
  computed: {

  },
};
</script>

<style></style>
